import { _SumArr, _Mul, _Sub } from "../../../../helper/handleMath";

export function sumCredit(arr) {
    return _SumArr(arr, 'credit');
}

export function sumDebet(arr) {
    return _SumArr(arr, 'debet');
};

export function sumCurrCredit(arr) {
    return _SumArr(arr, 'currCredit');
};

export function sumCurrDebet(arr) {
    return _SumArr(arr, 'currDebet');
};

export function sumDiffDebet(arr) {
    let _debet = sumDebet(arr);
    let _credit = sumCredit(arr);
    let result = _Sub(_debet, _credit) < 0 ? _Sub(_debet, _credit) : undefined;
    if (result < 0) {
        return _Mul(result , -1);
    } else {
      return undefined;
    }
};

export function sumDiffCredit(arr) {
    let _debet = sumDebet(arr);
    let _credit = sumCredit(arr);
    let result = _Sub(_debet, _credit) > 0 ? _Sub(_debet, _credit): undefined;
    return result;
};

export function sumDiffCurrDebet(arr) {
    let env = _Sub(sumCurrCredit(arr), sumCurrDebet(arr));
    if (env > 0) {    
        return env 
    } else {
      return undefined;
    }
};

export function sumDiffCurrCredit(arr) {
    let env = _Sub(sumCurrCredit(arr), sumCurrDebet(arr));
    if (env < 0) {
        return _Mul(env , -1);
    } else {
      return undefined;
    }
};

