import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Space, Divider, Input, Button, message, Spin } from "antd";
import _t from "../../../../../languages/translate";
import SelectSingle from "../../../../helper/selectSingle";
import { GetActiveChildren } from "../../../../../services/branchService";
import { handleEx } from "../../../../helper/handleException";
import {
  initiale,
  getEntPattSettById,
  editEntPattSett,
} from "../../../../../services/EntPatSettService";

import Draggable from "react-draggable";
import EntPatt from "../entPattSettings";
import { emptString } from "../../../../helper/helperMethods";
const LinkToBranch = ({
  showModal,
  setShowModal,
  entPattId,
  entPattSettId,
  selectedTypeId,
  onFinishLink,
}) => {
  const [loadData, setLoadData] = useState(false);
  const [branchChildsOpt, setBranchChildsOpt] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [dataObj, setDataObj] = useState(undefined);
  const [selectedBranchId, setSelectedBranchId] = useState(undefined);
  const [uniqueId, setUniqueId] = useState(new Date());
  const [form] = Form.useForm();
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
     await setLoadData(true);
      await handleActiveBranches();
      
      try {
        if (
          entPattId !== "new" &&
          showModal === true &&
          entPattSettId === undefined
        ) {
          const { data: data } = await initiale(entPattId);
          await  form.setFieldsValue({
            pattName: data.data.pattName,
            entTypeName: data.data.entTypeName,
            branchId: emptString(data.data.branchId, undefined),
          });
          await  setSelectedBranchId(emptString(data.data.branchId, undefined))
          await  setDataObj(data);
          
        } else if (entPattSettId !== undefined) {
          const { data: obj } = await getEntPattSettById(entPattSettId);
          await  form.setFieldsValue({
            pattName: obj.data.pattName,
            entTypeName: obj.data.entTypeName,
            branchId: emptString(obj.data.branchId, undefined),
          });
          await setSelectedBranchId(emptString(obj.data.branchId, undefined))
          await setDataObj(obj);
          
        }
      
      } catch (error) {
        handleEx(error);
      } finally {
        await setLoadData(false);
      }
    }
    fetchData();
  }, [showModal]);

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const handleActiveBranches = async () => {
    try {
      let obj = { search: "", addIds: null };
      const { data: data } = await GetActiveChildren(obj);
      setBranchChildsOpt(data.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      form.resetFields();
      setUniqueId(new Date());
      setDataObj(undefined);
      setShowModal(false);
      setLoadData(false);
    }, 100);
  };

  const onFinish = async (values) => {
    setLoadData(true);
    values.id = entPattSettId;
    values.entPattId = entPattId;
    try {
      const { data: data } = await editEntPattSett(values);
      message.success(data.message, 3);
      onFinishLink(data.message);
      handleClear();
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  return (
    <Modal
      width={"90%"}
      bodyStyle={{ background: "#f0f2f5" }}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {`${_t("strLinkToBranch")} / ${_t("strEntries")}`}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      centered
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={false}
    >
      <Spin spinning={loadData} tip={_t("strLoading")}>
      <Form form={form} onFinish={onFinish}>
        <div name="cardsId" className="frHeader">
          <Space size="large">
            <Form.Item
              name="branchId"
              label={_t("strBranch")}
              rules={[{ required: true }]}
            >
              <SelectSingle
                style={{ width: 250 }}
                allowClear={true}
                opt={branchChildsOpt}
                placeholder={_t("strNotSelected")}
                onChange={(e)=>setSelectedBranchId(e)}
              />
            </Form.Item>
            <Divider type="vertical" />
            <Form.Item name="pattName" label={_t("strPattren")}>
              <Input bordered={false} disabled placeholder={_t("strNameAr")} />
            </Form.Item>

            <Form.Item name="entTypeName" label={_t("strType")}>
              <Input bordered={false} disabled placeholder={_t("strType")} />
            </Form.Item>
          </Space>
        </div>

        <div key={uniqueId}>
          <EntPatt
            form={form}
            dataObj={dataObj}
            selectedTypeId={selectedTypeId}
            selectedBranchId={emptString(selectedBranchId,undefined)}
            setLoadData={setLoadData}
          />
        </div>

        <div name="cardsId" className="frFooter">
          <Form.Item>
            <Button onClick={form.submit} size="small" type="primary">
              {_t("strSave")}
            </Button>
          </Form.Item>
        </div>
      </Form>

      </Spin>
    </Modal>
  );
};

export default LinkToBranch;
