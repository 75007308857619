import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, message } from "antd";
import InputCurrency from "../../../../helper/Input/InputCurrency";
import SelectAccount from "../../../../helper/inputSelect/selectAccount";
import SelectDiscInvAcc from "../../invFunctions/selectHelper/selectDisInvAcc";
import { objCountNum } from "../../../../helper/countOfZero";
import { emptString, emptValue } from "../../../../helper/helperMethods";
import CostCenterInput from "../../../../helper/Input/costCenterInput";
import { _RateMul } from "../../../../helper/handleMath";
import _t from "../../../../../languages/translate";

const DiscsFields = ({
  innerRefSelect,
  refObj,
  form,
  discsDataSource,
  setDiscsDataSource,
  setInvDiscsOptReqCost,
  invDiscsOptReqCost,
  promptForm,
  pattrenSet,
  setStatDiscFields,
  disableDiscsPart,
  setActiveKey,
  auth,
}) => {
  const [accList, setAccList] = useState([]);

  //----------- cost center --------------------------------
  //const [invDiscsOptReqCost, setInvDiscsOptReqCost] = useState(0);

  // useEffect(()=>{

  // },[discsDataSource])

  const handleDiscsPerc = (e) => {
    const {
      invDiscId: _InvDiscId,
      invDiscTotal: _Value,
      invDiscTotalAcc: _AccId,
      invDiscTotalDistr: _Distr,
      itemSubTotal: _subTotal,
      discInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    const calcDiscVal = emptValue(
      emptValue(_subTotal, 0) !== 0
        ? emptValue(_RateMul(_subTotal, e), undefined)
        : 0,
      undefined
    );
    form.setFieldsValue({
      invDiscTotal: calcDiscVal,
    });
    handleDisc(
      emptValue(e, undefined),
      calcDiscVal,
      _AccId,
      _Distr,
      _CostCenters,
      _InvDiscId
    );
  };

  const handleDiscsVal = (e) => {
    const {
      invDiscId: _InvDiscId,
      invDiscTotalAcc: _AccId,
      invDiscTotalDistr: _Distr,
      discInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    form.setFieldsValue({
      invDiscTotalPer: undefined,
    });
    handleDisc(
      undefined,
      emptValue(e, undefined),
      _AccId,
      _Distr,
      _CostCenters,
      _InvDiscId
    );
  };

  const handleDiscsAcc = (e) => {
    const {
      invDiscId: _InvDiscId,
      invDiscTotal: _Value,
      invDiscTotalPer: _Perc,
      invDiscTotalDistr: _Distr,
      discInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleDisc(
      _Perc,
      _Value,
      emptString(e, undefined),
      _Distr,
      _CostCenters,
      _InvDiscId
    );
  };

  const handleDiscsCheck = (e) => {
    const {
      invDiscId: _InvDiscId,
      invDiscTotal: _Value,
      invDiscTotalPer: _Perc,
      invDiscTotalAcc: _AccId,
      discInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleDisc(
      _Perc,
      _Value,
      _AccId,
      e.target.checked,
      _CostCenters,
      _InvDiscId
    );
  };

  const onHandelCostCenter = () => {
    const {
      invDiscId: _InvDiscId,
      invDiscTotal: _Value,
      invDiscTotalPer: _Perc,
      invDiscTotalAcc: _AccId,
      invDiscTotalDistr: _Distr,
      itemSubTotal: _subTotal,
      discInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleDisc(_Perc, _Value, _AccId, _Distr, _CostCenters, _InvDiscId);
  };

  const handleDisc = (perc, val, acc, check, costCenters, invDiscId) => {
    setStatDiscFields(true);

    const list = [
      {
        id: invDiscId,
        key: 0,
        value: val,
        perc: perc,
        accId: acc,
        accName: accList.filter((x) => x?.id === acc)[0]?.name,
        distributed: check,
        costCenter_Maps: costCenters,
        costCenterReqOpt: accList.filter((x) => x?.id === acc)[0]
          ?.costCenterReqOpt,
        nextAccId: discsDataSource[0].nextAccId,
        nextAccName: discsDataSource[0].nextAccName,
      },
      { key: 1 },
    ];
    setDiscsDataSource([...list]);
  };

  return (
    <tr>
      <td
        style={{ textAlign: "end" }}
        hidden={!pattrenSet?.invDiscTotalVisible || !auth?.perShowPrices}
      >
        <Form.Item name="invDiscId" hidden>
          <Input />
        </Form.Item>
        <Input.Group compact style={{ direction: _t("langDiriction") }}>
          <Form.Item name="invDiscTotalPer" noStyle>
            {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
            <InputCurrency
              innerRef={refObj?.refDiscPerc}
              zIndex={110}
              precision={objCountNum.perc}
              size="small"
              style={{ width: 60, textAlign: "right", marginBottom: 3 }}
              onChange={(e) => {
                handleDiscsPerc(e);
                promptForm();
              }}
              disabled={!disableDiscsPart}
            />
          </Form.Item>
          <Form.Item name="invDiscTotal" noStyle>
            {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
            <InputCurrency
              innerRef={refObj?.refDiscValue}
              zIndex={111}
              precision={objCountNum.value}
              size="small"
              style={{ width: 100, textAlign: "right", marginBottom: 3 }}
              onChange={(e) => {
                handleDiscsVal(e);
                promptForm();
              }}
              disabled={!disableDiscsPart}
            />
          </Form.Item>
        </Input.Group>
      </td>
      <td
        hidden={!pattrenSet?.invDiscTotalVisible || !auth?.perShowPrices}
        style={{ minWidth: 130, paddingLeft: 10, paddingRight: 10 }}
      >
        <label> {_t("strInvDiscTotal")} </label>
      </td>
      {disableDiscsPart && (
        <>
          <td
            hidden={
              (!pattrenSet?.discInvAccVisible || !auth?.perShowPrices) &&
              disableDiscsPart
            }
          >
            <Form.Item
              name="invDiscTotalAcc"
              className="gen-input"
              rules={[
                {
                  required:
                    form.getFieldValue("invDiscTotalPer") ||
                    form.getFieldValue("invDiscTotal"),
                  message: "",
                  validator: async (_, value) => {
                    if (
                      (form.getFieldValue("invDiscTotalPer") ||
                        form.getFieldValue("invDiscTotal")) &&
                      (value === undefined || value === "")
                    ) {
                      setActiveKey("1");
                      message.error(
                        `(${_t("strInvDiscTotalAcc")}) ${_t("strIsRequired")}`
                      );
                      throw new Error("message");
                    }
                  },
                },
              ]}
            >
              <SelectAccount
                innerRef={refObj?.refDiscAcc}
                zIndex={112}
                addId={form.getFieldValue("invDiscTotalAcc")}
                onChange={(e, relatedObj) => {
                  handleDiscsAcc(e);
                  promptForm();
                  setInvDiscsOptReqCost(relatedObj?.costCenterReqOpt);
                }}
                handelOptChange={(e) => setAccList(e)}
                placeholder={_t("strAccount")}
                style={{
                  width: 160,
                  textAlign: "right",
                  marginBottom: 3,
                }}
                disabled={!pattrenSet?.discInvAccEnable}
                handleChange={() => {}}
              />
            </Form.Item>
          </td>
          <td
            hidden={
              (!pattrenSet?.discInvAccVisible || !auth?.perShowPrices) &&
              disableDiscsPart
            }
            style={{ minWidth: 75, paddingLeft: 10, paddingRight: 10 }}
          >
            <label>
              {_t("strAccount")}
              <label
                style={{
                  color: "red",
                  visibility:
                    form.getFieldValue("invDiscTotalPer") ||
                    form.getFieldValue("invDiscTotal")
                      ? ""
                      : "collapse",
                }}
              >
                *
              </label>
            </label>
          </td>

          <>
            <td
              hidden={
                !pattrenSet?.discInvAccCostCenterVisible || !auth?.perShowPrices
              }
            >
              <Form.Item name="discInvCostCenter_Maps" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.invDiscTotalAcc !== curr.invDiscTotalAcc
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="discInvCostCenter"
                      rules={[
                        {
                          required:
                            pattrenSet?.discInvAccCostCenterReq === true &&
                            emptString(discsDataSource[0]?.accId, false) !==
                              false &&
                            emptValue(discsDataSource[0]?.value, false) !==
                              false,
                          validator: async (_, value) => {
                            if (
                              pattrenSet?.discInvAccCostCenterReq === true &&
                              emptString(discsDataSource[0]?.accId, false) !==
                                false &&
                              emptValue(discsDataSource[0]?.value, false) !==
                                false &&
                              (value === undefined || value === "")
                            ) {
                              message.error(
                                `(${_t("strCostCenterInvDisc")}) ${_t(
                                  "strIsRequired"
                                )}`
                              );
                              throw new Error("message");
                            }
                          },
                        },
                      ]}
                    >
                      <CostCenterInput
                        unValue={false}
                        innerRef={refObj?.refDiscCostCent}
                        zIndex={113}
                        style={{
                          width: 160,
                          textAlign: "right",
                          marginBottom: 3,
                        }}
                        form={form}
                        fieldName="discInvCostCenter"
                        arrName="discInvCostCenter_Maps"
                        accountValidationOpt={invDiscsOptReqCost}
                        // type='input'
                        amount={getFieldValue("invDiscTotal")}
                        onHandelData={onHandelCostCenter}
                        disabled={!pattrenSet?.discInvAccCostCenterEnable}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </td>
            <td
              hidden={
                !pattrenSet?.discInvAccCostCenterVisible || !auth?.perShowPrices
              }
              style={{
                minWidth: 130,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <label>
                {_t("strCostCenterInvDisc")}
                <label
                  style={{
                    color: "red",
                    visibility:
                      pattrenSet?.discInvAccCostCenterReq === true &&
                      emptString(discsDataSource[0]?.accId, false) !== false &&
                      emptValue(discsDataSource[0]?.value, false) !== false
                        ? ""
                        : "collapse",
                  }}
                >
                  *
                </label>
              </label>
            </td>
          </>
        </>
      )}
      {pattrenSet?.discInvAccVisible && disableDiscsPart && (
        <td
          hidden={
            (!pattrenSet?.discInvAccVisible || !auth?.perShowPrices) &&
            disableDiscsPart
          }
        >
          <Form.Item name="invDiscTotalDistr" noStyle valuePropName="checked">
            <Checkbox
              tabIndex={114}
              onChange={(e) => {
                handleDiscsCheck(e);
                promptForm();
              }}
              disabled={!pattrenSet?.discInvDistEnable}
            />
          </Form.Item>
          <label> {_t("strDistribution")} </label>
        </td>
      )}
    </tr>
  );
};

export default DiscsFields;
