import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
  Switch,
  Typography,
  Tag
} from "antd";
import {
  getJournalById,
  editJournal,
  getNewJournalCode,
} from "../../services/journalService";
import { RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
const JournalForm = ({ onNewUnit }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const IX_StartCode = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const [isGoBack, setIsGoBack] = useState(true);
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const codeGenerator = async () => {
    const { data: newData } = await getNewJournalCode();
    form.setFieldsValue({ code: newData.data });
    IX_Name.current.focus();
    setLoadData(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (id === "new" || typeof onNewUnit === "function") {
        return await codeGenerator();
      } else {
        try {
          const { data } = await getJournalById(id);
          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            startCode: data.data.startCode,
            name: data.data.name,
            note: data.data.note,
            inactive: data.data.inactive,
          });
          IX_Name.current.focus();
        } catch (error) {
          handleEx(error, { IX_Code, IX_Name, IX_StartCode });
        }
        finally {
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {

    if (isGoBack) {
      history.goBack();
    } else {
      form.resetFields();
      history.replace({ ...history.location, pathname: "/journal/new" });
      codeGenerator();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    if (typeof onNewUnit === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    try {
      const data = await editJournal(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      if (typeof onNewUnit === "function") {
        onNewUnit(data);
      } else {
        goBack();
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name, IX_StartCode });
    }

  };



  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };


  //******************************// //*******************// Form //*******************// //******************************//

  return (

    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />


      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="journalform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{
            flex: "150px",
          }}
          wrapperCol={{
            span: 20,
          }}
        >

          <div name="code" className="frHeader">
            <Row>
              <Col xs={24} md={24}>
                <Space split="#">
                  <Space split="\">
                    <Text >{_t("strDefinitions")}</Text>
                    <Text style={{ fontWeight: 700 }}>{_t("strJournal")}</Text>
                  </Space>
                  <div>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Code}
                        bordered={false}
                      />
                    </Form.Item>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + F
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </div>


          <div name="field" className="frContent">

            <Row gutter={12}>

              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  label={<div>
                    {_t("strName")}
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 4,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + 1
                      </Tag>
                    </div>
                  </div>}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strName")}
                    maxLength={200}
                    autoComplete="off"
                    size="small"
                    onChange={promptForm}
                    ref={IX_Name}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="startCode"
                  label={_t("strStartCode")}

                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strStartCode")}
                    maxLength={200}
                    autoComplete="off"
                    size="small"
                    onChange={promptForm}
                    ref={IX_StartCode}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="inactive"
                  valuePropName="checked"
                  label={_t("strInActive")}
                >
                  <Switch size="small" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                    size="small"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="frFooter">
            <Form.Item>
              <Space size="large">
                {!auth?.perPut && (
                  <div>
                    <Button type="primary" size="small" htmlType="submit" disabled={loading}>
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                )}
                <Dropdown.Button
                  hidden={typeof onNewStore === "function"}
                  overlay={menu}
                  onClick={() => { history.action === "PUSH" ? history.goBack() : history.push("/journal"); }}
                  size="small"
                >
                  {" "}
                  {_t("strBack")}
                </Dropdown.Button>
                {isGoBack ? (
                  <i />
                ) : (
                  <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                )}
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Spin>

    </React.Fragment>

  );
};

export default JournalForm;
