
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Company";

export function getCompanyById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getCompany() {
  return http.get(`${apiEndpoint}`);
}

export function editCompany(obj) {
  return http.put(apiEndpoint, obj);
}

export function deleteCompany(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getAllCompany() {
  return http.get(`${apiEndpoint}`);
}

export function getPagerCompany(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function deleteImage(Id, fileName) {
  return http.post(`${apiEndpoint}/DeleteImage?Id=${Id}&fileName=${fileName}`);
}