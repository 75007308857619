//import { calcQty, calcQtyTotal, calcDisPerc } from "./calcColumns";
import { emptValue } from "../../../helper/helperMethods";
import { _Mul, _Sum, _Div, _RateMul } from "../../../helper/handleMath";

export const handleQty = async (p, e, obj, count) => {
  obj = p.row;
  obj.qty = emptValue(e.target.value, null);
  obj = await calcQtyTotal(obj, count);
  return obj;
};

export const handleQtyTotal = async (p, e, obj, count) => {
  
  obj = p.row;
  obj.qtyTotal = emptValue(e.target.value, null);
  obj = await calcQty(obj, count);
  return obj;
};

export const handleDisPerc = async (p, e, obj, rawTotal) => {
  obj = p.row;
  obj.distributePerc = emptValue(e.target.value, null);
  obj = await calcTotal(obj, rawTotal);
  return obj;
};

//-------

export const calcQty = (obj, count) => {
  obj.qty =  emptValue(_Div(obj.qtyTotal, count), null);
  obj = calcPrice(obj);
  return obj;
};

export const calcPrice = (obj) => {
  
  obj.price =  emptValue(_Div(obj.total, obj.qtyTotal), null);
  
  return obj;
};

export const calcQtyTotal = (obj, count) => {
  
  obj.qtyTotal = emptValue(_Mul(obj.qty, count), null);
  obj = calcPrice(obj);
  
  return obj;
};
export const calcTotal_munf = (obj, rawTotal) => {
  
  return calcTotal(obj,rawTotal);
}

export const calcTotal = (obj, rawTotal) => {
  
  obj.total = _RateMul(rawTotal, obj.distributePerc);
  
  if(emptValue(obj.qtyTotal,null)!==null){
  obj = calcPrice(obj);
}

  return obj;
};
