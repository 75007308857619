
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Currency";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getCurrenciesById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getDefaultIdByAccount(accId) {
  return http.post(`${apiEndpoint}/GetDefaultIdByAccount?accId=${accId}`);
}

export function editCurrencies(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}
    

    export function getCurrActiveSearch(search) {
      return http.post(
        `${apiEndpoint}/GetActiveSearch?search=${search}`
      );
    }
    

    export function getActiveCurr(search) {
      const params = new URLSearchParams()
      search && params.append("search", search);  
     return `${apiEndpoint}/GetActive/?${params.toString()}`;
    }


export function getAllCurrencies() {
  return http.get(`${apiEndpoint}`);
}
export function deleteCurrencies(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerCurrencies(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewCurrenciesCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}


export function getDefaultIdByCompany(id) {
  return http.post(`${apiEndpoint}/GetDefaultIdByCompany?companyId=${id}`);
}