import React, { useState, useRef } from "react";
import { Form, Modal, Button, Divider } from "antd";
import _t from "../../../../../languages/translate";
import Draggable from "react-draggable";
import SettTable from "../../../../helper/gridSettTable/settTable";
import { convertToSave } from "../../../../helper/gridSettTable/components/helperMethods";
const MunfTableSett = ({
  showModal,
  setShowModal,
 // setAddsColSett,
  columns,
  setColumns,
  dataSource,
  setDataSource,
  tableName,
  onHandleData
}) => {
 
  const [loadData, setLoadData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [uniqueId, setUniqueId] = useState(new Date());
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);


  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };
  
  const handleSave = () => {
    const data = convertToSave(dataSource, tableName);
    onHandleData(data);
   // setAddsColSett(data);
    setShowModal(false);
  };
  
  const handleClear = () => {
    setLoadData(true);
    setUniqueId(new Date());
    setShowModal(false);
    setTimeout(() => {
      setColumns([]);
      setDataSource([]);
      setLoadData(false);      
    }, 100);
  };

 
  return (
    <Modal
      width={"100%"}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {`${_t("strColSettings")} / ${_t("strMunfColSettings")}`}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={false}
    >
    <div key={uniqueId}>


          <SettTable
            style={{ height: "100%", width: "100%" }}
            hideNum={true}
            columns={columns}
            showPath={false}
            showTableButtons={true}
            rows={dataSource}
            setRows={setDataSource}
            isPagination={false}
            tableName={tableName}
            tree={false}
            showReload={false}
            frezzColExp={false}
            pageName={_t("strQTY")}
            groupName={_t("strReports")}
            type="rep"
            loading={loadData}
          />
  
        <Divider style={{ margin: "10px" }} />
 
          <Button
            type="primary"
            style={{ margin: "0 10px" }}
            onClick={handleSave}
          >
            {_t("strSave")}
          </Button>    
       

    </div>
  
     
    </Modal>
  );
};

export default MunfTableSett;
