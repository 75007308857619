import { useEffect, useRef } from "react";
import { Input, message } from "antd";
import _t from "../../../../languages/translate";
const CurrEditor = ({
  row,
  column,
  onRowChange,
  onClose,
  onBlur,
  positiveNum = false,
  ...rest
}) => {
  const _ref = useRef(null);

  useEffect(() => {
    _ref?.current?.focus();
    _ref?.current?.select();
  }, []);

  const handelOnBlur = (e) => {
    let val = parseFloat(e.target.value);
    if (positiveNum && val < 0) {
      message.error(_t("strFieldAcceptsPositive"));
      e.target.value = null;
      return e;
    } else {
      if (val === NaN || val === 0) 
      e.target.value = null;
      else e.target.value = val;
      return e;
    }
  };

  return (
    <Input
      ref={_ref}
      className="TextEditor"
      size="small"
      maxLength={15}
      style={{ width: "100%" }}
      //value={parseFloat(row?.[column?.key])}
      defaultValue={row?.[column?.key]}
      //onChange={(event) => onRowChange({ ...row, [column.key]: parseFloat(event.target.value) })}
      onPressEnter={(event) => {
        onBlur && onBlur(handelOnBlur(event));
      }}
      bordered={false}
      onBlur={(event) => {
        onClose(true);
        onBlur && onBlur(handelOnBlur(event));
      }}
      {...rest}
    />
  );
};
export default CurrEditor;

// import { InputNumber } from "antd";
// import { _ConvToNum } from "../../handleMath";
// const autoFocusAndSelect = (input) => {
//   input?.focus();
//   input?.select();
// };
// const CurrEditor = ({ row, column, onRowChange, onClose, onBlur, ...rest }) => {
//   return (
//     <InputNumber
//       className="TextEditor"
//       size="small"
//       ref={autoFocusAndSelect}
//       style={{ width: "100%" }}
//       value={row?.[column?.key]}
//       type="number"
//       onPressEnter={onBlur && onBlur}
//       bordered={false}
//       controls={false}
//       onBlur={(e) => {
//         onClose(true);
//         onBlur && onBlur(e);
//       }}
//       {...rest}
//     />
//   );
// };
// export default CurrEditor;
