import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Select,
  Empty,
  Row,
  Col,
  Typography,
  Spin,
  Tooltip,
  Card,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import DataTable from "../../helper/gridDataTable/dataTable";
import {
  browseNext,
  browsePrev,
  getByDoc,
  getByCode,
  savePostState,
  initValue,
  getEntSourceHtml,
} from "../../../services/entSourceService";
import { getActiveJournal } from "../../../services/journalService";
import { printPanel } from "../../helper/Print/printPanel";
import {
  LeftOutlined,
  RightOutlined,
  PrinterOutlined,
  LinkOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import moment from "moment";
import { emptValue, emptString } from "../../helper/helperMethods";
import { getNumFormat } from "../../helper/countOfZero";
import { LanguageContext } from "../../../languages/Language";
import {
  sumDiffDebet,
  sumDiffCredit,
  sumDiffCurrDebet,
  sumDiffCurrCredit,
} from "./entFunctions/handleColumns/sumColumns";
import ActionSearch from "../../helper/Modal/actionSearch";
import { _Mul, _Sub, _SumArr } from "../../helper/handleMath";

const EntryBrowseForm = () => {
  const IX_Code = useRef(null);
  const [form] = Form.useForm();
  let { id } = useParams();
  //******************************//
  const [loadData, setLoadData] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [postDate, setPostDate] = useState(null);
  const [post, setPost] = useState(false);
  const [jornalOpt, setJornalOpt] = useState([]);
  const { userLanguage } = useContext(LanguageContext);
  //******************************//
  const { TextArea } = Input;
  const { Search } = Input;
  const { Text } = Typography;

  const columns = [
    {
      name: _t("strDebet"),
      key: "debet",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return <label className="numCell">{p?.row?.debet}</label>;
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalDebet} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalDebet, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffDebet} className="numCell">
            <strong style={{ color: "red" }}>
              {getNumFormat(row.totalDiffDebet, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      name: _t("strCredit"),
      key: "credit",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return <label className="numCell">{p?.row?.credit}</label>;
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCredit} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCredit, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCredit} className="numCell">
            <strong style={{ color: "green" }}>
              {getNumFormat(row.totalDiffCredit, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      name: _t("strAccount"),
      key: "accName",
      visible: true,
      width: "300px",
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <strong style={{ color: "#7e7e7e" }}>{_t("strTotal")}</strong>
        ) : row.id === "total_1" ? (
          <strong style={{ color: "#7e7e7e" }}>{_t("strTheDifference")}</strong>
        ) : (
          ""
        );
      },
    },
    {
      name: _t("strCostCenter"),
      key: "costCentersName",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return (
          <Tooltip
            placement={_t("tooltipPlacement")}
            title={p?.row?.costCentersName}
          >
            <label>{p?.row?.costCentersName}</label>
          </Tooltip>
        );
      },
    },
    {
      name: _t("strNextAcc"),
      key: "accMapsName",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return (
          <Tooltip
            placement={_t("tooltipPlacement")}
            title={p?.row?.accMapsName}
          >
            <label>{p?.row?.accMapsName}</label>
          </Tooltip>
        );
      },
    },
    {
      name: _t("strCurrency"),
      key: "currName",
      visible: true,
      width: "300px",
    },
    {
      name: _t("strRate"),
      key: "currRate",
      visible: true,
      width: "300px",
    },
    {
      name: _t("strCreditRate"),
      key: "currCredit",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return <label className="numCell">{p?.row?.currCredit}</label>;
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCurrCredit} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCurrCredit, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCurrCredit} className="numCell">
            <strong style={{ color: "green" }}>
              {getNumFormat(row.totalDiffCurrCredit, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      name: _t("strDebetRate"),
      key: "currDebet",
      visible: true,
      width: "300px",
      formatter: (p) => {
        return <label className="numCell">{p?.row?.currDebet}</label>;
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCurrDebet} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCurrDebet, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCurrDebet} className="numCell">
            <strong style={{ color: "red" }}>
              {getNumFormat(row.totalDiffCurrDebet, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      name: _t("strDescription"),
      key: "note",
      visible: true,
      width: "300px",
    },
    {
      name: _t("strNote"),
      key: "note1",
      visible: true,
      width: "300px",
    },
  ];

  useEffect(() => {
    async function feachData() {
      const data = await handleJornal("");
      if (id !== undefined) {
        initVal(id);
      } else {
        initNewVal(data);
      }
    }
    feachData();
  }, [id]);

  const initNewVal = async (data) => {
    try {
      setLoadData(true);
      if (data?.length === 1) {
        const { data: result } = await browsePrev(
          data[0].id,
          form.getFieldValue("id")
        );
        if (result.data !== null) {
          fillData(result);
        }
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const initVal = async (id) => {
    try {
      setLoadData(true);
      const { data: result } = await initValue(id);
      if (result.data !== null) {
        fillData(result);
      } else {
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const next = async () => {
    try {
      setLoadData(true);
      if (form.getFieldValue("journalId") !== undefined) {
        let jor = form.getFieldValue("journalId");
        let jid = form.getFieldValue("id");

        const { data: result } = await browseNext(jor, jid);

        if (result.data !== null) {
          fillData(result);
        } else {
          message.info(_t("msgNoRecords"));
        }
      } else {
        form.resetFields();
        setDataSource([]);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const prev = async () => {
    try {
      setLoadData(true);
      if (form.getFieldValue("journalId") !== undefined) {
        let jor = form.getFieldValue("journalId");
        let jid = form.getFieldValue("id");     
        const { data: result } = await browsePrev(jor, jid);
        if (result?.data !== null) {
          fillData(result);
        } else {
          form.setFieldsValue({
            id: undefined,
            currName: "",
            currRate: "",
            date: "",
            doc: "",
            note: "",
            noteGen: "",
          });
          form.setFieldsValue({

          })
          setDataSource([]);
          message.info(_t("msgNoRecords"));
        }
      } else {
        form.resetFields();
        setDataSource([]);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const searchCode = async (popS) => {
    try {
      setLoadData(true);
      if (emptString(form.getFieldValue().code, null) !== null) {
        setLoadData(true);
        if (form.getFieldValue("journalId") !== undefined) {
          const { data: result } = await getByCode(
            form.getFieldValue("journalId"),
            popS !== undefined && typeof popS === "string"
              ? popS
              : form.getFieldValue().code
          );

          if (result.data !== null) {
            fillData(result);
          } else {
            message.info(_t("msgNoRecords"));
            form.setFieldsValue({
              id: undefined,
              currName: "",
              currRate: "",
              date: "",
              doc: "",
              note: "",
              noteGen: "",
              // journalId:undefined
            });
            setPost(false);
            setPostDate(null);
            setParentName(null);
            setParentUrl(null);
            handleJornal("");
            setDataSource([]);
          }
        } else {
          form.resetFields();
          setDataSource([]);
        }
        setLoadData(false);
      } else {
        message.error(_t("strInsertValueforSearch"));
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const searchDoc = async (popS) => {
    try {
      setLoadData(true);
      if (emptString(form.getFieldValue().doc, null) !== null) {
        setLoadData(true);
        if (form.getFieldValue("journalId") !== undefined) {
          const { data: result } = await getByDoc(
            form.getFieldValue("journalId"),
            popS !== undefined ? popS : form.getFieldValue().doc
          );
          if (result.data !== null) {
            fillData(result);
          } else {
            message.info(_t("msgNoRecords"));
            form.setFieldsValue({
              id: undefined,
              currName: "",
              currRate: "",
              date: "",
              code: "",
              note: "",
              noteGen: "",
              //  journalId:undefined
            });
            setPost(false);
            setPostDate(null);
            setParentName(null);
            setParentUrl(null);
            handleJornal("");
            setDataSource([]);
          }
        } else {
          form.resetFields();
          setDataSource([]);
        }
        setLoadData(false);
      } else {
        message.error(_t("strInsertValueforSearch"));
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const onPrint = async (obj) => {
    try {
      setLoadData(true);
      const data = await getEntSourceHtml(
        form.getFieldValue().id,
        new Date().getTimezoneOffset(),
        userLanguage === "ar" ? 0 : 1
      );
      printPanel(data.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const [parentName, setParentName] = useState(null);
  const [parentUrl, setParentUrl] = useState(null);
  const [resp, setResp] = useState(null);

  const fillData = (newData) => {
    form.setFieldsValue({
      id:
        newData.data?.id !== null &&
        newData.data?.id !== "00000000-0000-0000-0000-000000000000"
          ? newData.data?.id
          : "",
      code: newData.data?.code !== null ? newData.data?.code : "",
      currName: newData.data?.currName !== null ? newData.data?.currName : "",
      journalId:
        newData.data?.journalId !== null &&
        newData.data?.journalId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data?.journalId
          : "",
      currRate: newData.data?.currRate !== null ? newData.data?.currRate : "",
      date:
        newData.data?.date !== null
          ? moment(newData.data?.date).format("DD/MM/YYYY - h:mm a")
          : "",
      doc: newData.data?.doc !== null ? newData.data?.doc : "",
      note: newData.data?.note !== null ? newData.data?.note : "",
      noteGen: newData.data?.noteGen !== null ? newData.data?.noteGen : "",
    });

    setPost(newData.data.post);
    setPostDate(newData.data.postDate);
    setParentName(newData.data?.parentName);

    setParentUrl(newData.data?.parentUrl);
    setDataSource(newData.data?.details);
    // handleJornal("");
  };

  useEffect(() => {
    setPost(resp?.data?.post);
    setPostDate(resp?.data?.postDate);
  }, [resp]);

  const handlePost = async (state) => {
    try {
      const { data: data } = await savePostState(
        form.getFieldValue("id"),
        !state
      );
      if (data.code === 200) {
        setResp(data);
        message.success(data.message, 3);
      } else if (data.code === 208) {
        // already exists
        setResp(data);
        message.warning(data.message, 3);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const sumDebet = () => {
    let sum = 0;
    //  for (let i = 0; i < dataSource.length; i++) {
    // sum += parseFloat(emptValue(dataSource[i].debet.replace(/,/g, ""), 0));
    sum = _SumArr(dataSource, "debet");

    //  }
    return sum;
  };
  const sumCredit = () => {
    let sum = 0;
    //  for (let i = 0; i < dataSource.length; i++) {
    // sum += parseFloat(emptValue(dataSource[i].credit.replace(/,/g, ""), 0));
    sum = _SumArr(dataSource, "credit");
    // }
    return sum;
  };
  const sumCurrCredit = () => {
    let sum = 0;
    // for (let i = 0; i < dataSource.length; i++) {
    // sum += parseFloat(
    //   emptValue(dataSource[i].currCredit.replace(/,/g, ""), 0)
    // );
    sum = _SumArr(dataSource, "currCredit");
    // }
    return sum;
  };
  const sumCurrDebet = () => {
    let sum = 0;
    // for (let i = 0; i < dataSource.length; i++) {
    // sum += parseFloat(
    //   emptValue(dataSource[i].currDebet.replace(/,/g, ""), 0)
    // );
    sum = _SumArr(dataSource, "currDebet");
    // }
    return sum;
  };

  const sumDiffDebet = () => {
    let _debet = sumDebet();
    let _credit = sumCredit();
    let result = _Sub(_debet, _credit) < 0 ? _Sub(_debet, _credit) : undefined;
    if (result < 0) {
      return _Mul(result, -1);
    } else {
      return undefined;
    }
  };
  const sumDiffCredit = () => {
    let _debet = sumDebet();
    let _credit = sumCredit();
    let result = _Sub(_debet, _credit) > 0 ? _Sub(_debet, _credit) : undefined;
    return result;
  };

  const sumDiffCurrCredit = () => {
    let env = _Sub(sumCurrCredit(), sumCurrDebet());
    if (env < 0) {
      return _Mul(env, -1);
    } else {
      return undefined;
    }
  };

  const sumDiffCurrDebet = () => {
    let env = _Sub(sumCurrCredit(), sumCurrDebet());
    if (env > 0) {
      return env;
    } else {
      return undefined;
    }
  };

  const summaryRows = useMemo(() => {
    const summaryRow = [
      {
        id: "total_0",
        totalCount: dataSource.length,
        totalDebet: sumDebet(),
        totalCredit: sumCredit(),
        totalCurrCredit: sumCurrCredit(),
        totalCurrDebet: sumCurrDebet(),
      },
      {
        id: "total_1",
        totalDiffDebet: sumDiffDebet(),
        totalDiffCredit: sumDiffCredit(),
        totalDiffCurrCredit: sumDiffCurrCredit(),
        totalDiffCurrDebet: sumDiffCurrDebet(),
      },
    ];
    return summaryRow;
  }, [dataSource]);

  //******************************// //*******************// Form Code //*******************// //******************************//

  const handleJornal = async (_Search) => {
    try {
      const { data: responce } = await getActiveJournal(_Search, "");
      setJornalOpt(responce.data);
      return responce.data;
    } catch (error) {
      handleEx(error, { IX_Code });
    } finally {
      setLoadData(false);
    }
  };

  const jornalOptList =
    jornalOpt.length > 0 &&
    jornalOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const handleSearch = (data, type) => {
    if (type === 1) {
      searchCode(data);
    } else if (type === 2) {
      searchDoc(data);
    }
  };

  return (
    <div key="mainDiv">
      <ActionSearch
        showModal={showSearch}
        disableDoc={true}
        setShowModal={setShowSearch}
        handleData={(e, type) => {
          handleSearch(e, type);
        }}
      />
      <React.Fragment>
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            layout="horizontal"
            labelCol={{
              flex: "100px",
            }}
            wrapperCol={{
              span: 23,
            }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={12}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strEntries")}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {_t("strEntryBrowse")}
                      </Text>
                      <Form.Item name="journalId" noStyle>
                        <Select
                          allowClear
                          showSearch
                          bordered={false}
                          style={{ width: 120 }}
                          filterOption={false}
                          onSearch={(value) => {
                            handleJornal(value);
                          }}
                          onChange={() => {
                            prev();
                          }}
                          placeholder={_t("strJournal")}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          dropdownStyle={{ minWidth: 100 }}
                          size="small"
                        >
                          {jornalOptList}
                        </Select>
                      </Form.Item>
                      <Space>
                        <Button
                          size="small"
                          onClick={() =>
                            form.getFieldValue("journalId") === undefined
                              ? message.info(_t("msgSelectJor"))
                              : prev()
                          }
                        >
                          {_t("langDiriction") === "rtl" ? (
                            <RightOutlined />
                          ) : (
                            <LeftOutlined />
                          )}
                        </Button>

                        <Button
                          size="small"
                          icon={<SearchOutlined />}
                          onClick={() => {
                            setShowSearch(true);
                          }}
                        />

                        <Button
                          size="small"
                          onClick={() =>
                            form.getFieldValue("journalId") === undefined
                              ? message.info(_t("msgSelectJor"))
                              : next()
                          }
                        >
                          {_t("langDiriction") === "rtl" ? (
                            <LeftOutlined />
                          ) : (
                            <RightOutlined />
                          )}
                        </Button>
                      </Space>
                    </Space>

                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        ref={IX_Code}
                        bordered={false}
                        onPressEnter={() => searchCode()}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                {form.getFieldValue("id") !== undefined && (
                  <Col
                    xs={24}
                    md={12}
                    style={{ textAlign: _t("txtRevDiriction") }}
                  >
                    <Space split="/">
                      {emptString(parentUrl, null) !== null && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={parentUrl}
                        >
                          <LinkOutlined />
                          {parentName}
                        </Link>
                      )}
                      <Popconfirm
                        title={_t("msgAskChangeStatus")}
                        onConfirm={() => handlePost(post)}
                      >
                        <Button
                          style={{
                            padding: 0,
                            color: post === true ? "green" : "red",
                          }}
                          size="small"
                          type="text"
                        >
                          {post === true
                            ? _t("strDeported")
                            : _t("strNotDeported")}{" "}
                          {postDate !== null
                            ? ":" +
                              moment(postDate).format("DD/MM/YYYY - h:mm a")
                            : ""}
                        </Button>
                      </Popconfirm>
                    </Space>
                  </Col>
                )}
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Form.Item name="id" noStyle hidden>
                      <Input />
                    </Form.Item>
                    <Col xs={24} md={6}>
                      <Form.Item name="doc" label={_t("strDocument")}>
                        <Search
                          placeholder={_t("strDocument")}
                          maxLength={200}
                          autoComplete="off"
                          size="small"
                          onSearch={searchDoc}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Form.Item name="currName" label={_t("strCurrency")}>
                        <Input
                          style={{ color: "#444444" }}
                          disabled
                          bordered={false}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                      <Form.Item name="currRate" label={_t("strRate")}>
                        <Input
                          style={{ color: "#444444" }}
                          disabled
                          bordered={false}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                      <Form.Item name="date" label={_t("strDate")}>
                        <Input
                          style={{ color: "#444444" }}
                          disabled
                          bordered={false}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        labelCol={{
                          flex: "100px",
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <Card
                          bodyStyle={{ padding: 0 }}
                          style={{ marginBottom: 10 }}
                        >
                          <DataTable
                            columns={columns}
                            dataSource={dataSource}
                            tableName="entryBrowse"
                            tree={false}
                            style={{
                              height: `calc(100vh - 330px)`,
                              width: "100%",
                            }}
                            frezzColExp={false}
                            isPagination={false}
                            colExp={0}
                            summary={summaryRows}
                            pageName={_t("strEntryBrowse")}
                            groupName={_t("strEntries")}
                            type="page"
                            handleReload={searchCode}
                          />
                        </Card>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="note" label={_t("strNote")}>
                        <TextArea
                          style={{ color: "#444444" }}
                          disabled
                          bordered={false}
                          maxLength={1000}
                          autoComplete="off"
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="noteGen" label={_t("strNote2")}>
                        <TextArea
                          style={{ color: "#444444" }}
                          disabled
                          bordered={false}
                          maxLength={1000}
                          autoComplete="off"
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="middle">
                  <Button
                    type="text"
                    icon={<PrinterOutlined />}
                    onClick={() => onPrint()}
                    disabled={
                      form.getFieldValue().id === "" ||
                      form.getFieldValue().id === undefined
                        ? true
                        : false
                    }
                    size="small"
                  >
                    {_t("strPrint")}
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default EntryBrowseForm;
