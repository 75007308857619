import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Table, Modal, Space } from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getInStockInfo } from "../../../services/Items/itemService";
//import { getItemUnitById } from "../../../services/Items/itemUnitService";

import Draggable from "react-draggable";
import DataTable from "../../helper/gridDataTable/dataTable";

const CheckQtyItems = ({
  itemUnitMapId,
  mainDate,
  itemName = "unkow",
  showModal,
  setShowModal,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);

  const columns = [
    {
      key: "itemName",
      name: _t("strItem"),
      visible: true,
    },
    {
      key: "storeName",
      name: _t("strStore"),
      visible: true,
    },
    {
      key: "unitName",
      name: _t("strUnit"),
      visible: true,
    },
    {
      key: "qty",
      name: _t("strQuantity"),
      visible: true,
    },
  ];

  const itemTitle = (
    <Space>
      <label style={{ fontSize: 16, fontWeight: 700 }}>{_t("strItem")}:</label>
      <label style={{ fontSize: 16, fontWeight: 700 }}>{itemName}</label>
      <label style={{ fontSize: 16, fontWeight: 700 }}>/</label>
      <label style={{ fontSize: 16, fontWeight: 700 }}>{_t("strUnit")}:</label>
      <label style={{ fontSize: 16, fontWeight: 700 }}>{dataSource[0]?.unitName}</label>
    </Space>
  );

  const realodTable = () => {
    setLoadData(true);
    searchData();
  };

  const searchData = async () => {
    try {
      if (itemUnitMapId !== "" && mainDate !== "") {
        const { data: result } = await getInStockInfo(itemUnitMapId, mainDate);

        setDataSource(result.data);
        // const { data: newData } = await getItemUnitById(itemUnitMapId);
        //
        // setUnitName(newData.data);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    setLoadData(true);
    searchData();
  }, [showModal === true]);

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setLoadData(false);
    }, 50);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const [disabled, setDisabled] = useState(true);
  return (
    <Modal
      centered
      width={800}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strQTY")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={[]}
    >
      <Form form={popupForm}>
        <Form.Item>
          <DataTable
            style={{ height: "calc(85vh - 195px)", width: "100%" }}
            columns={columns}
            showPath={false}
            showTableButtons={true}
            dataSource={dataSource}
            isPagination={false}
            tableName="popup_itemQty"
            tree={true}
            frezzColExp={false}
            actionButtons={itemTitle}
            handleReload={realodTable}
            pageName={_t("strQTY")}
            groupName={_t("strReports")}
            type="rep"
            loading={loadData}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CheckQtyItems;
