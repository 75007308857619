import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Row,
  Col,
  Empty,
  Spin,
  Typography,
  DatePicker,
  Select,
  Popconfirm,
  Tag,
} from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getCompanyById,
  editCompany,
  deleteImage,
} from "../../../services/companyService";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import _t from "../../../languages/translate";
import UploadImg from "../../upload/uploadImg";
import { handleEx } from "../../helper/handleException";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import { shortCut } from "../../helper/shortCuts";
import TextArea from "antd/lib/input/TextArea";
const Companies = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const refFrom = useRef(null);
  const refTo = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [currencyOpt, setCurrencyOptOpt] = useState([]);
  const [avatarPath, setAvatarPath] = useState();
  const [imageActive, setImageActive] = useState(true);

  const [statusTo, setStatusTo] = useState("");
  const [statusFrom, setStatusFrom] = useState("");

  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const { Text } = Typography;

  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));

  // useEffect(() => {
  //     const handleKeydownOnView = (e) => {
  //       switch (e.keyCode) {
  //         case 18 && 83:
  //           alert()
  //           break;
  //       }
  //     };
  //     window.addEventListener("keydown", handleKeydownOnView);

  //     return () => {
  //       window.removeEventListener("keydown", handleKeydownOnView);
  //     };
  //   }, []);

  const codeGenerator = async () => {
    //   const { data: newData } = await getNewItemUnitCode();
    // form.setFieldsValue({ code: newData.data });
    IX_Name.current.focus();
    setLoadData(false);
  };

  useEffect(() => {
    if (avatarPath === undefined || avatarPath === null) {
      setImageActive(true);
    } else {
      setImageActive(false);
    }
  }, [avatarPath, setAvatarPath]);

  useEffect(() => {
    async function fetchData() {
      if (id === "new") {
        return await codeGenerator();
      } else {
        try {
          handleSearchCurrency("");
          debugger
          const { data } = await getCompanyById(id);
          debugger
         await setAvatarPath(data.data.logo);

          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            email: data.data.email,
            currDefId:
              data.data.currDefId === null ? undefined : data.data.currDefId,

            endFiscalYearDate:
              data.data.endFiscalYearDate !== null
                ? moment(data.data.endFiscalYearDate)
                : "",

            fiscalYearName: data.data.fiscalYearName,
            phone: data.data.phone,
            startFiscalYearDate:
              data.data.startFiscalYearDate !== null
                ? moment(data.data.startFiscalYearDate)
                : "",
            closePeriodDate:
              data.data.closePeriodDate !== null
                ? moment(data.data.closePeriodDate)
                : "",

            taxNum: data.data.taxNum,
            website: data.data.website,
            address: data.data.address,
            name: data.data.name,
            note: data.data.note,
          });
          IX_Name.current.focus();
          setLoadData(false);
        } catch (error) {
          handleEx(error, { IX_Code, IX_Name });
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData, form]);

  const onFinish = async (values) => {
    try {
      values.id = id;
      values.logo = avatarPath;
      debugger
      if (
        values.startFiscalYearDate !== "" &&
        values.startFiscalYearDate !== undefined&&
        values.startFiscalYearDate !== null
      ) {
        values.startFiscalYearDate =
          values.startFiscalYearDate?.toISOString().slice(0, 16) + "Z";
      }
      if (
        values.endFiscalYearDate !== "" &&
        values.endFiscalYearDate !== undefined&&
        values.endFiscalYearDate !== null
      ) {
        values.endFiscalYearDate =
          values.endFiscalYearDate?.toISOString().slice(0, 16) + "Z";
      }
      if (
        values.closePeriodDate !== "" &&
        values.closePeriodDate !== undefined &&
        values.closePeriodDate !== null
      ) {
        values.closePeriodDate =
          values.closePeriodDate?.toISOString().slice(0, 16) + "Z";
      }

      const data = await editCompany(values);
      setProChange(false);
      message.success(data.data.message, 3);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
  };

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };

  const onImageDelete = async () => {
    try {
      const res = await deleteImage(id !== "new" ? id : "", avatarPath);
      message.success(res.data.message, 3);
      setImageActive(true);
      setAvatarPath(undefined);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOptOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const handleDate = (field) => {
    const from = form.getFieldValue("startFiscalYearDate");
    const to = form.getFieldValue("endFiscalYearDate");
    if (to !== null && from !== null) {
      if (to < from) {
        message.error(_t("strErrorDateSelected"));
        if (field === "from") {
          refFrom.current.focus();
          setStatusFrom("error");
        } else {
          refTo.current.focus();
          setStatusTo("error");
        }
      } else {
        setStatusFrom("");
        setStatusTo("");
      }
    } else {
      setStatusFrom("");
      setStatusTo("");
    }
  };

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="generalRolesform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{
            flex: "150px",
          }}
          wrapperCol={{
            span: 23,
          }}
        >
          <div name="code" className="frHeader">
            <Row>
              <Col xs={24} md={24}>
                <Space split="#">
                  <Space split="\">
                    <Text>{_t("strDefinitions")}</Text>
                    <Text style={{ fontWeight: 700 }}>
                      {_t("strCompanies")}
                    </Text>
                  </Space>
                  <div>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Code}
                        bordered={false}
                      />
                    </Form.Item>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + F
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </div>

          <Row gutter={3}>
            <Col flex="0 1 100px">
              <div
                name="logoImage"
                className="frlogoImage"
                style={{ padding: "1.7rem 24px 4.6rem 24px" }}
              >
                <Form.Item name="logo">
                  <UploadImg
                    previewImg={avatarPath}
                    setPreviewImg={setAvatarPath}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Form.Item>
                <Popconfirm
                  placement="bottomLeft"
                  title={_t("msgAreYouSureDeleteImage?")}
                  onConfirm={onImageDelete}
                  okText={_t("strYes")}
                  cancelText={_t("strNo")}
                  disabled={imageActive}
                >
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    disabled={imageActive}
                  >
                    {_t("strRemoveImage")}
                  </Button>
                </Popconfirm>
              </div>
            </Col>
            <Col flex="1 1 400px">
              <div name="field" className="frContent">
                <Row gutter={12}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label={
                        <div>
                          {_t("strName")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 4,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 1
                            </Tag>
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strName")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Name}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="currDefId"
                      label={_t("strCurrency")}
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        showSearch
                        onDropdownVisibleChange={() => handleSearchCurrency("")}
                        filterOption={false}
                        onSearch={(value) => {
                          handleSearchCurrency(value);
                        }}
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }
                        placeholder={_t("strNotSelected")}
                        size="small"
                      >
                        {currencyOptList}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="email"
                      label={_t("strEmail")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strEmail")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="taxNum"
                      label={_t("strTaxNum")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strTaxNum")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="website"
                      label={_t("strWebsite")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strWebsite")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="fiscalYearName"
                      label={_t("strFiscalYearName")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strFiscalYearName")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="startFiscalYearDate"
                      label={_t("strStartFiscalYearDate")}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        ref={refFrom}
                        status={statusFrom}
                        onChange={() => handleDate("from")}
                        showTime={{ format: "HH:mm" }}
                        format={dateFormatList}
                        className="dateTimeStyle"
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="endFiscalYearDate"
                      label={_t("strEndFiscalYearDate")}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        ref={refTo}
                        status={statusTo}
                        onChange={() => handleDate("to")}
                        showTime={{ format: "HH:mm" }}
                        format={dateFormatList}
                        className="dateTimeStyle"
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="closePeriodDate"
                      label={_t("strClosePeriodDate")}
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        ref={refTo}
                        status={statusTo}
                        showTime={{ format: "HH:mm" }}
                        format={dateFormatList}
                        className="dateTimeStyle"
                        size="small"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="address"
                      label={_t("strAddress")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom1-1000")}` },
                        { max: 1000, message: `${_t("strFrom1-1000")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strAddress")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="phone"
                      label={_t("strPhone")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        size="small"
                        placeholder={_t("strPhone")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={24}>
                    <Form.Item
                      name="note"
                      label={_t("strNote")}
                      rules={[
                        {
                          required: false,
                          message: `${_t("strIsRequired")}`,
                        },
                        { min: 1, message: `${_t("strFrom1-1000")}` },
                        { max: 1000, message: `${_t("strFrom1-1000")}` },
                      ]}
                    >
                      <TextArea
                        size="small"
                        placeholder={_t("strNote")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="frFooter">
            <Form.Item>
              <Space size="large">
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    size="small"
                    style={{ width: 150, borderRadius: 5 }}
                  >
                    {_t("strSave")}
                  </Button>
                  <div
                    style={{
                      opacity: 0.8,
                      zIndex: 99,
                      position: "absolute",
                      top: 24,
                    }}
                  >
                    <Tag color="#2b2b2b" hidden={!altPress}>
                      alt + S
                    </Tag>
                  </div>
                </div>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

export default Companies;
