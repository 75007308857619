import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  Row,
  Col,
  Checkbox,
  Tabs,
  Spin,
  message,
  Alert,
  InputNumber,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";
import SelectSingle from "../../../helper/selectSingle";
import { GetActiveChildren } from "../../../../services/AccountService";
import { getClientActiveSearch } from "../../../../services/clientService";
import { getActivePayMethodSearch } from "../../../../services/Items/payMethodService";
import { GetActiveStoreChildren } from "../../../../services/storeService";
import { getCurrActiveSearch } from "../../../../services/Items/currenciesService";
import { getActivePriceMethodSearch } from "../../../../services/Items/pricingMethodsService";
import { getActiveJournal } from "../../../../services/journalService";
import { emptArr, emptString, emptValue } from "../../../helper/helperMethods";
import CostCenterInput from "../../../helper/Input/costCenterInput";
import { calcAndReturnList } from "../../../helper/Modal/costCenterModal/costCenterHelper";
import { convertFromSeve } from "../../../helper/gridSettTable/components/helperMethods";
import { checkInvType } from "./showFieldByType";
import AddTableSett from "./popups/addTableSett";
import DiscTableSett from "./popups/discTableSett";
import InvTableSett from "./popups/mainTableSett";
import { handleEx } from "../../../helper/handleException";
import SelectAccount from "../../../helper/inputSelect/selectAccount";

const PattSettings = ({ form, dataObj, selectedTypeId, setLoadData }) => {
  // const [loadData, setLoadData] = useState(false);
  const [showField, setShowField] = useState(undefined);
  // main table-----------
  const [showInvColSett, setShowInvColSett] = useState(false);
  const [mainDataSource, setMainDataSource] = useState([]);
  const [mainColumns, setMainColumns] = useState([]);
  // disc table-----------
  const [showDiscColSett, setShowDiscColSett] = useState(false);
  const [discDataSource, setDiscDataSource] = useState([]);
  const [discColumns, setDiscColumns] = useState([]);
  // add table-----------
  const [showAddColSett, setShowAddColSett] = useState(false);
  const [addDataSource, setAddDataSource] = useState([]);
  const [addColumns, setAddColumns] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  // cost center-----------
  const [clientOptReqCost, setClientOptReqCost] = useState(0);
  const [invOptReqCost, setInvOptReqCost] = useState(0);
  const [cashOptReqCost, setCashOptReqCost] = useState(0);
  const [addOptReqCost, setAddOptReqCost] = useState(0);
  const [invAddOptReqCost, setInvAddOptReqCost] = useState(0);

  const [itemDiscOptReqCost, setItemDiscOptReqCost] = useState(0);
  const [invDiscOptReqCost, setInvDiscOptReqCost] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await setLoadData(true);
      await handleAccActSearch();
      await handlePayMethodSearch();
      await handleClientSearch();
      await handleStoreSearch();
      await handleSearchCurrency();
      await handlePriceMethodSearch();
      await handleJornal();
      const obj = await checkInvType(selectedTypeId);
      window.sessionStorage.setItem("showGenEnt", obj?.showGenEnt);
      await setShowField(obj);
      if (dataObj !== undefined) {
        await fillData(dataObj);
      }
      await setLoadData(false);
    };
    fetchData();
  }, [dataObj]);

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      const arr = costList.map((e) => {
        e.value = 0;
        return e;
      });
      const reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, arr, emptValue(reqOpt, 0))?.text;
    }
  };

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const fillData = async (data) => {
    const accList = await handleAccAct();
    const showGenEnt = JSON.parse(window.sessionStorage.getItem("showGenEnt"));
    await form.setFieldsValue({
      genEnt: showGenEnt ? data?.data?.genEnt : false,
      branchId: emptString(data?.data?.branchId, undefined),
      invPattId: emptString(data?.data?.invPattId, undefined),
      addItemAccVisible: data?.data?.addItemAccVisible,
      addItemAccEnable: data?.data?.addItemAccEnable,
      addItemAccDefId: emptString(data?.data?.addItemAccDefId, undefined),
      clientVisible: data?.data?.clientVisible,
      clientReq: data?.data?.clientReq,
      clientEnable: data?.data?.clientEnable,
      clientDefId: emptString(data?.data?.clientDefId, undefined),
      discItemAccVisible: data?.data?.discItemAccVisible,
      discItemAccEnable: data?.data?.discItemAccEnable,
      itemDiscTotalVisible: data?.data?.itemDiscTotalVisible,
      itemAddTotalVisible: data?.data?.itemAddTotalVisible,
      discItemAccDefId: emptString(data?.data?.discItemAccDefId, undefined),
      addInvAccVisible: data?.data?.addInvAccVisible,
      addInvAccEnable: data?.data?.addInvAccEnable,
      addInvDist: data?.data?.addInvDist,
      addInvDistEnable: data?.data?.addInvDistEnable,
      addInvAccDefId: emptString(data?.data?.addInvAccDefId, undefined),
      invAddTotalVisible: data?.data?.invAddTotalVisible,
      itemSubTotalVisible: data?.data?.itemSubTotalVisible,
      clientAccVisible: data?.data?.clientAccVisible,
      clientAccEnable: data?.data?.clientAccEnable,
      clientAccReq: data?.data?.clientAccReq,
      clientAccDefId: emptString(data?.data?.clientAccDefId, undefined),
      invDiscTotalVisible: data?.data?.invDiscTotalVisible,
      discInvAccVisible: data?.data?.discInvAccVisible,
      discInvAccEnable: data?.data?.discInvAccEnable,
      discInvDist: data?.data?.discInvDist,
      discInvDistEnable: data?.data?.discInvDistEnable,
      discInvAccDefId: emptString(data?.data?.discInvAccDefId, undefined),
      netTotalVisible: data?.data?.netTotalVisible,
      calcPayTotalVisible: data?.data?.calcPayTotalVisible,
      payTotalVisible: data?.data?.payTotalVisible,
      restTotalVisible: data?.data?.restTotalVisible,
      dateEnable: data?.data?.dateEnable,
      addInvAccTableVisible: data?.data?.addInvAccTableVisible,
      discInvAccTableVisible: data?.data?.discInvAccTableVisible,
      payValueVisible: data?.data?.payValueVisible,
      cashAccDefId: emptString(data?.data?.cashAccDefId, undefined),
      cashAccVisible: data?.data?.cashAccVisible,
      cashAccEnable: data?.data?.cashAccEnable,
      payMethodVisible: data?.data?.payMethodVisible,
      payMethodEnable: data?.data?.payMethodEnable,
      payMethodReq: data?.data?.payMethodReq,
      payMethodDefId: emptString(data?.data?.payMethodDefId, undefined),
      storeVisible: data?.data?.storeVisible,
      storeEnable: data?.data?.storeEnable,
      storeDefId: emptString(data?.data?.storeDefId, undefined),
      dueDateVisible: data?.data?.dueDateVisible,
      dueDateEnable: data?.data?.dueDateEnable,
      dueDateReq: data?.data?.dueDateReq,
      toStoreVisible: data?.data?.toStoreVisible,
      toStoreEnable: data?.data?.toStoreEnable,
      toStoreReq: data?.data?.toStoreReq,
      toStoreDefId: emptString(data?.data?.toStoreDefId, undefined),
      totalQtyTableVisible: data?.data?.totalQtyTableVisible,
      totalQtyVisible: data?.data?.totalQtyVisible,
      itemTotalVisible: data?.data?.itemTotalVisible,
      invAccVisible: data?.data?.invAccVisible,
      invAccEnable: data?.data?.invAccEnable,
      invAccReq: data?.data?.invAccReq,
      invAccDefId: emptString(data?.data?.invAccDefId, undefined),
      currVisible: data?.data?.currVisible,
      currEnable: data?.data?.currEnable,
      currChType: data?.data?.currChType,
      currDefId: emptString(data?.data?.currDefId, undefined),
      priceMethodVisible: data?.data?.priceMethodVisible,
      priceMethodEnable: data?.data?.priceMethodEnable,
      priceMethodDefId: emptString(data?.data?.priceMethodDefId, undefined),
      docVisible: data?.data?.docVisible,
      docReq: data?.data?.docReq,
      codeStart: data?.data?.codeStart,
      codeEnable: data?.data?.codeEnable,
      postEntVisible: data?.data?.postEntVisible,
      postEntEnable: data?.data?.postEntEnable,
      postEnt: data?.data?.postEnt,
      oprStatusVisible: data?.data?.oprStatusVisible,
      oprStatusEnable: data?.data?.oprStatusEnable,
      oprStatusDefId: emptString(data?.data?.oprStatusDefId, undefined),
      genShortEnt: data?.data?.genShortEnt,
      genEntFromTotal: data?.data?.genEntFromTotal,
      journalId: emptString(data?.data?.journalId, undefined),
      accStatusVisible: data?.data?.accStatusVisible,
      costEffect: data?.data?.costEffect,
      lastPurEffect: data?.data?.lastPurEffect,
      lastSalesEffect: data?.data?.lastSalesEffect,
      lastClientEffect: data?.data?.lastClientEffect,
      isStoreEffect: data?.data?.isStoreEffect,
      detailsColSett: data?.data?.detailsColSett,
      addsColSett: data?.data?.addsColSett,
      discsColSett: data?.data?.discsColSett,

      // invAccCostCenter_Maps: data?.data?.invAccCostCenter_Maps,
      invAccCostCenterVisible: data?.data?.invAccCostCenterVisible,
      invAccCostCenterEnable: data?.data?.invAccCostCenterEnable,
      invAccCostCenterReq: data?.data?.invAccCostCenterReq,

      //  clientAccCostCenter_Maps: data?.data?.clientAccCostCenter_Maps,
      clientAccCostCenterVisible: data?.data?.clientAccCostCenterVisible,
      clientAccCostCenterEnable: data?.data?.clientAccCostCenterEnable,
      clientAccCostCenterReq: data?.data?.clientAccCostCenterReq,

      // cashAccCostCenter_Maps: data?.data?.cashAccCostCenter_Maps,
      cashAccCostCenterVisible: data?.data?.cashAccCostCenterVisible,
      cashAccCostCenterEnable: data?.data?.cashAccCostCenterEnable,
      cashAccCostCenterReq: data?.data?.cashAccCostCenterReq,

      // itemAddCostCenter_Maps: data?.data?.itemAddCostCenter_Maps,
      addItemAccCostCenterVisible: data?.data?.addItemAccCostCenterVisible,
      addItemAccCostCenterEnable: data?.data?.addItemAccCostCenterEnable,
      addItemAccCostCenterReq: data?.data?.addItemAccCostCenterReq,

      // itemDiscCostCenter_Maps: data?.data?.itemDiscCostCenter_Maps,
      discItemAccCostCenterVisible: data?.data?.discItemAccCostCenterVisible,
      discItemAccCostCenterEnable: data?.data?.discItemAccCostCenterEnable,
      discItemAccCostCenterReq: data?.data?.discItemAccCostCenterReq,

      //invAddCostCenter_Maps: data?.data?.invAddCostCenter_Maps,
      addInvAccCostCenterVisible: data?.data?.addInvAccCostCenterVisible,
      addInvAccCostCenterEnable: data?.data?.addInvAccCostCenterEnable,
      addInvAccCostCenterReq: data?.data?.addInvAccCostCenterReq,

      // invDiscCostCenter_Maps: data?.data?.invDiscCostCenter_Maps,
      discInvAccCostCenterVisible: data?.data?.discInvAccCostCenterVisible,
      discInvAccCostCenterEnable: data?.data?.discInvAccCostCenterEnable,
      discInvAccCostCenterReq: data?.data?.discInvAccCostCenterReq,

      invAddCostCenter_Maps: data?.data?.invAddCostCenter_Maps,
      invAddCostCenter: loadCostCenterData(
        data?.data?.invAddCostCenter_Maps,
        data?.data?.addInvAccDefId,
        accList
      ),

      invDiscCostCenter_Maps: data?.data?.invDiscCostCenter_Maps,
      invDiscCostCenter: loadCostCenterData(
        data?.data?.invDiscCostCenter_Maps,
        data?.data?.discInvAccDefId,
        accList
      ),

      invAccCostCenter_Maps: data?.data?.invAccCostCenter_Maps,
      invAccCostCenter: loadCostCenterData(
        data?.data?.invAccCostCenter_Maps,
        data?.data?.invAccId,
        accList
      ),

      clientAccCostCenter_Maps: data?.data?.clientAccCostCenter_Maps,
      clientAccCostCenter: loadCostCenterData(
        data?.data?.clientAccCostCenter_Maps,
        data?.data?.clientAccDefId,
        accList
      ),

      cashAccCostCenter_Maps: data?.data?.cashAccCostCenter_Maps,
      cashAccCostCenter: loadCostCenterData(
        data?.data?.cashAccCostCenter_Maps,
        data?.data?.payAccId,
        accList
      ),

      itemAddCostCenter_Maps: data?.data?.itemAddCostCenter_Maps,
      itemAddCostCenter: loadCostCenterData(
        data?.data?.itemAddCostCenter_Maps,
        data?.data?.itemAddAccId,
        accList
      ),

      itemDiscCostCenter_Maps: data?.data?.itemDiscCostCenter_Maps,
      itemDiscCostCenter: loadCostCenterData(
        data?.data?.itemDiscCostCenter_Maps,
        data?.data?.itemDiscAccId,
        accList
      ),
    });

    if (
      emptArr(accOpt, null) !== null &&
      data?.data?.clientAccDefId !== undefined
    ) {
      let test = accOpt?.map(
        (e) => e?.id === data?.data?.clientAccDefId
      )?.costCenterReqOpt;
    }
  };

  const onTabsChange = (key) => {
    setActiveKey(key);
  };

  const handleShowMainInvTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("detailsColSett"));
    setMainDataSource(tempObj?.dataSource);
    setMainColumns(tempObj?.columns);
    setShowInvColSett(true);
  };

  const handleShowAddTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("addsColSett"));
    setAddDataSource(tempObj?.dataSource);
    setAddColumns(tempObj?.columns);
    setShowAddColSett(true);
  };

  const handleShowDiscTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("discsColSett"));
    setDiscDataSource(tempObj?.dataSource);
    setDiscColumns(tempObj?.columns);
    setShowDiscColSett(true);
  };

  const [accOpt, setAccOpt] = useState([]);
  const handleAccActSearch = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      setAccOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const handlePayMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePayMethodSearch("");
      setPayMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [clientOpt, setClientOpt] = useState([]);
  const handleClientSearch = async () => {
    try {
      let obj = {
        search: "",
        addId: [""],
        accId: "",
      };

      const { data: result } = await getClientActiveSearch(obj);

      setClientOpt(result?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [storeOpt, setStoreOpt] = useState([]);
  const handleStoreSearch = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setStoreOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [currencyOpt, setCurrencyOpt] = useState([]);
  const handleSearchCurrency = async () => {
    try {
      const { data: responce } = await getCurrActiveSearch("");
      setCurrencyOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const handlePriceMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePriceMethodSearch("");
      setPriceMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [jornalOpt, setJornalOpt] = useState([]);
  const handleJornal = async (_Search) => {
    try {
      const { data: responce } = await getActiveJournal("", "");
      setJornalOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const generalCards = (
    <div className="mainCardsContent">
      <div name="generalCards" className="frContent warpCardsContent">
        {/*Clients*/}

        {showField?.showClientVisible &&
          showField?.showClientReq &&
          showField?.showClientEnable &&
          showField?.showClientDefId && (
            <Card
              type="inner"
              //<label style={{color:"red", fontSize:12,marginLeft:15,marginRight:15}}>لا يمكن تحديدها إلا في حال تحديد الإظهار</label>
              title={
                <label>
                  <strong>
                    {selectedTypeId !==
                      "b06e5b92-b19e-45b1-9c25-1fb51f8f2b5e" &&
                    selectedTypeId !== "c81f2a29-b52b-433b-b107-655342313007"
                      ? _t("strTheCustomer")
                      : _t("strSupplier")}
                  </strong>
                </label>
              }
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showClientVisible && (
                  <Form.Item name="clientVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientReq && (
                  <Form.Item name="clientReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientEnable && (
                  <Form.Item name="clientEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showClientDefId && (
                <Form.Item
                  name="clientDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={true}
                    fieldNames={{ label: "name", value: "id" }}
                    opt={clientOpt}
                    placeholder={_t("strNotSelected")}
                  />
                </Form.Item>
              )}
            </Card>
          )}
        {/*Client Accounts*/}

        {showField?.showClientAccVisible &&
          showField?.showClientAccEnable &&
          showField?.showClientAccReq &&
          showField?.showClientAccDefId && (
            <Card
              type="inner"
              title={
                <strong>
                  {selectedTypeId !== "b06e5b92-b19e-45b1-9c25-1fb51f8f2b5e" &&
                  selectedTypeId !== "c81f2a29-b52b-433b-b107-655342313007"
                    ? _t("strClientAcc")
                    : _t("strSupplierAcc")}
                </strong>
              }
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showClientAccVisible && (
                  <Form.Item name="clientAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientAccReq && (
                  <Form.Item name="clientAccReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientAccEnable && (
                  <Form.Item name="clientAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showClientAccDefId && (
                <Form.Item
                  name="clientAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("clientAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setClientOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Client Accounts*/}

        {showField?.showClientAccCostCenterVisible &&
          showField?.showClientAccCostCenterEnable &&
          showField?.showClientAccCostCenterReq &&
          showField?.showClientAccCostCenter_Maps && (
            <Card
              type="inner"
              title={
                <strong>
                  {selectedTypeId !== "b06e5b92-b19e-45b1-9c25-1fb51f8f2b5e" &&
                  selectedTypeId !== "c81f2a29-b52b-433b-b107-655342313007"
                    ? _t("strCostCenterClient")
                    : _t("strCostCenterSupplier")}
                </strong>
              }
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showClientAccCostCenterVisible && (
                  <Form.Item
                    name="clientAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientAccCostCenterReq && (
                  <Form.Item
                    name="clientAccCostCenterReq"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showClientAccCostCenterEnable && (
                  <Form.Item
                    name="clientAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showClientAccCostCenter_Maps && (
                <>
                  <Form.Item name="clientAccCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="clientAccCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       clientCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="clientAccCostCenter"
                      arrName="clientAccCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={clientOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ clientAccCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        {/*Date*/}
        <Card
          type="inner"
          title={<strong>{_t("strDate")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="dateEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/*Store*/}
        {/* ~show always~  */}
        <Card
          type="inner"
          title={<strong>{_t("strStore")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="storeVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>
            <Form.Item name="storeEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name="storeDefId"
            label={_t("strDefault")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={storeOpt}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        {/*To Store*/}
        {showField?.showToStoreVisible &&
          showField?.showToStoreEnable &&
          showField?.showToStoreReq &&
          showField?.showToStoreDefId && (
            <Card
              type="inner"
              title={<strong>{_t("strToStore")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showToStoreVisible && (
                  <Form.Item name="toStoreVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}

                {showField?.showToStoreReq && (
                  <Form.Item name="toStoreReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}

                {showField?.showToStoreEnable && (
                  <Form.Item name="toStoreEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showToStoreDefId && (
                <Form.Item
                  name="toStoreDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={true}
                    opt={storeOpt}
                    placeholder={_t("strNotSelected")}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Invoice Account*/}
        {showField?.showInvAccDefId &&
          showField?.showInvAccReq &&
          showField?.showInvAccEnable &&
          showField?.showInvAccVisible && (
            <Card
              type="inner"
              title={<strong>{_t("strInvAccount")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showInvAccVisible && (
                  <Form.Item name="invAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAccReq && (
                  <Form.Item name="invAccReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAccEnable && (
                  <Form.Item name="invAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showInvAccDefId && (
                <Form.Item
                  name="invAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("invAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setInvOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Inv Accounts*/}

        {showField?.showInvAccCostCenterVisible &&
          showField?.showInvAccCostCenterEnable &&
          showField?.showInvAccCostCenterReq &&
          showField?.showInvAccCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterInv")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showInvAccCostCenterVisible && (
                  <Form.Item
                    name="invAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAccCostCenterReq && (
                  <Form.Item name="invAccCostCenterReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAccCostCenterEnable && (
                  <Form.Item
                    name="invAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showInvAccCostCenter_Maps && (
                <>
                  <Form.Item name="invAccCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="invAccCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       invCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="invAccCostCenter"
                      arrName="invAccCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={invOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ invAccCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        {/*Currency*/}
        {showField?.showCurrVisible &&
          showField?.showCurrEnable &&
          showField?.showCurrChType &&
          showField?.showCurrDefId && (
            <Card
              type="inner"
              title={<strong>{_t("strCurrency")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showCurrVisible && (
                  <Form.Item name="currVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showCurrEnable && (
                  <Form.Item name="currEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showCurrChType && (
                <Form.Item
                  name="currChType"
                  label={_t("strExchangeRateAdjustment")}
                  labelCol={{ flex: "120px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    defaultValue={0}
                    opt={[
                      {
                        id: 0,
                        name: _t("strSaveDirectly"),
                        filter1: _t("strSaveDirectly"),
                      },
                      {
                        id: 1,
                        name: _t("strNeverSave"),
                        filter1: _t("strNeverSave"),
                      },
                      {
                        id: 2,
                        name: _t("strQuestionBeforeSaving"),
                        filter1: _t("strQuestionBeforeSaving"),
                      },
                    ]}
                    placeholder={_t("strMethodOfDetermining")}
                  />
                </Form.Item>
              )}
              {showField?.showCurrDefId && (
                <Form.Item
                  name="currDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    opt={currencyOpt}
                    fieldNames={{ label: "currName", value: "currId" }}
                    placeholder={_t("strNotSelected")}
                  />
                </Form.Item>
              )}
            </Card>
          )}
        {/*PriceMethod*/}
        {showField?.showPriceMethodVisible &&
          showField?.showPriceMethodEnable &&
          showField?.showPriceMethodDefId && (
            <Card
              type="inner"
              title={<strong>{_t("strPriceMethod")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showPriceMethodVisible && (
                  <Form.Item name="priceMethodVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showPriceMethodEnable && (
                  <Form.Item name="priceMethodEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showPriceMethodDefId && (
                <Form.Item
                  name="priceMethodDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={true}
                    opt={priceMethodOpt}
                    placeholder={_t("strNotSelected")}
                  />
                </Form.Item>
              )}
            </Card>
          )}
        {/*Doc*/}
        <Card
          type="inner"
          title={<strong>{_t("strDocument")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="docVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>
            <Form.Item name="docReq" valuePropName="checked">
              <Checkbox>{_t("strRequired")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>
        {/*Code*/}
        <Card
          type="inner"
          title={<strong>{_t("strCode")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item name="codeEnable" valuePropName="checked">
            <Checkbox>{_t("strEnable")}</Checkbox>
          </Form.Item>
          <Form.Item
            name="codeStart"
            label={_t("strStartNumber")}
            labelCol={{ flex: "70px" }}
          >
            <InputNumber
              size="small"
              placeholder={_t("strStartNumber")}
              style={{ width: "100%" }}
              controls={false}
            />
          </Form.Item>
        </Card>
        {/*Entry*/}

        <Card
          type="inner"
          title={<strong>{_t("strTheEntry")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            {showField?.showGenEnt && (
              <Form.Item name="genEnt" valuePropName="checked">
                <Checkbox>{_t("strCreateEntry")}</Checkbox>
              </Form.Item>
            )}
            {showField?.showGenShortEnt && (
              <Form.Item name="genShortEnt" valuePropName="checked">
                <Checkbox>{_t("strCreateShortEntry")}</Checkbox>
              </Form.Item>
            )}
            {showField?.showGenEntFromTotal && (
              <Form.Item name="genEntFromTotal" valuePropName="checked">
                <Checkbox>{_t("strCreateEntryFromTotal")}</Checkbox>
              </Form.Item>
            )}
          </Space>
          {showField?.showJournalId && (
            <Form.Item
              name="journalId"
              label={_t("strJournal")}
              labelCol={{ flex: "80px" }}
              rules={[
                {
                  required: true,
                  message: `${_t("strIsRequired")}`,
                  validator: async (_, value) => {
                    if (value === undefined || value === "") {
                      setActiveKey("1");
                      message.error(
                        `(${_t("strJournal")}) ${_t("strIsRequired")}`
                      );
                      throw new Error("message");
                    }
                  },
                },
              ]}
            >
              <SelectSingle
                allowClear={false}
                opt={jornalOpt}
                placeholder={_t("strNotSelected")}
              />
            </Form.Item>
          )}
        </Card>

        {/*Options*/}
        <Card
          type="inner"
          title={<strong>{_t("strOptions")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            {showField?.showCostEffect && (
              <Form.Item name="costEffect" valuePropName="checked">
                <Checkbox>{_t("strImpactCosts")}</Checkbox>
              </Form.Item>
            )}
            {showField?.showLastPurEffect && (
              <Form.Item name="lastPurEffect" valuePropName="checked">
                <Checkbox>{_t("strEffectLastPurchasePrice")}</Checkbox>
              </Form.Item>
            )}
          </Space>

          <Space>
            {showField?.showLastSalesEffect && (
              <Form.Item name="lastSalesEffect" valuePropName="checked">
                <Checkbox>{_t("strEffectLastSellingPrice")}</Checkbox>
              </Form.Item>
            )}
            {showField?.showLastClientEffect && (
              <Form.Item name="lastClientEffect" valuePropName="checked">
                <Checkbox>{_t("strEffectLastSellingPriceCustomer")}</Checkbox>
              </Form.Item>
            )}
            <Form.Item name="isStoreEffect" valuePropName="checked">
              <Checkbox>{_t("strAffectedStore")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>
      </div>
    </div>
  );

  const addCard = (
    <div className="mainCardsContent">
      <div name="addCards" className="frContent warpCardsContent">
        {/*add Items*/}
        {showField?.showAddItemAccVisible &&
          showField?.showAddItemAccEnable &&
          showField?.showAddItemAccDefId && (
            <Card
              type="inner"
              title={
                <strong>
                  {_t("strItemAddTotal")} ({_t("strAccount")}){" "}
                </strong>
              }
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showAddItemAccVisible && (
                  <Form.Item name="addItemAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showAddItemAccEnable && (
                  <Form.Item name="addItemAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showAddItemAccDefId && (
                <Form.Item
                  name="addItemAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("addItemAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setAddOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Add Item Accounts*/}

        {showField?.showItemAddCostCenterVisible &&
          showField?.showItemAddCostCenterEnable &&
          showField?.showItemAddCostCenterReq &&
          showField?.showItemAddCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterItemAdd")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showItemAddCostCenterVisible && (
                  <Form.Item
                    name="addItemAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showItemAddCostCenterReq && (
                  <Form.Item
                    name="addItemAccCostCenterReq"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showItemAddCostCenterEnable && (
                  <Form.Item
                    name="addItemAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showItemAddCostCenter_Maps && (
                <>
                  <Form.Item name="itemAddCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="itemAddCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       addCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="itemAddCostCenter"
                      arrName="itemAddCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={addOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ itemAddCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        {/*add Inv Account*/}
        {showField?.showAddInvAccVisible &&
          showField?.showAddInvAccEnable &&
          showField?.showAddInvAccDefId && (
            <Card
              type="inner"
              title={
                <strong>
                  {_t("strInvAddTotal")} ({_t("strAccount")}){" "}
                </strong>
              }
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showAddInvAccVisible && (
                  <Form.Item name="addInvAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showAddInvAccEnable && (
                  <Form.Item name="addInvAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showAddInvAccDefId && (
                <Form.Item
                  name="addInvAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("addInvAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setInvAddOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Add Inv Accounts*/}

        {showField?.showInvAddCostCenterVisible &&
          showField?.showInvAddCostCenterEnable &&
          showField?.showInvAddCostCenterReq &&
          showField?.showInvAddCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterInvAdd")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showInvAddCostCenterVisible && (
                  <Form.Item
                    name="addInvAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAddCostCenterReq && (
                  <Form.Item
                    name="addInvAccCostCenterReq"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvAddCostCenterEnable && (
                  <Form.Item
                    name="addInvAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showInvAddCostCenter_Maps && (
                <>
                  <Form.Item name="invAddCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="invAddCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       addCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="invAddCostCenter"
                      arrName="invAddCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={invAddOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ invAddCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        {/*disc Items Total*/}
        {showField?.showItemAddTotalVisible && (
          <Card
            type="inner"
            title={
              <strong>
                {_t("strItemAddTotal")} ({_t("strTotal2")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showItemAddTotalVisible && (
                <Form.Item name="itemAddTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*add Inv Distribution*/}

        {showField?.showAddInvDist && showField?.showAddInvDistEnable && (
          <Card
            type="inner"
            title={
              <strong>
                {" "}
                {_t("strInvAddTotal")} ({_t("strDistribution")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showAddInvDist && (
                <Form.Item name="addInvDist" valuePropName="checked">
                  <Checkbox>{_t("strStatus")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showAddInvDistEnable && (
                <Form.Item name="addInvDistEnable" valuePropName="checked">
                  <Checkbox>{_t("strEnable")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*add Inv total*/}

        {showField?.showInvAddTotalVisible && (
          <Card
            type="inner"
            title={
              <strong>
                {" "}
                {_t("strInvAddTotal")} ({_t("strTotal2")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showInvAddTotalVisible && (
                <Form.Item name="invAddTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*add Inv Distribution*/}
        {showField?.showAddInvAccTableVisible &&
          showField?.showAddInvAccTableVisible && (
            <Card
              type="inner"
              title={<strong>{_t("strAddTable")}</strong>}
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showAddInvAccTableVisible && (
                  <Form.Item
                    name="addInvAccTableVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showAddsColSett && (
                  <Space>
                    <Form.Item name="addsColSett" hidden>
                      <Input />
                    </Form.Item>
                    <Button
                      icon={<SettingOutlined />}
                      onClick={handleShowAddTable}
                    >
                      {_t("strColSettings")}
                    </Button>
                  </Space>
                )}
              </Space>
            </Card>
          )}
      </div>
    </div>
  );

  const discCard = (
    <div className="mainCardsContent">
      <div name="addCards" className="frContent warpCardsContent">
        {/*disc Items Acc*/}
        {showField?.showDiscItemAccVisible &&
          showField?.showDiscItemAccEnable &&
          showField?.showDiscItemAccDefId && (
            <Card
              type="inner"
              title={
                <strong>
                  {" "}
                  {_t("strItemDiscTotal")} ({_t("strAccount")})
                </strong>
              }
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showDiscItemAccVisible && (
                  <Form.Item name="discItemAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showDiscItemAccEnable && (
                  <Form.Item name="discItemAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showDiscItemAccDefId && (
                <Form.Item
                  name="discItemAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("discItemAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setItemDiscOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}
        {/*Cost Center Disc Item Accounts*/}

        {showField?.showItemDiscCostCenterVisible &&
          showField?.showItemDiscCostCenterEnable &&
          showField?.showItemDiscCostCenterReq &&
          showField?.showItemDiscCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterItemDisc")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showItemDiscCostCenterVisible && (
                  <Form.Item
                    name="discItemAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showItemDiscCostCenterReq && (
                  <Form.Item
                    name="discItemAccCostCenterReq"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showItemDiscCostCenterEnable && (
                  <Form.Item
                    name="discItemAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showItemDiscCostCenter_Maps && (
                <>
                  <Form.Item name="itemDiscCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="itemDiscCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       discCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="itemDiscCostCenter"
                      arrName="itemDiscCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={itemDiscOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ itemDiscCostCenter: e });
                      }}
                      //amount={getFieldValue("invDiscTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}
        {/*disc Inv Acc*/}
        {showField?.showDiscInvAccVisible &&
          showField?.showDiscInvAccEnable &&
          showField?.showDiscInvAccDefId && (
            <Card
              type="inner"
              title={
                <strong>
                  {" "}
                  {_t("strInvDiscTotal")} ({_t("strAccount")})
                </strong>
              }
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showDiscInvAccVisible && (
                  <Form.Item name="discInvAccVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showDiscInvAccEnable && (
                  <Form.Item name="discInvAccEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showDiscInvAccDefId && (
                <Form.Item
                  name="discInvAccDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("discInvAccDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setInvDiscOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Disc Inv Accounts*/}

        {showField?.showInvDiscCostCenterVisible &&
          showField?.showInvDiscCostCenterEnable &&
          showField?.showInvDiscCostCenterReq &&
          showField?.showInvDiscCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterInvDisc")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showInvDiscCostCenterVisible && (
                  <Form.Item
                    name="discInvAccCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvDiscCostCenterReq && (
                  <Form.Item
                    name="discInvAccCostCenterReq"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showInvDiscCostCenterEnable && (
                  <Form.Item
                    name="discInvAccCostCenterEnable"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showInvDiscCostCenter_Maps && (
                <>
                  <Form.Item name="invDiscCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="invDiscCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       addCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="invDiscCostCenter"
                      arrName="invDiscCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={invDiscOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ invDiscCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        {/*disc Items Total*/}
        {showField?.showItemDiscTotalVisible && (
          <Card
            type="inner"
            title={
              <strong>
                {" "}
                {_t("strItemDiscTotal")} ({_t("strTotal2")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showItemDiscTotalVisible && (
                <Form.Item name="itemDiscTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*disc Inv Distribution*/}
        {showField?.showDiscInvDist && showField?.showDiscInvDistEnable && (
          <Card
            type="inner"
            title={
              <strong>
                {" "}
                {_t("strInvDiscTotal")} ({_t("strDistribution")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showDiscInvDist && (
                <Form.Item name="discInvDist" valuePropName="checked">
                  <Checkbox>{_t("strStatus")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showDiscInvDistEnable && (
                <Form.Item name="discInvDistEnable" valuePropName="checked">
                  <Checkbox>{_t("strEnable")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}
        {/*disc Inv Distribution*/}
        {showField?.showInvDiscTotalVisible && (
          <Card
            type="inner"
            title={
              <strong>
                {" "}
                {_t("strInvDiscTotal")} ({_t("strTotal2")})
              </strong>
            }
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showInvDiscTotalVisible && (
                <Form.Item name="invDiscTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*disc Inv Distribution*/}
        {showField?.showDiscInvAccTableVisible &&
          showField?.showDiscsColSett && (
            <Card
              type="inner"
              title={<strong>{_t("strDiscTable")}</strong>}
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showDiscInvAccTableVisible && (
                  <Form.Item
                    name="discInvAccTableVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showDiscsColSett && (
                  <Space>
                    <Form.Item name="discsColSett" hidden>
                      <Input />
                    </Form.Item>
                    <Button
                      icon={<SettingOutlined />}
                      onClick={handleShowDiscTable}
                    >
                      {_t("strColSettings")}
                    </Button>
                  </Space>
                )}
              </Space>
            </Card>
          )}
      </div>
    </div>
  );

  const advanceCard = (
    <div className="mainCardsContent">
      <div name="addCards" className="frContent warpCardsContent">
        {/*totals final*/}
        {(showField?.showItemSubTotalVisible ||
          showField?.showNetTotalVisible ||
          showField?.showItemTotalVisible) && (
          <Card
            type="inner"
            title={<strong>{_t("strTot")}</strong>}
            size="small"
            style={{ width: "100%" }}
          >
            <Space>
              {showField?.showItemTotalVisible && (
                <Form.Item name="itemTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strTotal")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showItemSubTotalVisible && (
                <Form.Item name="itemSubTotalVisible" valuePropName="checked">
                  <Checkbox> {_t("strSubTotal")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showNetTotalVisible && (
                <Form.Item name="netTotalVisible" valuePropName="checked">
                  <Checkbox>{_t("strNet")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*Totals*/}
        {showField?.showCalcPayTotalVisible &&
          showField?.showPayTotalVisible &&
          showField?.showRestTotalVisible &&
          showField?.showPayValueVisible &&
          showField?.showCashAccEnable &&
          showField?.showCashAccVisible &&
          showField?.showCashAccDefId &&
          showField?.showPayMethodVisible &&
          showField?.showPayMethodEnable &&
          showField?.showPayMethodReq &&
          showField?.showPayMethodDefId && (
            <Card
              type="inner"
              title={<strong> {_t("strPayment")} </strong>}
              size="small"
              className="card-patt-fill stripe cardFitContent"
            >
              <Row gutter={6}>
                {/*Visible*/}
                {showField?.showCalcPayTotalVisible &&
                  showField?.showPayTotalVisible &&
                  showField?.showRestTotalVisible &&
                  showField?.showPayValueVisible && (
                    <Card
                      type="inner"
                      title={<strong> {_t("strVisible")} </strong>}
                      size="small"
                      className="card-patt-fill cardFitContent"
                    >
                      <Space>
                        {showField?.showCalcPayTotalVisible && (
                          <Form.Item
                            name="calcPayTotalVisible"
                            valuePropName="checked"
                          >
                            <Checkbox>{_t("strCalcPayTotal")}</Checkbox>
                          </Form.Item>
                        )}
                        {showField?.showPayTotalVisible && (
                          <Form.Item
                            name="payTotalVisible"
                            valuePropName="checked"
                          >
                            <Checkbox>{_t("strPayTotal")}</Checkbox>
                          </Form.Item>
                        )}

                        {showField?.showRestTotalVisible && (
                          <Form.Item
                            name="restTotalVisible"
                            valuePropName="checked"
                          >
                            <Checkbox>{_t("strRest")}</Checkbox>
                          </Form.Item>
                        )}

                        {showField?.showPayValueVisible && (
                          <Form.Item
                            name="payValueVisible"
                            valuePropName="checked"
                          >
                            <Checkbox>{_t("strPayValue")}</Checkbox>
                          </Form.Item>
                        )}
                      </Space>
                    </Card>
                  )}

                <Col span={12}>
                  {showField?.showCashAccEnable &&
                    showField?.showCashAccVisible &&
                    showField?.showCashAccDefId && (
                      <Card
                        type="inner"
                        title={<strong> {_t("strAccount")} </strong>}
                        size="small"
                        className="card-patt-fill cardFitContent"
                      >
                        <Space>
                          {showField?.showCashAccVisible && (
                            <Form.Item
                              name="cashAccVisible"
                              valuePropName="checked"
                            >
                              <Checkbox>{_t("strVisible")}</Checkbox>
                            </Form.Item>
                          )}
                          {showField?.showCashAccEnable && (
                            <Form.Item
                              name="cashAccEnable"
                              valuePropName="checked"
                            >
                              <Checkbox>{_t("strEnable")}</Checkbox>
                            </Form.Item>
                          )}
                        </Space>
                        {showField?.showCashAccDefId && (
                          <Form.Item
                            name="cashAccDefId"
                            label={_t("strDefault")}
                            labelCol={{ flex: "60px" }}
                          >
                            <SelectAccount
                              addId={form.getFieldValue("cashAccDefId")}
                              placeholder={_t("strNotSelected")}
                              onChange={(e, x) => {
                                setCashOptReqCost(x?.costCenterReqOpt);
                              }}
                            />
                          </Form.Item>
                        )}
                      </Card>
                    )}
                </Col>

                {/*Cost Center Cash Accounts*/}

                {/* {showField?.showCashAccVisible &&
                  showField?.showCashAccEnable &&
                  showField?.showCashAccReq &&
                  showField?.showCashAccDefId && ( */}
                <Card
                  type="inner"
                  title={<strong>{_t("strCostCenterPayAcc")}</strong>}
                  size="small"
                  className="cardPatt"
                >
                  <Space>
                    {showField?.showCashAccVisible && (
                      <Form.Item
                        name="cashAccCostCenterVisible"
                        valuePropName="checked"
                      >
                        <Checkbox>{_t("strVisible")}</Checkbox>
                      </Form.Item>
                    )}
                    {showField?.showCashAccReq && (
                      <Form.Item
                        name="cashAccCostCenterReq"
                        valuePropName="checked"
                      >
                        <Checkbox>{_t("strRequired")}</Checkbox>
                      </Form.Item>
                    )}
                    {showField?.showCashAccEnable && (
                      <Form.Item
                        name="cashAccCostCenterEnable"
                        valuePropName="checked"
                      >
                        <Checkbox>{_t("strEnable")}</Checkbox>
                      </Form.Item>
                    )}
                  </Space>

                  {showField?.showCashAccDefId && (
                    <>
                      <Form.Item name="cashAccCostCenter_Maps" hidden>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="cashAccCostCenter"
                        label={_t("strDefault")}
                        labelCol={{ flex: "60px" }}
                        // rules={[
                        //   {
                        //     required:
                        //       cashCostMessage !== undefined ? true : false,
                        //   },
                        // ]}
                      >
                        <CostCenterInput
                          form={form}
                          fieldName="cashAccCostCenter"
                          arrName="cashAccCostCenter_Maps"
                          unValue={true}
                          accountValidationOpt={cashOptReqCost}
                          type="input"
                          onHandelData={(e) => {
                            form.setFieldsValue({ cashAccCostCenter: e });
                          }}
                          //amount={getFieldValue("invAddTotal")}
                        />
                      </Form.Item>
                    </>
                  )}
                </Card>
                {/* )} */}

                <Col span={12}>
                  {showField?.showPayMethodVisible &&
                    showField?.showPayMethodEnable &&
                    showField?.showPayMethodReq &&
                    showField?.showPayMethodDefId && (
                      <Card
                        type="inner"
                        title={<strong>{_t("strPayMethod")}</strong>}
                        size="small"
                        className="card-patt-fill cardFitContent"
                      >
                        <Space>
                          {showField?.showPayMethodVisible && (
                            <Form.Item
                              name="payMethodVisible"
                              valuePropName="checked"
                            >
                              <Checkbox>{_t("strVisible")}</Checkbox>
                            </Form.Item>
                          )}
                          {showField?.showPayMethodReq && (
                            <Form.Item
                              name="payMethodReq"
                              valuePropName="checked"
                            >
                              <Checkbox>{_t("strRequired")}</Checkbox>
                            </Form.Item>
                          )}
                          {showField?.showPayMethodEnable && (
                            <Form.Item
                              name="payMethodEnable"
                              valuePropName="checked"
                            >
                              <Checkbox>{_t("strEnable")}</Checkbox>
                            </Form.Item>
                          )}
                        </Space>
                        {showField?.showPayMethodDefId && (
                          <Form.Item
                            name="payMethodDefId"
                            label={_t("strDefault")}
                            labelCol={{ flex: "60px" }}
                          >
                            <SelectSingle
                              allowClear={true}
                              opt={payMethodOpt}
                              placeholder={_t("strNotSelected")}
                            />
                          </Form.Item>
                        )}
                      </Card>
                    )}
                </Col>
              </Row>
            </Card>
          )}

        {/*Due Date*/}
        {/* {showField?.showDueDateReq &&
          showField?.showDueDateEnable &&
          showField?.showDueDateVisible && (
            <Card
              type="inner"
              title={<strong>{_t("strDueDate")}</strong>}
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showDueDateVisible && (
                  <Form.Item name="dueDateVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showDueDateReq && (
                  <Form.Item name="dueDateReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showDueDateEnable && (
                  <Form.Item name="dueDateEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
            </Card>
          )} */}

        {/*Total Qty*/}

        <Card
          type="inner"
          title={
            <strong>
              {_t("strQtyTotal")} ({_t("strVisible")}){" "}
            </strong>
          }
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="totalQtyTableVisible" valuePropName="checked">
              <Checkbox>{_t("strTotalQtyTable")}</Checkbox>
            </Form.Item>
            <Form.Item name="totalQtyVisible" valuePropName="checked">
              <Checkbox>{_t("strQtyTotal")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>
        {/*Posting Date*/}
        {(showField?.showPostEntVisible ||
          showField?.showPostEntEnable ||
          showField?.showPostEnt) && (
          <Card
            type="inner"
            title={<strong>{_t("strPosting")}</strong>}
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showPostEntVisible && (
                <Form.Item name="postEntVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showPostEntEnable && (
                <Form.Item name="postEntEnable" valuePropName="checked">
                  <Checkbox>{_t("strEnable")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showPostEnt && (
                <Form.Item name="postEnt" valuePropName="checked">
                  <Checkbox>{_t("strPostingEntry")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*totals final*/}
        {showField?.showAccStatusVisible && (
          <Card
            type="inner"
            title={<strong>{_t("strPaymentStatus")}</strong>}
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showAccStatusVisible && (
                <Form.Item name="accStatusVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {/*Posting Date*/}
        {showField?.showOprStatusVisible &&
          showField?.showOprStatusEnable &&
          showField?.showOprStatusDefId && (
            <Card
              type="inner"
              title={<strong> {_t("strOperationalStatus")}</strong>}
              size="small"
              className="cardPatt cardFitContent"
            >
              <Space>
                {showField?.showOprStatusVisible && (
                  <Form.Item name="oprStatusVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showOprStatusEnable && (
                  <Form.Item name="oprStatusEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showOprStatusDefId && (
                <Form.Item
                  name="oprStatusDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    defaultValue={0}
                    opt={[
                      {
                        id: 0,
                        name: _t("strDone"),
                        filter1: _t("strDone"),
                      },
                      {
                        id: 1,
                        name: _t("strCanceled"),
                        filter1: _t("strCanceled"),
                      },
                      {
                        id: 2,
                        name: _t("strLocked"),
                        filter1: _t("strLocked"),
                      },
                    ]}
                    placeholder={_t("strDefault")}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Inv Table*/}

        <Card
          type="inner"
          title={<strong>{_t("strInvTable")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="detailsColSett" hidden>
              <Input />
            </Form.Item>
            <Button icon={<SettingOutlined />} onClick={handleShowMainInvTable}>
              {_t("strColSettings")}
            </Button>
          </Space>
        </Card>
      </div>
    </div>
  );

  return (
    <>
      <InvTableSett
        showModal={showInvColSett}
        setShowModal={setShowInvColSett}
        columns={mainColumns}
        setColumns={setMainColumns}
        dataSource={mainDataSource}
        setDataSource={setMainDataSource}
        tableName="popup_invTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ detailsColSett: e });
        }}
      />
      <DiscTableSett
        showModal={showDiscColSett}
        setShowModal={setShowDiscColSett}
        columns={discColumns}
        setColumns={setDiscColumns}
        dataSource={discDataSource}
        setDataSource={setDiscDataSource}
        tableName="popup_discTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ discsColSett: e });
        }}
      />
      <AddTableSett
        showModal={showAddColSett}
        setShowModal={setShowAddColSett}
        columns={addColumns}
        setColumns={setAddColumns}
        dataSource={addDataSource}
        setDataSource={setAddDataSource}
        tableName="popup_addTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ addsColSett: e });
        }}
      />
      {/* <Spin spinning={loadData} tip={_t("strLoading")}> */}
      <div name="cardsId" className="frContent">
        <Tabs
          type="card"
          onChange={onTabsChange}
          activeKey={activeKey}
          items={[
            {
              key: "1",
              label: _t("strGeneral"),
              children: generalCards,
              forceRender: true,
            },
            {
              key: "2",
              label: _t("strAdvanced"),
              children: advanceCard,
              forceRender: true,
            },
            showField?.showAddTab && {
              key: "3",
              label: _t("strAdd"),
              children: addCard,
              forceRender: true,
            },
            showField?.showDiscTab && {
              key: "4",
              label: _t("strDiscount"),
              children: discCard,
              forceRender: true,
            },
          ]}
        />
      </div>
      {/* </Spin> */}
    </>
  );
};

export default PattSettings;
