import React from "react";
import { Input } from "antd";
import { emptValue } from "../../helper/helperMethods";
const InputCurrency = ({
  innerRef,
  precision = 3,
  zIndex,
  value,
  max = 100000000000000,
  min = -100000000000000,
  cutRestVal = false,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [focus, setFocus] = React.useState(false);
  const [orginalVal, setOrginalVal] = React.useState("");
  const handleChange = (e) => {
    onChange && onChange(formatterNumber(e.target.value));
  };

  const handleFocus = (e) => {
    setOrginalVal(e.currentTarget.value);
    setFocus(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e) => {
    setFocus(false);

    const n1 = parseFloat(formatedNumber(e.target.value, false));
    // const n2 = parseFloat(formatedNumber(orginalVal, false));
    const n2 = parseFloat(orginalVal.replace(/,/g, ""));

    if (n1 !== n2 && e.target.value !== "") {
      if (cutRestVal) {
        let val = formatedNumber(value, false);
        onChange && onChange(!!val ? parseFloat(val) : val);
      } else {
        onChange && onChange(!!value ? parseFloat(value) : value);
      }
      onBlur && onBlur(e);
    }
  };

  const formatedNumber = (val, withComma = true) => {
    if (
      (typeof val !== "string" && typeof val !== "number") ||
      val === undefined
    )
      return null;

    val = parseFloat(val).toFixed(precision);
    let results = `${val}`.replace(/[^\d.-]/g, "").split(".");
    if (!results[0]) {
      return null;
    }
    if (withComma) {
      results[0] = results[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (results[0] && typeof precision === "number" && precision > 0) {
      results[1] =
        results[1] && results[1].lenght === precision
          ? results[1]
          : fillNumber(results[1], precision);
      results = results.splice(0, 2);
      let val = results.join(".");
      return parseFloat(val) === 0 ? undefined : val;
    }

    return results[0];
  };

  // formatter and parser input number
  const formatterNumber = (val) => {
    if (!val) return undefined;
    let convValue = `${val}`
      .replace(/[^.\d]/g, "")
      .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    if (convValue === "") return undefined;
    else return convValue;
  };

  return (
    <Input
      ref={innerRef}
      tabIndex={zIndex}
      
      value={focus ? formatterNumber(value) : formatedNumber(value)}
      onChange={handleChange}
      onFocus={handleFocus}
      maxLength={15}
      onBlur={handleBlur}
      autoComplete="off"
      style={{ width: 500 }}
      {...rest}
    />
  );
};

export default InputCurrency;

function fillNumber(value, decimals) {
  if (!decimals || decimals === 0) {
    return "";
  }
  const values = (value || "").split("");
  let results = [];
  for (let i = 0; i < decimals; i++) {
    results[i] = values[i] || "0";
  }
  return results.join("");
}
