import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, Tag, Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getPriceInfo } from "../../../services/Items/itemService"
import Draggable from 'react-draggable';
import DataTable from "../../helper/gridDataTable/dataTable";
import CurrEditor from "../../helper/dataGrid/source/CurrEditor";
import { getNumFormat } from "../../helper/countOfZero";
import { emptValue } from "../../helper/helperMethods";

const CheckPriceItems = ({ itemUnitMapId, mainDate, rate = '1', showModal, setShowModal,  handleInsert, isInsert = true }) => {
    const [dataSource, setDataSource] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [popupForm] = Form.useForm();
    const draggleRef = useRef(null);

    const columns = [
        {
            key: "itemName",
            name: _t("strItem"),
            visible: true,
        },
        {
            key: "unitName",
            name: _t("strUnit"),
            visible: true,
        },
        {
            key: "priceMethodName",
            name: _t("strPriceMethod"),
            visible: true,
        },
        {
            key: "price",
            name: _t("strPrice"),
            visible: true,
            formatter: (p) => {
                return (
                  <Tooltip title={p?.row?.price}>
                    {getNumFormat(p?.row?.price, 2)}
                  </Tooltip>
                );
              },
        },
        {
            key: "actions",
            name: _t("strActions"),
            visible: true,
            formatter: (p) => {
                return (
                    isInsert === true ?
                        <Button type="link" size="small" disabled={!isInsert} onClick={() =>{ handleInsert({ e: { target: { value: p.row?.price } }})}}>
                            {_t("strInsert")}<DownloadOutlined /></Button>
                        :
                        <Tag color="error" style={{ fontWeight: 400 }}>{_t("strCanotInsertPrice")}</Tag>
                )
            }
        }
    ];




    const searchData = async () => {
        try {
            if (itemUnitMapId !== "" && mainDate !== "") {            
                const { data: result } = await getPriceInfo(itemUnitMapId, mainDate, rate);        
                setDataSource(result.data);
            }

        } catch (error) {
            handleEx(error);
        }
        finally {
            setLoadData(false);
        }
    }

    useEffect(() => {
        setLoadData(true);
        searchData();
    }, [showModal === true])


    const realodTable = () => {
        setLoadData(true);
        searchData();
      };
    
    const handleClear = () => {
        setLoadData(true);
        setTimeout(() => {
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setLoadData(false);
        }, 50);
    };


    const onStart = (_, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        })
    };

    const [disabled, setDisabled] = useState(true);
    return (
        <Modal

        centered
            width={800}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => { if (disabled) { setDisabled(false) } }}
                    onMouseOut={() => { setDisabled(true) }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    {_t("strPrice")}
                </div>
            }

            modalRender={modal => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}

            visible={showModal} onCancel={() => setShowModal(false)} afterClose={() => handleClear()} footer={[]} destroyOnClose>
            <Form form={popupForm}>

                <Form.Item>
                    <DataTable
                        style={{ height: "calc(85vh - 195px)", width: "100%" }}
                        onRowsChange={setDataSource}
                        columns={columns}
                        showPath={false}
                        showTableButtons={true}
                        dataSource={dataSource}
                        tableName="popup_itemPrice"
                        tree={false}
                        frezzColExp={false}
                        isPagination={false}
                        handleReload={realodTable}
                        pageName={_t("strQTY")}
                        groupName={_t("strReports")}
                        type="rep"
                        loading={loadData}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CheckPriceItems;
