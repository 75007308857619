import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Prompt } from "react-router";
import { Form, Input, Button, Select, Row, Col, Spin, Menu, Space, Dropdown, Typography, message } from "antd";
import { UserOutlined, LockOutlined, RetweetOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { getUsersById, editUsers } from "../../../services/Authorization/usersService";
import { GetActive } from "../../../services/Authorization/userRoleService";
import { GetActiveUserRole } from "../../../services/userAccRoleService";
import { handleEx } from "../../helper/handleException";
import { shortCut } from "../../helper/shortCuts";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";

const UsersForm = () => {
  const [form] = Form.useForm();
  let { id } = useParams();
  let history = useHistory();
  const IX_Code = useRef(null);
  const IX_FirstName = useRef(null);
  const IX_UserName = useRef(null);
  const IX_Password = useRef(null);
  const IX_Email = useRef(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isRequired, setIsRequired] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [roleOpt, setRoleOpt] = useState([]);
  const [roleAccOpt, setRoleAccOpt] = useState([]);
  const [proChange, setProChange] = useState(false);
  const [isGoBack, setIsGoBack] = useState(true);
  const [roleId, setRoleId] = useState("");

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.user)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  useMemo(() => { authorization() }, [])
  //////////----|👉 end auth|----//////////




  const { Text } = Typography;
  const roleOptList =
    roleOpt.length > 0 &&
    roleOpt.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
  const roleAccOptList =
    roleAccOpt.length > 0 &&
    roleAccOpt.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );


  useEffect(() => {
    async function fetchData() {
      try {
        const { data: newData } = await GetActive("", "");
        setRoleOpt(newData.data);
        handleUserAccRole("","");
        if (id === "new") { return; } else {
          const { data } = await getUsersById(id);
          setIsDisabled(true);
          setIsRequired(false);
          setRoleId(data.data.userRoleId,);
          form.setFieldsValue({
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            email: data.data.email,
            userName: data.data.userName,
            password: data.data.password,
            confirmPassword: data.data.confirmPassword,
            userRoleId: data.data.userRoleId,
            userAccRoleId: data.data.userAccRoleId
          });
        }
      } catch (error) {

        handleEx(error, { IX_UserName, IX_Password, IX_FirstName, IX_Email });
      }
      finally {
        setLoadData(false);
      }
    }
    fetchData();
  }, [id, history, form]);

  const goBack = () => {
    setRoleId("");
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({ ...history.location, pathname: "/users/new" });
      }
      form.resetFields();
      IX_UserName.current.focus();
    }
  };

  const handleActiveStores = async (_Search, _Active) => {
    try {
      const { data: Data } = await GetActive(_Search, _Active);
      setRoleOpt(Data.data);
    } catch (error) {
      handleEx(error, { IX_UserName, IX_Password, IX_FirstName, IX_Email });
    }
    finally {
      setLoadData(false);
    }
  };

  const handleUserAccRole = async (_Search, _Active) => {
    try {
      const { data: Data } = await GetActiveUserRole(_Search, _Active);
      
      setRoleAccOpt(Data.data);
    } catch (error) {
      handleEx(error, { IX_UserName, IX_Password, IX_FirstName, IX_Email });
    }
    finally {
      setLoadData(false);
    }
  };


  const onFinish = async (values) => {
    setProChange(false);
    values.id = id;
    values.language = "en"; 
    try {
      
      const { data: data } = await editUsers(values);
      message.success(data.message, 3);
      goBack();
    } catch (error) {
      handleEx(error, { IX_UserName, IX_Password, IX_FirstName, IX_Email });

    }
    finally {
      setLoadData(false);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;   
            case "block1":
              IX_FirstName?.current?.focus();
              break;         
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  return (
    <>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{
            flex: "150px",
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{ isMain: false }}
        >

          <div name="code" className="frHeader">
            <Row>
              <Col xs={24} md={24}>
                <Space split="\">
                  <Text >{_t("strSecurity")}</Text>
                  <Text style={{ fontWeight: 700 }}>{_t("strUsers")}</Text>
                </Space>
              </Col>
            </Row>
          </div>

          <div name="field" className="frContent">
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  label={_t("strFirstName")}
                  rules={[
                    {
                      required: true,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                  autoComplete="off"
                >
                  <Input ref={IX_FirstName} maxLength={200} onKeyPress={() => setProChange(true)} size="small" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="lastName"
                  label={_t("strLastName")}
                  rules={[
                    {
                      required: false,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                  autoComplete="off"
                >
                  <Input maxLength={200} onKeyPress={() => setProChange(true)} size="small" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="userName"
                  label={_t("strUserName")}
                  rules={[
                    {
                      required: true,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    maxLength={200}
                    ref={IX_UserName}
                    onKeyPress={() => setProChange(true)}
                    size="small"
                    autoComplete={false}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="userRoleId"
                  label={_t("strSelectRole")}
                  rules={[{ required: true, message: _t("msgPleaseSelectRole") }]}
                  autoComplete="off"
                >
                  <Select
                    showSearch
                    allowClear
                    filterOption={false}
                    onSearch={(value) => { handleActiveStores(value, roleId) }}
                    onChange={() => setProChange(true)}
                    size="small"
                  >{roleOptList}</Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="userAccRoleId"
                  label={_t("strAccRole")}         
                  autoComplete="off"
                >
                  <Select
                    showSearch
                    allowClear
                    filterOption={false}
                    onSearch={(value) => { handleUserAccRole(value, roleId) }}
                    onChange={() => setProChange(true)}
                    size="small"
                  >{roleAccOptList}</Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  label={_t("strEmail")}
                  rules={[
                    {
                      required: true,
                      min: 3,
                      max: 200,
                      message: _t("msgEmailIsRequired"),
                    },
                  ]}
                  autoComplete="off"
                  ref={IX_Email}
                >
                  <Input maxLength={200} onKeyPress={() => setProChange(true)} size="small" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  label={_t("strPassword")}
                  name="password"
                  autoComplete="off"
                  rules={[
                    {
                      required: isRequired,
                      min: 6,
                      max: 200,
                      message: _t("msgInputYourPass"),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    disabled={isDisabled}
                    ref={IX_Password}
                    autoComplete="off"
                    maxLength={200}
                    onKeyPress={() => setProChange(true)}
                    size="small"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  label={_t("strConfirmPassword")}
                  name="confirmPassword"
                  autoCompletee={false}
                  rules={[
                    {
                      required: isRequired,
                      min: 6,
                      max: 200,
                      message: _t("msgInputYourConPass"),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    disabled={isDisabled}
                    maxLength={200}
                    onKeyPress={() => setProChange(true)}
                    size="small"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="frFooter">
            <Form.Item>
              <Space size="large">
                {auth.perPut && (
                  <Button type="primary" htmlType="submit" size="small" disabled={loadData}>
                    {_t("strSave")}
                  </Button>
                )}
                <Dropdown.Button
                  hidden={typeof onNewStore === "function"}
                  overlay={menu}
                  onClick={() => history.goBack()}
                  size="small"
                >
                  {" "}
                  {_t("strBack")}
                </Dropdown.Button>
                {isGoBack ? (
                  <i />
                ) : (
                  <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                )}
              </Space>
            </Form.Item>
          </div>

        </Form>
      </Spin>
    </>
  );
};

export default UsersForm;
