import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  message,
  Empty,
  Space,
  Row,
  Col,
  Typography,
  Spin,
  DatePicker,
  Tabs,
  Modal,
  Collapse,
  Tag,
} from "antd";
import {
  editManufModels,
  deleteManufModels,
  initPage,
  getByPattCode,
  getByDoc,
  browseNext,
  browsePrev,
} from "../../../services/munfOrderService";
import {
  ExclamationCircleOutlined,
  DeleteTwoTone,
  InfoCircleOutlined,
  SaveOutlined,
  ReloadOutlined,
  DeleteOutlined,
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import { GetActiveStoreChildren } from "../../../services/storeService";
import { getActiveCurr } from "../../../services/Items/currenciesService";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import {
  getRate,
  changeRate,
} from "../../../services/Items/currenciesRateService";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { useApi } from "../../../services/useApi";
import ComplexDetails from "./ComplexGrid/complexDetails";
import MunfDetails from "./MunfGrid/munfDetails";
import RawDetails from "./RawGrid/rawDetails";
import { shortCut } from "../../helper/shortCuts";
import InputCurrency from "../../helper/Input/InputCurrency";
import { objCountNum } from "../../helper/countOfZero";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import moment from "moment";
import { munfSaveValidation } from "./helper/Validate";
import SelectSingle from "../../helper/selectSingle";
import ActionSearch from "../../helper/Modal/actionSearch";
import { emptDate, emptString, emptValue } from "../../helper/helperMethods";
import { handelClxPriceMethod } from "./helper/reCalcPrice";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const MunfOrderForm = () => {
  const IX_Code = useRef(null);
  const IX_Doc = useRef(null);
  const IX_Currency = useRef(null);
  const gridComplexRef = useRef(null);
  const gridMunfRef = useRef(null);
  const gridRawRef = useRef(null);
  const [form] = Form.useForm();
  let { id } = useParams();

  let query = useQuery();
  //******************************//
  const [loadData, setLoadData] = useState(true);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [fromStoreOpt, setFromStoreOpt] = useState([]);
  const [toStoreOpt, setToStoreOpt] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [detail, setDetail] = useState([]);
  const [rawDetail, setRawDetail] = useState([]);
  const [rawTotal, setRawTotal] = useState(0);
  const [munfDetail, setMunfDetail] = useState([]);
  const [currOptTable, setCurrOptTable] = useState([]);
  const [pattrenSet, setPattrenSet] = useState(null);
  const [complexDisable, setComplexDisable] = useState(true);
  const [manualDisable, setManualDisable] = useState(true);
  const [tabNumber, setTabNumber] = useState("0");
  const [tableTabsNumber, setTableTabsNumber] = useState("0");
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyCurrId, setCompanyCurrId] = useState(
    form.getFieldValue().currId === pattrenSet?.companyCurrId
  ); //بعد استلام العملة الافتراضية  pattrenSet حذف كل ال
  const [initVal, setInitVal] = useState(null);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { confirm } = Modal;
  const { TextArea } = Input;
  let history = useHistory();
  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text } = Typography;
  const { TabPane } = Tabs;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});

  const authorization = async () => {
    try {
      let authUrl = authKeys.munfs + "_PattSett_" + id;
      const data = await getAuth();
      const responce = data?.data?.data.filter(
        (e) => e?.roleKey === authUrl
      )[0];

      setAuth(responce);
    } catch (error) {
      handleEx(error);
    }
  };

  //   const authorization = async () => {
  //     const obj = JSON.parse(localStorage.getItem("auth"));
  //     let authUrl = authKeys.munfs + "_PattSett_" + id;
  //     if (obj !== null) {
  //       const responce = obj.filter((e) => e?.roleKey === authUrl)[0];
  // debugger
  //       setAuth(responce);
  //     } else {
  //       try {
  //         const data = await getAuth();
  //         localStorage.setItem("auth", JSON.stringify(data.data.data));
  //         authorization();
  //       } catch (ex) {
  //         return null;
  //       }
  //     }
  //   };
  //////////----|👉 end auth|----//////////

  const promptForm = () => {
    setProChange(true);
    window.sessionStorage.setItem("promptEnt", true);
  };

  const [{ data, a, b }, c] = useApi(getActiveCurr(), [null || null]);

  useEffect(() => {
    let list = [];
    for (let i = 0; i < data?.length; i++) {
      let tmpObj = {};
      tmpObj["id"] = data[i]?.currId;
      tmpObj["name"] = data[i]?.currName;
      list.push(tmpObj);
    }
    setCurrOptTable(list);
  }, [data]);

  const disableManual = () => {
    setComplexDisable(false);
    setManualDisable(true);
    setTabNumber("0");
  };
  const disableComplex = () => {
    setComplexDisable(true);
    setManualDisable(false);
    setTabNumber("1");
  };
  const enableAll = () => {
    setComplexDisable(false);
    setManualDisable(false);
    setTabNumber("0");
  };

  useEffect(() => {
    // (Complex = 1), (Simple = 2), (ComplexAndSimple = 3) : أنواع حالات النموذج
    //~~~~~~~~~~~~~~~~~~
    // القيم من السيرفر
    let detailState = detail.some((object) => object.munfModelId !== undefined);
    let rawState = rawDetail.some((object) => object.itemId !== undefined);
    let munfState = munfDetail.some((object) => object.itemId !== undefined);
    // نوع الامر من النموذج
    let isComplex = pattrenSet?.munfType === 3 || pattrenSet?.munfType === 2;
    let isManual = pattrenSet?.munfType === 3 || pattrenSet?.munfType === 1;
    // شرط اذا كانت كل الجداول فارغة
    let allDataEmp = !detailState && !rawState && !munfState;
    // توظيف الشروط
    if (allDataEmp && pattrenSet?.munfType === 3) {
      //  تفعيل الكل
      enableAll();
    } else if ((allDataEmp && isComplex) || detailState) {
      // تفعيل المركب / تعطيل اليدوي
      disableManual();
    } else if (allDataEmp && isManual || (rawState || munfState)) {
      // تعطيل المركب / تفعيل اليدوي
      disableComplex();
    }
  }, [detail, rawDetail, munfDetail, pattrenSet]);

  const initValue = async (_Patt, _Id) => {
    try {
      const { data: newData } = await initPage(_Patt, _Id);

      // .start: will delet it
      if (
        newData.data.munf.id === "00000000-0000-0000-0000-000000000000" &&
        newData.data.munf.munfType === 2
      ) {
        newData.data.munf.munfType = 3;
      }
      // .end
      fillData(newData);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  function showConfirmDelete() {
    confirm({
      title: _t("strSureToDelete"),
      icon: <DeleteTwoTone twoToneColor="#eb2f96" />,
      direction: _t("langDiriction"),
      onOk() {
        handleDeleteForm();
      },
      onCancel() {
        return;
      },
    });
  }

  const handleDeleteForm = async () => {
    try {
      const { data: newData } = await deleteManufModels(
        form.getFieldValue().id
      );
      initValue(id, "");
      message.success(newData.message);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    authorization();
    const recourdId = query.get("invId");
    async function fetchData() {
      initValue(id, recourdId ?? "");
    }
    fetchData();
  }, [id]);

  const changeCurr = async (currId, value) => {
    let obj = {
      currId: currId,
      date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      value: value,
    };
    const { data: result } = await changeRate(obj);
    message.success(result.message, 3);
  };

  const fillData = (newData) => {
    if (newData.data.munf !== undefined && newData.data.munf !== null) {
      form.setFieldsValue({
        id:
          newData.data.munf?.id !== null &&
          newData.data.munf?.id !== "00000000-0000-0000-0000-000000000000"
            ? newData.data.munf?.id
            : "",
        doc:
          newData.data.munf?.doc !== null ? newData.data.munf?.doc : undefined,
        pattCode:
          newData.data.munf?.pattCode !== null
            ? newData.data.munf?.pattCode
            : undefined,

        date:
          newData.data.munf?.date !== null
            ? moment(newData.data.munf?.date)
            : moment(),
        inDate:
          newData.data.munf?.inDate !== null
            ? moment(newData.data.munf?.inDate)
            : undefined,
        outDate:
          newData.data.munf?.outDate !== null
            ? moment(newData.data.munf?.outDate)
            : undefined,

        fromStoreId:
          newData.data.munf?.fromStoreId !== null &&
          newData.data.munf?.fromStoreId !==
            "00000000-0000-0000-0000-000000000000"
            ? newData.data.munf?.fromStoreId
            : pattrenSet?.fromStoreDefId,
        toStoreId:
          newData.data.munf?.toStoreId !== null &&
          newData.data.munf?.toStoreId !==
            "00000000-0000-0000-0000-000000000000"
            ? newData.data.munf?.toStoreId
            : pattrenSet?.toStoreDefId,
        currId:
          newData.data.munf?.currId !== null &&
          newData.data.munf?.currId !== "00000000-0000-0000-0000-000000000000"
            ? newData.data.munf?.currId
            : pattrenSet?.currDefId,
        currRate:
          newData.data.munf?.currRate !== null
            ? newData.data.munf?.currRate
            : undefined,
        priceMethodId:
          newData.data.munf?.priceMethodId !== null &&
          newData.data.munf?.priceMethodId !==
            "00000000-0000-0000-0000-000000000000"
            ? newData.data.munf?.priceMethodId
            : pattrenSet?.priceMethodDefId,
        note:
          newData.data.munf?.note !== null
            ? newData.data.munf?.note
            : undefined,

        raw_InvId:
          newData.data.munf?.details?.length === 1 &&
          newData.data.munf?.munfType === 2
            ? newData.data.munf.details[0]?.raw_InvId
            : undefined,
        munf_InvId:
          newData.data.munf?.details?.length === 1 &&
          newData.data.munf?.munfType === 2
            ? newData.data.munf?.details[0]?.munf_InvId
            : undefined,
      });
      setPattrenSet(newData.data.pattSett);

      setDetail(
        newData.data.munf?.munfType === 1 &&
          newData.data.munf?.details?.length > 0 &&
          newData.data.munf?.details !== undefined
          ? [
              ...newData.data.munf?.details,
              { key: newData.data.munf?.details?.length },
            ]
          : [{ key: 0 }]
      );

      setMunfDetail(
        newData.data?.munf?.munfType === 2 &&
          newData.data.munf?.details[0]?.munfMunfMaterials !== undefined &&
          newData.data.munf?.details[0]?.munfMunfMaterials?.length > 0
          ? [
              ...newData.data.munf?.details[0]?.munfMunfMaterials,
              { key: newData.data.munf?.details[0]?.munfMunfMaterials?.length },
            ]
          : [{ key: 0 }]
      );

      setRawDetail(
        newData.data.munf?.munfType === 2 &&
          newData.data.munf?.details[0]?.munfRawMaterials !== undefined &&
          newData.data.munf?.details[0]?.munfRawMaterials?.length > 0
          ? [
              ...newData.data.munf?.details[0]?.munfRawMaterials,
              { key: newData.data.munf?.details[0]?.munfRawMaterials?.length },
            ]
          : [{ key: 0 }]
      );
      setProChange(false);
      setInitVal(newData.data.munf);

      handleFromStoreSearch(
        "",
        newData.data?.munf?.fromStoreId !== null
          ? newData.data?.munf?.fromStoreId
          : ""
      );
      handleToStoreSearch(
        "",
        newData.data?.munf?.toStoreId !== null
          ? newData.data?.munf?.toStoreId
          : ""
      );
      handlePriceMethodSearch("");
      handleSearchCurrency("");
    } else {
      message.info(_t("msgNoRecords"));
    }
  };

  const searchByPattCode = async (popS) => {
    // setOldForm(form.getFieldValue());
    const { data: result } = await getByPattCode(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().pattCode
    );

    if (result.data.munf !== null) {
      fillData(result);
    } else {
      message.info(_t("msgNoRecords"));
    }
  };

  // const searchByPattCode = async () => {
  //   const { data: result } = await getByPattCode(
  //     id,
  //     form.getFieldValue("pattCode")
  //   );
  //   if (result.data !== null) {
  //     fillData(result);
  //   } else {
  //     message.info(_t("msgNoRecords"));
  //   }
  // };

  const handleToStoreSearch = async (_Search, _Active) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null ||
          _Active === undefined ||
          _Active === "" ||
          _Active === "00000000-0000-0000-0000-000000000000"
            ? []
            : [_Active],
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setToStoreOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };
  const handleFromStoreSearch = async (_Search, _Active) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null ||
          _Active === undefined ||
          _Active === "" ||
          _Active === "00000000-0000-0000-0000-000000000000"
            ? []
            : [_Active],
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setFromStoreOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handlePriceMethodSearch = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleGetUtl = () => {
    var url = window.location.pathname;
    return url.substring(url.lastIndexOf("/") + 1);
  };
  const next = async () => {
    try {
      const { data: result } = await browseNext(
        handleGetUtl(),
        form.getFieldValue().id
      );
      if (result.data.munf !== null) {
        fillData(result);
      } else {
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const prev = async () => {
    try {
      const { data: result } = await browsePrev(
        handleGetUtl(),
        form.getFieldValue().id
      );
      if (result.data.munf !== null) {
        fillData(result);
      } else {
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    setLoadData(true);
    const tempObject = munfSaveValidation(
      values,
      id,
      complexDisable,
      detail,
      rawDetail,
      munfDetail
    );

    if (tempObject?.state) {
      try {
        const responce = await editManufModels(tempObject.values);
        message.success(responce.data.message, 3);
        initValue(id, "");
      } catch (error) {
        handleEx(error, { IX_Code, IX_Currency });
      } finally {
        window.sessionStorage.setItem("promptEnt", false);
      }
    }
    setLoadData(false);
  };

  const handleChangeCurr = async (value, row) => {
    try {
      const data = await getRate(
        value,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      form.setFieldsValue({ currRate: data.data.data });
    } catch (error) {
      handleEx(error, { IX_Code, IX_Currency });
    }
    setCompanyCurrId(form.getFieldValue().currId === pattrenSet?.companyCurrId);
  };

  const searchDoc = async (popS) => {
    // setOldForm(form.getFieldValue());
    const result = await getByDoc(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().doc
    );
    if (result !== undefined) {
      if (result?.data?.data?.munf !== null) {
        fillData(result?.data);
      } else {
        message.info(_t("msgNoRecords"));
      }
    }
  };


  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));

  const priceMethodOptList =
    priceMethodOpt.length > 0 &&
    priceMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const fromStoreOptList =
    fromStoreOpt.length > 0 &&
    fromStoreOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const toStoreOptList =
    toStoreOpt.length > 0 &&
    toStoreOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const showConfirmNew = (currId, value, type) => {
    confirm({
      title: type === "curr" ? _t("msgAskSureToChange") : _t("msgAskSureToNew"),
      icon: <ExclamationCircleOutlined />,
      content: type === "curr" ? _t("msSureToChange") : _t("msSureToNew"),
      direction: _t("langDiriction"),
      onOk() {
        if (type === "next") {
          next();
        } else if (type === "prev") {
          prev();
        } else if (type === "pattCode") {
          searchByPattCode();
        } else if (type === "doc") {
          searchDoc();
        } else if (type === "new") {
          initValue(id, "");
        } else if (type === "curr") {
          changeCurr(currId, value);
        }
        window.sessionStorage.setItem("promptEnt", false);
      },
      onCancel() {
        return;
      },
    });
  };

  const handleChangeRate = (currId, rate) => {
    if (pattrenSet?.currChType === 0) {
      if (currId !== undefined) {
        changeCurr(currId, rate);
      }
    } else if (pattrenSet?.currChType === 2) {
      if (currId !== undefined) {
        showConfirmNew(currId, rate, "curr");
      }
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      let showMunfModal = JSON.parse(
        window.sessionStorage.getItem("showMunfModal")
      );
      let showRawModal = JSON.parse(
        window.sessionStorage.getItem("showRawModal")
      );

      if (
        (showModal === false || showModal === null) &&
        (showMunfModal === false || showMunfModal === null) &&
        (showRawModal === false || showRawModal === null)
      ) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "new":
              JSON.parse(window.sessionStorage.getItem("promptEnt"))
                ? showConfirmNew(null, null, "new")
                : initValue(window.location.pathname.replace("/Munf/", ""), "");
              break;
            case "delete":
              let isDelete = form.getFieldValue("id") !== "";
              if (isDelete) showConfirmDelete();
              break;
            case "search":
              setShowSearch(true);
              break;
            case "next":
              JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                ? showConfirmNew(null, null, "next")
                : next();
              break;
            case "prev":
              JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                ? showConfirmNew(null, null, "prev")
                : prev();
              break;
            case "block1":
              IX_Doc?.current?.focus();
              IX_Doc?.current?.select();
              break;
            case "block2":
              if (!complexDisable) {
                setTabNumber("0");
                setTimeout(function () {
                  if (!!gridComplexRef.current)
                    gridComplexRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 0);
              }

              break;
            case "block3":
              if (!manualDisable) {
                setTabNumber("1");

                setTimeout(function () {
                  if (!!gridMunfRef.current)
                    gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 50);
              }

              break;
            case "altQ":
              if (!manualDisable) {
                setTableTabsNumber("0");
                setTimeout(function () {
                  if (!!gridMunfRef.current)
                    gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 50);
              }
              break;
            case "altW":
              if (!manualDisable) {
                setTableTabsNumber("1");
                setTimeout(function () {
                  if (!!gridRawRef.current)
                    gridRawRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 50);
              }
              break;
            case "tag":
              IX_Doc?.current?.focus();
              IX_Doc?.current?.blur();
              if (!!gridComplexRef.current)
                gridComplexRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridMunfRef.current)
                gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridRawRef.current)
                gridRawRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, [manualDisable, complexDisable]);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const handleSearch = (data, type) => {
    // setOldForm(form.getFieldValue());
    if (type === 1) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("code", undefined, undefined, data)
        : searchByPattCode(data);
    } else if (type === 2) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("doc", undefined, undefined, data)
        : searchDoc(data);
    }
  };

  return (
    <div key="mainDiv">
      <ActionSearch
        showModal={showSearch}
        setShowModal={setShowSearch}
        handleData={(e, type) => {
          handleSearch(e, type);
        }}
      />

      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "100px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{
              details: detail,
              munfMunfMaterials: munfDetail,
              munfRawMaterials: rawDetail,
              date: moment(),
              fromStoreId: pattrenSet?.fromStoreDefId,
              toStoreId: pattrenSet?.toStoreDefId,
              priceMethodId: pattrenSet?.priceMethodDefId,
              currId: pattrenSet?.currDefId,
            }}
          >
            <div name="pattCode" className="frHeader">
              <Row>
                <Col xs={24} md={12}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strMunf")}</Text>
                      <Text>{pattrenSet?.branchName}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {pattrenSet?.pattName}
                      </Text>
                      <Space size="large">
                        <div>
                          <Button
                            size="small"
                            onClick={() =>
                              proChange === true
                                ? showConfirmNew(null, null, "prev")
                                : prev()
                            }
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <RightOutlined />
                            ) : (
                              <LeftOutlined />
                            )}
                          </Button>

                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↓ + alt
                            </Tag>
                          </div>
                        </div>

                        <div>
                          <Button
                            size="small"
                            icon={<SearchOutlined />}
                            onClick={() => {
                              setShowSearch(true);
                            }}
                          />
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + F
                            </Tag>
                          </div>
                        </div>

                        <div>
                          <Button
                            size="small"
                            onClick={() =>
                              proChange === true
                                ? showConfirmNew(null, null, "next")
                                : next()
                            }
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <LeftOutlined />
                            ) : (
                              <RightOutlined />
                            )}
                          </Button>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↑ + alt
                            </Tag>
                          </div>
                        </div>
                      </Space>
                    </Space>
                    <div>
                      <Form.Item
                        name="pattCode"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          ref={IX_Code}
                          bordered={false}
                          disabled={!pattrenSet?.codeEnable}
                          onPressEnter={() =>
                            proChange === true
                              ? showConfirmNew(null, null, "pattCode")
                              : searchByPattCode()
                          }
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Form.Item name="id" noStyle hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="munf_InvId" noStyle hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="raw_InvId" noStyle hidden>
                      <Input />
                    </Form.Item>

                    {/* {pattrenSet?.docVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.docVisible}>
                      <div>
                        <Form.Item
                          name="doc"
                          label={_t("strDocument")}
                          rules={[
                            {
                              required: pattrenSet?.docReq,
                              message: `${_t("strIsRequired")}`,
                              validator: async (_, value) => {
                                if ((value === undefined || value === ""|| value === null )&& pattrenSet?.docReq) {
                                  message.error(
                                    `(${_t("strDocument")}) ${_t("strIsRequired")}`
                                  );
                                  throw new Error("message");
                                }
                              },
                            },
                            { min: 1, message: `${_t("strFrom3-200")}` },
                            { max: 200, message: `${_t("strFrom3-200")}` },     
                          ]
                     
                        }
                        >
                          <Search
                            ref={IX_Doc}
                            placeholder={_t("strDocument")}
                            maxLength={200}
                            autoComplete="off"
                            size="small"
                            onSearch={() =>
                              proChange === true
                                ? showConfirmNew(null, null, "doc")
                                : searchDoc()
                            }
                          />
                        </Form.Item>
                        <div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 24,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + 1
                          </Tag>
                        </div>
                      </div>
                    </Col>
                    {/* )} */}

                    {/* {pattrenSet?.fromStoreVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.fromStoreVisible}>
                      <Form.Item
                        name="fromStoreId"
                        label={_t("strFromStore")}
                        rules={[
                          {
                            required: true,
                            validator: async (_, value) => {
                              debugger
                              if (value === undefined || value === "" || value === null) {
                                message.error(
                                  `(${_t("strFromStore")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleFromStoreSearch(value, initVal?.fromStoreId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strFromStore")}
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.fromStoreEnable}
                        >
                          {fromStoreOptList}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* )} */}
                    {/* {pattrenSet?.toStoreVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.toStoreVisible}>
                      <Form.Item
                        name="toStoreId"
                        label={_t("strToStore")}
                        rules={[
                          {
                            required: true,
                            validator: async (_, value) => {
                              if (value === undefined || value === ""|| value === null) {
                                message.error(
                                  `(${_t("strToStore")}) ${_t("strIsRequired")}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleToStoreSearch(value, initVal?.toStoreId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strToStore")}
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.toStoreEnable}
                        >
                          {toStoreOptList}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    {/* {pattrenSet?.dateVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.dateVisible}>
                      <Form.Item
                        name="date"
                        label={_t("strDate")}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          allowClear={false}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.dateEnable}
                        />
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    {/* {pattrenSet?.inDateVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.inDateVisible}>
                      <Form.Item name="inDate" label={_t("strInputDate")}>
                        <DatePicker
                          allowClear={true}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.inDateEnable}
                        />
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    {/* {pattrenSet?.outDateVisible && ( */}
                    <Col xs={24} md={8} hidden={!pattrenSet?.outDateVisible}>
                      <Form.Item name="outDate" label={_t("strOutputDate")}>
                        <DatePicker
                          allowClear={true}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.outDateEnable}
                        />
                      </Form.Item>
                    </Col>
                    {/* )} */}
                    {/* {pattrenSet?.currVisible && ( */}
                    <Col
                      xs={24}
                      md={8}
                      hidden={!pattrenSet?.currVisible || !auth?.perShowPrices}
                    >
                      <Form.Item label={_t("strCurrency")}>
                        <Input.Group compact>
                          <Form.Item
                            name="currId"
                            noStyle
                            rules={[{ required: pattrenSet?.currVisible }]}
                          >
                            <SelectSingle
                              ref={IX_Currency}
                              style={{ width: "70%" }}
                              onChange={(e) => {
                                handleChangeCurr(e);
                                promptForm();
                              }}
                              allowClear={false}
                              fieldNames={{
                                label: "currName",
                                value: "currId",
                              }}
                              placeholder={_t("strNotSelected")}
                              size="small"
                              disabled={!pattrenSet?.currEnable}
                              opt={currencyOpt}
                            />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, curr) =>
                              prev.currId !== curr.currId
                            }
                          >
                            {({ getFieldValue }) => {
                              const _currId = getFieldValue("currId");
                              const state =
                                !pattrenSet?.currEnable ||
                                _currId === pattrenSet?.companyCurrId;

                              return (
                                <Form.Item
                                  name="currRate"
                                  noStyle
                                  rules={[{ required: true }]}
                                >
                                  {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2, num:0 )~*/}
                                  <InputCurrency
                                    precision={objCountNum.rate}
                                    size="small"
                                    style={{ width: "30%" }}
                                    onChange={() => {
                                      promptForm();
                                    }}
                                    placeholder={_t("strRate")}
                                    onBlur={() => {
                                      handleChangeRate(
                                        form.getFieldValue("currId"),
                                        form.getFieldValue("currRate")
                                      );
                                    }}
                                    suffix={
                                      <Tooltip title={_t("msgRateCurrValue")}>
                                        <InfoCircleOutlined
                                          style={{ color: "rgba(0,0,0,.45)" }}
                                        />
                                      </Tooltip>
                                    }
                                    disabled={
                                      state ||
                                      pattrenSet?.currEnable === false ||
                                      pattrenSet?.currChType === 3
                                    }
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    {/* {pattrenSet?.priceMethodVisible && ( */}
                    <Col
                      xs={24}
                      md={8}
                      hidden={
                        !pattrenSet?.priceMethodVisible || !auth?.perShowPrices
                      }
                    >
                      <Form.Item
                        name="priceMethodId"
                        label={_t("strPriceMethod")}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handlePriceMethodSearch(value);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strPriceMethod")}
                          size="small"
                          // onChange={promptForm}

                          onChange={(e) => {
                            promptForm();
                            if (
                              e !== undefined &&
                              emptString(detail[0].munfModelId, false)
                            ) {
                              confirm({
                                title: _t("msgDoYouWantRepriceAllInvoiceItems"),
                                icon: <ExclamationCircleFilled />,
                                direction: _t("langDiriction"),
                                onOk: async () => {
                                  setLoadData(true);
                                  let res = await handelClxPriceMethod(
                                    detail,
                                    emptDate(
                                      form.getFieldValue("outDate"),
                                      form.getFieldValue("date")
                                    )
                                      .toISOString()
                                      .slice(0, 16) + "Z",
                                    e,
                                    form.getFieldValue().currRate === undefined
                                      ? ""
                                      : form.getFieldValue().currRate,
                                    "complex"
                                  );

                                  setDetail(res.length > 0 ? [...res] : detail);
                                  setLoadData(false);
                                },
                                onCancel() {},
                              });
                            }
                            if (
                              e !== undefined &&
                              emptString(rawDetail[0].itemId, false)
                            ) {
                              confirm({
                                title: _t("msgDoYouWantRepriceAllInvoiceItems"),
                                icon: <ExclamationCircleFilled />,
                                direction: _t("langDiriction"),
                                onOk: async () => {
                                  setLoadData(true);
                                  let res = await handelClxPriceMethod(
                                    [
                                      {
                                        munfRawMaterials: rawDetail,
                                        munfMunfMaterials: munfDetail,
                                      },
                                    ],
                                    emptDate(
                                      form.getFieldValue("outDate"),
                                      form.getFieldValue("date")
                                    )
                                      .toISOString()
                                      .slice(0, 16) + "Z",
                                    e,
                                    form.getFieldValue().currRate === undefined
                                      ? ""
                                      : form.getFieldValue().currRate
                                  );

                                  setRawDetail(
                                    res.length > 0
                                      ? res[0].munfRawMaterials
                                      : rawDetail
                                  );
                                  setMunfDetail(
                                    res.length > 0
                                      ? res[0].munfMunfMaterials
                                      : munfDetail
                                  );
                                  setLoadData(false);
                                },
                                onCancel() {},
                              });
                            }
                          }}
                          disabled={!pattrenSet?.priceMethodEnable}
                        >
                          {priceMethodOptList}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* )} */}

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="frContent">
                  <Col xs={24} md={24} style={{ marginTop: 10 }}>
                    <Tabs
                      activeKey={tabNumber}
                      onChange={(e) => {
                        setTabNumber(e);
                      }}
                      style={{ marginTop: -17 }}
                    >
                      {/* {(pattrenSet?.munfType === 3 ||
                        pattrenSet?.munfType === 2) && ( // مركب */}
                      {!complexDisable && ( // مركب
                      <TabPane
                        tab={
                          !JSON.parse(
                            window.sessionStorage.getItem("complexState")
                          ) ? (
                            <div>
                              {_t("strComplex")}
                              <div
                                style={{
                                  opacity: 0.8,
                                  position: "absolute",
                                  top: 24,
                                }}
                              >
                                {!complexDisable && (
                                  <Tag color="#2b2b2b" hidden={!altPress}>
                                    alt + 2
                                  </Tag>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {_t("strComplex")}
                              <div
                                style={{
                                  opacity: 0.8,
                                  position: "absolute",
                                  top: 24,
                                }}
                              ></div>
                            </div>
                          )
                        }
                        key="0"
                        disabled={complexDisable}
                      >
                        <ComplexDetails
                          auth={auth}
                          innerRef={gridComplexRef}
                          pattrenSet={pattrenSet}
                          form={form}
                          currOptTable={currOptTable}
                          setDetail={setDetail}
                          detail={detail}
                          setLoadData={setLoadData}
                          id={id}
                          promptForm={promptForm}
                          noStyle={true}
                          handleChangeRate={handleChangeRate}
                          // currRate={form.getFieldValue().currRate === undefined? "" : form.getFieldValue().currRate}
                          date={
                            emptDate(
                              form.getFieldValue("outDate"),
                              form.getFieldValue("date")
                            )
                              ?.toISOString()
                              ?.slice(0, 16) + "Z"
                          }
                        />
                      </TabPane>
                       )} 

                      {/* {(pattrenSet?.munfType === 3 ||
                        pattrenSet?.munfType === 1) && ( // يدوي */}
                     {!manualDisable && ( // يدوي 
                      <TabPane
                        tab={
                          !JSON.parse(
                            window.sessionStorage.getItem("manualState")
                          ) ? (
                            <div>
                              {_t("strManual")}
                              <div
                                style={{
                                  opacity: 0.8,
                                  position: "absolute",
                                  top: 24,
                                }}
                              >
                                {!manualDisable && (
                                  <Tag color="#2b2b2b" hidden={!altPress}>
                                    alt + 3
                                  </Tag>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {_t("strManual")}
                              <div
                                style={{
                                  opacity: 0.8,
                                  position: "absolute",
                                  top: 24,
                                }}
                              ></div>
                            </div>
                          )
                        }
                        key="1"
                        disabled={manualDisable}
                      >
                        <Tabs
                          activeKey={tableTabsNumber}
                          onChange={(e) => {
                            setTableTabsNumber(e);
                          }}
                          style={{ marginTop: 0 }}
                          tabBarStyle={{ width: 90 }}
                          tabPosition={"right"}
                          type="card"
                        >
                          <TabPane
                            forceRender
                            tab={
                              <div>
                                {_t("strTheOutput")}
                                <div
                                  style={{
                                    opacity: 0.8,
                                    position: "absolute",
                                    top: 24,
                                    zIndex: 999,
                                  }}
                                >
                                  {!manualDisable && (
                                    <Tag color="#2b2b2b" hidden={!altPress}>
                                      alt + Q
                                    </Tag>
                                  )}
                                </div>
                              </div>
                            }
                            key="0"
                          >
                            <MunfDetails
                              auth={auth}
                              innerRef={gridMunfRef}
                              pattrenSet={pattrenSet}
                              form={form}
                              setMunfDetail={setMunfDetail}
                              munfDetail={munfDetail}
                              rawTotal={rawTotal}
                              setLoadData={setLoadData}
                              id={id}
                              promptForm={promptForm}
                              noStyle={true}
                            />
                          </TabPane>
                          <TabPane
                            forceRender
                            tab={
                              <div>
                                {_t("strTheInput")}
                                <div
                                  style={{
                                    opacity: 0.8,
                                    position: "absolute",
                                    top: 24,
                                  }}
                                >
                                  {!manualDisable && (
                                    <Tag color="#2b2b2b" hidden={!altPress}>
                                      alt + W
                                    </Tag>
                                  )}
                                </div>
                              </div>
                            }
                            key="1"
                          >
                            <RawDetails
                              auth={auth}
                              innerRef={gridRawRef}
                              pattrenSet={pattrenSet}
                              form={form}
                              activeKey={tableTabsNumber}
                              setRawDetail={setRawDetail}
                              rawDetail={rawDetail}
                              setMunfDetail={setMunfDetail}
                              munfDetail={munfDetail}
                              setRawTotal={setRawTotal}
                              setLoadData={setLoadData}
                              id={id}
                              promptForm={promptForm}
                              noStyle={true}
                            />
                          </TabPane>
                        </Tabs>
                      </TabPane>
                       )} 
                    </Tabs>
                  </Col>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="middle">
                  <div>
                    <Button
                      type="link"
                      icon={<SaveOutlined />}
                      onClick={() => form.submit()}
                      size="small"
                      disabled={loadData}
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="text"
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        if (proChange === true) {
                          showConfirmNew(null, null, "new");
                        } else {
                          initValue(id, "");
                        }
                      }}
                      // disabled={form.getFieldValue("id") === "" ? true : false}
                      size="small"
                    >
                      {_t("strNew")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + N
                      </Tag>
                    </div>
                  </div>
                  {/* <Button
                      type="text"
                      icon={<PrinterOutlined />}
                      onClick={() => alert("Print inactive.")}
                      disabled={form.getFieldValue("id") === "" ? true : false}
                      size="small"
                    >
                      {_t("strPrint")}
                    </Button> */}
                  <div>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => showConfirmDelete()}
                      disabled={form.getFieldValue("id") === "" ? true : false}
                      size="small"
                      danger
                    >
                      {_t("strDelete")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + shift + delete
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};
export default MunfOrderForm;
