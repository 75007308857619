import { reSumCostCenter } from "../../../../helper/Modal/costCenterModal/costCenterHelper";
import { emptValue, emptString } from "../../../../helper/helperMethods";
import { calcDebet, calcCredit, calcCurrCredit, calcCurrDebet, chackCurrRateDebet, chackCurrRateCredit /*, calcEquivlent, calcCurrRate*/} from "./calcColumns";

export function handleDebet(p, e, obj) {
    obj = p.row;
    obj.debet = emptValue(e.target.value, null);
    obj = calcDebet(obj);
    obj.costCenters = reSumCostCenter(obj.currDebet, p.row?.costCenters);
    obj.mainAccCostCenters = reSumCostCenter(obj.currDebet, p.row?.mainAccCostCenters);
    return obj
};

export function handleCredit(p, e, obj) {
    obj = p.row;
    obj.credit = emptValue(e.target.value, null);
    obj = calcCredit(obj);
    obj.costCenters = reSumCostCenter(obj.currCredit, p.row?.costCenters);
    obj.mainAccCostCenters = reSumCostCenter(obj.currCredit, p.row?.mainAccCostCenters);
    return obj
};


export function handleClearCostCenter(p, obj) {
    obj = p.row;
    obj.costCentersName= null;
    obj.costCenters= null;
    return obj
};

export function handleClearMainCostCenter(p, obj) {
    obj = p.row;
    obj.mainCostCentersName= null;
    obj.mainAccCostCenters= null;
    return obj
};

// export function handleEquivlent(p, e, obj) {
//     obj = p.row;
//     obj.currEquivlent = emptValue(e.target.value, null);
//     obj = calcCurrRate(obj);

//     if (obj.credit)
//         obj = calcCredit(obj);
//     else if (obj.debet)
//         obj = calcDebet(obj);
//     return obj
// };

export function handelCurrRate(p, e, obj) {
    obj = p.row;
    let _valValue = emptValue(e.target.value, null) !== null ? true : false;
    let _valCurrRate = emptValue(p.row?.currRate,"").toString() !== e.target.value ? true : false;
    if (_valValue && _valCurrRate) {
        obj = p.row;
        obj.currRate = emptValue(e.target.value, null);
        //obj = calcEquivlent(obj);
        obj = chackCurrRateDebet(obj);
        obj = chackCurrRateCredit(obj);
        return obj
    }
    else
        return obj
};

export function handleCurrCredit(p, e, obj) {
    obj = p.row;
    obj.currCredit = emptValue(e.target.value, null);
    obj = calcCurrCredit(obj);
    obj.costCenters = reSumCostCenter(obj.credit, p.row?.costCenters);
    return obj
};

export function handleCurrDebet(p, e, obj) {
    obj = p.row;
    obj.currDebet = emptValue(e.target.value, null);
    obj = calcCurrDebet(obj);
    obj.costCenters = reSumCostCenter(obj.debet, p.row?.costCenters);
    return obj
};

export function handleNote(p, e, obj) {
    obj = p.row;
    obj.note = emptString(e.target.value, null);
    return obj
};

export function handleNote1(p, e, obj) {
    obj = p.row;
    obj.note1 = emptString(e.target.value, null);
    return obj
};

export function handleDoc(p, e, obj) {
    obj = p.row;
    obj.doc = emptString(e.target.value, null);
    return obj
};

