import { _SumArr } from "../../../../../helper/handleMath";

export function sumQTY(arr) {
  return _SumArr(arr, "qty");
}

export function sumPrice(arr) {
  return _SumArr(arr, "price");
}

export function sumTotal(arr) {
  return _SumArr(arr, "total");
}

export function sumDiscValue(arr) {
  return _SumArr(arr, "discValue");
}

export function sumAddValue(arr) {
  return _SumArr(arr, "addValue");
}

export function sumSubTotal(arr) {
  return _SumArr(arr, "subTotal");
}

export function sumLostQty(arr) {
  return _SumArr(arr, "lostQty");
}

export function sumFreeQty(arr) {
  return _SumArr(arr, "freeQty");
}
