import { Decimal } from "decimal.js";
import { emptValue } from "./helperMethods";

// جمع طرفين
// n1 + n2
export const _Sum = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.sum(num1, num2).toNumber();
};

// طرح طرفين
// n1 * n2
export const _Sub = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.sub(num1, num2).toNumber();
};

// ضرب طرفين
// n1 * n2
export const _Mul = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.mul(num1, num2).toNumber();
};

// قسمة طرفين
// n1 / n2
export const _Div = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.div(num1, num2).toNumber();
};

// نسبة ضرب من طرفين
// n1 * n2 / 100
export const _RateMul = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.div(Decimal.mul(num1, num2).toNumber(), 100).toNumber();
};

export const _ToFixed = (n1, count) => {


  return Decimal(new Decimal(n1).toFixed(count)).toNumber();    
};

// نسبة قسمة من طرفين
// n1 / n2 * 100
export const _RateDiv = (n1, n2) => {
  let num1 = emptValue(n1, 0);
  let num2 = emptValue(n2, 0);
  return Decimal.mul(Decimal.div(num1, num2).toNumber(), 100).toNumber();
};

// جمع مصفوفة
export const _SumArr = (arr, prop) => {
  if (arr !== undefined || arr?.length > 0)
    return arr?.reduce(
      (init, n) =>
        Decimal.sum(
          init,
          emptValue(
            typeof n?.[prop] === "string"
              ? n?.[prop].replace(/,/g, "")
              : n?.[prop],
            0
          )
        ).toNumber(),
      0
    );
  else return 0;
};

// // جمع مصفوفة مع حساب النسبة بناءَ على صافي القيمة
export const _SumArrWithRate = (arr, propVal, propPerc, subTotal) => {
  if (arr !== undefined || arr?.length > 0)
    return arr?.reduce(
      (init, n) =>
        Decimal.sum(
          init,
          emptValue(n[propVal], 0) !== 0
            ? emptValue(n[propVal], 0)
            : emptValue(_RateMul(subTotal, propPerc), 0)
        ).toNumber(),
      0
    );
  else return 0;
};
