import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  DatePicker,
  Dropdown,
  Typography,
  Spin,
  Popconfirm,
  Tag
} from "antd";
import moment from "moment";
import {
  getBranchById,
  editBranch,
  getActiveParents,
  deleteImage,
  getInitiale
} from "../../../services/branchService";
import { shortCut } from "../../helper/shortCuts";
import { GetActiveStoreChildren } from "../../../services/storeService";
import { ExclamationCircleOutlined, RetweetOutlined, DeleteOutlined } from "@ant-design/icons";
import UploadImg from "../../upload/uploadImg";
import _t from "../../../languages/translate";
import FormStores from "../Definitions/fr_Stores";
import { handleEx } from "../../helper/handleException";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BranchForm = ({ onNewBranch }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const refFrom = useRef(null);
  const refTo = useRef(null);
  const [visibility, setVisibility] = useState(false);
  const [form] = Form.useForm();
  const [avatarPath, setAvatarPath] = useState();
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [statusTo, setStatusTo] = useState("");
  const [statusFrom, setStatusFrom] = useState("");
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const [imageActive, setImageActive] = useState(true);
  const [parentChange, setParentChange] = useState(false);
  const [branchParentOpt, setBranchParentOpt] = useState([]);
  const [storeChildsOpt, setStoreChildsOpt] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [stateParintId, setStateParintId] = useState("");
  const [stateStoreIds, setStateStoreIds] = useState([]);
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const { Text } = Typography;


  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.branch)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const showModal = () => {
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };


  useEffect(() => {
    if (avatarPath === undefined || avatarPath === null) {
      setImageActive(true);
    } else {
      setImageActive(false);
    }
  }, [avatarPath, setAvatarPath]);

  const initValue = async (Id) => {
    try {
      const { data: newData } = await getInitiale(Id);
      setCompanyId(newData.data.companyId);
      form.setFieldsValue({
        code: newData.data.code !== null ? newData.data.code : "",
        parentId: newData.data.parentId !== null ? newData.data.parentId : "",
        storeIds: newData.data.storeIds !== null ? newData.data.storeIds : "",
      });
      handleActiveStores("", newData.data.storeIds !== null ? newData.data.storeIds : "");
      handleActiveParents("", newData.data.parentId !== null ? newData.data.parentId : "");
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
    finally {
      setLoadData(false);
    }
  }

  const getById = async () => {
    try {
      const { data } = await getBranchById(id);
      setCompanyId(data.data.companyId);
      setAvatarPath(data.data.logo);
      if (data.data.parentId === null) {
        data.data.parentId = "";
      }
      setStateParintId(data.data.parentId);
      setStateStoreIds(data.data.storeIds);
      form.setFieldsValue({
        code: data.data.code,
        isMain: data.data.isMain,
        parentId: data.data.parentId,
        name: data.data.name,
        phone: data.data.phone,
        email: data.data.email,
        storeIds: data.data.storeIds,
        note: data.data.note,
        startDate: data.data.startDate !== null ? moment(data.data.startDate) : "",
        endDate: data.data.endDate !== null ? moment(data.data.endDate) : "",
        inactive: data.data.inactive,
        website: data.data.website,
        address: data.data.address,
        taxNum: data.data.taxNum,
      });
      handleActiveStores("", data.data.storeIds !== null ? data.data.storeIds : "");
      handleActiveParents("", data.data.parentId !== null ? data.data.parentId : "");
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
    finally {
      setLoadData(false);
    }

  }

  useEffect(() => {
    authorization()
    async function fetchData() {
      if (id === "new" || typeof onNewBranch === "function") {
        await initValue("");
      }
      else if (id === "add") {
        const parentId = query.get("ParentId");
        //  const parentId = history.location.state?.parentId;
        if (!parentId) return;
        setIsGoParentId(parentId);
        await initValue(parentId);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (id !== "add" && id !== "new" && typeof onNewBranch !== "function") {
        setParentChange(true);
        await getById();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        direction: _t("langDiriction"),
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          initValue(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {

      initValue(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };


  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      if (history.action === "POP") { history.push({ pathname: "/branches" }) }
      else { history.goBack() }
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/branches/new" });
      }
      form.resetFields();
      initValue(isGoParentId);
      handleActiveParents("", "");
      IX_Name.current.focus();
    }
  };

  const handleActiveStores = async (_Search, _Active) => {
    try {
      let obj = { search: _Search, addIds: _Active }
      const { data: storeChildData } = await GetActiveStoreChildren(obj);
      setStoreChildsOpt(storeChildData.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
    finally {
      setLoadData(false);
    }
  };

  const handleActiveParents = async (_Search, _Active) => {
    try {
      const { data: responce } = await getActiveParents(_Search, _Active);
      setBranchParentOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    if (typeof onNewBranch === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    values.logo = avatarPath;
    values.companyId = companyId;
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    if (values.startDate !== "") {
      values.startDate = values.startDate?.toISOString().slice(0, 16) + 'Z';
    }
    if (values.endDate !== "") {
      values.endDate = values.endDate?.toISOString().slice(0, 16) + 'Z';
    }
    try {
      const data = await editBranch(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      if (typeof onNewBranch === "function") {
        onNewBranch(data);
      } else {
        goBack();
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name });
    }
  };

  const handleNewStores = (stores) => {
    const newStores = [...storeChildsOpt, stores.data.data];
    setStoreChildsOpt(newStores);
    setVisibility(false);
    const newList = [...form.getFieldValue().storeIds, stores.data.data.id];
    form.setFieldsValue({
      storeIds: newList,
    });
  };


  const branchParentOptList =
    branchParentOpt.length > 0 &&
    branchParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );

  const storeChildsOptList =
    storeChildsOpt.length > 0 &&
    storeChildsOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const { TextArea } = Input;


  const onImageDelete = async () => {
    try {
      const res = await deleteImage(id !== "new" ? id : "", avatarPath);
      message.success(res.data.message, 3);
      setImageActive(true);
      setAvatarPath(undefined);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
  };


  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);


  const handleDate = (field) => {

    const from = form.getFieldValue("startDate");
    const to = form.getFieldValue("endDate");
    if (to !== null && from !== null) {
      if (to < from) {
        message.error(_t("strErrorDateSelected"));
        if (field === "from") {
          refFrom.current.focus();
          setStatusFrom("error");
        } else {
          refTo.current.focus();
          setStatusTo("error");
        }
      } else {
        setStatusFrom("");
        setStatusTo("");
      }
    } else {
      setStatusFrom("");
      setStatusTo("");
    }
  };


  return (
    <div key="mainDiv">
      <Modal
        visible={visibility}
        onCancel={() => handleCancel()}
        title={_t("strStores")}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <FormStores onNewStore={handleNewStores} />
      </Modal>
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{ isMain: false }}
          >

            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text >{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strBranches")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>



            <Row gutter={3}>
              <Col flex="0 1 100px">
                <div name="logoImage" className="frlogoImage" style={{ padding: "2.6rem 24px 6rem 24px" }}>
                  <Form.Item name="logo" >
                    <UploadImg
                      previewImg={avatarPath}
                      setPreviewImg={setAvatarPath}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </Form.Item>
                  <Popconfirm
                    placement="bottomLeft"
                    title={_t("msgAreYouSureDeleteImage?")}
                    onConfirm={onImageDelete}
                    okText={_t("strYes")}
                    cancelText={_t("strNo")}
                    disabled={imageActive}
                  >
                    <Button type="text" icon={<DeleteOutlined />} disabled={imageActive} >{_t("strRemoveImage")}</Button>
                  </Popconfirm>
                </div>
              </Col>

              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>

                    <Col xs={24} md={12}>
                      <Form.Item
                        label={_t("strBranchClassification")}
                        name="isMain"
                        hidden={typeof onNewBranch === "function"}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio value={false} autoFocus>
                            {_t("strSub")}
                          </Radio>
                          <Radio value={true}>{_t("strMain")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="parentId"
                        label={_t("strMainBranchse")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleActiveParents(value, stateParintId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          onChange={onChangeParent}
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {branchParentOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={
                          <div>
                          {_t("strName")}
                          <div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 4,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + 1
                          </Tag>
                        </div>
                      </div>
                      }
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="phone" label={_t("strPhone")}>
                        <Input
                          placeholder={_t("strPhone")}
                          maxLength={20}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="email"
                        label={_t("strEmail")}
                        rules={[{ type: "email", message: `${_t("strFixEmail")}` }]}
                      >
                        <Input
                          placeholder={_t("strEmail")}
                          maxLength={100}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="website"
                        label={_t("strWebsite")}
                        rules={[
                          { required: false, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={_t("strWebsite")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}

                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="startDate" label={_t("strStartDate")} rules={[{ required: true }]}>
                        <DatePicker
                          ref={refFrom}
                          status={statusFrom}
                          onChange={() => handleDate("from")}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="endDate" label={_t("strEndDate")} rules={[{ required: true }]}>
                        <DatePicker
                          ref={refTo}
                          status={statusTo}
                          onChange={() => handleDate("to")}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="taxNum"
                        label={_t("strTaxNum")}
                        rules={[
                          { required: false, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={_t("strTaxNum")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}

                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="storeIds"
                        label={_t("strStores")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          mode="multiple"
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleActiveStores(value, stateStoreIds);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                              <Button
                                onClick={() => showModal()}
                                hidden={typeof onNewBranch === "function"}
                              >
                                {" "}
                                {_t("strAddNew")}{" "}
                              </Button>
                            </Empty>
                          }
                          size="small"
                          placeholder={_t("strNotSelected")}
                        >
                          {storeChildsOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="address" label={_t("strAddress")}>
                        <TextArea
                          placeholder={_t("strAddress")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          { required: false, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  {auth?.perPut && (
<div>
                    <Button type="primary" htmlType="submit" size="small" disabled={loading}>
                      {_t("strSave")}
                    </Button>
                           <div
                           style={{
                             opacity: 0.8,
                             zIndex: 99,
                             position: "absolute",
                             top: 24,
                           }}
                         >
                           <Tag color="#2b2b2b" hidden={!altPress}>
                             alt + S
                           </Tag>
                         </div>
                       </div>
                  )}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => { history.action === "PUSH" ? history.goBack() : history.push("/branches"); }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>

      </React.Fragment>

    </div>
  );
};

export default BranchForm;
