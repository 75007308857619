import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
  Spin,
  Tag,
} from "antd";
import {
  getClientById,
  editClient,
  getActiveParents,
  getInitiale,
} from "../../services/clientService";
import { getCurrActiveSearch } from "../../services/Items/currenciesService";
import { getActivePayMethodSearch } from "../../services/Items/payMethodService";
import { getActiveAccountSearch } from "../../services/AccountService";
import { getActivePriceMethodSearch } from "../../services/Items/pricingMethodsService";
import { ExclamationCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import SelectAccount from "../helper/inputSelect/selectAccount";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClientForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [form] = Form.useForm();
  const [avatarPath, setAvatarPath] = useState();
  const [loadData, setLoadData] = useState(true);
  const [mainState, setMainState] = useState(false);
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const [parentChange, setParentChange] = useState(false);
  const [clientParentOpt, setClientParentOpt] = useState([]);
  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [currOpt, setCurrOpt] = useState([]);
  const [accountOpt, setAccountOpt] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [stateParintId, setStateParintId] = useState("");
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.client)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const initValue = async (Id, initPP) => {
    try {
      const { data: newData } = await getInitiale(Id);
      setCompanyId(newData.data.companyId);

      if (initPP) {
        if (id === "new") {
          form.setFieldsValue({
            code: newData.data.code !== null ? newData.data.code : "",
            parentId:
              newData.data.parentId !== null &&
              newData.data.parentId !== "00000000-0000-0000-0000-000000000000"
                ? newData.data.parentId
                : undefined,
            currDefId:
              newData.data.currDefId !== null &&
              newData.data.currDefId !== "00000000-0000-0000-0000-000000000000"
                ? newData.data.currDefId
                : undefined,
            priceMethodDefId:
              newData.data.priceMethodDefId !== null &&
              newData.data.priceMethodDefId !==
                "00000000-0000-0000-0000-000000000000"
                ? newData.data.priceMethodDefId
                : undefined,
            payMethodDefId:
              newData.data.payMethodDefId !== null &&
              newData.data.payMethodDefId !==
                "00000000-0000-0000-0000-000000000000"
                ? newData.data.payMethodDefId
                : undefined,
            accId:
              newData.data.accId !== null &&
              newData.data.accId !== "00000000-0000-0000-0000-000000000000"
                ? newData.data.accId
                : undefined,
            //  isMain: newData.data.isMain,
          });
        } else {
          form.setFieldsValue({
            code: newData.data.code !== null ? newData.data.code : "",
          });
        }
      } else {
        form.setFieldsValue({
          code: newData.data.code !== null ? newData.data.code : "",
          parentId:
            newData.data.parentId !== null &&
            newData.data.parentId !== "00000000-0000-0000-0000-000000000000"
              ? newData.data.parentId
              : undefined,
          currDefId:
            newData.data.currDefId !== null &&
            newData.data.currDefId !== "00000000-0000-0000-0000-000000000000"
              ? newData.data.currDefId
              : undefined,
          priceMethodDefId:
            newData.data.priceMethodDefId !== null &&
            newData.data.priceMethodDefId !==
              "00000000-0000-0000-0000-000000000000"
              ? newData.data.priceMethodDefId
              : undefined,
          payMethodDefId:
            newData.data.payMethodDefId !== null &&
            newData.data.payMethodDefId !==
              "00000000-0000-0000-0000-000000000000"
              ? newData.data.payMethodDefId
              : undefined,
          accId:
            newData.data.accId !== null &&
            newData.data.accId !== "00000000-0000-0000-0000-000000000000"
              ? newData.data.accId
              : undefined,
          //   isMain: newData.data.isMain,
          name: newData?.data?.name,
          phone: newData.data.phone,
          email: newData.data.email,
          address: newData.data.address,
          note: newData.data.note,
          inactive: newData.data.inactive,
          website: newData.data.website,
        });
      }

      handleActiveParents(
        "",
        newData.data.parentId !== null ? newData.data.parentId : ""
      );
      handleCurrActiveSearch("");
      handleAccountSearch("");
      handlePriceMethod("");
      handlePayMethod("");
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const getById = async () => {
    try {
      const { data } = await getClientById(id);
      setCompanyId(data.data.companyId);
      setAvatarPath(data.data.logo);
      if (data.data.parentId === null) {
        data.data.parentId = "";
      }
      setStateParintId(data.data.parentId);

      form.setFieldsValue({
        code: data.data.code,
        isMain: data.data.isMain,

        parentId:
          data.data.parentId !== null &&
          data.data.parentId !== "00000000-0000-0000-0000-000000000000"
            ? data.data.parentId
            : undefined,
        currDefId:
          data.data.currDefId !== null &&
          data.data.currDefId !== "00000000-0000-0000-0000-000000000000"
            ? data.data.currDefId
            : undefined,
        priceMethodDefId:
          data.data.priceMethodDefId !== null &&
          data.data.priceMethodDefId !== "00000000-0000-0000-0000-000000000000"
            ? data.data.priceMethodDefId
            : undefined,
        payMethodDefId:
          data.data.payMethodDefId !== null &&
          data.data.payMethodDefId !== "00000000-0000-0000-0000-000000000000"
            ? data.data.payMethodDefId
            : undefined,
        accId:
          data.data.accId !== null &&
          data.data.accId !== "00000000-0000-0000-0000-000000000000"
            ? data.data.accId
            : undefined,
        name: data.data.name,
        phone: data.data.phone,
        email: data.data.email,
        note: data.data.note,
        inactive: data.data.inactive,
        website: data.data.website,
        address: data.data.address,
      });
      handleActiveParents(
        "",
        data.data.parentId !== null ? data.data.parentId : ""
      );
      handleCurrActiveSearch("");
      handleAccountSearch("");
      handlePriceMethod("");
      handlePayMethod("");
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    authorization();
    async function fetchData() {
      if (id === "new") {
        return await initValue("");
      } else if (id === "add") {
        const parentId = query.get("ParentId");
        if (!parentId) return;
        setIsGoParentId(parentId);
        return await initValue(parentId);
      } else {
        setParentChange(true);
        return await getById(id);
      }
    }
    fetchData();
  }, []);

  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        direction: _t("langDiriction"),
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          initValue(value, true);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      initValue(value, true);
      setIsGoParentId(value);
      setProChange(true);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      if (history.action === "POP") {
        history.push({ pathname: "/client" });
      } else {
        history.goBack();
      }
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/client/new" });
      }
      form.resetFields();
      initValue(isGoParentId, false);
      handleActiveParents("", "");
      handleCurrActiveSearch("");
      handleAccountSearch("");
      handlePriceMethod("");
      handlePayMethod("");
      IX_Name.current.focus();
    }
  };

  const handleActiveParents = async (_Search, _Active) => {
    try {
      const { data: responce } = await getActiveParents(_Search, _Active);
      setClientParentOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const handleCurrActiveSearch = async (_Search) => {
    try {
      const { data: responce } = await getCurrActiveSearch(_Search);
      setCurrOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const handleAccountSearch = async (_Search) => {
    try {
      const { data: responce } = await getActiveAccountSearch(_Search);
      setAccountOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const handlePayMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePayMethodSearch(_Search);
      setPayMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const handlePriceMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    values.logo = avatarPath;
    values.companyId = companyId;
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    if (values.startDate !== "") {
      values.startDate = values.startDate?.toISOString().slice(0, 16) + "Z";
    }
    if (values.endDate !== "") {
      values.endDate = values.endDate?.toISOString().slice(0, 16) + "Z";
    }
    try {
      const { data: data } = await editClient(values);
      if (data.status === 200) {
        message.success(data.message, 3);
      }
      goBack();
      form.setFieldsValue({ isMain: mainState });
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const clientParentOptList =
    clientParentOpt.length > 0 &&
    clientParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );
  const currOptList =
    currOpt.length > 0 &&
    currOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));
  const accountOptList =
    accountOpt.length > 0 &&
    accountOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const payMethodOptList =
    payMethodOpt.length > 0 &&
    payMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));
  const priceMethodOptList =
    priceMethodOpt.length > 0 &&
    priceMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const { TextArea } = Input;

  return (
    <div key="mainDiv">
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="clientform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{ isMain: mainState }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {_t("strClientsSupp")}
                      </Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Col xs={24} md={12}>
                      <Form.Item label={_t("strClassification")} name="isMain">
                        <Radio.Group
                          buttonStyle="solid"
                          onChange={(e) => {
                            setMainState(e.target.value);
                          }}
                        >
                          <Radio value={false} autoFocus>
                            {_t("strSub")}
                          </Radio>
                          <Radio value={true}>{_t("strMain")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="parentId"
                        label={_t("strMainClient")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleActiveParents(value, stateParintId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          onChange={onChangeParent}
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {clientParentOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={
                          <div>
                            {_t("strName")}
                            <div
                              style={{
                                opacity: 0.8,
                                zIndex: 99,
                                position: "absolute",
                                top: 4,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + 1
                              </Tag>
                            </div>
                          </div>
                        }
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="phone" label={_t("strPhone")}>
                        <Input
                          placeholder={_t("strPhone")}
                          maxLength={20}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="email"
                        label={_t("strEmail")}
                        rules={[
                          { type: "email", message: `${_t("strFixEmail")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strEmail")}
                          maxLength={100}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="website"
                        label={_t("strWebsite")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={_t("strWebsite")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="accId" label={_t("strAccount")}>
                        <SelectAccount                      
                          addId={form.getFieldValue("accId")}
                          placeholder={_t("strAccount")}                
                          onChange={() => {                      
                              promptForm();                         
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="currDefId"
                        label={_t("strDefaultCurrency")}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleCurrActiveSearch(value);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strDefaultCurrency")}
                          size="small"
                        >
                          {currOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="payMethodDefId"
                        label={_t("strDefaultPaymentMethod")}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handlePayMethod(value);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strDefaultPaymentMethod")}
                          size="small"
                        >
                          {payMethodOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="priceMethodDefId"
                        label={_t("strDefaultPriceMethod")}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handlePriceMethod(value);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strDefaultPriceMethod")}
                          size="small"
                        >
                          {priceMethodOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="address" label={_t("strAddress")}>
                        <TextArea
                          placeholder={_t("strAddress")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  {auth?.perPut && (
                    <div>
                      <Button type="primary" htmlType="submit" size="small">
                        {_t("strSave")}
                      </Button>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + S
                        </Tag>
                      </div>
                    </div>
                  )}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => {
                      history.action === "PUSH"
                        ? history.goBack()
                        : history.push("/client");
                    }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default ClientForm;
