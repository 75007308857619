import React, { useState, useMemo, useEffect } from "react";
import { Input, message, Tooltip } from "antd";
import CostCenterSelector from "../../helper/Modal/costCenterModal/CostCenterSelector";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getSearchPopup } from "../../../services/CostCenterService";
import { emptArr, emptString, emptValue } from "../../helper/helperMethods";
import { InfoCircleOutlined,CloseCircleFilled } from "@ant-design/icons";
import {
  calcAndReturnList,
  reSumCostCenter,
} from "../Modal/costCenterModal/costCenterHelper";
const CostCenterInput = ({
  innerRef,
  zIndex,
  form,
  fieldName,
  arrName,
  accountValidationOpt,
  amount = 0,
  type = "cell",
  value,
  unValue = false,
  onChange,
  disabled=false,
  onHandelData,
  ...rest
}) => {
  const [searchCostValue, setSearchCostValue] = useState();
  const [costCenterValue, setCostCenterValue] = useState([]);
  const [showCostModal, setShowCostModal] = useState(false);
  const [messageCostCenterState, setMessageCostCenterState] = useState("");
  const [inputCostCenterState, setInputCostCenterState] = useState("");
  const [textState, setTextState] = useState(value);
  const { Search } = Input;
 
  const valMethod = (obj) => {
    
    setTextState(obj?.text);
    setMessageCostCenterState(
      obj?.state === "required"
        ? _t("msgErrorAddCostCenter")
        : obj?.state === "distribution"
        ? _t("msgErrorDistrCostCenter")
        : obj?.text
    );

    setInputCostCenterState(
      obj?.state === "required" ? 1 : obj?.state === "distribution" ? 2 : 0
    );
  };

  useEffect(() => {
    const newArr = reSumCostCenter(amount, form.getFieldValue(arrName));
    
   // if (emptArr(newArr, null) !== null) {
      const obj = calcAndReturnList(amount, newArr, accountValidationOpt, unValue);
      form.setFieldsValue({ [arrName]: obj?.list });
      
      valMethod(obj);
   
   // }
  }, [accountValidationOpt, value]);

  useEffect(() => {

    const newArr = reSumCostCenter(amount, form.getFieldValue(arrName));
   // if (emptArr(newArr, null) !== null) {
      const obj = calcAndReturnList(amount, newArr, accountValidationOpt, unValue);
      form.setFieldsValue({ [arrName]: obj?.list });
      valMethod(obj);
      if (type !== "cell"){
        onHandelData(obj?.text)
      }
   // }
  }, [amount]);

  const costCenterSearch = async (e) => {
    try {
      const { data: result } = await getSearchPopup(e);
      if (result.data.length > 0) {
        if (
          result.data.length === 1 &&
          emptArr(form.getFieldValue(arrName), null) === null
        ) {
          
          result.data[0].perc = 100;
          const newArr = reSumCostCenter(amount, result.data);
          if (emptArr(newArr, null) !== null) {
            // newArr[0].perc = 100;
            
            
            const tempObj = calcAndReturnList(
              amount,
              newArr,
              accountValidationOpt,
              unValue
            );

            valMethod(tempObj);

            let object = [
              {
                costCenterId: tempObj?.list[0].costCenterId,
                costCenterName: tempObj?.list[0].costCenterName,
                perc: tempObj?.list[0].perc,
                value:tempObj?.list[0].value
              },
            ];

            form.setFieldsValue({
              [fieldName]: tempObj?.list[0].costCenterName,
              [arrName]: object,
            });

            let obj = {
              text: tempObj?.list[0].costCenterName,
              list: object,
            };

            setTextState(obj?.text);
            if (type === "cell") {
              onHandelData(obj);
            }
          }
        } else {
          setCostCenterValue(form.getFieldValue(arrName));
          setSearchCostValue(e);
          window.sessionStorage.setItem("showCostModal", true);
          setShowCostModal(true);
        }
      } else {
        message.info(_t("msgNotFoundSearchResult"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleCostCenterData = (obj) => {
    form.setFieldsValue({
      [arrName]: obj?.list,
    });
    setTextState(obj?.text);
    valMethod(obj);
    setShowCostModal(false);
    setCostCenterValue([]);
    setSearchCostValue("");
    if (type === "cell") {
      onHandelData(obj);
    }
    window.sessionStorage.setItem("showCostModal", false);
  };

  const handleChange = (e) => {
    onChange && onChange(e);
  };

  return (
    <>
      <CostCenterSelector
        unValue={unValue}
        searchValue={searchCostValue}
        costCenterValue={costCenterValue}
        onHandelData={handleCostCenterData}
        showModal={showCostModal}
        setShowModal={setShowCostModal}
        amount={amount}
        validationOpt={accountValidationOpt}
      />

      <Search
        ref={innerRef}
        tabIndex={zIndex}
        size="small"
        value={value}
        onFocus={() => {      
           let costText = form.getFieldValue([fieldName]);      
           if (emptString(costText,null) !== null ) {
             setTextState(form.getFieldValue([fieldName]));
           }

          form.setFieldsValue({ [fieldName]: undefined });

        }}
        onBlur={() => {
           form.setFieldsValue({ [fieldName]: textState });
        }}
        suffix={!disabled &&
          <CloseCircleFilled 
          
          className="clearIcon"
            onClick={async () => {
              form.setFieldsValue({
                [fieldName]: undefined,
                [arrName]: undefined,
              });
              onHandelData()
            }}
          />
        }
        prefix={
          <Tooltip
            color={emptValue(inputCostCenterState, 0) === 0 ? "" : "red"}
            title={messageCostCenterState}
          >
            <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        }
        status={emptValue(inputCostCenterState, 0) === 0 ? "" : "error"}
        // status={reqMessage !== undefined ? "error" : ""}
        onSearch={costCenterSearch}
        onChange={handleChange}
        autoComplete="off"
        style={{ width: "100%" }}
        disabled={disabled}
        {...rest}
      />
    </>
  );
};

export default CostCenterInput;
