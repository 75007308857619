
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Munf";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}


export function initPage(Patt, Id) {
  return http.get(`${apiEndpoint}/?patt=${Patt}&id=${Id}`);
}

export function editManufModels(obj) {
   if (obj.id !== "") {
     return http.put(apiEndpoint, obj);
   } else {
  return http.post(apiEndpoint, obj);
   }
}

export function getDetailsByMunfModel(obj, date, patt, currId, rate, priceMethodId, fromStore, toStore) {
  return http.post(`${apiEndpoint}/GetDetailsByMunfModel/?date=${date}&patt=${patt}&currId=${currId}&rate=${rate}&priceMethodId=${priceMethodId}&fromStore=${fromStore}&toStore=${toStore}`, obj);
}

export function getMunfMaterialsByUnit(obj/*, date, priceMethodId, rate*/) {
  
  return http.post(`${apiEndpoint}/GetMunfMaterialsByUnit/`/*?date=${date}&priceMethod=${priceMethodId}&rate=${rate}`*/, obj);
}

export function getRawMaterialsByUnit(obj, date, priceMethodId, rate) {
  return http.post(`${apiEndpoint}/GetRawMaterialsByUnit/?date=${date}&priceMethod=${priceMethodId}&rate=${rate}`, obj);
}





export function browseNext(Patt, Id) {

  return http.post(`${apiEndpoint}/GetNext/?patt=${Patt}&id=${Id === undefined ? "" : Id}`);
}

export function browsePrev(Patt, Id) {

  return http.post(`${apiEndpoint}/GetPrev/?patt=${Patt}&id=${Id === undefined ? "" : Id}`);
}

export function getByPattCode(Patt, pattCode) {

  return http.post(`${apiEndpoint}/GetByPattCode/?patt=${Patt}&pattCode=${pattCode}`);
}

export function getByDoc(Patt, doc) {

  return http.post(`${apiEndpoint}/GetByDoc/?patt=${Patt}&doc=${doc}`);
}



export function setDefaultItem(Id) {
  return http.post(
    `${apiEndpoint}/SetDefaultItem?MunfTemplateOutDetailId=${Id}`
  );
}

export function deleteManufModels(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getManufModels() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllManufacturingModels() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewManufModelsCode() {
  return http.post(`${apiEndpoint}/GetNewCode/`);
}

export function getPagerManufModels(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function searchManufModels(obj) {
  return http.post(`${apiEndpoint}/GetPagerSearch/`, obj);
}

export function getActiveTreeManufModels() {
  return http.post(`${apiEndpoint}/GetActiveTree`);
}
