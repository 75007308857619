import React from "react";
import { Select, Empty } from "antd";
import _t from "../../languages/translate";
import { validateSelectFilter } from "../helper/helperMethods";

const SelectSingle = ({
  innerRef,
  zIndex,
  placeholder = _t("strNotSelected"),
  noStyle = false,
  opt = [],
  fieldNames = { label: "name", value: "id" },
  onChange,
  allowClear = true,
  ...rest
}) => {
  return (
    <Select
      ref={innerRef}
      tabIndex={zIndex}
      style={{ minWidth: 160 }}
      allowClear={allowClear}
      size="small"
      showSearch
      optionFilterProp="children"
      notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      fieldNames={fieldNames}
      filterOption={(search, item) => {
        return validateSelectFilter(search, item);
      }}
      placeholder={placeholder}
      onChange={onChange}
      options={opt}
      {...rest}
    />
  );
};

export default SelectSingle;
