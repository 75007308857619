import { emptValue, emptString } from "../../../../helper/helperMethods";
import { _Mul, _Sum, _Div, _RateMul } from "../../../../helper/handleMath";
import { getPriceByUnit } from "../../../../../services/priceListService";
import { handleEx } from "../../../../helper/handleException";

export const calcLostQty = (obj) => {
  if (emptValue(obj.lostQty, 0) === 0 && emptValue(obj.lostPrice, 0) !== 0)
    obj.lostQty = emptValue(_Div(obj.lostPriceTotal, obj.lostPrice), null);
  return obj;
};

export const calcLostTotal_new = async (obj) => {
  obj.lostPriceTotal = emptValue(_Mul(obj.lostPrice, obj.lostQty), null);
  obj = await calcNetTotal(obj);
  return obj;
};

export const calcEmptyLostPec = (obj) => {
  obj.lostPerc = null;
  return obj;
};

export const calcLostQtyByPerc = async (obj, date, priceMethod, rate) => {
  obj.lostQty = emptValue(_RateMul(obj.qtyTotal, obj.lostPerc), null);
  if (emptValue(obj.lostPrice, null) == null) {
    obj = await handleItemLostUnitFn(obj, date, priceMethod, rate);
  }
  obj = await calcLostTotal_new(obj);
  return obj;
};

export const calcQty = (obj, count) => {
  
  obj.qty = emptValue(_Div(obj.qtyTotal, count), null);
  
  return obj;
};

export const calcQtyTotal = async (obj, count) => {
  if (emptValue(obj.qtyTotal, 0) === 0 && emptValue(obj.price, 0) !== 0)
    obj.qtyTotal = emptValue(_Div(obj.total, obj.price), null);
  if (emptValue(obj.lostPerc, 0) !== 0) obj = await calcLostQtyByPerc(obj); //~~ak
  obj = await calcQty(obj, count);
  return obj;
};

export const calcQtyTotalByCount = async (obj, count) => {
  obj.qtyTotal = emptValue(_Mul(count, obj.qty), null);
  if (emptValue(obj.lostPerc, 0) !== 0) obj = await calcLostQtyByPerc(obj);
  if(emptValue(obj.price, 0) !== 0) obj = await calcTotal_new(obj);

  return obj;
};

export const calcPrice = (obj) => {
  
  if (emptValue(obj.qtyTotal, null) !== null)
    obj.price = emptValue(_Div(obj.total, obj.qtyTotal), null);
  return obj;
};

export const calcLostPrice = (obj) => {
  if (emptValue(obj.lostQty, 0) !== 0)
    obj.lostPrice = emptValue(_Div(obj.lostPriceTotal, obj.lostQty), null);
  return obj;
};

export const calcTotal_new = async (obj) => {
  
  obj.total = emptValue(_Mul(obj.price, obj.qtyTotal), null);
  obj = await calcNetTotal(obj);
  return obj;
};

export const setLostPrice = async (obj) => {
  if (
    emptString(obj.lostItemUnit_MapId, obj.itemUnit_MapId) ===
    obj.itemUnit_MapId
  ) {
    if (emptValue(obj.lostQty, 0) !== 0) obj.lostPrice = obj.price;
    else obj.lostPrice = 0;
    obj = await calcLostTotal_new(obj);
  }
  return obj;
};

export const calcNetTotal = async (obj) => {
  obj.netTotal = await emptValue(_Sum(obj.total, obj.lostPriceTotal), null);
  return obj;
};

export const getItemPriceByUnit = async (itemUnit, date, priceMethod, rate) => {
  if (itemUnit !== null) {
    try {
      if (emptString(priceMethod, null) === null) {
        return 0;
      } else {
        const { data: result } = await getPriceByUnit(
          itemUnit,
          date,
          priceMethod,
          rate
        );
        return result?.data;
      }
    } catch (error) {
      handleEx(error);
    }
  }
};

export const handleItemLostUnitFn = async (obj, date, priceMethod, rate) => {
  if (emptValue(obj.lostQty, null) !== null) {
    if (
      emptString(obj.lostItemUnit_MapId, obj.itemUnit_MapId) ===
      obj.itemUnit_MapId
    ) {
      obj.lostPrice = obj.price;
    } else {
      let lostPriceVal = await getItemPriceByUnit(
        obj.lostItemUnit_MapId,
        date,
        priceMethod,
        rate
      );
      obj.lostPrice = lostPriceVal;
    }
  } else {
    obj.lostPrice = 0;
  }
  obj = await handleLostPrice_newFn(obj);
  return obj;
};

export const handleLostPrice_newFn = async(obj) => {
  
  if (emptValue(obj.lostQty, 0) === 0) obj = await calcLostQty(obj);
  else obj = await calcLostTotal_new(obj);
  return obj;
};
