
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/EntPatt";

export function getEntPatt(query) {
  return http.post(`${apiEndpoint}/GetTreeRep?search=${query}`);
}


export function initiale(id) {
  return http.post(`${apiEndpoint}/Initiale?entType=${id}`);
}

export function getNewCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function editPattEnt(obj) {
  
  if (obj.id !== "new" && obj.id !== undefined ) {
    return http.put(apiEndpoint, obj);
  } else {
   return http.post(apiEndpoint, obj);
 }
}

export function getEntPattById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function deleteEntPatt(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}


