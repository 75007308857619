import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

/***********************************| Services |*****************************************/
import ProdtectedRoute from "./components/route/protectedRoute";
import { getCurrentUser } from "./services/Authorization/authService";

/************************************| forms |******************************************/
import FormUsers from "./components/forms/Authorization/fr_Users";
import FormGeneralRoles from "./components/forms/Authorization/fr_generalRoles";
import FormCompanies from "./components/forms/Definitions/fr_Companies"
import FormBranches from "./components/forms/Definitions/fr_Branches";
import FormStores from "./components/forms/Definitions/fr_Stores";
import FormUnit from "./components/forms/fr_Unit";
import FormUserAccRole from "./components/forms/UserRoleAccounts/fr_UserAccRole";
import FormCurrencies from "./components/forms/fr_Currencies";
import FormItem from "./components/forms/Items/fr_Item";
import FormCurrencyRate from "./components/forms/fr_CurrencyRate";
import FormPricingMethods from "./components/forms/fr_PricingMethods";
import FormPayMethod from "./components/forms/fr_PayMethod";
import FormAccount from "./components/forms/fr_Account";
import FormFinalAccount from "./components/forms/fr_FinalAccount";
import FormItemFinalAccount from "./components/forms/fr_ItemFinalAccount";
import FormCostCenter from "./components/forms/fr_CostCenter";
import FormPriceList from "./components/forms/fr_PriceList";
import FormJournal from "./components/forms/fr_Journal";
import FormClient from "./components/forms/fr_Client";
import FormEntry from "./components/forms/Entry/fr_Entry";
import FormEntryBrowse from "./components/forms/Entry/fr_EntryBrowse";
import FormInvoice from "./components/forms/Invoice/fr_Invoice";
import FormMunfModel from "./components/forms/Munf/fr_MunfModel";
import FormMunfOrder from "./components/forms/Munf/fr_MunfOrder";
import FormImportFromXls from "./components/forms/fr_ImportFromXls";
import FormInvPatt from "./components/forms/InvPattren/fr_InvPatt";
import FormEntPatt from "./components/forms/entPattren/fr_entPatt";
 import FormMunfPatt from "./components/forms/munfPattren/fr_munfPatt";
import FormPrintPatt from "./components/forms/printPattren/fr_printPatt";

/************************************| pages |******************************************/
import Login from "./components/pages/Authorization/pg_Login";
import Logout from "./components/pages/Authorization/esc_logout";
import PageUsers from "./components/pages/Authorization/pg_Users";
import PageGeneralRoles from "./components/pages/Authorization/pg_generalRoles";
import PageBranches from "./components/pages/pg_Branches";
import PageStores from "./components/pages/pg_Stores";
import PageUnits from "./components/pages/pg_Units"
import PageUserAccRole from "./components/pages/pg_UserAccRole"
import PageCurrencies from "./components/pages/pg_Currencies";
import PageItems from "./components/pages/pg_Items";
import PageCurrencyRate from "./components/pages/pg_CurrencyRate";
import PagePricingMethods from "./components/pages/pg_PricingMethods";
import PagePayMethod from "./components/pages/pg_PayMethod";
import PageAccount from "./components/pages/pg_Account";
import PageInvPatt from "./components/pages/pg_InvPatt";
import PageEntPatt from "./components/pages/pg_EntPatt";
 import PageMunfPatt from "./components/pages/pg_MunfPatt";
import PagePrintPatt from "./components/pages/pg_PrintPatt";
import PageFinalAccount from "./components/pages/pg_FinalAccount";
import PageItemFinalAccount from "./components/pages/pg_ItemsFinalAccount";
import PageCostCenter from "./components/pages/pg_CostCenter";
import PagePriceList from "./components/pages/pg_PriceList";
import PageJournal from "./components/pages/pg_Journal";
import PageClient from "./components/pages/pg_Client";
import PageMunfModel from "./components/pages/pg_MnufModels";
import PageHomePage from "./components/pages/pg_HomePage";
/****************************************************************************************/
import ReportLedger from "./components/reports/rep_Ledger"
import ReportItemsMove from "./components/reports/rep_ItemsMove"
import ReportItemInStock from "./components/reports/rep_ItemInStock"
import ReportInvoices from "./components/reports/rep_Invoices"
import ReportMunf from "./components/reports/rep_Munf"
import ReportTrialBalance from "./components/reports/rep_TrialBalance"
import ReportJournal from "./components/reports/rep_Journal"
/****************************************************************************************/
import ChangePassword from "./components/pages/Authorization/pg_ChangePassword";
import PageNotFound from "./components/pages/pg_PageNotFound";


const RouteDom = () => {
    const user = getCurrentUser();
    return (

        <Switch>

            <ProdtectedRoute path="/users/:id" render={() => <FormUsers />} />

            <ProdtectedRoute path="/users" render={() => <PageUsers />} />

            <ProdtectedRoute path="/generalRoles/:id" render={() => <FormGeneralRoles />} />

            <ProdtectedRoute path="/generalRoles" render={() => <PageGeneralRoles />} />

            <ProdtectedRoute path="/changePassword" render={() => <ChangePassword />} />

            <ProdtectedRoute path="/companies/:id" render={() => <FormCompanies />} />

            <ProdtectedRoute path="/branches/:id" render={() => <FormBranches />} />

            <ProdtectedRoute path="/branches" render={() => <PageBranches />} />

            <ProdtectedRoute path="/stores/:id" render={() => <FormStores />} />

            <ProdtectedRoute path="/stores" render={() => <PageStores />} />

            <ProdtectedRoute path="/units/:id" render={() => <FormUnit />} />

            <ProdtectedRoute path="/units" render={() => <PageUnits />} />

            <ProdtectedRoute path="/currencies/:id" render={() => <FormCurrencies />} />

            <ProdtectedRoute path="/currencies" render={() => <PageCurrencies />} />

            <ProdtectedRoute path="/items/:id" render={() => <FormItem />} />     

            <ProdtectedRoute path="/items" render={() => <PageItems />} />

            <ProdtectedRoute path="/importExcel" render={() => <FormImportFromXls />} />
            
            <ProdtectedRoute path="/currencyRate/:id" render={() => <FormCurrencyRate />} />

            <ProdtectedRoute path="/currencyRate" render={() => <PageCurrencyRate />} />

            <ProdtectedRoute path="/pricingMethod/:id" render={() => <FormPricingMethods />} />

            <ProdtectedRoute path="/pricingMethod" render={() => <PagePricingMethods />} />

            <ProdtectedRoute path="/payMethod/:id" render={() => <FormPayMethod />} />

            <ProdtectedRoute path="/payMethod" render={() => <PagePayMethod />} />

            <ProdtectedRoute path="/account/:id" render={() => <FormAccount />} />

            <ProdtectedRoute path="/account" render={() => <PageAccount />} />

            <ProdtectedRoute path="/finalAcc/:id" render={() => <FormFinalAccount />} />

            <ProdtectedRoute path="/finalAcc" render={() => <PageFinalAccount />} />
            
            <ProdtectedRoute path="/itemFinalAcc/:id" render={() => <FormItemFinalAccount />} />

            <ProdtectedRoute path="/itemFinalAcc" render={() => <PageItemFinalAccount />} />
            
            <ProdtectedRoute path="/costCenter/:id" render={() => <FormCostCenter />} />

            <ProdtectedRoute path="/costCenter" render={() => <PageCostCenter />} />

            <ProdtectedRoute path="/priceList/:id" render={() => <FormPriceList />} />

            <ProdtectedRoute path="/priceList" render={() => <PagePriceList />} />

            <ProdtectedRoute path="/journal/:id" render={() => <FormJournal />} />

            <ProdtectedRoute path="/journal" render={() => <PageJournal />} />

            <ProdtectedRoute path="/client/:id" render={() => <FormClient />} />

            <ProdtectedRoute path="/client" render={() => <PageClient />} />

            <ProdtectedRoute path="/entry/:id" render={() => <FormEntry />} />

            <ProdtectedRoute path="/entryBrowse/:id" render={() => <FormEntryBrowse />} />

            <ProdtectedRoute path="/entryBrowse" render={() => <FormEntryBrowse />} />

            <ProdtectedRoute path="/inv/:id" render={() => <FormInvoice />} />

            <ProdtectedRoute path="/munfModel/:id" render={() => <FormMunfModel />} />

            <ProdtectedRoute path="/munfModel" render={() => <PageMunfModel />} />

            <ProdtectedRoute path="/munf/:id" render={() => <FormMunfOrder />} />

            <ProdtectedRoute path="/munf" render={() => <FormMunfOrder />} />

            <ProdtectedRoute path="/invPatt/:id" render={() => <FormInvPatt />} />
            <ProdtectedRoute path="/invPatt" render={() => <PageInvPatt />} />

            <ProdtectedRoute path="/entPatt/:id" render={() => <FormEntPatt />} />
            <ProdtectedRoute path="/entPatt" render={() => <PageEntPatt />} />

            <ProdtectedRoute path="/printPatt/:id" render={() => <FormPrintPatt />} />
            <ProdtectedRoute path="/printPatt" render={() => <PagePrintPatt />} />

             <ProdtectedRoute path="/munfPatt/:id" render={() => <FormMunfPatt />} /> 
            <ProdtectedRoute path="/munfPatt" render={() => <PageMunfPatt />} />

            <ProdtectedRoute path="/accRoles/:id" render={() => <FormUserAccRole />} />
            <ProdtectedRoute path="/accRoles" render={() => <PageUserAccRole />} />

            <ProdtectedRoute path="/ledger" render={() => <ReportLedger />} />
            <ProdtectedRoute path="/repItemsMove" render={() => <ReportItemsMove />} />
            <ProdtectedRoute path="/repItemInStock" render={() => <ReportItemInStock />} />
            <ProdtectedRoute path="/repInvoices" render={() => <ReportInvoices />} />
            <ProdtectedRoute path="/repMunf" render={() => <ReportMunf />} />
            <ProdtectedRoute path="/repTrialBalance" render={() => <ReportTrialBalance />} />
            <ProdtectedRoute path="/repJournal" render={() => <ReportJournal />} />
            <ProdtectedRoute path="/homepage" render={() => <PageHomePage />} />
            <ProdtectedRoute path="/404" render={() => <PageNotFound />} />

            {/* <ProdtectedRoute path="/entry/:id/:entId" render={() => <Form_Entry />} /> */}
                     
            {!user && (<Route exact path="/login"> <Login /></Route>)}

            <Route exact path="/logout"><Logout /></Route>

            <Redirect from="/" exact to={user ? process.env.REACT_APP_PATH_NAME : "/login"} />

            <Redirect to={user ? "/404" : "/login"} />
        </Switch>

    )
};
export default RouteDom;