import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    message,
    Space,
    Row,
    Col,
    Spin,
    Tabs,
    Typography
} from "antd";
import _t from "../../../languages/translate";
import TreeTableSelect from "../../helper/treeTableSelect/treeTableSelect";
import { getInitiale, getGeneralRolesById, editGeneralRoles } from "../../../services/Authorization/generalRolesService"
import { handleEx } from "../../helper/handleException";

import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";

const GeneralRoles = () => {
    const IX_Code = useRef(null);
    const IX_Name = useRef(null);

    const [loading] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [tabsRole, setTabsRole] = useState([]);
    const [form] = Form.useForm();
    const [proChange, setProChange] = useState(false);

    let history = useHistory();
    let { id } = useParams();
    const { Text } = Typography;
    const { TabPane } = Tabs;

 //////////----|👉 start auth|----//////////
    // perDelete
    // perExcel
    // perPdf
    // perPost
    // perPrint
    // perPut
    // perView
    //-----------------------------------------
    const [auth, setAuth] = useState({});
    const authorization = async () => {
        const obj = JSON.parse(localStorage.getItem("auth"));
        if (obj !== null) {
            const responce = obj.filter((e) => e?.roleKey === authKeys.role)[0];
            setAuth(responce);
        } else {
            try {
                const data = await getAuth();
                localStorage.setItem("auth", JSON.stringify(data.data.data));
                authorization();
            } catch (ex) {
                return null;
            }
        }
    };
    //////////----|👉 end auth|----//////////

    const promptForm = () => {
        setProChange(true);
    };


    useEffect(() => {
        authorization()
        async function fetchData() {
            if (id === "new") {
                try {
                    const { data: newData } = await getInitiale();
                    form.setFieldsValue({
                        code: newData.data.code,
                        name: newData.data.name,
                        note: newData.data.note,
                        inactive: newData.data.inactive,
                    });
                    setTabsRole(newData.data.roleTypes);
                    
                    IX_Name.current.focus();
                    setLoadData(false);
                } catch (error) {
                    handleEx(error,{IX_Code, IX_Name});
                }
            } else {
                 
                    try {
                        const { data: newData } = await getGeneralRolesById(id);
                        form.setFieldsValue({
                            code: newData.data.code,
                            name: newData.data.name,
                            note: newData.data.note,
                            inactive: newData.data.inactive,
                        });
                        setTabsRole(newData.data.roleTypes);
                        IX_Name.current.focus();
                        setLoadData(false);
                    } catch (error) {
                        handleEx(error,{IX_Code, IX_Name});
                    }
                
            }
        }
        fetchData();
    }, [id, history, form]);


    const goBack = () => {
        setProChange(false);
        history.goBack();
    };


    const handleObj = (obj) => {
        return obj.flatMap(a => a.userRoleDetails.dataSource);
    }

    const onFinish = async (values) => {
        values.id = id;
        let details = handleObj(tabsRole);
        values.UserRoleDetails = details;
        try {
            const data = await editGeneralRoles(values);
            message.success(data.data.message, 3);
            if (data.data.code === 200) {
                goBack();
            }
        } catch (error) {
            handleEx(error,{IX_Code, IX_Name});
        }
    };


    return (
        <React.Fragment>
            <Prompt when={proChange} message={_t("strUnsavedChanges")} />
            <Spin spinning={loadData} tip={_t("strLoading")}>
                <Form
                    form={form}
                    name="generalRolesform"
                    onFinish={onFinish}
                    layout="horizontal"
                >

                    <div name="code" className="frHeader">
                        <Row>
                            <Col xs={24} md={24}>
                                <Space split="#">
                                    <Space split="\">
                                        <Text >{_t("strSecurity")}</Text>
                                        <Text style={{fontWeight:700}}>{_t("strGeneralRoles")}</Text>
                                    </Space>
                                    <Form.Item
                                        name="code"
                                        rules={[
                                            { required: true, message: `${_t("strIsRequired")}` },
                                            { min: 1, message: `${_t("strFrom3-200")}` },
                                            { max: 200, message: `${_t("strFrom3-200")}` },
                                        ]}
                                    >
                                        <Input
                                            className="inpCode"
                                            size="small"
                                            placeholder={_t("strCode")}
                                            maxLength={200}
                                            autoComplete="off"
                                            onChange={promptForm}
                                            ref={IX_Code}
                                            bordered={false}
                                        />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </div>

                    <div name="field" className="frContent">
                        <Row gutter={12}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="name"
                                    label={_t("strName")}
                                    rules={[
                                        { required: true, message: `${_t("strIsRequired")}` },
                                        { min: 1, message: `${_t("strFrom3-200")}` },
                                        { max: 200, message: `${_t("strFrom3-200")}` },
                                    ]}
                                >
                                    <Input
                                        size="small"
                                        placeholder={_t("strName")}
                                        maxLength={200}
                                        autoComplete="off"
                                        onChange={promptForm}
                                        ref={IX_Name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="note"
                                    label={_t("strNote")}
                                    rules={[
                                        { required: false, message: `${_t("strIsRequired")}` },
                                        { min: 1, message: `${_t("strFrom1-1000")}` },
                                        { max: 1000, message: `${_t("strFrom1-1000")}` },
                                    ]}
                                >
                                    <Input
                                        size="small"
                                        placeholder={_t("strNote")}
                                        maxLength={200}
                                        autoComplete="off"
                                        onChange={promptForm}

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div name="tables" className="frTabX">
                        <Tabs >
                            {tabsRole.map(pane => (
                                <TabPane tab={pane.title} key={pane.key}>
                                    <TreeTableSelect columns={pane.userRoleDetails.columns} dataSource={pane.userRoleDetails.dataSource} /*onHandelData={handleData}*/ />
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>

                    <div className="frFooter">
                        <Form.Item>
                            <Space size="large">
                            {/* {auth?.perPut && ( */}
                                <Button type="primary" htmlType="submit" disabled={loading} size="small" style={{ width: 150, borderRadius: 5 }}>
                                    {_t("strSave")}
                                </Button>
                            {/* )} */}
                                <Button type="default" onClick={() => history.goBack()} disabled={loading} size="small" >
                                    {_t("strBack")}
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Form>
            </Spin>

        </React.Fragment>
    );
};

export default GeneralRoles;
