import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  message,
  Card,
  Tooltip,
  Input,
  Button,
  Popover,
  Divider,
} from "antd";
import { getRawMaterialsByUnit } from "../../../../services/munfOrderService";
import {
  FileSearchOutlined,
  DiffOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _t from "../../../../languages/translate";
import { handleEx } from "../../../helper/handleException";
import CheckQtyItems from "../../../helper/Modal/CheckQtyItems";
import CheckPriceItems from "../../../helper/Modal/CheckPriceItems";
import ItemInvSelector from "../../../helper/Modal/ItemInvSelector";
import { getSearchPopup } from "../../../../services/Items/itemService";
import { convertPriceByUnit } from "../../../../services/Items/itemUnitService";
import { emptValue, emptString } from "../../../helper/helperMethods";
import DropDownList from "../../../helper/dataGrid/source/DropDownList";
import CurrEditor from "../../../helper/dataGrid/source/CurrEditor";
import { TextEditor } from "react-data-grid";
import DataGrid from "react-data-grid";
import { getNumFormat } from "../../../helper/countOfZero";
import {
  sumQty,
  sumPrice,
  sumTotal,
  sumLostQty,
  sumQtyTotal,
  sumLostPrice,
  sumLostPriceTotal,
  sumNetTotal,
} from "./handleColumns/sumColumns";
import { calcQtyTotalByCount } from "./handleColumns/calcColumns";
import {
  handleQty,
  handlePrice,
  handleTotal,
  handleLostPerc,
  handleLostQty,
  handleLostPrice,
  handleLostTotal,
  handleQtyTotal,
  handleItemLostUnit,
  handleItemUnit,
} from "./handleColumns/handleColumns";
import { _Mul, _Sum, _Div, _RateMul } from "../../../helper/handleMath";
import { calcTotal_munf } from "../MunfGrid/handelColumns";
const RawDetails = ({
  auth,
  innerRef,
  pattrenSet,
  form,
  setRawDetail,
  readOnly = true,
  callType,
  rawDetail,
  activeKey,
  showModal,
  setMunfDetail,
  munfDetail,
  setLoadData,
  id,
  promptForm,
  noStyle,
  showTotalQty = false,
}) => {
  const [searchItemInvValue, setSearchItemInvValue] = useState("");
  const [showRawModal, setShowRawModal] = useState(false);
  const [showQtyItemsModal, setShowQtyItemsModal] = useState(false);
  const [showPriceItemsModal, setShowPriceItemsModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  let mainObj;

  const linkCont = (p) => (
    <>
      {auth?.perShowQtyDetails && (
        <div>
          <Button
            type="link"
            onClick={() =>
              handleOpenQtyItem(
                p?.row.itemUnit_MapId,
                form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                p?.row.itemName
              )
            }
            icon={<FileSearchOutlined />}
          >
            {_t("strQTY")}
          </Button>
        </div>
      )}
      {auth?.perShowPrices && (
        <div>
          <Button
            type="link"
            onClick={() =>
              handleOpenPriceItem(
                p?.row.itemUnit_MapId,
                form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                p
              )
            }
            icon={<DiffOutlined />}
          >
            {_t("strPrice")}
          </Button>
        </div>
      )}
      <Divider style={{ margin: 5 }} />
      <div>
        <Button
          type="link"
          onClick={() => handleDelete(p.row?.key)}
          icon={<DeleteOutlined />}
          style={{ color: "red" }}
        >
          {_t("strDelete")}
        </Button>
      </div>
    </>
  );
  const [lineUnitId, setLineUnitId] = useState("");
  const [lineItemName, setLineItemName] = useState("");
  const [lineDate, setLineDate] = useState("");
  const [pRow, setPRow] = useState({});
  const handleOpenQtyItem = (unitId = "", date = "", itemName = "") => {
    setLineUnitId(unitId);
    setLineItemName(itemName);
    setLineDate(date);

    setShowQtyItemsModal(true);
  };

  const handleOpenPriceItem = (unitId = "", date = "", p) => {
    setLineUnitId(unitId);
    setLineDate(date);

    setPRow(p);
    setShowPriceItemsModal(true);
  };

  const allColumns = [
    {
      name: "#",
      key: "num",
      minWidth: 30,
      width: 43,
      visible: true,
      formatter: (p) => {
        return p.row?.itemId ? (
          <Popover
            placement="bottomRight"
            trigger="click"
            style={{ width: "100%" }}
            content={() => linkCont(p)}
          >
            <label
              className="keyNum"
              style={{
                width: "100%",
                color: "#1d98f4",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {p?.row?.key + 1}
            </label>
          </Popover>
        ) : (
          p?.row?.key + 1
        );
      },
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{row.totalCount - 1}</strong>
        );
      },
    },
    {
      name: _t("strItems"),
      key: "itemName",
      width: 200,
      visible: true,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            onPressEnter={(e) => itemSearch(e.target.value, p.row.key)}
          />
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
      summaryFormatter() {
        return <strong style={{ color: "#7e7e7e" }}>الإجمالي</strong>;
      },
    },
    {
      name: _t("strUnits"),
      key: "itemUnit_MapId",
      width: 90,
      visible: true,
      editor: (p) => (
        <DropDownList
          value={p.row?.itemUnit_MapId}
          bordered={false}
          className="TextEditor"
          onChange={async (e) => {
            let rest = {
              count: emptValue(form.getFieldValue("countPriv"), 1),
              date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
              priceMethod:
                form.getFieldValue("priceMethodId") === undefined
                  ? ""
                  : form.getFieldValue("priceMethodId"),
              rate:
                form.getFieldValue("currRate") === undefined
                  ? ""
                  : form.getFieldValue("currRate"),
            };
            mainObj = await handleItemUnit(p, e, mainObj, rest);
            setRowChanges(p, mainObj);
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.itemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strQuantity"),
      key: "qty",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                let rest = {
                  count: emptValue(form.getFieldValue("countPriv"), 1),
                  date:
                    form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                  priceMethod:
                    form.getFieldValue("priceMethodId") === undefined
                      ? ""
                      : form.getFieldValue("priceMethodId"),
                  rate:
                    form.getFieldValue("currRate") === undefined
                      ? ""
                      : form.getFieldValue("currRate"),
                };
                mainObj = await handleQty(p, e, mainObj, rest);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.qty}>{getNumFormat(p?.row?.qty, 0)}</Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQty}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strQtyTotal"),
      key: "qtyTotal",
      width: 150,
      visible: showTotalQty,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                let rest = {
                  count: emptValue(form.getFieldValue("countPriv"), 1),
                  date:
                    form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                  priceMethod:
                    form.getFieldValue("priceMethodId") === undefined
                      ? ""
                      : form.getFieldValue("priceMethodId"),
                  rate:
                    form.getFieldValue("currRate") === undefined
                      ? ""
                      : form.getFieldValue("currRate"),
                };
                mainObj = await handleQtyTotal(p, e, mainObj, rest);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.qtyTotal}>
            {getNumFormat(p?.row?.qtyTotal, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQtyTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQtyTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strPrice"),
      key: "price",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                mainObj = await handlePrice(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.price}>
            {getNumFormat(p?.row?.price, 2)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalPrice}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalPrice, 2)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strTotal"),
      key: "total",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                let rest = {
                  count: emptValue(form.getFieldValue("countPriv"), 1),
                  date:
                    form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                  priceMethod:
                    form.getFieldValue("priceMethodId") === undefined
                      ? ""
                      : form.getFieldValue("priceMethodId"),
                  rate:
                    form.getFieldValue("currRate") === undefined
                      ? ""
                      : form.getFieldValue("currRate"),
                };
                mainObj = await handleTotal(p, e, mainObj, rest);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.total}>
            {getNumFormat(p?.row?.total, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      key: "note",
      name: _t("strNote"),
      width: 170,
      visible: true,
      editor: TextEditor,
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strLostPerc"),
      key: "lostPerc",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                let rest = {
                  count: emptValue(form.getFieldValue("countPriv"), 1),
                  date:
                    form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                  priceMethod:
                    form.getFieldValue("priceMethodId") === undefined
                      ? ""
                      : form.getFieldValue("priceMethodId"),
                  rate:
                    form.getFieldValue("currRate") === undefined
                      ? ""
                      : form.getFieldValue("currRate"),
                };
                mainObj = await handleLostPerc(p, e, mainObj, rest);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostPerc}>
            {getNumFormat(p?.row?.lostPerc, 5)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalLostPerc}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalLostPerc, 5)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strLostQty"),
      key: "lostQty",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (p?.row !== undefined) {
                let rest = {
                  count: emptValue(form.getFieldValue("countPriv"), 1),
                  date:
                    form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                  priceMethod:
                    form.getFieldValue("priceMethodId") === undefined
                      ? ""
                      : form.getFieldValue("priceMethodId"),
                  rate:
                    form.getFieldValue("currRate") === undefined
                      ? ""
                      : form.getFieldValue("currRate"),
                };
                mainObj = await handleLostQty(p, e, mainObj, rest);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostQty}>
            {getNumFormat(p?.row?.lostQty, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalLostQty}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalLostQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strLostUnit"),
      key: "lostItemUnit_MapId",
      width: 100,
      visible: true,
      editor: (p) => (
        <DropDownList
          allowClear={true}
          value={p.row?.lostItemUnit_MapId}
          bordered={false}
          className="TextEditor"
          onChange={async (e) => {
            let rest = {
              count: emptValue(form.getFieldValue("countPriv"), 1),
              date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
              priceMethod:
                form.getFieldValue("priceMethodId") === undefined
                  ? ""
                  : form.getFieldValue("priceMethodId"),
              rate:
                form.getFieldValue("currRate") === undefined
                  ? ""
                  : form.getFieldValue("currRate"),
            };
            mainObj = await handleItemLostUnit(p, e, mainObj, rest);
            setRowChanges(p, mainObj);
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.lostItemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strPriceLost"),
      key: "lostPrice",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async(e) => {
              if (p?.row !== undefined) {
                mainObj = await handleLostPrice(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostPrice}>
            {getNumFormat(p?.row?.lostPrice, 2)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalLostPrice}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalLostPrice, 2)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strTotalLost"),
      key: "lostPriceTotal",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async(e) => {
              if (p?.row !== undefined) {
                mainObj = await handleLostTotal(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostPriceTotal}>
            {getNumFormat(p?.row?.lostPriceTotal, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalLostPriceTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalLostPriceTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strNet"),
      key: "netTotal",
      width: 120,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,

      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.netTotal}>
            {getNumFormat(p?.row?.netTotal, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalNetTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalNetTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
    },
  ];

  const setRowChanges = (p, obj) => {
    if (obj !== undefined) {
      p?.onRowChange(
        {
          ...p.row,
          itemUnit_MapId: obj?.itemUnit_MapId,
          qty: emptValue(obj?.qty, null),
          qtyTotal: emptValue(obj?.qtyTotal, null),
          price: emptValue(obj?.price, null),
          total: emptValue(obj?.total, null),
          lostQty: emptValue(obj?.lostQty, null),
          lostPerc: emptValue(obj?.lostPerc, null),
          lostPrice: emptValue(obj?.lostPrice, null),
          lostPriceTotal: emptValue(obj?.lostPriceTotal, null),
          netTotal: emptValue(obj?.netTotal, null),
          note: obj?.note,
        },
        true
      );
      let data = rawDetail;
      data[p.row.key] = obj;

      setRow([...data]);
    }
  };

  const setRow = (list) => {
    // list.push({ key: list.length });
    setRawDetail([...list]);
    promptForm();
  };

  const handleDelete = (key) => {
    const newUnit = rawDetail.filter((e) => e.key !== key);
    let newNum = 0;
    for (let i = 0; i < newUnit.length; i++) {
      newUnit[i].key = newNum;
      newNum++;
    }
    setRawDetail([...newUnit]);
    if (rawDetail.length <= 1) {
      setRawDetail([{ key: 0 }]);
    }
  };

  const handleConvUnitVal = async (_iU, _lU, _val) => {
    let itemUnit = _iU !== null && _iU !== undefined ? _iU : null;
    let lostUnit = _lU !== null && _lU !== undefined ? _lU : null;
    let priceVal = emptValue(_val, null);

    if (itemUnit !== null && lostUnit !== null && priceVal !== null) {
      if (itemUnit !== lostUnit) {
        try {
          const { data: result } = await convertPriceByUnit(
            lostUnit,
            itemUnit,
            priceVal
          );
          return result?.data;
        } catch (error) {
          handleEx(error);
        }
      } else {
        return _val;
      }
    }
  };

  const handQtyAndPriceServer = (p, val, e, type) => {
    let _qty =
      type === "qty"
        ? emptValue(e.target.value, null)
        : emptValue(p.row.qty, null);
    let _price =
      type === "price"
        ? emptValue(e.target.value, null)
        : emptValue(p.row.price, null);
    let _lostQty =
      emptValue(p.row.lostPerc, null) !== null
        ? emptValue(_RateMul(_qty, p.row.lostPerc), null)
        : p.row.lostQty;
    let _qtyTotal = emptValue(
      _Mul(_qty, emptValue(form.getFieldValue("countPriv"), 1)),
      null
    );
    let _total = showTotalQty
      ? emptValue(_Mul(_qtyTotal, _price), null)
      : emptValue(_Mul(_qty, _price), null);
    let _lostPrice =
      type === "qty" ? p.row.lostPrice : emptValue(val, p.row.lostPrice);
    let _lostPriceTotal =
      type === "qty"
        ? p.row.lostPriceTotal
        : emptValue(_Mul(_lostQty, emptValue(val, _lostPrice)), null);
    let _netTotal = emptValue(
      _Sum(
        emptValue(_Mul(_lostQty, emptValue(val, _lostPrice)), 0),
        emptValue(_total, 0)
      ),
      null
    );

    p?.onRowChange(
      {
        ...p?.row,
        qty: _qty,
        price: _price,
        total: _total,
        qtyTotal: _qtyTotal,
        lostQty: _lostQty,
        lostPrice: _lostPrice,
        lostPriceTotal: _lostPriceTotal,
        netTotal: _netTotal,
      },
      true
    );
  };

  const handQtyAndPrice = (p, e, type) => {
    let _qty =
      type === "qty"
        ? emptValue(e.target.value, null)
        : emptValue(p.row.qty, null);
    let _price =
      type === "price"
        ? emptValue(e.target.value, null)
        : emptValue(p.row.price, null);

    let _qtyTotal = emptValue(
      _Mul(_qty, emptValue(form.getFieldValue("countPriv"), 1)),
      null
    );

    let _lostQty = emptValue(_RateMul(e.target.qty, p.row.lostPerc), null);

    let _total = showTotalQty
      ? type === "qty"
        ? emptValue(_Mul(_qtyTotal, _price), null)
        : emptValue(_Mul(_qtyTotal, e.target.value), null)
      : emptValue(calcTotal(p, e, type), null);
    let _netTotal = emptValue(_Sum(p.row.lostPriceTotal, _total), null);

    p?.onRowChange(
      {
        ...p?.row,
        qty: _qty,
        qtyTotal: _qtyTotal,
        price: _price,
        lostQty: _lostQty,
        total: _total,
        netTotal: _netTotal,
      },
      true
    );
  };

  const calcTotal = (p, e, type) => {
    return type === "qty"
      ? emptValue(_Mul(p.row.price, e.target.value), null)
      : emptValue(_Mul(p.row.qty, e.target.value), null);
  };

  const handleItemInvSelector = async (result) => {
    const servData = await groupingIds(result);
    await appendAccSelector(servData);
  };

  useEffect(() => {
    if (pattrenSet === null) {
      let res = allColumns.filter((e) => e.visible !== false);
      setColumns(res);
    } else {
      setColumns([allColumns[0]]);
      pattrenSet?.rawMaterialsColSett.forEach((e) => fillColumns(e));
    }
  }, [pattrenSet?.rawMaterialsColSett, rawDetail]);

  const fillColumns = (tmpObj) => {
    allColumns.map((e) => {
      if (e.key === tmpObj.key && e.visible !== false) {
        e.name = tmpObj.name;
        e.width = tmpObj.width;
        setColumns((x) => [...x, e]);
      }
    });
  };

  const groupingIds = async (arr) => {
    let list = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.itemUnit_MapId === undefined) continue;
      id = "";
      id = arr[i]?.itemUnit_MapId;
      list.push(id);
    }
    let date = form.getFieldValue().date.toISOString().slice(0, 16) + "Z";
    let priceMethodId =
      form.getFieldValue("priceMethodId") === undefined
        ? ""
        : form.getFieldValue("priceMethodId");
    let currRate =
      form.getFieldValue("currRate") === undefined
        ? ""
        : form.getFieldValue("currRate");

    try {
      const { data: data } = await getRawMaterialsByUnit(
        list,
        date,
        emptString(priceMethodId, ""),
        currRate
      );
      return data.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const itemSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getSearchPopup(value);
      if (result.data.length === 1) {
        insertSingleRec(result.data, key);
      } else if (result.data.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchItemInvValue(value);
        setShowRawModal(true);
        window.sessionStorage.setItem("showRawModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = async (value, row) => {
    const servData = await groupingIds(value);
    const data = rawDetail;
    if (data[row] === undefined) {
      data.push([{ key: row }]);
    }

    data[row] = {};

    data[row].id = servData[0].id;
    data[row].key = servData[0].key;
    data[row].itemId = servData[0].itemId;
    data[row].itemName = servData[0].itemName;
    data[row].itemUnit_MapId = servData[0].itemUnit_MapId;
    data[row].qty = emptValue(servData[0].qty, null);
    data[row].price = emptValue(servData[0].price, null);
    data[row].total = emptValue(servData[0].total, null);
    data[row].lostQty = emptValue(servData[0].lostQty, null);
    data[row].lostPerc = emptValue(servData[0].lostPerc, null);
    data[row].note = servData[0].note;
    data[row].rowType = servData[0].rowType;
    data[row].units = servData[0].units;
    await sortDataKey(data);
  };

  const appendAccSelector = async (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      let dataCollect;
      let tmpdata = rawDetail.find((x) => x.key === rowNumberEdit);
      if (tmpdata?.itemId !== undefined) {
        dataCollect = rawDetail;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            memoData.id = rawDetail[rowNumberEdit + i].id;
            memoData.qty = emptValue(rawDetail[rowNumberEdit + i].qty, null);
            memoData.price = emptValue(
              rawDetail[rowNumberEdit + i].price,
              null
            );
            memoData.total = emptValue(
              rawDetail[rowNumberEdit + i].total,
              null
            );
          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        dataCollect = [...rawDetail, ...memoData];
      }
      await sortDataKey(dataCollect);
    }
    setTimeout(() => {
      setShowRawModal(false);
      window.sessionStorage.setItem("showRawModal", false);
    });
  };

  const sortDataKey = async (data) => {
    let list = [];
    let KeyNum = 0;

    let count = emptValue(form.getFieldValue("countPriv"), 1);
    for (let i = 0; i < data.length; i++) {
      if (data[i].itemId !== undefined) {
        let obj = {};
        obj = data[i];
        obj["key"] = KeyNum;
        obj["qty"] = emptValue(data[i].qty, 1);
        obj = await calcQtyTotalByCount(obj, count);
        list.push(obj);
        KeyNum++;
      }
    }
    list.push({ key: list.length });
    promptForm();
    setRawDetail(list);
  };

  // const sum = () => {
  //   const data = munfDetail;
  //   if (data.length > 1)
  //     data.map((e) => {
  //       if (emptValue(e.distributePerc, null) !== null &&sumNetTotal(rawDetail) !== 0) {
  //         e.total = emptValue(_RateMul(sumNetTotal(rawDetail), emptValue(e.distributePerc, 0)),null);
  //          }
  //       if (emptValue(e.total, null) !== null && emptValue(e.qty, null) !== null ) {
  //         e.price = emptValue(_Div(e.total, e.qty), null);
  //       }
  //     });

  //   setMunfDetail([...data]);
  // };

  const sum = () => {
    const data = munfDetail;
    let _netTotal = sumNetTotal(rawDetail);
    if (data.length > 1)
      data.map((e) => {
        e = calcTotal_munf(e, _netTotal);
      });

    setMunfDetail([...data]);
  };

  const summaryRows = useMemo(() => {
    if (activeKey === "1" && callType === "inOut" && showModal) {
      sum();
    } else if (activeKey === "1" && callType !== "inOut") {
      sum();
    }
    const summaryRow = [
      {
        id: "total_0",
        totalCount: rawDetail.length,
        totalQty: sumQty(rawDetail),
        totalQtyTotal: sumQtyTotal(munfDetail),
        totalPrice: sumPrice(rawDetail),
        totalTotal: sumTotal(rawDetail),
        totalLostQty: sumLostQty(rawDetail),
        totalLostPrice: sumLostPrice(rawDetail),
        totalLostPriceTotal: sumLostPriceTotal(rawDetail),
        totalNetTotal: sumNetTotal(rawDetail),
      },
    ];

    form.setFieldsValue({
      rawPrice: sumPrice(rawDetail),
      rawItemTotal: sumTotal(rawDetail),
      rawTotal: sumNetTotal(rawDetail),
    });
    return summaryRow;
  }, [rawDetail]);

  const onHandleInsert = async (obj) => {
    try {
      if (
        pRow?.row.itemUnit_MapId !== undefined &&
        pRow?.row.lostItemUnit_MapId !== undefined
      ) {
        let val = await handleConvUnitVal(
          pRow?.row?.itemUnit_MapId,
          pRow?.row?.lostItemUnit_MapId,
          obj.e?.target.value
        );
        handQtyAndPriceServer(pRow, val, obj.e, "price");
      } else {
        handQtyAndPrice(pRow, obj.e, "price");
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setShowPriceItemsModal(false);
    }
  };

  return (
    <>
      <ItemInvSelector
        searchValue={searchItemInvValue}
        onHandelData={handleItemInvSelector}
        showModal={showRawModal}
        setShowModal={setShowRawModal}
        showModalName="showRawModal"
      />
      <CheckQtyItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        itemName={lineItemName}
        showModal={showQtyItemsModal}
        setShowModal={setShowQtyItemsModal}
      />

      <CheckPriceItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        rate={form.getFieldValue().currRate}
        showModal={showPriceItemsModal}
        setShowModal={setShowPriceItemsModal}
        handleInsert={onHandleInsert}
        pRow={pRow}
      />

      <Form.Item
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        label={_t("strTheInput")}
        noStyle={noStyle}
      >
        <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 10 }}>
          <DataGrid
            ref={innerRef}
            columns={[...columns]}
            rows={rawDetail}
            rowHeight={25}
            style={{
              height: `calc(100vh - 400px)`,
              width: "100%",
            }}
            className="rdg-light"
            onRowsChange={setRawDetail}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
            summaryRows={summaryRows}
          />
        </Card>
      </Form.Item>
    </>
  );
};
export default RawDetails;
