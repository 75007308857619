import React, { useState, useEffect, useRef, useContext } from "react";
import { Prompt } from "react-router";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { calcAndReturnList } from "../../helper/Modal/costCenterModal/costCenterHelper";
import SelectSingle from "../../helper/selectSingle";
import SelectAccByClient from "./invFunctions/selectHelper/selectAccByClient";
import SelectClientByAcc from "./invFunctions/selectHelper/selectClientByAcc";
import SelectItemAcc from "./invFunctions/selectHelper/selectItemAcc";
import { valByPircInput } from "../../helper/Modal/costCenterModal/costCenterHelper";
import { focusEffectiveFields } from "./invFunctions/helper/focusFields";
import { reCalcItemsPrice } from "./invFunctions/helper/reCalcItemsPrice";
import SelectAccount from "../../helper/inputSelect/selectAccount";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  message,
  Upload,
  Space,
  Row,
  Col,
  Typography,
  Spin,
  DatePicker,
  Tabs,
  Modal,
  Collapse,
  Dropdown,
  Popconfirm,
  Tag,
} from "antd";
import {
  initPage,
  editInvoice,
  browseNext,
  browsePrev,
  getByDoc,
  getByPattCode,
  deleteInvoice,
  getInvHtml,
  savePostState,
  uploadExcel,
} from "../../../services/invoiceService";
import {
  ExclamationCircleOutlined,
  DeleteTwoTone,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SaveOutlined,
  LinkOutlined,
  FileExcelOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import ActionSearch from "../../helper/Modal/actionSearch";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import InvMainGrid from "./invFunctions/MainGrid/invMainGrid";
import InvGridDiscs from "./invFunctions/Discount/invGridDiscs";
import InvGridAdds from "./invFunctions/Additional/invGridAdds";
import InvGeneralTotal from "./invGeneralTotal";
import {
  newRow,
  newRowRest,
} from "./invFunctions/MainGrid/handleColumns/addNewRows";
import {
  getCurrActiveSearch,
  getActiveCurr,
} from "../../../services/Items/currenciesService";
import { getClientActiveSearch } from "../../../services/clientService";
import { GetActiveChildren } from "../../../services/AccountService";
import { GetActiveStoreChildren } from "../../../services/storeService";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import { getActivePayMethodSearch } from "../../../services/Items/payMethodService";
import {
  getRate,
  changeRate,
} from "../../../services/Items/currenciesRateService";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { useApi } from "../../../services/useApi";
import InputCurrency from "../../helper/Input/InputCurrency";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import { objCountNum } from "../../helper/countOfZero";
import { printPanel } from "../../helper/Print/printPanel";
import CostCenterInput from "../../helper/Input/costCenterInput";
import moment from "moment";
import { LanguageContext } from "../../../languages/Language";
import {
  emptString,
  dateTimeNow,
  emptArr,
  emptValue,
} from "../../helper/helperMethods";
import { shortCut } from "../../helper/shortCuts";
import {
  saveValidate,
  validateGrids,
  valCostCenter,
} from "./invFunctions/helper/Validate";
import InvUnImportDataExcel from "../../helper/Modal/invUnImportDataExcel";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const InvForm = () => {
  const IX_Code = useRef(null);
  const IX_Date = useRef(null);
  const IX_Currency = useRef(null);
  const IX_Acc = useRef(null);
  const refInvCostCenter = useRef(null);
  const gridMainRef = useRef();
  const gridDiscRef = useRef();
  const gridAddRef = useRef();

  //------------|الدفعة|--------------
  const refPayValue = useRef(null);
  const refPayAcc = useRef(null);
  const refPayCostCent = useRef(null);
  const refPayWay = useRef(null);
  //------------|إضافة الفاتورة|--------------
  const refAddPerc = useRef(null);
  const refAddValue = useRef(null);
  const refAddAcc = useRef(null);
  const refAddCostCent = useRef(null);
  //------------|خصم الفاتورة|--------------
  const refDiscPerc = useRef(null);
  const refDiscValue = useRef(null);
  const refDiscAcc = useRef(null);
  const refDiscCostCent = useRef(null);
  //------------|إضافة الأصناف|--------------
  const refItemAddAcc = useRef(null);
  const refItemAddCostCent = useRef(null);
  //------------|خصم الأصناف|--------------
  const refItemDiscAcc = useRef(null);
  const refItemDiscCostCent = useRef(null);

  const refObj = {
    refPayValue,
    refPayAcc,
    refPayCostCent,
    refPayWay,
    refAddPerc,
    refAddValue,
    refAddAcc,
    refAddCostCent,
    refDiscPerc,
    refDiscValue,
    refDiscAcc,
    refDiscCostCent,
    refItemAddAcc,
    refItemAddCostCent,
    refItemDiscAcc,
    refItemDiscCostCent,
  };

  const ref_InvPayMethod = useRef(null);
  const ref_InvPayMethodAcc = useRef(null);
  const ref_DiscInvAcc = useRef(null);
  const ref_AddInvAcc = useRef(null);
  const ref_DiscItemAcc = useRef(null);
  const ref_AddItemAcc = useRef(null);
  const ref_DueDateVisible = useRef(null);
  const ref_PayValueVisible = useRef(null);
  const divTabRef = useRef(null);
  const [form] = Form.useForm();
  let { id } = useParams();
  let query = useQuery();
  //******************************//
  const [auth, setAuth] = useState({});
  const [statDiscFields, setStatDiscFields] = useState(false);
  const [statAddFields, setStatAddFields] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const [saveAndPrint, setSaveAndPrint] = useState(false);
  const [currOptTable, setCurrOptTable] = useState([]);
  const [accOpt, setAccOpt] = useState([]);
  const [clientOpt, setClientOpt] = useState([]);
  const [storeOpt, setStoreOpt] = useState([]);
  const [mainDataSource, setMainDataSource] = useState([]);
  const [addsDataSource, setAddsDataSource] = useState([]);
  const [discsDataSource, setDiscsDataSource] = useState([]);
  const [oldForm, setOldForm] = useState({});
  const [pattrenSet, setPattrenSet] = useState(null);
  const [summTotal, setSummTotal] = useState(null);
  const [disableDiscsPart, setDisableDiscsPart] = useState(true);
  const [disableAddPart, setDisableAddPart] = useState(true);
  const [postEntState, setPostEntState] = useState(false);
  const [postEntDateState, setPostEntDateState] = useState(null);
  const [discValueReq, setDiscValueReq] = useState(false);
  const [addValueReq, setAddValueReq] = useState(false);
  const [payAccReq, setPayAccReq] = useState(false);
  const [initVal, setInitVal] = useState(null);
  const [genEntName, setGenEntName] = useState(null);
  const [accStatusName, setAccStatusName] = useState(null);
  const [genTotalObj, setGenTotalObj] = useState("");
  const [changeU, setChangeU] = useState(false);
  const [inLoadData, setInLoadData] = useState(true);
  const [payMethType, setPayMethType] = useState(0);
  //----------- cost center --------------------------------
  const [clientCostMessage, setClientCostMessage] = useState(undefined);
  const [clientOptReqCost, setClientOptReqCost] = useState(0);

  const [invCostMessage, setInvCostMessage] = useState(undefined);
  const [invOptReqCost, setInvOptReqCost] = useState(0);

  const [itemDiscCostMessage, setItemDiscCostMessage] = useState(undefined);
  const [itemDiscOptReqCost, setItemDiscOptReqCost] = useState(0);

  const [itemAddCostMessage, setItemAddCostMessage] = useState(undefined);
  const [itemAddOptReqCost, setItemAddOptReqCost] = useState(0);

  const [payCostMessage, setPayCostMessage] = useState(undefined);
  const [payOptReqCost, setPayOptReqCost] = useState(0);

  const [invDiscsOptReqCost, setInvDiscsOptReqCost] = useState(0);

  const [invAddsOptReqCost, setInvAddsOptReqCost] = useState(0);

  //-------------------------------------------

  const [entSourceUrl, setEntSourceUrl] = useState(null);
  const [entSourceId, setEntSourceId] = useState(null);
  const [resp, setResp] = useState(null);
  const [{ data }] = useApi(getActiveCurr(), [null || null]);
  const [proChange, setProChange] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const { confirm } = Modal;
  let history = useHistory();
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text } = Typography;
  const { TabPane } = Tabs;
  const { userLanguage } = useContext(LanguageContext);
  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------

  const authorization = async () => {
    try {
      let authUrl = authKeys.invs + "_PattSett_" + id;
      const data = await getAuth();
      const responce = data?.data?.data.filter(
        (e) => e?.roleKey === authUrl
      )[0];

      setAuth(responce);
    } catch (error) {
      handleEx(error);
    }
  };
  // const authorization = async () => {
  //   const obj = JSON.parse(sessionStorage.getItem("auth"));
  //   let authUrl = authKeys.invs + "_PattSett_" + id;
  //   if (obj !== null) {
  //     const responce = obj.filter((e) => e?.roleKey === authUrl)[0];
  //
  //     setAuth(responce);
  //   } else {
  //     try {
  //       const data = await getAuth();
  //       sessionStorage.setItem("auth", JSON.stringify(data.data.data));
  //       authorization();
  //     } catch (ex) {
  //       return null;
  //     }
  //   }
  // };
  //////////----|👉 end auth|----//////////

  const promptForm = () => {
    setProChange(true);
    window.sessionStorage.setItem("promptEnt", true);
  };

  const handleCleanUrl = () => {
    const url = new URLSearchParams(history.location.search);
    url.delete("invId");
    history.push({
      pathname: history.location.pathname,
      search: url.toString(),
    });
  };

  const initValue = async (_Id, type) => {
    try {
      const { data: newData } = await initPage(handleGetUtl(), _Id);
      if (emptString(newData?.data?.inv, null) === null) {
        handleCleanUrl();
        initValue("", "new");
      } else {
        fillData(newData, type);
        setOldForm(form.getFieldValue());
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    let list = [];
    for (let i = 0; i < data.length; i++) {
      let tmpObj = {};
      tmpObj["id"] = data[i]?.currId;
      tmpObj["name"] = data[i]?.currName;
      list.push(tmpObj);
    }
    setCurrOptTable(list);
  }, [data]);

  function showConfirmDelete() {
    confirm({
      title: _t("strSureToDelete"),
      icon: <DeleteTwoTone twoToneColor="#eb2f96" />,
      direction: _t("langDiriction"),
      onOk() {
        handleDeleteForm();
      },
      onCancel() {
        return;
      },
    });
  }

  const handleDeleteForm = async () => {
    try {
      const { data: newData } = await deleteInvoice(form.getFieldValue().id);
      handleCleanUrl();
      initValue("", "new");
      message.success(newData.message);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    window.sessionStorage.setItem("promptEnt", false);
    authorization();
    async function fetchData() {
      const recourdId = query.get("invId");
      initValue(
        recourdId ?? "",
        emptString(recourdId, null) !== null ? "code" : "new"
      );
    }
    fetchData();
  }, [id]);

  const handleGetUtl = () => {
    var url = window.location.pathname;
    return url.substring(url.lastIndexOf("/") + 1);
  };

  const next = async () => {
    try {
      const { data: result } = await browseNext(
        handleGetUtl(),
        form.getFieldValue().id
      );
      if (result?.data?.inv !== null) {
        fillData(result, "next");
        setOldForm(form.getFieldValue());
      } else {
        setLoadData(false);
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    }
  };

  const prev = async () => {
    try {
      const { data: result } = await browsePrev(
        handleGetUtl(),
        form.getFieldValue().id
      );

      if (result.data.inv !== null) {
        await fillData(result, "prev");
        setOldForm(form.getFieldValue());
      } else {
        setLoadData(false);
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    }
  };

  const searchPattCode = async (popS) => {
    setOldForm(form.getFieldValue());
    const { data: result } = await getByPattCode(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().pattCode
    );
    if (result.data.inv !== null) {
      fillData(result, "code");
    } else {
      message.info(_t("msgNoRecords"));
    }
  };

  const searchDoc = async (popS) => {
    setOldForm(form.getFieldValue());
    const result = await getByDoc(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().doc
    );
    if (result !== undefined) {
      if (result?.data?.data?.inv !== null) {
        fillData(result?.data, "doc");
      } else {
        message.info(_t("msgNoRecords"));
      }
    }
  };

  const changeCurr = async (currId, value) => {
    let obj = {
      currId: currId,
      date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      value: value,
    };
    const { data: result } = await changeRate(obj);
    message.success(result.message, 3);
  };

  const [accList, setAccList] = useState([]);

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      setAccList(responce?.data);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      // const arr = costList.map((e) => {
      //   e.value = 0;
      //   return e;
      // });
      const reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, costList, emptValue(reqOpt, 0))?.text;
    }
  };

  const fillData = async (newData, type) => {
    await setMainDataSource(newRow(newData.data.inv?.details));

    await setDiscsDataSource(
      newRowRest(newData.data.inv?.invDiscs, {
        distributed: newData.data?.pattSett?.discInvDist,
      })
    );

    await setAddsDataSource(
      newRowRest(newData.data.inv?.invAdds, {
        distributed: newData.data?.pattSett?.addInvDist,
      })
    );

    setActiveKey("1");
   await setInLoadData(true);
    const accList = await handleAccAct();

    await handleClientSearch(
      "",
      newData.data.inv?.clientId !== null ? newData.data.inv?.clientId : "",
      newData.data.inv?.clientAccId !== null
        ? newData.data.inv?.clientAccId
        : ""
    );

    await handleAccActSearch(
      "",
      newData.data.inv?.clientAccId !== null
        ? newData.data.inv?.clientAccId
        : "",
      newData.data.inv?.clientId !== null ? newData.data.inv?.clientId : ""
    );

    await form.setFieldsValue({
      id:
        newData.data.inv?.id !== null &&
        newData.data.inv?.id !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.id
          : "",
      pattCode:
        newData.data.inv?.pattCode !== null
          ? newData.data.inv?.pattCode
          : undefined,

      clientId:
        newData.data.inv?.clientId !== null &&
        newData.data.inv?.clientId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.clientId
          : undefined,

      clientAccId:
        newData.data.inv?.clientAccId !== null &&
        newData.data.inv?.clientAccId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.clientAccId
          : undefined,

      storeId:
        newData.data.inv?.storeId !== null &&
        newData.data.inv?.storeId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.storeId
          : undefined,
      toStoreId:
        newData.data.inv?.toStoreId !== null &&
        newData.data.inv?.toStoreId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.toStoreId
          : undefined,
      invAccId:
        newData.data.inv?.invAccId !== null
          ? newData.data.inv?.invAccId
          : undefined,

      currId:
        newData.data.inv?.currId !== null &&
        newData.data.inv?.currId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.currId
          : undefined,
      currRate:
        newData.data.inv?.currRate !== null
          ? newData.data.inv?.currRate
          : undefined,
      doc: newData.data.inv?.doc !== null ? newData.data.inv?.doc : undefined,
      dueDate:
        newData.data.inv?.dueDate !== null
          ? moment(newData.data.inv?.dueDate)
          : undefined,
      note:
        newData.data.inv?.note !== null ? newData.data.inv?.note : undefined,

      payValue: newData.data.inv?.payValue,

      entSourceId:
        newData.data.inv?.entSourceId !== null &&
        newData.data.inv?.entSourceId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.entSourceId
          : "",
      itemTotal: newData.data.inv?.itemTotal,

      itemDiscTotal: newData.data.inv?.itemDiscTotal,
      itemAddTotal: newData.data.inv?.itemAddTotal,
      itemSubTotal: newData.data.inv?.itemSubTotal,
      invDiscTotal:
        newData.data.inv?.invDiscTotal === 0
          ? undefined
          : newData.data.inv?.invDiscTotal,
      invAddTotal:
        newData.data.inv?.invAddTotal === 0
          ? undefined
          : newData.data.inv?.invAddTotal,
      oprStatusId: newData.data.inv?.oprStatusId,
      netTotal: newData.data.inv?.netTotal,
      payMethodId:
        newData.data.inv?.payMethodId !== null &&
        newData.data.inv?.payMethodId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.payMethodId
          : undefined,
      priceMethodId:
        newData.data.inv?.priceMethodId !== null &&
        newData.data.inv?.priceMethodId !==
          "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.priceMethodId
          : undefined,
      payAccId:
        newData.data.inv?.payAccId !== null &&
        newData.data.inv?.payAccId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.payAccId
          : undefined,
      itemDiscAccId:
        newData.data.inv?.itemDiscAccId !== null &&
        newData.data.inv?.itemDiscAccId !==
          "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.itemDiscAccId
          : undefined,
      itemAddAccId:
        newData.data.inv?.itemAddAccId !== null &&
        newData.data.inv?.itemAddAccId !==
          "00000000-0000-0000-0000-000000000000"
          ? newData.data.inv?.itemAddAccId
          : undefined,

      invAccCostCenter_Maps: newData.data.inv?.invAccCostCenter_Maps,
      invAccCostCenter: loadCostCenterData(
        newData.data.inv?.invAccCostCenter_Maps,
        newData?.data?.inv?.invAccId,
        accList
      ),

      clientAccCostCenter_Maps: newData.data.inv?.clientAccCostCenter_Maps,
      clientAccCostCenter: loadCostCenterData(
        newData.data.inv?.clientAccCostCenter_Maps,
        newData?.data?.inv?.clientId,
        accList
      ),

      cashAccCostCenter_Maps: newData.data.inv?.cashAccCostCenter_Maps,
      cashAccCostCenter: loadCostCenterData(
        newData.data.inv?.cashAccCostCenter_Maps,
        newData?.data?.inv?.payAccId,
        accList
      ),

      itemAddCostCenter_Maps: newData.data.inv?.itemAddCostCenter_Maps,
      itemAddCostCenter: loadCostCenterData(
        newData.data.inv?.itemAddCostCenter_Maps,
        newData?.data?.inv?.itemAddAccId,
        accList
      ),

      itemDiscCostCenter_Maps: newData.data.inv?.itemDiscCostCenter_Maps,
      itemDiscCostCenter: loadCostCenterData(
        newData.data.inv?.itemDiscCostCenter_Maps,
        newData?.data?.inv?.itemDiscAccId,
        accList
      ),
      payTotal: newData.data.inv?.payTotal,
      restTotal: newData.data.inv?.restTotal,
    });

    setInvOptReqCost(checkTypeAccs(accList, newData?.data?.inv?.invAccId));
    setClientOptReqCost(
      checkTypeAccs(accList, newData?.data?.inv?.clientAccId)
    );
    setItemDiscOptReqCost(
      checkTypeAccs(accList, newData?.data?.inv?.itemDiscAccId)
    );
    setItemAddOptReqCost(
      checkTypeAccs(accList, newData?.data?.inv?.itemAddAccId)
    );
    setPayOptReqCost(checkTypeAccs(accList, newData?.data?.inv?.payAccId));
    setInvDiscsOptReqCost(
      checkTypeAccs(accList, newData.data.inv?.invDiscs[0]?.accId)
    );
    setInvAddsOptReqCost(
      checkTypeAccs(accList, newData.data.inv?.invAdds[0]?.accId)
    );

    handleLoadDate(newData.data.inv?.date, type);
    setPayAccReq(false);
    setAddValueReq(false);
    setDiscValueReq(false);
    await setPattrenSet(newData.data?.pattSett);

    let authUrl = authKeys.invs + "_PattSett_" + id;
    const data = await getAuth();
    const responce = data?.data?.data.filter((e) => e?.roleKey === authUrl)[0];

    window.sessionStorage.setItem(
      "shortKeyDisc",
      newData.data?.pattSett?.discInvAccTableVisible && responce?.perShowPrices
    );
    window.sessionStorage.setItem(
      "shortKeyAdd",
      newData.data?.pattSett?.addInvAccTableVisible && responce?.perShowPrices
    );

    window.sessionStorage.setItem(
      "invPatt",
      JSON.stringify(newData.data?.pattSett)
    );

    //start .سيتم حذفهم : للاستخدام بالفورم
    setAccStatusName(newData.data.inv?.accStatusName);
    setPostEntDateState(newData.data.inv?.postEntDate);

    setEntSourceUrl(newData.data.inv?.entSourceUrl);
    setEntSourceId(newData.data.inv?.entSourceId);
    setGenEntName(newData.data.inv?.genEntName);
    setPostEntState(newData.data.inv?.postEnt);
    //end
    setInitVal(newData.data?.inv);
    handleStoreSearch(
      "",
      newData.data.inv?.storeId !== null ? newData.data.inv?.storeId : ""
    );
    handlePriceMethodSearch("");
    handlePayMethodSearch("");
    handleSearchCurrency("");
    await getPayMethod(newData.data.inv?.payMethodId);
  };

  const getPayMethod = async (_Id) => {
    try {
      const { data: responce } = await getActivePayMethodSearch("");
      const _type = responce.data.filter((e) => e.id === _Id)[0]?.type;

      await setPayMethType(_type);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const checkTypeAccs = (array, accId) =>
    array?.find((e) => e?.id === accId)?.costCenterReqOpt;

  const handleLoadDate = (date, type) => {
    if (
      type === "next" ||
      type === "prev" ||
      type === "code" ||
      type === "doc"
    ) {
      form.setFieldsValue({
        date: date !== null ? moment(date) : undefined,
      });
    }
    if (type === "new") {
      form.setFieldsValue({
        date: dateTimeNow(),
      });
    }
  };

  const handleClientSearch = async (_Search, _Active, _Acc) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null || _Active === undefined || _Active === ""
            ? []
            : [_Active],
        accId: _Acc,
      };
      const { data: responce } = await getClientActiveSearch(obj);
      setClientOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const handleAccActSearch = async (_Search, _Active, _Client) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null || _Active === undefined || _Active === ""
            ? []
            : [_Active],
        clientId: _Client,
      };
      const { data: responce } = await GetActiveChildren(obj);
      if (responce?.data.length > 0) {
        setAccOpt(responce?.data);

        if (_Client !== undefined && _Client !== "" && _Client !== null) {
          form.setFieldsValue({ clientAccId: responce.data[0].id });
        }
      } else {
        setAccOpt([]);
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const handleStoreSearch = async (_Search, _Active) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null ||
          _Active === undefined ||
          _Active === "" ||
          _Active === "00000000-0000-0000-0000-000000000000"
            ? []
            : [_Active],
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setStoreOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const handlePriceMethodSearch = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const handlePayMethodSearch = async (_Search) => {
    try {
      const { data: responce } = await getActivePayMethodSearch(_Search);
      setPayMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      setLoadData(false);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showInvModal = JSON.parse(
        window.sessionStorage.getItem("showInvModal")
      );
      let showAddAccModal = JSON.parse(
        window.sessionStorage.getItem("showAddAccModal")
      );
      let showCostModal = JSON.parse(
        window.sessionStorage.getItem("showCostModal")
      );
      let showDiscAccModal = JSON.parse(
        window.sessionStorage.getItem("showDiscAccModal")
      );
      let showSearchModal = JSON.parse(
        window.sessionStorage.getItem("showSearchModal")
      );
      if (
        (showInvModal === false || showInvModal === null) &&
        (showAddAccModal === false || showAddAccModal === null) &&
        (showCostModal === false || showCostModal === null) &&
        (showDiscAccModal === false || showDiscAccModal === null) &&
        (showSearchModal === false || showSearchModal === null)
      ) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "saveAndPrint":
              setSaveAndPrint(true);
              form.submit();
              break;
            case "new":
              JSON.parse(window.sessionStorage.getItem("promptEnt"))
                ? showConfirmNew("new")
                : initValue("", "new");
              break;
            case "print":
              let isRecord = form.getFieldValue("id") === "";
              let isChanged = JSON.parse(
                window.sessionStorage.getItem("promptEnt")
              );
              let isPrint =
                isChanged === false && isRecord === false ? true : false;
              if (isPrint) onPrint();
              break;
            case "delete":
              let isDelete = form.getFieldValue("id") !== "";
              if (isDelete) showConfirmDelete();
              break;
            case "search":
              setShowSearch(true);
              break;
            case "next":
              JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                ? showConfirmNew("next")
                : next();
              break;
            case "prev":
              JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                ? showConfirmNew("prev")
                : prev();
              break;
            case "block1":
              document
                .getElementById("fContent")
                .getElementsByTagName("Input")[0]
                .focus();
              break;
            case "block2":
              if (!!gridMainRef.current)
                gridMainRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              break;
            case "block3":
              setActiveKey("1");
              setTimeout(() => {
                focusEffectiveFields(
                  pattrenSet,
                  refObj,
                  disableAddPart,
                  disableDiscsPart
                );
              });
              break;
            case "block4":
              if (
                JSON.parse(window.sessionStorage.getItem("shortKeyDisc")) ===
                true
              ) {
                setActiveKey("2");
                setTimeout(function () {
                  if (!!gridDiscRef.current)
                    gridDiscRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 0);
              }

              break;
            case "block5":
              if (
                JSON.parse(window.sessionStorage.getItem("shortKeyAdd")) ===
                true
              ) {
                setActiveKey("3");
                setTimeout(function () {
                  if (!!gridAddRef.current)
                    gridAddRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
                }, 0);
              }
              break;
            case "tag":
              //  blurControl();
              if (!!gridMainRef.current)
                gridMainRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridDiscRef.current)
                gridDiscRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridAddRef.current)
                gridAddRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, [pattrenSet, disableDiscsPart, disableAddPart]);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = () => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const blurControl = () => {
    document
      .getElementById("fContent")
      .getElementsByTagName("Input")[0]
      .focus();
    document.getElementById("fContent").getElementsByTagName("Input")[0].blur();
  };

  const onFinish = async (values) => {
    setLoadData(true);
    const oldDate = values.date;
    values.postEnt = postEntState;
    values.invPattSettId = id;
    delete values.unitTotal;
    delete values.entSourceId;
    const tempObj = validateGrids(
      values,
      pattrenSet,
      mainDataSource,
      addsDataSource,
      discsDataSource
    );
    const validate = saveValidate(
      tempObj,
      pattrenSet,
      clientCostMessage,
      invCostMessage,
      itemDiscCostMessage,
      itemAddCostMessage,
      payCostMessage
    );

    let costCenterVal = [];

    for (let i = 0; i < addsDataSource.length; i++) {
      let addMessage = valCostCenter(
        addsDataSource[i],
        _t("strCostCenterInvAdd"),
        pattrenSet?.addInvAccCostCenterReq
      );
      if (addMessage !== undefined) {
        costCenterVal.push(addMessage);
      }
    }

    for (let i = 0; i < discsDataSource.length; i++) {
      let discMessage = valCostCenter(
        discsDataSource[i],
        _t("strCostCenterInvDisc"),
        pattrenSet?.discInvAccCostCenterReq
      );
      if (discMessage !== undefined) {
        costCenterVal.push(discMessage);
      }
    }
    const alertMessage = (
      <>
        {costCenterVal.map((stringView, index) => (
          <p key={index}>{stringView}</p>
        ))}
      </>
    );
    try {
      if (costCenterVal.length < 1) {
        if (validate.status) {
          const dataInv = await editInvoice(tempObj);

          if (saveAndPrint) {
            await onPrint(dataInv.data.data);
          }

          message.success(dataInv.data.message, 3);

          if (tempObj.id === "") {
            initValue("", "add");
          } else {
            initValue(dataInv.data.data, "add");
          }
        } else {
          message.error(validate.message);
        }
      } else {
        message.error(alertMessage);
      }
      setLoadData(false);
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    } finally {
      tempObj.date = oldDate;
      setProChange(false);
      window.sessionStorage.setItem("promptEnt", false);
      setLoadData(false);
    }
  };

  const [tableUnImport, setTableUnImport] = useState([]);
  const [visTableUnImport, setVisTableUnImport] = useState(false);

  const onImportExcel = async (file) => {
    setLoadData(true);
    const hide = message.loading("جاري استلام البيانات ..", 0);
    try {
      const responce = await uploadExcel(file, id);

      if (responce.data.code === 417) {
        setTableUnImport(responce.data.data);
        message.error(responce.data.message);
        setVisTableUnImport(true);
      } else {
        setMainDataSource([
          ...responce.data.data,
          { key: responce.data.data.length },
        ]);
        message.success(responce.data.message);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setTimeout(hide);
      setLoadData(false);
    }
  };

  const handelBeforeUpload = (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return message.error(`xlsx. بصيغة  "${file.name}" يجب أن يكون مجلد`);
    }
  };

  const handleChangeCurr = async (value, row) => {
    try {
      const data = await getRate(
        value,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      form.setFieldsValue({ currRate: data.data.data });
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency, divTabRef });
    }
  };

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };

  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));

  const priceMethodOptList =
    priceMethodOpt.length > 0 &&
    priceMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const payMethodOptList =
    payMethodOpt.length > 0 &&
    payMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const storeOptList =
    storeOpt.length > 0 &&
    storeOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const showConfirmNew = (type, currId, value, popS) => {
    confirm({
      title:
        type === "changeCurr"
          ? _t("msgAskSureToChange")
          : _t("msgAskSureToNew"),
      icon: <ExclamationCircleOutlined />,
      content: type === "changeCurr" ? _t("msSureToChange") : _t("msSureToNew"),
      direction: _t("langDiriction"),

      onOk() {
        window.sessionStorage.setItem("promptEnt", false);
        setProChange(false);
        if (type === "new") {
          initValue("", "new");
        } else if (type === "next") next();
        else if (type === "prev") prev();
        else if (type === "code") searchPattCode(popS);
        else if (type === "doc") searchDoc(popS);
        else if (type === "changeCurr") {
          changeCurr(currId, value);
        } else return;
      },
      onCancel() {
        if (type === "code") form.setFieldsValue({ accId: oldForm?.pattCode });
        else if (type === "doc") form.setFieldsValue({ accId: oldForm?.doc });
        if (type === "date") {
          form.setFieldsValue({ accId: oldForm?.date });
        } else if (type === "accId") {
          form.setFieldsValue({ accId: oldForm?.accId });
        }
        setLoadData(false);
        return;
      },
    });
  };

  const changeUnit = (data) => {
    setChangeU((changeU) => !changeU);
  };

  useEffect(() => {
    setPostEntState(resp?.post);
    setPostEntDateState(resp?.postDate);
  }, [resp]);

  const [printState, setPrintState] = useState(true);

  useEffect(() => {
    if (emptString(form.getFieldValue("id"), null) !== null) {
      if (JSON.parse(window.sessionStorage.getItem("promptEnt")) === true) {
        setPrintState(true);
      } else {
        setPrintState(false);
      }
    } else {
      setPrintState(true);
    }
  }, [form.getFieldValue("id"), proChange]);

  const handlePost = async (state) => {
    try {
      if (form.getFieldValue("entSourceId") !== "") {
        const { data: data } = await savePostState(
          form.getFieldValue("entSourceId"),
          !state
        );
        if (data.code === 200) {
          setResp(data?.data);
          message.success(data.message, 3);
        } else if (data.code === 208) {
          // already exists
          setResp(data?.data);
          message.warning(data.message, 3);
        }
      } else {
        setResp({
          post: !postEntState,
          postDate: postEntDateState,
        });
      }
    } catch {}
  };

  const handleChangeRate = (currId, rate) => {
    if (pattrenSet?.currChType === 0) {
      if (currId !== undefined) {
        changeCurr(currId, rate);
      }
    } else if (pattrenSet?.currChType === 2) {
      if (currId !== undefined) {
        showConfirmNew("changeCurr", currId, rate);
      }
    }
  };

  const onPrint = async (idp) => {
    let _Id = idp !== undefined ? idp : form.getFieldValue().id;
    try {
      setLoadData(true);
      const data = await getInvHtml(
        _Id,
        new Date().getTimezoneOffset(),
        userLanguage === "ar" ? 0 : 1
      );
      printPanel(data.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const items = [
    {
      key: "1",
      label: _t("strSave"),
    },
    {
      key: "2",
      label: _t("strSave&Print"),
    },
  ];

  const onMenuClick = (e) => {
    switch (e?.key) {
      case "1":
        setSaveAndPrint(false);
        break;
      case "2":
        setSaveAndPrint(true);
        break;
      default:
        break;
    }
  };

  const handleSearch = (data, type) => {
    setOldForm(form.getFieldValue());
    if (type === 1) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("code", undefined, undefined, data)
        : searchPattCode(data);
    } else if (type === 2) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("doc", undefined, undefined, data)
        : searchDoc(data);
    }
  };

  return (
    <div key="mainDiv">
      <ActionSearch
        showModal={showSearch}
        setShowModal={setShowSearch}
        handleData={(e, type) => {
          handleSearch(e, type);
        }}
      />

      <React.Fragment>
        <Prompt
          when={JSON.parse(window.sessionStorage.getItem("promptEnt"))}
          message={_t("strUnsavedChanges")}
        />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <InvUnImportDataExcel
            showModal={visTableUnImport}
            setShowModal={setVisTableUnImport}
            dataSource={tableUnImport}
            showModalName="modalUnImportExcel"
          />
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            labelCol={{
              flex: "100px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{ isAdjAtClose: false }}
          >
            <Form.Item name="id" noStyle hidden>
              <Input />
            </Form.Item>
            <Form.Item name="entSourceId" noStyle hidden>
              <Input />
            </Form.Item>
            <div key="div-frhead" name="code" className="frHeader">
              <Row>
                <Col xs={24} md={12}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strInvoices")}</Text>
                      <Text>{pattrenSet?.branchName}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {pattrenSet?.pattName}
                      </Text>
                      <Space size="large">
                        <div>
                          <Button
                            size="small"
                            onClick={() => {
                              setLoadData(true);
                              JSON.parse(
                                window.sessionStorage.getItem("promptEnt")
                              ) === true
                                ? showConfirmNew("prev")
                                : prev();
                              // proChange === true
                              //   ? showConfirmNew("prev")
                              //   : prev();
                            }}
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <RightOutlined />
                            ) : (
                              <LeftOutlined />
                            )}
                          </Button>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↓ + alt
                            </Tag>
                          </div>
                        </div>
                        <div>
                          <Button
                            size="small"
                            icon={<SearchOutlined />}
                            onClick={() => {
                              setShowSearch(true);
                            }}
                          />
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + F
                            </Tag>
                          </div>
                        </div>

                        <div>
                          <Button
                            size="small"
                            onClick={() => {
                              setLoadData(true);
                              JSON.parse(
                                window.sessionStorage.getItem("promptEnt")
                              ) === true
                                ? showConfirmNew("next")
                                : next();
                              // proChange === true
                              //   ? showConfirmNew("next")
                              //   : next();
                            }}
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <LeftOutlined />
                            ) : (
                              <RightOutlined />
                            )}
                          </Button>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↑ + alt
                            </Tag>
                          </div>
                        </div>
                      </Space>
                    </Space>
                    <Form.Item
                      name="pattCode"
                      rules={[
                        {
                          required: true,
                          message: `${_t("strIsRequired")}`,
                          validator: async (_, value) => {
                            if (value === undefined || value === "") {
                              // setActiveKey("1");
                              message.error(
                                `(${_t("strCode")}) ${_t("strIsRequired")}`
                              );
                              throw new Error("message");
                            }
                          },
                        },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        className="pattCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        ref={IX_Code}
                        bordered={false}
                        onPressEnter={() =>
                          JSON.parse(
                            window.sessionStorage.getItem("promptEnt")
                          ) === true
                            ? showConfirmNew("code")
                            : searchPattCode()
                        }
                        disabled={!pattrenSet?.codeEnable}
                      />
                    </Form.Item>
                  </Space>
                </Col>

                <Col
                  xs={24}
                  md={12}
                  style={{ textAlign: _t("txtRevDiriction") }}
                >
                  <Space size="small">
                    {(genEntName ?? "" !== "") && (
                      <>
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={entSourceUrl}
                        >
                          <LinkOutlined />
                          {genEntName}
                        </Link>{" "}
                        /
                      </>
                    )}

                    {pattrenSet?.accStatusVisible &&
                      (accStatusName ?? "" !== "") && (
                        <>
                          <Text>{accStatusName}</Text>/
                        </>
                      )}

                    <>
                      {pattrenSet?.postEntVisible && entSourceId && (
                        <Popconfirm
                          title={_t("msgAskChangeStatus")}
                          onConfirm={() => handlePost(postEntState)}
                          disabled={!pattrenSet?.postEntEnable}
                        >
                          <Button
                            style={{
                              padding: 0,
                              color: postEntState === true ? "green" : "red",
                            }}
                            disabled={!pattrenSet?.postEntEnable}
                            size="small"
                            type="text"
                          >
                            {postEntState === true
                              ? _t("strDeported")
                              : _t("strNotDeported")}{" "}
                            {postEntDateState !== null
                              ? ":" +
                                moment(postEntDateState).format(
                                  "DD/MM/YYYY - h:mm a"
                                )
                              : ""}
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  </Space>
                </Col>
              </Row>
            </div>
            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div
                  id="fContent"
                  key="div-frContent"
                  name="field"
                  className="frContent"
                >
                  <Row gutter={12}>
                    <Col xs={24} md={8} hidden={!pattrenSet?.clientVisible}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.clientAccId !== curr.clientAccId
                        }
                      >
                        {({ getFieldValue }) => {
                          return (
                            <Form.Item
                              name="clientId"
                              label={
                                pattrenSet?.invType !== 1 &&
                                pattrenSet?.invType !== 2
                                  ? _t("strClient")
                                  : _t("strSupplier")
                              }
                              rules={[
                                {
                                  required: pattrenSet?.clientReq,
                                  validator: async (_, value) => {
                                    if (
                                      pattrenSet?.clientReq &&
                                      (value === undefined || value === "")
                                    ) {
                                      // setActiveKey("1");
                                      message.error(
                                        `(${
                                          pattrenSet?.invType !== 1 &&
                                          pattrenSet?.invType !== 2
                                            ? _t("strClient")
                                            : _t("strSupplier")
                                        }) ${_t("strIsRequired")}`
                                      );
                                      throw new Error("message");
                                    }
                                  },
                                },
                              ]}
                            >
                              <SelectClientByAcc
                                placeholder={
                                  pattrenSet?.invType !== 1 &&
                                  pattrenSet?.invType !== 2
                                    ? _t("strClient")
                                    : _t("strSupplier")
                                }
                                addId={
                                  initVal?.clientId !== null
                                    ? initVal?.clientId
                                    : ""
                                }
                                onChange={promptForm}
                                accId={getFieldValue("clientAccId")}
                                disabled={!pattrenSet?.clientEnable}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.clientAccVisible}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.clientId !== curr.clientId
                        }
                      >
                        {({ getFieldValue }) => {
                          const _client = getFieldValue("clientId");
                          const handleChange = (data) => {
                            form.setFieldsValue({ clientAccId: data });
                          };
                          return (
                            <Form.Item
                              name="clientAccId"
                              label={
                                pattrenSet?.invType !== 1 &&
                                pattrenSet?.invType !== 2
                                  ? _t("strClientAcc")
                                  : _t("strSupplierAcc")
                              }
                              rules={[
                                {
                                  required: pattrenSet?.clientAccReq,
                                  validator: async (_, value) => {
                                    if (
                                      pattrenSet?.clientAccReq &&
                                      (value === undefined || value === "")
                                    ) {
                                      // setActiveKey("1");
                                      message.error(
                                        `(${
                                          pattrenSet?.invType !== 1 &&
                                          pattrenSet?.invType !== 2
                                            ? _t("strClientAcc")
                                            : _t("strSupplierAcc")
                                        }) ${_t("strIsRequired")}`
                                      );
                                      throw new Error("message");
                                    }
                                  },
                                },
                              ]}
                            >
                              <SelectAccount
                                addId={form.getFieldValue("clientAccId")}
                                placeholder={
                                  pattrenSet?.invType !== 1 &&
                                  pattrenSet?.invType !== 2
                                    ? _t("strClientAcc")
                                    : _t("strSupplierAcc")
                                }
                                clientId={_client}
                                disabled={!pattrenSet?.clientAccEnable}
                                onChange={(_, relatedObj) => {
                                  promptForm();
                                  setClientOptReqCost(
                                    relatedObj?.costCenterReqOpt
                                  );
                                }}
                                handleChange={handleChange}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      md={8}
                      hidden={!pattrenSet?.clientAccCostCenterVisible}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.clientAccCostCenter_Maps !==
                          curr.clientAccCostCenter_Maps
                        }
                      >
                        {({ getFieldValue }) => {
                          setClientCostMessage(
                            valByPircInput(
                              clientOptReqCost,
                              getFieldValue("clientAccCostCenter_Maps")
                            )
                          );
                          return (
                            <Form.Item name="clientAccCostCenter_Maps" hidden>
                              <Input />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>

                      <Form.Item
                        name="clientAccCostCenter"
                        label={
                          pattrenSet?.invType !== 1 && pattrenSet?.invType !== 2
                            ? _t("strCostCenterClient")
                            : _t("strCostCenterSupplier")
                        }
                        rules={[
                          {
                            required:
                              pattrenSet?.clientAccCostCenterReq === true
                                ? pattrenSet?.clientAccCostCenterReq
                                : clientCostMessage !== undefined
                                ? true
                                : false,
                            validator: async (_, value) => {
                              if (
                                pattrenSet?.clientAccCostCenterReq === true
                                  ? pattrenSet?.clientAccCostCenterReq &&
                                    (value === undefined || value === "")
                                  : clientCostMessage !== undefined &&
                                    (value === undefined || value === "")
                                  ? true
                                  : false &&
                                    (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${
                                    pattrenSet?.invType !== 1 &&
                                    pattrenSet?.invType !== 2
                                      ? _t("strCostCenterClient")
                                      : _t("strCostCenterSupplier")
                                  }) ${_t("strIsRequired")}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <CostCenterInput
                          form={form}
                          fieldName="clientAccCostCenter"
                          arrName="clientAccCostCenter_Maps"
                          unValue={true}
                          type="input"
                          accountValidationOpt={clientOptReqCost}
                          onHandelData={(e) => {
                            form.setFieldsValue({ clientAccCostCenter: e });
                          }}
                          disabled={!pattrenSet?.clientAccCostCenterEnable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                      <Form.Item
                        name="date"
                        label={_t("strDate")}
                        rules={[{ required: false }]}
                      >
                        <DatePicker
                          ref={IX_Date}
                          allowClear={false}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                          onChange={promptForm}
                          disabled={!pattrenSet?.dateEnable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.storeVisible}>
                      <Form.Item
                        name="storeId"
                        label={
                          pattrenSet?.invType !== 7
                            ? _t("strStore")
                            : _t("strFromStore")
                        }
                        rules={[
                          {
                            required: true,
                            validator: async (_, value) => {
                              if (value === undefined || value === "") {
                                // setActiveKey("1");
                                message.error(
                                  `(${
                                    pattrenSet?.invType !== 7
                                      ? _t("strStore")
                                      : _t("strFromStore")
                                  }) ${_t("strIsRequired")}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <SelectSingle
                          onChange={promptForm}
                          placeholder={
                            pattrenSet?.invType !== 7
                              ? _t("strStore")
                              : _t("strFromStore")
                          }
                          disabled={!pattrenSet?.storeEnable}
                          allowClear={true}
                          fieldNames={{
                            label: "name",
                            value: "id",
                          }}
                          opt={storeOpt}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.toStoreVisible}>
                      <Form.Item
                        name="toStoreId"
                        label={
                          pattrenSet?.invType !== 7
                            ? _t("strStore")
                            : _t("strToStore")
                        }
                        rules={[
                          {
                            required:
                              pattrenSet?.toStoreReq ||
                              pattrenSet?.invType === 7,
                            validator: async (_, value) => {
                              if (
                                (pattrenSet?.toStoreReq ||
                                  pattrenSet?.invType === 7) &&
                                (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${
                                    pattrenSet?.invType !== 7
                                      ? _t("strStore")
                                      : _t("strToStore")
                                  }) ${_t("strIsRequired")}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <SelectSingle
                          onChange={promptForm}
                          placeholder={
                            pattrenSet?.invType !== 7
                              ? _t("strStore")
                              : _t("strToStore")
                          }
                          disabled={!pattrenSet?.toStoreEnable}
                          allowClear={true}
                          fieldNames={{
                            label: "name",
                            value: "id",
                          }}
                          opt={storeOpt}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.invAccVisible}>
                      <Form.Item
                        name="invAccId"
                        label={_t("strInvAccount")}
                        rules={[
                          {
                            required: pattrenSet?.invAccReq,
                            validator: async (_, value) => {
                              if (
                                pattrenSet?.invAccReq &&
                                (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${_t("strInvAccount")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <SelectAccount
                          addId={form.getFieldValue("invAccId")}
                          placeholder={_t("strInvAccount")}
                          disabled={!pattrenSet?.invAccEnable}
                          onChange={(_, relatedObj) => {
                            promptForm();
                            setInvOptReqCost(relatedObj?.costCenterReqOpt);
                          }}
                          handleChange={() => {}}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      md={8}
                      hidden={!pattrenSet?.invAccCostCenterVisible}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.invAccCostCenter_Maps !==
                          curr.invAccCostCenter_Maps
                        }
                      >
                        {({ getFieldValue }) => {
                          setInvCostMessage(
                            valByPircInput(
                              invOptReqCost,
                              getFieldValue("invAccCostCenter_Maps")
                            )
                          );
                          return (
                            <Form.Item name="invAccCostCenter_Maps" hidden>
                              <Input />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>

                      <Form.Item
                        name="invAccCostCenter"
                        label={_t("strCostCenterInv")}
                        rules={[
                          {
                            required:
                              pattrenSet?.invAccCostCenterReq === true
                                ? pattrenSet?.invAccCostCenterReq
                                : invCostMessage !== undefined
                                ? true
                                : false,
                            validator: async (_, value) => {
                              if (
                                pattrenSet?.invAccCostCenterReq === true
                                  ? pattrenSet?.invAccCostCenterReq &&
                                    (value === undefined || value === "")
                                  : invCostMessage !== undefined &&
                                    (value === undefined || value === "")
                                  ? true
                                  : false &&
                                    (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${_t("strCostCenterInv")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <CostCenterInput
                          innerRef={refInvCostCenter}
                          form={form}
                          fieldName="invAccCostCenter"
                          arrName="invAccCostCenter_Maps"
                          unValue={true}
                          accountValidationOpt={invOptReqCost}
                          onHandelData={(e) => {
                            form.setFieldsValue({ invAccCostCenter: e });
                          }}
                          type="input"
                          disabled={!pattrenSet?.invAccCostCenterEnable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.currVisible}>
                      <Form.Item label={_t("strCurrency")}>
                        <Input.Group compact>
                          <Form.Item
                            name="currId"
                            noStyle
                            rules={[
                              {
                                required: true,
                                validator: async (_, value) => {
                                  if (value === undefined || value === "") {
                                    // setActiveKey("1");
                                    message.error(
                                      `(${_t("strCurrency")}) ${_t(
                                        "strIsRequired"
                                      )}`
                                    );
                                    throw new Error("message");
                                  }
                                },
                              },
                            ]}
                          >
                            <SelectSingle
                              ref={IX_Currency}
                              style={{ width: "70%" }}
                              onChange={(e) => {
                                handleChangeCurr(e, "");
                                promptForm();
                              }}
                              allowClear={false}
                              placeholder={_t("strNotSelected")}
                              disabled={!pattrenSet?.currEnable}
                              fieldNames={{
                                label: "currName",
                                value: "currId",
                              }}
                              opt={currencyOpt}
                            />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, curr) =>
                              prev.currId !== curr.currId
                            }
                          >
                            {({ getFieldValue }) => {
                              const _currId = getFieldValue("currId");

                              const state =
                                !pattrenSet?.currEnable ||
                                _currId === pattrenSet?.companyCurrId;

                              return (
                                <Form.Item
                                  name="currRate"
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      validator: async (_, value) => {
                                        if (
                                          value === undefined ||
                                          value === ""
                                        ) {
                                          // setActiveKey("1");
                                          message.error(
                                            `(${_t("strCurrRate")}) ${_t(
                                              "strIsRequired"
                                            )}`
                                          );
                                          throw new Error("message");
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                                  <InputCurrency
                                    precision={objCountNum.rate}
                                    size="small"
                                    style={{ width: "30%" }}
                                    onChange={promptForm}
                                    placeholder={_t("strRate")}
                                    onBlur={() =>
                                      handleChangeRate(
                                        form.getFieldValue("currId"),
                                        form.getFieldValue("currRate")
                                      )
                                    }
                                    suffix={
                                      <Tooltip title={_t("msgRateCurrValue")}>
                                        <InfoCircleOutlined
                                          style={{ color: "rgba(0,0,0,.45)" }}
                                        />
                                      </Tooltip>
                                    }
                                    disabled={state}
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      md={8}
                      hidden={
                        !pattrenSet?.priceMethodVisible || !auth?.perShowPrices
                      }
                    >
                      <Form.Item
                        name="priceMethodId"
                        label={_t("strPriceMethod")}
                        rules={[
                          {
                            required: pattrenSet?.priceMethodReq,
                            validator: async (_, value) => {
                              if (
                                pattrenSet?.priceMethodReq &&
                                (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${_t("strPriceMethod")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <SelectSingle
                          onChange={(e) => {
                            promptForm();
                            if (
                              e !== undefined &&
                              emptString(mainDataSource[0].itemId, false)
                            ) {
                              confirm({
                                title: _t("msgDoYouWantRepriceAllInvoiceItems"),
                                icon: <ExclamationCircleFilled />,
                                direction: _t("langDiriction"),
                                onOk: async () => {
                                  setLoadData(true);
                                  let res = await reCalcItemsPrice(
                                    mainDataSource,
                                    form
                                      .getFieldValue()
                                      .date.toISOString()
                                      .slice(0, 16) + "Z",
                                    e,
                                    form.getFieldValue().currRate === undefined
                                      ? ""
                                      : form.getFieldValue().currRate
                                  );
                                  //setMainDataSource( res.length > 0 ? [...res, { key: res.length }]: mainDataSource);

                                  setMainDataSource(
                                    res.length > 0 ? [...res] : mainDataSource
                                  );
                                  setLoadData(false);
                                },
                                onCancel() {},
                              });
                            }
                          }}
                          placeholder={_t("strPriceMethod")}
                          disabled={!pattrenSet?.priceMethodEnable}
                          allowClear={true}
                          fieldNames={{
                            label: "name",
                            value: "id",
                          }}
                          opt={priceMethodOpt}
                        />

                        {/* <Select
                            allowClear
                            showSearch
                            onSearch={(value) => {
                              handlePriceMethodSearch(value);
                            }}
                            optionFilterProp="children"
                            notFoundContent={
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              ></Empty>
                            }
                            placeholder={_t("strPriceMethod")}
                            size="small"
                            onChange={promptForm}
                            disabled={!pattrenSet?.priceMethodEnable}
                          >
                            {priceMethodOptList}
                          </Select> */}
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={8} hidden={!pattrenSet?.docVisible}>
                      <Form.Item
                        name="doc"
                        label={_t("strDocument")}
                        rules={[
                          {
                            required: pattrenSet?.docReq,
                            message: `${_t("strIsRequired")}`,
                            validator: async (_, value) => {
                              if (
                                pattrenSet?.docReq &&
                                (value === undefined || value === "")
                              ) {
                                // setActiveKey("1");
                                message.error(
                                  `(${_t("strDocument")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                        normalize={(value) => value.trimStart()}
                      >
                        <Search
                          placeholder={_t("strDocument")}
                          maxLength={200}
                          autoComplete="off"
                          size="small"
                          //onChange={promptForm}
                          onSearch={() =>
                            JSON.parse(
                              window.sessionStorage.getItem("promptEnt")
                            ) === true
                              ? showConfirmNew("doc")
                              : searchDoc()
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div
                    style={{
                      opacity: 0.8,
                      zIndex: 99,
                      marginLeft: 100,
                      marginRight: 100,
                      position: "absolute",
                      top: 24,
                    }}
                  >
                    <Tag color="#2b2b2b" hidden={!altPress}>
                      alt + 1
                    </Tag>
                  </div>
                </div>
                <div key="div-InvMainGrid" className="frContent">
                  <Col xs={24} md={24} style={{ marginTop: 10 }}>
                    <div>
                      <InvMainGrid
                        auth={auth}
                        innerRef={gridMainRef}
                        form={form}
                        mainDataSource={mainDataSource}
                        setMainDataSource={setMainDataSource}
                        pattrenSet={pattrenSet}
                        setLoadData={setLoadData}
                        showConfirmNew={showConfirmNew}
                        id={id}
                        promptForm={promptForm}
                        noStyle={true}
                        changeUnit={changeUnit}
                        style={{ height: `calc(100vh - 400px)`, width: "100%" }}
                      />
                      <div
                        style={{
                          opacity: 0.8,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + 2
                        </Tag>
                      </div>
                    </div>
                  </Col>
                </div>

                <div key="div-finalTotals" ref={divTabRef}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Panel header={_t("strTotalsAndDiscounts")} key="1">
                      <div key="div-Tabs" className="frContent">
                        <Col xs={24} md={24} style={{ marginTop: 10 }}>
                          <Tabs
                            // defaultActiveKey="1"
                            activeKey={activeKey}
                            tabPosition={_t("txtDiriction")}
                            className="ak-tabs"
                            onTabClick={handleChangeTab}
                          >
                            <TabPane
                              forceRender
                              tab={
                                <div>
                                  {_t("strGeneral")}
                                  <div
                                    style={{
                                      opacity: 0.8,
                                      position: "absolute",
                                      top: 24,
                                    }}
                                  >
                                    <Tag color="#2b2b2b" hidden={!altPress}>
                                      alt + 3
                                    </Tag>
                                  </div>
                                </div>
                              }
                              key="1"
                              style={{ margin: 0 }}
                            >
                              <InvGeneralTotal
                                refObj={refObj}
                                pattrenSet={pattrenSet}
                                form={form}
                                // accListOptions={invAccOptList}
                                //  accList={invAccOpt}
                                accList={accList}
                                payMethodOpt={payMethodOpt}
                                payMethListOptions={payMethodOpt}
                                handlePayMethodSearch={(val) =>
                                  handlePayMethodSearch(val)
                                }
                                promptForm={promptForm}
                                setSummTotal={setSummTotal}
                                discValueReq={discValueReq}
                                setDiscValueReq={setDiscValueReq}
                                addValueReq={addValueReq}
                                setAddValueReq={setAddValueReq}
                                payAccReq={payAccReq}
                                setPayAccReq={setPayAccReq}
                                genTotalObj={genTotalObj}
                                mainDataSource={mainDataSource}
                                inLoadData={inLoadData}
                                setInLoadData={setInLoadData}
                                disableDiscsPart={disableDiscsPart}
                                setDisableDiscsPart={setDisableDiscsPart}
                                setDiscsDataSource={setDiscsDataSource}
                                setAddsDataSource={setAddsDataSource}
                                addsDataSource={addsDataSource}
                                discsDataSource={discsDataSource}
                                setDisableAddPart={setDisableAddPart}
                                disableAddPart={disableAddPart}
                                changeU={changeU}
                                setStatAddFields={setStatAddFields}
                                setStatDiscFields={setStatDiscFields}
                                refInvPayMethod={ref_InvPayMethod}
                                refInvPayMethodAcc={ref_InvPayMethodAcc}
                                refDiscInvAcc={ref_DiscInvAcc}
                                refAddInvAcc={ref_AddInvAcc}
                                refDiscItemAcc={ref_DiscItemAcc}
                                refAddItemAcc={ref_AddItemAcc}
                                refDueDateVisible={ref_DueDateVisible}
                                refPayValueVisible={ref_PayValueVisible}
                                auth={auth}
                                setActiveKey={setActiveKey}
                                setItemDiscOptReqCost={setItemDiscOptReqCost}
                                itemDiscOptReqCost={itemDiscOptReqCost}
                                setItemDiscCostMessage={setItemDiscCostMessage}
                                itemDiscCostMessage={itemDiscCostMessage}
                                setItemAddOptReqCost={setItemAddOptReqCost}
                                itemAddOptReqCost={itemAddOptReqCost}
                                setItemAddCostMessage={setItemAddCostMessage}
                                itemAddCostMessage={itemAddCostMessage}
                                setPayOptReqCost={setPayOptReqCost}
                                payOptReqCost={payOptReqCost}
                                setPayCostMessage={setPayCostMessage}
                                payCostMessage={payCostMessage}
                                setInvDiscsOptReqCost={setInvDiscsOptReqCost}
                                invDiscsOptReqCost={invDiscsOptReqCost}
                                setInvAddsOptReqCost={setInvAddsOptReqCost}
                                invAddsOptReqCost={invAddsOptReqCost}
                                payMethType={payMethType}
                                setPayMethType={setPayMethType}
                              />
                            </TabPane>

                            <TabPane
                              forceRender
                              tab={
                                JSON.parse(
                                  window.sessionStorage.getItem("shortKeyDisc")
                                ) === true ? (
                                  <div>
                                    {_t("strDiscount")}
                                    <div
                                      style={{
                                        opacity: 0.8,
                                        position: "absolute",
                                        top: 24,
                                      }}
                                    >
                                      <Tag color="#2b2b2b" hidden={!altPress}>
                                        alt + 4
                                      </Tag>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              key="2"
                              disabled={
                                !pattrenSet?.discInvAccTableVisible ||
                                !auth?.perShowPrices
                              }
                            >
                              <InvGridDiscs
                                innerRef={gridDiscRef}
                                accList={accList}
                                pattrenSet={pattrenSet}
                                form={form}
                                setDiscsDataSource={setDiscsDataSource}
                                mainDataSource={mainDataSource}
                                setDisableDiscsPart={setDisableDiscsPart}
                                discsDataSource={discsDataSource}
                                setLoadData={setLoadData}
                                showConfirmNew={showConfirmNew}
                                id={id}
                                promptForm={promptForm}
                                noStyle={true}
                                currOpt={currOptTable}
                                summTotal={summTotal}
                                setGenTotalObj={setGenTotalObj}
                                statDiscFields={statDiscFields}
                                setStatDiscFields={setStatDiscFields}
                              />
                            </TabPane>

                            <TabPane
                              forceRender
                              tab={
                                JSON.parse(
                                  window.sessionStorage.getItem("shortKeyAdd")
                                ) === true ? (
                                  <div>
                                    {_t("strAdd")}
                                    <div
                                      style={{
                                        opacity: 0.8,
                                        position: "absolute",
                                        top: 24,
                                      }}
                                    >
                                      <Tag color="#2b2b2b" hidden={!altPress}>
                                        alt + 5
                                      </Tag>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              key="3"
                              disabled={
                                !pattrenSet?.addInvAccTableVisible ||
                                !auth?.perShowPrices
                              }
                            >
                              <InvGridAdds
                                innerRef={gridAddRef}
                                accList={accList}
                                pattrenSet={pattrenSet}
                                form={form}
                                setAddsDataSource={setAddsDataSource}
                                mainDataSource={mainDataSource}
                                setDisableAddPart={setDisableAddPart}
                                addsDataSource={addsDataSource}
                                setLoadData={setLoadData}
                                showConfirmNew={showConfirmNew}
                                id={id}
                                promptForm={promptForm}
                                noStyle={true}
                                currOpt={currOptTable}
                                summTotal={summTotal}
                                setGenTotalObj={setGenTotalObj}
                                statAddFields={statAddFields}
                                setStatAddFields={setStatAddFields}
                              />
                            </TabPane>
                          </Tabs>
                        </Col>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Row>
            <div key="div-Footer" className="frFooter">
              <Form.Item>
                <Space size="middle">
                  <div>
                    <Dropdown.Button
                      type="link"
                      onClick={() => form.submit()}
                      size="small"
                      menu={{
                        items,
                        onClick: onMenuClick,
                      }}
                      disabled={loadData}
                    >
                      {saveAndPrint ? (
                        <>
                          {_t("strSave&Print")} <PrinterOutlined />
                        </>
                      ) : (
                        <>
                          {_t("strSave")} <SaveOutlined />
                        </>
                      )}
                    </Dropdown.Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="text"
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        if (
                          JSON.parse(
                            window.sessionStorage.getItem("promptEnt")
                          ) === true
                        ) {
                          showConfirmNew("new");
                        } else {
                          initValue("", "new");
                        }
                      }}
                      size="small"
                    >
                      {_t("strNew")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + N
                      </Tag>
                    </div>
                  </div>

                  <div>
                    <Button
                      type="text"
                      icon={<PrinterOutlined />}
                      onClick={() => onPrint()}
                      disabled={printState}
                      size="small"
                    >
                      {_t("strPrint")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + P
                      </Tag>
                    </div>
                  </div>

                  {true && (
                    <Upload
                      action={(file) => onImportExcel(file)}
                      beforeUpload={(file) => {
                        handelBeforeUpload(file);
                      }}
                      maxCount={1}
                      showUploadList={false}
                    >
                      <Button
                        icon={<FileExcelOutlined />}
                        size="small"
                        type="text"
                      >
                        {_t("strImportFormExcel")}
                      </Button>
                    </Upload>
                  )}

                  <div>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => showConfirmDelete()}
                      disabled={form.getFieldValue("id") === "" ? true : false}
                      size="small"
                      danger
                    >
                      {_t("strDelete")}
                    </Button>
                    <div
                      style={{ opacity: 0.8, position: "absolute", top: 24 }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + shift + delete
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};
export default InvForm;
