export function newRow(list) {
  let newNum =
    list.reduce((acc, shot) => (acc = acc > shot.key ? acc : shot.key), -1) + 1;
  return [...list, { key: newNum }];
}

export function newRowRest(list, rest) {
  
  let newNum =
    list.reduce((acc, shot) => (acc = acc > shot.key ? acc : shot.key), -1) + 1;
  return [...list, { key: newNum, ...rest }];
}
