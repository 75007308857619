import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
  Typography,
  Tag,
  message,
  Tooltip,
  Popconfirm,
  Divider,
} from "antd";
import { checkEntType } from "./helperPatt/showFieldByType";
import {
  getNewCode,
  initiale,
  editPattEnt,
  getEntPattById,
} from "../../../services/EntPatService";
import {
  getByEntPatt,
  deleteEntPattSett,
} from "../../../services/EntPatSettService";
import DataGrid from "react-data-grid";
import SelectSingle from "../../helper/selectSingle";
import {
  RetweetOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { shortCut } from "../../helper/shortCuts";
import LinkToBranch from "./helperPatt/popups/linkToBranch";
import PattSettings from "./helperPatt/entPattSettings";

const EntPattForm = () => {
  const IX_Code = useRef(null);
  const IX_NameAr = useRef(null);
  const IX_NameEn = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [selectedPattren, setSelectedPattren] = useState(undefined);
  const [dataObj, setDataObj] = useState(undefined);
  const [selectedTypeId, setSelectedTypeId] = useState(undefined);
  const [showLinkToBransh, setShowLinkToBransh] = useState(false);
  const [detailsCol, setDetailsCol] = useState(false);
  const [discsCol, setDiscsCol] = useState(false);
  const [addsCol, setAddsCol] = useState(false);
  const [branchDataSource, setBranchDataSource] = useState([]);
  const [entPattSettId, setEntPattSettId] = useState(undefined);

  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const [isGoBack, setIsGoBack] = useState(true);
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////
  const codeGenerator = async () => {
    setLoadData(true);
    const { data: newData } = await getNewCode();
    form.setFieldsValue({ code: newData.data });
    IX_NameAr.current.focus();
    setLoadData(false);
  };

  const handleGetBranchesCol = async () => {
    try {
      const { data: data } = await getByEntPatt(id);
      setBranchDataSource(data.data);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (id === "new") {
        return await codeGenerator();
      } else {
        try {
          const { data } = await getEntPattById(id);
          form.setFieldsValue({
            id: data.data.Id,
            entPattSettId: data.data.entPattSettId,
            code: data.data.code,
            nameAr: data.data.nameAr,
            nameEn: data.data.nameEn,
            entTypeId: data.data.entTypeId,
          });
          
          setDataObj(data);
          setSelectedTypeId(data.data.entTypeId);
          setSelectedPattren(data.data.entTypeId);
          handleGetBranchesCol();
          IX_NameAr.current.focus();
        } catch (error) {
          handleEx(error, { IX_Code, IX_NameAr, IX_NameEn });
        } finally {
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = (objId) => {
    if (objId !== true) {
      history.replace({ ...history.location, pathname: `/entPatt/${objId}` });
      IX_NameAr.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    sessionStorage.removeItem("popup_entTableSett");
    sessionStorage.removeItem("popup_addTableSett");
    sessionStorage.removeItem("popup_discTableSett");
    // values.detailsColSett= values.detailsColSett.filter(e=>e.visible === true);

    try {
      const data = await editPattEnt(values);
      message.success(data.data.message, 3);
      goBack(data?.data?.data);
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_NameAr, IX_NameEn });
    } finally {
      setLoadData(false);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_NameAr?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };

  const handelChangeType = async (e) => {
    try {
      setSelectedPattren(e);
      setSelectedTypeId(e);
      //const obj = await checkEntType(e);
      if (e !== undefined) {
      const { data: data } = await initiale(e);
      setDataObj(data);
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error);
    }
  };

  const finishLink = () => {
    handleGetBranchesCol();
    setEntPattSettId(undefined);
  };
  const deleteBranch = async (objId) => {
    await deleteEntPattSett(objId);
    await handleGetBranchesCol();
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <LinkToBranch
        showModal={showLinkToBransh}
        setShowModal={setShowLinkToBransh}
        selectedTypeId={selectedTypeId}
        entPattSettId={entPattSettId}
        entPattId={id}
        onFinishLink={finishLink}
      />

      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="itemUnitform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{
            flex: "150px",
          }}
          wrapperCol={{
            span: 50,
          }}
        >
          <Form.Item name="entPattSettId" hidden>
            <Input />
          </Form.Item>
          <div name="code" className="frHeader">
            <Row>
              <Col xs={24} md={24}>
                <Space split="#">
                  <Space split="\">
                    <Text>{_t("strPatterns")}</Text>
                    <Text style={{ fontWeight: 700 }}>
                      {_t("strEntPatterns")}
                    </Text>
                  </Space>
                  <div>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Code}
                        bordered={false}
                      />
                    </Form.Item>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + F
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </div>
          <Row gutter={6}>
            {id !== "new" && (
              <Col xs={24} md={6}>
                <div
                  name="field"
                  className="frContent"
                  style={{ height: "100%" }}
                >
                  <Button
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setShowLinkToBransh(true);
                    }}
                  >
                    {_t("strAdd")}
                  </Button>
                  <div style={{ marginTop: 10 }}>
                    <DataGrid
                      //ref={innerRef}
                      columns={[
                        {
                          name: "#",
                          key: "num",
                          minWidth: 30,
                          width: 43,
                        },
                        {
                          name: _t("strBranch"),
                          key: "branchName",
                        },
                        {
                          name: _t("strActions"),
                          key: "action",
                          minWidth: 75,
                          width: 100,
                          formatter: (p) => {
                            return (
                              <>
                                <Tooltip title={_t("strEdit")} color="#6c63ff">
                                  <EditOutlined
                                    style={{ color: "#6c63ff" }}
                                    onClick={() => {
                                      setEntPattSettId(p?.row?.id);
                                      setShowLinkToBransh(true);
                                    }}
                                  />
                                </Tooltip>

                                <Divider type="vertical" />

                                <Popconfirm
                                  title={_t("strSureToDelete")}
                                  onConfirm={() => deleteBranch(p.row.id)}
                                >
                                  <DeleteOutlined
                                    style={{ color: "#EC2867" }}
                                  />
                                </Popconfirm>
                              </>
                            );
                          },
                        },
                      ]}
                      rows={branchDataSource?.map(
                        (e, i) => (e = { ...e, num: i + 1 })
                      )}
                      rowHeight={25}
                      className="rdg-light"
                      defaultColumnOptions={{
                        sortable: true,
                        resizable: true,
                      }}
                      direction={_t("langDiriction")}
                    />
                  </div>
                </div>
              </Col>
            )}

            <Col xs={24} md={id !== "new" ? 18 : 24}>
              <div name="field" className="frContent">
                <Row gutter={12}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="nameAr"
                      label={
                        <div>
                          {_t("strNameAr")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 4,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 1
                            </Tag>
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}`,
                          validator: async (_, value) => {
                          if (value === undefined || value === "") {                     
                            message.error(
                              `(${_t("strNameAr")}) ${_t("strIsRequired")}`
                            );
                            throw new Error("message");
                          }
                        },},
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                        
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        placeholder={_t("strNameAr")}
                        maxLength={200}
                        autoComplete="off"
                        size="small"
                        onChange={promptForm}
                        ref={IX_NameAr}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="nameEn"
                      label={_t("strNameEn")}
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}`,
                        validator: async (_, value) => {
                        if (value === undefined || value === "") {                     
                          message.error(
                            `(${_t("strNameEn")}) ${_t("strIsRequired")}`
                          );
                          throw new Error("message");
                        }
                      }, },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        placeholder={_t("strNameEn")}
                        maxLength={200}
                        autoComplete="off"
                        size="small"
                        onChange={promptForm}
                        ref={IX_NameEn}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="entTypeId"
                      label={_t("strType")}
                      labelCol={{ flex: "85px" }}
                    >
                      <SelectSingle
                        allowClear
                        opt={[
                          {
                            id: "1addd599-60e1-48bf-9d91-390d6b262662",
                            name: _t("strDailyMovement"),
                            filter1: _t("strDailyMovement"),
                          },
                          {
                            id: "9dfc78cd-3569-4433-a9a6-7c0e6aa8b6e7",
                            name: _t("strPayments"),
                            filter1: _t("strPayments"),
                          },
                          {
                            id: "336894bf-06df-4d08-b710-51e2f46b1401",
                            name: _t("strReceipts"),
                            filter1: _t("strReceipts"),
                          },
                          {
                            id: "9b6267dd-651f-4fd6-94c3-de6f63bd4ef5",
                            name: _t("strEntryDeed"),
                            filter1: _t("strEntryDeed"),
                          },
                          {
                            id: "273eb372-062e-4ebc-9579-262af345512a",
                            name: _t("strOpeningEntry"),
                            filter1: _t("strOpeningEntry"),
                          },
                        ]}
                        onChange={(e) => {
                          // setSelectedPattren(e);
                          handelChangeType(e);
                        }}
                        placeholder={_t("strType")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {selectedPattren !== undefined && (
                <PattSettings
                  form={form}
                  dataObj={dataObj}
                  selectedTypeId={selectedTypeId}
                  showLinkToBransh={showLinkToBransh}
                  detailsCol={detailsCol}
                  discsCol={discsCol}
                  addsCol={addsCol}
                  setDetailsCol={setDetailsCol}
                  setDiscsCol={setDiscsCol}
                  setAddsCol={setAddsCol}
                  setLoadData={setLoadData}
                  // selectedPattren={selectedPattren}
                  // setSelectedPattren={setSelectedPattren}
                />
              )}
            </Col>
          </Row>
          <div
            className="frFooter"
            style={id !== "new" ? { marginTop: 3 } : {}}
          >
            <Form.Item>
              <Space size="large">
                {!auth?.perPut && (
                  <div>
                    <Button
                    onClick={form.submit}
                      type="primary"
                      size="small"
                   //   htmlType="submit"
                      disabled={loading}
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                )}
                <Button
                  hidden={typeof onNewStore === "function"}
                  onClick={() => {
                    history.action === "PUSH"
                      ? history.goBack()
                      : history.push("/entPatt");
                  }}
                  size="small"
                >
                  {_t("strBack")}
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

export default EntPattForm;
