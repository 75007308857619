import React from 'react';
import { Button, Result } from 'antd';
import _t from "../../languages/translate";
const PageNotFound = () => (
  <Result
  className="center"
    status="404"
    title={<h1>404</h1>}
    subTitle={_t("msgPageNotFound")}
   // extra={<Button type="primary">Back Home</Button>}
  />
);
export default PageNotFound;