import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Form,
  Input,
  Modal,
  Tag,
  Button,
  Tooltip,
  Divider,
  message,
} from "antd";
import DataGrid, { SelectColumn } from "react-data-grid";
import {
  DeleteOutlined,
  PercentageOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import _t from "../../../../languages/translate";
import { handleEx } from "../../../helper/handleException";
import { getSearchPopup } from "../../../../services/CostCenterService";
import { shortCut } from "../../../helper/shortCuts";
import Draggable from "react-draggable";
import CurrEditor from "../../../helper/dataGrid/source/CurrEditor";
import { getNumFormat } from "../../../helper/countOfZero";
import { emptValue } from "../../../helper/helperMethods";
import { calcAndReturnList } from "./costCenterHelper";
import { _Div, _Mul, _RateMul, _Sub, _Sum, _SumArr ,_ToFixed} from "../../handleMath";
const CostCenterSelector = ({
  unValue = false,
  onHandelData,
  searchValue = "",
  amount = 0,
  showModal,
  setShowModal,
  costCenterValue,
  validationOpt,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [costSource, setCostSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState();
  const [altPress, setAltPress] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);
  const gridRef = useRef(null);
  const costGridRef = useRef(null);
  const refSelectCost = useRef(null);

  const { Search } = Input;
  let mainObj;

  const costColumns = [
    SelectColumn,
    {
      name: _t("strName"),
      key: "costCenterName",
      width: "100%",
    },
  ];

  const columns = [
    {
      key: "key",
      name: "#",
      width: 30,
      minWidth: 30,
      formatter: (p) => {
        return p?.row?.key;
      },
    },
    {
      name: _t("strName"),
      key: "costCenterName",
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{`${_t("strCount")} : ${
            row.totalCount
          }`}</strong>
        );
      },
    },
    {
      name: _t("strRatio"),
      key: "perc",
      width: 30,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              handlePerc(p, e);
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.perc}>
            {getNumFormat(p?.row?.perc, 5)}
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
  ];

  const colValue = [
    {
      name: _t("strValue"),
      key: "value",
      width: 30,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              handleVal(p, e);
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.value}>
            {getNumFormat(p?.row?.value, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalValue}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalValue, 1)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
  ];

  const colDelete = [
    {
      key: "delete",
      name: _t("strDelete"),
      visible: true,
      width: 10,
      formatter: (p) => {
        return (
          <>
            <DeleteOutlined
              style={{ color: "#EC2867" }}
              onClick={() => onDelete(p.row?.costCenterId)}
            />
          </>
        );
      },
    },
  ];

  const onDelete = (rId) => {
    const result = dataSource?.filter((x) => x.costCenterId !== rId);
    setDataSource(result);
    const entries = new Set(selectedRows);
    for (const item of selectedRows) {
      if (rId === item) {
        entries.delete(item);
      }
    }
    setSelectedRows(entries);
  };

  const handlePerc = (p, e) => {
    mainObj = p?.row;

    mainObj.perc = emptValue(e?.target?.value, null);
    calcValue(mainObj);
    setRowChanges(p, mainObj);
  };

  const handleVal = (p, e) => {
    if (p?.row.value !== emptValue(e?.target?.value, null)) {
      mainObj = p?.row;
      mainObj.value = emptValue(e?.target?.value, null);
      calcEmptyPec(mainObj);
      setRowChanges(p, mainObj);
    }
  };

  const searchData = async (value) => {
    try {
      const { data: result } = await getSearchPopup(value);
      setCostSource(result.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
      refSelectCost?.current?.blur();
      costGridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    }
  };

  const calcValue = (obj) => {
    obj.value = _RateMul(emptValue(amount, 0), emptValue(obj.perc, 0));
  };

  const calcEmptyPec = (obj) => {
    obj.perc = null;
  };

  const setRowChanges = (p, obj) => {
    p?.onRowChange(
      {
        ...p.row,
        value: emptValue(obj?.value, null),
        perc: emptValue(obj?.perc, null),
      },
      true
    );
  };

  const sumValue = () => {
    let sum = 0;
    for (let i = 0; i < dataSource.length; i++) {
      sum += parseFloat(emptValue(dataSource[i].value, 0));
    }
    return sum;
  };

  const summaryRows = useMemo(() => {
    const summaryRow = [
      {
        id: "total_0",
        totalCount: dataSource.length,
        totalValue: sumValue(),
      },
    ];
    return summaryRow;
  }, [dataSource]);

  useEffect(() => {
    if (JSON.parse(window.sessionStorage.getItem("showCostModal"))) {
      popupForm.setFieldsValue({ amountVal: amount });

      if (costCenterValue?.length > 0) {
        const entries = new Set();
        for (let i = 0; i < costCenterValue.length; i++) {
          entries.add(costCenterValue[i].costCenterId);
        }

        setSelectedRows(entries);
        setDataSource(costCenterValue);
      }
      setLoadData(true);

      popupForm.setFieldsValue({ search: searchValue });
      searchData(searchValue);
    } else {
      setSelectedRows();
      setDataSource([]);
      setCostSource([]);
    }
  }, [showModal]);

  const distributeHundred = () => {
    if (dataSource.length === 1) {
      return dataSource.map((e) =>
        e.value === null && e.perc === null ? { ...e, perc: 100 } : e
      );
    } else {
      return dataSource;
    }
  };

  const onFinish = () => {
    const arr = distributeHundred();

    const validationList = arr.filter(
      (e) => e.value === null && e.perc === null
    );
    const perc = emptValue(
      arr?.reduce((partialSum, a) => _Sum(partialSum, a.perc), 0),
      0
    );
    if (validationList?.length > 0) {
      message.error(_t("msgErrorInsertCostCenter"));
    } else if (perc > 100) {
      message.error(_t("msgErrorInsertPersCostCenter"));
    } else {
      const obj = calcAndReturnList(amount, arr, validationOpt, unValue);
      onHandelData(obj);
    }
  };

  const autoDistribution = () => {
    let arr = dataSource.map((e) => {
      return { ...e };
    });
    const _perc = _ToFixed(_Div(100, dataSource.length),9);
    const _value = _RateMul(emptValue(amount, 0), emptValue(_perc, 0));
    arr.map((e, index) => {
      if (index < arr.length - 1) {
        e.perc = _perc;
        e.value = _value;
      } else {
        e.value = _Sub(amount, _Mul(_value, arr.length - 1));
        let fa =_Mul(_perc, arr.length - 1);
        e.perc = _Sub(100, fa);
      }
    });
    setDataSource(arr);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const handleChange = (list) => {
    const array = [];
    const addedArr = [...dataSource];
    list?.forEach((x) => x == array?.push(x));
    const result = costSource.filter((c) =>
      array.some((s) => s === c.costCenterId)
    );
    for (let i = 0; i < result.length; i++) {
      let index = addedArr.findIndex(
        (object) => object.costCenterId === result[i].costCenterId
      );
      if (index === -1) {
        result[i].value = null;
        result[i].perc = null;
        addedArr.push(result[i]);
      }
    }
    const finalArr = addedArr.filter((c) =>
      array.some((s) => s === c.costCenterId)
    );
    setDataSource(finalArr);
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      setCostSource([]);
      setDataSource([]);
      setSelectedRows();
      setLoadData(false);
    });
  };

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem("showCostModal"))) {
        switch (shortCut(e)) {
          // case "insert":
          // popupForm.submit();
          //   break;
          case "search":
            refSelectCost?.current?.focus();
            break;
          case "distribute":
            autoDistribution();
            break;
          case "block1":
            setTimeout(function () {
              if (!!costGridRef.current)
                costGridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "block2":
            setTimeout(function () {
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "tag":
            if (!!costGridRef.current)
              costGridRef?.current?.selectCell({ idx: 0, rowIdx: null });
            if (!!gridRef.current)
              gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
            setAltPress(true);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, [amount, dataSource]);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  return (
    <Modal
      destroyOnClose
      centered
      bodyStyle={{ padding: "5px 15px" }}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strCostCenter")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        window.sessionStorage.setItem("showCostModal", false);
      }}
      // onOk={() => popupForm.submit()}
      afterClose={() => {
        handleClear();
      }}
      //  okText={_t("strInsert")}

      footer={[
        <Button
          key="distribution"
          type="dashed"
          onClick={() => autoDistribution()}
          disabled={dataSource?.length === 0}
          icon={_t("strDistribute")}
        >
          <div>
            <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + D
              </Tag>
            </div>
          </div>
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => popupForm.submit()}
          disabled={dataSource?.length === 0}
        >
          {/* <div> */}
          {_t("strInsert")}
          {/* <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + Enter
              </Tag>
            </div>
          </div> */}
        </Button>,
      ]}
    >
      <Form form={popupForm} forceRender onFinish={onFinish}>
        <Form.Item name="amountVal" label={_t("strAmount")} hidden={unValue}>
          <Input
            size="small"
            bordered={false}
            disabled
            style={{ fontWeight: "900" }}
          />
        </Form.Item>

        <div>
          <Form.Item name="search">
            <Search
              ref={refSelectCost}
              onSearch={searchData}
              placeholder={_t("strSearch")}
              size="small"
              style={{ width: 200, marginBottom: 10 }}
            />
          </Form.Item>
          <div
            style={{
              opacity: 0.8,
              zIndex: 99,
              position: "absolute",
              top: 92,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + F
            </Tag>
          </div>
        </div>

        <div>
          <DataGrid
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              handleChange(e);
            }}
            ref={costGridRef}
            style={{ height: 175, width: "100%" }}
            columns={costColumns}
            rowHeight={25}
            className="rdg-light"
            rowKeyGetter={(row) => row?.costCenterId}
            rows={costSource}
            onRowsChange={setCostSource}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
          />
          <div
            style={{
              opacity: 0.8,
              position: "absolute",
              top: 128,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + 1
            </Tag>
          </div>
        </div>

        <Divider size="small" style={{ width: "10px" }} />

        <div>
          <DataGrid
            ref={gridRef}
            style={{ height: 175, width: "100%" }}
            columns={
              unValue
                ? [...columns, ...colDelete]
                : [...columns, ...colValue, ...colDelete]
            }
            rowHeight={25}
            className="rdg-light"
            rows={dataSource.map((e, i) => (e = { ...e, key: i + 1 }))}
            onRowsChange={setDataSource}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
            summaryRows={summaryRows}
          />
          <div
            style={{
              opacity: 0.8,
              position: "absolute",
              top: 352,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + 2
            </Tag>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CostCenterSelector;
