import React, { useState, useRef } from "react";
import { Form, Badge, Modal, Button, message } from "antd";
import DataGrid from "react-data-grid";
import { getUnImportValidateExcel } from "../../../services/entryService";
import _t from "../../../languages/translate";
import Draggable from "react-draggable";
import { saveAs } from "file-saver";
import { handleEx } from "../../helper/handleException";
const EntUnImportDataExcel = ({
  showModal,
  setShowModal,
  showModalName,
  dataSource,
}) => {
  const [loadData, setLoadData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const gridRef = useRef(null);


  const columns = [
    {
      name: "#",
      key: "accCode",
      width: 40,
      minWidth: 40,
      maxWidth: 40,
    },
    {
      name: _t("strAccount"),
      key: "accName",
      width: 120,
    },
    {
      name: _t("strCredit"),
      key: "credit",
      width: 75,
    },
    {
      name: _t("strDebet"),
      key: "debet",
      width: 75,
    },
    {
      name: _t("strCostCenterCode"),
      key: "costCenterCode",
      width: 120,
    },
    {
      name: _t("strCostCenterName"),
      key: "costCenterName",
      width: 120,
    },
    {
      name: _t("strCurrency"),
      key: "currName",
      width: 75,
    },
    {
      name: _t("strRate"),
      key: "currRate",
      width: 100,
    },
    {
      name: _t("strDescription"),
      key: "note",
      width: 100,
    },
    {
      name: _t("strNote"),
      key: "note1",
      width: 150,
    },
    {
      name: _t("strStatus"),
      key: "approved",
      width: 75,
      formatter: (p) => {
        return p.row.approved ? (
          <Badge
            color="green"
            text={_t("strAcceptable")}
            style={{ fontSize: 10 }}
          />
        ) : (
          <Badge
            color="red"
            text={_t("strRejected")}
            style={{ fontSize: 10 }}
          />
        );
      },
    },
    {
      name: _t("strReasonRefuse"),
      key: "unApprovedNote",
      width: 250,
    },
  ];

  const exportValidationToExcel = async () => {
    setLoadData(true);
    try {
      const hide = message.loading("جاري تصدير البيانات ..", 0);
      const { data, headers } = await getUnImportValidateExcel(dataSource);
      const byteCharacters = atob(data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "السجلات المقبولةوالمرفوضة.xlsx");
      setTimeout(hide);
      setShowModal(false);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      setLoadData(false);
    }, 50);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <Modal
    centered
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strRejectedAcceptedData")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        window.sessionStorage.setItem(showModalName, false);
      }}
      afterClose={() => handleClear()}
      footer={[
        <Button key="submit" type="primary" onClick={exportValidationToExcel}>
          {_t("strExportToExcel")}
        </Button>,
      ]}
      width={1300}
    >
      <DataGrid
        ref={gridRef}
        style={{ height: `calc(100vh - 300px)`, width: "100%" }}
        columns={columns}
        rowHeight={25}
        className="rdg-light"
        rows={dataSource}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        direction={_t("langDiriction")}
      />
    </Modal>
  );
};

export default EntUnImportDataExcel;
