import React, { useEffect, useState, useContext } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { parse } from "psl";
import uploadImg from "../../services/uploadService";
import _t from "../../languages/translate";
import { LanguageContext } from "../../languages/Language";

function UploadImg({ previewImg, setPreviewImg, loading, setLoading }) {
  const { userLanguage } = useContext(LanguageContext);
  const [allowUpImage, setAllowUpImage] = useState(true);
  const [path, setPath] = useState(true);
  useEffect(() => {
    async function feachData() {
      await setLoading(true);
      await setPreviewImg(previewImg);
      let fullPath = await imagePathUrl(previewImg);
      await setPath(fullPath);
      await setLoading(false);
    }
    feachData();
  }, [previewImg]);

  const imagePathUrl = (pathImg) => {
    const url = window.location.host;
    const { subdomain } = parse(url);
    if (subdomain !== null && subdomain !== undefined) {
      let url =
        process.env.REACT_APP_BASE_URL + "/images/" + subdomain + "/" + pathImg;
      return url;
    } else {
      let url = process.env.REACT_APP_BASE_URL + "/images/" + pathImg;
      return url;
    }
  };

  const uploadButton = previewImg ? (
    <img src={path} alt="avatar" className="heroImageprv" />
  ) : (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">{_t("strUploadImage")}</div>
    </div>
  );

  function beforeUpload(file) {
    if (previewImg === undefined || previewImg === null) {
      setAllowUpImage(true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(_t("strOnlyPNG&JPG"));
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        message.error(_t("strMustSmaller1MB"));
      }
      return isJpgOrPng && isLt2M;
    } else {
      setAllowUpImage(false);
      return message.error(_t("msgPleaseDeleteOldImage"));
    }
  }

  const uploadImgs = async (fileObj) => {
    if (allowUpImage) {
      await setLoading(true);
      try {
        const res = await uploadImg(fileObj);
        debugger;
        await setPreviewImg(res.data.data);
        await setLoading(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoading(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoading(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoading(false);
        }
      }
    }
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className={
        userLanguage === "en" ? "ltr-avatar-uploader" : "rtl-avatar-uploader"
      }
      showUploadList={false}
      action={(file) => uploadImgs(file)}
      beforeUpload={beforeUpload}
    >
      {loading ? <LoadingOutlined /> : uploadButton}
    </Upload>
  );
}
export default UploadImg;
