
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/PriceList";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getPriceListById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editPriceList(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getInitiale() {
  return http.post(`${apiEndpoint}/Initiale`);
}
    
export function getAllPriceList() {
  return http.get(`${apiEndpoint}`);
}
export function deletePriceList(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerPriceList(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}


export function getPriceByUnit(itemUnit, date, priceMethod, rate) {
  return http.post(`${apiEndpoint}/GetPriceByUnit?itemUnit_MapId=${itemUnit}&date=${date}&priceMethod=${priceMethod}&rate=${rate}`);
}

export function getNewPriceListCode() {
  if(process.env.IS_DEBUG){

  }
  return http.post(`${apiEndpoint}/GetNewCode`);
}
