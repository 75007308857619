
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/Inv";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function initPage(Patt, Id) {
  return http.get(`${apiEndpoint}/?patt=${Patt}&id=${Id}`);
}

export function editInvoice(obj) {
  
   if (obj.id !== "" && obj.id !== undefined ) {
     return http.put(apiEndpoint, obj);
   } else {
    return http.post(apiEndpoint, obj);
  }
}


export function uploadExcel(obj,patt) {
  let formData = new FormData();
  formData.append("file", obj);
  return http.post(`${apiEndpoint}/ImportFromExcel/?patt=${patt}`, formData);
}

export function getUnImportValidateExcel(obj) {
  return http.post(apiEndpoint + "/GetValidateExcel", obj);
}


export function getInvHtml(invId, TimeShift, lang) {
  
  return http.post(`${apiEndpoint}/InvHtml?invId=${invId}&TimeShift=${TimeShift}&Lang=${lang}`);
}

export function savePostState(id, post) { 
  
  return http.post(`${apiEndpoint}/SetPost/?entId=${id}&post=${post}`);
}

export function saveDailyMove(obj) {
  
  return http.post(`${apiEndpoint}/SaveDailyMove`, obj);
}

export function deleteInvoice(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}


export function browseNext(Patt, Id) {
  
  return http.post(`${apiEndpoint}/GetNext/?patt=${Patt}&id=${Id === undefined? "":Id}`);
}

export function browsePrev(Patt, Id) {
  
  return http.post(`${apiEndpoint}/GetPrev/?patt=${Patt}&id=${Id === undefined? "":Id}`);
}



export function deleteDailyMoveRow(Id) {
  return http.delete(`${apiEndpoint}/DeleteDailyMoveRow/${Id}`);
}

export function getDailyMove(patt, acc, date) {

  let editTime = date?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  let convDate = editTime.toISOString(true).slice(0, 16) + 'Z';
  
  return http.get(`${apiEndpoint}/GetDailyMove/?patt=${patt}&acc=${acc}&date=${convDate}`);
}

export function getByPattCode(Patt, pattCode) {
  
  return http.post(`${apiEndpoint}/GetByPattCode/?patt=${Patt}&pattCode=${pattCode}`);
}

export function getByDoc(Patt, doc) {
  
  return http.post(`${apiEndpoint}/GetByDoc/?patt=${Patt}&doc=${doc}`);
}

export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}

export function getEntry() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getDetailsByUnits(obj, date, priceMethod, rate) {
  
  return http.post(`${apiEndpoint}/GetDetailsByUnit/?date=${date}&priceMethod=${priceMethod}&rate=${rate}`, obj);
}

export function getAddsDiscsByAcc(obj, date) {
  return http.post(`${apiEndpoint}/GetAddsDiscsByAcc/?date=${date}`, obj);
}


export function GetActiveChildren(obj) {
  return http.post(`${apiEndpoint}/GetActiveChildren`, obj);
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

export function getActiveParentsURL(search, addId) {
  const params = new URLSearchParams()
  search && params.append("search", search);
  //addId && params.append("addId", addId); 

  return `${apiEndpoint}/GetActiveChildrenTest/?${params.toString()}`;
}




export function getAllEntry() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewEntryCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerEntry(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}



export function deleteImage(Id, fileName) {

  return http.post(`${apiEndpoint}/DeleteImage?Id=${Id}&fileName=${fileName}`);
}

