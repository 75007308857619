import React, { useState } from "react";
import { SelectCellFormatter } from "react-data-grid";
import { AppstoreAddOutlined, DeleteTwoTone,DeleteOutlined } from "@ant-design/icons";
import AccountTreeSelector from "../../helper/Modal/AccountTreeSelector";
import _t from "../../../languages/translate";
import DataGrid, { SelectColumn } from "react-data-grid";
import { emptValue } from "../../helper/helperMethods";
import { Button, Divider, Tag, Popconfirm,Card, message } from "antd";

const ActiveGrid = ({ promptForm, dataSource = [], setDataSource }) => {
  const [showAccModal, setShowAccModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const colActive = [
    SelectColumn,
    {
      name: "#",
      key: "rowNum",
      minWidth: 30,
      width: 43,
      formatter({ row }) {
        return (
          <label
            className="keyNum"
            style={{
              width: "100%",
              fontSize: 12,
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {row?.rowNum}
          </label>
        );
      },
    },
    {
      name: _t("strType"),
      key: "isMain",
      width: 55,
      minWidth: 55,
      maxWidth: 55,
      formatter({ row }) {
        return (
          <label
            className="keyNum"
            style={{ width: "100%", fontSize: 10, fontWeight: 600 }}
          >
            {row?.isMain ? (
              <Tag color="magenta">{_t("strMain")}</Tag>
            ) : (
              <Tag color="cyan">{_t("strSub")}</Tag>
            )}
          </label>
        );
      },
    },
    {
      name: _t("strCode"),
      key: "accCode",
      width: 100,
    },
    {
      name: _t("strName"),
      key: "accName",
      width: 300,
    },

    {
      name: _t("strVisible"),
      key: "perView",
      width: 50,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.perView}
            onChange={() => {
              onRowChange({
                ...row,
                perView: !row.perView,
              });
              promptForm();
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
  ];
  const deleteColumn = [
    {
      key: "delete",
      name: _t("strDelete"),
      align: "center",
      dataIndex: "delete",
      resizable: false,
      width: 55,
      minWidth: 55,
      maxWidth: 55,
      formatter(props) {
        return (
          <Popconfirm
          title={_t("strSureToDelete")}
            onConfirm={() => {
              confirm(props?.row?.accId);
            }}
            okText={_t("strYes")}
            cancelText={_t("strNo")}
          >
            <DeleteTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: "16px" }}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const confirm = (id) => {
    setDataSource(dataSource.filter((object) => object.accId !== id));
  };

  const handleAccSelector = (e) => {
    for (let i = 0; i < e.length; i++) {
      e[i].perView = true;
    }
    setDataSource([...dataSource, ...e]);
    setShowAccModal(false);
  };

  const convertArr = () => {
    if (emptValue(selectedRows?.size, 0) !== 0) {
      let array = [];
      selectedRows?.forEach((x) => x !== array?.push(x));
      const result = dataSource.filter((c) =>
        array.some((s) => s === c.accId)
      );
      return result;
    }
  };

  const onDelete = () => {
    let dataSelected = convertArr();
    let result = dataSource.filter((e) => {
      if (!dataSelected.some((s) => s.accId === e.accId)) return e;
    });
    setSelectedRows();
    setDataSource(result);
  };

  return (
    <>
    <AccountTreeSelector
    currData={dataSource}
    onHandelData={handleAccSelector}
    showModal={showAccModal}
    showModalName="showAccModal"
    setShowModal={setShowAccModal}
  />
    <Card bodyStyle={{ padding: 1 }}>
      <Button
          type="link"
          onClick={() =>  setShowAccModal(true)}
          icon={<AppstoreAddOutlined  style={{ fontSize: 16 }} />}
        >
          {_t("strInsert")}
        </Button>
        <Divider type="vertical" />
      <Popconfirm
        title={_t("strSureToDelete")}
        placement="bottom"
        onConfirm={onDelete}
        okText={_t("strYes")}
        cancelText={_t("strNo")}
        disabled={emptValue(selectedRows?.size, 0) === 0}
      >
        <Button
          danger
          type="link"
          disabled={emptValue(selectedRows?.size, 0) === 0}
          icon={<DeleteOutlined  style={{ fontSize: 16 }} />}
        >
          {_t("strDelete")}
        </Button>
      </Popconfirm>
      <DataGrid
        selectedRows={selectedRows}
        onSelectedRowsChange={(e) => setSelectedRows(e)}
        style={{ height: "calc(100vh - 370px)", width: "100%" }}
        columns={[...colActive, ...deleteColumn]}
        rowKeyGetter={(row) => row?.accId}
        rows={dataSource.map((e, i) => (e = { ...e, rowNum: i + 1 }))}
        rowHeight={25}
        className="rdg-light"
        onRowsChange={setDataSource}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        direction={_t("langDiriction")}
      />
    </Card>
    </>
  );
};
export default ActiveGrid;
