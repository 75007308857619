import React from "react";
import { Form, Input, Button, notification, message } from "antd";
import { useHistory } from "react-router-dom";
import _t from "../../../languages/translate";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../../../services/Authorization/authService";
import Image from "../../../images/svg/bg2.svg";
import Avatar from "../../../images/svg/Logo/YBLogoTxt.svg";
import { useState } from "react";
import { handleEx } from "../../helper/handleException";
const PageLogin = () => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
  };
  const [loadings, setLoadings] = useState(false);
  let history = useHistory();

  const onFinish = async (values) => {
    await setLoadings(true);
    try {
      await login(values.username, values.password);
      window.localStorage.setItem("user", values.username);
      const { state } = history.location;
      window.history.replaceState(null, null, `${process.env.PUBLIC_URL}/`);
      window.location = state
        ? state.from.pathname
        : `${process.env.PUBLIC_URL}/`;
    } catch (error) {
      handleEx(error);
    } finally {
      await setLoadings(false);
    }
  };

  return (
    <div className="log-container">
      <div className="log-img">
        <img src={Image} style={{ width: "600px" }} />
      </div>
      <div className="log-content">
        <Form
          {...layout}
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          className="log-form"
        >
          <img src={Avatar} style={{ width: "230px" }} />
          {/* <h2 style={{ color: "#575A89" }}>Welcome</h2> */}

          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              style={{ borderRadius: "20px", marginBottom: "15px" }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              style={{ borderRadius: "20px" }}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="log-btn"
            loading={loadings}
          >
            Log in
          </Button>

          {process.env.REACT_APP_DEMO === "true" ? (
            <span style={{ color: "red" }}>
              {_t("strDemo")} | {process.env.REACT_APP_VIRSION}
            </span>
          ) : (
            <span style={{ color: "gray" }}>
              {process.env.REACT_APP_COMPANY_NAME} |{" "}
              {process.env.REACT_APP_VIRSION}
            </span>
          )}
        </Form>
      </div>
    </div>
  );
};

export default PageLogin;
