import React, { useState, useEffect, useMemo, useContext } from "react";
import { Input, Tooltip, message, Modal, Dropdown, Popconfirm } from "antd";
import AccountSelector from "../../helper/Modal/AccountSelector";
import CostCenterSelector from "../../helper/Modal/costCenterModal/CostCenterSelector";
import {
  getDetailsByAcc,
  deleteDailyMoveRow,
  saveDailyMoveRow,
  getEntHtml,
} from "../../../services/entryService";
import { ExclamationCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { calcAndReturnList } from "../../helper/Modal/costCenterModal/costCenterHelper";
import { handleEx } from "../../helper/handleException";
import {
  getActAccPopupSearch,
  GetActiveChildren,
} from "../../../services/AccountService";
import {
  getRate,
  changeRate,
} from "../../../services/Items/currenciesRateService";
import { getSearchPopup } from "../../../services/CostCenterService";
import { printPanel } from "../../helper/Print/printPanel";
import SelectCurr from "./entFunctions/selectCurr";
import CurrEditor from "../../helper/dataGrid/source/CurrEditor";

import DataGrid from "react-data-grid";
import { getNumFormat } from "../../helper/countOfZero";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import { emptValue, emptEntValue, emptString, emptArr } from "../../helper/helperMethods";
import {
  handleDebet,
  handleCredit,
  // handleEquivlent,
  handelCurrRate,
  handleCurrCredit,
  handleCurrDebet,
  handleClearCostCenter,
  handleClearMainCostCenter,
  handleNote,
  handleNote1,
  handleDoc,
} from "./entFunctions/handleColumns/handleColumns";

import {
  sumCredit,
  sumDebet,
  sumCurrCredit,
  sumCurrDebet,
  sumDiffDebet,
  sumDiffCredit,
  sumDiffCurrDebet,
  sumDiffCurrCredit,
} from "./entFunctions/handleColumns/sumColumns";

import { LanguageContext } from "../../../languages/Language";
import { _Div, _Mul } from "../../helper/handleMath";
const EntryGrid = ({
  innerRef,
  form,
  dataSource,
  setDataSource,
  pattrenSet,
  showConfirmNew,
  setLoadData,
  pattSettId,
  entType = 0,
  promptForm,
  accountValidationOpt,
  handelFinalTotal,
}) => {
  let mainObj;

  const [columns, setColumns] = useState([]);
  const [showAccModal, setShowAccModal] = useState(false);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const [searchAccValue, setSearchAccValue] = useState("");
  const { userLanguage } = useContext(LanguageContext);

  const [showCostModal, setShowCostModal] = useState(false);
  const [searchCostValue, setSearchCostValue] = useState();
  const [amountCostValue, setAmountCostValue] = useState();
  const [costCenterValue, setCostCenterValue] = useState([]);
  const [validationOptValue, setValidationOptValue] = useState(0);

  const [showMainCostModal, setShowMainCostModal] = useState(false);
  const [searchMainCostValue, setSearchMainCostValue] = useState();
  const [mainCostCenterValue, setMainCostCenterValue] = useState([]);
  const [mainValidationOptValue, setMainValidationOptValue] = useState(0);
  const [isWating, setIsWating] = useState(false);
  const [pValue, setPValue] = useState();

  const { confirm } = Modal;

  const onPrint = async (ent, patt, rowId) => {
    try {
      setLoadData(true);
      const data = await getEntHtml(
        ent,
        patt,
        new Date().getTimezoneOffset(),
        userLanguage === "ar" ? 0 : 1,
        rowId,
        true
      );
      setLoadData(false);
      printPanel(data.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onClick = ({ key }) => {
    let sorce = JSON.parse(key);
    if (sorce.type === "print") {
      onPrint(sorce.obj?.entGrpId, sorce.obj?.sorucePattSettId, sorce.obj?.id);
    } else if (sorce.type === "delete") {
      if (pattrenSet.entType === 0) {
        if (
          sorce.obj?.entGrpId !== "00000000-0000-0000-0000-000000000000" &&
          sorce.obj?.entGrpId !== null &&
          sorce.obj?.entGrpId !== undefined
        ) {
          conDelete(sorce.obj);
        } else {
          conDelete(sorce.obj);
        }
      } else {
        handleDelete(sorce.obj?.key);
      }
    }
  };

  const keysCol = [
    {
      name: "#",
      key: "key",
      minWidth: 30,
      width: 43,
      formatter: (p) => {
        let items = [];
        if (
          p?.row?.reportTempMenu?.length > 0 ||
          (p?.row?.sorucePattSettId &&
            p?.row?.entGrpId &&
            p?.row?.id !== "00000000-0000-0000-0000-000000000000") ||
          (p?.row?.rowType !== "read" && p?.row?.accId)
        ) {
          for (let i = 0; i < p?.row?.reportTempMenu?.length; i++) {
            if (p.row?.reportTempMenu[i].type === 0)
              items.push({
                label: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={p.row?.reportTempMenu[i].url}
                  >
                    {p.row?.reportTempMenu[i].name}
                  </a>
                ),
                key: i,
              });
            else if (
              p.row?.reportTempMenu[i].type === 2 &&
              p.row?.reportTempMenu[i].key === "printSource" &&
              p.row?.sorucePattSettId &&
              p.row?.entGrpId &&
              p.row?.id !== "00000000-0000-0000-0000-000000000000"
            )
              items.push({
                label: _t("strPrint"),
                key: JSON.stringify({ type: "print", obj: p.row }),
              });
            else if (
              p.row?.reportTempMenu[i].type === 2 &&
              p.row?.reportTempMenu[i].key === "deleteRow" &&
              p.row?.rowType !== "read" &&
              p.row?.accId &&
              entType === 0
            ) {
              items.push({ type: "divider" });
              items.push({
                danger: true,
                label: _t("strDelete"),
                key: JSON.stringify({ type: "delete", obj: p.row }),
              });
            }
          }

          if (
            p.row?.rowType !== "read" &&
            p.row?.accId &&
            (entType !== 0 ||
              p.row?.id === "00000000-0000-0000-0000-000000000000" ||
              p.row?.id === undefined)
          )
            items.push({
              danger: true,
              label: _t("strDelete"),
              key: JSON.stringify({ type: "delete", obj: p.row }),
            });
        }
        return (
          <Dropdown
            className="keyNum"
            menu={{ items, onClick }}
            style={{ width: "100%" }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()} style={{ width: "100%" }}>
              {p?.row?.key + 1}
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const handelIsLoad = () => {
    window.sessionStorage.setItem("isEntLoad", false);
    setLoadData(true);
  };

  const handelUnLoad = () => {
    window.sessionStorage.setItem("isEntLoad", true);
    setLoadData(false);
  };

  const allColumns = [
    {
      key: "debet",
      name: "",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onFocus={() => {
              onRowClick(p.row);
            }}
            positiveNum={true}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              handelIsLoad();
              
              //  if (emptEntValue(e.target.value, undefined) !== undefined) {
                mainObj = await handleDebet(p, e, mainObj);
                await handleSaveRowChange(p);
              //  }
              handelUnLoad();
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.debet} className="numCell">
            {getNumFormat(p?.row?.debet, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalDebet} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalDebet, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffDebet} className="numCell">
            <strong style={{ color: "red" }}>
              {pattrenSet?.entType === 0
                ? getNumFormat(row.totalDiffDebet, 1)
                    ?.toString()
                    ?.replaceAll("-", "")
                : getNumFormat(row.totalDiffDebet, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "credit",
      name: "",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onFocus={() => {
              onRowClick(p.row);
            }}
            positiveNum={true}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              handelIsLoad();
              
              // if (emptEntValue(e.target.value, undefined) !== undefined) {
                mainObj = await handleCredit(p, e, mainObj);
                await handleSaveRowChange(p);
              // }
              handelUnLoad();
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.credit} className="numCell">
            {getNumFormat(p?.row?.credit, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCredit} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCredit, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCredit} className="numCell">
            <strong style={{ color: "green" }}>
              {pattrenSet?.entType === 0
                ? getNumFormat(row.totalDiffCredit, 1)
                    ?.toString()
                    ?.replaceAll("-", "")
                : getNumFormat(row.totalDiffCredit, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "accName",
      name: "",
      width: 300,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            onFocus={() => {
              onRowClick(p.row);
            }}
            bordered={false}
            className="TextEditor"
            //defaultValue={p.row?.accName}
            onPressEnter={async (e) => {
              if (isWating) {
                return;
              }
              setIsWating(true);
              handelIsLoad();
              await accSearch(e.target.value, p.row.key);
              if (pattrenSet?.entType !== 0) {
                promptForm();
              }
              handelUnLoad();
              setIsWating(false);
            }}
          />
        );
      },
      formatter:(p)=>{
        const { status } = handleCostFormatter(p.row);
        const { status: mainStatus } = handleMainCostFormatter(p.row);
        return (
          <Tooltip
            color={(status?.state !== "done" || mainStatus?.state !=="done") ? "red" : "#2c2c2ce0"}
            
            title={
              status?.state === "required"
                ? _t("msgErrorAddCostCenter")
                : status?.state === "distribution"
                ? _t("msgErrorDistrCostCenter")
                :mainStatus?.state === "required" 
                ?_t("msgErrorAddMainCostCenter"):
                mainStatus?.state === "distribution" 
                ?_t("msgErrorDistrMainCostCenter"):
                p.row?.accName
            }
            placement="bottomRight"
          >
            <p
              style={{
                fontWeight: p.row?.rowType === "read" ? 700 : "",
                color: p.row?.rowType === "read" ? "#bababa": (status?.state !== "done" || mainStatus?.state !== "done") ? "red" : "#2c2c2ce0",
              }}
            >
              {p.row?.accName}
            </p>
          </Tooltip>
        );
    
    },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <strong style={{ color: "#7e7e7e" }}>{_t("strTotal")}</strong>
        ) : row.id === "total_1" ? (
          <strong style={{ color: "#7e7e7e" }}>
            {pattrenSet.entType === 0
              ? _t("strBalance")
              : _t("strTheDifference")}
          </strong>
        ) : (
          ""
        );
      },
    },
    {
      key: "costCentersName",
      name: "",
      width: 300,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            onFocus={() => {
              onRowClick(p.row);
            }}
            className="TextEditor"
            // defaultValue={p.row?.costCentersName}
            onPressEnter={(e) => {
              handelIsLoad();
              costCenterSearch(e.target.value, p);
              if (pattrenSet?.entType !== 0) {
                promptForm();
              }
              handelUnLoad();
            }}
            suffix={
              <CloseCircleFilled 
              className="clearIcon"
                onClick={async () => {
                  handelIsLoad();
                  mainObj = await handleClearCostCenter(p, mainObj);
                  await handleSaveRowChange(p);
                  handelUnLoad();
                }}
              />
            }
          />
        );
      },
      formatter: (p) => {
        const { status, arrHasVal } = handleCostFormatter(p.row);

        return (
          <Tooltip
            color={status.state !== "done" ? "red" : "#2c2c2ce0"}
            title={
              arrHasVal === false
                ? ""
                : status.state === "required"
                ? _t("msgErrorAddCostCenter")
                : status.state === "distribution"
                ? _t("msgErrorDistrCostCenter")
                : status?.text
            }
            placement="bottomRight"
          >
            <p
              style={{
                color:
                  p.row?.rowType === "read"
                    ? "#bababa"
                    : status.state !== "done"
                    ? "red"
                    : "#2c2c2ce0",
              }}
            >
              {status?.text}
            </p>
          </Tooltip>
        );
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "mainCostCentersName",
      name: "",
      width: 300,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            // onFocus={() => {
            //   onRowClick(p.row);
            // }}
            className="TextEditor"
            // defaultValue={p.row?.mainCostCentersName}
            onPressEnter={(e) => {
              handelIsLoad();
              mainCostCenterSearch(e.target.value, p);
              if (pattrenSet?.entType !== 0) {
                promptForm();
              }
              handelUnLoad();
            }}
            suffix={
              <CloseCircleFilled 
              className="clearIcon"
                onClick={async () => {
                  handelIsLoad();
                  mainObj = await handleClearMainCostCenter(p, mainObj);
                  await handleSaveRowChange(p);
                  handelUnLoad();
                }}
              />
            }
          />
        );
      },
      formatter: (p) => {
        const { status, arrHasVal } = handleMainCostFormatter(p.row);

        return (
          p.row.rowType !== "read" && (
            <Tooltip
              color={status.state !== "done" ? "red" : "#2c2c2ce0"}
              title={
                arrHasVal === false
                  ? ""
                  : status.state === "required"
                  ? _t("msgErrorAddCostCenter")
                  : status.state === "distribution"
                  ? _t("msgErrorDistrCostCenter")
                  : status?.text
              }
              placement="bottomRight"
            >
              <p
                style={{
                  color:
                    p.row?.rowType === "read"
                      ? "#bababa"
                      : status.state !== "done"
                      ? "red"
                      : "#2c2c2ce0",
                }}
              >
                <label
                  style={
                    !pattrenSet?.entCostCenterEnable
                      ? { fontWeight: "bold", color: "#b8b8b8" }
                      : {}
                  }
                >
                  {status?.text}
                </label>
              </p>
            </Tooltip>
          )
        );
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        pattrenSet?.entCostCenterEnable &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "currId",
      name: "",
      editor: (p) => (
        <SelectCurr
          value={p.row?.currId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            handelIsLoad();
            handleChangeCurr(p, e);
            if (pattrenSet?.entType !== 0) {
              promptForm();
            }
            handelUnLoad();
          }}
          options={JSON.parse(window.sessionStorage.getItem("currlist"))}
        />
      ),
      formatter(props) {
        let currId = props?.row?.currId;
        let result = currId
          ? JSON.parse(window.sessionStorage.getItem("currlist"))?.find(
              (c) => c?.currId === currId
            )?.currName
          : null;

        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    // {
    //   key: "currEquivlent",
    //   name: "",
    //   editor: (p) => {
    //     return (
    //       <CurrEditor
    //         row={p.row}
    //         column={p.column}
    //         onRowChange={p.onRowChange}
    //         onBlur={(e) => {
    //           if (emptValue(e.target.value, 0) !== 0 && emptValue(e.target.value, 0) !== emptValue(p.row.currEquivlent, 0)) {
    //             mainObj = handleEquivlent(p, e, mainObj);
    //             handleSaveRowChange(p, true);
    //           }
    //         }}
    //         onClose={p.onClose}
    //       />
    //     );
    //   },
    //   formatter: (p) => {
    //     return (
    //       <Tooltip title={p?.row?.currEquivlent}>
    //         {getNumFormat(p?.row?.currEquivlent, 3)}
    //       </Tooltip>
    //     );
    //   },
    //   editable: (row) =>
    //     row?.currId !== pattrenSet?.companyCurrId &&
    //     pattrenSet?.currChType !== 3 &&
    //     row?.rowType !== "read",
    // },
    {
      key: "currRate",
      name: "",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onFocus={() => {
              onRowClick(p.row);
            }}
            positiveNum={true}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              handelIsLoad();
              if (
                emptValue(e.target.value, 0) !== 0 &&
                emptValue(e.target.value, 0) !== emptValue(p.row.currRate, 0)
              ) {
                mainObj = handelCurrRate(p, e, mainObj);
                handleSaveRowChange(p, true);
              }
              handelUnLoad();
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.currRate} className="numCell">
            {getNumFormat(p?.row?.currRate, 3)}
          </Tooltip>
        );
      },
      editable: (row) =>
        row?.currId !== pattrenSet?.companyCurrId &&
        pattrenSet?.currChType !== 3 &&
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "currCredit",
      name: "",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            positiveNum={true}
            onRowChange={p.onRowChange}
            onFocus={() => {
              window.sessionStorage.setItem("rowDebet", p.row?.currDebet);
              onRowClick(p.row);
            }}
            onBlur={(e) => {
              handelIsLoad();
              // if (emptEntValue(e.target.value, undefined) !== undefined && emptEntValue(e.target.value, undefined) !== emptEntValue(p?.row?.currCredit, undefined)) {
              if (emptEntValue(e.target.value, undefined) !== emptEntValue(p?.row?.currCredit, undefined)) {
                let state = emptValue(p.row.credit, 0) !== 0;
                mainObj = handleCurrCredit(p, e, mainObj);
                let rowDebet = emptValue(window.sessionStorage.getItem("rowDebet"), 0);
                handleSaveRowChange(
                  p,
                  emptValue(e.target.value, 0) !== rowDebet && state
                ); // false);
              }
              handelUnLoad();
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.currCredit} className="numCell">
            {getNumFormat(p?.row?.currCredit, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCurrCredit} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCurrCredit, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCurrCredit} className="numCell">
            <strong style={{ color: "green" }}>
              {pattrenSet?.entType === 0
                ? getNumFormat(row.totalDiffCurrCredit, 1)
                    ?.toString()
                    ?.replaceAll("-", "")
                : getNumFormat(row.totalDiffCurrCredit, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
      editable: (row) =>
        row?.currId !== pattrenSet?.companyCurrId &&
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "currDebet",
      name: "",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            positiveNum={true}
            onRowChange={p.onRowChange}
            onFocus={() => {
              window.sessionStorage.setItem("rowCredit", p.row?.currCredit);
              onRowClick(p.row);
            }}
            onBlur={(e) => {
              handelIsLoad();
              // if (emptEntValue(e.target.value, undefined) !== undefined && emptEntValue(e.target.value, undefined) !== emptEntValue(p?.row?.currDebet, undefined) ) {
              if ( emptEntValue(e.target.value, undefined) !== emptEntValue(p?.row?.currDebet, undefined) ) {

                let state = emptValue(p.row.debet, 0) !== 0;

                mainObj = handleCurrDebet(p, e, mainObj);
                let rowCredit = emptValue(
                  window.sessionStorage.getItem("rowCredit"),
                  0
                );

                handleSaveRowChange( p, emptValue(e.target.value, 0) !== rowCredit && state); 
              }
              handelUnLoad();
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.currDebet} className="numCell">
            {getNumFormat(p?.row?.currDebet, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return row.id === "total_0" ? (
          <Tooltip title={row.totalCurrDebet} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCurrDebet, 1)}
            </strong>
          </Tooltip>
        ) : row.id === "total_1" ? (
          <Tooltip title={row.totalDiffCurrDebet} className="numCell">
            <strong style={{ color: "red" }}>
              {pattrenSet?.entType === 0
                ? getNumFormat(row.totalDiffCurrDebet, 1)
                    ?.toString()
                    ?.replaceAll("-", "")
                : getNumFormat(row.totalDiffCurrDebet, 1)}
            </strong>
          </Tooltip>
        ) : (
          ""
        );
      },
      editable: (row) =>
        row?.currId !== pattrenSet?.companyCurrId &&
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "doc",
      name: "",
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            defaultValue={p.row?.doc}
            onFocus={(event) => {
              event.target.select();
              onRowClick(p.row);
            }}
            onPressEnter={(e) => {
              handelIsLoad();
              mainObj = handleDoc(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
            onBlur={(e) => {
              handelIsLoad();
              mainObj = handleDoc(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
          />
        );
      },
      formatter: (p) => {
        return <label>{p?.row.doc}</label>;
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "note",
      name: "",
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            defaultValue={p.row?.note}
            onFocus={(event) => {
              event.target.select();
              onRowClick(p.row);
            }}
            onPressEnter={(e) => {
              handelIsLoad();
              mainObj = handleNote(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
            onBlur={(e) => {
              handelIsLoad();
              mainObj = handleNote(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
          />
        );
      },
      formatter: (p) => {
        return <label>{p?.row.note}</label>;
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
    {
      key: "note1",
      name: "",
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            defaultValue={p.row?.note1}
            onFocus={(event) => {
              event.target.select();
              onRowClick(p.row);
            }}
            onPressEnter={(e) => {
              handelIsLoad();
              mainObj = handleNote1(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
            onBlur={(e) => {
              handelIsLoad();
              mainObj = handleNote1(p, e, mainObj);
              handleSaveRowChange(p, false);
              handelUnLoad();
            }}
          />
        );
      },
      formatter: (p) => {
        return <label>{p?.row.note1}</label>;
      },
      editable: (row) =>
        row?.rowType !== "read" &&
        JSON.parse(window.sessionStorage.getItem("isEntLoad")),
    },
  ];

  const handleCostFormatter = (row) => {
    const status = calcAndReturnList(
      emptValue(row?.debet, null)
        ? emptValue(row?.debet, null)
        : emptValue(row?.credit, null),
      row?.costCenters,
      row?.costCenterReqOpt
    );
    row.costState = status?.state;
    const arrHasVal = emptArr(row?.costCenters, false);
    status.text =
      arrHasVal === false ? typeCostReq(row?.costCenterReqOpt) : status?.text;
    return { status: status, arrHasVal: arrHasVal };
  };

  const typeCostReq = (type) => {
    if (type === 0 || type === undefined) {
      return;
    } else if (type === 1) {
      return <p style={{ color: "red" }}>{_t("strInsertValueIsRequired")}</p>;
    } else if (type === 2) {
      return (
        <p style={{ color: "red" }}>
          {_t("strFullValueDistributionIsRequired")}
        </p>
      );
    }
  };

  const mainReq = (row) => {
    // let xx =  emptString(row?.accId, true) !== true &&
    // emptString(row?.id, true) === true
    // ? accountValidationOpt === 0 ? pattrenSet?.entCostCenterReq === true  ? 1: accountValidationOpt : accountValidationOpt
    // : 0;

    //
    return accountValidationOpt;
  };

  const handleMainCostFormatter = (row) => {
    const status = calcAndReturnList(
      emptValue(row?.debet, null)
        ? emptValue(row?.debet, null)
        : emptValue(row?.credit, null),
      row?.mainAccCostCenters,
      mainReq(row)
    );

    row.costState = status?.state;
    const arrHasVal = emptArr(row?.mainAccCostCenters, false);
    status.text =
      arrHasVal === false ? typeMainCostReq(mainReq(row)) : status?.text;
    return { status: status, arrHasVal: arrHasVal };
  };

  const typeMainCostReq = (type) => {
    if (type === 0 || type === undefined) {
      return;
    } else if (type === 1) {
      return <p style={{ color: "red" }}>{_t("strInsertValueIsRequired")}</p>;
    } else if (type === 2) {
      return (
        <p style={{ color: "red" }}>
          {_t("strFullValueDistributionIsRequired")}
        </p>
      );
    }
  };

  const handleGetCurr = async () => {
    try {
      const { data: result } = await getCurrActiveSearch("");
      window.sessionStorage.setItem("currlist", JSON.stringify(result.data));
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    handleGetCurr();
  }, []);

  useEffect(() => {
    if (!pattrenSet?.detailsColSett) return;
    const filteredColumns = pattrenSet?.detailsColSett.map((e) => {
      const col = allColumns.find((i) => i.key === e.key);
      return col ? { ...col, name: e.name, width: e.width } : e;
    });

    setColumns(filteredColumns || []);
  }, [pattrenSet, setColumns, dataSource]);

  const handleSaveRowChange = async (p, cellType) => {
    //let test =  emptValue(p.row[cellProp],0);

    setRowChanges(p, mainObj);

    if (pattrenSet.entType === 0) {
      await handleSaveDailyMoveRow(mainObj);
    } else {
      promptForm();
    }
    if (cellType) {
      if (pattrenSet?.currChType === 0) {
        if (emptString(mainObj.currId, null) !== null) {
          changeCurr(mainObj.currId, mainObj.currRate);
        }
      } else if (pattrenSet?.currChType === 2) {
        if (emptString(mainObj?.currId, null) !== null) {
          showConfirmNew("changeCurr", mainObj.currId, mainObj.currRate);
        }
      }
    }
  };

  const handleChangeCurr = async (p, e) => {
    try {
      const data = await getRate(
        e,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      let val = data?.data?.data;
      mainObj = p?.row;
      mainObj.currId = e;
      mainObj.currRate = val;
      mainObj.currCredit = emptValue(_Mul(mainObj.credit, val), null);
      mainObj.currDebet = emptValue(_Mul(mainObj.debet, val), null);
      //  mainObj.currEquivlent = emptValue(_Div(1, emptValue(parseFloat(p.row?.currRate), 0)),null);
      setRowChanges(p, mainObj);

      if (pattrenSet.entType === 0) {
        handleSaveDailyMoveRow(mainObj);
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const calcDebet = (obj) => {
    obj.currDebet = emptValue(_Mul(obj.debet, obj.currRate), null);
    obj.credit = null;
    obj.currCredit = null;
  };

  const calcCredit = (obj) => {
    obj.currCredit = emptValue(_Mul(obj.credit, obj.currRate), null);
    obj.debet = null;
    obj.currDebet = null;
  };

  const setRowChanges = (p, obj) => {
    p?.onRowChange(
      {
        ...p.row,
        debet: emptValue(obj?.debet, null),
        currDebet: emptValue(obj?.currDebet, null),
        credit: emptValue(obj?.credit, null),
        currCredit: emptValue(obj?.currCredit, null),
        currRate: emptValue(obj?.currRate, null),
        //  currEquivlent: emptValue(obj?.currEquivlent, null),

        costCentersName: emptValue(obj?.costCentersName, null),
        costCenters: emptArr(obj?.costCenters, null),

        mainCostCentersName: emptValue(obj?.mainCostCentersName, null),
        mainAccCostCenters: emptArr(obj?.mainAccCostCenters, null),

        note: emptString(obj?.note, null),
        note1: emptString(obj?.note1, null),
        accId: emptString(obj?.accId, null),
        currId: emptString(obj?.currId, null),
      },
      true
    );

    let data = dataSource;
    data[p.row.key] = obj;
    setDataSource([...data]);
  };

  const changeCurr = async (currId, value) => {
    let obj = {
      currId: currId,
      date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      value: value,
    };

    const { data: result } = await changeRate(obj);
    message.success(result.message, 3);
  };

  const accSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getActAccPopupSearch(value);

      let filterNotMain = result?.data?.filter(
        (e) => e.accId !== form.getFieldValue("accId")
      );

      if (filterNotMain.length === 1) {
        const servData = await groupingIds(filterNotMain);
        await insertSingleRec(servData, key);
      } else if (filterNotMain.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchAccValue(value);
        setShowAccModal(true);
        window.sessionStorage.setItem("showAccModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const handleAccSelector = async (result) => {
    const servData = await groupingIds(result);
    servData.forEach((e) => {
      let mainList = form.getFieldValue("entGrpAccCostCenter_Maps");
      e.mainAccCostCenters = mainList?.map((x) => {
        return { ...x };
      });
    });
    appendAccSelector(servData);
  };

  const insertSingleRec = async (value, row) => {
    const data = dataSource;
    if (data[row] === undefined) {
      data.push({ key: row });
    }
    data[row].accId = value[0].accId;
    data[row].accName = value[0].accName;
    data[row].costCenterReqOpt = value[0].costCenterReqOpt;
    data[row].mainAccCostCenters =
      data[row].mainAccCostCenters !== undefined
        ? data[row].mainAccCostCenters
        : form.getFieldValue("entGrpAccCostCenter_Maps");
    if (pattrenSet?.currApplyType === 1) {
      data[row].currId = value[0].currId;
      data[row].currRate = value[0].currRate;
    } else if (pattrenSet?.currApplyType === 0) {
      data[row].currId = form.getFieldValue().currId;
      data[row].currRate = form.getFieldValue().currRate;
    }

    if (emptValue(data[row].credit, null) !== null) {
      calcCredit(data[row]);
    }
    if (emptValue(data[row].debet, null) !== null) {
      calcDebet(data[row]);
    }

    if (pattrenSet.entType === 0) {
      await handleSaveDailyMoveRow(data[row]);
    }

    await sortDataKey(data, row);
  };

  const appendAccSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else if (result.length > 0) {
      let dataCollect;
      let tmpdata = dataSource.find((x) => x.key === rowNumberEdit);
      if (tmpdata?.accId !== undefined) {
        dataCollect = dataSource;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            memoData.debet = dataSource[rowNumberEdit + i].debet;
            memoData.credit = dataSource[rowNumberEdit + i].credit;
            memoData.id = dataSource[rowNumberEdit + i].id;
            memoData.entId = dataSource[rowNumberEdit + i].entId;
            memoData.note = dataSource[rowNumberEdit + i].note;
            memoData.costCenterReqOpt =
              dataSource[rowNumberEdit + i].costCenterReqOpt;
            memoData.mainAccCostCenters =
              dataSource[rowNumberEdit + i].mainAccCostCenters !== undefined
                ? dataSource[rowNumberEdit + i].mainAccCostCenters
                : form.getFieldValue("entGrpAccCostCenter_Maps");
            memoData.costCenters = dataSource[rowNumberEdit + i].costCenters;
            memoData.note1 = dataSource[rowNumberEdit + i].note1;
            memoData.currDebet = dataSource[rowNumberEdit + i].currDebet;
            memoData.currCredit = dataSource[rowNumberEdit + i].currCredit;
          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        memoData[0].debet = dataSource[rowNumberEdit]?.debet;
        memoData[0].credit = dataSource[rowNumberEdit]?.credit;
        memoData[0].costCenters = dataSource[rowNumberEdit]?.costCenters;
        memoData[0].mainAccCostCenters =
          dataSource[rowNumberEdit].mainAccCostCenters !== undefined
            ? dataSource[rowNumberEdit].mainAccCostCenters
            : form.getFieldValue("entGrpAccCostCenter_Maps");
        if (emptValue(memoData[0].credit, null) !== null) {
          calcCredit(memoData[0]);
        }
        if (emptValue(memoData[0].debet, null) !== null) {
          calcDebet(memoData[0]);
        }
        dataCollect = [...dataSource, ...memoData];
      }

      if (pattrenSet.entType === 0) {
        dataCollect.forEach((element) => {
          validateSaveDailyMoveRow(element);
        });
        if (emptString(dataCollect[rowNumberEdit].id, null) !== null) {
          handleSaveDailyMoveRow(dataCollect[rowNumberEdit], true);
        } else {
          handleSaveDailyMoveRow(dataCollect[rowNumberEdit + 1], true);
        }
      }

      sortDataKey([...dataCollect]);
    }
    setTimeout(() => {
      setShowAccModal(false);
      window.sessionStorage.setItem("showAccModal", false);
    }, 0);
  };

  const groupingIds = async (arr) => {
    let list = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.accId === undefined) continue;
      let id = "";
      id = arr[i]?.accId;
      list.push(id);
    }

    const { data: data } = await getDetailsByAcc(
      list,
      form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
    );

    if (pattrenSet?.currApplyType === 0) {
      let tmpCurrId = form.getFieldValue().currId;
      let tmpCurrRate = form.getFieldValue().currRate;
      for (let i = 0; i < data.data.length; i++) {
        data.data[i].currId = tmpCurrId;
        data.data[i].currRate = tmpCurrRate;
      }
      return data.data;
    }

    return data.data;
  };

  const calcRate = (obj) => {
    if (obj.debet !== undefined) {
      obj.currDebet = emptValue(_Mul(obj.debet, obj.currRate), undefined);
    }
    if (obj.credit !== undefined) {
      obj.currCredit = emptValue(_Mul(obj.credit, obj.currRate), undefined);
    }
    return obj;
  };

  const sortDataKey = async (data) => {
    let list = [];
    let KeyNum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].accId !== undefined) {
        let obj = {};
        obj = data[i];
        obj["key"] = KeyNum;
        let res = calcRate(obj);
        list.push(res);
        KeyNum++;
      }
    }
    await setRow([...list]);
  };

  //--------------------- Cost Center

  const costCenterSearch = async (e, p) => {
    try {
      const { data: result } = await getSearchPopup(e);

      if (result.data.length > 0) {
        if (
          result.data.length === 1 &&
          emptArr(p.row?.costCenters, null) === null
        ) {
          let object = p.row;
          object.costCenterName = result.data[0].costCenterName;
          object.costCenters = [
            {
              costCenterId: result.data[0].costCenterId,
              costCenterName: result.data[0].costCenterName,
              perc: 100,
              value: emptValue(p.row?.debet, null)
                ? emptValue(p.row?.debet, null)
                : emptValue(p.row?.credit, null),
            },
          ];
          setRowChanges(p, object);

          if (pattrenSet.entType === 0) {
            handleSaveDailyMoveRow(object);
          }
        } else {
          setCostCenterValue(p.row?.costCenters);
          setValidationOptValue(p.row?.costCenterReqOpt);
          setSearchCostValue(e);
          setAmountCostValue(
            emptValue(p.row?.debet, null)
              ? emptValue(p.row?.debet, null)
              : emptValue(p.row?.credit, null)
          );
          setPValue(p);
          window.sessionStorage.setItem("showCostModal", true);
          setShowCostModal(true);
        }
      } else {
        message.info(_t("msgNotFoundSearchResult"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleCostCenterData = (obj) => {
    const object = pValue.row;
    object.costCentersName = obj.text;
    object.costCenters = obj.list;
    object.costState = obj.state;
    setRowChanges(pValue, object);

    if (pattrenSet.entType === 0) {
      handleSaveDailyMoveRow(object);
    }
    setShowCostModal(false);
    setValidationOptValue(0);
    setCostCenterValue([]);
    setAmountCostValue();
    setSearchCostValue("");
    window.sessionStorage.setItem("showCostModal", false);
  };

  const checkCostCenterSaveDailyMove = (obj) => {
    let status = calcAndReturnList(
      emptValue(obj?.debet, null)
        ? emptValue(obj?.debet, null)
        : emptValue(obj?.credit, null),
      obj?.costCenters,
      obj?.costCenterReqOpt
    );
    if (status.state === "done") return true;
    else return false;
  };

  const fillCostCenterText = (obj) => {
    const { status } = handleCostFormatter(obj);
    obj.costCentersName = status?.text;
    return obj;
  };

  //--------------------- Main Cost Center

  const mainCostCenterSearch = async (e, p) => {
    try {
      const { data: result } = await getSearchPopup(e);

      if (result.data.length > 0) {
        if (
          result.data.length === 1 &&
          emptArr(p.row?.mainAccCostCenters, null) === null
        ) {
          let object = p.row;

          object.mainCostCentersName = result.data[0].costCenterName;
          object.mainAccCostCenters = [
            {
              costCenterId: result.data[0].costCenterId,
              costCenterName: result.data[0].costCenterName,
              perc: 100,
              value: emptValue(p.row?.debet, null)
                ? emptValue(p.row?.debet, null)
                : emptValue(p.row?.credit, null),
            },
          ];

          setRowChanges(p, object);

          if (pattrenSet.entType === 0) {
            handleSaveDailyMoveRow(object);
          }
        } else {
          setMainCostCenterValue(p.row?.mainAccCostCenters);
          setMainValidationOptValue(accountValidationOpt);
          setSearchMainCostValue(e);
          setAmountCostValue(
            emptValue(p.row?.debet, null)
              ? emptValue(p.row?.debet, null)
              : emptValue(p.row?.credit, null)
          );
          setPValue(p);
          window.sessionStorage.setItem("showCostModal", true);
          setShowMainCostModal(true);
        }
      } else {
        message.info(_t("msgNotFoundSearchResult"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleMainCostCenterData = (obj) => {
    const object = pValue.row;
    object.mainCostCentersName = obj.text;
    object.mainAccCostCenters = obj.list;
    object.mainCostState = obj.state;

    setRowChanges(pValue, object);

    if (pattrenSet.entType === 0) {
      handleSaveDailyMoveRow(object);
    }

    setShowMainCostModal(false);
    setMainValidationOptValue(0);
    setMainCostCenterValue([]);
    setAmountCostValue();
    setSearchMainCostValue("");
    window.sessionStorage.setItem("showCostModal", false);
  };

  const checkMainCostCenterSaveDailyMove = (obj) => {
    let status = calcAndReturnList(
      emptValue(obj?.debet, null)
        ? emptValue(obj?.debet, null)
        : emptValue(obj?.credit, null),
      obj?.mainAccCostCenters,
      mainReq(obj)
    );
    // obj?.costCenterReqOpt حسب الحساب الرئيسي
    if (status.state === "done") return true;
    else return false;
  };

  const fillMainCostCenterText = (obj) => {
    const { status } = handleMainCostFormatter(obj);
    obj.mainCostCentersName = status?.text;
    return obj;
  };

  const validateSaveDailyMoveRow = async (obj) => {
    const accId = form.getFieldValue("accId");
    if (obj?.rowType !== "read")
      if (
        !checkCostCenterSaveDailyMove(obj) ||
        !checkMainCostCenterSaveDailyMove(obj) ||
        !(
          (obj?.debet && obj?.currDebet) ||
          (obj?.credit &&
            obj?.currCredit &&
            obj?.accId &&
            obj?.currId &&
            obj?.currRate)
        ) ||
        !(
          emptString(accId, null) !== null &&
          emptString(obj?.accId, null) !== null
        ) ||
        !(obj?.accId !== accId)
      ) {
        obj.isNotSaved = true;
        if (!(obj?.accId !== accId)) {
          message.error(_t("msgRepeatedSingle"));
        }
        return false;
      }
    return true;
  };

  const handleSaveDailyMoveRow = async (obj, status) => {
    await setLoadData(true);
    window.sessionStorage.setItem("isEntLoad", false);
    //  editable = false;
    try {
      const arr = dataSource;
      const accId = form.getFieldValue("accId");
      const currId = form.getFieldValue("currId");
      const currRate = form.getFieldValue("currRate");
      const date =
        form.getFieldValue("date").toISOString(true).slice(0, 16) + "Z";
      if (await validateSaveDailyMoveRow(obj)) {
        {
          let object = fillCostCenterText(obj);
          object = fillMainCostCenterText(obj);
          const { data: res } = await saveDailyMoveRow(
            object,
            accId,
            currId,
            currRate,
            pattSettId,
            date,
            new Date().getTimezoneOffset()
          );
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].key === obj.key) {
              arr[i] = res.data;
            }
          }
        }
        if (!status) {
          checkSaveRow([...arr], accId);
        }
      }
      // }
    } catch (error) {
      handleEx(error);
    } finally {
      // editable = true
      window.sessionStorage.setItem("isEntLoad", true);
      await setLoadData(false);
    }
  };

  const checkSaveRow = (data, accId) => {
    const filterData = data;
    let list = [];
    let KeyNum = 0;
    for (let i = 0; i < filterData.length; i++) {
      if (
        filterData[i].accId !== undefined ||
        filterData[i].debet !== undefined ||
        filterData[i].credit !== undefined
      ) {
        let obj = {};
        obj = filterData[i];
        obj["key"] = KeyNum;
        let res = calcRate(obj);
        list.push(res);
        KeyNum++;
      }
    }
    list.push({ key: list.length });

    setDataSource([...list]);
  };

  const setRow = async (list) => {
    if (window.sessionStorage.getItem("entType") === "0") {
      list.push({ key: list.length });
    } else {
    }
    list.push({ key: list.length });

    await setDataSource([...list]);
  };

  const handleDelete = (key) => {
    const newUnit = dataSource.filter((e) => e.key !== key);
    let newNum = 0;
    for (let i = 0; i < newUnit.length; i++) {
      newUnit[i].key = newNum;
      newNum++;
    }

    setDataSource([...newUnit]);
    if (dataSource.length <= 1) {
      //addEmptyRow([...newUnit]);
    }
  };

  const conDelete = (row) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        if (pattrenSet.entType === 0) {
          if (
            row?.entGrpId !== "00000000-0000-0000-0000-000000000000" &&
            row?.entGrpId !== null &&
            row?.entGrpId !== undefined
          ) {
            handleDeleteServRow(row);
          } else {
            handleDelete(row?.key);
          }
        } else {
          handleDelete(row?.key);
        }
      },
      onCancel() {},
    });
  };

  const handleDeleteServRow = async (row) => {
    try {
      const { data: newData } = await deleteDailyMoveRow(
        pattSettId,
        row?.entGrpId
      );
      handleDelete(row.key);
      message.success(newData.message);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const summaryRows = useMemo(() => {
    const summaryRow = [
      {
        id: "total_0",
        totalDebet: sumDebet(dataSource),
        totalCredit: sumCredit(dataSource),
        totalCurrCredit: sumCurrCredit(dataSource),
        totalCurrDebet: sumCurrDebet(dataSource),
      },
      {
        id: "total_1",
        totalDiffDebet:
          pattrenSet?.entType === 0
            ? sumDiffCredit(dataSource)
            : sumDiffDebet(dataSource),
        totalDiffCredit:
          pattrenSet?.entType === 0
            ? sumDiffDebet(dataSource)
            : sumDiffCredit(dataSource),
        totalDiffCurrCredit:
          pattrenSet?.entType === 0
            ? sumDiffCurrDebet(dataSource)
            : sumDiffCurrCredit(dataSource),
        totalDiffCurrDebet:
          pattrenSet?.entType === 0
            ? sumDiffCurrCredit(dataSource)
            : sumDiffCurrDebet(dataSource),
      },
    ];
    if (window.sessionStorage.getItem("entType") !== "0") {
      handelFinalTotal(
        emptValue(sumCurrCredit(dataSource), sumCurrDebet(dataSource))
      );
    }

    return summaryRow;
  }, [dataSource]);

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      const arr = costList.map((e) => {
        e.value = 0;
        return e;
      });
      const reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, arr, emptValue(reqOpt, 0))?.text;
    }
  };

  const onRowClick = async (event) => {
    //
    // if (window.sessionStorage.getItem("entType") === "0") {
    //   const accList = await handleAccAct();
    //   handelFinalTotal(emptValue(emptValue(event?.debet, event?.credit), 0));
    //   form.setFieldsValue({
    //     entGrpAccCostCenter_Maps: event?.mainAccCostCenters,
    //     entGrpAccCostCenter: loadCostCenterData(
    //       event?.mainAccCostCenters,
    //       event?.accId,
    //       accList
    //     ),
    //   });
    // }
  };

  return (
    <>
      <AccountSelector
        searchValue={searchAccValue}
        onHandelData={handleAccSelector}
        mainAccValue={form.getFieldValue("accId")}
        showModal={showAccModal}
        showModalName="showAccModal"
        setShowModal={setShowAccModal}
      />

      <CostCenterSelector
        searchValue={searchCostValue}
        costCenterValue={costCenterValue}
        onHandelData={handleCostCenterData}
        showModal={showCostModal}
        setShowModal={setShowCostModal}
        amount={amountCostValue}
        validationOpt={validationOptValue}
      />
      <CostCenterSelector
        searchValue={searchMainCostValue}
        costCenterValue={mainCostCenterValue}
        onHandelData={handleMainCostCenterData}
        showModal={showMainCostModal}
        setShowModal={setShowMainCostModal}
        amount={amountCostValue}
        validationOpt={mainValidationOptValue}
      />
      <DataGrid
        ref={innerRef}
        rows={dataSource.map((e, i) => (e = { ...e, key: i }))}
        onRowsChange={setDataSource}
        onRowClick={onRowClick}
        columns={[...keysCol, ...columns]}
        rowHeight={25}
        className="rdg-light"
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowClass={(row) => {
          if (pattrenSet?.entType === 0) {
            if (row?.isNotSaved === true) return "unSaveRow";
          }
        }}
        direction={_t("langDiriction")}
        style={{
          height: `calc(100vh - 300px)`,
          width: "100%",
        }}
        summaryRows={
          pattrenSet?.entType !== 1 && pattrenSet?.entType !== 2
            ? summaryRows
            : summaryRows.filter((e) => e.id === "total_0")
        }
      ></DataGrid>
    </>
  );
};
export default EntryGrid;
