import React, { useState } from "react";
import { Input, Tooltip } from "antd";

const formatNumber = (value) => new Intl.NumberFormat().format(value);

const NumInput = (props) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    var rgx = /^[0-9]*$/;

    const reg = /^-?\d*(\.\d*)?$/;

    if (props?.trueNum && inputValue !== ".") {
      if (rgx.test(inputValue)) {
        onChange(inputValue);
      }
    } else {
      if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
        onChange(inputValue);
      }
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value?.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp?.replace(/0*(\d+)/, "$1"));
  };

  const title = value ? (
    <span className="numeric-input-title">
      {value !== "-" ? formatNumber(Number(value)) : "-"}
    </span>
  ) : (
    props?.placeholder
  );

  return (
    <Tooltip
      trigger={["focus"]}
      title={title}
      placement="topLeft"
      overlayClassName="numeric-input"
    >
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props?.placeholder}
        maxLength={16}
        autoComplete="off" 
      />
    </Tooltip>
  );
};
const NumericInput = ({ ...rest }) => {
  const [value, setValue] = useState("");

  return (
    <NumInput
      style={{
        width: 120,
      }}
      value={value}
      onChange={setValue}
      {...rest}
    />
  );
};
export default NumericInput;
