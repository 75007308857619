const TextBox = ({
  row,
  column,
  onRowChange,
  onClose,
  onBlur
}) => {

  return (
    <input
      className="TextEditor"
      size="small"
      ref={(event) => {event?.focus()}}
      style={{ width: "100%" }}
      defaultValue={row[column.key]}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onPressEnter={onBlur && onBlur}
      bordered={false}
      controls={false}
      onBlur={(event) => { onClose(true); onBlur && onBlur(event.target.value)}}
    />
  );
};
export default TextBox;






