import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import CurrEditor from "../helper/dataGrid/source/CurrEditor";
import {
  Form,
  Input,
  Button,
  Switch,
  message,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
  Spin,
  DatePicker,
  Card,
  Modal,
  Tag
} from "antd";

import {
  getPriceListById,
  editPriceList,
  getInitiale,
} from "../../services/priceListService";
import { getSearchPopup } from "../../services/Items/itemService";
import {
  RetweetOutlined,
  DeleteTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import DropDownList from "../helper/dataGrid/source/DropDownList";
import EditableGrid from "../helper/dataGrid/DataGrid";
import ItemSelector from "../helper/Modal/ItemInvSelector";
import { dateFormatList } from "../helper/Input/dateFormatList";
import moment from "moment";
import { getNumFormat, objCountNum } from "../helper/countOfZero";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
const PriceListForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const IX_Date = useRef(null);
  const IX_Note =useRef(null);
  const priceListRef = useRef(null);

  const [form] = Form.useForm();
  const [loadData, setLoadData] = useState(true);
  const { confirm } = Modal;
  let history = useHistory();
  let { id } = useParams();
  const today = moment();
  //******************************//
  const [isGoBack, setIsGoBack] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const [searchItemValue, setSearchItemValue] = useState("");
  const [extraColumns, setExtraColumns] = useState([]);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { TextArea } = Input;
  const { Text } = Typography;
  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////
  const promptForm = () => {
    setProChange(true);
  };

  const columns = [
    {
      key: "key",
      name: "#",
      width: 20,
      resizable: false,
      formatter(props) {
        const key = props?.row?.key + 1;
        return key;
      },
    },
    {
      key: "itemName",
      name: _t("strItems"),
      width: 200,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            defaultValue={p.row?.itemName}
            onPressEnter={(e) => ItemSearch(e.target.value, p.row.key)}
          />
        );
      },
    },
    {
      key: "itemUnit_MapId",
      name: _t("strUnits"),
      width: 200,
      editor: (p) => (
        <DropDownList
          value={p.row?.itemUnit_MapId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            p?.onRowChange({ ...p?.row, itemUnit_MapId: e }, true);
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.itemUnit_MapId;
        return id ? props?.row?.units.find((c) => c?.id === id)?.name : null;
      },
    },
  ];

  const deleteColumn = [
    {
      key: "delete",
      name: _t("strDelete"),
      align: "center",
      dataIndex: "delete",
      resizable: false,
      width: 50,
      formatter(props) {
        return (
          <DeleteTwoTone
            twoToneColor="#eb2f96"
            style={{ fontSize: "16px" }}
            onClick={() => handleDelete(props.row.key)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      if (id === "new") {
        return initValue();
      } else {
        return getById(id);
      }
    }
    fetchData();
  }, []);

  const handleFillDynamicCol = (arr) => {
    const list = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = {};
      obj["key"] = arr[i].key;
      obj["name"] = arr[i].name;
      obj["width"] = 200;
      obj["editor"] = (p) => (
        <CurrEditor
          row={p.row}
          column={p.column}
          onRowChange={p.onRowChange}
          onBlur={(e) => {
            p?.onRowChange(
              {
                ...p?.row,
                [p.column.key]: e.target.value,
              },
              true
            );
          }}
          onClose={p.onClose}
        />
      );
      obj["formatter"] = (p) => {
        if (p?.column?.key !== undefined) {
          var value = p?.row[p.column.key];
        }
        return value !== undefined
          ? getNumFormat(value, objCountNum?.value)
          : null;
      };
      list.push(obj);
    }
    return list;
  };

  const initValue = async () => {
    try {
      const { data: newData } = await getInitiale();
      form.setFieldsValue({
        code: newData.data.code !== null ? newData.data.code : "",
      });
      setExtraColumns(handleFillDynamicCol(newData.data.detailColumns));
      addEmptyRow([{ key: 0 }]);
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Date });
    } finally {
      setLoadData(false);
    }
  };

  const getById = async () => {
    try {
      const { data } = await getPriceListById(id);
      form.setFieldsValue({
        code: data.data.code !== null ? data.data.code : "",
        name: data.data.name !== null ? data.data.name : "",
        date: data.data.date !== null ? moment(data.data.date) : "",
        note: data.data.note,
        inactive: data.data.inactive,
      });
      setExtraColumns(handleFillDynamicCol(data.data.detailColumns));
      addEmptyRow(data.data.details);
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Date });
    } finally {
      setLoadData(false);
    }
  };

  const ItemSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getSearchPopup(value);
      if (result.data.length === 1) {
        insertSingleRec(result.data, key);
      } else if (result.data.length < 1) {
        form.setFieldsValue({ details: dataSource });
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchItemValue(value);
        setShowModal(true);
        window.sessionStorage.setItem("showModal", true);
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Date });
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = (value, row) => {
    // const { details: data } = form.getFieldsValue(true);
    dataSource[row].itemId = value[0].itemId;
    dataSource[row].itemName = value[0].itemName;
    dataSource[row].itemUnit_MapId = value[0].itemUnit_MapId;
    dataSource[row].units = value[0].units;
    // form.setFieldsValue({ details: data });
    setDataSource(dataSource);
    sortDataKey(dataSource, row);
  };

  const handleItemSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      let dataCollect;
      let tmpdata = dataSource.find((x) => x.key === rowNumberEdit);
      if (tmpdata.itemId !== undefined) {
        dataCollect = dataSource;
        let i = 0;
        result.forEach((e) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          dataCollect.splice(rowNumberEdit + i, replaceItem, e);
          i++;
        });
      } else {
        dataCollect = [...dataSource, ...result];
      }
      dataCollect = dataCollect.filter(function (obj) {
        return obj.itemId !== undefined;
      });
      sortDataKey(dataCollect, null);
    }
    setTimeout(() => {
      setShowModal(false);
      window.sessionStorage.setItem("showModal", false);
    }, 0);
  };

  const sortDataKey = (data, rowNum) => {
    const list = data.map((obj, i) => ({ ...obj, key: i }));
    addEmptyRow(list);
  };

  const deleteEmptyRow = (arr) => {
    return arr.filter((obj) => Object.keys(obj).includes("itemId"));
  };

  const addEmptyRow = (arr) => {
    let newArr = deleteEmptyRow(arr);
    let newNum =
      newArr.reduce(
        (acc, shot) => (acc = acc > shot.key ? acc : shot.key),
        -1
      ) + 1;
    let output = [...newArr, { key: newNum }];
    setDataSource(output);
  };

  const handleDelete = (key) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        const delValue = dataSource.filter((item) => item.key !== key);
        sortDataKey(delValue, key);
      },
      onCancel() { },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  //******************************// //*******************// Save //*******************// //******************************//

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "block3":
              IX_Note?.current?.focus();
              break;
            case "block2":
              priceListRef?.current?.selectCell({ idx: 0, rowIdx: -1 })
              break;
            case "tag":
              priceListRef?.current?.selectCell({ idx: 0, rowIdx: null })
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);


  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    const oldDate = values.date;
    const output = deleteEmptyRow(dataSource);
    if (output.length !== 0) {
      values.details = output;
      if (values.date !== "") {
        values.date = values.date?.toISOString().slice(0, 16) + "Z";
      }
      try {
        const data = await editPriceList(values);
        setLoadData(false);
        message.success(data.data.message, 3);
        goBack();
      } catch (error) {
        setLoadData(false);
        handleEx(error, { IX_Code, IX_Name, IX_Date });
      } finally {
        values.date = oldDate;
      }
    } else {
      message.warning(_t("msgListReq"), 3);
    }
  };

  const goBack = () => {
    //
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({ ...history.location, pathname: "/priceList/new" });
      }
      form.resetFields();
      initValue();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//


  return (
    <div key="mainDiv">
      <ItemSelector
        searchValue={searchItemValue}
        onHandelData={handleItemSelector}
        showModal={showModal}
        setShowModal={setShowModal}
        showModalName="showModal"
      />

      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{ date: today }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strPriceList")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={<div>{_t("strName")}<div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 4,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + 1
                          </Tag>
                        </div></div>}
                        rules={[
                          {
                            required: true,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          size="small"
                          onChange={promptForm}
                          ref={IX_Name}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item name="date" label={_t("strDate")}>
                        <DatePicker
                          ref={IX_Date}
                          allowClear={false}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item label={<div>{_t("strDetails")}<div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + 2
                        </Tag>
                      </div>
                      </div>}>
                        <Card
                          bodyStyle={{ padding: 0 }}
                          style={{ marginBottom: 10 }}
                        >
                          <EditableGrid
                            innerRef={priceListRef}
                            style={{ height: `calc(100vh - 300px)`, width: "100%" }}
                            columns={[
                              ...columns,
                              ...extraColumns,
                              ...deleteColumn,
                            ]}
                            dataSource={dataSource}
                            setDataSource={setDataSource}
                          />

                        </Card>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={<div>{_t("strNote")}<div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 24,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + 3
                          </Tag>
                        </div>
                        </div>}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          ref={IX_Note}
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  {!auth?.perPut && (
                    <div>
                      <Button
                        type="primary"
                        onClick={() => form.submit()}
                        size="small"
                      >
                        {_t("strSave")}
                      </Button>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + S
                        </Tag>
                      </div>
                    </div>
                  )}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => { history.action === "PUSH" ? history.goBack() : history.push("/priceList"); }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default PriceListForm;
