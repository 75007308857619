    export function printPanel(data) {
    
       var printWindow = window.open('', '', 'height=600,width=800');
      // if(!printWindow || printWindow.closed || typeof printWindow.closed=='undefined') 
      // { 
      //          if (!("Notification" in window)) {
      //   // Check if the browser supports notifications
      //   alert("This browser does not support desktop notification");
      // } else if (Notification.permission === "granted") {
      //   // Check whether notification permissions have already been granted;
      //   // if so, create a notification
      //   const notification = new Notification("Hi there!");
      //   // …
      // } else if (Notification.permission !== "denied") {
      //   // We need to ask the user for permission
      //   Notification.requestPermission().then((permission) => {
      //     // If the user accepts, let's create a notification
      //     if (permission === "granted") {
      //       const notification = new Notification("Hi there!");
      //       // …
      //     }
      //   });
      // }
    
      // // At last, if the user has denied notifications, and you
      // // want to be respectful there is no need to bother them anymore.
      // }
   
    printWindow.document.write(data);
    printWindow.document.close();
    setTimeout(function () {
        printWindow.print();
         printWindow.close();
    }, 500);
    return false;
}



