import {message} from "antd";
import _t from "../../../../../languages/translate";

export const popInsertVal = ( mainArr, data ) => {
  let samePosation = mainArr?.some((e) =>e.id !== data.id && (e?.row === data.row && e?.col === data.col));
  if (samePosation) {
    message.error(_t("msgAlreadySimilarRowAndCol"));
  } else {
    return true;
  }
};

export const sortObjsArr =(arr)=>{
  return arr?.sort((a, b) => (a?.row - b?.row)||(a?.col - b?.col))   
  }
  