import { emptString } from "../../../helper/helperMethods";
import _t from "../../../../languages/translate";
import { _Mul, _RateMul, _SumArr, _Sum } from "../../../helper/handleMath";
import { message } from "antd";

export function munfSaveValidation(
  values,
  id,
  complexDisable,
  detail,
  rawDetail,
  munfDetail
) {
  values.munfPattSettId = id;

  if (complexDisable !== true) {
    const output = detail.filter((obj) =>
      Object.keys(obj).includes("munfModelId")
    );
    values.details = output;
    values.munfMunfMaterials = [];
    values.munfRawMaterials = [];
  } else {
    if (munfDetail !== null) {
      let totalDistribution = 0;
      let saveContinue = true;
      let output = munfDetail.filter((obj) =>
        Object.keys(obj).includes("itemId")
      );
      output.map((v, i) => {
        if (
          v.distributePerc === undefined ||
          v.distributePerc === 0 ||
          v.distributePerc === null
        ) {
          message.error(
            _t("msgDistributionReqOne") + (i + 1) + _t("msgDistributionReqTwo"),
            5
          );
          saveContinue = false;
        } else {
          totalDistribution = _Sum(totalDistribution,v.distributePerc)
        }
      });
      if (saveContinue === false) {
        return { state: false };
      }
      if (totalDistribution !== 100) {
        message.error(
          _t("msgDistributionRatio") +
            " " +
            totalDistribution +
            "%" +
            " " +
            _t("msgDistributionRatio2"),
          5
        );
        return { state: false };
      }

      values.munfMunfMaterials = output;
    }
    if (rawDetail !== null) {
      let output = rawDetail.filter((obj) =>
        Object.keys(obj).includes("itemId")
      );
      values.munfRawMaterials = output;
    }

    values.details = [
      {
        key: 0,
        count: 1,
        currRate: values.currRate,
        note: values.note,
        fromStoreId: values.fromStoreId,
        toStoreId: values.toStoreId,
        currId: values.currId,
        priceMethodId: values.priceMethodId,
        munf_InvId: values.munf_InvId,
        raw_InvId: values.raw_InvId,
        munfRawMaterials: values.munfRawMaterials,
        munfMunfMaterials: values.munfMunfMaterials,
      },
    ];
  }

  if (emptString(values.date, null) !== null) {
    values.date = values.date?.toISOString().slice(0, 16) + "Z";
  }
  if (emptString(values.inDate, null) !== null) {
    values.inDate = values.inDate?.toISOString().slice(0, 16) + "Z";
  }
  if (emptString(values.outDate, null) !== null) {
    values.outDate = values.outDate?.toISOString().slice(0, 16) + "Z";
  }

  let complexState = detail.some((object) => object.munfModelId !== undefined);
  let munfState = munfDetail.some((object) => object.itemId !== undefined);
  let rawState = rawDetail.some((object) => object.itemId !== undefined);

  if ((!munfState && !rawState) && !complexState) {
    message.error(
      _t("msgListOrderReq")
    ); // المركب
    return { state: false };
  }

    if((!munfState && rawState) && !complexState){
      message.error(
        _t("msgInsertOneRecordMunfAtLeast") + " (" + _t("strTheOutput") + ")"
      ); // الجاهزة
      return { state: false };
    }
  
    if((munfState && !rawState) && !complexState){
      message.error(
        _t("msgInsertOneRecordMunfAtLeast") + " (" + _t("strTheInput") + ")"
      ); // الاولية
      return { state: false };
    }
  
  
  return { state: true, values: values };
}
