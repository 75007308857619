import { emptValue } from "../../../../../helper/helperMethods";
import {
  _Sum,
  _Sub,
  _Mul,
  _Div,
  _RateMul,
} from "../../../../../helper/handleMath";

export function calcQty(obj) {
  if (emptValue(obj.qty, 0) === 0 && emptValue(obj.price, 0) !== 0)
    obj.qty = emptValue(_Div(obj.total, obj.price), null);
  return obj;
}

export function calcPrice(obj) {
  if (emptValue(obj.qty, 0) !== 0)
    obj.price = emptValue(_Div(obj.total, obj.qty), null);
  return obj;
}

export function calcTotal(obj) {
  obj.total = emptValue(_Mul(obj.price, obj.qty), null);
  if (emptValue(obj.discPerc, 0) !== 0) obj = calcDiscValue(obj);
  if (emptValue(obj.addPerc, 0) !== 0) obj = calcAddValue(obj);
  obj = calcSubTotal(obj);
  return obj;
}

export function calcEmptyDiscPec(obj) {
  obj.discPerc = null;
  return obj;
}

export function calcDiscValue(obj) {
  obj.discValue = emptValue(_RateMul(obj.total, obj.discPerc), null);
  obj = calcSubTotal(obj);
  return obj;
}

export function calcEmptyAddPec(obj) {
  obj.addPerc = null;
  return obj;
}

export function calcAddValue(obj) {
  obj.addValue = emptValue(_RateMul(obj.total, obj.addPerc), null);
  obj = calcSubTotal(obj);
  return obj;
}

export function calcSubTotal(obj) {
  obj.subTotal = emptValue(
    _Sum(_Sub(obj.total, obj.discValue), obj.addValue),
    null
  );
  return obj;
}

export function calcEmptyLostPec(obj) {
  obj.lostPerc = null;
  return obj;
}

export function calcLostQty(obj) {
  obj.lostQty = emptValue(_RateMul(obj.qty, obj.lostPerc), null);
  return obj;
}

export function calcEmptyFreePec(obj) {
  obj.freePerc = null;
  return obj;
}

export function calcFreeQty(obj) {
  obj.freeQty = emptValue(_RateMul(obj.qty, obj.freePerc), null);
  return obj;
}
