import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Space,
  Row,
  Col,
  Menu,
  Modal,
  Dropdown,
  Typography,
  Popconfirm,
  Spin,
  DatePicker,
  Card,
  Tag
} from "antd";
import {
  getCurrencyRateById,
  editCurrencyRate,
  getNewCurrencyRateCode,
} from "../../services/Items/currenciesRateService";
import {
  RetweetOutlined,
  DeleteTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import EditableTable from "../helper/editableTable/editableTable";
import CurrencySelector from "../helper/Modal/CurrencySelector";
import { getCurrActiveSearch } from "../../services/Items/currenciesService";
import { getNumFormat, objCountNum } from "../helper/countOfZero";
import CurrEditor from "../helper/dataGrid/source/CurrEditor";
import { TextEditor } from "react-data-grid";
import EditableGrid from "../helper/dataGrid/DataGrid";
import { convertFloat, emptString, emptValue } from "../helper/helperMethods"
import { dateFormatList } from "../helper/Input/dateFormatList";
import moment from "moment";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
import { _Div } from "../helper/handleMath";
const CurrencyRateForm = ({ onNewStore }) => {
  const IX_Code = useRef(null);
  const IX_Date = useRef(null);
  const IX_Currency = useRef(null);
  const IX_Note = useRef(null);
  const gridRef = useRef(null);
  const [form] = Form.useForm();
  const [loadData, setLoadData] = useState(true);
  let history = useHistory();
  let { id } = useParams();
  const today = moment();
  //******************************//
  const [currencyOpt, setCurrencyOptOpt] = useState([]);
  const [isGoBack, setIsGoBack] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [stopEdit, setStopFocus] = useState(true);
  const [lastKeyNum, setLastKeyNum] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const [searchCurrencyValue, setSearchCurrencyValue] = useState("");
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { TextArea } = Input;
  const { confirm } = Modal;
  const { Text } = Typography;
  const [mainCurrency, setMainCurrency] = useState("");


  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////
  const promptForm = () => {
    setProChange(true);
  };

  const columns = [
    {
      key: "key",
      name: "#",
      width: 20,
      resizable: false,
      formatter(props) {
        const key = props?.row?.key + 1;
        return key;
      },
    },
    {
      key: "currName",
      name: _t("strCurrency"),
      width: 200,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            defaultValue={p.row?.search}
            onPressEnter={(e) => currencySearch(e.target.value, p.row.key)}
          />
        );
      },
    },
    {
      key: "rate",
      name: _t("strRate"),
      width: 200,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              
              p?.onRowChange(
                {
                  ...p?.row,
                  rate: emptValue(e?.target?.value,null),
                  equivlent: emptValue(_Div(1 , emptValue(e?.target?.value,0)),null),
                },
                true
              );
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return getNumFormat(p?.row.rate, objCountNum?.value);
      },
    },
    {
      key: "equivlent",
      name: _t("strEquivlent"),
      width: 200,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {

              p?.onRowChange(
                {
                  ...p?.row,
                  equivlent: emptValue(e?.target?.value,null),
                  rate:  emptValue(_Div(1 , emptValue(e?.target?.value,0)),null),
                },
                true
              );
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return getNumFormat(p?.row.equivlent, objCountNum?.value);
      },
    },
  ];

  const deleteColumn = [
    {
      key: "delete",
      name: _t("strDelete"),
      align: "center",
      dataIndex: "delete",
      resizable: false,
      width: 50,
      formatter(props) {
        return (
          <DeleteTwoTone
            twoToneColor="#eb2f96"
            style={{ fontSize: "16px" }}
            onClick={() => handleDelete(props.row.key)}
          />
        );
      },
    },
  ];
  const handleDelete = (key) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        const delValue = dataSource.filter((item) => item.key !== key);
        sortDataKey(delValue, key);
      },
      onCancel() { },
    });
  };

  useEffect(() => {
    async function fetchData() {
      handleSearchCurrency("");
      if (id === "new") {
        return initValue();
      } else {
        return getById(id);
      }
    }
    fetchData();
  }, []);

  const initValue = async () => {
    try {
      const { data: newData } = await getNewCurrencyRateCode();
      form.setFieldsValue({ code: newData.data !== null ? newData.data : "" });
      addEmptyRow([]);
      IX_Date.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const getById = async () => {
    try {
      const { data } = await getCurrencyRateById(id);
      form.setFieldsValue({
        code: data.data.code !== null ? data.data.code : "",
        date: data.data.date !== null ? moment(data.data.date) : "",
        currId: data.data.currId !== null ? data.data.currId : "",
        note: data.data.note,
        inactive: data.data.inactive,
        // details: data.data.details,
      });
      setMainCurrency(data.data.currId !== null ? data.data.currId : "");
      addEmptyRow(data.data.details);
      IX_Date.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      const listDeleteIds = dataSource.map((s) => s.currId);
      for (var i = 0; i < result.data.length; i++) {
        var obj = result.data[i];
        if (listDeleteIds.indexOf(obj.currId) !== -1) {
          result.data.splice(i, 1);
          i--;
        }
      }
      setCurrencyOptOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    }
  };

  const currencySearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getCurrActiveSearch(value);

      if (result.data.length === 1) {
        insertSingleRec(result.data, key);
      } else if (result.data.length < 1) {
        // form.setFieldsValue({ details: dataSource });
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchCurrencyValue(value);
        setShowModal(true);
        window.sessionStorage.setItem("showModal", true);
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = (value, row) => {
    if (value[0].currId !== mainCurrency) {
      const found = dataSource.find((e) => e?.currId === value[0].currId);
      if (found === undefined) {
        // const { details: data } = form.getFieldsValue(true);
        const objIndex = dataSource.findIndex((obj) => obj.key === row);
        dataSource[row].currId = value[0].currId;
        dataSource[row].currName = value[0].currName;
        dataSource[row].equivlent = value[0].equivlent;
        dataSource[row].rate = value[0].rate;
        // form.setFieldsValue({ details: data });
        setDataSource([...dataSource]);
        sortDataKey(dataSource, row);
      } else {
        //form.setFieldsValue({ details: dataSource });
        message.warning(_t("msgThisValueAlreadyExists"));
      }
    } else {
      // form.setFieldsValue({ details: dataSource });
      IX_Currency.current.focus();
      message.warning(_t("msgThisValueAlreadyExists"));
    }
  };

  const handleCurrencySelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      // const { details: data } = form.getFieldsValue(true);
      //
      let dataCollect;
      let tmpdata = dataSource.find((x) => x.key === rowNumberEdit);
      if (tmpdata.currId !== undefined) {
        dataCollect = dataSource;
        let i = 0;
        result.forEach((e) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          dataCollect.splice(rowNumberEdit + i, replaceItem, e);
          i++;
        });
      } else {
        //
        dataCollect = [...dataSource, ...result];
      }
      sortDataKey(dataCollect, null);
    }
    setTimeout(() => {
      setShowModal(false);
      window.sessionStorage.setItem("showModal", false);
    }, 0);
  };

  const sortDataKey = (data, rowNum) => {
    let list = [];
    let KeyNum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].currId !== undefined) {
        let obj = {};
        obj["key"] = KeyNum;
        obj["currId"] = data[i].currId;
        obj["currName"] = data[i].currName;
        obj["rate"] = data[i].rate === undefined ? 1 : data[i].rate;
        obj["equivlent"] =
          data[i].equivlent === undefined ? 1 : data[i].equivlent;
        list.push(obj);
        KeyNum++;
      }
    }
    list.push({ key: list.length });

    //form.setFieldsValue({ details: list });
    setDataSource(list);

    if (rowNum === null) {
      setLastKeyNum(["currName", list.length - 1]);
    } else {
      if (list.map((e) => e.key === rowNum) === undefined) {
        setLastKeyNum(["currName", list.length - 1]);
      } else if (rowNum > list.length) {
        setLastKeyNum(["currName", list.length - 1]);
      } else {
        setLastKeyNum(["currName", rowNum]);
      }
    }
    //
  };

  const addEmptyRow = (arr) => {
    // const dataDetails = form.getFieldsValue(true);
    let newNum =
      arr.reduce((acc, shot) => (acc = acc > shot.key ? acc : shot.key), -1) +
      1;
    let output = [...arr, { key: newNum }];

    //form.setFieldsValue({ details: output });
    setDataSource(output);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({ ...history.location, pathname: "/currencyRate/new" });
      }
      form.resetFields();
      initValue();
      IX_Date.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    // const values = form.getFieldsValue(true);
    values.id = id;
    const oldDate = values.date;
    if (dataSource !== null) {
      let output = dataSource.filter((obj) =>
        Object.keys(obj).includes("currId")
      );
      values.details = output;
    }
    if (values.date !== "") {
      values.date = values.date?.toISOString().slice(0, 16) + "Z";
    }
    try {
      const data = await editCurrencyRate(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      goBack();
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      values.date = oldDate;
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Date?.current?.focus();
              break;
            case "block2":
              gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              break;
            case "block3":
              IX_Note?.current?.focus();
              break;
            case "tag":
              gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);




  //******************************// //*******************// Form Code //*******************// //******************************//

  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));


  return (
    <div key="mainDiv">
      <CurrencySelector
        mainItemId={mainCurrency}
        selectedItems={dataSource}
        searchValue={searchCurrencyValue}
        onHandelData={handleCurrencySelector}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{ date: today, details: dataSource }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strCurrencyRate")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Col xs={24} md={12}>
                      <Form.Item name="date" label={
                        <div>
                          {_t("strDate")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 4,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 1
                            </Tag>
                          </div>
                        </div>
                      }>
                        <DatePicker
                          ref={IX_Date}
                          allowClear={false}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="currId"
                        label={_t("strCurrency")}
                        rules={[{ required: true }]}
                      >
                        <Select
                          ref={IX_Currency}
                          allowClear
                          showSearch
                          onDropdownVisibleChange={() =>
                            handleSearchCurrency("")
                          }
                          filterOption={false}
                          onSearch={(value) => {
                            handleSearchCurrency(value);
                          }}
                          onChange={(e) => setMainCurrency(e)}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            ></Empty>
                          }
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {currencyOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item label={<div>{_t("strDetails")}
                        <div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 24,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + 2
                          </Tag>
                        </div></div>}>

                        <Card
                          bodyStyle={{ padding: 0 }}
                          style={{ marginBottom: 10 }}
                        >
                          <EditableGrid
                            innerRef={gridRef}
                            style={{ height: `calc(100vh - 300px)`, width: "100%" }}
                            columns={[...columns, ...deleteColumn]}
                            dataSource={dataSource}
                            setDataSource={setDataSource}
                          />
                          {/* <EditableTable
                              dataKey="details"
                              stopEdit={stopEdit}
                              columns={[...columns, ...deleteColumn]}
                              scrolling={{ y: '55vh', x: '80vw' }}
                              defaultSelected={lastKeyNum}
                            /> */}
                        </Card>

                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={
                          <div>
                            {_t("strNote")}
                            <div
                              style={{
                                opacity: 0.8,
                                zIndex: 99,
                                position: "absolute",
                                top: 4,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + 3
                              </Tag>
                            </div>
                          </div>
                        }
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          ref={IX_Note}
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  {!auth?.perPut && (
                    <div>
                      <Button
                        type="primary"
                        onClick={() => form.submit()}
                        size="small"
                      >
                        {_t("strSave")}
                      </Button>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + S
                        </Tag>
                      </div>
                    </div>
                  )}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => { history.action === "PUSH" ? history.goBack() : history.push("/currencyRate"); }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default CurrencyRateForm;
