import { emptValue, emptArr } from "../../helperMethods";
import _t from "../../../../languages/translate";
import { getNumFormat } from "../../countOfZero";
import { _RateMul,_Sum } from "../../handleMath";

const convertListToText = (data, amount) => {
  if (data?.length > 1) {
    return data
      ?.map(
        (x) =>
          x?.costCenterName +
          ":" +
          (emptValue(x?.perc, null) !== null
            ? getNumFormat(x?.perc ?? 0, 1)?.toString() + "%"
            : "") +
          (emptValue(x?.value, null) != null
            ? emptValue(x?.perc, null) !== null
              ? "= " + getNumFormat(emptValue(x?.value, 0), 1)?.toString()
              : "" + getNumFormat(emptValue(x?.value, 0), 1)?.toString()
            : "")
      )
      ?.join(" | ");
  } else if (data?.length === 1 && data[0]?.perc === 100) {
    return data[0]?.costCenterName;
  } else {
    return data
      ?.map(
        (x) =>
          x?.costCenterName +
          ":" +
          (emptValue(x?.perc, null) !== null
            ? getNumFormat(x?.perc ?? 0, 1)?.toString() + "%"
            : "") +
          (emptValue(x?.value, null) !== null
            ? emptValue(x?.perc, null) !== null
              ? "= " + getNumFormat(emptValue(x?.value, 0), 1)?.toString()
              : "" + getNumFormat(emptValue(x?.value, 0), 1)?.toString()
            : "")
      )
      ?.join(" | ");
  }
};

// CC ~ for calc and return value of error message.
export const calcAndReturnList = (amount, costList, validationOpt, unValue) => {

  if (costList !== undefined) {
    for (let i = 0; i < costList?.length; i++) {
      if( emptValue(costList[i].perc, 0) !==0){
        costList[i].value = _RateMul(  emptValue(amount, 0),  emptValue(costList[i].perc, 0));
     }
    }
  }

  const amountVal = emptValue(amount, 0);

  const dataText = convertListToText(costList, amountVal);

  const perc = emptValue(
    costList?.reduce((partialSum, a) => _Sum(partialSum , a.perc), 0),
    0
  );

  const sum = emptValue(
    costList?.reduce((partialSum, a) => _Sum(partialSum , a.value), 0),
    0
  );

  if (unValue === true) {
    if (validationOpt === 1 && perc !== 0) {
      return { list: costList, text: dataText, state: "done" };
    } else if (validationOpt === 1 && perc === 0) {
      return {
        list: costList,
        text: dataText,
        state: "required",
      };
    } else if (validationOpt === 2 && perc === 100) {
      return { list: costList, text: dataText, state: "done" };
    } else if (validationOpt === 2 && perc < 100) {
      return {
        list: costList,
        text: dataText,
        state: "distribution",
      };
    } else {
      return { list: costList, text: dataText, state: "done" };
    }
  } else {
    if (validationOpt === 2) {
      if ((sum !== amountVal || sum === 0) && perc !== 100) {
        return {
          list: costList,
          text: dataText,
          state: "distribution",
        };
      } else {
        return { list: costList, text: dataText, state: "done" };
      }
    } else if (validationOpt === 1) {
      if (((sum > amountVal && perc !== 100) || sum <= 0) && perc === 0) {
        return {
          list: costList,
          text: dataText,
          state: "required",
        };
      } else {
        return { list: costList, text: dataText, state: "done" };
      }
    } else {
      if (sum !== 0 && ((sum > amountVal && perc !== 100) || sum <= 0)) {
        return {
          list: costList,
          text: dataText,
          state: "required",
        };
      } else if (sum === 0 && amountVal === null) {
        return {
          list: costList,
          text: dataText,
          state: "required",
        };
      } else {
        return { list: costList, text: dataText, state: "done" };
      }
    }
  }
};

export const reSumCostCenter = (amount, list) => {
  list?.forEach((e) => {
    emptValue(e?.perc, 0) !== 0
      ? (e.value = _RateMul(emptValue(amount, 0), emptValue(e?.perc, 0)))
      : (e.value = e?.value);
  });
  return list;
};

export const calcValueReq = (amountVal, costList, validationOpt) => {
  const sum = emptValue(
    costList?.reduce((partialSum, a) => _Sum(partialSum , a.value), 0), 
    null
  );

  const perc = emptValue(
    costList?.reduce((partialSum, a) => _Sum(partialSum , a.perc), 0), 
    0
  );

  if (validationOpt === 2) {
    if ((sum !== amountVal || sum === null) && perc !== 100) {
      //if cost 100% req
      return _t("strFullValueDistributionIsRequired");
      // error
    } else {
      return;
    }
  } else if (validationOpt === 1) {
    if (sum > amountVal || sum <= 0 || sum === null) {
      //if cost add req
      return _t("strInsertValueIsRequired");
      // error
    } else {
      return;
    }
  } else {
    if (sum !== null && (sum > amountVal || sum <= 0) && perc !== 100) {
      //if cost add req
      return _t("strInsertValueIsRequired");
      // error
    } else if (sum === null && amountVal === null) {
      return _t("strInsertValueIsRequired");
    } else {
      return;
    }
  }
};

export const valByPircInput = (type, arrVal) => {
  if (type === 0 || type === undefined) {
    return;
  } else if (type === 1 && emptArr(arrVal, null) === null) {
    return _t("strInsertValueIsRequired");
  } else if (
    type === 2 &&
    (sumArrPirc(arrVal) > 100 || sumArrPirc(arrVal) < 100)
  ) {
    return _t("strFullValueDistributionIsRequired");
  }
};

const sumArrPirc = (costList) => {
  return emptValue(
    costList?.reduce((partialSum, a) => _Sum(partialSum , a.perc), 0),
    null
  );
};
