export function focusEffectiveFields(
  patt,
  obj,
  disableAddPart,
  disableDiscsPart
) {
  return patt?.payValueVisible &&
    !JSON.parse(window.sessionStorage.getItem("payValueEnable"))
    ? obj.refPayValue?.current?.focus()
    : patt?.cashAccVisible && patt?.cashAccEnable
    ? obj?.refPayAcc?.current?.focus()
    : patt?.cashAccCostCenterVisible && patt?.cashAccCostCenterEnable
    ? obj?.refPayCostCent?.current?.focus()
    : patt?.payMethodVisible && patt?.payMethodEnable
    ? obj?.refPayWay?.current?.focus()
    : disableAddPart
    ? obj?.refAddPerc?.current?.focus()
    : disableAddPart
    ? obj?.refAddValue?.current?.focus()
    : patt?.addInvAccVisible && patt?.addInvAccEnable && disableAddPart
    ? obj?.refAddAcc?.current?.focus()
    : patt?.addInvAccCostCenterVisible &&
      patt?.addInvAccCostCenterEnable &&
      disableAddPart
    ? obj?.refAddCostCent?.current?.focus()
    : disableDiscsPart
    ? obj?.refDiscPerc?.current?.focus()
    : disableDiscsPart
    ? obj?.refDiscValue?.current?.focus()
    : patt?.discInvAccVisible && patt?.discInvAccEnable && disableDiscsPart
    ? obj?.refDiscAcc?.current?.focus()
    : patt?.discInvAccCostCenterVisible &&
      patt?.discInvAccCostCenterEnable &&
      disableDiscsPart
    ? obj?.refDiscCostCent?.current?.focus()
    : patt?.addItemAccVisible && patt?.addItemAccEnable
    ? obj?.refItemAddAcc?.current?.focus()
    : patt?.addItemAccCostCenterVisible && patt?.addItemAccCostCenterEnable
    ? obj?.refItemAddCostCent?.current?.focus()
    : patt?.discItemAccVisible && patt?.discItemAccEnable
    ? obj?.refItemDiscAcc?.current?.focus()
    : patt?.discItemAccCostCenterVisible && patt?.discItemAccCostCenterEnable
    ? obj?.refItemDiscCostCent?.current?.focus()
    : null;
}
