import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Modal, Tag, Button } from "antd";
import DataGrid, { SelectColumn } from "react-data-grid";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActiveTreeSearchPopup } from "../../../services/AccountService";
import { shortCut } from "../../helper/shortCuts";
import Draggable from "react-draggable";
import { SelectCellFormatter } from "react-data-grid";
import { emptValue } from "../helperMethods";

const AccountTreeSelector = ({
  currData,
  onHandelData,
  searchValue = "",
  showModal,
  setShowModal,
  showModalName,
  type = "checkbox",
}) => {
  const [selectedRows, setSelectedRows] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);
  const gridRef = useRef(null);
  const refAccSearch = useRef(null);
  const { Search } = Input;

  const columns = [
    SelectColumn,
    {
      name: _t("strType"),
      key: "isMain",
      width: 55,
      minWidth: 55,
      maxWidth: 55,
      formatter({ row }) {
        return (
          <label
            className="keyNum"
            style={{ width: "100%", fontSize: 10, fontWeight: 600 }}
          >
            {row?.isMain ? (
              <Tag color="magenta">{_t("strMain")}</Tag>
            ) : (
              <Tag color="cyan">{_t("strSub")}</Tag>
            )}
          </label>
        );
      },
    },

    {
      name: _t("strCode"),
      key: "accCode",
      width: 50,
    },
    {
      name: _t("strName"),
      key: "accName",
      width: 300,
    },
  ];

  useEffect(() => {
    if (showModal) {
      searchData("");
    }
    if (showModal) gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    else gridRef?.current?.selectCell({ idx: null, rowIdx: null });
  }, [showModal]);

  useEffect(() => {
    setLoadData(true);
    popupForm.setFieldsValue({ search: searchValue });
    searchData(searchValue);
  }, [searchValue]);

  const [altPress, setAltPress] = useState(false);

  const allowInsert = () => {
    return type === "checkbox"
      ? emptValue(selectedRows?.size, 0) === 0
        ? true
        : false
      : checkInsert
      ? false
      : true;
  };

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem(showModalName))) {
        switch (shortCut(e)) {
          case "insert":
            if (allowInsert) {
              popupForm.submit();
            }
            break;
          case "search":
            refAccSearch?.current?.focus();
            refAccSearch?.current?.select();
            break;
          case "block1":
            setTimeout(function () {
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "tag":
            if (!!gridRef.current)
              gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
            setAltPress(true);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const searchData = async (value) => {
    try {
      const { data: result } = await getActiveTreeSearchPopup(value);
      const removedItems = result.data.filter((item) =>
        currData.some((oldItem) => oldItem.accId === item.accId)
      );
      if (removedItems.length > 0) {
        setDataSource(
          result.data.filter((item) => !removedItems.includes(item))
        );
      } else {
        setDataSource(result.data);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
      refAccSearch?.current?.blur();
      gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    }
  };
  const [checkInsert, setCheckInsert] = useState(false);
  const handleCheckDef = (row) => {
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].accId === row.accId) {
        dataSource[i].insert = true;
      } else {
        dataSource[i].insert = false;
      }
    }
    setCheckInsert(true);
    setDataSource([...dataSource]);
  };

  const onFinish = () => {
    let array = [];
    if (type === "checkbox") {
      if (emptValue(selectedRows?.size, 0) !== 0) {
        selectedRows?.forEach((x) => x == array?.push(x));
        const result = dataSource.filter((c) =>
          array.some((s) => s === c.accId)
        );
        onHandelData(result);
      }
    } else {
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].insert === true) {
          array.push(dataSource[i]);
        }
      }
      onHandelData(array);
    }
  };

  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      handleCheckDef("");
      setSelectedRows();
      setCheckInsert(false);
      setLoadData(false);
    }, 50);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <Modal
      centered
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strAccounts")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        window.sessionStorage.setItem(showModalName, false);
      }}
      // onOk={() => popupForm.submit()}
      afterClose={() => handleClear()}
      //  okText={_t("strInsert")}

      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => popupForm.submit()}
          disabled={allowInsert()}
        >
          <div>
            {_t("strInsert")}
            <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + Enter
              </Tag>
            </div>
          </div>
        </Button>,
      ]}
    >
      <Form form={popupForm} forceRender onFinish={onFinish}>
        <div>
          <Form.Item name="search">
            <Search
              ref={refAccSearch}
              onSearch={searchData}
              placeholder={_t("strSearch")}
              size="small"
              style={{ width: 200, marginBottom: 25 }}
            />
          </Form.Item>
          <div
            style={{
              opacity: 0.8,
              zIndex: 99,
              position: "absolute",
              top: 100,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + F
            </Tag>
          </div>
        </div>
        <Form.Item>
          <div>
            <DataGrid
              selectedRows={selectedRows}
              onSelectedRowsChange={(e) => {
                setSelectedRows(e);
              }}
              ref={gridRef}
              style={{ height: `calc(100vh - 300px)`, width: "100%" }}
              columns={columns}
              rowHeight={25}
              className="rdg-light"
              rowKeyGetter={(row) => row?.accId}
              rows={dataSource}
              onRowsChange={setDataSource}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              direction={_t("langDiriction")}
            />
            <div
              style={{
                opacity: 0.8,
                position: "absolute",
                top: 24,
              }}
            >
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + 1
              </Tag>
            </div>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AccountTreeSelector;
