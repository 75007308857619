import React, { memo } from "react";
import { Select } from "antd";

const { Option } = Select;

const DropDownList = ({
  value,
  onFilter,
  rowKey,
  onBlur,
  onSearch,
  options,
  ...rest
}) => {
  
  const filteredOptions =
    typeof onFilter === "function" ? onFilter(rowKey) : options;

  return (
    <Select
      autoFocus 
      size="small"
      showSearch
      optionFilterProp='children'
      bordered={false}
      value={value}
      {...rest}
    >
      {filteredOptions?.map((op) => (
        <Option key={op?.id} value={op?.id} >
          {op?.name}
        </Option>
      ))}
    </Select>
  );
};

export default memo(DropDownList);
