import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Select,
  Col,
  Upload,
  Button,
  Space,
  Tabs,
  Badge,
} from "antd";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import PathText from "../helper/gridDataTable/components/pathText";
import EditableGrid from "../helper/dataGrid/DataGrid";
import { TextEditor, SelectCellFormatter } from "react-data-grid";

const ImportFromXls = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [openTabOne, setOpenTabOne] = useState(false);
  const [openTabTwo, setOpenTabTwo] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState("1");

  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      required: true,
      field:  "text",
      colInExcel: "text",
      defaultValue: "text",
    },
  ]);
  const columns = [
    {
      key: "rowNum",
      name: "#",
      width: 0,
    },
    {
      key: "required",
      name: _t("strRequired"),
      resizable: true,
      formatter(p) {
        return (
          <label>
            {p.row.required === true ? (
              <Badge
                color="green"
                text={_t("strRequired")}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Badge
                color="red"
                text={_t("strUnRequired")}
                style={{ fontSize: 10 }}
              />
            )}
          </label>
        );
      },
    },
    {
      key: "field",
      name: _t("strField"),
      resizable: true,
    },
    {
      key: "colInExcel",
      name: _t("strColInExcel"),
      resizable: true,
      editor: TextEditor,
    },
    {
      key: "defaultValue",
      name: _t("strDefaultValue"),
      resizable: true,
      editor: TextEditor,
    },
  ];

  const onFinish = (values) => {};

  const children = [];

  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const handleChange = (value) => {

  };

  const handleContinue = () => {
    setOpenTabOne(false);
    setOpenTabTwo(true);
    setTabActiveKey("2");
  };

const handlePrev =()=>{
  setOpenTabOne(true);
  setOpenTabTwo(false);
  setTabActiveKey("1");
}

  const handleSaveTemplate = () => {};

  return (
    <>
      <PathText
        pageName={_t("strImportFormExcel")}
        groupName={_t("strSettings")}
        type={"sett"}
      />
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
      >
   

        <div className="frContent">
          <Tabs
            activeKey={tabActiveKey}
            size="small"
            style={{ marginBottom: 32 }}
          >
            <TabPane tab={_t("strEditFile")} key="1" disabled={!openTabOne}>
            <Row gutter={12} style={{marginBottom:5}}>
            <Col span={16}>
              <Form.Item label="Import type">
                <Select
                  style={{ width: "100%" }}
                  size="small"
                  onChange={handleChange}
                >
                  {children}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  showUploadList={false}
                  maxCount={1}
                >
                  <Button size="small" icon={<CloudUploadOutlined />}>
                    Upload File
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
              <Space style={{ marginBottom: 5 }}>
                <Button
                  size="small"
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={handleContinue}
                >
                  {_t("strContinue")}
                </Button>
                <Button size="small" type="link" onClick={handleSaveTemplate}>
                  {_t("strSaveTemplate")}
                </Button>
              </Space>
              <EditableGrid
                columns={columns}
                style={{ height: `calc(100vh - 300px)`, width: "100%" }}
                dataSource={dataSource.map(
                  (e, i) => (e = { ...e, rowNum: i + 1 })
                )}
                setDataSource={setDataSource}
              />
            </TabPane>

            <TabPane tab={"سجلات غير قابلة للرفع"} key="2" disabled={!openTabTwo}>            
              <Space style={{ marginBottom: 5 }}>
                <Button
                  size="small"
                  type="primary"
                  icon={<UploadOutlined />}
                >
                  {_t("strContinue")}
                </Button>
                <Button size="small" type="link">
                  {_t("strTableExport")}
                </Button>
                <Button size="small" onClick={handlePrev}>
                {_t("strPrev")}
                </Button>
              </Space>
              <EditableGrid
                columns={[]}
                style={{ height: `calc(100vh - 300px)`, width: "100%" }}
                dataSource={[]}
                setDataSource={[]}
              />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default ImportFromXls;
