import React, { useState, useEffect, useMemo, useRef } from "react";
import { Form, message, Tooltip, Input, Card, Modal } from "antd";
import _t from "../../../../../languages/translate";
import { handleEx } from "../../../../helper/handleException";
import AccountSelector from "../../../../helper/Modal/AccountSelector";
import { getActAccPopupSearch } from "../../../../../services/AccountService";
import { TextEditor, SelectCellFormatter } from "react-data-grid";
import CurrEditor from "../../../../helper/dataGrid/source/CurrEditor";
import DataGrid from "react-data-grid";
import { getNumFormat } from "../../../../helper/countOfZero";
import DropDownList from "../../../../helper/dataGrid/source/DropDownList";
import { getSearchPopup } from "../../../../../services/CostCenterService";
import { calcAndReturnList } from "../../../../helper/Modal/costCenterModal/costCenterHelper";
import CostCenterSelector from "../../../../helper/Modal/costCenterModal/CostCenterSelector";
import { reSumCostCenter } from "../../../../helper/Modal/costCenterModal/costCenterHelper";

import {
  emptValue,
  emptString,
  emptArr, //
} from "../../../../helper/helperMethods";

import { ExclamationCircleOutlined, DeleteTwoTone, CloseCircleFilled} from "@ant-design/icons";
import {
  _RateMul,
  _RateDiv,
  _SumArrWithRate,
  _SumArr,
} from "../../../../helper/handleMath";
const InvGridAdds = ({
  innerRef,
  accList,
  pattrenSet,
  form,
  addsDataSource,
  setAddsDataSource,
  setDisableAddPart,
  setLoadData,
  promptForm,
  setStatAddFields,
  statAddFields,
}) => {
  const [searchAccValue, setSearchAccValue] = useState("");
  const [showAddAccModal, setShowAddAccModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const [typePopup, setTypePopup] = useState("checkbox");
  // cost center
  const [showCostModal, setShowCostModal] = useState(false);
  const [searchCostValue, setSearchCostValue] = useState();
  const [amountCostValue, setAmountCostValue] = useState();
  const [costCenterValue, setCostCenterValue] = useState([]);
  const [validationOptValue, setValidationOptValue] = useState(0);

  const [pValue, setPValue] = useState();

  const { confirm } = Modal;

  // #region ~ fill columns from server
  const allColumns = [
    {
      name: "#",
      key: "num",
      minWidth: 30,
      width: 43,
      resizable: false,
      formatter(props) {
        const key = props?.row?.key + 1;
        return key;
      },
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{row.totalCount - 1}</strong>
        );
      },
    },
    {
      name: "value",
      key: "value",
      width: 150,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              if (e.target.value !== null) {
                p?.onRowChange(
                  {
                    ...p?.row,
                    value: e.target.value,
                    perc:
                      emptValue(e.target.value, 0) !==
                      emptValue(p?.row?.value, 0)
                        ? null
                        : p?.row?.perc,
                    costCenter_Maps: reSumCostCenter(
                      e.target.value,
                      p.row?.costCenter_Maps
                    ),
                  },
                  true
                );
                promptForm();
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.value} className="numCell">
            {getNumFormat(p?.row?.value, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalValue} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalValue, 1)}
            </strong>
          </Tooltip>
        );
      },
    },
    {
      name: "perc",
      key: "perc",
      width: 150,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              if (e.target.value !== null) {
                const { itemSubTotal: sub } = form.getFieldsValue(true);
                const val = emptValue(_RateMul(sub, e.target.value), undefined);
                p?.onRowChange(
                  {
                    ...p?.row,
                    perc: e.target.value,
                    value: val,
                    costCenter_Maps: reSumCostCenter(
                      val,
                      p.row?.costCenter_Maps
                    ),
                  },
                  true
                );
                promptForm();
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.perc} className="numCell">
            {getNumFormat(p?.row?.perc, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalPerc} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalPerc, 1)}
            </strong>
          </Tooltip>
        );
      },
    },
    {
      name: "accName",
      key: "accName",
      width: 150,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            //defaultValue={p.row?.accName}
            onPressEnter={(e) => accSearch(e.target.value, p.row.key, "acc")}
          />
        );
      },
    },
    {
      name: "distributed",
      key: "distributed",
      width: 50,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.distributed}
            onChange={() => {
              onRowChange({
                ...row,
                distributed: !row.distributed,
              });
              promptForm();
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },
    {
      key: "costCentersName",
      name: "",
      width: 300,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            // defaultValue={p.row?.costCentersName}
            onPressEnter={(e) => {
              costCenterSearch(e.target.value, p);
              if (pattrenSet?.entType !== 0) {
                promptForm();
              }
            }}
            suffix={
              <CloseCircleFilled 
              className="clearIcon"
                onClick={async () => {       
                  p?.onRowChange(
                    {
                      ...p?.row,
                      costCentersName:null,
                      costCenter_Maps:null
                    },
                    true
                  );


                }}

              />
            }
          />
        );
      },

      formatter: (p) => {
        const { status, arrHasVal } = handleCostFormatter(p.row);

        return (
          <Tooltip
            color={status.state !== "done" ? "red" : "#2c2c2ce0"}
            title={
              arrHasVal === false
                ? ""
                : status.state === "required"
                ? _t("msgErrorAddCostCenter")
                : status.state === "distribution"
                ? _t("msgErrorDistrCostCenter")
                : status?.text
            }
            placement="bottomRight"
          >
            <p style={{ color: status.state !== "done" ? "red" : "#2c2c2ce0" }}>
              {status?.text}
            </p>
          </Tooltip>
        );
      },
      editable: (row) => {
        if (row?.rowType === "read" || !pattrenSet?.addInvAccCostCenterEnable) {
          return false;
        }
      },
    },
    {
      key: "note",
      name: _t("strNote"),
      width: 170,
      editor: TextEditor,
    },
    {
      name: _t("strNextAcc"),
      key: "nextAccId",
      width: 150,
      editor: (p) => {
        return (
          <DropDownList
            allowClear
            style={{ width: "100%" }}
            rowKey={p?.row?.key}
            value={p.row?.nextAccId}
            onChange={(e, o) => {
              p?.onRowChange(
                { ...p?.row, nextAccId: e, nextAccName: o?.children },
                true
              );
            }}
            options={accList}
          />
        );
      },
      formatter: (p) => p?.row?.nextAccName,
    },
  ];

  const deleteColumn = [
    {
      key: "delete",
      name: _t("strDelete"),
      align: "center",
      dataIndex: "delete",
      resizable: false,
      width: "5%",
      formatter(props) {
        return (
          <DeleteTwoTone
            twoToneColor="#eb2f96"
            style={{ fontSize: "16px" }}
            onClick={() => {
              conDelete(props.row);
            }}
          />
        );
      },
    },
  ];

  const conDelete = (row) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        handleDelete(row?.key);
      },
      onCancel() {},
    });
  };

  const handleDelete = (key) => {
    const newUnit = addsDataSource.filter((e) => e.key !== key);
    let newNum = 0;
    for (let i = 0; i < newUnit.length; i++) {
      newUnit[i].key = newNum;
      newNum++;
    }
    setAddsDataSource([...newUnit]);
    if (addsDataSource.length <= 1) {
      setAddsDataSource([{ key: 0, distributed: pattrenSet?.addInvDist }]);
    }
  };

  // #endregion

  // #region ~ insert Cost Centers

  const costCenterSearch = async (e, p) => {
    try {
      const { data: result } = await getSearchPopup(e);

      if (result.data.length > 0) {
        if (
          result.data.length === 1 &&
          emptArr(p.row?.costCenter_Maps, null) === null
        ) {
          let object = p.row;
          object.costCenterName = result.data[0].costCenterName;
          object.costCenter_Maps = [
            {
              costCenterId: result.data[0].costCenterId,
              costCenterName: result.data[0].costCenterName,
              perc: 100,
              value: emptValue(p.row?.value, null),
            },
          ];

          p?.onRowChange(
            {
              ...p?.row,
              costCentersName: object.costCenterName,
              costCenter_Maps: object.costCenter_Maps,
              state: object.costState,
            },
            true
          );
        } else {
          setCostCenterValue(p.row?.costCenter_Maps);
          setValidationOptValue(p.row?.costCenterReqOpt);
          setSearchCostValue(e);
          setAmountCostValue(emptValue(p.row?.value, null));
          setPValue(p);
          window.sessionStorage.setItem("showCostModal", true);
          setShowCostModal(true);
        }
      } else {
        message.info(_t("msgNotFoundSearchResult"));
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const handleCostCenterData = (obj) => {
    pValue?.onRowChange(
      {
        ...pValue?.row,
        costCentersName: obj.text,
        costCenter_Maps: obj.list,
        costState: obj.state,
      },
      true
    );
    setShowCostModal(false);
    setValidationOptValue(0);
    setCostCenterValue([]);
    setAmountCostValue();
    setSearchCostValue("");
    window.sessionStorage.setItem("showCostModal", false);
  };

  const handleCostFormatter = (row) => {
    if (row !== undefined) {
      const status = calcAndReturnList(
        emptValue(row?.value, null),
        row?.costCenter_Maps,
        row?.costCenterReqOpt
      );
      row.costState = status?.state;
      const arrHasVal = emptArr(row?.costCenter_Maps, false);
      status.text =
        arrHasVal === false ? typeCostReq(row?.costCenterReqOpt) : status?.text;
      return { status: status, arrHasVal: arrHasVal };
    } else {
      return;
    }
  };

  const typeCostReq = (type) => {
    if (type === 0 || type === undefined) {
      return;
    } else if (type === 1) {
      return <p style={{ color: "red" }}>
        {_t("strInsertValueIsRequired")}</p>;
    } else if (type === 2) {
      return (
        <p style={{ color: "red" }}>
          {_t("strFullValueDistributionIsRequired")}
        </p>
      );
    }
  };

  // #endregion

  // #region ~ insert Accounts

  const accSearch = async (value, key, type) => {
    type === "acc" ? setTypePopup("checkbox") : setTypePopup("radio");
    try {
      setRowNumberEdit(key);
      const { data: result } = await getActAccPopupSearch(value, false);

      if (result.data.length === 1) {
        insertSingleRec(
          result.data,
          key,
          type === "acc" ? "checkbox" : "radio"
        );
      } else if (result.data.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchAccValue(value);
        setShowAddAccModal(true);
        window.sessionStorage.setItem("showAddAccModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = async (value, row, type) => {
    const data = addsDataSource;

    if (type === "checkbox") {
      data[row].accId = value[0].accId;
      data[row].accName = value[0].accName;
      //data[row].costCenter_Maps = data[0].costCenter_Maps;
      data[row].costCenterReqOpt = value[0].costCenterReqOpt;
    } else {
      data[row].nextAccId = value[0].accId;
      data[row].nextAccName = value[0].accName; ////////////////////////////////AK
      //data[row].costCenter_Maps = data[0].costCenter_Maps;
      data[row].costCenterReqOpt = value[0].costCenterReqOpt;
    }

    sortDataKey(data, row);
  };

  const appendAccSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit, typePopup);
    } else {
      let dataCollect;
      let tmpdata = addsDataSource.find((x) => x.key === rowNumberEdit);

      if (tmpdata?.accId !== undefined) {
        dataCollect = addsDataSource;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            memoData.value = addsDataSource[rowNumberEdit + i].value;
            memoData.perc = addsDataSource[rowNumberEdit + i].perc;
            memoData.id = addsDataSource[rowNumberEdit + i].id;
          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        memoData[0].value = addsDataSource[rowNumberEdit]?.value;
        memoData[0].perc = addsDataSource[rowNumberEdit]?.perc;
        dataCollect = [...addsDataSource, ...memoData];
      }
      sortDataKey(dataCollect, null);
    }
    setTimeout(() => {
      setShowAddAccModal(false);
      window.sessionStorage.setItem("showAddAccModal", false);
    }, 0);
  };

  const sortDataKey = (data) => {
    const list = [];
    let KeyNum = 0;
    for (let i = 0; i < data.length; i++) {
      if (emptString(data[i].accId, null) !== null) {
        let obj = {};
        obj["key"] = KeyNum;
        obj["id"] = data[i].id;
        obj["value"] = data[i].value;
        obj["perc"] = data[i].perc;
        obj["accId"] = data[i].accId;
        obj["accName"] = data[i].accName;
        obj["costCenter_Maps"] = data[i].costCenter_Maps;
        obj["costCenterReqOpt"] = data[i].costCenterReqOpt;
        obj["distributed"] = pattrenSet?.addInvDist;
        obj["note"] = data[i].note;
        obj["nextAccName"] = data[i].nextAccName;
        obj["nextAccId"] = data[i].nextAccId;
        list.push(obj);
        KeyNum++;
      }
    }
    list.push({ key: list.length, distributed: pattrenSet?.addInvDist });
    promptForm();
    setAddsDataSource([...list]);
  };

  // #endregion

  // #region ~ fill columns from server

  useEffect(() => {
    setColumns([allColumns[0]]);
    pattrenSet?.addsColSett.map((e) => fillColumns(e));
  }, [pattrenSet?.addsColSett, addsDataSource]);

  const fillColumns = (tmpObj) => {
    allColumns.map((e) => {
      if (e.key === tmpObj.key) {
        e.name = tmpObj.name;
        e.width = tmpObj.width;
        setColumns((x) => [...x, e]);
      }
    });
  };

  // #endregion

  // #region ~summary

  const sumValue = (arr) => {
    return _SumArr(arr, "value");
  };

  const sumPerc = (arr) => {
    return _SumArr(arr, "perc");
  };

  const calcAddTotal = () => {
    if (!statAddFields) {
      const addData = calcTable(addsDataSource);
      const { itemSubTotal: sub } = form.getFieldsValue(true);
      if (
        addData.filter(
          (x) =>
            emptValue(x?.value, 0) > 0 ||
            emptValue(x?.perc, 0) > 0 ||
            x?.accId !== undefined
        ).length > 1
      ) {
        setDisableAddPart(false);
        let addsTotalValue = _SumArrWithRate(addData, "value", "perc", sub);
        form.setFieldsValue({
          invAddTotal: emptValue(addsTotalValue, undefined),
          invAddTotalPer: emptValue(
            _RateDiv(addsTotalValue, form.getFieldsValue().itemSubTotal),
            undefined
          ),
        });
      } else {
        setDisableAddPart(true);
        const status = handleCostFormatter(addData[0]);

        form.setFieldsValue({
          invAddId: addData[0]?.id,
          invAddTotal: _SumArrWithRate(addData, "value", "perc", sub),
          invAddTotalPer: emptValue(addData[0]?.perc, undefined),
          invAddTotalAcc: addData[0]?.accId,
          addInvCostCenter_Maps: addData[0]?.costCenter_Maps,

          addInvCostCenter:status?.status.state ==="done"?  status?.status?.text:"",
          costCenterReqOpt: addData[0]?.costCenterReqOpt,

          invAddTotalDistr: addData[0]?.distributed,
        });
      }
    }
    setStatAddFields(false);
  };

  const calcTable = (addData) => {
    const { itemSubTotal: sub } = form.getFieldsValue(true);
    if (
      addData.filter(
        (x) => emptValue(x?.value, 0) > 0 || emptValue(x?.perc, 0) > 0
      )
    ) {
      addData.map((e) => {
        if (emptValue(e.perc, undefined) !== undefined) {
          e.value = emptValue(_RateMul(sub, e.perc), undefined);
          e.costCenter_Maps = reSumCostCenter(e.value, e.costCenter_Maps);
        }
      });

      return addData;
    }
  };

  const summaryRows = useMemo(() => {
    calcAddTotal();
    const summaryRow = [
      {
        id: "total_0",
        totalCount: addsDataSource.length,
        totalValue: sumValue(addsDataSource),
        totalPerc: sumPerc(addsDataSource),
      },
    ];
    return summaryRow;
  }, [addsDataSource, form.getFieldValue("itemSubTotal")]);

  // #endregion

  return (
    <>
      <AccountSelector
        searchValue={searchAccValue}
        onHandelData={appendAccSelector}
        showModal={showAddAccModal}
        setShowModal={setShowAddAccModal}
        showModalName="showAddAccModal"
        type={typePopup}
        withClient={false}
      />

      <CostCenterSelector
        searchValue={searchCostValue}
        costCenterValue={costCenterValue}
        onHandelData={handleCostCenterData}
        showModal={showCostModal}
        setShowModal={setShowCostModal}
        amount={amountCostValue}
        validationOpt={validationOptValue}
      />

      <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 10 }}>
        <DataGrid
          ref={innerRef}
          style={{ height: "24vh", width: "100%" }}
          columns={[...columns, ...deleteColumn]}
          rows={addsDataSource}
          rowHeight={25}
          className="rdg-light"
          onRowsChange={setAddsDataSource}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          direction={_t("langDiriction")}
          summary={summaryRows}
        />
      </Card>
    </>
  );
};
export default InvGridAdds;
