import { emptValue, emptString } from "../../../../helper/helperMethods";




export function convObjToStyle(tempObj) {
// Generate a random UUID
const random_uuid = uuidv4();
  
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

  //#region ==== Border Style

  let _borderStyle = tempObj?.values?.drawingBorder
    ? `border-width:${
        emptValue(tempObj?.values.borderT, 0) +
        tempObj?.values.borderType +
        " " +
        emptValue(tempObj?.values.borderR, 0) +
        tempObj?.values.borderType +
        " " +
        emptValue(tempObj?.values.borderB, 0) +
        tempObj?.values.borderType +
        " " +
        emptValue(tempObj?.values.borderL, 0) +
        tempObj?.values.borderType
      };border-color:${tempObj?.values.borderColor};border-style:${
        tempObj?.values.borderStyle
      };`
    : "";

  //#endregion

  //#region ==== Spaces Style

  let _spaceStyle = `width:${
    emptValue(tempObj?.values.width, 0) + tempObj?.values.widthHightType
  };height:${emptValue(tempObj?.values.hight, 0) + tempObj?.values.widthHightType};`;

  //#endregion

  //#region ==== Margin Style

  let _marginStyle = `margin:${
    emptValue(tempObj?.values.marginT, 0) +
    tempObj?.values.marginType +
    " " +
    emptValue(tempObj?.values.marginR, 0) +
    tempObj?.values.marginType +
    " " +
    emptValue(tempObj?.values.marginB, 0) +
    tempObj?.values.marginType +
    " " +
    emptValue(tempObj?.values.marginL, 0) +
    tempObj?.values.marginType
  };`;

  //#endregion

  //#region ==== Padding Style

  let _paddingStyle = `padding:${
    emptValue(tempObj?.values.paddingT, 0) +
    tempObj?.values.paddingType +
    " " +
    emptValue(tempObj?.values.paddingR, 0) +
    tempObj?.values.paddingType +
    " " +
    emptValue(tempObj?.values.paddingB, 0) +
    tempObj?.values.paddingType +
    " " +
    emptValue(tempObj?.values.paddingL, 0) +
    tempObj?.values.paddingType
  };`;

  //#endregion

  //#region ==== textAlign Style

  let _textAlignStyle = `text-align:${tempObj?.values?.textAlign};`;

  //#endregion

  //#region ==== Font Style

  let _fontStyle = `font-size:${emptValue(
    tempObj?.values.fontSize,
    12
  )}px;font-weight:${tempObj?.values.fontWeight};color:${
    tempObj?.values.fontColor
  };font-style:${tempObj?.values.italic ? "italic" : "normal"};text-overflow: ${
    tempObj?.values.textWarper ? "ellipsis" : "normal"
  };`;

  //#endregion
  
  return {
    id: tempObj?.id === undefined ? random_uuid : tempObj?.id,
    elementName: tempObj?.values?.elementName,
    elementType: tempObj?.values?.elementType,
    elementId: tempObj?.values?.elementId,
    afterTextAr: emptString(tempObj?.values?.afterTextAr, ""),
    afterTextEn: emptString(tempObj?.values?.afterTextEn, ""),
    beforeTextAr: emptString(tempObj?.values?.beforeTextAr, ""),
    beforeTextEn: emptString(tempObj?.values?.beforeTextEn, ""),
    row: emptValue(tempObj?.values?.row, 1),
    col: emptValue(tempObj?.values?.col, 1),
    dateTimeFormatting: emptString(tempObj?.values?.dateTimeFormatting, ""),
    valueFormat: tempObj?.values?.valueFormat,
    countOfZeros: emptValue(tempObj?.values?.countOfZeros, 0),
    thousandsComma: tempObj?.values?.thousandsComma,
    unPrintEmptyElements: tempObj?.values?.unPrintEmptyElements,
    tdStyle: `${
      _borderStyle +
      _spaceStyle +
      _marginStyle +
      _paddingStyle +
      _textAlignStyle +
      _fontStyle
    }`,
    source: JSON.stringify(tempObj?.values),
    colSett: tempObj?.values?.colSett,
    tHeaderStyle: tempObj?.values?.tHeaderStyle,
    tBodyStyle: tempObj?.values?.tBodyStyle,
  };

}
