import { emptValue } from "../../../../helper/helperMethods";
import { _Mul, _Div } from "../../../../helper/handleMath";

export function calcDebet(obj) {
  obj.currDebet = emptValue(_Mul(obj.debet, obj.currRate), null);
  obj.credit = null;
  obj.currCredit = null;
  return obj;
}

export function calcCredit(obj) {
  obj.currCredit = emptValue(_Mul(obj.credit, obj.currRate), null);
  obj.debet = null;
  obj.currDebet = null;
  return obj;
}

export function calcCurrCredit(obj) {
    
  let _credit = emptValue(obj?.credit, null) === null ? true : false;
  let _currCredit = emptValue(obj?.currCredit, null) !== null ? true : false;
  let _currRate = emptValue(obj?.currRate, null) !== null ? true : false;
  obj.debet = null;
  obj.currDebet = null;
  obj.credit =  _credit && _currCredit && _currRate  ? emptValue(_Div(obj.currCredit, obj.currRate), null) : obj.credit;

  
  obj.currRate =  _credit === false && _currCredit !== false ? emptValue(_Div(obj.currCredit, obj.credit), null) : obj.currRate;

 // obj.currEquivlent =  _credit === false && _currCredit !== false ? emptValue(_Div(1, obj.currRate), null) : obj.currEquivlent;
  return obj;
}

export function calcCurrDebet(obj) {
    
  let _debet = emptValue(obj?.debet, null) === null ? true : false;
  let _currDebet = emptValue(obj?.currDebet, null) !== null ? true : false;
  let _currRate = emptValue(obj?.currRate, null) !== null ? true : false;

  obj.credit = null;
  obj.currCredit = null;

  obj.debet =  _debet && _currDebet && _currRate  ? emptValue(_Div(obj.currDebet, obj.currRate), null) : obj.debet;

  obj.currRate =  _debet === false && _currDebet !== false  ? emptValue(_Div(obj.currDebet, obj.debet), null) : obj.currRate;

  
 // obj.currEquivlent = _debet === false && _currDebet !== false ? emptValue(_Div(1, obj.currRate), null) : obj.currEquivlent;
  return obj;
}

export function calcCurrRateDebet(obj) {
  obj.currDebet =
    _Mul(obj.debet, obj.currRate) === 0 ? null : _Mul(obj.debet, obj.currRate);
  return obj;
}

export function calcCurrRateCredit(obj) {
  obj.currCredit =
    _Mul(obj.credit, obj.currRate) === 0
      ? null
      : _Mul(obj.credit, obj.currRate);
  return obj;
}

// export function calcEquivlent(obj) {
//   obj.currEquivlent = emptValue(_Div(1, obj.currRate), null);
//   return obj;
// }

// export function calcCurrRate(obj) {
//     
//   obj.currRate = emptValue(_Div(1, obj.currEquivlent), null);
//   return obj;
// }

export function chackCurrRateDebet(obj) {
  let _valDebet = emptValue(obj.debet, null) !== null;
  let _valCurrRate = emptValue(obj.currRate, null) !== null;
  if (_valDebet && _valCurrRate) return calcCurrRateDebet(obj);
  else return obj;
}

export function chackCurrRateCredit(obj) {
  let _valCredit = emptValue(obj.credit, null) !== null;
  let _valCurrRate = emptValue(obj.currRate, null) !== null;
  if (_valCredit && _valCurrRate) return calcCurrRateCredit(obj);
  else return obj;
}
