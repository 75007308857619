
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Menu";


export function getMenu() {
  return http.get(`${apiEndpoint}`);
}

