import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  message,
  Tooltip,
  Input,
  Card,
  Dropdown,
} from "antd";
import { getDetailsByUnits } from "../../../../../services/invoiceService";
import _t from "../../../../../languages/translate";
import { handleEx } from "../../../../helper/handleException";
import ItemInvSelector from "../../../../helper/Modal/ItemInvSelector";
import CheckQtyItems from "../../../../helper/Modal/CheckQtyItems";
import CheckPriceItems from "../../../../helper/Modal/CheckPriceItems";
import { getSearchPopup } from "../../../../../services/Items/itemService";
import DataGrid from "react-data-grid";
import { emptValue, emptString } from "../../../../helper/helperMethods";
import DropDownList from "../../../../helper/dataGrid/source/DropDownList";
import CurrEditor from "../../../../helper/dataGrid/source/CurrEditor";
import TextBox from "../../../../helper/dataGrid/source/TextBox";
import { getNumFormat } from "../../../../helper/countOfZero";

import {
  handleQty,
  handlePrice,
  handleTotal,
  handleDiscVal,
  handleDiscPerc,
  handleAddVal,
  handleAddPerc,
  handleLostPerc,
  handleLostQty,
  handleLostItemUnit,
  handleFreePerc,
  handleFreeQty,
  handleFreeItemUnit,
  handleNote,
  handleItemUnit,
} from "./handleColumns/handleColumns";
import {
  sumQTY,
  sumPrice,
  sumTotal,
  sumDiscValue,
  sumAddValue,
  sumSubTotal,
  sumLostQty,
  sumFreeQty,
} from "./handleColumns/sumColumns";
import { calcQty, calcTotal } from "./handleColumns/calcColumns";

const InvMainGrid = ({
  auth,
  innerRef,
  pattrenSet,
  form,
  mainDataSource,
  setMainDataSource,
  setLoadData,
  id,
  promptForm,
  noStyle,
  changeUnit,
  ...rest
}) => {
  const [searchItemInvValue, setSearchItemInvValue] = useState("");
  const [showInvModal, setShowInvModal] = useState(false);
  const [showQtyItemsModal, setShowQtyItemsModal] = useState(false);
  const [showPriceItemsModal, setShowPriceItemsModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  let mainObj;

  const [lineUnitId, setLineUnitId] = useState("");
  const [lineItemName, setLineItemName] = useState("");
  const [lineDate, setLineDate] = useState("");

  const handleOpenQtyItem = (unitId = "", date = "", itemName = "") => {
    setLineUnitId(unitId);
    setLineItemName(itemName);
    setLineDate(date);

    setShowQtyItemsModal(true);
  };

  const handleOpenPriceItem = (unitId = "", date = "") => {
    setLineUnitId(unitId);
    setLineDate(date);

    
    setShowPriceItemsModal(true);
  };

  // #region ~Columns

  const onClick = ({ key }) => {
    let sorce = JSON.parse(key);

    if (sorce.type === "qty") {
      handleOpenQtyItem(
        sorce.obj?.itemUnit_MapId,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
        sorce.obj?.itemName
      );
    } else if (sorce.type === "price") {
      window.sessionStorage.setItem("popKey", sorce.obj?.key);
      handleOpenPriceItem(
        sorce.obj?.itemUnit_MapId,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
    } else if (sorce.type === "delete") {
      handleDelete(sorce.obj?.key);
    }
  };

  const allColumns = [
    {
      name: "#",
      key: "num",
      minWidth: 30,
      width: 43,
      formatter: (p) => {
        
        let items = [];
        {
          auth?.perShowQtyDetails &&
            items.push({
              label: _t("strQTY"),
              key: JSON.stringify({ type: "qty", obj: p.row }),
            });
        }
        {
          auth?.perShowPrices &&
            items.push({
              label: _t("strPrice"),
              key: JSON.stringify({ type: "price", obj: p.row }),
            });
        }

        items.push({ type: "divider" });
        items.push({
          danger: true,
          label: _t("strDelete"),
          key: JSON.stringify({ type: "delete", obj: p.row }),
        });

        return p.row?.itemId ? (
          <Dropdown
            menu={{ items, onClick }}
            className="keyNum"
            trigger={["click"]}
          >
            <a
              onClick={(e) => e.preventDefault()}
              style={{
                width: "100%",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {p?.row?.key + 1}
            </a>
          </Dropdown>
        ) : (
          <label className="keyNum">{p?.row?.key + 1}</label>
        );
      },
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{row.totalCount - 1}</strong>
        );
      },
    },
    {
      name: "",
      key: "itemName",
      width: 200,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            onPressEnter={(e) => {
              itemSearch(e.target.value, p.row.key);
              promptForm();
            }}
          />
        );
      },
      editable: (row) => row?.rowType !== "read",
      summaryFormatter() {
        return <strong style={{ color: "#7e7e7e" }}>{_t("strTotal")}</strong>;
      },
    },
    {
      name: "",
      key: "itemUnit_MapId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.itemUnit_MapId}
          bordered={false}
          className="TextEditor"
          onChange={async (e) => {
            let date = emptString(
              form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
              ""
            );
            let priceMethod = emptString(
              form.getFieldValue("priceMethodId"),
              ""
            );
            let rate = emptValue(form.getFieldValue("currRate"), "");
            mainObj = await handleItemUnit(
              p,
              e,
              mainObj,
              date,
              priceMethod,
              rate
            );
            setRowChanges(p, mainObj);
            changeUnit(e);
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.itemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "qty",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleQty(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.qty} className="numCell">
            {getNumFormat(p?.row?.qty, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQty} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "price",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handlePrice(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.price} className="numCell">
            {getNumFormat(p?.row?.price, 2)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalPrice} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalPrice, 2)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "total",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleTotal(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.total} className="numCell">
            {getNumFormat(p?.row?.total, 2)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalTotal} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalTotal, 2)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "discValue",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleDiscVal(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.discValue} className="numCell">
            {getNumFormat(p?.row?.discValue, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalDiscValue} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalDiscValue, 1)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "discPerc",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleDiscPerc(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.discPerc} className="numCell">
            {getNumFormat(p?.row?.discPerc, 5)}
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "addValue",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleAddVal(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.addValue} className="numCell">
            {getNumFormat(p?.row?.addValue, 1)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalAddValue} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalAddValue, 1)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "addPerc",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleAddPerc(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.addPerc} className="numCell">
            {getNumFormat(p?.row?.addPerc, 5)}
          </Tooltip>
        );
      },
      editable: (row) => pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "subTotal",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.subTotal} className="numCell">
            {p?.row?.subTotal < 0 ? (
              <lable style={{ color: "red" }}>
                {getNumFormat(p?.row?.subTotal, 2)}
              </lable>
            ) : (
              getNumFormat(p?.row?.subTotal, 2)
            )}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalSubTotal} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalSubTotal, 2)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => !pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "lostPerc",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleLostPerc(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostPerc} className="numCell">
            {getNumFormat(p?.row?.lostPerc, 5)}
          </Tooltip>
        );
      },

      editable: (row) => pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "lostQty",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleLostQty(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.lostQty} className="numCell">
            {getNumFormat(p?.row?.lostQty, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalLostQty} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalLostQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "lostItemUnit_MapId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.lostItemUnit_MapId}
          bordered={false}
          allowClear
          className="TextEditor"
          onChange={(e) => {
            if (p?.row !== undefined) {
              mainObj = handleLostItemUnit(p, e, mainObj);
              setRowChanges(p, mainObj);
            }
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.lostItemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: "",
      key: "freePerc",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleFreePerc(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.freePerc} className="numCell">
            {getNumFormat(p?.row?.freePerc, 5)}
          </Tooltip>
        );
      },
      editable: (row) => pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "freeQty",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleFreeQty(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.freeQty} className="numCell">
            {getNumFormat(p?.row?.freeQty, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalFreeQty} className="numCell">
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalFreeQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => pattrenSet?.companyCurrId || row?.rowType === "read",
    },
    {
      name: "",
      key: "freeItemUnit_MapId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.freeItemUnit_MapId}
          bordered={false}
          allowClear
          className="TextEditor"
          onChange={(e) => {
            if (p?.row !== undefined) {
              mainObj = handleFreeItemUnit(p, e, mainObj);
              setRowChanges(p, mainObj);
            }
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.freeItemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      key: "note",
      name: "",
      width: 150,
      editor: (p) => {
        return (
          <TextBox
            row={p.row}
            column={p.column}
            onBlur={(e) => {
              if (p?.row !== undefined) {
                mainObj = handleNote(p, e, mainObj);
                setRowChanges(p, mainObj);
              }
            }}
            onRowChange={p.onRowChange}
            onClose={p.onClose}
          />
        );
      },
      // editor: TextEditor,
      editable: (row) => row?.rowType !== "read",
    },
  ];

  // #endregion

  // #region ~Init

  useEffect(() => {
    setColumns([allColumns[0]]);
    pattrenSet?.detailsColSett.map((e) => fillColumns(e));
  }, [pattrenSet?.detailsColSett, showInvModal]);

  const fillColumns = (tmpObj) => {
    allColumns.map((e) => {
      if (e.key === tmpObj.key) {
        e.name = tmpObj.name;
        e.width = tmpObj.width;
        setColumns((x) => [...x, e]);
      }
    });
  };

  //#endregion

  // #region ~Insert

  const itemSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getSearchPopup(value);
      if (result.data.length === 1) {
        const servData = await groupingIds(result.data);
        insertSingleRec(servData, key);
      } else if (result.data.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchItemInvValue(value);
        setShowInvModal(true);
        window.sessionStorage.setItem("showInvModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = (value, row) => {
    
    const data = mainDataSource;
    
    if (data[row] === undefined) {
      data.push({ key: row });
    }
    data[row] = value[0];
    sortDataKey(data, row);
  };

  const groupingIds = async (arr) => {
    let list = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.itemUnit_MapId === undefined) continue;
      id = "";
      id = arr[i]?.itemUnit_MapId;
      list.push(id);
    }

    const { data: data } = await getDetailsByUnits(
      list,
      form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      form.getFieldValue().priceMethodId === undefined
        ? ""
        : form.getFieldValue().priceMethodId,
      form.getFieldValue().currRate === undefined
        ? ""
        : form.getFieldValue().currRate
    );
    return data.data;
  };

  const appendAccSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      const data = mainDataSource;
      let dataCollect;
      let tmpdata = data.find((x) => x.key === rowNumberEdit);
      if (tmpdata.itemId !== undefined) {
        dataCollect = data;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            // memoData = data[rowNumberEdit + i];

            memoData.id = data[rowNumberEdit + i].id;

          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        dataCollect = [...data, ...memoData];
      }
      sortDataKey(dataCollect);
    }
    setTimeout(() => {
      setShowInvModal(false);
      window.sessionStorage.setItem("showInvModal", false);
    }, 0);
  };

  const sortDataKey = (data) => {
    let list = [];
    let KeyNum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].itemId !== undefined) {
        let obj = {};
        obj = data[i];
        obj["key"] = KeyNum;
        list.push(obj);
        KeyNum++;
      }
    }
    setRow([...list]);
    //promptForm();
  };

  const setRow = (list) => {
    list.push({ key: list.length });
    setMainDataSource([...list]);
    promptForm();
  };

  //#endregion

  const setRowChanges = (p, obj) => {
    if (obj !== undefined) {
      p?.onRowChange(
        {
          ...p.row,
          qty: emptValue(obj?.qty, null),
          price: emptValue(obj?.price, null),
          total: emptValue(obj?.total, null),
          discValue: emptValue(obj?.discValue, null),
          discPerc: emptValue(obj?.discPerc, null),
          addValue: emptValue(obj?.addValue, null),
          addPerc: emptValue(obj?.addPerc, null),
          subTotal: emptValue(obj?.subTotal, null),
          lostQty: emptValue(obj?.lostQty, null),
          lostPerc: emptValue(obj?.lostPerc, null),
          freeQty: emptValue(obj?.freeQty, null),
          freePerc: emptValue(obj?.freePerc, null),
          note: emptString(obj?.note, null),
          itemUnit_MapId: emptString(obj?.itemUnit_MapId, null),
          freeItemUnit_MapId: emptString(obj?.freeItemUnit_MapId, null),
          lostItemUnit_MapId: emptString(obj?.lostItemUnit_MapId, null),
        },
        true
      );
      let data = mainDataSource;
      data[p.row.key] = obj;
      setRow([...data]);
    }
  };

  // #region ~Actions

  const handleDelete = (key) => {
    mainDataSource.splice(key, 1);
    sortDataKey(mainDataSource);
    if (mainDataSource.length < 1) {
      setMainDataSource([{ key: 0 }]);
    }
  };

  // #endregion

  // #region ~Handel

  const handleItemInvSelector = async (result) => {
    const servData = await groupingIds(result);
    appendAccSelector(servData);
  };

  //#endregion

  // #region ~Summary

  const summaryRows = useMemo(() => {
    form.setFieldsValue({
      itemTotal: sumTotal(mainDataSource),
      itemDiscTotal: sumDiscValue(mainDataSource),
      itemAddTotal: sumAddValue(mainDataSource),
      itemSubTotal: sumSubTotal(mainDataSource),
      unitTotal: sumQTY(mainDataSource),
    });
    const summaryRow = [
      {
        id: "total_0",
        totalCount: mainDataSource.length,
        totalQty: sumQTY(mainDataSource),
        totalPrice: sumPrice(mainDataSource),
        totalTotal: sumTotal(mainDataSource),
        totalDiscValue: sumDiscValue(mainDataSource),
        totalAddValue: sumAddValue(mainDataSource),
        totalSubTotal: sumSubTotal(mainDataSource),
        totalLostQty: sumLostQty(mainDataSource),
        totalFreeQty: sumFreeQty(mainDataSource),
      },
    ];
    return summaryRow;
  }, [mainDataSource]);

  //#endregion

  const onHandleInsert = (obj) => {
    
    let data = mainDataSource;
    let popKey = parseInt(window.sessionStorage.getItem("popKey"));
    let val = emptValue(parseFloat(obj.e?.target?.value), null);
    data[popKey].price = val;
    if (emptValue(data[popKey].qty, 0) === 0)
      data[popKey] = calcQty(data[popKey]);
    else data[popKey] = calcTotal(data[popKey]);
    setMainDataSource([...data]);
    setShowPriceItemsModal(false);
  };

  return (
    <>
      <ItemInvSelector
        searchValue={searchItemInvValue}
        onHandelData={handleItemInvSelector}
        showModal={showInvModal}
        setShowModal={setShowInvModal}
        showModalName="showInvModal"
      />
      <CheckQtyItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        itemName={lineItemName}
        showModal={showQtyItemsModal}
        setShowModal={setShowQtyItemsModal}
      />

      <CheckPriceItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        rate={form.getFieldValue().currRate}
        showModal={showPriceItemsModal}
        setShowModal={setShowPriceItemsModal}
        handleInsert={onHandleInsert}
        // pRow={pRow}
      />

      <Form.Item
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        label={_t("strDetails")}
        noStyle={noStyle}
      >
        <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 10 }}>
          <DataGrid
            ref={innerRef}
            columns={[...columns]}
            rows={mainDataSource}
            rowHeight={25}
            className="rdg-light"
            onRowsChange={setMainDataSource}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
            summaryRows={summaryRows}
            {...rest}
          />
        </Card>
      </Form.Item>
    </>
  );
};
export default InvMainGrid;
