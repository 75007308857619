import http from "./http";
import { useEffect, useState } from "react";

export function useApi(initUrl, initData) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(initData);
  const [url, setUrl] = useState(initUrl);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setIsLoading(true);
      try {
      
        const { data } = await http.get(url);
        if (!didCancel) setData(data.data);
      } catch ({ response }) {
        if (!didCancel) {
          setIsError(true);
        }
      } finally {
        if (!didCancel) setIsLoading(false);
      }
    };
    if (url !== "") fetchData();
    return () => {
      didCancel = true;
    };
  }, [setIsLoading, setIsError, setData, url]);

  const state = { data, isLoading, isError };

  return [state, setUrl, setData];
}
