import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
  Spin,
  Tag,
} from "antd";
import {
  getCostCenterById,
  editCostCenter,
  getActiveParents,
  getInitiale,
} from "../../services/CostCenterService";
import {ExclamationCircleOutlined,RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import { shortCut } from "../helper/shortCuts";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CostCentersForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const IX_Name2 = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  //******************************//
  const [parentChange, setParentChange] = useState(false);
  const [storeParentOpt, setStoreParentOpt] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [stateParintId, setStateParintId] = useState("");
  const [isGoBack, setIsGoBack] = useState(true);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { TextArea } = Input;
  const { Text } = Typography;
 
  const promptForm = () => {
    setProChange(true);
  };

  const initValue = async (Id) => {
    try {
      const { data: newData } = await getInitiale(Id);  
      if (id !== "new" && id !== "add") {
        form.setFieldsValue({
          code: newData.data.code !== null ? newData.data.code : "",
        });
      } else {
        form.setFieldsValue({
          code: newData.data.code !== null ? newData.data.code : "",
          parentId: newData.data.parentId !== null ? newData.data.parentId : "",
          isMain: newData.data.isMain,
          type: newData.data.type,

          itemFinalAccId:
            newData.data.itemFinalAccId !==
            "00000000-0000-0000-0000-000000000000"
              ? newData.data.itemFinalAccId
              : undefined,

          inactive: newData.data.inactive,
        });

      }
     
   
      handleActiveParents(
        "",
        newData.data.parentId !== null ? newData.data.parentId : ""
      );
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  const getById = async () => {
    try {
      
      const { data } = await getCostCenterById(id);
      if (data.data.parentId === null) {
        data.data.parentId = "";
      }
      setStateParintId(data.data.parentId);

      form.setFieldsValue({
        code: data.data.code !== null ? data.data.code : "",
        parentId: data.data.parentId !== null ? data.data.parentId : "",
        isMain: data.data.isMain,
        name: data.data.name,
        name2: data.data.name2,
        itemFinalAccId: data.data.itemFinalAccId,
        // type: data.data.type,
        note: data.data.note,
        inactive: data.data.inactive,
      });
   
  
      handleActiveParents(
        "",
        data.data.parentId !== null ? data.data.parentId : ""
      );
      IX_Name.current.focus();
  
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
   
      if (id === "new" || typeof onNewStore === "function") {
        return await initValue("");
      } else if (id === "add") {
        const parentId = query.get("ParentId");

        // const parentId = history.location.state?.parentId;

        if (!parentId) return;
        setIsGoParentId(parentId);
        return await initValue(parentId);
      } else {
        setParentChange(true);
        return await getById(id);
      }
    }
    fetchData();
  }, []);

  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        direction: _t("langDiriction"),
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          initValue(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      initValue(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      if (history.action === "POP") {
        history.push({ pathname: "/costCenter" });
      } else {
        history.goBack();
      }
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/costCenter/new" });
      }
      form.resetFields();
      initValue(isGoParentId);
      handleActiveParents("", "");
      IX_Name.current.focus();
    }
  };


  // search client & supp by type num // AK~
  const handleActiveParents = async (_Search, _Active) => {
    try {
      const { data: responce } = await getActiveParents(_Search, _Active);
      setStoreParentOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":          
              setAltPress(true);
              break;
            default:
              break;
          }
        }    
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    if (values.isMain === undefined) {
      values.isMain = false;
    }

    try {
      const data = await editCostCenter(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      goBack();
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const storeParentOptList =
    storeParentOpt.length > 0 &&
    storeParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );

  const isMainChange = (e) => {
    setProChange(true);
  };

  return (
    <div key="mainDiv">
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{
              isMain: false,
            }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strCostCenter")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={_t("strCostCenterClassification")}
                        name="isMain"
                        hidden={typeof onNewStore === "function"}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                          onChange={isMainChange}
                        >
                          <Radio value={false} autoFocus>
                            {_t("strSub")}
                          </Radio>
                          <Radio value={true}>{_t("strMain")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="parentId"
                        label={_t("strMainCenter")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleActiveParents(value, stateParintId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          onChange={onChangeParent}
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {storeParentOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={
                          <div>
                            {_t("strName")}
                            <div
                              style={{
                                opacity: 0.8,
                                position: "absolute",
                                top: 4,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + 1
                              </Tag>
                            </div>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name2"
                        label={_t("strName2")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName2")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name2}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              name="inactive"
                              valuePropName="checked"
                              label={_t("strInActive")}
                            >
                              <Switch size="small" />
                            </Form.Item>
                          </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  <div>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      size="small"
                      disabled={loading}
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => {
                      history.action === "PUSH"
                        ? history.goBack()
                        : history.push("/items");
                    }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default CostCentersForm;
