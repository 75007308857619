import { _SumArr } from "../../../../helper/handleMath";

export function sumQty(arr) {
  return _SumArr(arr, "qty");
};

export function sumPrice(arr) {
  return _SumArr(arr, "price");
};

export function sumTotal(arr) {
  return _SumArr(arr, "total");
};

export function sumLostQty(arr) {
  return _SumArr(arr, "lostQty");
};

export function sumQtyTotal(arr) {
  return _SumArr(arr, "qtyTotal");
};

export function sumLostPrice(arr) {
  return _SumArr(arr, "lostPrice");
};

export function sumLostPriceTotal(arr) {
  return _SumArr(arr, "lostPriceTotal");
};

export function sumNetTotal(arr) {
  return _SumArr(arr, "netTotal");
};
