export function checkInvType(typeId) {
  const obj = {
    showAddItemAccVisible: true,
    showAddItemAccEnable: true,
    showAddItemAccDefId: true,

    showClientVisible: true,
    showClientReq: true,
    showClientEnable: true,
    showClientDefId: true,
    showDiscItemAccVisible: true,
    showDiscItemAccEnable: true,
    showDiscItemAccDefId: true,
    showItemDiscTotalVisible: true,
    showItemAddTotalVisible: true,
    showAddInvAccVisible: true,
    showAddInvAccEnable: true,
    showAddInvAccDefId: true,
    showAddInvDist: true,
    showAddInvDistEnable: true,
    showInvDiscTotalVisible: true,
    showDiscInvAccVisible: true,
    showDiscInvAccEnable: true,
    showDiscInvAccDefId: true,
    showDiscInvDist: true,
    showDiscInvDistEnable: true,
    showInvAddTotalVisible: true,

    showItemTotalVisible: true,

    showItemSubTotalVisible: true,
    showNetTotalVisible: true,
    showClientAccVisible: true,
    showClientAccEnable: true,
    showClientAccReq: true,
    showClientAccDefId: true,
    showCalcPayTotalVisible: true,
    showPayTotalVisible: true,
    showRestTotalVisible: true,
    showPayValueVisible: true,
    showAddsColSett: true,
    showCashAccDefId: true,
    showCashAccVisible: true,
    showCashAccReq:true,
    showCashAccEnable: true,
    showPayMethodVisible: true,
    showPayMethodEnable: true,
    showPayMethodReq: true,
    showPayMethodDefId: true,
    showDueDateVisible: true,
    showDueDateEnable: true,
    showDueDateReq: true,
    showDiscsColSett: true,
    showToStoreVisible: true,
    showToStoreEnable: true,
    showToStoreReq: true,
    showToStoreDefId: true,
    showPostEntVisible: true,
    showPostEntEnable: true,
    showPostEnt: true,
    showAccStatusVisible: true,
    showOprStatusVisible: true,
    showOprStatusEnable: true,
    showOprStatusDefId: true,
    showAddInvAccTableVisible: true,
    showDiscInvAccTableVisible: true,
    showInvAccVisible: true,
    showInvAccEnable: true,
    showInvAccReq: true,
    showInvAccDefId: true,
    showCurrVisible: true,
    showCurrEnable: true,
    showCurrChType: true,
    showCurrDefId: true,
    showPriceMethodVisible: true,
    showPriceMethodEnable: true,
    showPriceMethodDefId: true,
    showGenEnt: true,
    showGenShortEnt: true,
    showGenEntFromTotal: true,
    showJournalId: true,
    showCostEffect: true,
    showLastPurEffect: true,
    showLastSalesEffect: true,
    showLastClientEffect: true,
    showAddTab :true,
    showDiscTab :true,

    showClientAccCostCenterVisible:true,
    showClientAccCostCenterEnable:true,
    showClientAccCostCenterReq:true,
    showClientAccCostCenter_Maps:true,
    
    showInvAccCostCenterVisible:true,
    showInvAccCostCenterEnable:true,
    showInvAccCostCenterReq:true,
    showInvAccCostCenter_Maps:true,
    
    showInvDiscCostCenterVisible:true,
    showInvDiscCostCenterEnable:true,
    showInvDiscCostCenterReq:true,
    showInvDiscCostCenter_Maps:true,
    
    showItemDiscCostCenterVisible:true,
    showItemDiscCostCenterEnable:true,
    showItemDiscCostCenterReq:true,
    showItemDiscCostCenter_Maps:true,
    
    showItemAddCostCenterVisible:true,
    showItemAddCostCenterEnable:true,
    showItemAddCostCenterReq:true,
    showItemAddCostCenter_Maps:true,
    
    showInvAddCostCenterVisible:true,
    showInvAddCostCenterEnable:true,
    showInvAddCostCenterReq:true,
    showInvAddCostCenter_Maps:true,


  };
  switch (typeId) {
    case "b06e5b92-b19e-45b1-9c25-1fb51f8f2b5e": // مشتريات
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showLastSalesEffect = false;
      obj.showLastClientEffect = false;
      break;
    case "51807f8b-9e03-498e-8f90-b634273b5c4b": // مبيعات
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showCostEffect = false;
      obj.showLastPurEffect = false;
      break;
    case "c81f2a29-b52b-433b-b107-655342313007": // مر مشتريات
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showLastSalesEffect = false;
      obj.showLastClientEffect = false;
      break;
    case "4ca23e8b-63f5-4147-92ef-3cd45a1c9145": // مر مبيعات
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showCostEffect = false;
      obj.showLastPurEffect = false;
      break;
    case "17ccdbca-cc1b-47bf-8d87-411277026ca2": // ادخال
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showLastSalesEffect = false;
      obj.showLastClientEffect = false;
      break;
    case "678c6cc0-bb0a-4065-89e2-2265b2ce37df": // اخراج
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showCostEffect = false;
      obj.showLastPurEffect = false;
      break;
    case "38e83484-c6a5-4245-ab3c-a8091cdd2d54": // بضاعة اول مدة
      obj.showAddItemAccVisible = false;
      obj.showAddItemAccEnable = false;
      obj.showAddItemAccDefId = false;

      obj.showItemAddCostCenterVisible = false;
      obj.showItemAddCostCenterEnable = false;
      obj.showItemAddCostCenterReq = false;
      obj.showItemAddCostCenter_Maps = false;


      obj.showClientVisible = false;
      obj.showClientReq = false;
      obj.showClientEnable = false;
      obj.showClientDefId = false;
      obj.showDiscItemAccVisible = false;
      obj.showDiscItemAccEnable = false;
      obj.showItemDiscTotalVisible = false;
      obj.showItemAddTotalVisible = false;
      obj.showDiscItemAccDefId = false;

      obj.showItemDiscCostCenterVisible = false;
      obj.showItemDiscCostCenterEnable = false;
      obj.showItemDiscCostCenterReq = false;
      obj.showItemDiscCostCenter_Maps = false;

      obj.showAddInvAccVisible = false;
      obj.showAddInvAccEnable = false;
      obj.showAddInvDist = false;
      obj.showAddInvDistEnable = false;
      obj.showAddInvAccDefId = false;


      obj.showInvAddCostCenterVisible= false;
      obj.showInvAddCostCenterEnable= false;
      obj.showInvAddCostCenterReq= false;
      obj.showInvAddCostCenter_Maps= false;
      
      obj.showInvAddTotalVisible = false;
      obj.showItemSubTotalVisible = false;
      
      obj.showClientAccVisible = false;
      obj.showClientAccEnable = false;
      obj.showClientAccReq = false;
      obj.showClientAccDefId = false;

      obj.showClientAccCostCenterVisible=false;
      obj.showClientAccCostCenterEnable=false;
      obj.showClientAccCostCenterReq=false;
      obj.showClientAccCostCenter_Maps=false;

      obj.showInvDiscTotalVisible = false;
      obj.showDiscInvAccVisible = false;
      obj.showDiscInvAccEnable = false;
      obj.showDiscInvDist = false;
      obj.showDiscInvDistEnable = false;
      obj.showDiscInvAccDefId = false;


      obj.showInvDiscCostCenterVisible = false;
      obj.showInvDiscCostCenterEnable = false;
      obj.showInvDiscCostCenterReq = false;
      obj.showInvDiscCostCenter_Maps = false;

      obj.showCalcPayTotalVisible = false;
      obj.showPayTotalVisible = false;
      obj.showRestTotalVisible = false;
      obj.showAddsColSett = false;
      obj.showDiscsColSett = false;
      obj.showAddInvAccTableVisible = false;
      obj.showDiscInvAccTableVisible = false;
      obj.showPayValueVisible = false;
      obj.showCashAccDefId = false;
      obj.showCashAccVisible = false;
      obj.showCashAccEnable = false;
      obj.showCashAccReq = false;

      obj.showPayMethodVisible = false;
      obj.showPayMethodEnable = false;
      obj.showPayMethodReq = false;
      obj.showPayMethodDefId = false;
      obj.showDueDateVisible = false;
      obj.showDueDateEnable = false;
      obj.showDueDateReq = false;
      obj.showToStoreVisible = false;
      obj.showToStoreEnable = false;
      obj.showToStoreReq = false;
      obj.showToStoreDefId = false;
      obj.showOprStatusVisible = false;
      obj.showOprStatusEnable = false;
      obj.showOprStatusDefId = false;
      obj.showGenShortEnt = false;
      obj.showGenEntFromTotal = false;
      obj.showAccStatusVisible = false;
      obj.showPostEntEnable = false;
      obj.showLastSalesEffect = false;
      obj.showNetTotalVisible = false;
      obj.showLastClientEffect = false;
      obj.showDiscTab =false;
      obj.showAddTab=false;
      obj.showGenEnt = false;
      break;
    case "626182e0-32ac-4af2-9b77-5fb7ffbd46f0": // تحويل مخزني
      obj.showAddItemAccVisible = false;
      obj.showAddItemAccEnable = false;
      obj.showAddItemAccDefId = false;

      obj.showItemAddCostCenterVisible = false;
      obj.showItemAddCostCenterEnable = false;
      obj.showItemAddCostCenterReq = false;
      obj.showItemAddCostCenter_Maps = false;

      obj.showClientVisible = false;
      obj.showClientReq = false;
      obj.showClientEnable = false;
      obj.showClientDefId = false;
      obj.showDiscItemAccVisible = false;
      obj.showDiscItemAccEnable = false;
      obj.showItemDiscTotalVisible = false;
      obj.showItemAddTotalVisible = false;
      obj.showDiscItemAccDefId = false;

      obj.showItemDiscCostCenterVisible = false;
      obj.showItemDiscCostCenterEnable = false;
      obj.showItemDiscCostCenterReq = false;
      obj.showItemDiscCostCenter_Maps = false;

      obj.showAddInvAccVisible = false;
      obj.showAddInvAccEnable = false;
      obj.showAddInvDist = false;
      obj.showAddInvDistEnable = false;
      obj.showAddInvAccDefId = false;

      obj.showInvAddCostCenterVisible= false;
      obj.showInvAddCostCenterEnable= false;
      obj.showInvAddCostCenterReq= false;
      obj.showInvAddCostCenter_Maps= false;

      obj.showInvAddTotalVisible = false;
      obj.showItemTotalVisible =false;
      obj.showItemSubTotalVisible = false;

      obj.showClientAccVisible = false;
      obj.showClientAccEnable = false;
      obj.showClientAccReq = false;
      obj.showClientAccDefId = false;

      obj.showClientAccCostCenterVisible=false;
      obj.showClientAccCostCenterEnable=false;
      obj.showClientAccCostCenterReq=false;
      obj.showClientAccCostCenter_Maps=false;

      obj.showInvDiscTotalVisible = false;
      obj.showDiscInvAccVisible = false;
      obj.showDiscInvAccEnable = false;
      obj.showDiscInvDist = false;
      obj.showDiscInvDistEnable = false;
      obj.showDiscInvAccDefId = false;


      obj.showInvDiscCostCenterVisible = false;
      obj.showInvDiscCostCenterEnable = false;
      obj.showInvDiscCostCenterReq = false;
      obj.showInvDiscCostCenter_Maps = false;

      obj.showNetTotalVisible = false;
      obj.showCalcPayTotalVisible = false;
      obj.showPayTotalVisible = false;
      obj.showRestTotalVisible = false;
      obj.showAddsColSett = false;
      obj.showDiscsColSett = false;
      obj.showAddInvAccTableVisible = false;
      obj.showDiscInvAccTableVisible = false;
      obj.showPayValueVisible = false;
      obj.showCashAccDefId = false;
      obj.showCashAccVisible = false;
      obj.showCashAccEnable = false;
      obj.showCashAccReq = false;
      obj.showPayMethodVisible = false;
      obj.showPayMethodEnable = false;
      obj.showPayMethodReq = false;
      obj.showPayMethodDefId = false;
      obj.showDueDateVisible = false;
      obj.showDueDateEnable = false;
      obj.showDueDateReq = false;

      obj.showInvAccVisible = false;
      obj.showInvAccEnable = false;
      obj.showInvAccReq = false;
      obj.showInvAccDefId = false;

      obj.showInvAccCostCenterVisible=false;
      obj.showInvAccCostCenterEnable=false;
      obj.showInvAccCostCenterReq=false;
      obj.showInvAccCostCenter_Maps=false;

      obj.showCurrVisible = false;
      obj.showCurrEnable = false;
      obj.showCurrChType = false;
      obj.showCurrDefId = false;
      obj.showPriceMethodVisible = false;
      obj.showPriceMethodEnable = false;
      obj.showPriceMethodDefId = false;
      obj.showPostEntVisible = false;
      obj.showPostEntEnable = false;
      obj.showPostEnt = false;
      obj.showGenEnt = false;
      obj.showGenShortEnt = false;
      obj.showGenEntFromTotal = false;
      obj.showAccStatusVisible = false;
      obj.showCostEffect = false;
      obj.showLastPurEffect = false;
      obj.showLastSalesEffect = false;
      obj.showLastClientEffect = false;
      obj.showDiscTab =false;
      obj.showAddTab=false;
      break;
    default:
      break;
  }

  return obj;
}
