import React, { useEffect, useState, useContext } from "react";
import { Menu, message } from "antd";
import _t from "../../languages/translate";
import { Link } from "react-router-dom";
import { getMenu } from "../../services/menuService";
import { authFromServer } from "../../services/Authorization/authService";
import { LanguageContext } from "../../languages/Language";
import { handleEx } from "../helper/handleException";
import {
  SettingFilled,
  ContainerFilled,
  SignalFilled,
  ExperimentFilled,
  FileTextFilled,
  PushpinFilled,
  BuildFilled,
} from "@ant-design/icons";
let rootSubmenuKeys = [];

const SideMenu = ({ isMobile, ...rest }) => {
  const { userLanguage } = useContext(LanguageContext);

  const [openKeys, setOpenKeys] = React.useState(["sub1"]);
  const [menuData, setMenuData] = useState([]);
  const onOpenChange = (keys) => {
    if (keys !== null) {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    }
  };

  const setIconsMenu = (e) => {
    switch (e.key) {
      case "Msub_Def":
        e.icon = <ContainerFilled style={{ fontSize: 14 }} />;
        break;
      case "Msub_Opr_Ents":
        e.icon = <PushpinFilled style={{ fontSize: 14 }} />;
        break;
      case "Msub_Sett":
        e.icon = <SettingFilled style={{ fontSize: 14 }} />;
        break;
      case "Msub_Opr_Invs":
        e.icon = <FileTextFilled style={{ fontSize: 14 }} />;
        break;
      case "Msub_Rep":
        e.icon = <SignalFilled style={{ fontSize: 14 }} />;
        break;
      case "Msub_Opr_Munfs":
        e.icon = <ExperimentFilled style={{ fontSize: 14 }} />;
        break;
       case "Msub_Patt":
         e.icon = <SettingFilled style={{ fontSize: 14 }} />;
         break;
      default:
        break;
    }
    return e;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await authFromServer();
        const { data: result } = await getMenu();
        setMenuData([
          ...result.data
          // ,{
          //   label: "الاعدادات",
          //   key: "Msub_Patt",
          //   url: null,
          //   children: [
          //     { label: "أنماط الطباعة", key: "print_Patt", url: "/printPatt" }
          //   ],
          // },
        ]);
      } catch (error) {
        handleEx(error)
        // if (error.response) {
        //   message.error(
        //     error.response.data?.message || _t("msgUnknownError"),
        //     3
        //   );
        // } else if (error.request) {
        //   
        //   message.error(_t("msgServerDisconnected"), 3);
        // } else {
        //   
        //   message.error(_t("msgUnknownError"), 3);
        // }
      }
    };
    fetchData();
  }, [window.localStorage.getItem("lang")]);

  const renderMenu = (menuData) => {
    for (let i = 0; i < menuData.length; i++) {
      let _menu = setIconsMenu(menuData[i]);

      _menu.url !== null ? (
        (_menu.label = (
          <Link style={{ color: "#c9c9c9" }} to={_menu.url}>
            {_menu.label}
          </Link>
        ))
      ) : (
        <lable> {_menu.label}</lable>
      );

      if (_menu?.children?.length > 0) {
        renderMenu(_menu?.children);
      } else {
        delete _menu?.children;
      }
    }
    return menuData;
  };

  return isMobile ? (
    <Menu
      theme="dark"
      items={renderMenu(menuData)}
      inlineIndent={20}
      defaultSelectedKeys={["0"]}
      // triggerSubMenuAction="click"
      {...rest}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    />
  ) : (
    <Menu
      triggerSubMenuAction="click"
      theme="dark"
      items={renderMenu(menuData)}
      // triggerSubMenuAction="click"
      inlineIndent={20}
      defaultSelectedKeys={["0"]}
      {...rest}
      mode="horizontal"
    />
  );
};

export default SideMenu;
