import React, { useMemo, useContext, useEffect, useState } from "react";
import { Input } from "antd";
import { EditableTableContext } from "./editableTableBody";
const { Search } = Input;

const SearchEditCell = ({ value, onChange, onSearch, ...rest }) => {
  const { stopEditing, moveFocus } = useContext(EditableTableContext);
  // const prevValue = useMemo(() => value);

  const [prevValue] = useState(value);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      switch (e.keyCode) {
        case 27:
          
          onChange(prevValue);
          stopEditing();
          break;
        case 13:
          stopEditing();
          //  moveFocus("down");
          break;

      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);

    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);
  const handleOrginalValue = () => {
    
    onChange(prevValue);
    stopEditing();
  }
  const handleFocus = (event) => event.target.select();
  return (
    <Search
    size="small"
      autoFocus
      onBlur={handleOrginalValue}
      onFocus={handleFocus}
      onChange={onChange}
      onSearch={(e)=>{onSearch(e); onChange(prevValue);}}
      value={value}
      {...rest}
    />
  );
};

export default SearchEditCell;
