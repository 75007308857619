import React, { useMemo } from "react";
import DataGrid from "react-data-grid";
import _t from "../../../languages/translate";
import "./style/style.css";

const EditableGrid = ({
  innerRef,
  columns,
  dataSource,
  setDataSource,
  summary,
  ...rest
}) => {
  return (
    <DataGrid
      ref={innerRef}
      columns={columns}
      rows={dataSource}
      rowHeight={25}
      className="rdg-light"
      onRowsChange={setDataSource}
      defaultColumnOptions={{
        sortable: true,
        resizable: true,
      }}
      direction={_t("langDiriction")}
      summaryRows={summary}
      {...rest}
    />
  );
};
export default EditableGrid;
