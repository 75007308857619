
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Ent";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function initPage(Patt, Id) {
  return http.get(`${apiEndpoint}/?patt=${Patt}&id=${Id}`);
}

export function editEntry(obj) {
  
  if (obj.id !== "") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}


export function  uploadExcel(obj) {
  let formData = new FormData();
  formData.append("file", obj);
  return http.post(`${apiEndpoint}/ImportFromExcel`, formData);
}

export function getUnImportValidateExcel(obj) {
  return http.post(apiEndpoint + "/GetValidateExcel", obj);
}



export function getEntHtml(ent, patt, timeShift, lang, rowId, isReceipt) {
  return http.post(`${apiEndpoint}/EntHtml?ent=${ent}&patt=${patt}&timeShift=${timeShift}&Lang=${lang}&isReceipt=${isReceipt}&rowId=${rowId}`);
}


export function getDailyMoveHtml(patt, acc, date, timeShift, lang) {
  
  return http.post(`${apiEndpoint}/DailyMoveHtml?patt=${patt}&acc=${acc}&date=${date}&timeShift=${timeShift}&Lang=${lang}`);
}


export function saveDailyMove(obj) {

  return http.post(`${apiEndpoint}/SaveDailyMove`, obj);
}

export function deleteEntry(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}


export function browseNext(Patt, Id) {

  return http.post(`${apiEndpoint}/GetNext/?patt=${Patt}&id=${Id}`);
}

export function browsePrev(Patt, Id) {

  return http.post(`${apiEndpoint}/GetPrev/?patt=${Patt}&id=${Id}`);
}


export function savePostState(id, post) {
  return http.post(`${apiEndpoint}/SetPost/?id=${id}&post=${post}`);
}


export function deleteDailyMoveRow(patt,entGrpId) {
  return http.delete(`${apiEndpoint}/DeleteDailyMoveRow/?patt=${patt}&entGrpId=${entGrpId}`);
}

export function saveDailyMoveRow(obj, acc, curr, rate, patt, date, timeShift) {
  
  return http.post(`${apiEndpoint}/SaveDailyMoveRow/?acc=${acc}&curr=${curr}&rate=${rate}&patt=${patt}&date=${date}&timeShift=${timeShift}`, obj);
}


export function getDailyMove(patt, acc, curr, date, timeShift) {

  let editTime = date?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  let convDate = editTime.toISOString(true).slice(0, 16) + 'Z';

  return http.get(`${apiEndpoint}/GetDailyMove/?patt=${patt}&acc=${acc}&curr=${curr}&date=${convDate}&timeShift=${timeShift}`);
}

export function getByPattCode(Patt, pattCode) {

  return http.post(`${apiEndpoint}/GetByPattCode/?patt=${Patt}&pattCode=${pattCode}`);
}

export function getByDoc(Patt, doc) {

  return http.post(`${apiEndpoint}/GetByDoc/?patt=${Patt}&doc=${doc}`);
}

export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}

export function getEntry() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getDetailsByAcc(obj, date) {
  return http.post(`${apiEndpoint}/GetDetailsByAcc/${date}`, obj);
}

export function GetActiveChildren(obj) {
  return http.post(`${apiEndpoint}/GetActiveChildren`, obj);
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

export function getActiveParentsURL(search, addId) {
  const params = new URLSearchParams()
  search && params.append("search", search);
  //addId && params.append("addId", addId); 

  return `${apiEndpoint}/GetActiveChildrenTest/?${params.toString()}`;
}




export function getAllEntry() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewEntryCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerEntry(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}



export function deleteImage(Id, fileName) {

  return http.post(`${apiEndpoint}/DeleteImage?Id=${Id}&fileName=${fileName}`);
}

