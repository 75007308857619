import { emptString, emptValue } from "../../../../helper/helperMethods";
import {
  calcAndReturnList,
  reSumCostCenter,
} from "../../../../helper/Modal/costCenterModal/costCenterHelper";
import _t from "../../../../../languages/translate";

export function validateGrids(
  obj,
  pattren,
  mainDataSource,
  addsDataSource,
  discsDataSource
) {
  if (mainDataSource !== null) {
    let mainGrid = mainDataSource.filter((obj) =>
      Object.keys(obj).includes("itemId")
    );
    obj.details = mainGrid;
  }

  if (pattren?.invType !== 7 && pattren?.invType !== 0) {
    if (discsDataSource !== null) {
      let discGrid = discsDataSource.filter(
        (obj) =>
          Object.keys(obj).includes("accId") &&
          Object.keys(obj).includes("value") &&
          emptValue(obj.value, 0) !== 0
      );
      obj.invDiscs = discGrid;
    }

    if (addsDataSource !== null) {
      let addGrid = addsDataSource.filter(
        (obj) =>
          Object.keys(obj).includes("accId") &&
          Object.keys(obj).includes("value") &&
          emptValue(obj.value, 0) !== 0
      );
      obj.invAdds = addGrid;
    }
  } else {
    delete obj.invDiscs;
    delete obj.invAdds;
  }

  if (obj.invAddTotal === undefined) {
    obj.invAdds = [];
  }
  if (obj.invDiscTotal === undefined) {
    obj.invDiscs = [];
  }
  return obj;
}

export function valCostCenter(rowObj, arrName, pattSettReq) {
  const rowNum = rowObj.key + 1;

  
  if(rowObj?.costCenter_Maps?.length > 0){
    const obj = calcAndReturnList( rowObj?.value, rowObj?.costCenter_Maps, rowObj?.costCenterReqOpt, false);
    if (obj.state === "required") {
      return `${ arrName + "-" + _t("strInsertValueIsRequired") + " " +  _t("strRowNum") + rowNum }`;
    } else if (obj.state === "distribution") {
      return `${ arrName +  "-" + _t("strFullValueDistributionIsRequired") + " " + _t("strRowNum") + rowNum }`;
    }
  }else{
    if(emptString(rowObj?.accId,false) !==false && emptValue(rowObj?.value,0) !== 0 && pattSettReq === true ){
      return `${ arrName + "-" + _t("strInsertValueIsRequired") + " " +  _t("strRowNum") + rowNum }`;
    }
  }
}


export function saveValidate(
  obj,
  pattren,
  clientCostMessage,
  invCostMessage,
  itemDiscCostMessage,
  itemAddCostMessage,
  payCostMessage
) {

  if (emptString(clientCostMessage, false)) {
    return {
      status: false,
      message: `(${_t("strCostCenterClient")})  ${clientCostMessage}`,
    };
  }
  if (emptString(invCostMessage, false)) {
    return {
      status: false,
      message: `(${_t("strCostCenterInv")})  ${invCostMessage}`,
    };
  }
  if (emptString(itemDiscCostMessage, false)) {
    return {
      status: false,
      message: `(${_t("strCostCenterItemDisc")})  ${itemDiscCostMessage}`,
    };
  }
  if (emptString(itemAddCostMessage, false)) {
    return {
      status: false,
      message: `(${_t("strCostCenterItemAdd")})  ${itemAddCostMessage}`,
    };
  }
  if (emptString(payCostMessage, false)) {
    return {
      status: false,
      message: `(${_t("strCostCenterPayAcc")})  ${payCostMessage}`,
    };
  }

  // if (pattren?.invType !== 0 && pattren?.invType !== 7) {
    
  if (pattren?.genEnt === true) {
    if (obj?.netTotal !== obj?.payValue && obj?.clientAccId === undefined)
    
      return {
        status: false,
        message:
          pattren?.invType !== 1 && pattren?.invType !== 2
            ? _t("msgClientReq")
            : _t("msgSupplierReq"),
      };
  }

  if ((emptString(obj?.invAccId, null) !==null && emptString(obj?.clientAccId, null) !==null) && (emptString(obj?.invAccId, null) === emptString(obj?.clientAccId, null)))
  return { status: false, message: _t("msgCannotSaveSameInvAccIdWithClientAccId") };// لايمكن حفظ الفاتورة بسبب تشابه حساب العميل و حساب الفاتورة



  if (pattren?.clientAccReq && emptString(obj?.clientAccId, null) === null)
    return { status: false, message: _t("strCustomerAccReq") };

  if (pattren?.clientReq && emptString(obj?.clientId, null) === null)
    return { status: false, message: _t("strCustomerReq") };

  if (pattren?.docReq && emptString(obj?.doc, null) === null)
    return { status: false, message: _t("strDocReq") };

  if (pattren?.dueDateReq && emptString(obj?.dueDate, null) === null)
    return { status: false, message: _t("strDueDateReq") };

  if (pattren?.invAccReq && emptString(obj?.invAccId, null) === null)
    return { status: false, message: _t("strInvAccReq") };

  if (pattren?.payMethodReq && emptString(obj?.payMethodId, null) === null)
    return { status: false, message: _t("strPayMethReq") };

  if (pattren?.toStoreReq && emptString(obj?.storeId, null) === null)
    return { status: false, message: _t("strStoreReq") };

  if (obj.storeId === undefined && obj.toStoreId === undefined)
    return { status: false, message: _t("msgMustSelectStore") };

  if (obj.storeId === obj.toStoreId)
    return { status: false, message: _t("strSameStoreError") };

  if (obj.netTotal < 0)
    return { status: false, message: _t("msgInvNetTotalMinZero") };

  if (emptValue(obj?.payValue,0) !== 0 && emptString(obj?.payMethodId, undefined) === undefined
  )
    return { status: false, message: _t("msgPayValuePayMethodEmpty") };

  if (obj.date !== "") obj.date = obj.date?.toISOString().slice(0, 16) + "Z";

  for (let i = 0; i < obj.details.length; i++) {
    if (
      emptString(obj.details[i].itemId, true) === true ||
      emptString(obj.details[i].itemUnit_MapId, true) === true ||
      emptValue(obj.details[i].qty, true) === true
    ) {
      return {
        status: false,
        message: emptValue(obj.details[i].qty, true)
          ? _t("msgQtyCellReq") + " " + (i + 1)
          : emptString(obj.details[i].itemUnit_MapId, true)
          ? _t("msgUnitCellReq") + " " + (i + 1)
          : emptString(obj.details[i].itemId, true)
          ? _t("msgItemCellReq") + " " + (i + 1)
          : _t("msgInsertErrorByRowNum") + " " + (i + 1),
      };
    }
  }

  return { status: true, message: "done" };
}
