import { emptValue, emptString } from "../../../../../helper/helperMethods";
import { handleEx } from "../../../../../helper/handleException";
import { getPriceByUnit } from "../../../../../../services/priceListService";
import {
  calcQty,
  calcPrice,
  calcTotal,
  calcEmptyDiscPec,
  calcDiscValue,
  calcEmptyAddPec,
  calcAddValue,
  calcSubTotal,
  calcEmptyLostPec,
  calcLostQty,
  calcEmptyFreePec,
  calcFreeQty,
} from "./calcColumns";

export function handleQty(p, e, obj) {
  obj = p.row;
  obj.qty = emptValue(e.target.value, null);
  if (emptValue(obj.price, 0) === 0) obj = calcPrice(obj);
  else obj = calcTotal(obj);
  if (emptValue(obj.lostPerc, 0) !== 0) obj = calcLostQty(obj);
  if (emptValue(obj.freePerc, 0) !== 0) obj = calcFreeQty(obj);
  return obj;
}

export function handlePrice(p, e, obj) {
  obj = p.row;
  obj.price = emptValue(e.target.value, null);
  if (emptValue(obj.qty, 0) === 0) obj = calcQty(obj);
  else 
  obj = calcTotal(obj);
  if (emptValue(obj.lostPerc, 0) !== 0) obj = calcLostQty(obj);
  if (emptValue(obj.freePerc, 0) !== 0) obj = calcFreeQty(obj);
  return obj;
}

export function handleTotal(p, e, obj) {
  obj = p.row;
  obj.total = emptValue(e.target.value, null);
  obj = calcQty(obj);
  obj = calcPrice(obj);
  if (emptValue(obj.discPerc, 0) !== 0) obj = calcDiscValue(obj);
  if (emptValue(obj.addPerc, 0) !== 0) obj = calcAddValue(obj);
  if (emptValue(obj.lostPerc, 0) !== 0) obj = calcLostQty(obj);
  if (emptValue(obj.freePerc, 0) !== 0) obj = calcFreeQty(obj);
  obj = calcSubTotal(obj);
  return obj;
}

export function handleDiscVal(p, e, obj) {
  if (p?.row?.discValue !== emptValue(e.target.value, null)) {
    obj = p.row;
    obj.discValue = emptValue(e.target.value, null);
    obj = calcEmptyDiscPec(obj);
    obj = calcSubTotal(obj);
    return obj;
  }
}

export function handleDiscPerc(p, e, obj) {
  obj = p.row;
  obj.discPerc = emptValue(e.target.value, null);
  obj = calcDiscValue(obj);
  return obj;
}

export function handleAddVal(p, e, obj) {
  if (p?.row?.addValue !== emptValue(e.target.value, null)) {
    obj = p.row;
    obj.addValue = emptValue(e.target.value, null);
    obj = calcEmptyAddPec(obj);
    obj = calcSubTotal(obj);
    return obj;
  }
}

export function handleAddPerc(p, e, obj) {
  obj = p.row;
  obj.addPerc = emptValue(e.target.value, null);
  obj = calcAddValue(obj);
  return obj;
}

export function handleLostPerc(p, e, obj) {
    obj = p.row;
    obj.lostPerc = emptValue(e.target.value, null);
    obj = calcLostQty(obj);
  return obj;
}

export function handleLostQty(p, e, obj) {
  if (p?.row?.lostQty !== emptValue(e.target.value, null)) {
    obj = p.row;
    obj.lostQty = emptValue(e.target.value, null);
    obj = calcEmptyLostPec(obj);
    return obj;
  }
}

export function handleLostItemUnit(p, e, obj) {
  obj = p.row;
  obj.lostItemUnit_MapId = emptString(e, null);
  return obj;
}

export function handleFreePerc(p, e, obj) {
  obj = p.row;
  obj.freePerc = emptValue(e.target.value, null);
  obj = calcFreeQty(obj);
  return obj;
}

export function handleFreeQty(p, e, obj) {
  if (p?.row?.freeQty !== emptValue(e.target.value, null)) {
    obj = p.row;
    
    obj.freeQty = emptValue(e?.target?.value, null);
    obj = calcEmptyFreePec(obj);
    return obj;
  }
}

export function handleFreeItemUnit(p, e, obj) {
  obj = p.row;
  obj.freeItemUnit_MapId = emptString(e, null);
  return obj;
}

export function handleNote(p, e, obj) {
  obj = p.row;
  obj.note = emptString(e, null);
  return obj;
}

export async function handleItemUnit(p, e, obj, date, priceMethod, rate) {
  let result = await handlePriceByUnit(e, date, priceMethod, rate);
  obj = p.row;
  obj.price = emptValue(result, emptValue(obj.price, null));
  obj.itemUnit_MapId = emptString(e, null);
  if (emptValue(obj.qty, 0) === 0) obj = calcQty(obj);
  else obj = calcTotal(obj);
  return obj;
}

async function handlePriceByUnit(_iU, date, priceMethod, rate) {
  let itemUnit = _iU !== null && _iU !== undefined ? _iU : null;
  if (itemUnit !== null) {
    try {
      const { data: result } = await getPriceByUnit(
        itemUnit,
        date,
        priceMethod,
        rate
      );
      return result?.data;
    } catch (error) {
      handleEx(error);
    }
  }
}
