import React from "react";
import { Form, Select, Space, Tooltip } from "antd";
import SelectSingle from "../../../../helper/selectSingle";
import NumericInput from "../Inputs/NumericInput";
import {
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  BorderTopOutlined,
  BorderBottomOutlined,
  BorderRightOutlined,
  BorderLeftOutlined,
} from "@ant-design/icons";
import _t from "../../../../../languages/translate";
const { Option } = Select;

const InputSpacing = ({
  inpType = "space",
  typeName,
  topInputName,
  bottomInputName,
  rightInputName,
  leftInputName,
  disabled =false
}) => {
  return (
    <Space.Compact size="small" style={{ width: "100%" }}>
      <Form.Item noStyle name={topInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strFromTop")}>
              {inpType === "space" ? (
                <VerticalAlignTopOutlined
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              ) : (
                <BorderTopOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              )}
            </Tooltip>
          }
          placeholder={_t("strFromTop")}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item noStyle name={rightInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strFromRight")}>
              {inpType === "space" ? (
                <VerticalAlignTopOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                    transform: "rotate(90deg)",
                  }}
                />
              ) : (
                <BorderRightOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              )}
            </Tooltip>
          }
          placeholder={_t("strFromRight")}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item noStyle name={bottomInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strFromBottom")}>
              {inpType === "space" ? (
                <VerticalAlignBottomOutlined
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              ) : (
                <BorderBottomOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              )}
            </Tooltip>
          }
          placeholder={_t("strFromBottom")}
          disabled={disabled}
        />
      </Form.Item>  
      <Form.Item noStyle name={leftInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strFromLeft")}>
              {inpType === "space" ? (
                <VerticalAlignTopOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                    transform: "rotate(-90deg)",
                  }}
                />
              ) : (
                <BorderLeftOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              )}
            </Tooltip>
          }
          placeholder={_t("strFromLeft")}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item noStyle name={typeName}>
        <SelectSingle
        showArrow={false}
          allowClear={false}
          style={{ width: '30%' }}
          opt={[
            { id: "mm", name: "mm" },
            { id: "cm", name: "cm" },
            { id: "px", name: "px" },
            { id: "%", name: "%" },
          ]}
          disabled={disabled}
        />
      </Form.Item>
    </Space.Compact>
  );
};
export default InputSpacing;
