import { Select, Empty } from "antd";
import { useEffect, useState } from "react";
import { handleEx } from "../../../../helper/handleException";
import { getClientActiveSearch } from "../../../../../services/clientService";
import SelectSingle from "../../../../helper/selectSingle";
import { emptString } from "../../../../helper/helperMethods";

const SelectClientAccBy = ({
  value,
  onChange,
  placeholder = "",
  addId = "",
  accId = "",
  disabled = false,
}) => {
  const [list, setList] = useState([]);

  const handleSearch = async (e) => {

    let _addIds =[];
    let _check = emptString(value,undefined);
    _check !== undefined? _addIds = [_check] : _addIds =  []
   
    try {
      const { data: responce } = await getClientActiveSearch({
        search: emptString(e,""),
        addIds: _addIds,
        accId: emptString(accId,""),
      });
      
      setList(responce?.data !== undefined ? responce?.data : []);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    handleSearch("");
  }, [accId]);

  return (
    
    <SelectSingle
    value={value}
    onChange={onChange}
   // onSearch={handleSearch}
    placeholder={placeholder}
    disabled={disabled}
    allowClear={true}
    fieldNames={{
      label: "name",
      value: "id",
    }}
    opt={list}
    />
  );
};

export default SelectClientAccBy;

    // <Select
    //   value={value}
    //   onChange={onChange}
    //   allowClear
    //   showSearch
    //   onSearch={handleSearch}
    //   optionFilterProp="children"
    //   notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    //   placeholder={placeholder}
    //   size="small"
    //   disabled={disabled}
    // >
    //   {opt}
    // </Select>