
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/EntPattSett";

export function getEntPatt(query) {
  return http.post(`${apiEndpoint}/GetTreeRep?search=${query}`);
}

export function initiale(id) {
  return http.post(`${apiEndpoint}/Initiale?entPatt=${id}`);
}

export function getNewCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function editEntPattSett(obj) {
  
  if (obj.id !== "new" && obj.id !== undefined ) {
    return http.put(apiEndpoint, obj);
  } else {
   return http.post(apiEndpoint, obj);
 }
}

export function getEntPattSettById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}


export function getByEntPatt(Id) {
  return http.post(`${apiEndpoint}/GetByEntPatt?entPatt=${Id}`);
}

export function getByBranch(Id) {
  return http.post(`${apiEndpoint}/GetByBranch?branchId=${Id}`);
}



export function deleteEntPattSett(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}





