import { TextEditor } from "react-data-grid";
import SelectSingle from "../../selectSingle";
import _t from "../../../../languages/translate";

export function convertToSave(dataSource, tableName) {
  if (sessionStorage.getItem(tableName) !== undefined) {
    const columns = JSON.parse(sessionStorage.getItem(tableName));
    for (let i = 0; i < columns.length; i++) {
      columns[i].nameAr = dataSource[0][columns[i].key];
      columns[i].nameEn = dataSource[1][columns[i].key];
    }
    return columns;
  }
}
export function convertFromSeve(detailsColSett) {
  let data = [{}, {}];
  if (detailsColSett !== undefined) {
    let col = [];
    for (let i = 0; i < detailsColSett.length; i++) {
      let column = { ...detailsColSett[i] };
      column.editor = TextEditor;
      data[0][column.key] = column.nameAr;
      data[1][column.key] = column.nameEn;
      col.push(column);
    }
    //width
    return { dataSource: data, columns: col };
  }
}


//........... Columns Print Settings

export function convertToSavePT(dataSource, tableName) {
  if (sessionStorage.getItem(tableName) !== undefined) {
    const columns = JSON.parse(sessionStorage.getItem(tableName));

    for (let i = 0; i < columns.length; i++) {
      columns[i].nameAr = dataSource[0][columns[i].key];
      columns[i].nameEn = dataSource[1][columns[i].key];
      columns[i].style = dataSource[2][columns[i].key];
    }

    return columns;
  }
}

export function convertFromSevePT(detailsColSett) {
  let data = [{}, {}, {}];
  const opt = [
    { id: 0, name: _t("strColumnP"), filter1: _t("strColumnP") },
    { id: 1, name: _t("strRowP"), filter1: _t("strRowP") },
    { id: 2, name: _t("strRowAndCol"), filter1: _t("strRowAndCol") },
  ];
  if (detailsColSett !== undefined) {
    let col = [];
    for (let i = 0; i < detailsColSett.length; i++) {
      let column = { ...detailsColSett[i] };
      column.editor = (p) => {
        if (p.row.rowNum === 3) {
          return (
            <SelectSingle
              onChange={(e) => {
                p?.onRowChange({ ...p?.row, [column.key]: e }, true);
              }}
              style={{ width: "100%" }}
              bordered={false}
              opt={opt}
            />
          );
        } else {
          return (
            <TextEditor
              row={p.row}
              column={p.column}
              onRowChange={p.onRowChange}
              onClose={p.onClose}
            />
          );
        }
      };
      column.formatter = (p) => {
        if (p.row.rowNum === 3) {
          return opt.find((c) => c?.id === p?.row[column.key])?.name || null;
        } else {
          return p?.row[column.key];
        }
      };
      data[0][column.key] = column.nameAr;
      data[1][column.key] = column.nameEn;
      data[2][column.key] = column.style;
      col.push(column);
    }
    //width

    return { dataSource: data, columns: col };
  }
}
