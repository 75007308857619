
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Unit";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getItemUnitById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getUnitRep(key) {
  return http.get(`${apiEndpoint}/GetForRep?repKey=${key}`);
}


export function getActiveUnit(search, addId) {
  const params = new URLSearchParams()
  search && params.append("search", search);
  addId && params.append("addId", addId); 

 return `${apiEndpoint}/GetActive/?${params.toString()}`;
}


export function editItemUnit(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllUnit() {
  return http.get(`${apiEndpoint}`);
}
export function deleteItemUnit(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}



export function convertPriceByUnit(lostUnit, itemUnit, priceVal) {
  return http.get(
    `${apiEndpoint}/ConvertPriceByUnit?unitMap_DestId=${lostUnit}&unitMap_sourceId=${itemUnit}&value=${priceVal}`
  );
}

export function getPagerItemUnit(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewItemUnitCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
