
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/EntSource";



export function initValue(id) {
  return http.get(`${apiEndpoint}/?id=${id}`);
}



export function getByCode(journal, code) {

  return http.post(`${apiEndpoint}/GetByCode/?journal=${journal}&code=${code}`);
}

export function getEntSourceHtml(ent, timeShift, lang) {
  
  return http.post(process.env.REACT_APP_BASE_URL +`/api/Ent/EntHtml?ent=${ent}&timeShift=${timeShift}&Lang=${lang}`);
}

export function getByDoc(journal, doc) {

  return http.post(`${apiEndpoint}/GetByDoc/?journal=${journal}&doc=${doc}`);
}

export function browseNext(journal, Id) {

  return http.post(`${apiEndpoint}/GetNext/?journal=${journal}&id=${Id??""}`);
}

export function browsePrev(journal, Id) { 
  return http.post(`${apiEndpoint}/GetPrev/?journal=${journal}&id=${Id??""}`);
}

export function savePostState(id, post) { 
  return http.post(`${apiEndpoint}/SetPost/?id=${id}&post=${post}`);
}

