import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Form,
  message,
  Popconfirm,
  Dropdown,
  Menu,
  Card,
  Input,
  Tooltip,
} from "antd";
import { getRate } from "../../../../services/Items/currenciesRateService";
import { deleteDailyMoveRow } from "../../../../services/entryService";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";
import { handleEx } from "../../../helper/handleException";
import MunfModelSelector from "../../../helper/Modal/MunfModelSelector";
import MunfInOut from "../munfInOut";
import { getDetailsByMunfModel } from "../../../../services/munfOrderService";
import { getActivePriceMethodSearch } from "../../../../services/Items/pricingMethodsService";
import { useApi } from "../../../../services/useApi";
import { getSearchPopup } from "../../../../services/munfModelService";
import { getActiveStore } from "../../../../services/storeService";
import { emptValue, emptString } from "../../../helper/helperMethods";
import DropDownList from "../../../helper/dataGrid/source/DropDownList";
import CurrEditor from "../../../helper/dataGrid/source/CurrEditor";
import { TextEditor } from "react-data-grid";
import DataGrid from "react-data-grid";
import { getNumFormat } from "../../../helper/countOfZero";
import { _SumArr } from "../../../helper/handleMath";
import { calcRowAndMunf } from "../helper/generalCalc";
import { handelClxPriceMethod } from "../helper/reCalcPrice";

const ComplexDetails = ({
  auth,
  innerRef,
  pattrenSet,
  form,
  currOptTable,
  detail,
  setDetail,
  setLoadData,
  id,
  promptForm,
  noStyle,
  handleChangeRate,
  //currRate,
  date
}) => {
  const [searchMunfModelValue, setSearchMunfModelValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const [{ data, d, e }, f] = useApi(getActiveStore(), [null || null]);

  useEffect(() => {
    handlePriceMethodSearch();
  }, []);

  const handlePriceMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePriceMethodSearch("");
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  // #region ~Columns
  const allColumns = [
    {
      name: "#",
      key: "num",
      minWidth: 30,
      width: 43,
      formatter(p) {
        if (p?.row?.munfModelId)
          return (
            <Dropdown
              overlay={() => menu(p, p?.row?.key)}
              style={{ width: "100%" }}
              trigger={["click"]}
            >
              <label
              className="keyNum"
              style={{
                width: "100%",
                color: "#1d98f4",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {p?.row?.key + 1}
            </label>
            </Dropdown>
          );
        else
          return (
            <p
              style={{ width: "100%", marginBottom: 0, cursor: "not-allowed" }}
            >
              {p?.row?.key + 1}
            </p>
          );
      },
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{row.totalItems - 1}</strong>
        );
      },
    },

    {
      name: _t("strModel"),
      key: "munfModelName",
      width: 100,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            // defaultValue={p.row?.itemName}
            onPressEnter={(e) => munfModelSearch(e.target.value, p.row.key)}
          />
        );
      },
      editable: (row) => row?.rowType !== "read",
      summaryFormatter() {
        return <strong style={{ color: "#7e7e7e" }}>الإجمالي</strong>;
      },
    },

    {
      name: _t("strCount"),
      key: "count",
      width: 90,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              if (e.target.value !== null) {
                const result = await calcRowAndMunf(
                  p.row,
                  emptValue(e?.target?.value, 0)
                );
                p?.onRowChange(
                  {
                    ...p.row,
                    count: emptValue(e.target.value, null),
                    munfRawMaterials: result.munfRawMaterials,
                    munfMunfMaterials: result.munfMunfMaterials,
                  },
                  true
                );
              }
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.count}>
            {getNumFormat(p?.row?.count, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalCount}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalCount, 0)}
            </strong>
          </Tooltip>
        );
      },
    },

    {
      name: _t("strFromStore"),
      key: "fromStoreId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.fromStoreId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            p?.onRowChange({ ...p.row, fromStoreId: e }, true);
          }}
          options={data}
        />
      ),
      formatter(props) {
        const id = props?.row?.fromStoreId;
        let result = id ? data?.find((c) => c?.id === id)?.name : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },

    {
      name: _t("strToStore"),
      key: "toStoreId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.toStoreId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            p?.onRowChange({ ...p.row, toStoreId: e }, true);
          }}
          options={data}
        />
      ),
      formatter(props) {
        const id = props?.row?.toStoreId;
        let result = id ? data?.find((c) => c?.id === id)?.name : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },

    {
      name: _t("strCurrency"),
      key: "currId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.currId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            handleChangeCurr(p, e);
            if (pattrenSet?.entType !== 0) {
              promptForm();
            }
          }}
          options={currOptTable}
        />
      ),
      formatter(props) {
        const id = props?.row?.currId;
        let result = id ? currOptTable?.find((c) => c?.id === id)?.name : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },
    {
      name: _t("strRate"),
      key: "currRate",
      width: 90,

      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              if (
                emptValue(e.target.value, 0) !== 0 &&
                emptValue(e.target.value, 0) !== emptValue(p.row.currRate, 0)
              ) {
                p?.onRowChange(
                  { ...p.row, currRate: emptValue(e.target.value, null) },
                  true
                );
                handleChangeRate(p.row.currId,e.target.value)
              }


              // if (e.target.value !== null) {
              //   p?.onRowChange(
              //     { ...p.row, currRate: emptValue(e.target.value, null) },
              //     true
              //   );
              // }
            }}
            onClose={p.onClose}
          />
        );
      },

      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.currRate}>
            {getNumFormat(p?.row?.currRate, 3)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQty}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) =>
        row?.currId !== pattrenSet?.companyCurrId &&
        pattrenSet?.currChType !== 3 &&
        row?.rowType !== "read",
    },

    {
      name: _t("strPriceMethod"),
      key: "priceMethodId",
      width: 100,
      editor: (p) => (
        <DropDownList
          value={p.row?.priceMethodId}
          bordered={false}
          className="TextEditor"
          onChange={async (e) => {
            const result = await handelClxPriceMethod([p.row], date, e, p.row?.currRate);
            
            p?.onRowChange(
              {
                ...p.row,
                priceMethodId: e,
                munfMunfMaterials: result[0].munfMunfMaterials,
                munfRawMaterials: result[0].munfRawMaterials,
              },
              true
            );
          }}
          options={priceMethodOpt}
        />
      ),
      formatter(props) {
        const id = props?.row?.priceMethodId;

        let result = id
          ? priceMethodOpt?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read",
    },

    {
      key: "note",
      name: _t("strNote"),
      width: 170,
      editor: TextEditor,
    },
  ];
  // #endregion

  //#region ~Handel

  const handleChangeCurr = async (p, e) => {
    try {
      const data = await getRate(
        e,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      let val = data?.data?.data;
      p?.onRowChange({ ...p.row, currId: e, currRate: val }, true);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleDelete = (key) => {
    const res = detail.filter((item) => item.key !== key);
    sortDataKey(res);
  };

  const handleDeleteServRow = async (row) => {
    try {
      const { data: newData } = await deleteDailyMoveRow(row.entId);
      handleDelete(row.key);
      message.success(newData.message);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const groupingIds = async (arr) => {
    let _munfId = "";
    let list = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.munfModelId === undefined) continue;
      _munfId = "";
      _munfId = arr[i]?.munfModelId;
      list.push(_munfId);
    }
    const date = form.getFieldValue().date.toISOString().slice(0, 16) + "Z";
    const currId =  form.getFieldValue().currId === undefined ? "" : form.getFieldValue().currId;
    const currRate = form.getFieldValue().currRate.toString() === undefined ? "" : form.getFieldValue().currRate.toString();
    const priceMethodId = form.getFieldValue().priceMethodId === undefined ? "" : form.getFieldValue().priceMethodId;
    const fromStore =  form.getFieldValue().fromStore === undefined  ? "" : form.getFieldValue().fromStore;
    const toStore =  form.getFieldValue().toStore === undefined  ? "" : form.getFieldValue().toStore;

    try {
      const { data: data } = await getDetailsByMunfModel(
        list,
        date,
        id,
        emptString(currId, ""),
        currRate,
        emptString(priceMethodId, ""),
        emptString(fromStore, ""),
        emptString(toStore, "")
      );

      return data.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const handleMunfModelSelector = async (result) => {
    const servData = await groupingIds(result);
    appendAccSelector(servData);
  };

  //#endregion

  useEffect(() => {
    if (pattrenSet === null) {
      setColumns(allColumns);
    } else {
      setColumns([allColumns[0]]);
      pattrenSet?.detailsModelColSett.map((e) => fillColumns(e));
    }
  }, [
    pattrenSet?.detailsModelColSett,
    showModal,
    currOptTable,
    detail,
    data,
    priceMethodOpt,
  ]);

  const fillColumns = (tmpObj) => {
    allColumns.map((e) => {
      if (e.key === tmpObj.key) {
        e.name = tmpObj.name;
        e.width = tmpObj.width;
        setColumns((x) => [...x, e]);
      }
    });
  };

  const menu = (record, index) => (
    <Menu>
      {record?.row?.munfModelId !== undefined && pattrenSet?.modelVisible && (
        <>
          <Menu.Item key="0" icon={<EditOutlined style={{ fontSize: 15 }} />}>
            <a
              onClick={() => {
                setShowModalInOut(true);
                window.sessionStorage.setItem("showModal", true);
                setObjValue(record?.row);
                setObjRowNum(index);
              }}
            >
              {_t("strEdit")}
            </a>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}

      <Menu.Item
        key="1"
        danger
        icon={<DeleteOutlined style={{ fontSize: 15 }} />}
      >
        {record?.entId !== "00000000-0000-0000-0000-000000000000" &&
        record?.entId !== null &&
        record?.entId !== undefined ? (
          <Popconfirm
            title={_t("strSureToDelete")}
            onConfirm={() => handleDeleteServRow(record)}
          >
            <a>{_t("strDelete")}</a>
          </Popconfirm>
        ) : (
          <a
            onClick={() => {
              handleDelete(record?.row?.key);
            }}
          >
            {_t("strDelete")}
          </a>
        )}
      </Menu.Item>
    </Menu>
  );

  const munfModelSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getSearchPopup(value);

      if (result.data.length === 1) {
        insertSingleRec(result.data, key);
      } else if (result.data.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchMunfModelValue(value);
        setShowModal(true);
        window.sessionStorage.setItem("showModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = async (value, row) => {
    const servData = await groupingIds(value);
    if (servData !== undefined) {
      const arrData = detail;
      arrData[row].id = servData[0].id;
      arrData[row].key = servData[0].key;
      arrData[row].count = servData[0].count;
      arrData[row].currRate = servData[0].currRate;
      arrData[row].note = servData[0].note;
      arrData[row].munfModelId = servData[0].munfModelId;
      arrData[row].munfModelName = servData[0].munfModelName;
      arrData[row].fromStoreId = servData[0].fromStoreId;
      arrData[row].toStoreId = servData[0].toStoreId;
      arrData[row].currId = servData[0].currId;
      arrData[row].priceMethodId = servData[0].priceMethodId;
      arrData[row].munfMunfMaterials = servData[0].munfMunfMaterials;
      arrData[row].munfRawMaterials = servData[0].munfRawMaterials;
      debugger
      sortDataKey(arrData, row);
    }
  };

  const appendAccSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      const arrData = detail;
      let dataCollect;
      let tmpdata = arrData.find((x) => x.key === rowNumberEdit);
      if (tmpdata.munfModelId !== undefined) {
        dataCollect = arrData;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            memoData.fromStoreId = arrData[rowNumberEdit + i].fromStoreId;
            memoData.toStoreId = arrData[rowNumberEdit + i].toStoreId;
          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        dataCollect = [...arrData, ...memoData];
      }
      sortDataKey(dataCollect, null);
    }
    setTimeout(() => {
      setShowModal(false);
      window.sessionStorage.setItem("showModal", false);
    }, 0);
  };

  const sortDataKey = async (data) => {
    let list = [];
    let KeyNum = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].munfModelId !== undefined) {
        data[i] = await calcRowAndMunf(data[i], data[i]?.count);

        let obj = {};
        obj = data[i];
        obj["key"] = KeyNum;
        obj["fromStoreId"] = emptString(
          form.getFieldValue("fromStoreId"),
          data[i].fromStoreId
        );
        obj["toStoreId"] = emptString(
          form.getFieldValue("toStoreId"),
          data[i].toStoreId
        );
        list.push(obj);
        KeyNum++;
      }
    }
    list.push({ key: list.length });
    promptForm();
    setDetail(list);
  };

  const [showModalInOut, setShowModalInOut] = useState(false);
  const [objValue, setObjValue] = useState(null);
  const [objRowNum, setObjRowNum] = useState(0);

  const handleMunfInOut = (result) => {};

  const sumCount = (arr) => {
    return _SumArr(arr, "count");
  };

  const summaryRows = useMemo(() => {
    const summaryRow = [
      {
        id: "total_0",
        totalItems: detail.length,
        totalCount: sumCount(detail),
      },
    ];
    //form.setFieldsValue({rawTotal:sumTotal()})
    return summaryRow;
  }, [detail]);

  return (
    <>
      <MunfModelSelector
        searchValue={searchMunfModelValue}
        onHandelData={handleMunfModelSelector}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <MunfInOut
        auth={auth}
        form={form}
        detail={detail}
        setDetail={setDetail}
        rowObj={objValue}
        objRowNum={objRowNum}
        pattrenSet={pattrenSet}
        onHandelData={handleMunfInOut}
        showModal={showModalInOut}
        setShowModal={setShowModalInOut}
        promptForm={promptForm}
      />

      <Form.Item
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        label={_t("strTheOutput")}
        noStyle={noStyle}
      >
        <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 10 }}>
          <DataGrid
            ref={innerRef}
            columns={[...columns]}
            rows={detail}
            rowHeight={25}
            style={{
              height: `calc(100vh - 400px)`,
              width: "100%",
            }}
            className="rdg-light"
            onRowsChange={setDetail}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
            summaryRows={summaryRows}
          />
        </Card>
      </Form.Item>
    </>
  );
};
export default ComplexDetails;
