
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/UserAccRole";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getUserAccRoleById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getUserAccRoleRep(key) {
  return http.get(`${apiEndpoint}/GetForRep?repKey=${key}`);
}

export function GetActiveUserRole(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActive/?search=${search}&addId=${addId}`
  );
}

export function getActiveUserAccRole(search, addId) {
  const params = new URLSearchParams()
  search && params.append("search", search);
  addId && params.append("addId", addId); 

 return `${apiEndpoint}/GetActive/?${params.toString()}`;
}


export function editUserAccRole(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllUserAccRole() {
  return http.get(`${apiEndpoint}`);
}
export function deleteUserAccRole(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerUserAccRole(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}


export function getInitiale() {
  return http.post(`${apiEndpoint}/Initiale`);
}

