import { getDetailsByUnits } from "../../../../../services/invoiceService";
import { handleEx } from "../../../../helper/handleException";
import { emptValue } from "../../../../helper/helperMethods";
import { calcQty, calcTotal } from "../MainGrid/handleColumns/calcColumns";
export const reCalcItemsPrice = async (arr, date, priceMetodId, currRate) => {
  let list = [];
  let finalArray = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.itemUnit_MapId === undefined) continue;
    let id = "";
    id = arr[i]?.itemUnit_MapId;
    list.push(id);
  }

  try {
    const { data: newData } = await getDetailsByUnits(
      list,
      date,
      priceMetodId,
      currRate
    );

    for (let y = 0; y < arr.length; y++) {
      let tmpItem = newData.data.filter(
        (x) => x.itemUnit_MapId === arr[y].itemUnit_MapId
      )[0];
      if (tmpItem != undefined && tmpItem != null) {
        arr[y].price = tmpItem.price;
        if (emptValue(arr[y].qty, 0) === 0) arr[y] = calcQty(arr[y]);
        else arr[y] = calcTotal(arr[y]);
      }
    }

    return arr;
  } catch (error) {
    handleEx(error);
  }
};
