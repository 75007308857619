import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button,Tag, Modal } from "antd";
import DataGrid, { SelectColumn } from "react-data-grid";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { shortCut } from "../../helper/shortCuts";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import Draggable from "react-draggable";

const CurrencySelector = ({
  onHandelData,
  searchValue,
  showModal,
  setShowModal,
  selectedItems,
  mainItemId,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);
  const gridRef = useRef(null);
  const refCurrSearch = useRef(null);
  const { Search } = Input;

  const columns = [
    SelectColumn,
    {
      name: _t("strName"),
      key: "currName",
      width: "100%",
    },
  ];

  useEffect(() => {
    if (showModal) gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    else gridRef?.current?.selectCell({ idx: null, rowIdx: null });
  }, [showModal]);

  const searchData = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      const listDeleteIds = selectedItems.map((s) => s.currId);

      for (var i = 0; i < result.data.length; i++) {
        var obj = result.data[i];
        if (listDeleteIds.indexOf(obj.currId) !== -1) {
          result.data.splice(i, 1);
          i--;
        }
      }
      if (mainItemId !== "") {
        var index = result.data
          .map((x) => {
            return x.currId;
          })
          .indexOf(mainItemId);
        result.data.splice(index, 1);
      }
      setDataSource(result.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
      refCurrSearch?.current?.blur();
      gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    }
  };

  useEffect(() => {
    setLoadData(true);
    popupForm.setFieldsValue({ search: searchValue });
    searchData(searchValue);
  }, [searchValue, selectedItems, mainItemId]);

  const handleClear = () => {

    setLoadData(true);
    setTimeout(() => {
      setSelectedRows();
      setLoadData(false);
    }, 50);
  };

  const onFinish = () => {
    if (selectedRows !== undefined) {
    let array = [];
    selectedRows?.forEach((x) => x == array?.push(x));
    const result = dataSource.filter((c) => array.some((s) => s === c.currId));
    onHandelData(result);
    }
  };



  const [altPress, setAltPress] = useState(false);
  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem("showModal"))) {
        switch (shortCut(e)) {
          case "insert":
            popupForm.submit();
            break;
          case "search":
            refCurrSearch?.current?.focus();
            refCurrSearch?.current?.select();
            break;
          case "block1":
            setTimeout(function () {
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "tag":
            if (!!gridRef.current)
              gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
            setAltPress(true);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const [disabled, setDisabled] = useState(true);
  return (
    <Modal
    centered
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strCurrencies")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        window.sessionStorage.setItem("showModal", false);
      }}
      onOk={() => popupForm.submit()}
      afterClose={() => handleClear()}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => popupForm.submit()}
          disabled={selectedRows?.size === 0 || selectedRows === undefined}
        >
          <div>
            {_t("strInsert")}
            <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + Enter
              </Tag>
            </div>
          </div>
        </Button>,
      ]}
    >
      <Form form={popupForm} onFinish={onFinish}>
        <div>
        <Form.Item name="search">
          <Search
            ref={refCurrSearch}
            onSearch={searchData}
            placeholder={_t("strSearch")}
            size="small"
            style={{ width: 200, marginBottom: 25 }}
          />
        </Form.Item>
        <div
            style={{
              opacity: 0.8,
              zIndex: 99,
              position: "absolute",
              top: 100,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + F
            </Tag>
          </div>
        </div>
        <Form.Item>
          <div>
          <DataGrid
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => setSelectedRows(e)}
            ref={gridRef}
            style={{ height: `calc(100vh - 300px)`, width: "100%" }}
            columns={columns}
            rowHeight={25}
            className="rdg-light"
            rowKeyGetter={(row) => row?.currId}
            rows={dataSource}
            onRowsChange={setDataSource}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
          />
              <div
              style={{
                opacity: 0.8,
                position: "absolute",
                top: 24,
              }}
            >
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + 1
              </Tag>
            </div>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CurrencySelector;
