import {
  calcLostQty,
  calcLostTotal_new,
  calcEmptyLostPec,
  calcLostQtyByPerc,
  calcQty,
  calcQtyTotal,
  calcQtyTotalByCount,
  calcPrice,
  calcLostPrice,
  calcTotal_new,
  setLostPrice,
  calcNetTotal,
  getItemPriceByUnit,
  handleItemLostUnitFn,
  handleLostPrice_newFn,
} from "./calcColumns";
import { emptValue } from "../../../../helper/helperMethods";
import { _Mul, _Sum, _Div, _RateMul } from "../../../../helper/handleMath";
import { handleEx } from "../../../../helper/handleException";

export const handleQty = async (p, e, obj, rest) => {
  obj = p.row;
  obj.qty = emptValue(e.target.value, null);
  obj = await calcQtyTotalByCount(obj, rest.count);
  if (emptValue(obj.price, 0) === 0) obj = calcPrice(obj);
  else obj = calcTotal_new(obj);
  if (emptValue(obj.lostPerc, 0) !== 0) {
    obj = calcLostQtyByPerc(obj,
        rest?.date,
        rest?.priceMethod,
        rest?.rate);
  }
  return obj;
};

export const handlePrice = async (p, e, obj) => {
  obj = p?.row;
  obj.price = emptValue(e?.target?.value, null);
  return await handlePriceFn(obj);
};

export const handlePriceFn = async (obj, rest) => {
  if (emptValue(obj?.qty, 0) === 0) obj = await calcQtyTotal(obj, rest?.count);
  else obj = await calcTotal_new(obj);
  obj = await setLostPrice(obj);
  debugger
  return obj;
};

export const handleTotal = async (p, e, obj, rest) => {
  obj = p.row;
  obj.total = emptValue(e.target.value, null);
  obj = await calcQtyTotal(obj, rest.count);
  obj = calcPrice(obj);
  obj = calcNetTotal(obj);
  return obj;
};

export const handleLostPerc = async (p, e, obj, rest) => {
  
  obj = p.row;
  obj.lostPerc = emptValue(e.target.value, null);
  obj = await calcLostQtyByPerc(obj,
    rest?.date,
    rest?.priceMethod,
    rest?.rate);
    
  return obj;
};

export const handleLostQty = async (p, e, obj, rest) => {
  if (p?.row?.lostQty !== emptValue(e.target.value, null)) {
    obj = p.row;
    obj.lostQty = emptValue(e.target.value, null);
    obj = calcEmptyLostPec(obj);
    if (emptValue(obj.lostPrice, null) == null) {
      obj = await handleItemLostUnitFn(obj,
        rest?.date,
        rest?.priceMethod,
        rest?.rate);
    }
    obj = calcLostTotal_new(obj);
    return obj;
  }
};

export const handleLostPrice = async(p, e, obj) => {
  
  obj = p.row;
  obj.lostPrice = emptValue(e.target.value, null);
  
  return await handleLostPrice_newFn(obj);
};

export const handleLostTotal = async(p, e, obj) => {
  obj = p.row;
  obj.lostPriceTotal = emptValue(e.target.value, null);
  obj = await calcLostQty(obj);
  obj = await calcLostPrice(obj);
  obj = await calcNetTotal(obj);
  return obj;
};

export const handleQtyTotal = async(p, e, obj, rest) => {
  obj = p.row;
  obj.qtyTotal = emptValue(e.target.value, null);
  

  obj = await calcQty(obj, rest.count);
  
  obj = await calcTotal_new(obj);
  

  if (emptValue(obj.lostPerc, 0) !== 0) {
    obj = await calcLostQtyByPerc(obj,
        rest?.date,
        rest?.priceMethod,
        rest?.rate);
  }
  return obj;
};

export const handleItemLostUnit = async (p, e, obj, rest) => {
  try {
    obj = p.row;
    obj.lostItemUnit_MapId = e;
    return await handleItemLostUnitFn(obj,
        rest?.date,
        rest?.priceMethod,
        rest?.rate);
  } catch (error) {
    handleEx(error);
  }
};

export const handleItemUnit = async (p, e, obj, rest) => {
  try {
    obj = p.row;
    obj.itemUnit_MapId = e;
    let priceVal = await getItemPriceByUnit(
      obj.itemUnit_MapId,
      rest?.date,
      rest?.priceMethod,
      rest?.rate
    );

    obj.price = priceVal;
    return await handlePriceFn(obj,rest);
  } catch (error) {
    handleEx(error);
  }
};
