import React, { useState, useEffect } from "react";
import { Table, Menu, Dropdown, Divider, Button } from 'antd';
import { DownOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";

const TreeTableSelect = ({onSetChange, onHandelData, columns, dataSource, ...rest }) => {
  const [nextColumns, setNextColumns] = useState(columns);
  const [data, setData] = useState(dataSource);

  const menuRow = [
    <>
      <Menu.Item key="1">{_t("strRowSelection")}</Menu.Item>
      <Divider className="ak-divider" dashed />
      <Menu.Item key="2">{_t("strRowCancellation")}</Menu.Item>
      <Divider className="ak-divider" dashed />

    </>
  ]

  const menuAll = [
    <>
      <Menu.Item key="3">{_t("strSelectAll")}</Menu.Item>
      <Divider className="ak-divider" dashed />
      <Menu.Item key="4">{_t("strCancelAll")}</Menu.Item>
    </>
  ]

  useEffect(()=>{
    setNextColumns( nextColumns.filter(i => i.type === "checkbox" || i.type === "checktitle" ?
    i.title = <Dropdown overlay={<Menu onClick={(e) => handleCol(e, i,null)}>{menuAll}</Menu>} trigger={['click']}>
      <a style={{ width: "100%" }} className="ant-dropdown-link">{i.title} </a></Dropdown> : i.title = i.title))
  },[columns])

  useEffect(() => {
    setNextColumns(nextColumns.filter(i => i.type === "checkbox" ?
      i["render"] = (v, r) => v === true ? <Dropdown overlay={<Menu onClick={(e) => handleCol(e, i, r)}>{r.children != null && r.children.length > 0 ? menuAll : null}</Menu>}
        trigger={['contextMenu']}><Button type="link" style={{ width: "100%", textAlign: "start" }} onClick={() => handleCell(v, i.key, r)}>
          <CheckOutlined style={{ color: 'green', fontSize: 15 }} />
        </Button></Dropdown>
        : <Dropdown overlay={<Menu onClick={(e) => handleCol(e, i, r)} >{r.children != null && r.children.length > 0 ? menuAll : null}</Menu>}
          trigger={['contextMenu']}><Button type="link" style={{ width: "100%", textAlign: "start" }} onClick={() => handleCell(v, i.key, r)} >
            <CloseOutlined style={{ color: 'red', fontSize: 15 }} />
          </Button></Dropdown> :

      i["render"] = (v, r) =>
        <Dropdown overlay={<Menu onClick={(e) => handleMenu(r, e, i)}>{menuRow} {r.children != null && r.children.length > 0 ? menuAll : null}</Menu>} trigger={['click']}>
          <a style={{ width: "100%" }} className="ant-dropdown-link"> {v} <DownOutlined /></a>
        </Dropdown>

    ))
  }, [data]);

  const handleCell = (value, item, row) => {
    row[item] = !value;
    setData([...data]);
  }

  const handleMenu = (row, event, item) => {
    if (event.key === "1" || event.key === "2")
      handleDynamic(row, null, event.key, item.key)
    else if (event.key === "3" || event.key === "4") {
      let tmpNum = "0";
      if (event.key == "3")
        tmpNum = "1";
      else tmpNum = "2";
      handleDynamic(row, null, tmpNum, item.key)
      for (let i in row.children) {
        handleDynamic(row.children[i], null, tmpNum)
        renderRow(row.children[i], tmpNum);
      }
    }
    setData([...data]);
  }

  const renderRow = (node, event) => {
    for (let i in node.children) {
      handleDynamic(node.children[i], null, event)
      if (node.children[i].children != null && node.children[i].children.length > 0) {
        renderRow(node.children[i], event);
      }
    }
  }

  const handleDynamic = (node, checked, num, name) => {
    switch (num) {
      case "1": // تحديد الصف
        for (let i in node) { if (typeof node[i] == "boolean") { node[i] = true; } }
        break;
      case "2": // إلغاء الصف
        for (let i in node) { if (typeof node[i] == "boolean") { node[i] = false; } }
        break;
      default:
        break;
    }
  }

  const handleCol = (event, item, row) => {

    if (event.key === "3" && item.key !== "name") {
      renderCol(row !== null ? [row] : data, true, item.key);
    }
    else if (event.key === "3" && item.key === "name") {
      for (let i in nextColumns) {
        renderCol(data, true, nextColumns[i].type === "checkbox" ? nextColumns[i].key : null);
      }
    }

    if (event.key === "4" && item.key !== "name") {
      renderCol(row !== null ? [row] : data, false, item.key);
    }
    else if (event.key === "4" && item.key === "name") {
      for (let i in nextColumns) {
        renderCol(data, false, nextColumns[i].type === "checkbox" ? nextColumns[i].key : null);
      }
    }

    setData([...data]);
  }

  const renderCol = (list, checked, itemName) => {
    for (let i in list) {
      renderCell(list[i], checked, itemName)
    }
  }

  const renderCell = (node, checked, itemName) => {
    node[itemName] = checked;
    for (let i in node.children) {
      node.children[i][itemName] = checked;
      if (node.children[i].children != null && node.children[i].children.length > 0) {
        renderCell(node.children[i], checked, itemName);
      }
    }
  }

  const customExpandIcon = (props) => {
    if (props.record.children.length < 1) {
      return <span></span>;
    }
    if (props.expanded) {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    } else {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
  };
  return (
    <Table
      size="small"     
      pagination={false}
      bordered={true}
      {...rest}
      columns={nextColumns}
      expandIcon={(treeBran) => customExpandIcon(treeBran)}
      dataSource={data}
      rowClassName={(record, index) =>
        record.rowType === "parent"
          ? "treeMain-row"
          : record.rowType === "child"
            ? "treeDetails-row"
            : index % 2 === 0
              ? "table-row-light"
              : "table-row-dark"
      }
    />
  );
};

export default TreeTableSelect;
