import { useState, useEffect, useMemo } from "react";
import { Row, Col, Spin } from "antd";
import { DndProvider } from "react-dnd";
import { SwapOutlined } from "@ant-design/icons";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableHeaderRenderer from "./components/DraggableHeaderRenderer";
import DataGrid from "react-data-grid";
import VisibleCol from "./components/visibleCol";
import _t from "../../../languages/translate";
import TableEmpty from "./components/tableEmpty";
import { getTableSetting } from "../../../services/tabeSettService";
import { handleEx } from "../handleException";

const SettTable = ({
  rows = [],
  setRows,
  tableName = "",
  columns = [],
  showCustomTable = true,
  cIndexExp = 1,
  frezzColExp = false,
  currentData,
  loading = false,
  ...rest
}) => {
  const [renderColumns, setRenderColumns] = useState(columns);
  const langColumns = [
    {
      key: "rowNum",
      name: (
        <p style={{ textAlign: "center" }}>
          <SwapOutlined style={{ fontSize: 16 }} />
        </p>
      ),
      minWidth: 55,
      width: 55,
      maxWidth: 55,
      formatter: (p) => {
        return (
          <p
            style={{
              fontWeight: "bold",
              fontSize: 12,
              color: "black",
              textAlign: "center",
            }}
          >
            {p?.row?.rowNum === 1 ? "عربي" : p?.row?.rowNum === 2 ?  "English" : "الشكل" }
          </p>
        );
      },
    },
  ];

  //   const swapElements = (array, index1, index2) => {

  //    return array[index1] = array.splice(index2, 1, array[index1])[0];
  // };

  const toggleCol = (colKey, visible) => {
    let nextCols = [];
    if (colKey === "all") {
      nextCols = [...renderColumns].map((c) => (c = { ...c, visible }));
    } else {
      nextCols = [...renderColumns].map((c) =>
        c.key === colKey ? { ...c, visible } : c
      );

      //  var indexCol = [...renderColumns].map(function(e) { return e.key; }).indexOf(colKey);

      // swapElements(nextCols, nextCols.length-1, 0);
      // swapElements(nextCols, nextCols.length-1,index);

      //  nextCols = nextCols.sort((a, b) => {
      //   if (a.visible === b.visible) {
      //     return 0;
      //   }

      //   return a.visible ? -1 : 1;
      // });
    }

    setRenderColumns([...nextCols]);
  };

  const draggableColumns = useMemo(() => {
    const HeaderRenderer = (props) => {
      return (
        <DraggableHeaderRenderer
          {...props}
          onColumnsReorder={handleColumnsReorder}
        />
      );
    };

    const handleColumnsReorder = (sourceKey, targetKey) => {
      const sourceColumnIndex = renderColumns.findIndex(
        (c) => c.key === sourceKey
      );
      const targetColumnIndex = renderColumns.findIndex(
        (c) => c.key === targetKey
      );
      const reorderedColumns = [...renderColumns];
      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );
      setRenderColumns([...reorderedColumns]);
    };

    return renderColumns.map((c, i) => {
      if (frezzColExp === true && i === cIndexExp) return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [renderColumns]);

  const filteredCols = () => {
    sessionStorage.setItem(tableName, JSON.stringify(draggableColumns));
    const result = draggableColumns.filter(
      (c) => typeof c.visible !== "boolean" || c.visible
    );

    return [...langColumns, ...result];
  };

  // resize start ~~~~~~~~~~~~~~~~~~~~~~~~

  let rtime;
  let timeout = false;
  let delta = 5;
  let _index = 0;
  let _width = 0;
  let idi = 0;
  const handleColumnResize = (idx, width) => {
    idi = idx;
    if (idi < 1) return;
    if (timeout === false) {
      timeout = true;
      _width = width;
      rtime = new Date();
      setTimeout(resize, delta);
    }
  };

  const resize = () => {
    if (new Date() - rtime < delta) {
      setTimeout(resize, delta);
    } else {
      timeout = false;
      let visCol = renderColumns.filter((e) => e.visible === true);
      _index = idi - 1;
      _index = renderColumns.findIndex((e) => e.key === visCol[_index].key);
      renderColumns[_index].width = _width;
      sessionStorage.setItem(tableName, JSON.stringify(renderColumns));
    }
  };

  const handleGetColumns = () => {
    setRenderColumns(columns);
  };

  // resize end ~~~~~~~~~~~~~~~~~~~~~~~~

  useEffect(() => {
    handleGetColumns();
  }, [columns.length]);

  useEffect(() => {
    // handleGetColumns();
    if (currentData !== undefined) {
      currentData(rows);
    }
  }, [rows]);

  const tableButtons = (
    <div className="tableButtons" style={{ direction: _t("langDiriction") }}>
      <VisibleCol
        columns={renderColumns}
        toggleCol={toggleCol}
        tableName={tableName}
        direction={_t("langDiriction")}
        showSaveColumns={false}
      />
    </div>
  );

  return (
    <Spin tip={_t("strLoading")} size="large" spinning={loading}>
      <div className="tableContener_middle">
        <Row>
          <Col span={24}>{tableButtons}</Col>
        </Row>
        <DndProvider backend={HTML5Backend}>
          <DataGrid
            {...rest}
            rowHeight={25}
            components={{ noRowsFallback: <TableEmpty /> }}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onRowsChange={setRows}
            onColumnResize={handleColumnResize}
            columns={filteredCols()}
            rows={rows.map((e, i) => (e = { ...e, rowNum: i + 1 }))}
            className="rdg-light"
            direction={_t("langDiriction")}
          />
        </DndProvider>
      </div>
    </Spin>
  );
};
export default SettTable;
