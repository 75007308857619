import { useEffect, useState } from "react";
import { handleEx } from "../../helper/handleException";
import { getActiveParents } from "../../../services/AccountService";
import SelectSingle from "../../helper/selectSingle";
import { emptString } from "../../helper/helperMethods";

const SelectParentAccount = ({
  value,
  onChange,
  placeholder = "",
  addId = "",
  disabled = false,
}) => {
  const [list, setList] = useState([]);

  const handleSearch = async (e) => {
    
    try {
      const { data: responce } = await getActiveParents(
        emptString(e, ""),
        emptString(addId, "")
      );
      setList(responce?.data !== undefined ? responce?.data : []);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    handleSearch("");
  }, [addId]);

  return (
    <SelectSingle
      value={value}
      onChange={(_, reqOptAccCenter) => {
        onChange(_, reqOptAccCenter);
      }}
      placeholder={placeholder}
      disabled={disabled}
      allowClear={true}
      fieldNames={{
        label: "name",
        value: "id",
      }}
      opt={list}
    />
  );
};

export default SelectParentAccount;
