import React, { useEffect, useState, useMemo } from "react";
import DataTable, { pageSizeKey } from "../../helper/gridDataTable/dataTable";
import { Button, Divider, Space, message, Popconfirm, Tooltip } from "antd";
import _t from "../../../languages/translate";
import {
  DeleteOutlined,
  EditOutlined,
  UnlockOutlined,
  EyeOutlined
} from "@ant-design/icons";
import SearchT from "../../helper/gridDataTable/components/searchT";
import { useLocation, Link } from "react-router-dom";
import {
  getPagers,
  deleteUsers,
} from "../../../services/Authorization/usersService";
import ChangePassModal from "./modal_ChangePass";
import { handleEx } from "../../helper/handleException";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
const PageUsers = () => {
  const location = useLocation();
  const savedPageSize = localStorage.getItem(pageSizeKey);
  const [dataSource, setDataSource] = useState([]);
  const [meta, setMeta] = useState("");
  const [visible, setVisible] = useState(false);
  const [idVal, setIdVal] = useState("");

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey===authKeys.user)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  useMemo(()=>{authorization()},[])
  //////////----|👉 end auth|----//////////

  const columns = [
    {
      name: _t("strFirstName"),
      key: "firstName",
      visible: true,
      width: 300,
    },
    {
      name: _t("strLastName"),
      key: "lastName",
      visible: true,
      width: 300,
    },
    {
      name: _t("strEmail"),
      key: "email",
      visible: true,
      width: 300,
    },
    {
      name: _t("strUserName"),
      key: "userName",
      visible: true,
      width: 300,
    },

    {
      name: _t("strRoles"),
      key: "userRoleName",
      visible: true,
      width: 300,
    },
  ];

  useEffect(() => {
    getView();
  }, [location.search, savedPageSize]);

  const getView = async () => {
    const query = new URLSearchParams(location.search);
    if (savedPageSize && !query.has(pageSizeKey))
      query.set(pageSizeKey, savedPageSize);
    try {
      const { data: responce } = await getPagers(query.toString());
      
      if (responce.data.length > 0) {
        setDataSource(responce.data);
        setMeta(responce.meta);
      } else {
        setDataSource([]);
        message.info(_t("msgThereIsNoData"), 3);
      }
    } catch (error) {
      handleEx(error);
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteUsers(id);
      getView();
      message.success(response.data.message);
    } catch (ex) {
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const actionButtons = (
    <div className="actionButtons">
      <Space size="small">
        {auth.perPost && (
          <Button className="addNewButton" type="primary" size="small">
            <Link to={`/users/new`}>{_t("strAddNew")}</Link>
          </Button>
        )}
        <SearchT />
      </Space>
    </div>
  );

  const actionCol = [
    {
      key: "actions",
      name: _t("strActions"),
      unAuthorized: true,
      visible: true,
      width: 300,
      formatter: (p) => {
        return (
          <>
            {auth.perPut && (
              <>
                <Tooltip title={_t("strChangePassword")} color="#6c63ff">
                  <a type="link" 
                    onClick={() => {                    
                      setIdVal(p.row.id);
                      setVisible(true);
                    }}
                  >
                    <UnlockOutlined />
                  </a>
                </Tooltip>
                <Divider type="vertical" />
              </>
            )}
         
              <>
                <Tooltip title={auth.perPut ?_t("strEdit") : _t("strPreview")} color="#6c63ff">
                  <Link style={{ color: "#6c63ff" }} to={`/users/${p.row.id}`}>
                  {auth.perPut && (
                    <EditOutlined />
                    )}
                    {!auth.perPut && (
                    <EyeOutlined />
                    )}

                  </Link>
                </Tooltip>
                <Divider type="vertical" />
              </>
           
            {auth.perDelete && (
              <>
                <Tooltip title={_t("strDelete")} color="red">
                  <Popconfirm
                    title={_t("strSureToDelete")}
                    onConfirm={() => onDelete(p.row.id)}
                  >
                    <DeleteOutlined style={{ color: "#EC2867" }} />
                  </Popconfirm>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ChangePassModal
        idVal={idVal}
        visible={visible}
        setVisible={setVisible}
      />
      <DataTable
        columns={[...columns, ...actionCol]}
        style={{ height: "calc(100vh - 195px)", width: "100%" }}
        dataSource={dataSource}
        tableName="Scr_Users"
        tree={false}
        frezzColExp={false}
        colExp={0}
        actionButtons={actionButtons}
        meta={meta}
        pageName={_t("strUsers")}
        branchName={_t("strAuthorization")}
        groupName={_t("strSettings")}
        type="sett"
      />
    </>
  );
};

export default PageUsers;


