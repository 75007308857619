import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  Cascader,
  InputNumber,
  Card,
  Tabs,
  Checkbox,
  Spin,message,Space,Dropdown,Button

} from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import { getActiveTreeRepItems } from "../../../services/Items/itemService";
import { getActiveTreeStore } from "../../../services/storeService";
import { InfoCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import { getUnitRep } from "../../../services/Items/itemUnitService";
import Draggable from "react-draggable";
import {
  emptValue,
  emptArr,
  emptDate,
  dateRange,
  emptString,
  convertISOString
} from "../../helper/helperMethods";
import {
  postRepSett,
  postRepSettAllUser,
} from "../../../services/Reports/ReportSettService";

const FilterItemInStock = ({
  visible,
  setVisible,
  onHandelData,
  onFilterData
}) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const draggleRef = useRef(null);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [itemOpt, setItemOpt] = useState([]);
  const [itemState, setItemState] = useState("");
  const [itemTreeId, setItemTreeId] = useState([]);
  const [itemTypeSingleIds, setItemTypeSingleIds] = useState([]);
  const [storeOpt, setStoreOpt] = useState([]);
  const [storeState, setStoreState] = useState("");
  const [storeTreeId, setStoreTreeId] = useState([""]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [unitOpt, setUnitOpt] = useState([]);

  useEffect(() => {
    const feachData = async () => {
     // await setLoading(true);
      await onItemSearch("");
      await onStoreSearch("");
      await handleGetUnit();
      await handlePriceMethod("");
      await handleSearchCurrency("");
    //  await setLoading(false);
    };
    feachData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (onFilterData?.reportFilter !== undefined) {
        await fillData(onFilterData?.reportFilter);
      }
    };
    fetchData();
  }, [onFilterData]);

  const fillData = async(data) => {
     await form.setFieldsValue({
        itemIds: emptArr(data?.itemIds, undefined),
        storeIds: emptArr(data?.storeIds, undefined),
        unitType: emptArr(data?.unitType, undefined),
        entPostTypes: emptArr(data?.entPostTypes, undefined),
        itemViewType: emptArr(data?.itemViewType, undefined),
        itemType: emptArr(data?.itemType, undefined),
        toDate: emptDate(data?.toDate, ""),
        currId: emptString(data?.currId, undefined),
        priceMethodView: emptString(data?.priceMethodView, undefined),
        treeLevel: emptString(data?.treeLevel, undefined),
        viewType: emptString(data?.viewType, undefined),
        showEmptyStore: data?.showEmptyStore,
      })
  };


  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleGetUnit = async () => {
    try {
      const { data: responce } = await getUnitRep("Rep_ItemInStock");
      setUnitOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const items = [
    {
      label: _t("strSaveOpt"),
      key: "saveOpt",
    },
    {
      label: _t("strSaveOptAsDefault"),
      key: "saveOptAsDefault",
    },
  ];

   const onClick = async ({ key }) => {
    try {
      await setLoading(true);
      setTimeout(async () => {
        let test = form.getFieldsValue(true);
        
        let obj = {
          reportName: "Rep_Items_ItemInStock_AllFilterSett",
          reportFilter: JSON.stringify(form.getFieldsValue(true)),
        };
        if (key === "saveOpt") {
          await postRepSett(obj);
          message.success(_t("strDoneSuccessfully"));
        } else if (key === "saveOptAsDefault") {
          await postRepSettAllUser(obj);
          message.success(_t("strDoneSuccessfully"));
        }
        setLoading(false);
      }, 500);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.toDate = convertISOString(values?.toDate);

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };
    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const onItemSearch = async (searchValue) => {
    try {
      setItemState(searchValue);
      let obj = {
        search: searchValue,
        addIds: itemTreeId,
        types: itemTypeSingleIds,
      };
      const { data: result } = await getActiveTreeRepItems(obj);
      setItemOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onItemChange = (value) => {
    setItemTreeId(value);
  };

  const onStoreSearch = async (searchValue) => {
    try {
      setStoreState(searchValue);
      let obj = {
        search: searchValue,
        addIds: form.getFieldValue("storeIds"),
      };
      const { data: result } = await getActiveTreeStore(obj);
      setStoreOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onStoreChange = (value) => {
    setStoreTreeId(value);
  };


  useEffect(() => {
    onItemSearch("");
  }, [itemTypeSingleIds]);

  const handlePriceMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleItemTypeChange = (value) => {
    setItemTypeSingleIds(value);
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        >    
          <Space>
          {_t("strItemsInStockRep")}
            <Dropdown menu={{ items, onClick }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Button
                  type="text"
                  icon={
                    <EllipsisOutlined
                      style={{
                        color: "#717171",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    />
                  }
                />
              </a>
            </Dropdown>
          </Space>

        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >

<Spin spinning={loading} tip={_t("strLoading")}>


      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          toDate: dateRange(),
          currId: window.localStorage.getItem("currId")
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <Form.Item
                    label={_t("strItems")}
                  >
                    <Input.Group compact>
                      <Form.Item name="itemType" noStyle>
                        <SelectMulty
                          style={{ width: "30%" }}
                          placeholder={_t("strItemType")}
                          onChange={handleItemTypeChange}
                          opt={[
                            { id: 0, name: _t("strStocked"), filter1: _t("strStocked") },
                            { id: 1, name: _t("strManufactured"), filter1: _t("strManufactured") },
                            { id: 2, name: _t("strComposite"), filter1: _t("strComposite") },
                            { id: 3, name: _t("strUnStocked"), filter1: _t("strUnStocked") },
                          ]}
                        />
                      </Form.Item>
                      <SelectTreeMulty
                        style={{ width: "70%" }}
                        noStyle={true}
                        name="itemIds"
                        label={""}
                        opt={itemOpt}
                        setOpt={setItemOpt}
                        state={itemState}
                        setState={setItemState}
                        placeholder={_t("strItem")}
                        treeId={itemTreeId}
                        setTreeId={setItemTreeId}
                        onChange={onItemChange}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <SelectTreeMulty
                    name="storeIds"
                    label={_t("strStores")}
                    opt={storeOpt}
                    setOpt={setStoreOpt}
                    state={storeState}
                    setState={setStoreState}
                    treeId={storeTreeId}
                    setTreeId={setStoreTreeId}
                    onChange={onStoreChange}
                  />
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="unitType"
                    tooltip={{
                      title: _t("msgItemUnitRepType"),
                      icon: <InfoCircleOutlined />,
                    }}
                    label={_t("strUnit")}
                  >
                    <Cascader
                      showSearch
                      allowClear={false}
                      placeholder={_t("strNotSelected")}
                      optionFilterProp="children"
                      options={unitOpt}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      lable={_t("strToDate")}
                      fromName="fromDate"
                      toName="toDate"                 
                      spanTo={24}
                      defaultRange={0}
                      hideFrom={true}
                      hideRange={true}
                      noStyleTo={true}
                    />
                  </Row>
                </Col>
              </Card>
            </Row>
          </TabPane>

          <TabPane tab="الخيارات" key="2" forceRender>
            <Row gutter={12}>
              <Col span={16}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item name="entPostTypes" label={_t("strEntryStatus")}>
                          <SelectMulty
                            placeholder={_t("strEntryStatus")}
                            opt={[
                              { id: 0, name: _t("strPosted"), filter1: _t("strPosted") },
                              { id: 1, name: _t("strUnPosted"), filter1: _t("strUnPosted") },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="itemViewType" label={_t("strShowItems")}>
                          <SelectMulty
                            placeholder={_t("strShowItems")}
                            opt={[
                              { id: 0, name: _t("strItemsContainCredit"), filter1: _t("strItemsContainCredit") },
                              { id: 1, name: _t("strNegativeItems"), filter1: _t("strNegativeItems") },
                              { id: 2, name: _t("strBlankItems"), filter1: _t("strBlankItems") },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="currId" label={_t("strCurrency")}  >
                          <SelectSingle
                          allowClear={false}
                            fieldNames={{
                              label: "currName",
                              value: "currId",
                            }}
                            opt={currencyOpt}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="priceMethodView" label={_t("strPricingPolicy")} >
                          <SelectSingle opt={priceMethodOpt} />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                      <Form.Item name="viewType" label={_t("strShowStyle")} >
                          <SelectSingle 
                            opt={[
                              { id: 0, name: _t("strDetailed"), filter1: _t("strDetailed") },
                              { id: 1, name: _t("strTotal2"), filter1: _t("strTotal2") },                          
                            ]} placeholder={_t("strShowStyle")} />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item name="treeLevel" label={_t("strLevel")}>
                          <InputNumber
                            size="small"
                            placeholder={_t("strLevel")}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{
                    width: "100%",
                    height: "95%",
                    margin: "5px 0px 5px 0px",
                  }}
                  bodyStyle={{ padding: "10px" }}
                >
                  <Form.Item
                    name="showEmptyStore"
                    valuePropName="checked"
                    className="formItemClass"
                  >
                    <Checkbox>{_t("strShowEmptyStock")}</Checkbox>
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
      </Spin>
    </Modal>
  );
};

export default FilterItemInStock;
