import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  Empty,
  Modal,
  Space,
  Row,
  Checkbox,
  Col,
  Menu,
  Dropdown,
  Typography,
  Spin,
  Tag
} from "antd";

import {
  getParents,
  getItemFinalAccountById,
  editItemFinalAccount,
  getInitiale,
} from "../../services/itemFinalAccountService";
import { ExclamationCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ItemFinalAccountForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [form] = Form.useForm();
  const [loadData, setLoadData] = useState(true);
  const { Option } = Select;
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  //******************************//
  const [parentChange, setParentChange] = useState(false);
  const [parentOpt, setParentOpt] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [isGoBack, setIsGoBack] = useState(true);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { TextArea } = Input;
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.itemFinalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const promptForm = () => {
    setProChange(true);
  };

  const initValue = async (Id) => {
    try {
      const { data: newData } = await getInitiale(Id);
      form.setFieldsValue({
        code: newData.data.code !== null ? newData.data.code : "",
        parentId: newData.data.parentId !== null ? newData.data.parentId : "",
      });

      handleActiveParents("", id === "new" ? "" : id);
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
    finally {
      setLoadData(false);
    }
  }

  const getById = async (Id) => {
    try {
      const { data } = await getItemFinalAccountById(id);

      if (data.data.parentId === null) {
        data.data.parentId = "";
      }
      form.setFieldsValue({
        code: data.data.code,
        parentId: data.data.parentId,
        name: data.data.name,
        itemBasicAccId: data.data.itemBasicAccId,
        note: data.data.note,
      });
      handleActiveParents("", "", id === "new" ? "" : id);
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    }
    finally {
      setLoadData(false);
    }

  }

  useEffect(() => {
    authorization();
    async function fetchData() {
      if (id === "new") {
        return await initValue("");
      }
      else if (id === "add") {
        const parentId = query.get("ParentId");

        //  const parentId = history.location.state?.parentId;
        if (!parentId) return;
        setIsGoParentId(parentId);
        return await initValue(parentId);
      }
      else {
        setParentChange(true);
        return await getById(id);
      }
    }
    fetchData();
  }, [])


  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        direction: _t("langDiriction"),
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          initValue(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {

      initValue(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };


  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      if (history.action === "POP") { history.push({ pathname: "/itemFinalAcc" }) }
      else { history.goBack() }
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/itemFinalAcc/new" });
      }
      form.resetFields();
      initValue(isGoParentId);
      handleActiveParents("", "");
      IX_Name.current.focus();
    }
  };


  const handleActiveParents = async (_Search) => {
    try {
      const { data: responce } = await getParents(_Search, "", id === "new" ? "" : id);
      setParentOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name });
    } finally {
      setLoadData(false);
    }
  };


  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    try {
      const data = await editItemFinalAccount(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      goBack();
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name });
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const parentOptList =
    parentOpt.length > 0 &&
    parentOpt.map((item) =>
      item.id !== id ? (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ) : null
    );


  return (
    <div key="mainDiv">
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{ isMain: false }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text >{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strItemFinalAccount")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={
                          <div>
                            {_t("strName")}
                            <div
                              style={{
                                opacity: 0.8,
                                zIndex: 99,
                                position: "absolute",
                                top: 4,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + 1
                              </Tag>
                            </div>
                          </div>
                        }
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="parentId"
                        label={_t("strMainCategory")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => { handleActiveParents(value); }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          onChange={onChangeParent}
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {parentOptList}
                        </Select>
                      </Form.Item>
                    </Col>



                    <Col xs={24} md={12}>
                      <Form.Item
                        name="itemBasicAccId"
                        label={_t("strItemFinalAcc")} rules={[{ required: true }]} >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          onChange={promptForm}
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          placeholder={_t("strItemFinalAcc")}
                          size="small"
                        >
                          <Option value="34c1d1b7-cefe-40db-aad8-a4814ea3d11b">{_t("strTradingAcc")}</Option>
                          <Option value="2f66e90c-bc4f-4587-8a35-71e3c48bf431">{_t("strOperationAcc")}</Option>
                        </Select>
                      </Form.Item>
                    </Col>


                    <Col xs={24} md={12}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          { required: false, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  {auth?.perPut && (
                    <div>
                      <Button type="primary" htmlType="submit" size="small" disabled={loadData}>
                        {_t("strSave")}
                      </Button>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + S
                        </Tag>
                      </div>
                    </div>
                  )}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => { history.action === "PUSH" ? history.goBack() : history.push("/itemFinalAcc"); }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>

      </React.Fragment>
    </div>
  );
};

export default ItemFinalAccountForm;
