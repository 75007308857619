import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Tag, Modal, Button, Spin } from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getSearchPopup } from "../../../services/Items/itemService";
import { shortCut } from "../../helper/shortCuts";
import DataGrid, { SelectColumn } from "react-data-grid";
import Draggable from "react-draggable";
import { emptValue } from "../helperMethods";

const ItemInvSelector = ({
  onHandelData,
  searchValue,
  showModal,
  setShowModal,
  showModalName,
}) => {
  const [selectedRows, setSelectedRows] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [popupForm] = Form.useForm();
  const draggleRef = useRef(null);
  const gridRef = useRef(null);
  const refItemSearch = useRef(null);
  const { Search } = Input;

  const columns = [
    SelectColumn,
    {
      name: _t("strName"),
      key: "itemName",
      width: "100%",
    },
  ];

  useEffect(() => {
    if (showModal) gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    else gridRef?.current?.selectCell({ idx: null, rowIdx: null });
  }, [showModal]);

  useEffect(() => {
    setLoadData(true);
    popupForm.setFieldsValue({ search: searchValue });
    searchData(searchValue);
  }, [searchValue]);

  const [altPress, setAltPress] = useState(false);
  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem(showModalName))) {
        switch (shortCut(e)) {
          case "insert":          
              popupForm.submit();  
            break;
          case "search":
            refItemSearch?.current?.focus();
            refItemSearch?.current?.select();
            break;
          case "block1":
            setTimeout(function () {
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "tag":
            if (!!gridRef.current)
              gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
            setAltPress(true);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const searchData = async (value) => {
    try {
      const { data: result } = await getSearchPopup(value);
      setDataSource(result.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
      refItemSearch?.current?.blur();
      gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
    }
  };

  const handleCheckDef = (row) => {
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].accId === row.accId) {
        dataSource[i].insert = true;
      } else {
        dataSource[i].insert = false;
      }
    }
    setDataSource([...dataSource]);
  };

  const onFinish = async() => {
    if (emptValue(selectedRows?.size, 0) !== 0) {
      if (loadData===false) {
        await setLoadData(true);
        setTimeout(async () => {

 
          let array = [];
          await selectedRows?.forEach((x) => x == array?.push(x));
          const result = dataSource.filter((c) =>
            array.some((s) => s === c.itemId)
          );
          await onHandelData(result);
        
      }
    , 100);
  }
}
  };
  const handleClear = () => {
    setLoadData(true);
    setTimeout(() => {
      handleCheckDef("");
      setSelectedRows();
      setLoadData(false);
    }, 50);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <Modal
      centered
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strItems")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        window.sessionStorage.setItem(showModalName, false);
      }}
      onOk={() => popupForm.submit()}
      afterClose={() => handleClear()}
      okText={_t("strInsert")}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => popupForm.submit()}
          disabled={emptValue(selectedRows?.size, 0) === 0 || loadData}
        >
          <div>
            {_t("strInsert")}
            <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + Enter
              </Tag>
            </div>
          </div>
        </Button>,
      ]}
    >
       <Spin tip={_t("strLoading")} spinning={loadData}>
      <Form form={popupForm} forceRender onFinish={onFinish}>
        <div>
          <Form.Item name="search">
            <Search
              ref={refItemSearch}
              onSearch={searchData}
              placeholder={_t("strSearch")}
              size="small"
              style={{ width: 200, marginBottom: 25 }}
            />
          </Form.Item>
          <div
            style={{
              opacity: 0.8,
              zIndex: 99,
              position: "absolute",
              top: 20,
            }}
          >
            <Tag color="#2b2b2b" hidden={!altPress}>
              alt + F
            </Tag>
          </div>
        </div>
        <Form.Item>
          <div>
            <DataGrid
              selectedRows={selectedRows}
              onSelectedRowsChange={(e) => setSelectedRows(e)}
              ref={gridRef}
              style={{ height: `calc(100vh - 300px)`, width: "100%" }}
              columns={columns}
              rowHeight={25}
              className="rdg-light"
              rowKeyGetter={(row) => row?.itemId}
              rows={dataSource}
              onRowsChange={setDataSource}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              direction={_t("langDiriction")}
            />
            <div
              style={{
                opacity: 0.8,
                position: "absolute",
                top: 24,
              }}
            >
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + 1
              </Tag>
            </div>
          </div>
        </Form.Item>
      </Form>
      </Spin>
    </Modal>
  );
};

export default ItemInvSelector;
