
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/CurrencyRate";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getCurrencyRateById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getRate(id, date) {
  
  return http.get(`${apiEndpoint}/GetRate/${id}/${date}`);
}

export function changeRate(obj) {
  return http.post(`${apiEndpoint}/ChangeRate`, obj);
}

export function editCurrencyRate(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}


export function getAllCurrencyRate() {
  return http.get(`${apiEndpoint}`);
}
export function deleteCurrencyRate(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerCurrencyRate(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewCurrencyRateCode() {
  if (process.env.IS_DEBUG) {
  }
  return http.post(`${apiEndpoint}/GetNewCode`);
}
