import React, { useState } from "react";
import { useHistory,Link } from "react-router-dom";
/********************| pages |********************/
import ListMenu from "./components/layout/menu";
import RouteDom from "./RouteDom";
/********************| languages |********************/
import LanguageSelector from "./languages/LanguageSelector";
import { LanguageProvider } from "./languages/Language";
/********************| antd |********************/
import {
  Layout,
  Row,
  Col,
  BackTop,
  Drawer,
  Space,
  Avatar,
  Dropdown,
  Menu,
  Typography,
  Modal,
  Button
} from "antd";

import {
  UpOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import "./app.less";

import { getCurrentUser} from "./services/Authorization/authService";

/********************| Media respo |********************/
import Media from "react-media";
import _t from "./languages/translate";
import logo from "./images/svg/Logo/logo.svg";
//import logoSmall from "./images/svg/YBOrginalsmall.svg";

const { Header, Content } = Layout;


const App = () => {
  const { Text } = Typography;
  const { confirm } = Modal;
  let history = useHistory();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const user = getCurrentUser();
  const [direction, setDirection] = useState("left");
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const newDirection = (direction) => {
    setDirection(direction);
  };

  const handleTitleLang = (lang) => {};


  const handleMenu = (e) => {
    if (e.key === "3") {
      confirm({
        title: <span style={{ direction: "rtl" }}>{_t("msgAskLogOut")}</span>,
        icon: <LogoutOutlined style={{ color: "#1890ff" }} />,
        onOk() {
          history.push("/logout");
        },
        onCancel() {},
      });
    }
  };

  const userMenu = (
    <Menu style={{ marginTop: 21 }} onClick={handleMenu}>
      <Menu.Item key="3" icon={<LogoutOutlined style={{ fontSize: 15 }} />}>
        تسجيل خروج
      </Menu.Item>
    </Menu>
  );

  return (

    <LanguageProvider userDirection={newDirection}>   
      <Layout className="site-layout" style={{ minHeight: "100vh"}}>
        {user && (
          <Media query="(max-width: 1024px)">
            {(matches) =>
              matches ? (
                <>
                  <Header style={{ height: 50 }} className="header-style">
                    <Row style={{ height: "100%", alignContent: "center" }}>
                      {/* <Col flex={0.1} style={{ textAlign: "start" }}>
                        <a href="https://yotta-base.com" >
                          <img style={{ width: 30 }} alt="Yottabase" src={logoSmall} />
                        </a>
                      </Col> */}
                      <Col flex={3.9} style={{ textAlign: "start" }}>
                        <Button type="link" onClick={showDrawer}>
                          <MenuFoldOutlined
                            style={{
                              fontSize: 20,
                              marginTop: 5,
                              color: "#fff",
                            }}
                          />
                        </Button>
                      </Col>
                      <Col
                        flex={1}
                        style={{ textAlign: "end", marginBottom: -11 }}
                      >
                        <Space size="large">
                          <Dropdown overlay={userMenu} trigger={["click"]}>
                            <Space>
                              <Avatar
                                icon={<UserOutlined />}
                                size="small"
                              ></Avatar>
                              <Text
                                style={{ color: "#ccc", cursor: "pointer", textTransform: "capitalize" }}
                              >
                                {" "}
                                {window.localStorage.getItem("user")}{" "}
                              </Text>
                            </Space>
                          </Dropdown>

                          <LanguageSelector
                            onHandelLang={(lang) => {
                              handleTitleLang(lang);
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Header>

                  <Drawer
                    placement={direction}
                    closable={false}
                    onClose={onCloseDrawer}
                    visible={visibleDrawer}
                    bodyStyle={{ backgroundColor: "#001529", padding: 0 }}
                  >
                    <Link to="/homepage" >
                      <img
                        style={{ width: 170, margin: "10px 18px" }} 
                        alt="Yottabase"
                        src={logo}
                      />
                    </Link>
                    <ListMenu mode="inline" isMobile />
                  </Drawer>
                </>
              ) : (
                <Header style={{ height: 50 }} className="header-style">
                  <Row style={{ height: "100%", alignContent: "center" }}>
                    <Col flex={0.1} style={{ textAlign: "start" }}>
                    <Link to="/homepage" >
                        <img style={{ width: 130 }} alt="Yottabase" src={logo} />
                      </Link>
                    </Col>
                    <Col flex={4.9} style={{ textAlign: "start" }}>
                      <ListMenu mode="horizontal" />
                    </Col>
                    <Col flex={1} style={{ textAlign: "end" }}>
                      <Space size="middle">
                        <Dropdown overlay={userMenu} trigger={["click"]}>
                          <Space>
                            <Avatar
                              icon={<UserOutlined />}
                              size="small"
                            ></Avatar>
                            <Text style={{ color: "#ccc", cursor: "pointer", textTransform: "capitalize" }}>
                              {" "}
                              {window.localStorage.getItem("user")}{" "}
                            </Text>
                          </Space>
                        </Dropdown>
                        <LanguageSelector
                          onHandelLang={(lang) => {
                            handleTitleLang(lang);
                          }}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Header>
              )
            }
          </Media>
        )}

        <Content
          className={
            !user
              ? "login-site-layout-background log-wave"
              : "site-layout-background"
          }
        >
          <RouteDom />
          <BackTop>
            <div className="backTop">
              <UpOutlined />
            </div>
          </BackTop>
        </Content>

        {!user ? (
          <div class="area" style={{background:"#fff"}}>
            <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>{" "}
          </div>
        ) : (
          <></>
        )}
      </Layout>
    </LanguageProvider>
    
  );
};
export default App;


