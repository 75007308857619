import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  Checkbox,
  Tabs,
  Spin,
  message,
  InputNumber,
  Typography,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";
import SelectSingle from "../../../helper/selectSingle";
import { GetActiveChildren } from "../../../../services/AccountService";
import { getClientActiveSearch } from "../../../../services/clientService";
import { getActivePayMethodSearch } from "../../../../services/Items/payMethodService";
import { GetActiveStoreChildren } from "../../../../services/storeService";
import { getCurrActiveSearch } from "../../../../services/Items/currenciesService";
import { getActivePriceMethodSearch } from "../../../../services/Items/pricingMethodsService";
import { getActiveJournal } from "../../../../services/journalService";
import { emptString, emptArr, emptValue } from "../../../helper/helperMethods";
import { convertFromSeve } from "../../../helper/gridSettTable/components/helperMethods";
import { getByBranch } from "../../../../services/EntPatSettService";
import { calcAndReturnList } from "../../../helper/Modal/costCenterModal/costCenterHelper";
import { checkEntType } from "./showFieldByType";
import CostCenterInput from "../../../helper/Input/costCenterInput";
import EntTableSett from "./popups/mainTableSett";
import { handleEx } from "../../../helper/handleException";
import SelectAccount from "../../../helper/inputSelect/selectAccount";
const PattSettings = ({
  form,
  dataObj,
  selectedTypeId,
  selectedBranchId,
  setLoadData,
}) => {
//  const [loadData, setLoadData] = useState(false);
  const [showField, setShowField] = useState(undefined);
  // main table-----------
  const [showEntColSett, setShowEntColSett] = useState(false);
  const [mainDataSource, setMainDataSource] = useState([]);
  const [accOptReqCost, setAccOptReqCost] = useState(0);
  const [mainColumns, setMainColumns] = useState([]);
  const [relatedPattSettOpt, setRelatedPattSettOpt] = useState([]);

  const [activeKey, setActiveKey] = useState("1");
  const { Text } = Typography;
  useEffect(() => {
    async function fetchData() {
      await setLoadData(true);
      await handleAccActSearch();
      await handlePayMethodSearch();
      await handleClientSearch();
      await handleStoreSearch();
      await handleSearchCurrency();
      await handlePriceMethodSearch();
      await handleJornal();
      const obj = await checkEntType(selectedTypeId);
      await setShowField(obj);
      if (dataObj !== undefined) {
        await fillData(dataObj);
      }
      await setLoadData(false);
    }
    fetchData();
  }, [dataObj]);

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      const arr = costList.map((e) => {
        e.value = 0;
        return e;
      });
      const reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, arr, emptValue(reqOpt, 0))?.text;
    }
  };

  useEffect(() => {
    const callRelatedPattSett = async () => {
      const { data: data } = await getByBranch(selectedBranchId);

      setRelatedPattSettOpt(data.data);
    };
    callRelatedPattSett();
  }, [selectedBranchId]);

  const fillData = async (data) => {
    const accList = await handleAccAct();
  await  form.setFieldsValue({
      accVisible: data?.data?.accVisible,
      accEnable: data?.data?.accEnable,
      accDefId: emptString(data?.data?.accDefId, undefined),
      dateEnable: data?.data?.dateEnable,
      currVisible: data?.data?.currVisible,
      currEnable: data?.data?.currEnable,
      currChType: data?.data?.currChType,
      currApplyType: data?.data?.currApplyType,
      currDefId: emptString(data?.data?.currDefId, undefined),
      docReq: data?.data?.docReq,
      docVisible: data?.data?.docVisible,
      showPrevBalance: data?.data?.showPrevBalance,
      showDayBalance: data?.data?.showDayBalance,
      codeStart: data?.data?.codeStart,
      codeEnable: data?.data?.codeEnable,
      genShortEnt: data?.data?.genShortEnt,
      journalId: emptString(data?.data?.journalId, undefined),
      post: data?.data?.post,
      postVisible: data?.data?.postVisible,
      postEnable: data?.data?.postEnable,
      relatedPattSettId: emptString(data?.data?.relatedPattSettId, undefined),
      detailsColSett: data?.data?.detailsColSett,

      entCostCenterVisible: data?.data?.entCostCenterVisible,
      entCostCenterEnable: data?.data?.entCostCenterEnable,
      entCostCenterReq: data?.data?.entCostCenterReq,

      entPattSettCostCenter_Maps: data?.data?.entPattSettCostCenter_Maps,
      entPattSettCostCenter: loadCostCenterData(
        data?.data?.entPattSettCostCenter_Maps,
        data?.data?.accDefId,
        accList
      ),
    });
  };
  const onTabsChange = (key) => {
    setActiveKey(key);
  };

  const handleShowMainEntTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("detailsColSett"));
    setMainDataSource(tempObj.dataSource);
    setMainColumns(tempObj.columns);
    setShowEntColSett(true);
  };

  const [accOpt, setAccOpt] = useState([]);
  const handleAccActSearch = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      setAccOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const handlePayMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePayMethodSearch("");
      setPayMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [clientOpt, setClientOpt] = useState([]);
  const handleClientSearch = async () => {
    try {
      let obj = {
        search: "",
        addId: [""],
        accId: "",
      };

      const { data: result } = await getClientActiveSearch(obj);

      setClientOpt(result?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [storeOpt, setStoreOpt] = useState([]);
  const handleStoreSearch = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setStoreOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [currencyOpt, setCurrencyOpt] = useState([]);
  const handleSearchCurrency = async () => {
    try {
      const { data: responce } = await getCurrActiveSearch("");
      setCurrencyOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const handlePriceMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePriceMethodSearch("");
      setPriceMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [jornalOpt, setJornalOpt] = useState([]);
  const handleJornal = async (_Search) => {
    try {
      const { data: responce } = await getActiveJournal("", "");
      setJornalOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const generalCards = (
    <div className="mainCardsContent">
      <div name="generalCards" className="frContent warpCardsContent">
        {showField?.showAccEnable &&
          showField?.showAccVisible &&
          showField?.showAccDefId && (
            <Card
              type="inner"
              title={<strong> {_t("strAccount")} </strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showAccVisible && (
                  <Form.Item name="accVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showAccEnable && (
                  <Form.Item name="accEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showAccDefId && (
                <Form.Item
                  name="accDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectAccount
                    addId={form.getFieldValue("accDefId")}
                    placeholder={_t("strNotSelected")}
                    onChange={(e, x) => {
                      setAccOptReqCost(x?.costCenterReqOpt);
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Cost Center Client Accounts*/}

        {showField?.showEntCostCenterVisible &&
          showField?.showEntCostCenterEnable &&
          showField?.showEntCostCenterReq &&
          showField?.showEntPattSettCostCenter_Maps && (
            <Card
              type="inner"
              title={<strong>{_t("strCostCenterAcc")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showEntCostCenterVisible && (
                  <Form.Item
                    name="entCostCenterVisible"
                    valuePropName="checked"
                  >
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showEntCostCenterReq && (
                  <Form.Item name="entCostCenterReq" valuePropName="checked">
                    <Checkbox>{_t("strRequired")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showEntCostCenterEnable && (
                  <Form.Item name="entCostCenterEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>

              {showField?.showEntPattSettCostCenter_Maps && (
                <>
                  <Form.Item name="entPattSettCostCenter_Maps" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="entPattSettCostCenter"
                    label={_t("strDefault")}
                    labelCol={{ flex: "60px" }}
                    // rules={[
                    //   {
                    //     required:
                    //       clientCostMessage !== undefined ? true : false,
                    //   },
                    // ]}
                  >
                    <CostCenterInput
                      form={form}
                      fieldName="entPattSettCostCenter"
                      arrName="entPattSettCostCenter_Maps"
                      unValue={true}
                      accountValidationOpt={accOptReqCost}
                      type="input"
                      onHandelData={(e) => {
                        form.setFieldsValue({ entPattSettCostCenter: e });
                      }}
                      //amount={getFieldValue("invAddTotal")}
                    />
                  </Form.Item>
                </>
              )}
            </Card>
          )}

        <Card
          type="inner"
          title={<strong>{_t("strDate")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="dateEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/*Currency*/}
        {showField?.showCurrVisible &&
          showField?.showCurrEnable &&
          showField?.showCurrChType &&
          showField?.showCurrApplyType &&
          showField?.showCurrDefId && (
            <Card
              type="inner"
              title={<strong>{_t("strCurrency")}</strong>}
              size="small"
              className="cardPatt"
            >
              <Space>
                {showField?.showCurrVisible && (
                  <Form.Item name="currVisible" valuePropName="checked">
                    <Checkbox>{_t("strVisible")}</Checkbox>
                  </Form.Item>
                )}
                {showField?.showCurrEnable && (
                  <Form.Item name="currEnable" valuePropName="checked">
                    <Checkbox>{_t("strEnable")}</Checkbox>
                  </Form.Item>
                )}
              </Space>
              {showField?.showCurrChType && (
                <Form.Item
                  name="currChType"
                  label={_t("strExchangeRateAdjustment")}
                  labelCol={{ flex: "120px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    defaultValue={0}
                    opt={[
                      {
                        id: 0,
                        name: _t("strSaveDirectly"),
                        filter1: _t("strSaveDirectly"),
                      },
                      {
                        id: 1,
                        name: _t("strNeverSave"),
                        filter1: _t("strNeverSave"),
                      },
                      {
                        id: 2,
                        name: _t("strQuestionBeforeSaving"),
                        filter1: _t("strQuestionBeforeSaving"),
                      },
                    ]}
                    placeholder={_t("strMethodOfDetermining")}
                  />
                </Form.Item>
              )}
              {showField?.showCurrApplyType && (
                <Form.Item
                  name="currApplyType"
                  label={_t("strApplyMethod")}
                  labelCol={{ flex: "120px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    defaultValue={0}
                    opt={[
                      {
                        id: 0,
                        name: _t("strEntryCurrency"),
                        filter1: _t("strEntryCurrency"),
                      },
                      {
                        id: 1,
                        name: _t("strAccCurr"),
                        filter1: _t("strAccCurr"),
                      },
                    ]}
                    placeholder={_t("strMethodOfDetermining")}
                  />
                </Form.Item>
              )}
              {showField?.showCurrDefId && (
                <Form.Item
                  name="currDefId"
                  label={_t("strDefault")}
                  labelCol={{ flex: "60px" }}
                >
                  <SelectSingle
                    allowClear={false}
                    opt={currencyOpt}
                    fieldNames={{ label: "currName", value: "currId" }}
                    placeholder={_t("strNotSelected")}
                  />
                </Form.Item>
              )}
            </Card>
          )}

        {/*Doc*/}
        {showField?.showDocReq && showField?.showDocVisible && (
          <Card
            type="inner"
            title={<strong>{_t("strDocument")}</strong>}
            size="small"
            className="cardPatt"
          >
            <Space>
              {showField?.showDocReq && (
                <Form.Item name="docVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showDocReq && (
                <Form.Item name="docReq" valuePropName="checked">
                  <Checkbox>{_t("strRequired")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {showField?.showShowPrevBalance && (
          <Card
            type="inner"
            title={<strong>{_t("strDifference")}</strong>}
            size="small"
            className="cardPatt"
          >
            {showField?.showShowPrevBalance && (
              <Space>
                <Form.Item name="showPrevBalance" valuePropName="checked">
                  <Checkbox>{_t("strShowPreviousBalance")}</Checkbox>
                </Form.Item>
              </Space>
            )}
          </Card>
        )}
      </div>
    </div>
  );

  const advanceCard = (
    <div className="mainCardsContent">
      <div name="generalCards" className="frContent warpCardsContent">
        <Card
          type="inner"
          title={<strong>{_t("strEntTable")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="detailsColSett" hidden>
              <Input />
            </Form.Item>
            <Button icon={<SettingOutlined />} onClick={handleShowMainEntTable}>
              {_t("strColSettings")}
            </Button>
          </Space>
        </Card>
        {/*Code*/}
        {showField?.showCodeEnable && showField?.showCodeStart && (
          <Card
            type="inner"
            title={<strong>{_t("strCode")}</strong>}
            size="small"
            className="cardPatt"
          >
            {showField?.showCodeEnable && (
              <Form.Item name="codeEnable" valuePropName="checked">
                <Checkbox>{_t("strEnable")}</Checkbox>
              </Form.Item>
            )}
            {showField?.showCodeStart && (
              <Form.Item
                name="codeStart"
                label={_t("strStartNumber")}
                labelCol={{ flex: "70px" }}
              >
                <InputNumber
                  size="small"
                  placeholder={_t("strStartNumber")}
                  style={{ width: "100%" }}
                  controls={false}
                />
              </Form.Item>
            )}
          </Card>
        )}
        {showField?.showJournalId && (
          <Card
            type="inner"
            title={<strong>{_t("strTheEntry")}</strong>}
            size="small"
            className="cardPatt"
          >
            {showField?.showJournalId && (
              <Form.Item
                name="journalId"
                label={_t("strJournal")}
                labelCol={{ flex: "100px" }}
                rules={[
                  {
                    required: true,
                    message: `${_t("strIsRequired")}`,
                    validator: async (_, value) => {
                      if (value === undefined || value === "") {
                        setActiveKey("2");
                        message.error(
                          `(${_t("strJournal")}) ${_t("strIsRequired")}`
                        );
                        throw new Error("message");
                      }
                    },
                  },
                ]}
              >
                <SelectSingle
                  allowClear={false}
                  opt={jornalOpt}
                  placeholder={_t("strNotSelected")}
                />
              </Form.Item>
            )}
          </Card>
        )}
        {/*Posting Date*/}
        {/* {showField?.showPostVisible &&
          showField?.showPostEnable &&
          showField?.showPost && ( */}
        {(showField?.showPostVisible ||
          showField?.showPostEnable ||
          showField?.showPost) && (
          <Card
            type="inner"
            title={<strong>{_t("strPosting")}</strong>}
            size="small"
            className="cardPatt cardFitContent"
          >
            <Space>
              {showField?.showPostVisible && (
                <Form.Item name="postVisible" valuePropName="checked">
                  <Checkbox>{_t("strVisible")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showPostEnable && (
                <Form.Item name="postEnable" valuePropName="checked">
                  <Checkbox>{_t("strEnable")}</Checkbox>
                </Form.Item>
              )}
              {showField?.showPost && (
                <Form.Item name="post" valuePropName="checked">
                  <Checkbox>{_t("strPostingEntry")}</Checkbox>
                </Form.Item>
              )}
            </Space>
          </Card>
        )}

        {showField?.showRelatedPattSettId &&
          emptString(selectedBranchId, undefined) !== undefined && (
            <Card
              type="inner"
              title={<strong>{_t("strPatt")}</strong>}
              extra={<Text mark>{_t("msgWhenSelectBranchFillVal")}</Text>}
              size="small"
              className="cardPatt"
            >
              {showField?.showRelatedPattSettId && (
                <Form.Item
                  name="relatedPattSettId"
                  label={_t("strTheMainEntryDeed")}
                  labelCol={{ flex: "140px" }}
                  rules={[
                    {
                      required:
                        emptString(selectedBranchId, undefined) !== undefined
                          ? true
                          : false,
                      message: `${_t("strIsRequired")}`,
                      validator: async (_, value) => {
                        if (
                          (emptString(selectedBranchId, undefined) !==
                            undefined &&
                            value === undefined) ||
                          value === ""
                        ) {
                          setActiveKey("2");
                          message.error(
                            `(${_t("strTheMainEntryDeed")}) ${_t(
                              "strIsRequired"
                            )}`
                          );
                          throw new Error("message");
                        }
                      },
                    },
                  ]}
                >
                  <SelectSingle
                    allowClear={false}
                    opt={relatedPattSettOpt}
                    placeholder={_t("strNotSelected")}
                    disabled={
                      emptString(selectedBranchId, undefined) === undefined
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              )}
            </Card>
          )}
      </div>
    </div>
  );

  return (
    <>
      <EntTableSett
        showModal={showEntColSett}
        setShowModal={setShowEntColSett}
        columns={mainColumns}
        setColumns={setMainColumns}
        dataSource={mainDataSource}
        setDataSource={setMainDataSource}
        tableName="popup_entTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ detailsColSett: e });
        }}
      />
      {/* <Spin spinning={loadData} tip={_t("strLoading")}> */}
      <div name="cardsId" className="frContent">
        <Tabs
          type="card"
          onChange={onTabsChange}
          activeKey={activeKey}
          items={[
            {
              key: "1",
              label: _t("strGeneral"),
              children: generalCards,
              forceRender: true,
            },
            {
              key: "2",
              label: _t("strAdvanced"),
              children: advanceCard,
              forceRender: true,
            },
          ]}
        />
      </div>
      {/* </Spin> */}
    </>
  );
};

export default PattSettings;
