import React from "react";
import { Form, Space, Tooltip } from "antd";
import SelectSingle from "../../../../helper/selectSingle";
import { ColumnWidthOutlined, ColumnHeightOutlined } from "@ant-design/icons";
import NumericInput from "../Inputs/NumericInput";
import _t from "../../../../../languages/translate";
const InputWidth = ({
  typeName,
  widthInputName,
  hightInputName,
}) => {
  return (
    <Space.Compact size="small" style={{ width: "100%" }}>
      <Form.Item noStyle name={widthInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strWidth")}>            
                <ColumnWidthOutlined 
                  style={{ color: "rgba(0,0,0,.45)" }}
                />           
            </Tooltip>
          }
          placeholder={_t("strWidth")}
        />
      </Form.Item>
      <Form.Item noStyle name={hightInputName}>
        <NumericInput
          style={{ width: "100%" }}
          size="small"
          suffix={
            <Tooltip title={_t("strHeight")}>        
                <ColumnHeightOutlined
                  style={{ color: "rgba(0,0,0,.45)"}}
                />             
            </Tooltip>
          }
          placeholder={_t("strHeight")}
        />
      </Form.Item>
      <Form.Item noStyle name={typeName}>
        <SelectSingle
        showArrow={false}
          allowClear={false}
          style={{ width: '15%' }}
          opt={[
            { id: "mm", name: "mm" },
            { id: "cm", name: "cm" },
            { id: "px", name: "px" },
            { id: "%", name: "%" },
          ]}
        />
      </Form.Item>
    </Space.Compact>
  );
};
export default InputWidth;
