import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

const container = document.getElementById('root');

// create a root
const root = ReactDOM.createRoot(container);

//render app to root
root.render(
  <Router>
    <App basename={`${process.env.PUBLIC_URL}`} />
  </Router>,
  //document.getElementById("root")
);

serviceWorker.unregister();
//basename={`${process.env.PUBLIC_URL}`}
