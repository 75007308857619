import moment from "moment";

export function emptValue(realVal, emptVal) {
  if (
    parseFloat(realVal) === 0 ||
    realVal === "" ||
    realVal === null ||
    isNaN(realVal) ||
    realVal === undefined ||
    realVal === "_"
  ) {
    return emptVal;
  } else {
    return parseFloat(realVal);
  }
}

export function emptEntValue(realVal, emptVal) {
  
  if (  
    isNaN(realVal) ||
    realVal === undefined ||
    realVal === "_"
  ) {
    return emptVal;
  } else {
    return parseFloat(realVal);
  }
}

export function emptString(realVal, emptVal) {
  if (
    realVal === "" ||
    realVal === "_" ||
    realVal === "00000000-0000-0000-0000-000000000000" ||
    realVal === null ||
    realVal === undefined
  ) {
    return emptVal;
  } else {
    return realVal;
  }
}



export function emptArr(realVal, emptVal) {
  if (
    realVal === null ||
    realVal === "" ||
    realVal === undefined ||
    realVal?.length === 0
  ) {
    return emptVal;
  } else {
    return realVal;
  }
}

export function columnsToObject(name) {
  return JSON.parse(localStorage.getItem(name))?.filter(
    (e) => e.key !== "rowNum"
  );
}

export function convertFloat(val) {
  if (val.startsWith(".")) {
    let result = "0" + val;
    return parseFloat(result);
  } else {
    return parseFloat(val);
  }
}

const getForLikeSearch = (searchName) => {
  // "\\",
  //   ".",
  //   "+",
  //   "*",
  //   "?",
  //   "[",
  //   "^",
  //   "]",
  //   "$",
  //   "(",
  //   ")",
  //   "{",
  //   "}",
  //   "=",
  //   "!",
  //   "<",
  //   ">",
  //   "|",
  //   ":",
  //   "-";
  searchName = searchName.toLowerCase();
  searchName = searchName
    .replaceAll("[", "\\[")
    .replaceAll("$", "\\$")
    .replaceAll("?", "\\?")
    .replaceAll("+", "\\+")
    .replaceAll("|", "\\|")
    .replaceAll("*", "\\*")
    .replaceAll("(", "\\(")
    .replaceAll(")", "\\)");

  searchName = ".*" + searchName.replaceAll(" ", ".* .*") + ".*";
  return searchName;
};

export const validateTreeSelectFilter = (search, item) => {
  return (
    (item?.filter1 ?? "") +
    " " +
    (item?.filter2 ?? "") +
    " " +
    (item?.filter3 ?? "") +
    " " +
    (item?.filter4 ?? "") +
    " " +
    (item?.filter5 ?? "")
  )
    .toLowerCase()
    .match(getForLikeSearch(search));
};

export const validateSelectFilter = (search, item) => {
  return (
    (item?.filter1 ?? "") +
    " " +
    (item?.filter2 ?? "") +
    " " +
    (item?.filter3 ?? "") +
    " " +
    (item?.filter4 ?? "") +
    " " +
    (item?.filter5 ?? "")
  )
    .toLowerCase()
    .match(getForLikeSearch(search));
};


export function emptDate(realVal, emptVal) {
  return realVal !== undefined && realVal !== null && realVal !== "" ? moment(realVal) : emptVal;
}

export function dateRange(range) {
  return moment().startOf(range);
}

export const convertISOString = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return value?.toISOString().slice(0, 16) + "Z";
  } else return undefined;
};

export const dateTimeNow =()=>{
return moment();
}

export const handleRange = (key, fromName, toName, form) => {
  const daysOfWeek = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };
  const currentQuarter = moment().quarter();

  switch (key) {
    case 0:
      form.setFieldsValue({ [fromName]: moment().startOf('day'), [toName]: moment().endOf('day') })
      break;
    case 1:
      form.setFieldsValue({ [fromName]: moment().subtract(1, 'days').startOf('day'), [toName]: moment().subtract(1, 'days').endOf('day') })
      break;
    case 2:
      form.setFieldsValue({ [fromName]: moment().subtract(10, 'days').startOf('day'), [toName]: moment().endOf('day') })
      break;
    case 3:
      form.setFieldsValue({ [fromName]: moment().startOf('week').isoWeekday(daysOfWeek.sat), [toName]: moment().endOf('week').isoWeekday(daysOfWeek.sat - 1) })
      break;
    case 4:
      form.setFieldsValue({ [fromName]: moment().startOf('month'), [toName]: moment().endOf('month') })
      break;
    case 5:
      form.setFieldsValue({ [fromName]: moment().subtract(1, 'month').startOf('month'), [toName]: moment().subtract(1, 'month').endOf('month') })
      break;
    case 6:
      form.setFieldsValue({ [fromName]: moment().subtract(30, 'days').startOf('day'), [toName]: moment().endOf('day') })
      break;
    case 7:
      form.setFieldsValue({ [fromName]: moment().quarter(currentQuarter).startOf('quarter'), [toName]: moment().quarter(currentQuarter).endOf('quarter') })
      break;
    case 8:
      if (currentQuarter === 1) {
        form.setFieldsValue({
          [fromName]: moment().subtract(1, 'year').startOf('year').quarter(4).startOf('quarter'),
          [toName]: moment().subtract(1, 'year').startOf('year').quarter(4).endOf('quarter')
        })
      } else {
        form.setFieldsValue({
          [fromName]: moment().quarter(currentQuarter - 1).startOf('quarter'),
          [toName]: moment().quarter(currentQuarter - 1).endOf('quarter')
        })
      }
      break;
    case 9:
      form.setFieldsValue({ [fromName]: moment().startOf('year'), [toName]: moment().endOf('day') })
      break;
    case 10:
      form.setFieldsValue({ [fromName]: moment().startOf('year'), [toName]: moment().endOf('year') })
      break;
    case 11:
      form.setFieldsValue({ [fromName]: moment().subtract(1, 'year').startOf('year'), [toName]: moment().subtract(1, 'year').endOf('year') })
      break;
    default:
      form.setFieldsValue({ [fromName]: undefined, [toName]: undefined })
      break;
  }
}



