import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL + "/api/PrintPatt";

export function getPrintPatt(query) {
  return http.post(`${apiEndpoint}/GetTreeRep?search=${query}`);
}

export function initiale(id) {
  return http.post(`${apiEndpoint}/Initiale?invType=${id}`);
}

export function getNewCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}

export function getTypeDropDown() {
  return http.post(`${apiEndpoint}/GetTypeDropDown`);
}

export function getTableColumns(printType, fieldId) {
  return http.post(`${apiEndpoint}/GetTableColumns?printType=${printType}&fieldId=${fieldId}`);
}

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function editPattInv(obj) {
  if (obj.id !== "new" && obj.id !== undefined) {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getPrintPattById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function deletePrintPatt(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}
