import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Select,
  Empty,
  Row,
  Col,
  Menu,
  Dropdown,
  Switch,
  Checkbox,
  Spin,
  Typography,
  Tag
} from "antd";
import {
  getPricingMethodsById,
  editPricingMethods,
  getNewPricingMethodsCode,
  getActiveCalcSearch
} from "../../services/Items/pricingMethodsService";
import { RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import { handleEx } from "../helper/handleException";
import InputCurrency from '../helper/Input/InputCurrency';
import { objCountNum } from '../helper/countOfZero';
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import { shortCut } from "../helper/shortCuts";
const PricingMethodsForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const [isGoBack, setIsGoBack] = useState(true);
  const { Text } = Typography;
  const { Option } = Select;
  const [calculatedStatus, setCalculatedStatus] = useState(false);
  const [fixedStatus, setFixedStatus] = useState(false);
  const [isRoundStatus, setIsRoundStatus] = useState(false);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
 //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////
  const codeGenerator = async () => {
    const { data: newData } = await getNewPricingMethodsCode();
    form.setFieldsValue({ code: newData.data });
    IX_Name.current.focus();
    setLoadData(false);
  };

  useEffect(() => {
    async function fetchData() {
      handleSearchPriceMethod("");
      if (id === "new") {
        return await codeGenerator();
      } else {
        try {
          const { data } = await getPricingMethodsById(id);

          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            name: data.data.name,
            type: data.data.type,
            calcType: data.data.calcType,
            calcTypeOpt: data.data.calcTypeOpt,
            value: data.data.value,
            isRound: data.data.isRound,
            roundType: data.data.roundType,
            roundValue: data.data.roundValue,
            relPriceMethodId: data.data.relPriceMethodId,
            note: data.data.note,
            inactive: data.data.inactive,
          });
          setIsRoundStatus(data.data.isRound);
          setCalculatedStatus(data.data.type === 2 ? true : false)
          setFixedStatus(data.data.type === 2 ? true : false)
          IX_Name.current.focus();
        } catch (error) {
          handleEx(error,{IX_Code, IX_Name});
        }
        finally {
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      history.goBack();
    } else {
      form.resetFields();
      history.replace({ ...history.location, pathname: "/pricingMethod/new" });
      codeGenerator();
      IX_Name.current.focus();
    }
  };


  const priceMethodOptList =
    priceMethodOpt.length > 0 &&
    priceMethodOpt.map((item) =>
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    );

  const handleSearchPriceMethod = async (_Search) => {
    try {
      const { data: responce } = await getActiveCalcSearch(_Search);

      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error,{IX_Code, IX_Name});
    } finally {
      setLoadData(false);
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    try {
      const data = await editPricingMethods(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      goBack();
    } catch (error) {
      setLoadData(false);
      handleEx(error,{IX_Code, IX_Name});
    }

  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };



  const handleType = e => {
    if (e === 2) {
      setCalculatedStatus(true)
      setFixedStatus(true)
    }
    else if (e === 1) {
      setCalculatedStatus(false)
      setFixedStatus(false)
    } else {
      setCalculatedStatus(false)
      setFixedStatus(true)
    }
  }
  //******************************// //*******************// Form //*******************// //******************************//



  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);


  return (

      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />

        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="itemUnitform"
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{ type: 1 }}
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 20,
            }}
          >

            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text >{_t("strDefinitions")}</Text>
                      <Text style={{fontWeight:700}}>{_t("strPricingMethods")}</Text>
                    </Space>
                    <div>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Code}
                        bordered={false}
                      />
                    </Form.Item>
                    <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                      </div>
                  </Space>
                </Col>
              </Row>
            </div>


            <div name="field" className="frContent">

              <Row gutter={12}>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={ <div>
                      {_t("strName")}
                      <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 4,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + 1
                      </Tag>
                    </div>
                  </div>}

                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strName")}
                      maxLength={200}
                      autoComplete="off"
                      size="small"
                      onChange={promptForm}
                      ref={IX_Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="type"
                    label={_t("strType")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      showSearch
                      onChange={(e) => handleType(e)}
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      placeholder={_t("strNotSelected")}
                      size="small"

                    >
                      <Option value={1}>{_t("strFixed")}</Option>
                      <Option value={2}>{_t("strCalculated")}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {fixedStatus && <Col xs={24} md={12}>
                  <Form.Item
                    name="relPriceMethodId"
                    label={_t("strRelatedPriceMethod")}
                    rules={[{ required: calculatedStatus, message: `${_t("strIsRequired")}` }]}

                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={false}
                      onSearch={(value) => { handleSearchPriceMethod(value) }}
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      placeholder={_t("strNotSelected")}
                      size="small"
                    >
                      {priceMethodOptList}

                    </Select>
                  </Form.Item>
                </Col>}
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="calcType" label={_t("strCalcType")} rules={[{ required: calculatedStatus, message: `${_t("strIsRequired")}` }]}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}
                        placeholder={_t("strNotSelected")}
                        size="small">
                        <Option value={1}>{_t("strAddition")}</Option>
                        <Option value={2}>{_t("strDiscount")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                }
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="calcTypeOpt" label={_t("strCalcTypeOpt")} rules={[{ required: calculatedStatus, message: `${_t("strIsRequired")}` }]}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}
                        placeholder={_t("strNotSelected")}
                        size="small" >
                        <Option value={1}>{_t("strPercentage")}</Option>
                        <Option value={2}>{_t("strValues")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                }
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="value" label={_t("strValue")} rules={[{ required: calculatedStatus, message: `${_t("strIsRequired")}` }]}>

                      {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                      <InputCurrency precision={objCountNum.value} size="small" style={{ width: "100%" }} placeholder={_t("strValue")} onChange={promptForm} />

                    </Form.Item>
                  </Col>
                }
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="isRound" label={_t("strIsRound")} valuePropName="checked">
                      <Checkbox onChange={e => setIsRoundStatus(e.target.checked)} />
                    </Form.Item>
                  </Col>
                }
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="roundValue" label={_t("strRoundValue")} rules={[{ required: isRoundStatus, message: `${_t("strIsRequired")}` }]}>

                      {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                      <InputCurrency precision={objCountNum.value} size="small" style={{ width: "100%" }} placeholder={_t("strRoundValue")} onChange={promptForm} />

                    </Form.Item>
                  </Col>
                }
                {fixedStatus &&
                  <Col xs={24} md={12}>
                    <Form.Item name="roundType" label={_t("strRoundType")} rules={[{ required: isRoundStatus, message: `${_t("strIsRequired")}` }]}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }
                        placeholder={_t("strNotSelected")}
                        size="small"
                      >
                        <Option value={1}>{_t("strHigher")}</Option>
                        <Option value={2}>{_t("strLess")}</Option>
                        <Option value={2}>{_t("strCloser")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                }


                <Col xs={24} md={12}>
                  <Form.Item
                    name="note"
                    label={_t("strNote")}
                    rules={[{ min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strNote")}
                      maxLength={1000}
                      autoComplete="off"
                      size="small"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="inactive"
                    valuePropName="checked"
                    label={_t("strInActive")}
                  >
                    <Switch size="small" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                {!auth?.perPut && (
                  <div>
                  <Button type="primary" size="small" htmlType="submit" disabled={loading}>
                    {_t("strSave")}
                  </Button>
                   <div
                   style={{
                     opacity: 0.8,
                     zIndex: 99,
                     position: "absolute",
                     top: 24,
                   }}
                 >
                   <Tag color="#2b2b2b" hidden={!altPress}>
                     alt + S
                   </Tag>
                 </div>
               </div>
                )}
                  <Dropdown.Button
                      hidden={typeof onNewStore === "function"}
                      overlay={menu}
                      onClick={() => { history.action === "PUSH"? history.goBack() : history.push("/pricingMethod"); }}
                      size="small"
                    >
                      {" "}
                      {_t("strBack")}
                    </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>

      </React.Fragment>
 
  );
};

export default PricingMethodsForm;
