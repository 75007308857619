import axios from "axios";
import { message } from "antd";
import {
  logout,
  saveJwt,
  getJwt,
  getRefreshJwt,
  saveRefreshJwt,
} from "./Authorization/authService";

import _t from "../languages/translate";
import { authFromServer } from "./Authorization/authService";
import { Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { doMigrate } from "../services/Authorization/dbService";
import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/Auth`;

let isRefreshRequesting = false;
let requestsToRefresh = [];
let task = true;
export function setJwt(jwt) {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
}

axios.interceptors.response.use(
  (r) => {
    return r;
  },
  async (error) => {
    const { response, config } = error;

    if (response?.data?.message === "ex:DataBaseNotUpdatedToLastVersion") {
      if (task) {
        task = false;
        showPromiseConfirm();
      }
      return;
    }
    const status = response && response.status;
    if (status === 401 && response.data === "tokenExpired") {
      if (!isRefreshRequesting) {
        isRefreshRequesting = true;
        const tokenObj = {
          token: getJwt(),
          refreshToken: getRefreshJwt(),
        };
        axios
          .post(`${apiEndpoint}/refreshtoken`, tokenObj)
          .then(({ data }) => {
            // New token received

            setJwt(data.token);
            saveJwt(data.token);
            saveRefreshJwt(data.refreshToken);
            requestsToRefresh.forEach((cb) => cb(data.token));
          })
          .catch(() => {
            // An error occurred while getting a new token

            requestsToRefresh.forEach((cb) => cb(null));
            logout();
            window.location.href = "/";
          })
          .finally(() => {
            // Clear queue of failed requests
            requestsToRefresh = [];
            isRefreshRequesting = false;
          });
      }
      // The request is waiting for a new token.. [${response.config.url}]
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            config.headers.Authorization = "Bearer " + token;
            resolve(axios(config));
          }
          reject(error);
        });
      });
    } else if (status === 401 && !isRefreshRequesting) {
      logout();
      window.location.href = "/";
    } else if (status <= 400 && status > 500) {
      message.error(_t("msgServerDisconnected")); /// قطع الاتصال
    } else if (status === 409) {
      authFromServer();
      authError(response?.data?.message);
    } else {
      return Promise.reject(error);
    }
  }
);

const showPromiseConfirm = () => {
  confirm({
    title: _t("strThisVersionHasExpired"),
    icon: <ExclamationCircleFilled />,
    content:_t("msgVersionWillBeUpdated"),
    direction:_t("langDiriction"),
    okText:_t("strOK"),
    cancelText:_t("strCancel"),
    onOk() {
      setTimeout(async () => {
        await doMigrate();
        window.location.reload(true);
      }, 1000);
      task = true;
    },
    onCancel() {},
  });
};

const authError = (message) => {
  Modal.confirm({
    title: _t("strExpiration"),
    content: message + _t("msgReloadAuthConf"),
    icon: <LockOutlined />,
    direction: _t("langDiriction"),
    onOk() {
      window.location.href = "/";
    },
    onCancel() {},
  });
};

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default http;
