import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, message } from "antd";
import InputCurrency from "../../../../helper/Input/InputCurrency";
import SelectAccount from "../../../../helper/inputSelect/selectAccount";
import SelectAddInvAcc from "../../invFunctions/selectHelper/selectAddInvAcc";
import { objCountNum } from "../../../../helper/countOfZero";
import { emptString, emptValue } from "../../../../helper/helperMethods";
import CostCenterInput from "../../../../helper/Input/costCenterInput";
import { _RateMul } from "../../../../helper/handleMath";
import _t from "../../../../../languages/translate";

const AddsFields = ({
  innerRefSelect,
  refObj,
  form,
  addsDataSource,
  setAddsDataSource,
  setInvAddsOptReqCost,
  invAddsOptReqCost,
  promptForm,
  pattrenSet,
  setStatAddFields,
  disableAddPart,
  setActiveKey,
  auth,
}) => {
  const [accList, setAccList] = useState([]);

  //----------- cost center --------------------------------
  // const [invAddsOptReqCost, setInvAddsOptReqCost] = useState(0);

  //  useEffect(()=>{

  //   setInvAddsOptReqCost(checkTypeAccs(accList, newData?.data?.inv?.invAccId));
  //  },[addsDataSource])

  const handleAddsPerc = (e) => {
    const {
      invAddId: _InvAddId,
      invAddTotal: _Value,
      invAddTotalAcc: _AccId,
      invAddTotalDistr: _Distr,
      itemSubTotal: _subTotal,
      addInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    const calcAddVal = emptValue(
      emptValue(_subTotal, 0) !== 0
        ? emptValue(_RateMul(_subTotal, e), undefined)
        : 0,
      undefined
    );
    form.setFieldsValue({
      invAddTotal: calcAddVal,
    });
    handleAdd(
      emptValue(e, undefined),
      calcAddVal,
      _AccId,
      _Distr,
      _CostCenters,
      _InvAddId
    );
  };

  const handleAddsVal = (e) => {
    const {
      invAddId: _InvAddId,
      invAddTotalAcc: _AccId,
      invAddTotalDistr: _Distr,
      addInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    form.setFieldsValue({
      invAddTotalPer: undefined,
    });
    handleAdd(
      undefined,
      emptValue(e, undefined),
      _AccId,
      _Distr,
      _CostCenters,
      _InvAddId
    );
  };

  const handleAddsAcc = (e) => {
    const {
      invAddId: _InvAddId,
      invAddTotal: _Value,
      invAddTotalPer: _Perc,
      invAddTotalDistr: _Distr,
      addInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleAdd(
      _Perc,
      _Value,
      emptString(e, undefined),
      _Distr,
      _CostCenters,
      _InvAddId
    );
  };

  const handleAddsCheck = (e) => {
    const {
      invAddId: _InvAddId,
      invAddTotal: _Value,
      invAddTotalPer: _Perc,
      invAddTotalAcc: _AccId,
      addInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleAdd(_Perc, _Value, _AccId, e.target.checked, _CostCenters, _InvAddId);
  };

  const onHandelCostCenter = () => {
    const {
      invAddId: _InvAddId,
      invAddTotal: _Value,
      invAddTotalPer: _Perc,
      invAddTotalAcc: _AccId,
      invAddTotalDistr: _Distr,
      itemSubTotal: _subTotal,
      addInvCostCenter_Maps: _CostCenters,
    } = form.getFieldsValue(true);
    handleAdd(_Perc, _Value, _AccId, _Distr, _CostCenters, _InvAddId);
  };

  const handleAdd = (perc, val, acc, check, costCenters, invAddId) => {
    setStatAddFields(true);

    const list = [
      {
        id: invAddId,
        key: 0,
        value: val,
        perc: perc,
        accId: acc,
        accName: accList.filter((x) => x?.id === acc)[0]?.name,
        distributed: check,
        costCenter_Maps: costCenters,
        costCenterReqOpt: accList.filter((x) => x?.id === acc)[0]
          ?.costCenterReqOpt,
        nextAccId: addsDataSource[0].nextAccId,
        nextAccName: addsDataSource[0].nextAccName,
      },
      { key: 1 },
    ];
    setAddsDataSource([...list]);
  };

  return (
    <tr>
      <td
        style={{ textAlign: "end" }}
        hidden={!pattrenSet?.invAddTotalVisible || !auth?.perShowPrices}
      >
        <Form.Item name="invAddId" hidden>
          <Input />
        </Form.Item>
        <Input.Group compact style={{ direction: _t("langDiriction") }}>
          <Form.Item name="invAddTotalPer" noStyle>
            {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
            <InputCurrency
             innerRef={refObj?.refAddPerc}
              zIndex={105}
              precision={objCountNum.perc}
              size="small"
              style={{ width: 60, textAlign: "right", marginBottom: 3 }}
              onChange={(e) => {
                handleAddsPerc(e);
                promptForm();
              }}
              disabled={!disableAddPart}
            />
          </Form.Item>
          <Form.Item name="invAddTotal" noStyle>
            {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
            <InputCurrency
              innerRef={refObj?.refAddValue}
                zIndex={106}
              precision={objCountNum.value}
              size="small"
              style={{ width: 100, textAlign: "right", marginBottom: 3 }}
              onChange={(e) => {
                handleAddsVal(e);
                promptForm();
              }}
              disabled={!disableAddPart}
            />
          </Form.Item>
        </Input.Group>
      </td>
      <td
        hidden={!pattrenSet?.invAddTotalVisible || !auth?.perShowPrices}
        style={{ minWidth: 130, paddingLeft: 10, paddingRight: 10 }}
      >
        <label> {_t("strInvAddTotal")} </label>
      </td>
      {disableAddPart && (
        <>
          <td
            style={{ minWidth: "160px" }}
            hidden={
              (!pattrenSet?.addInvAccVisible || !auth?.perShowPrices) &&
              disableAddPart
            }
          >
            <Form.Item
              name="invAddTotalAcc"
              className="gen-input"
              rules={[
                {
                  required:
                    form.getFieldValue("invAddTotalPer") ||
                    form.getFieldValue("invAddTotal"),
                  message: "",
                  validator: async (_, value) => {
                    if (
                      (form.getFieldValue("invAddTotalPer") ||
                        form.getFieldValue("invAddTotal")) &&
                      (value === undefined || value === "")
                    ) {
                      setActiveKey("1");
                      message.error(
                        `(${_t("strInvAddTotalAcc")}) ${_t("strIsRequired")}`
                      );
                      throw new Error("message");
                    }
                  },
                },
              ]}
            >
              <SelectAccount
                addId={form.getFieldValue("invAddTotalAcc")}
                innerRef={refObj?.refAddAcc}
                zIndex={107}
                onChange={(e, relatedObj) => {
                  handleAddsAcc(e);
                  promptForm();
                  setInvAddsOptReqCost(relatedObj?.costCenterReqOpt);
                }}
                handelOptChange={(e) => setAccList(e)}
                placeholder={_t("strAccount")}
                style={{
                  minWidth: 160,
                  textAlign: "right",
                  marginBottom: 3,
                }}
                disabled={!pattrenSet?.addInvAccEnable}
              />
            </Form.Item>
          </td>
          <td
            hidden={
              (!pattrenSet?.addInvAccVisible || !auth?.perShowPrices) &&
              disableAddPart
            }
            style={{ minWidth: 75, paddingLeft: 10, paddingRight: 10 }}
          >
            <label>
              {_t("strAccount")}
              <label
                style={{
                  color: "red",
                  visibility:
                    form.getFieldValue("invAddTotalPer") ||
                    form.getFieldValue("invAddTotal")
                      ? ""
                      : "collapse",
                }}
              >
                *
              </label>
            </label>
          </td>

          <>
            <td hidden={!pattrenSet?.addInvAccCostCenterVisible || !auth?.perShowPrices}>
              <Form.Item name="addInvCostCenter_Maps" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.invAddTotalAcc !== curr.invAddTotalAcc
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="addInvCostCenter"
                      rules={[
                        {
                          required:
                            pattrenSet?.addInvAccCostCenterReq === true &&
                            emptString(addsDataSource[0]?.accId, false) !==
                              false &&
                            emptValue(addsDataSource[0]?.value, false) !==
                              false,
                          validator: async (_, value) => {
                            if (
                              pattrenSet?.addInvAccCostCenterReq === true &&
                              emptString(addsDataSource[0]?.accId, false) !==
                                false &&
                              emptValue(addsDataSource[0]?.value, false) !==
                                false &&
                              (value === undefined || value === "")
                            ) {
                              message.error(
                                `(${_t("strCostCenterInvAdd")}) ${_t(
                                  "strIsRequired"
                                )}`
                              );
                              throw new Error("message");
                            }
                          },
                        },
                      ]}
                    >
                      <CostCenterInput
                        innerRef={refObj?.refAddCostCent}
                        unValue={false}
                        zIndex={108}
                        style={{
                          width: 160,
                          textAlign: "right",
                          marginBottom: 3,
                        }}
                        form={form}
                        fieldName="addInvCostCenter"
                        arrName="addInvCostCenter_Maps"
                        accountValidationOpt={invAddsOptReqCost}
                        //type='input'
                        amount={getFieldValue("invAddTotal")}
                        onHandelData={onHandelCostCenter}
                        disabled={!pattrenSet?.addInvAccCostCenterEnable}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </td>
            <td
              hidden={!pattrenSet?.addInvAccCostCenterVisible || !auth?.perShowPrices}
              style={{
                minWidth: 130,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <label>
                {_t("strCostCenterInvAdd")}
                <label
                  style={{
                    color: "red",
                    visibility:
                      pattrenSet?.addInvAccCostCenterReq === true &&
                      emptString(addsDataSource[0]?.accId, false) !== false &&
                      emptValue(addsDataSource[0]?.value, false) !== false
                        ? ""
                        : "collapse",
                  }}
                >
                  *
                </label>
              </label>
            </td>
          </>
        </>
      )}
      {pattrenSet?.addInvAccVisible && disableAddPart && (
        <td
          hidden={
            (!pattrenSet?.addInvAccVisible || !auth?.perShowPrices) &&
            disableAddPart
          }
        >
          <Form.Item name="invAddTotalDistr" noStyle valuePropName="checked">
            <Checkbox
              tabIndex={109}
              onChange={(e) => {
                handleAddsCheck(e);
                promptForm();
              }}
              disabled={!pattrenSet?.addInvDistEnable}
            />
          </Form.Item>
          <label> {_t("strDistribution")} </label>
        </td>
      )}
    </tr>
  );
};

export default AddsFields;
