import React, { useEffect, useMemo, useState } from "react";
import DataTable, { pageSizeKey } from "../helper/gridDataTable/dataTable";
import { Button, Divider, Space, message, Popconfirm, Tooltip } from "antd";
import _t from "../../languages/translate";
import {
  DeleteOutlined,
  EditOutlined,
  SisternodeOutlined
} from "@ant-design/icons";
import SearchT from "../helper/gridDataTable/components/searchT";
import { useLocation, Link } from "react-router-dom";
import { getPagers, deleteMunfPatt } from "../../services/munfPattService";
import { deleteMunfPattSett } from "../../services/munfPattSettService";
import { handleEx } from "../helper/handleException";
import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";
import LinkToBranch from "../forms/munfPattren/helperPatt/popups/linkToBranch"

const PageMunfPatt = () => {
  const [auth, setAuth] = useState();
  const location = useLocation();
  const savedPageSize = localStorage.getItem(pageSizeKey);
  const [dataSource, setDataSource] = useState([]);
  const [showLinkToBransh, setShowLinkToBransh] = useState(false);
  const [munfPattSettId, setMunfPattSettId] = useState(undefined);
  const [munfPattId, setMunfPattId] = useState(undefined);
  const [munfTypeId, setMunfTypeId] = useState(undefined);
  const [meta, setMeta] = useState("");

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.acc)[0];

      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  useMemo(() => {
    authorization();
  }, []);
  //////////----|👉 end auth|----//////////

  const columns = [
    {
      key: "code",
      name: _t("strCode"),
      visible: true,
      width: 300,
    },
    {
      key: "nameAr",
      name: _t("strNameAr"),
      visible: true,
      width: 300,
    },
    {
      key: "nameEn",
      name: _t("strNameEn"),
      visible: true,
      width: 300,
    },
    {
      key: "type",
      name: _t("strType"),
      visible: true,
      width: 300,
    },
    {
      key: "branch",
      name: _t("strBranch"),
      visible: true,
      width: 300,
    },
  ];
  const [loadData, setLoadData] = useState(false);
  const realodTable = () => {
    getView();
  };

  useEffect(() => {
    getView();
  }, [location.search, savedPageSize]);

  const getView = async () => {
    setLoadData(true);
    const query = new URLSearchParams(location.search);
    if (savedPageSize && !query.has(pageSizeKey))
      query.set(pageSizeKey, savedPageSize);
    try {
      const { data: responce } = await getPagers(query.toString());

      if (responce.data.length > 0) {
        setDataSource(responce.data);
        setMeta(responce.meta);
      } else {
        setDataSource([]);
        message.info(_t("msgThereIsNoData"), 3);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteMunfPatt(id);
      getView();
      message.success(response.data.message);
    } catch (ex) {
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };
  const onDeletePattSett = async (id) => {
    try {
      const response = await deleteMunfPattSett(id);
      getView();
      message.success(response.data.message);
    } catch (ex) {
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const finishLink = () => {
    setShowLinkToBransh(false);
    setMunfPattSettId(undefined);
    setMunfTypeId(undefined);
    setMunfPattId(undefined);
    getView();
  };

  const actionButtons = (
    <div className="actionButtons">
      <Space size="small">
        {auth?.perPost && (
          <Button className="addNewButton" type="primary" size="small">
            <Link to={`/munfPatt/new`}>{_t("strAddNew")}</Link>
          </Button>
        )}
        <SearchT />
      </Space>
    </div>
  );

  const actionCol = [
    {
      key: "actions",
      name: _t("strActions"),
      visible: true,
      width: 300,
      formatter: (p) => {
        
        return (
          <>
            {/* {p.row.rowType !== "Patt" &&( */}
            <>
              {p.row.rowType === "Patt" ? (
                <>
                  <Tooltip title={_t("strEdit")} color="#6c63ff">
                    <Link
                      style={{ color: "#6c63ff" }}
                      to={`/munfPatt/${p.row?.id}`}
                    >
                      <EditOutlined />
                    </Link>
                  </Tooltip>
                  <Divider type="vertical" />
                </>
              ) : (
                <>
                  <EditOutlined
                    style={{ color: "#6c63ff" }}
                    onClick={() => {
                      
                      setMunfTypeId(p.row.munfTypeId);
                      setMunfPattSettId(p.row.id);
                      setMunfPattId(p.row.parentId)
                      setShowLinkToBransh(true);
                    }}
                  />

                  <Divider type="vertical" />
                </>
              )}

              {auth?.perDelete && p.row.rowType === "Patt" ? (
                <>
                  <Tooltip title={_t("strDelete")} color="red">
                    <Popconfirm
                      title={_t("strSureToDelete")}
                      onConfirm={() => onDelete(p.row.id)}
                    >
                      <DeleteOutlined style={{ color: "#EC2867" }} />
                    </Popconfirm>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title={_t("strDelete")} color="red">
                    <Popconfirm
                      title={_t("strSureToDelete")}
                      onConfirm={() => onDeletePattSett(p.row.id)}
                    >
                      <DeleteOutlined style={{ color: "#EC2867" }} />
                    </Popconfirm>
                  </Tooltip>
                </>
              )}

              {auth?.perPost && p.row.rowType === "Patt" && (
                <>
                  <Divider type="vertical" />
                  <Tooltip title={_t("strAddSubItem")} color="#4EA219">              
                      <SisternodeOutlined style={{ color: "#4EA219" }} 
                      
                      onClick={() => {
                        setMunfTypeId(p.row.munfTypeId);
                        //setMunfPattSettId("new");
                        setMunfPattId(p.row.id)
                        setShowLinkToBransh(true);
                      }}
                      />                  
                  </Tooltip>
                </>
              )}
            </>
            {/* )} */}
          </>
        );
      },
    },
  ];

  return (
    <>
     <LinkToBranch
        showModal={showLinkToBransh}
        setShowModal={setShowLinkToBransh}
        selectedTypeId={munfTypeId}
        munfPattSettId={munfPattSettId}
        munfPattId={munfPattId}
        onFinishLink={finishLink}
      />  
      <DataTable
        columns={[...columns, ...actionCol]}
        style={{ height: "calc(100vh - 195px)", width: "100%" }}
        dataSource={dataSource}
        tableName="Scr_MunfPatt"
        tree={true}
        frezzColExp={false}
        actionButtons={actionButtons}
        meta={meta}
        pageName={_t("strMunfModel")}
        groupName={_t("strPatterns")}
        type="page"
        handleReload={realodTable}
        loading={loadData}
      />
    </>
  );
};

export default PageMunfPatt;
