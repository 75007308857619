import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Cascader,
  Card,
  Tabs,  Spin,message,Space,Dropdown,Button
} from "antd";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import { getActiveTreeRepItems } from "../../../services/Items/itemService";
import { getUnitRep } from "../../../services/Items/itemUnitService";
import { getActiveTreeStore } from "../../../services/storeService";
import { QuestionCircleOutlined,EllipsisOutlined } from "@ant-design/icons";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import { searchMunfPatt } from "../../../services/munfPattService";
import SelectTreeMulty from "../../helper/selectTreeMulty";
import SelectMulty from "../../helper/selectMulty";
import SelectSingle from "../../helper/selectSingle";
import TimeRange from "../../helper/Input/TimeRange";
import { emptValue, handleRange } from "../../helper/helperMethods";
import Draggable from "react-draggable";
import {
  emptArr,
  emptDate,
  emptString,
  dateRange,
  convertISOString,
} from "../../helper/helperMethods";
import {
  postRepSett,
  postRepSettAllUser,
} from "../../../services/Reports/ReportSettService";

const FilterMunf = ({
  visible,
  setVisible,
  onHandelData,
  onFilterData
}) => {
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const draggleRef = useRef(null);
  const [itemInputState, setItemInputState] = useState("");
  const [itemOutputState, setItemOutputState] = useState("");
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [itemInputOpt, setItemInputOpt] = useState([]);
  const [itemOutputOpt, setItemOutputOpt] = useState([]);
  const [itemInputTreeId, setItemInputTreeId] = useState([]);
  const [itemOutputTreeId, setItemOutputTreeId] = useState([]);
  const [itemTypeSingleIds, setItemTypeSingleIds] = useState([]);
  const [storeOpt, setStoreOpt] = useState([]);
  const [storeState, setStoreState] = useState("");
  const [storeTreeId, setStoreTreeId] = useState([""]);
  const [unitOpt, setUnitOpt] = useState([]);
  const [munfPattOpt, setMunfPattOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
    // await setLoading(true);
     await handleMunfPattSearch("");
     await onItemInputSearch("");
     await onItemOutputSearch("");
     await handleGetUnit();
     await onStoreSearch("");
     await handlePriceMethod("");
     await handleSearchCurrency("");
   // await setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (onFilterData?.reportFilter !== undefined) {
        await fillData(onFilterData?.reportFilter);
      }
    };
    fetchData();
  }, [onFilterData]);

  const convertDateByRange = (range, fromDate, toDate) => {
    handleRange(range, fromDate, toDate, form);
  };


  const fillData = async (data) => {
    await form.setFieldsValue({
      rangeDate: onFilterData?.reportFilter === undefined? emptString(data?.rangeDate, 4) : data?.rangeDate,
      // fromDate: emptDate(data?.fromDate, ""),
      // toDate: emptDate(data?.toDate, ""),
      munfPattIds: emptArr(data?.munfPattIds, undefined),
      rawItemIds: emptArr(data?.rawItemIds, undefined),
      munfItemIds: emptArr(data?.munfItemIds, undefined),
      unitType: emptArr(data?.unitType, undefined),
      rawStoreIds: emptArr(data?.rawStoreIds, undefined),
      munfStoreIds: emptArr(data?.munfStoreIds, undefined),
      priceMethodIds: emptArr(data?.priceMethodIds, undefined),
      munfType: emptArr(data?.munfType, undefined),
      viewType: emptString(data?.viewType, undefined),
      currId: emptString(data?.currId, undefined),
    });

    if (onFilterData?.reportFilter === undefined) {
      convertDateByRange(emptString(data?.rangeDate, 4), "fromDate", "toDate");

      }else{
        convertDateByRange(data?.rangeDate, "fromDate", "toDate");

      }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onFinish = async (values) => {
    values.fromDate = convertISOString(values?.fromDate);
    values.toDate = convertISOString(values?.toDate);

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const onItemInputSearch = async (searchValue) => {
    try {
      setItemInputState(searchValue);
      let obj = {
        search: searchValue,
        addIds: itemInputTreeId,
        types: itemTypeSingleIds,
      };

      const { data: result } = await getActiveTreeRepItems(obj);

      setItemInputOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onItemOutputSearch = async (searchValue) => {
    try {
      setItemOutputState(searchValue);
      let obj = {
        search: searchValue,
        addIds: itemOutputTreeId,
        types: itemTypeSingleIds,
      };

      const { data: result } = await getActiveTreeRepItems(obj);

      setItemOutputOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleGetUnit = async () => {
    try {
      const { data: responce } = await getUnitRep("Rep_MunfMove");
      setUnitOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onItemOutputChange = (value) => {
    setItemOutputTreeId(value);
  };
  const onItemInputChange = (value) => {
    setItemInputTreeId(value);
  };

  const onStoreSearch = async (searchValue) => {
    try {
      setStoreState(searchValue);
      let obj = {
        search: searchValue,
        addIds: form.getFieldValue("storeIds"),
      };
      const { data: result } = await getActiveTreeStore(obj);
      setStoreOpt(result.data);
    } catch (error) {
      handleEx(error);
    }
  };
  const onStoreChange = (value) => {
    setStoreTreeId(value);
  };

  const handleMunfPattSearch = async (e) => {
    try {
      const { data: responce } = await searchMunfPatt(e);

      setMunfPattOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };


  const handlePriceMethod = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const handleItemTypeChange = (value) => {
    setItemTypeSingleIds(value);
  };

  const items = [
    {
      label: _t("strSaveOpt"),
      key: "saveOpt",
    },
    {
      label: _t("strSaveOptAsDefault"),
      key: "saveOptAsDefault",
    },
  ];

  const onClick = async ({ key }) => {
    try {
      await setLoading(true);
      setTimeout(async () => {
        let test = form.getFieldsValue(true);
        
        let obj = {
          reportName: "Rep_Items_MunfMove_AllFilterSett",
          reportFilter: JSON.stringify(form.getFieldsValue(true)),
        };
        if (key === "saveOpt") {
          await postRepSett(obj);
          message.success(_t("strDoneSuccessfully"));
        } else if (key === "saveOptAsDefault") {
          await postRepSettAllUser(obj);
          message.success(_t("strDoneSuccessfully"));
        }
        setLoading(false);
      }, 500);
    } catch (error) {
      handleEx(error);
    }
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        >  
          <Space>
          {_t("strMunfRep")}
            <Dropdown menu={{ items, onClick }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Button
                  type="text"
                  icon={
                    <EllipsisOutlined
                      style={{
                        color: "#717171",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    />
                  }
                />
              </a>
            </Dropdown>
          </Space>
        </div>
      }
      width={800}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{ padding: "0px 15px 5px 15px" }}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={_t("strSearch")}
    >
<Spin spinning={loading} tip={_t("strLoading")}>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        onFinish={onFinish}
        initialValues={{
          // fromDate: dateRange("month"),
          // toDate: dateRange(),
          currId: window.localStorage.getItem("currId")
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="عام" key="1" forceRender>
            <Row gutter={12}>
              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                <Form.Item name="munfPattIds" label={_t("strMunfModel")}>
                    <SelectMulty  
                   
                      placeholder={_t("strMunfModel")}
                      size="small"
                      opt={munfPattOpt}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <SelectTreeMulty
                    name="rawItemIds"
                    label={_t("strTheInput")}
                    opt={itemInputOpt}
                    setOpt={setItemInputOpt}
                    state={itemInputState}
                    setState={setItemInputState}
                    placeholder={_t("strTheInput")}
                    onChange={onItemInputChange}
                  />
                </Col>
                <Col span={24}>
                  <SelectTreeMulty
                    name="munfItemIds"
                    label={_t("strTheOutput")}
                    opt={itemOutputOpt}
                    setOpt={setItemOutputOpt}
                    state={itemOutputState}
                    setState={setItemOutputState}
                    placeholder={_t("strTheOutput")}
                    onChange={onItemOutputChange}
                  />
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="unitType"
                    tooltip={{
                      title: _t("msgItemUnitRepType"),
                      icon: <QuestionCircleOutlined />,
                    }}
                    label={_t("strUnit")}
                  >
                    <Cascader
                      showSearch
                      allowClear={true}
                      // style={{width:"150px"}}
                      placeholder={_t("strNotSelected")}
                      optionFilterProp="children"
                      options={unitOpt}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Card>

              <Card
                style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                bodyStyle={{ padding: 0 }}
              >
                <Col span={24}>
                  <SelectTreeMulty
                    name="rawStoreIds"
                    label={_t("strTheInputStore")}
                    opt={storeOpt}
                    setOpt={setStoreOpt}
                    state={storeState}
                    setState={setStoreState}
                    treeId={storeTreeId}
                    setTreeId={setStoreTreeId}
                    onChange={onStoreChange}
                  />
                </Col>
                <Col span={24}>
                  <SelectTreeMulty
                    name="munfStoreIds"
                    label={_t("strTheOutputStore")}
                    opt={storeOpt}
                    setOpt={setStoreOpt}
                    state={storeState}
                    setState={setStoreState}
                    treeId={storeTreeId}
                    setTreeId={setStoreTreeId}
                    onChange={onStoreChange}
                  />
                </Col>
                <Col xs={24}>
                  <Row>
                    <TimeRange
                      mainForm={Form}
                      form={form}
                      rangeName="rangeDate"
                      lable={_t("strDate")}
                      fromName="fromDate"
                      toName="toDate"   
                   //   defaultRange={4}                 
                    />
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="priceMethodIds" label={_t("strPriceMethod")}>
                    <SelectMulty
                      placeholder={_t("strPriceMethod")}
                      opt={priceMethodOpt}
                    />
                  </Form.Item>
                </Col>
              </Card>
            </Row>
          </TabPane>

          <TabPane tab="الخيارات" key="3" forceRender>
            <Row gutter={12}>
              <Col span={24}>
                <Card
                  style={{ width: "100%", margin: "5px 0px 5px 0px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Col xs={24}>
                    <Row gutter={12}>
                      <Col span={24}>
                        <Form.Item
                          name="munfType"
                          label={_t("strMunfOrderType")}
                        >
                          <SelectMulty
                            placeholder={_t("strMunfOrderType")}
                            opt={[{ id: 1, name: _t("strComplex"), filter1: _t("strComplex") },
                            { id: 2, name: _t("strManual"), filter1: _t("strManual") }]}
                          />

                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="viewType" label={_t("strShowStyle")}>
                          <SelectSingle
                            placeholder={_t("strShowStyle")}
                            opt={[{ id: 0, name: _t("strTotal2"), filter1: _t("strTotal2") },
                            { id: 1, name: _t("strDetailed"), filter1: _t("strDetailed") },
                            { id: 2, name: _t("strShowItems"), filter1: _t("strShowItems") }]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="currId" label={_t("strCurrency")}>
                          <SelectSingle
                          allowClear={false}
                            fieldNames={{
                              label: "currName",
                              value: "currId",
                            }}
                            opt={currencyOpt}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
      </Spin>
    </Modal>
  );
};

export default FilterMunf;
