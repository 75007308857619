import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Form,
  message,
  Modal,
  Input,
  Tooltip,
  Card,
  Button,
  Popover,
  Divider,
} from "antd";
import {
  DeleteTwoTone,
  ExclamationCircleOutlined,
  FileSearchOutlined,
  DiffOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _t from "../../../../languages/translate";
import { handleEx } from "../../../helper/handleException";
import ItemInvSelector from "../../../helper/Modal/ItemInvSelector";
import CheckQtyItems from "../../../helper/Modal/CheckQtyItems";
import CheckPriceItems from "../../../helper/Modal/CheckPriceItems";
import { getSearchPopup } from "../../../../services/Items/itemService";
import { emptValue } from "../../../helper/helperMethods";
import DropDownList from "../../../helper/dataGrid/source/DropDownList";
import CurrEditor from "../../../helper/dataGrid/source/CurrEditor";
import { TextEditor, SelectCellFormatter } from "react-data-grid";
import DataGrid from "react-data-grid";
import { getNumFormat } from "../../../helper/countOfZero";
import {
  handleQty,
  handleQtyTotal,
  handleDisPerc,
  calcTotal,
} from "./handelColumns";
import { _Div, _Mul, _RateMul, _SumArr } from "../../../helper/handleMath";

const MunfDetails = ({
  auth,
  innerRef,
  pattrenSet,
  form,
  setMunfDetail,
  munfDetail,
  readOnly = true,
  setLoadData,
  promptForm,
  noStyle,
  showTotalQty = false,
}) => {
  const [searchItemInvValue, setSearchItemInvValue] = useState("");
  const [showMunfModal, setShowMunfModal] = useState(false);
  const [showQtyItemsModal, setShowQtyItemsModal] = useState(false);
  const [showPriceItemsModal, setShowPriceItemsModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rowNumberEdit, setRowNumberEdit] = useState(0);
  const { confirm } = Modal;

  const linkCont = (p) => (
    <>
      {auth?.perShowQtyDetails && (
        <div>
          <Button
            type="link"
            onClick={() =>
              handleOpenQtyItem(
                p?.row.itemUnit_MapId,
                form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                p?.row.itemName
              )
            }
            icon={<FileSearchOutlined />}
          >
            {_t("strQTY")}
          </Button>
        </div>
      )}
      {auth?.perShowPrices && (
        <div>
          <Button
            type="link"
            onClick={() =>
              handleOpenPriceItem(
                p?.row.itemUnit_MapId,
                form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
                p
              )
            }
            icon={<DiffOutlined />}
          >
            {_t("strPrice")}
          </Button>
        </div>
      )}
      <Divider style={{ margin: 5 }} />
      <div>
        <Button
          type="link"
          onClick={() => handleDelete(p.row?.key)}
          icon={<DeleteOutlined />}
          style={{ color: "red" }}
        >
          {_t("strDelete")}
        </Button>
      </div>
    </>
  );
  const [lineUnitId, setLineUnitId] = useState("");
  const [lineItemName, setLineItemName] = useState("");
  const [lineDate, setLineDate] = useState("");
  const [pRow, setPRow] = useState({});
  let mainObj;
  const handleOpenQtyItem = (unitId = "", date = "", itemName = "") => {
    setLineUnitId(unitId);
    setLineItemName(itemName);
    setLineDate(date);

    setShowQtyItemsModal(true);
  };

  const handleOpenPriceItem = (unitId = "", date = "", p) => {
    setLineUnitId(unitId);
    setLineDate(date);

    setPRow(p);
    setShowPriceItemsModal(true);
  };

  const allColumns = [
    {
      name: "#",
      key: "num",
      minWidth: 30,
      width: 43,
      visible: true,
      formatter: (p) => {
        return p.row?.itemId ? (
          <Popover
            placement="bottomRight"
            trigger="click"
            style={{ width: "100%" }}
            content={() => linkCont(p)}
          >
            <label
              className="keyNum"
              style={{
                width: "100%",
                color: "#1d98f4",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {p?.row?.key + 1}
            </label>
          </Popover>
        ) : (
          p?.row?.key + 1
        );
      },
      summaryFormatter({ row }) {
        return (
          <strong style={{ color: "#7e7e7e" }}>{row.totalCount - 1}</strong>
        );
      },
    },
    {
      name: _t("strItems"),
      key: "itemName",
      width: 200,
      visible: true,
      editor: (p) => {
        return (
          <Input
            autoFocus
            size="small"
            bordered={false}
            className="TextEditor"
            // defaultValue={p.row?.itemName}
            onPressEnter={(e) => itemSearch(e.target.value, p.row.key)}
          />
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
      summaryFormatter() {
        return <strong style={{ color: "#7e7e7e" }}>الإجمالي</strong>;
      },
    },
    {
      name: _t("strUnits"),
      key: "itemUnit_MapId",
      width: 100,
      visible: true,
      editor: (p) => (
        <DropDownList
          value={p.row?.itemUnit_MapId}
          bordered={false}
          className="TextEditor"
          onChange={(e) => {
            p?.onRowChange({ ...p.row, itemUnit_MapId: e }, true);
            //changeUnit(e);
          }}
          options={p?.row?.units}
        />
      ),
      formatter(props) {
        const id = props?.row?.itemUnit_MapId;
        let result = id
          ? props?.row?.units?.find((c) => c?.id === id)?.name
          : null;
        if (result !== null && result !== undefined) return result;
        else return null;
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strQuantity"),
      key: "qty",
      width: 100,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              mainObj = await handleQty(
                p,
                e,
                mainObj,
                emptValue(form.getFieldValue("countPriv"), 1)
              );
              setRowChanges(p, mainObj);
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.qty}>{getNumFormat(p?.row?.qty, 0)}</Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQty}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQty, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strQtyTotal"),
      key: "qtyTotal",
      width: 150,
      visible: showTotalQty,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              mainObj = await handleQtyTotal(
                p,
                e,
                mainObj,
                emptValue(form.getFieldValue("countPriv"), 1)
              );
              setRowChanges(p, mainObj);
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.qtyTotal}>
            {getNumFormat(p?.row?.qtyTotal, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalQtyTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalQtyTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strPrice"),
      key: "price",
      width: 150,
      visible: true,
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.price}>
            {getNumFormat(p?.row?.price, 2)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalPrice}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalPrice, 2)}
            </strong>
          </Tooltip>
        );
      },
    },
    {
      name: _t("strTotal"),
      key: "total",
      width: 150,
      visible: true,
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.total}>
            {getNumFormat(p?.row?.total, 0)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.totalTotal}>
            <strong style={{ color: "#7e7e7e" }}>
              {getNumFormat(row.totalTotal, 0)}
            </strong>
          </Tooltip>
        );
      },
    },
    {
      key: "note",
      name: _t("strNote"),
      width: 200,
      visible: true,
      editor: TextEditor,
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strDistributionRatio"),
      key: "distributePerc",
      width: 100,
      visible: true,
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={async (e) => {
              mainObj = await handleDisPerc(
                p,
                e,
                mainObj,
                emptValue(form.getFieldValue("rawTotal"), 0)
              );
              setRowChanges(p, mainObj);
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={p?.row?.distributePerc}>
            {getNumFormat(p?.row?.distributePerc, 5)}
          </Tooltip>
        );
      },
      summaryFormatter({ row }) {
        return (
          <Tooltip title={row.distributePerc}>
            <strong
              style={
                emptValue(row.distributePerc, 0) > 100 ||
                emptValue(row.distributePerc, 0) < 100
                  ? { color: "#fe0037" }
                  : { color: "#33aa55" }
              }
            >
              %{getNumFormat(row.distributePerc, 0)}
            </strong>
          </Tooltip>
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
    {
      name: _t("strDefault"),
      key: "isDefault",
      width: 50,
      visible: true,
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.isDefault}
            onChange={() => {
              onRowChange({
                ...row,
                isDefault: !row.isDefault,
              });
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
      editable: (row) => row?.rowType !== "read" && readOnly,
    },
  ];

  const handleDelete = (key) => {
    const newUnit = munfDetail.filter((e) => e.key !== key);
    let newNum = 0;
    for (let i = 0; i < newUnit.length; i++) {
      newUnit[i].key = newNum;
      newNum++;
    }
    setMunfDetail([...newUnit]);
    if (munfDetail.length <= 1) {
      setMunfDetail([{ key: 0 }]);
    }
  };

  //#region >> Calc

  const setRowChanges = (p, obj) => {
    if (obj !== undefined) {
      p?.onRowChange(
        {
          ...p.row,
          itemUnit_MapId: obj?.itemUnit_MapId,
          qty: emptValue(obj?.qty, null),
          qtyTotal: emptValue(obj?.qtyTotal, null),
          price: emptValue(obj?.price, null),
          total: emptValue(obj?.total, null),
          distributePerc: emptValue(obj?.distributePerc, null),
          note: obj?.note,
        },
        true
      );
      let data = munfDetail;
      data[p.row.key] = obj;
      setRow([...data]);
    }
  };

  const setRow = (list) => {
    // list.push({ key: list.length });
    setMunfDetail([...list]);
    promptForm();
  };

  // #endregion

  //#region >> Table Search & Insert

  const handleItemInvSelector = async (result) => {
    appendAccSelector(result);
  };

  useEffect(() => {
    if (pattrenSet === null) {
      let res = allColumns.filter((e) => e.visible !== false);
      setColumns(res);
    } else {
      setColumns([allColumns[0]]);
      pattrenSet?.munfMaterialsColSett.map((e) => fillColumns(e));
    }
  }, [pattrenSet?.munfMaterialsColSett, munfDetail]);

  const fillColumns = (tmpObj) => {
    allColumns.map((e) => {
      if (e.key === tmpObj.key && e.visible !== false) {
        e.name = tmpObj.name;
        e.width = tmpObj.width;
        setColumns((x) => [...x, e]);
      }
    });
  };

  const itemSearch = async (value, key) => {
    try {
      setRowNumberEdit(key);
      const { data: result } = await getSearchPopup(value);
      if (result.data.length === 1) {
        insertSingleRec(result.data, key);
      } else if (result.data.length < 1) {
        message.warning(_t("msgThereAreNoDataSearch"));
      } else {
        setSearchItemInvValue(value);
        setShowMunfModal(true);
        window.sessionStorage.setItem("showMunfModal", true);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  const insertSingleRec = async (value, row) => {
    munfDetail[row].itemId = value[0].itemId;
    munfDetail[row].itemName = value[0].itemName;
    munfDetail[row].itemUnit_MapId = value[0].itemUnit_MapId;
    munfDetail[row].unitName = value[0].unitName;
    munfDetail[row].units = value[0].units;
    sortDataKey([...munfDetail]);
  };

  const appendAccSelector = (result) => {
    if (result.length === 1) {
      insertSingleRec(result, rowNumberEdit);
    } else {
      let dataCollect;
      let tmpdata = munfDetail.find((x) => x.key === rowNumberEdit);
      if (tmpdata?.itemId !== undefined) {
        dataCollect = munfDetail;
        let i = 0;
        result.forEach((e, index) => {
          let replaceItem = 0;
          if (i === 0) replaceItem = 1;
          let memoData = e;
          if (index === 0) {
            memoData.id = munfDetail[rowNumberEdit + i].id;
          }
          dataCollect.splice(rowNumberEdit + i, replaceItem, memoData);
          i++;
        });
      } else {
        let memoData = result;
        dataCollect = [...munfDetail, ...memoData];
      }

      sortDataKey(dataCollect);
    }

    setTimeout(() => {
      setShowMunfModal(false);
      window.sessionStorage.setItem("showMunfModal", false);
    });
  };

  const sortDataKey = async (data) => {
    let list = [];
    let KeyNum = 0;

    let _pers = data.some((e) => e?.distributePerc > 0);

    for (let i = 0; i < data.length; i++) {
      if (data[i].itemId !== undefined) {
        let obj = {};
        obj["key"] = KeyNum;
        obj["itemId"] = data[i].itemId;
        obj["itemName"] = data[i].itemName;
        obj["itemUnit_MapId"] = data[i].itemUnit_MapId;
        obj["qty"] = emptValue(data[i].qty, 1);
        obj["qtyTotal"] = emptValue(
          _Mul(
            emptValue(data[i].qty, 1),
            emptValue(form.getFieldValue("countPriv"), 1)
          ),
          null
        );
        obj["price"] =
          _pers === false && i === 0
            ? emptValue(form.getFieldValue("rawPrice"), null)
            : emptValue(data[i].price, null);
        obj["total"] =
          _pers === false && i === 0
            ? emptValue(form.getFieldValue("rawItemTotal"), null)
            : emptValue(data[i].total, null);
        obj["note"] = data[i].note;
        obj["distributePerc"] =
          _pers === false && i === 0
            ? 100
            : emptValue(data[i].distributePerc, null);
        obj["isDefault"] = data[i].isDefault;
        obj["unitName"] = data[i].unitName;
        obj["units"] = data[i].units;
        obj = await calcTotal(
          obj,
          emptValue(form.getFieldValue("rawTotal"), 0)
        );
        list.push(obj);
        KeyNum++;
      }
    }
    list.push({ key: list.length });
    promptForm();

    await setMunfDetail(list);
  };

  // #endregion

  const sumQty = (arr) => {
    return _SumArr(arr, "qty");
  };

  const sumQtyTotal = (arr) => {
    return _SumArr(arr, "qtyTotal");
  };

  const sumPrice = (arr) => {
    return _SumArr(arr, "price");
  };

  const sumTotal = (arr) => {
    return _SumArr(arr, "total");
  };

  const sumDistributePerc = (arr) => {
    return _SumArr(arr, "distributePerc");
  };

  const summaryRows = useMemo(() => {
    const summaryRow = [
      {
        id: "total_0",
        totalCount: munfDetail?.length,
        totalQty: sumQty(munfDetail),
        totalQtyTotal: sumQtyTotal(munfDetail),
        totalPrice: sumPrice(munfDetail),
        totalTotal: sumTotal(munfDetail),
        distributePerc: sumDistributePerc(munfDetail),
      },
    ];
    return summaryRow;
  }, [munfDetail]);

  return (
    <>
      <ItemInvSelector
        searchValue={searchItemInvValue}
        onHandelData={handleItemInvSelector}
        showModal={showMunfModal}
        setShowModal={setShowMunfModal}
        showModalName="showMunfModal"
      />
      <CheckQtyItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        itemName={lineItemName}
        showModal={showQtyItemsModal}
        setShowModal={setShowQtyItemsModal}
      />

      <CheckPriceItems
        itemUnitMapId={lineUnitId}
        mainDate={lineDate}
        rate={form.getFieldValue().currRate}
        showModal={showPriceItemsModal}
        setShowModal={setShowPriceItemsModal}
        isInsert={false}
        pRow={pRow}
      />
      <Form.Item
        labelCol={{
          flex: "100px",
        }}
        wrapperCol={{
          span: 23,
        }}
        label={_t("strTheOutput")}
        noStyle={noStyle}
      >
        <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 10 }}>
          <DataGrid
            ref={innerRef}
            columns={[...columns]}
            rows={munfDetail}
            style={{
              height: `calc(100vh - 400px)`,
              width: "100%",
            }}
            rowHeight={25}
            className="rdg-light"
            onRowsChange={setMunfDetail}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            direction={_t("langDiriction")}
            summaryRows={summaryRows}
          />
        </Card>
      </Form.Item>
    </>
  );
};
export default MunfDetails;
