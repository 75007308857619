import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  Checkbox,
  Tabs,
  Spin,
  message,
  InputNumber,
  Typography,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import _t from "../../../../languages/translate";
import SelectSingle from "../../../helper/selectSingle";
import { GetActiveChildren } from "../../../../services/AccountService";
import { getClientActiveSearch } from "../../../../services/clientService";
import { getActivePayMethodSearch } from "../../../../services/Items/payMethodService";
import { GetActiveStoreChildren } from "../../../../services/storeService";
import { getCurrActiveSearch } from "../../../../services/Items/currenciesService";
import { getActivePriceMethodSearch } from "../../../../services/Items/pricingMethodsService";
import { getActiveJournal } from "../../../../services/journalService";
import { emptArr, emptValue } from "../../../helper/helperMethods";
import { convertFromSeve } from "../../../helper/gridSettTable/components/helperMethods";
import { getByBranch } from "../../../../services/EntPatSettService";
import { calcAndReturnList } from "../../../helper/Modal/costCenterModal/costCenterHelper";
// import { checkEntType } from "./showFieldByType";
import ComplexTableSett from "./popups/complexTableSett";
import MunfTableSett from "./popups/munfTableSett";
import RawTableSett from "./popups/rawTableSett";
import { emptString } from "../../../helper/helperMethods";
import { handleEx } from "../../../helper/handleException";
import SelectAccount from "../../../helper/inputSelect/selectAccount";
const PattSettings = ({
  form,
  dataObj,
  selectedTypeId,
  selectedBranchId,
  setLoadData,
}) => {
  // const [loadData, setLoadData] = useState(false);
  const [showField, setShowField] = useState(undefined);
  // main table-----------
  const [showComplexColSett, setShowComplexColSett] = useState(false);
  const [showMunfColSett, setShowMunfColSett] = useState(false);
  const [showRawColSett, setShowRawColSett] = useState(false);
  const [complexDataSource, setComplexDataSource] = useState([]);
  const [rawDataSource, setRawDataSource] = useState([]);
  const [munfDataSource, setMunfDataSource] = useState([]);
  // const [accOptReqCost, setAccOptReqCost] = useState(0);
  const [complexColumns, setComplexColumns] = useState([]);
  const [rawColumns, setRawColumns] = useState([]);
  const [munfColumns, setMunfColumns] = useState([]);
  // const [relatedPattSettOpt, setRelatedPattSettOpt] = useState([]);

  const [activeKey, setActiveKey] = useState("1");
  const { Text } = Typography;
  useEffect(() => {
    async function fetchData() {
      await setLoadData(true);
      // handleAccActSearch();
      await handlePayMethodSearch();
      // handleClientSearch();
      await handleStoreSearch();
      await handleSearchCurrency();
      await handlePriceMethodSearch();
      await handleJornal();
      // const obj = checkMunfType(selectedTypeId);
      // setShowField(obj);

      if (dataObj !== undefined) {
        await fillData(dataObj);
      }
      await setLoadData(false);
    }
    fetchData();
  }, [dataObj]);

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      const arr = costList.map((e) => {
        e.value = 0;
        return e;
      });
      const reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, arr, emptValue(reqOpt, 0))?.text;
    }
  };

  useEffect(() => {
    const callRelatedPattSett = async () => {
      const { data: data } = await getByBranch(selectedBranchId);
      // setRelatedPattSettOpt(data.data);
    };
    callRelatedPattSett();
  }, [selectedBranchId]);

  const fillData = async (data) => {
    // const accList = await handleAccAct();
    await form.setFieldsValue({
      munfType: data?.data?.munfType,
      codeStart: data?.data?.codeStart,
      codeEnable: data?.data?.codeEnable,
      fromStoreVisible: data?.data?.fromStoreVisible,
      fromStoreEnable: data?.data?.fromStoreEnable,
      fromStoreDefId: emptString(data?.data?.fromStoreDefId, undefined),
      toStoreVisible: data?.data?.toStoreVisible,
      toStoreEnable: data?.data?.toStoreEnable,
      toStoreDefId: emptString(data?.data?.toStoreDefId, undefined),
      dateVisible: data?.data?.dateVisible,
      dateEnable: data?.data?.dateEnable,
      currSource: data?.data?.currSource,
      currVisible: data?.data?.currVisible,
      currEnable: data?.data?.currEnable,
      currChType: data?.data?.currChType,
      currDefId: emptString(data?.data?.currDefId, undefined),
      priceMethodSource: data?.data?.priceMethodSource,
      priceMethodVisible: data?.data?.priceMethodVisible,
      priceMethodEnable: data?.data?.priceMethodEnable,
      priceMethodDefId: emptString(data?.data?.priceMethodDefId, undefined),
      lastPurEffect: data?.data?.lastPurEffect,
      costEffect: data?.data?.costEffect,
      isStoreEffect: data?.data?.isStoreEffect,
      journalId: emptString(data?.data?.journalId, undefined),

      docVisible: data?.data?.docVisible,
      docReq: data?.data?.docReq,
      inDateVisible: data?.data?.inDateVisible,
      inDateEnable: data?.data?.inDateEnable,
      outDateVisible: data?.data?.outDateVisible,
      outDateEnable: data?.data?.outDateEnable,
      modelVisible: data?.data?.modelVisible,
      modelEnable: data?.data?.modelEnable,

      detailsModelColSett: data?.data?.detailsModelColSett,
      rawMaterialsColSett: data?.data?.rawMaterialsColSett,
      munfMaterialsColSett: data?.data?.munfMaterialsColSett,
    });
  };
  const onTabsChange = (key) => {
    setActiveKey(key);
  };

  const handleShowComplexTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("detailsModelColSett"));

    setComplexDataSource(tempObj.dataSource);
    setComplexColumns(tempObj.columns);
    setShowComplexColSett(true);
  };

  const handleShowRawTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("rawMaterialsColSett"));

    setRawDataSource(tempObj.dataSource);
    setRawColumns(tempObj.columns);
    setShowRawColSett(true);
  };

  const handleShowMunfTable = () => {
    const tempObj = convertFromSeve(form.getFieldValue("munfMaterialsColSett"));

    setMunfDataSource(tempObj.dataSource);
    setMunfColumns(tempObj.columns);
    setShowMunfColSett(true);
  };

  const [payMethodOpt, setPayMethodOpt] = useState([]);
  const handlePayMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePayMethodSearch("");
      setPayMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [storeOpt, setStoreOpt] = useState([]);
  const handleStoreSearch = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setStoreOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [currencyOpt, setCurrencyOpt] = useState([]);
  const handleSearchCurrency = async () => {
    try {
      const { data: responce } = await getCurrActiveSearch("");
      setCurrencyOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const handlePriceMethodSearch = async () => {
    try {
      const { data: responce } = await getActivePriceMethodSearch("");
      setPriceMethodOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [jornalOpt, setJornalOpt] = useState([]);
  const handleJornal = async (_Search) => {
    try {
      const { data: responce } = await getActiveJournal("", "");
      setJornalOpt(responce?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const generalCards = (
    <div className="mainCardsContent">
      <div name="generalCards" className="frContent warpCardsContent">
        <Card
          type="inner"
          title={<strong>{_t("strOrderType")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item
            name="munfType"
            label={_t("strType")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={false}
              defaultValue={3}
              opt={[
                {
                  id: 3,
                  name: _t("strNotSelected"),
                  filter1: _t("strNotSelected"),
                },
                {
                  id: 1,
                  name: _t("strManual"),
                  filter1: _t("strManual"),
                },
                {
                  id: 2,
                  name: _t("strComplex"),
                  filter1: _t("strComplex"),
                },
              ]}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        {/*Code*/}
        <Card
          type="inner"
          title={<strong>{_t("strCode")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item name="codeEnable" valuePropName="checked">
            <Checkbox>{_t("strEnable")}</Checkbox>
          </Form.Item>
          <Form.Item
            name="codeStart"
            label={_t("strStartNumber")}
            labelCol={{ flex: "70px" }}
          >
            <InputNumber
              size="small"
              placeholder={_t("strStartNumber")}
              style={{ width: "100%" }}
              controls={false}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strFromStore")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="fromStoreVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="fromStoreEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name="fromStoreDefId"
            label={_t("strDefault")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={storeOpt}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strToStore")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="toStoreVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="toStoreEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name="toStoreDefId"
            label={_t("strDefault")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={storeOpt}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strDate")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="dateVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="dateEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strCurrSource")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item
            name="currSource"
            label={_t("strSource")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={[
                {
                  id: 1,
                  name: _t("strFromOrder"),
                  filter1: _t("strFromOrder"),
                },
                {
                  id: 2,
                  name: _t("strFromPatt"),
                  filter1: _t("strFromPatt"),
                },
              ]}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strCurrency")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="currVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="currEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name="currChType"
            label={_t("strExchangeRateAdjustment")}
            labelCol={{ flex: "120px" }}
          >
            <SelectSingle
              allowClear={false}
              defaultValue={0}
              opt={[
                {
                  id: 0,
                  name: _t("strSaveDirectly"),
                  filter1: _t("strSaveDirectly"),
                },
                {
                  id: 1,
                  name: _t("strNeverSave"),
                  filter1: _t("strNeverSave"),
                },
                {
                  id: 2,
                  name: _t("strQuestionBeforeSaving"),
                  filter1: _t("strQuestionBeforeSaving"),
                },
              ]}
              placeholder={_t("strMethodOfDetermining")}
            />
          </Form.Item>

          <Form.Item
            name="currDefId"
            label={_t("strDefault")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={false}
              opt={currencyOpt}
              fieldNames={{ label: "currName", value: "currId" }}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strPriceMethodSource")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item
            name="priceMethodSource"
            label={_t("strSource")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={[
                {
                  id: 1,
                  name: _t("strFromOrder"),
                  filter1: _t("strFromOrder"),
                },
                {
                  id: 2,
                  name: _t("strFromPatt"),
                  filter1: _t("strFromPatt"),
                },
              ]}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strPriceMethod")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="priceMethodVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="priceMethodEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name="priceMethodDefId"
            label={_t("strDefault")}
            labelCol={{ flex: "60px" }}
          >
            <SelectSingle
              allowClear={true}
              opt={priceMethodOpt}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strOptions")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="costEffect" valuePropName="checked">
              <Checkbox>{_t("strImpactCosts")}</Checkbox>
            </Form.Item>

            <Form.Item name="lastPurEffect" valuePropName="checked">
              <Checkbox>{_t("strEffectLastPurchasePrice")}</Checkbox>
            </Form.Item>
          </Space>

          <Space>
            <Form.Item name="isStoreEffect" valuePropName="checked">
              <Checkbox>{_t("strAffectedStore")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        <Card
          type="inner"
          title={<strong>{_t("strTheEntry")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Form.Item
            name="journalId"
            label={_t("strJournal")}
            labelCol={{ flex: "100px" }}
            rules={[
              {
                required: true,
                message: `${_t("strIsRequired")}`,
                validator: async (_, value) => {
                  if (value === undefined || value === "") {
                    message.error(
                      `(${_t("strJournal")}) ${_t("strIsRequired")}`
                    );
                    throw new Error("message");
                  }
                },
              },
            ]}
          >
            <SelectSingle
              allowClear={false}
              opt={jornalOpt}
              placeholder={_t("strNotSelected")}
            />
          </Form.Item>
        </Card>
      </div>
    </div>
  );

  const advanceCards = (
    <div className="mainCardsContent">
      <div name="generalCards" className="frContent warpCardsContent">
        <Card
          type="inner"
          title={<strong>{_t("strDocument")}</strong>}
          size="small"
          className="cardPatt"
        >
          <Space>
            <Form.Item name="docVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="docReq" valuePropName="checked">
              <Checkbox>{_t("strRequired")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/* Edit Modal */}
        <Card
          type="inner"
          title={<strong>{_t("strEditMunfModal")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="modelVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="modelEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/* inDate */}
        <Card
          type="inner"
          title={<strong>{_t("strInputDate")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="inDateVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="inDateEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/* outDate */}
        <Card
          type="inner"
          title={<strong>{_t("strOutputDate")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="outDateVisible" valuePropName="checked">
              <Checkbox>{_t("strVisible")}</Checkbox>
            </Form.Item>

            <Form.Item name="outDateEnable" valuePropName="checked">
              <Checkbox>{_t("strEnable")}</Checkbox>
            </Form.Item>
          </Space>
        </Card>

        {/* columns settings */}
        <Card
          type="inner"
          title={<strong>{_t("strColSettings")}</strong>}
          size="small"
          className="cardPatt cardFitContent"
        >
          <Space>
            <Form.Item name="detailsModelColSett" hidden>
              <Input />
            </Form.Item>
            <Button icon={<SettingOutlined />} onClick={handleShowComplexTable}>
              {_t("strComplexColSettings")}
            </Button>

            <Form.Item name="munfMaterialsColSett" hidden>
              <Input />
            </Form.Item>
            <Button icon={<SettingOutlined />} onClick={handleShowMunfTable}>
              {_t("strMunfColSettings")}
            </Button>

            <Form.Item name="rawMaterialsColSett" hidden>
              <Input />
            </Form.Item>
            <Button icon={<SettingOutlined />} onClick={handleShowRawTable}>
              {_t("strRawColSettings")}
            </Button>
          </Space>
        </Card>
      </div>
    </div>
  );

  return (
    <>
      <ComplexTableSett
        showModal={showComplexColSett}
        setShowModal={setShowComplexColSett}
        columns={complexColumns}
        setColumns={setComplexColumns}
        dataSource={complexDataSource}
        setDataSource={setComplexDataSource}
        tableName="popup_entTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ detailsModelColSett: e });
        }}
      />
      <MunfTableSett
        showModal={showMunfColSett}
        setShowModal={setShowMunfColSett}
        columns={munfColumns}
        setColumns={setMunfColumns}
        dataSource={munfDataSource}
        setDataSource={setMunfDataSource}
        tableName="popup_munfTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ munfMaterialsColSett: e });
        }}
      />
      <RawTableSett
        showModal={showRawColSett}
        setShowModal={setShowRawColSett}
        columns={rawColumns}
        setColumns={setRawColumns}
        dataSource={rawDataSource}
        setDataSource={setRawDataSource}
        tableName="popup_rawTableSett"
        onHandleData={(e) => {
          form.setFieldsValue({ rawMaterialsColSett: e });
        }}
      />
      {/* <Spin spinning={loadData} tip={_t("strLoading")}> */}
      <div name="cardsId" className="frContent">
        <Tabs
          type="card"
          onChange={onTabsChange}
          activeKey={activeKey}
          items={[
            {
              key: "1",
              label: _t("strGeneral"),
              children: generalCards,
              forceRender: true,
            },
            {
              key: "2",
              label: _t("strAdvanced"),
              children: advanceCards,
              forceRender: true,
            },
          ]}
        />
      </div>
      {/* </Spin> */}
    </>
  );
};

export default PattSettings;
