
import http from "./../http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/ReportSetting";


export function getRepSetting(name) {
    return http.get(`${apiEndpoint}?name=${name}`);
  }
  
export function postRepSett(obj) {
  return http.post(`${apiEndpoint}`, obj);
}

export function postRepSettAllUser(obj) {
  return http.post(`${apiEndpoint}/PostAllUser`, obj);
}

