import React, { useEffect,useMemo, useState, useRef } from "react";
import { Form, Modal, Radio, Input, Divider,Tag } from "antd";
import _t from "../../../languages/translate";
import Draggable from "react-draggable";
import { shortCut } from "../../helper/shortCuts";

const ActionSearch = ({ showModal,disableDoc, setShowModal, handleData }) => {
  const [loadData, setLoadData] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const [uniqueId, setUniqueId] = useState(new Date());
  const [form] = Form.useForm();
  const { Search } = Input;
  const draggleRef = useRef(null);
  const myRef = useRef(null);
  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem("showSearchModal"))) {
        switch (shortCut(e)) {
          case "search":
            myRef?.current?.focus();
            myRef?.current?.select();
            break;
            case "tag":
                setAltPress(true);
                break;
              default:
                break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
setTimeout(()=>{
  if (showModal) {  
    window.sessionStorage.setItem("showSearchModal", true);
    myRef?.current?.focus();
  } else myRef?.current?.blur();
},100)
   }, [showModal]);

  const handleClear = () => {
    setLoadData(true);
    window.sessionStorage.setItem("showSearchModal", false);
    form.resetFields();
    setTimeout(() => {
      setUniqueId(new Date());
      setLoadData(false);
    }, 50);
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const onSearch = (e) => {
    let type = form.getFieldValue("type");
    handleData(e, type);
    setShowModal(false);
  };

  return (
    <div key={uniqueId}>
      <Modal
        width={800}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              top: 20,
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {_t("strSearch")}
          </div>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        afterClose={() => handleClear()}
        head={false}
        footer={false}
      >
        <Form form={form} initialValues={{ type: 1 }}>
          <div>
            <Form.Item
              name="searchInput"
              normalize={(value) => value.trimStart()}
            >
              <Search ref={myRef} allowClear onSearch={onSearch} />
            </Form.Item>
            <div
              style={{
                opacity: 0.8,
                zIndex: 99,
                position: "absolute",
                top: 110,
              }}
            >
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + F
              </Tag>
            </div>
          </div>
          <Divider style={{ margin: "25px 0px 19px 0px" }} />
          <Form.Item name="type" label={_t("strSearchType")}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={1}>{_t("strCode")}</Radio.Button>
              <Radio.Button disabled={disableDoc} value={2}>{_t("strDocument")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ActionSearch;
