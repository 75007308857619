
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/Account";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getAccountById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editAccount(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteAccount(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}


export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}


export function GetActiveChildren(obj) {
  return http.post(`${apiEndpoint}/GetActiveChildren`,obj);
}

export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

export function getActiveAccountSearch(search) {
  return http.post(
    `${apiEndpoint}/GetActiveChildrenSearch/?search=${search}`
  );
}
export function getActiveTreeSearchPopup(search) {
  return http.post(
    `${apiEndpoint}/GetActiveTreeSearchPopup/?search=${search}`
  );
}

export function getActiveTreeRepAccount(obj) {
  return http.post(`${apiEndpoint}/GetActiveTreeRep`,obj);
}

export function getActAccPopupSearch(search,withClient) {
  return http.post(
    `${apiEndpoint}/GetActiveSearchPopup/?search=${search}&withClient=${withClient===undefined?true:withClient}`
  );
}

export function getAccCatTreeSearch(search) {
  return http.post(process.env.REACT_APP_BASE_URL + `/api/AccCat/GetTreeSearch/?search=${search}`);
}

export function getActiveParentsURL(search, addId) {
   const params = new URLSearchParams()
  search && params.append("search", search);
  //addId && params.append("addId", addId); 
 
  return `${apiEndpoint}/GetActiveChildrenTest/?${params.toString()}`;
}

export function deleteImage(Id, fileName) {
  
  return http.post(`${apiEndpoint}/DeleteImage?Id=${Id}&fileName=${fileName}`);
}

