import React from "react";
import { Form, Space, Tooltip, message } from "antd";
import {
  InsertRowAboveOutlined,
  InsertRowLeftOutlined,
} from "@ant-design/icons";
import NumericInput from "../Inputs/NumericInput";
import _t from "../../../../../languages/translate";
const InputPosition = ({ rowInputName, colInputName }) => {
  return (
    <Space.Compact size="small" style={{ width: "100%" }}>                
      <Form.Item noStyle name={rowInputName} rules={[{ required: true,
      validator: async (_, value) => {
        if (value === "0" || value === 0 || value === undefined) {      
          message.error(_t("msgMinimumRowValueIs1"));
          throw new Error("message");
        }
      },
      }]}>
        <NumericInput
          style={{ width: "100%" }}
          trueNum={true}
          size="small"
          suffix={
            <Tooltip title={_t("strRow")}>
              <InsertRowAboveOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          placeholder={_t("strRow")}
        />
      </Form.Item>
      <Form.Item noStyle name={colInputName} rules={[{ required: true,
      validator: async (_, value) => {
        if (value === "0" || value === 0  || value === undefined) {      
          message.error(_t("msgMinimumColValueIs1"));
          throw new Error("message");
        }
      },}]}>
        <NumericInput
          style={{ width: "100%" }}
          trueNum={true}
          size="small"
          suffix={
            <Tooltip title={_t("strCol")}>
              <InsertRowLeftOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          placeholder={_t("strCol")}
        />
      </Form.Item>
    </Space.Compact>
  );
};
export default InputPosition;
