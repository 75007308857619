import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Prompt } from "react-router";
import { useParams, useLocation, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  message,
  Empty,
  Space,
  Row,
  Col,
  Typography,
  Spin,
  DatePicker,
  Card,
  Modal,
  Tag,
  Dropdown,
  Upload,
  Popconfirm,
} from "antd";
import {
  dateFormatList,
  dateFormatListUnTime,
} from "../../helper/Input/dateFormatList";
import InputCurrency from "../../helper/Input/InputCurrency";
import { GetActiveChildren } from "../../../services/AccountService";
import { printPanel } from "../../helper/Print/printPanel";
import { calcAndReturnList } from "../../helper/Modal/costCenterModal/costCenterHelper";
import { newRow } from "./entFunctions/handleColumns/addNewRows";
import EntryGrid from "./entryGrid";
import ActionSearch from "../../helper/Modal/actionSearch";
import EntUnImportDataExcel from "../../helper/Modal/entUnImportDataExcel";
import {
  initPage,
  editEntry,
  browseNext,
  browsePrev,
  getByDoc,
  getByPattCode,
  getDailyMove,
  deleteEntry,
  savePostState,
  getEntHtml,
  getDailyMoveHtml,
  uploadExcel,
} from "../../../services/entryService";
import {
  ExclamationCircleOutlined,
  DeleteTwoTone,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SaveOutlined,
  LinkOutlined,
  FileExcelOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import { getActiveAccountSearch } from "../../../services/AccountService";
import SelectSingle from "../../helper/selectSingle";
import {
  getRate,
  changeRate,
} from "../../../services/Items/currenciesRateService";
import { shortCut } from "../../helper/shortCuts";
import { useApi } from "../../../services/useApi";
import { getActiveCurr } from "../../../services/Items/currenciesService";
import { objCountNum } from "../../helper/countOfZero";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { LanguageContext } from "../../../languages/Language";
import { calcValueReq } from "../../helper/Modal/costCenterModal/costCenterHelper";
import CostCenterInput from "../../helper/Input/costCenterInput";
import {
  emptString,
  emptValue,
  dateTimeNow,
  emptArr,
} from "../../helper/helperMethods";
import moment from "moment";
import { _Div, _SumArr } from "../../helper/handleMath";
import SelectAccount from "../../helper/inputSelect/selectAccount";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EntryForm = () => {
  const IX_Code = useRef(null);
  const IX_Date = useRef(null);
  const IX_Currency = useRef(null);
  const IX_Acc = useRef(null);
  const IX_Note = useRef(null);
  const gridRef = useRef();
  const [form] = Form.useForm();
  let { id } = useParams();
  let query = useQuery();
  let history = useHistory();
  //******************************//
  const [currOptTable, setCurrOptTable] = useState([]);

  const [loadData, setLoadData] = useState(true);

  const [finalTotal, setFinalTotal] = useState(0);
  const [accOptReqCost, setAccOptReqCost] = useState(0);
  const [accCostMessage, setAccCostMessage] = useState(undefined);

  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [saveAndPrint, setSaveAndPrint] = useState(false);
  const [accOpt, setAccOpt] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [post, setPost] = useState(false);
  const [postDate, setPostDate] = useState(null);
  const [genEntName, setGenEntName] = useState(null);
  const [resp, setResp] = useState(null);
  const [entSourceUrl, setEntSourceUrl] = useState(null);
  const [oldForm, setOldForm] = useState({});
  const [pattrenSet, setPattrenSet] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { userLanguage } = useContext(LanguageContext);
  const { confirm } = Modal;
  const { TextArea } = Input;
  const { Search } = Input;
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    let authUrl = authKeys.ents + "_PattSett_" + handleGetUtl();
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authUrl)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const promptForm = () => {
    setProChange(true);
    window.sessionStorage.setItem("promptEnt", true);
  };
  const [{ data }] = useApi(getActiveCurr(), [null || null]);

  function showConfirmDelete() {
    confirm({
      title: _t("strSureToDelete"),
      icon: <DeleteTwoTone twoToneColor="#eb2f96" />,
      direction: _t("langDiriction"),
      onOk() {
        handleDeleteForm();
      },
      onCancel() {
        return;
      },
    });
  }

  const handleDeleteForm = async () => {
    try {
      const { data: newData } = await deleteEntry(form.getFieldValue().id);
      // let url = window.location.href;

      handleCleanUrl();
      initValue("", "new");
      message.success(newData.message);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleChangeRate = (currId, rate) => {
    if (pattrenSet?.currChType === 0) {
      if (currId !== undefined) {
        changeCurr(currId, rate);
      }
    } else if (pattrenSet?.currChType === 2) {
      if (currId !== undefined) {
        showConfirmNew("changeCurr", currId, rate);
      }
    }
  };
  const [entTypeState, strEntTypeState] = useState(0);

  const initValue = async (_Id, type) => {
    try {
      const { data: newData } = await initPage(handleGetUtl(), _Id);
      fillData(newData, type);
      strEntTypeState(newData.data.pattSett.entType);
      if (
        newData.data.pattSett.entType === 0 &&
        newData.data.ent.accId !== undefined &&
        newData.data.ent.accId !== null
      ) {
        let tempObj = {
          date: newData.data.ent.date,
          accId: newData.data.ent.accId,
          currId: newData.data.ent.currId,
        };

        handleDailyMove(false, tempObj);
      }
      setOldForm(form.getFieldValue());
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    authorization();
    async function fetchData() {
      const recourdId = query.get("entId");
      setDataSource([]);
      window.sessionStorage.setItem("isEntLoad", true);
      initValue(
        recourdId ?? "",
        emptString(recourdId, null) !== null ? "code" : "new"
      );
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    setPost(resp?.post);
    setPostDate(resp?.postDate);
  }, [resp]);

  const handleGetUtl = () => {
    var url = window.location.pathname;
    return url.substring(url.lastIndexOf("/") + 1);
  };

  const next = async () => {
    try {
      const { data: result } = await browseNext(
        handleGetUtl(),
        form.getFieldValue().id
      );
      if (result.data.ent !== null) {
        // handleCleanUrl();        تم اضافته بسبب ان عند تغيير نوع القيد بعد العمل على قيد سابق بتظهر مشكلة بالجدول أو بيرجع لنوع القيد السابق
        fillData(result, "next");
        setOldForm(form.getFieldValue());
      } else {
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    }
  };

  const prev = async () => {
    try {
      const { data: result } = await browsePrev(
        handleGetUtl(),
        form.getFieldValue().id
      );
      if (result.data.ent !== null) {
        // handleCleanUrl();          تم اضافته بسبب ان عند تغيير نوع القيد بعد العمل على قيد سابق بتظهر مشكلة بالجدول أو بيرجع لنوع القيد السابق
        fillData(result, "prev");
        setOldForm(form.getFieldValue());
      } else {
        message.info(_t("msgNoRecords"));
      }
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    }
  };

  const handleDailyMove = async (type, initObj) => {
    try {
      let date =
        initObj !== undefined
          ? moment(initObj.date)
          : form.getFieldValue().date;
      let accId =
        initObj !== undefined ? initObj.accId : form.getFieldValue().accId;
      let currId =
        initObj !== undefined ? initObj.currId : form.getFieldValue().currId;
      let timeShift = new Date().getTimezoneOffset();

      if (accId && currId && date) {
        setLoadData(true);
        setOldForm(form.getFieldValue());
        const { data: result } = await getDailyMove(
          handleGetUtl(),
          accId,
          currId,
          date,
          timeShift
        );

        if (result.data.ent !== null) {
          if (type) {
            if (result.data?.pattSett?.entType === 0) {
              if (
                result.data.ent.accId !== null &&
                result.data.ent.accId !== undefined
              ) {
                setDataSource(newRow(result.data.ent?.details));
              }
            } else {
              setDataSource(newRow(result.data.ent?.details));
            }
          } else {
            fillData(result, "daily");
          }
        } else {
          message.info(_t("msgNoRecords"));
        }
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleAddDay = (type) => {
    if (form.getFieldValue("accId") !== undefined) {
      if (type === "next") {
        var orgDate = moment(form.getFieldValue().date);
        var nextDay = orgDate.add(1, "day");
        form.setFieldsValue({ date: nextDay });
        handleDailyMove();
      } else if (type === "prev") {
        var orgDate = moment(form.getFieldValue().date);
        var prevDay = orgDate.subtract(1, "day");
        form.setFieldsValue({ date: prevDay });
        handleDailyMove();
      }
    }
  };

  const handleCleanUrl = () => {
    const url = new URLSearchParams(history.location.search);
    url.delete("entId");
    history.push({
      pathname: history.location.pathname,
      search: url.toString(),
    });
  };

  const searchPattCode = async (popS) => {
    setOldForm(form.getFieldValue());
    const result = await getByPattCode(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().pattCode
    );

    if (result !== undefined) {
      if (result?.data?.data?.ent !== null) {
        fillData(result?.data, "code");
      } else {
        message.info(_t("msgNoRecords"));
      }
    }
  };

  const searchDoc = async (popS) => {
    setOldForm(form.getFieldValue());

    const result = await getByDoc(
      handleGetUtl(),
      popS !== undefined ? popS : form.getFieldValue().doc
    );
    if (result !== undefined) {
      if (result?.data?.data?.ent !== null) {
        fillData(result?.data, "doc");
      } else {
        message.info(_t("msgNoRecords"));
      }
    }
  };

  const changeCurr = async (currId, value) => {
    let obj = {
      currId: currId,
      date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      value: value,
    };

    const { data: result } = await changeRate(obj);
    message.success(result.message, 3);
  };

  const loadCostCenterData = (costList, accId, accList) => {
    if (emptArr(costList, null) !== null) {
      const arr = costList.map((e) => {
        e.value = 0;
        return e;
      });
      let reqOpt = accList?.map((e) => e?.id === accId)?.costCenterReqOpt;
      return calcAndReturnList(0, arr, emptValue(reqOpt, 0))?.text;
    }
  };

  const handleAccAct = async () => {
    try {
      let obj = {
        search: "",
        addIds: null,
        clientId: null,
      };
      const { data: responce } = await GetActiveChildren(obj);
      return responce?.data;
    } catch (error) {
      handleEx(error);
    }
  };

  const fillData = async (newData, type) => {
    const accList = await handleAccAct();
    form.setFieldsValue({
      id:
        newData.data.ent?.id !== null &&
        newData.data.ent?.id !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.ent?.id
          : "",
      entId:
        newData.data.ent?.entId !== null &&
        newData.data.ent?.entId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.ent?.entId
          : "",
      pattCode:
        newData.data.ent?.pattCode !== null ? newData.data.ent?.pattCode : "",
      currId:
        newData.data.ent?.currId !== null &&
        newData.data.ent?.currId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.ent?.currId
          : "",
      accId:
        newData.data.ent?.accId !== null ? newData.data.ent?.accId : undefined,
      currRate:
        newData.data.ent?.currRate !== null ? newData.data.ent?.currRate : "",
      doc: newData.data.ent?.doc !== null ? newData.data.ent?.doc : "",
      note: newData.data.ent?.note !== null ? newData.data.ent?.note : "",
      noteGen:
        newData.data.ent?.noteGen !== null ? newData.data.ent?.noteGen : "",

      entGrpAccCostCenter_Maps: newData.data.ent?.entGrpAccCostCenter_Maps,
      entGrpAccCostCenter: loadCostCenterData(
        newData.data.ent?.entGrpAccCostCenter_Maps,
        newData?.data?.ent?.accId,
        accList
      ),
    });

    const reqOpt = accList?.find(
      (e) => e?.id === newData?.data?.ent?.accId
    )?.costCenterReqOpt;
    setAccOptReqCost(reqOpt);

    // if (window.sessionStorage.getItem('entType') === '0') {
    //   form.setFieldsValue({
    //     date: newData.data.ent?.date !== null ? moment(newData.data.ent?.date) : "",
    //   });
    // }
    // if (window.sessionStorage.getItem('entType') !== '0' && form.getFieldValue('date') === undefined) {
    //   form.setFieldsValue({
    //     date: newData.data.ent?.date !== null ? moment(newData.data.ent?.date) : "",
    //   });
    // }

    if (newData.data.ent !== null) {
      handleLoadDate(newData.data.ent?.date, type);
      setPost(newData.data.ent.post);
      setPostDate(newData.data.ent.postDate);
      setEntSourceUrl(newData.data.ent?.entSourceUrl);
      setGenEntName(newData.data.ent?.genEntName);
      if (newData.data?.pattSett?.entType === 0) {
        if (
          newData.data.ent.accId !== null &&
          newData.data.ent.accId !== undefined
        ) {
          setDataSource(newRow(newData.data.ent?.details));
        }
      } else {
        setDataSource(newRow(newData.data.ent?.details));
      }
      setProChange(false);

      setPattrenSet(newData.data?.pattSett);
      window.sessionStorage.setItem("entType", newData.data?.pattSett?.entType);
      window.sessionStorage.setItem("promptEnt", false);
      window.sessionStorage.setItem("confState", false);
      handleAccSearch("");
      handleSearchCurrency("");
      if (!!gridRef.current)
        gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
      // IX_Date.current.focus();
    } else {
      handleCleanUrl();
      let url = window.location.href;
      window.location.replace(url);
    }
  };

  const handleLoadDate = (date, type) => {
    const url = window.location.href;

    if (
      type === "next" ||
      type === "prev" ||
      type === "code" ||
      type === "doc"
    ) {
      form.setFieldsValue({
        date: date !== null ? moment(date) : undefined,
      });
    }
    if (type === "new") {
      form.setFieldsValue({
        date: dateTimeNow(),
      });
    }
  };

  const handleAccSearch = async (_Search) => {
    try {
      const { data: responce } = await getActiveAccountSearch(_Search);

      setAccOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const tableValidation = (arr) => {
    const dupplListNum = [];
    const valListNum = [];
    const valCostList = [];
    arr.map((e, i) => {
      if (e.accId.includes(form.getFieldValue().accId)) {
        dupplListNum.push(i + 1);
      }
      if (e.accId !== "") {
        if (
          e.accId === undefined ||
          e?.currId === undefined ||
          ((e.credit === undefined || e.credit === 0 || e.credit === null) &&
            (e.debet === undefined || e.debet === 0 || e.debet === null)) ||
          ((e.currCredit === undefined ||
            e.currCredit === 0 ||
            e.currCredit === null) &&
            (e.currDebet === undefined ||
              e.currDebet === 0 ||
              e.currDebet === null)) ||
          e.currRate === undefined ||
          e.currRate === 0 ||
          e.currRate === null
        ) {
          valListNum.push(i + 1);
        }
      }
    });

    for (let i = 0; i < arr.length; i++) {
      let status = calcAndReturnList(
        emptValue(arr[i].debet, null)
          ? emptValue(arr[i].debet, null)
          : emptValue(arr[i].credit, null),
        arr[i].costCenters,
        arr[i].costCenterReqOpt
      );
      if (status.state !== "done") {
        valCostList.push(i + 1);
      }
    }
   

    if (window.sessionStorage.getItem("entType") === "3") {
      let sumCurrDebet = _SumArr(arr, "currDebet");
      let sumCurrCredit = _SumArr(arr, "currCredit");

      if (sumCurrDebet !== sumCurrCredit) {
        message.error(_t("msgTheEntNotEqual"));
        return false;
      }
    }

    if (valCostList?.length > 0) {
      message.error(_t("msgErrorSaveCostCenter") + valCostList?.toString());
      return false;
    }
    if (pattrenSet?.validationDiff === true) {
      message.error(_t("msgNotDifference") + dupplListNum?.toString());
      return false;
    }

    if (dupplListNum?.length > 0) {
      message.error(_t("msgRepeated") + dupplListNum?.toString());
      return false;
    }
    if (valListNum?.length > 0) {
      message.error(_t("msgUncompleted") + valListNum?.toString());
      return false;
    } else return true;
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    window.sessionStorage.setItem("promptEnt", false);
    values.post = post;

    if (postDate === null) {
      values.postDate = undefined;
    } else {
      values.postDate = postDate;
    }
    if (values.accId === "") {
      values.accId = undefined;
    }
    values.entPattSettId = handleGetUtl();
    const oldDate = values.date;

    if (values.details !== null) {
      let output = dataSource.filter((obj) =>
        Object.keys(obj).includes("accId")
      );
      values.details = output;
    }

    if (tableValidation(values.details)) {
      try {
        if (values.date !== "") {
          values.date = values.date?.toISOString().slice(0, 16) + "Z";
        }

        const dataEntry = await editEntry(values);
        if (saveAndPrint) {
          await onPrint(dataEntry.data.data);
        }

        message.success(dataEntry.data.message, 3);
        if (values?.id === "") initValue("", "add");
      } catch (error) {
        setLoadData(false);
        handleEx(error, { IX_Code, IX_Date, IX_Currency });
      } finally {
        values.date = oldDate;
        setLoadData(false);
      }
    }
    setLoadData(false);
  };
  const [companyCurrId, setCompanyCurrId] = useState(
    form.getFieldValue().currId === pattrenSet?.companyCurrId
  );

  const handleChangeCurr = async (value) => {
    try {
      const data = await getRate(
        value,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      setFinalTotal(emptValue(_Div(finalTotal, data?.data?.data), 0));
      form.setFieldsValue({ currRate: data.data.data });
    } catch (error) {
      handleEx(error, { IX_Code, IX_Date, IX_Currency });
    }
    setCompanyCurrId(form.getFieldValue().currId === pattrenSet?.companyCurrId);
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item?.currId} value={item?.currId}>
        {item.currName}
      </Select.Option>
    ));

  const accOptList =
    accOpt.length > 0 &&
    accOpt.map((item) => (
      <Select.Option
        key={item?.id}
        value={item?.id}
        disabled={emptValue(item?.costCenterReqOpt, false)}
      >
        <Tooltip
          color="red"
          title={
            emptValue(item?.costCenterReqOpt, false)
              ? _t("msgAccountCannotBeUsed")
              : ""
          }
          placement="left"
        >
          {item.name}
        </Tooltip>
      </Select.Option>
    ));

  const onPrint = async (entId) => {
    try {
      setLoadData(true);
      const dataPrint = await getEntHtml(
        entId,
        handleGetUtl(),
        new Date().getTimezoneOffset(),
        userLanguage === "ar" ? 0 : 1,
        "",
        false
      );
      setLoadData(false);
      printPanel(dataPrint.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const onPrintDailyMove = async () => {
    try {
      setLoadData(true);
      const dataDailyPrint = await getDailyMoveHtml(
        handleGetUtl(),
        form.getFieldValue("accId"),
        form.getFieldValue("date").toISOString(true).slice(0, 16) + "Z",
        new Date().getTimezoneOffset(),
        userLanguage === "ar" ? 0 : 1
      );
      setLoadData(false);
      printPanel(dataDailyPrint.data);
    } catch (error) {
      handleEx(error);
    }
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showAccModal = JSON.parse(
        window.sessionStorage.getItem("showAccModal")
      );
      let showCostModal = JSON.parse(
        window.sessionStorage.getItem("showCostModal")
      );
      let showSearchModal = JSON.parse(
        window.sessionStorage.getItem("showSearchModal")
      );
      if (
        (showAccModal === false || showAccModal === null) &&
        (showCostModal === false || showCostModal === null) &&
        (showSearchModal === false || showSearchModal === null)
      ) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              if (window.sessionStorage.getItem("entType") !== "0") {
                form.submit();
              }

              break;
            case "saveAndPrint":
              if (window.sessionStorage.getItem("entType") !== "0") {
                setSaveAndPrint(true);
                form.submit();
              }
              break;
            case "new":
              if (window.sessionStorage.getItem("entType") !== "0") {
                JSON.parse(window.sessionStorage.getItem("promptEnt"))
                  ? showConfirmNew("new")
                  : initValue("", "new");
              }
              break;
            case "refresh":
              if (window.sessionStorage.getItem("entType") === "0") {
                let isNotSaved = dataSource.filter(
                  (e) => e?.isNotSaved === true
                );
                if (isNotSaved.length > 0) {
                  showConfirmRefresh();
                } else {
                  handleDailyMove(true);
                }
                if (!!gridRef.current)
                  gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              }
              break;
            case "print":
              let isRecord = form.getFieldValue("id") === "";
              let isChanged = JSON.parse(
                window.sessionStorage.getItem("promptEnt")
              );
              let isPrint =
                isChanged === false && isRecord === false ? true : false;
              if (isPrint) onPrint(form.getFieldValue("id"));
              break;
            case "delete":
              let isDelete = form.getFieldValue("id") !== "";
              if (isDelete) showConfirmDelete();
              break;
            case "search":
              if (window.sessionStorage.getItem("entType") === "0") {
                IX_Date?.current?.focus();
              } else {
                setShowSearch(true);
              }

              break;
            case "next":
              if (window.sessionStorage.getItem("entType") !== "0") {
                JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                  ? showConfirmNew("next")
                  : next();
              } else {
                JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                  ? showConfirmNew("next", null, "date")
                  : handleAddDay("next");
              }
              break;
            case "prev":
              if (window.sessionStorage.getItem("entType") !== "0") {
                JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                  ? showConfirmNew("prev")
                  : prev();
              } else {
                JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
                  ? showConfirmNew("prev", null, "date")
                  : handleAddDay("prev");
              }
              break;
            case "block1":
              document
                .getElementById("fContent")
                .getElementsByTagName("Input")[0]
                .focus();

              //  IX_Acc?.current?.focus();
              break;
            case "block2":
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              break;
            case "block3":
              IX_Note?.current?.focus();
              break;
            case "tag":
              blurControl();
              IX_Date.current.focus();
              IX_Date.current.blur();
              if (!!gridRef.current)
                gridRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, [dataSource]);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const blurControl = () => {
    document
      .getElementById("fContent")
      .getElementsByTagName("Input")[0]
      .focus();
    document.getElementById("fContent").getElementsByTagName("Input")[0].blur();
  };

  const onMenuClick = (e) => {
    switch (e?.key) {
      case "1":
        setSaveAndPrint(false);
        break;
      case "2":
        setSaveAndPrint(true);
        break;
      default:
        break;
    }
  };

  const showConfirmNew = (type, currId, value, popS) => {
    window.sessionStorage.setItem("confState", true);
    confirm({
      title:
        type === "changeCurr"
          ? _t("msgAskSureToChangeRate")
          : _t("msgAskSureToNew"),
      icon: <ExclamationCircleOutlined />,
      content:
        type === "changeCurr" ? _t("msSureToChangeRate") : _t("msSureToNew"),
      direction: _t("langDiriction"),

      onOk() {
        window.sessionStorage.setItem("confState", false);
        if (type === "new") {
          initValue("", "new");
        } else if (type === "next")
          if (value === "date") {
            handleAddDay("next");
          } else {
            next();
          }
        else if (type === "prev")
          if (value === "date") {
            handleAddDay("prev");
          } else {
            prev();
          }
        else if (type === "code") searchPattCode(popS);
        else if (type === "doc") searchDoc(popS);
        else if (type === "changeCurr") {
          changeCurr(currId, value);
        } else if (type === "accId") {
          handleDailyMove();
        } else if (type === "date") {
          handleDailyMove();
        } else return;
      },
      onCancel() {
        window.sessionStorage.setItem("confState", false);
        if (type === "code") form.setFieldsValue({ accId: oldForm?.pattCode });
        else if (type === "doc") form.setFieldsValue({ accId: oldForm?.doc });
        if (type === "date") {
          form.setFieldsValue({ accId: oldForm?.date });
        } else if (type === "accId") {
          form.setFieldsValue({ accId: oldForm?.accId });
        }
        return;
      },
    });
  };

  const showConfirmRefresh = () => {
    confirm({
      title: _t("strWarning"),
      icon: <ExclamationCircleOutlined />,
      content: _t("msgRowsUnSeved"),
      direction: _t("langDiriction"),
      onOk() {
        handleDailyMove(true);
      },
      onCancel() {
        return;
      },
    });
  };

  const handlePost = async (state) => {
    try {
      if (form.getFieldValue("id") !== "") {
        const { data: data } = await savePostState(
          form.getFieldValue("id"),
          !state
        );
        if (data.code === 200) {
          setResp(data?.data);
          message.success(data.message, 3);
        } else if (data.code === 208) {
          // already exists
          setResp(data?.data);
          message.warning(data.message, 3);
        }
      } else {
        setResp({
          post: !post,
          postDate: postDate,
        });
      }
    } catch {}
  };

  const items = [
    {
      key: "1",
      label: _t("strSave"),
    },
    {
      key: "2",
      label: _t("strSave&Print"),
    },
  ];

  const [printState, setPrintState] = useState(true);
  useEffect(() => {
    if (emptString(form.getFieldValue("id"), null) !== null) {
      if (JSON.parse(window.sessionStorage.getItem("promptEnt")) === true) {
        setPrintState(true);
      } else {
        setPrintState(false);
      }
    } else {
      setPrintState(true);
    }
  }, [form.getFieldValue("id"), proChange]);

  const [tableUnImport, setTableUnImport] = useState([]);
  const [visTableUnImport, setVisTableUnImport] = useState(false);

  const onImportExcel = async (file) => {
    setLoadData(true);
    const hide = message.loading("جاري استلام البيانات ..", 0);
    try {
      const responce = await uploadExcel(file);

      if (responce.data.code === 417) {
        setTableUnImport(responce.data.data);

        message.error(responce.data.message);
        setVisTableUnImport(true);
      } else {
        setDataSource([
          ...responce.data.data,
          { key: responce.data.data.length },
        ]);

        message.success(responce.data.message);
      }
    } catch (error) {
      handleEx(error);
    } finally {
      setTimeout(hide);
      setLoadData(false);
    }
  };

  const handelBeforeUpload = (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return message.error(`xlsx. بصيغة  "${file.name}" يجب أن يكون مجلد`);
    }
  };

  const handleSearch = (data, type) => {
    setOldForm(form.getFieldValue());
    if (type === 1) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("code", undefined, undefined, data)
        : searchPattCode(data);
    } else if (type === 2) {
      JSON.parse(window.sessionStorage.getItem("promptEnt")) === true
        ? showConfirmNew("doc", undefined, undefined, data)
        : searchDoc(data);
    }
  };

  return (
    <div key="mainDiv">
      <ActionSearch
        showModal={showSearch}
        setShowModal={setShowSearch}
        handleData={(e, type) => {
          handleSearch(e, type);
        }}
      />
      <React.Fragment>
        <Prompt
          when={JSON.parse(window.sessionStorage.getItem("promptEnt"))}
          message={_t("strUnsavedChanges")}
        />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <EntUnImportDataExcel
            showModal={visTableUnImport}
            setShowModal={setVisTableUnImport}
            dataSource={tableUnImport}
            showModalName="modalUnImportExcel"
          />
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "100px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{ details: dataSource }}
            forceRender
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={12}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strEntries")}</Text>
                      <Text>{pattrenSet?.branchName}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {pattrenSet?.pattName}
                      </Text>
                      <Space size="large">
                        <div>
                          <Button
                            size="small"
                            disabled={
                              window.sessionStorage.getItem("entType") !== "0"
                                ? false
                                : form.getFieldValue().accId === undefined
                                ? true
                                : false
                            }
                            onClick={() =>
                              window.sessionStorage.getItem("entType") !== "0"
                                ? JSON.parse(
                                    window.sessionStorage.getItem("promptEnt")
                                  ) === true
                                  ? showConfirmNew("prev")
                                  : prev()
                                : JSON.parse(
                                    window.sessionStorage.getItem("promptEnt")
                                  ) === true
                                ? showConfirmNew("prev", null, "date")
                                : handleAddDay("prev")
                            }
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <RightOutlined />
                            ) : (
                              <LeftOutlined />
                            )}
                          </Button>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↓ + alt
                            </Tag>
                          </div>
                        </div>
                        {window.sessionStorage.getItem("entType") !== "0" && (
                          <div>
                            <Button
                              size="small"
                              icon={<SearchOutlined />}
                              onClick={() => {
                                setShowSearch(true);
                              }}
                            />
                            <div
                              style={{
                                opacity: 0.8,
                                zIndex: 99,
                                position: "absolute",
                                top: 24,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + F
                              </Tag>
                            </div>
                          </div>
                        )}

                        <div>
                          <Button
                            size="small"
                            disabled={
                              window.sessionStorage.getItem("entType") !== "0"
                                ? false
                                : form.getFieldValue().accId === undefined
                                ? true
                                : false
                            }
                            onClick={() =>
                              window.sessionStorage.getItem("entType") !== "0"
                                ? JSON.parse(
                                    window.sessionStorage.getItem("promptEnt")
                                  ) === true
                                  ? showConfirmNew("next")
                                  : next()
                                : JSON.parse(
                                    window.sessionStorage.getItem("promptEnt")
                                  ) === true
                                ? showConfirmNew("next", null, "date")
                                : handleAddDay("next")
                            }
                          >
                            {_t("langDiriction") === "rtl" ? (
                              <LeftOutlined />
                            ) : (
                              <RightOutlined />
                            )}
                          </Button>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              ↑ + alt
                            </Tag>
                          </div>
                        </div>
                      </Space>
                    </Space>

                    {pattrenSet?.entType === 0 && (
                      <div>
                        <Form.Item name="date">
                          <DatePicker
                            size="small"
                            ref={IX_Date}
                            format={dateFormatListUnTime}
                            disabled={
                              !pattrenSet?.dateEnable
                                ? true
                                : form.getFieldValue().accId === undefined
                                ? true
                                : false
                            }
                            allowClear={false}
                            onChange={() =>
                              JSON.parse(
                                window.sessionStorage.getItem("promptEnt")
                              ) === true
                                ? showConfirmNew("date")
                                : handleDailyMove()
                            }
                            bordered={false}
                          />
                        </Form.Item>
                        <div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 24,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + F
                          </Tag>
                        </div>
                      </div>
                    )}

                    {window.sessionStorage.getItem("entType") !== "0" && (
                      <div>
                        <Form.Item
                          name="pattCode"
                          rules={[
                            {
                              required: true,
                              message: `${_t("strIsRequired")}`,
                            },
                            { min: 1, message: `${_t("strFrom3-200")}` },
                            { max: 200, message: `${_t("strFrom3-200")}` },
                          ]}
                        >
                          <Input
                            className="inpCode"
                            size="small"
                            placeholder={_t("strCode")}
                            maxLength={200}
                            autoComplete="off"
                            // onChange={()=>promptForm()}
                            ref={IX_Code}
                            bordered={false}
                            onPressEnter={() =>
                              JSON.parse(
                                window.sessionStorage.getItem("promptEnt")
                              ) === true
                                ? showConfirmNew("code")
                                : searchPattCode()
                            }
                            disabled={!pattrenSet?.codeEnable}
                          />
                        </Form.Item>
                        <div
                          style={{
                            opacity: 0.8,
                            zIndex: 99,
                            position: "absolute",
                            top: 24,
                          }}
                        >
                          <Tag color="#2b2b2b" hidden={!altPress}>
                            alt + F
                          </Tag>
                        </div>
                      </div>
                    )}
                  </Space>
                </Col>

                <Col
                  xs={24}
                  md={12}
                  style={{ textAlign: _t("txtRevDiriction") }}
                >
                  <Space>
                    {(genEntName ?? "" !== "") && (
                      <>
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={entSourceUrl}
                        >
                          <LinkOutlined />
                          {genEntName}
                        </Link>{" "}
                        /
                      </>
                    )}
                    {pattrenSet?.entType === 0 ? (
                      <label
                        style={{
                          padding: 0,
                          color: post === true ? "green" : "red",
                        }}
                      >
                        {post === true
                          ? _t("strDeported")
                          : _t("strNotDeported")}{" "}
                        {postDate !== null
                          ? ":" + moment(postDate).format("DD/MM/YYYY - h:mm a")
                          : ""}
                      </label>
                    ) : (
                      pattrenSet?.postVisible && (
                        <Popconfirm
                          title={_t("msgAskChangeStatus")}
                          onConfirm={() => handlePost(post)}
                          disabled={!pattrenSet?.postEnable}
                        >
                          <Button
                            style={{
                              padding: 0,
                              color: post === true ? "green" : "red",
                            }}
                            disabled={!pattrenSet?.postEnable}
                            size="small"
                            type="text"
                          >
                            {post === true
                              ? _t("strDeported")
                              : _t("strNotDeported")}{" "}
                            {postDate !== null
                              ? ":" +
                                moment(postDate).format("DD/MM/YYYY - h:mm a")
                              : ""}
                          </Button>
                        </Popconfirm>
                      )
                    )}
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <Form.Item name="id" noStyle hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="entId" noStyle hidden>
                  <Input />
                </Form.Item>
                <div
                  id="fContent"
                  key="div-frContent"
                  name="field"
                  className="frContent"
                >
                  <Row gutter={12}>
                    <Col xs={24} md={12} hidden={!pattrenSet?.accVisible}>
                      <Form.Item
                        name="accId"
                        label={_t("strAccount")}
                        rules={[{ required: pattrenSet?.accVisible }]}
                      >
                        <SelectAccount
                          ref={IX_Acc}
                          addId={form.getFieldValue("accId")}
                          placeholder={_t("strAccount")}
                          disabled={!pattrenSet?.accEnable}
                          onChange={(_, relatedObj) => {
                            setAccOptReqCost(relatedObj?.costCenterReqOpt);
                            if (pattrenSet?.entType === 0) {
                              let accId = form.getFieldValue().accId;
                              if (accId) {
                                handleDailyMove();
                              } else {
                                setDataSource([]);
                              }
                            } else {
                              promptForm();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      md={12}
                      hidden={!pattrenSet?.entCostCenterVisible}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.entGrpAccCostCenter_Maps !==
                          curr.entGrpAccCostCenter_Maps
                        }
                      >
                        {({ getFieldValue }) => {
                          let x = calcValueReq(
                            finalTotal,
                            getFieldValue("entGrpAccCostCenter_Maps"),
                            accOptReqCost
                          );
                          setAccCostMessage(x);
                          return (
                            <Form.Item name="entGrpAccCostCenter_Maps" hidden>
                              <Input />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>

                      <Form.Item
                        name="entGrpAccCostCenter"
                        label={_t("strCostCenterAcc")}
                        rules={[
                          {
                            required:
                              pattrenSet?.entCostCenterReq === true
                                ? pattrenSet?.entCostCenterReq
                                : accCostMessage !== undefined
                                ? true
                                : false,
                            validator: async (_, value) => {
                              let list = form.getFieldValue(
                                "entGrpAccCostCenter_Maps"
                              );
                              let perc;
                              if (list?.length > 0) {
                                perc = emptValue(
                                  list?.reduce(
                                    (partialSum, a) => partialSum + a.perc,
                                    0
                                  ),
                                  0
                                );
                              }

                              let isFaildPattReq =
                                pattrenSet?.entCostCenterReq === true &&
                                emptString(value, null) === null;
                              let isFaildAccReq =
                                accOptReqCost === 1 &&
                                emptString(value, null) === null;
                              let isFaildFullPerc =
                                accOptReqCost === 2 &&
                                accCostMessage !== undefined &&
                                perc > 1;
                              let isFaild =
                                isFaildPattReq ||
                                isFaildAccReq ||
                                isFaildFullPerc;

                              if (isFaild) {
                                message.error(
                                  `(${_t("strCostCenterAcc")}) ${_t(
                                    "strIsRequired"
                                  )}`
                                );
                                throw new Error("message");
                              }
                            },
                          },
                        ]}
                      >
                        <CostCenterInput
                          form={form}
                          fieldName="entGrpAccCostCenter"
                          arrName="entGrpAccCostCenter_Maps"
                          accountValidationOpt={accOptReqCost}
                          amount={finalTotal}
                          type="input"
                          reqMessage={accCostMessage}
                          onHandelData={(e) => {
                            form.setFieldsValue({ entGrpAccCostCenter: e });
                          }}
                          disabled={!pattrenSet?.entCostCenterEnable}
                        />
                      </Form.Item>

                      {/* entGrpAccCostCenter_Maps                            
                    entCostCenterVisible
                    entCostCenterEnable
                    entCostCenterReq  */}
                    </Col>
                    {window.sessionStorage.getItem("entType") !== "0" && (
                      <Col xs={24} md={12}>
                        <Form.Item name="date" label={_t("strDate")}>
                          <DatePicker
                            ref={IX_Date}
                            allowClear={false}
                            showTime={{ format: "HH:mm" }}
                            format={dateFormatList}
                            className="dateTimeStyle"
                            size="small"
                            onChange={() => promptForm()}
                            disabled={!pattrenSet?.dateEnable}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} md={12} hidden={!pattrenSet?.currVisible}>
                      <Form.Item label={_t("strCurrency")}>
                        <Input.Group compact>
                          <Form.Item
                            name="currId"
                            noStyle
                            rules={[{ required: pattrenSet?.currVisible }]}
                          >
                            <SelectSingle
                              ref={IX_Currency}
                              style={{
                                width:
                                  window.sessionStorage.getItem("entType") ===
                                  "0"
                                    ? "100%"
                                    : "70%",
                              }}
                              onChange={(e) => {
                                handleChangeCurr(e);
                                if (pattrenSet?.entType === 0) {
                                  handleDailyMove(true);
                                } else {
                                  promptForm();
                                }
                              }}
                              allowClear={false}
                              fieldNames={{
                                label: "currName",
                                value: "currId",
                              }}
                              placeholder={_t("strNotSelected")}
                              size="small"
                              disabled={!pattrenSet?.currEnable}
                              opt={currencyOpt}
                            />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, curr) =>
                              prev.currId !== curr.currId
                            }
                            hidden={
                              window.sessionStorage.getItem("entType") === "0"
                            }
                          >
                            {({ getFieldValue }) => {
                              const _currId = getFieldValue("currId");
                              const state =
                                !pattrenSet?.currEnable ||
                                _currId === pattrenSet?.companyCurrId;

                              return (
                                <Form.Item
                                  name="currRate"
                                  noStyle
                                  rules={[{ required: true }]}
                                  hidden={
                                    window.sessionStorage.getItem("entType") ===
                                    "0"
                                  }
                                >
                                  {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2, num:0 )~*/}
                                  <InputCurrency
                                    precision={objCountNum.rate}
                                    size="small"
                                    style={{ width: "30%" }}
                                    onChange={() => {
                                      if (
                                        window.sessionStorage.getItem(
                                          "entType"
                                        ) !== "0"
                                      ) {
                                        promptForm();
                                      }
                                    }}
                                    placeholder={_t("strRate")}
                                    onBlur={() => {
                                      handleChangeRate(
                                        form.getFieldValue("currId"),
                                        form.getFieldValue("currRate")
                                      );
                                    }}
                                    suffix={
                                      <Tooltip title={_t("msgRateCurrValue")}>
                                        <InfoCircleOutlined
                                          style={{ color: "rgba(0,0,0,.45)" }}
                                        />
                                      </Tooltip>
                                    }
                                    disabled={
                                      state ||
                                      pattrenSet?.currEnable === false ||
                                      pattrenSet?.currChType === 3
                                    }
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} hidden={!pattrenSet?.docVisible}>
                      <Form.Item
                        name="doc"
                        label={_t("strDocument")}
                        rules={[
                          {
                            required: pattrenSet?.docReq,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Search
                          placeholder={_t("strDocument")}
                          maxLength={200}
                          autoComplete="off"
                          // onChange={()=>promptForm()}
                          size="small"
                          onSearch={() =>
                            JSON.parse(
                              window.sessionStorage.getItem("promptEnt")
                            ) === true
                              ? showConfirmNew("doc")
                              : searchDoc()
                          }
                          disabled={!pattrenSet?.currEnable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        labelCol={{
                          flex: "100px",
                        }}
                        wrapperCol={{
                          span: 23,
                        }}
                        label={_t("strDetails")}
                      >
                        <div>
                          <Card
                            bodyStyle={{ padding: 0 }}
                            style={{ marginBottom: 10 }}
                          >
                            <EntryGrid
                              innerRef={gridRef}
                              form={form}
                              dataSource={dataSource}
                              setDataSource={setDataSource}
                              pattrenSet={pattrenSet}
                              showConfirmNew={(type, currId, value) =>
                                showConfirmNew(type, currId, value)
                              }
                              setLoadData={setLoadData}
                              loadData={loadData}
                              currOptTable={currOptTable}
                              entType={entTypeState}
                              pattSettId={handleGetUtl()}
                              promptForm={promptForm}
                              accountValidationOpt={accOptReqCost}
                              handelFinalTotal={(e) => {
                                setFinalTotal(
                                  emptValue(
                                    _Div(e, form.getFieldValue("currRate")),
                                    0
                                  )
                                );
                                // setAccCostMessage(
                                // calcValueReq(
                                //   e,
                                //   form.getFieldValue("entGrpAccCostCenter_Maps"),
                                //   accOptReqCost
                                // ))
                              }}
                            />
                          </Card>
                          <div
                            style={{
                              opacity: 0.8,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 2
                            </Tag>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                    {window.sessionStorage.getItem("entType") !== "0" && (
                      <Col xs={24} md={12}>
                        <div>
                          <Form.Item
                            name="note"
                            label={_t("strNote")}
                            rules={[
                              {
                                required: false,
                                message: `${_t("strIsRequired")}`,
                              },
                              { min: 1, message: `${_t("strFrom1-1000")}` },
                              { max: 1000, message: `${_t("strFrom1-1000")}` },
                            ]}
                          >
                            <TextArea
                              ref={IX_Note}
                              placeholder={_t("strNote")}
                              maxLength={1000}
                              autoComplete="off"
                              onChange={() => promptForm()}
                              size="small"
                            />
                          </Form.Item>
                          <div
                            style={{
                              opacity: 0.8,
                              position: "absolute",
                              marginLeft: 100,
                              marginRight: 100,
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 3
                            </Tag>
                          </div>
                        </div>
                      </Col>
                    )}
                    {window.sessionStorage.getItem("entType") !== "0" && (
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="noteGen"
                          label={_t("strNote2")}
                          rules={[
                            {
                              required: false,
                              message: `${_t("strIsRequired")}`,
                            },
                            { min: 1, message: `${_t("strFrom1-1000")}` },
                            { max: 1000, message: `${_t("strFrom1-1000")}` },
                          ]}
                        >
                          <TextArea
                            placeholder={_t("strNote2")}
                            maxLength={1000}
                            disabled
                            autoComplete="off"
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <div
                    style={{
                      opacity: 0.8,
                      zIndex: 99,
                      marginLeft: 100,
                      marginRight: 100,
                      position: "absolute",
                      top: 10,
                    }}
                  >
                    <Tag color="#2b2b2b" hidden={!altPress}>
                      alt + 1
                    </Tag>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="middle">
                  {window.sessionStorage.getItem("entType") !== "0" && (
                    <div>
                      <Dropdown.Button
                        type="link"
                        onClick={() => form.submit()}
                        size="small"
                        menu={{
                          items,
                          onClick: onMenuClick,
                        }}
                      >
                        {saveAndPrint ? (
                          <>
                            {_t("strSave&Print")} <PrinterOutlined />
                          </>
                        ) : (
                          <>
                            {_t("strSave")} <SaveOutlined />
                          </>
                        )}
                      </Dropdown.Button>
                      <div
                        style={{ opacity: 0.8, position: "absolute", top: 24 }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + S
                        </Tag>
                      </div>
                    </div>
                  )}
                  {pattrenSet?.entType === 0 && (
                    <div>
                      <Button
                        type="link"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          let isNotSaved = dataSource.filter(
                            (e) => e?.isNotSaved === true
                          );
                          if (isNotSaved.length > 0) {
                            showConfirmRefresh();
                          } else {
                            handleDailyMove(true);
                          }
                          if (!!gridRef.current)
                            gridRef?.current?.selectCell({
                              idx: 0,
                              rowIdx: -1,
                            });
                        }}
                        size="small"
                        disabled={form.getFieldValue().accId === undefined}
                      >
                        {_t("strRefreshTable")}
                      </Button>
                      <div
                        style={{ opacity: 0.8, position: "absolute", top: 24 }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + R
                        </Tag>
                      </div>
                    </div>
                  )}
                  {window.sessionStorage.getItem("entType") !== "0" && (
                    <div>
                      <Button
                        type="text"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          //  handleCleanUrl();
                          if (
                            JSON.parse(
                              window.sessionStorage.getItem("promptEnt")
                            ) === true
                          ) {
                            showConfirmNew("new");
                          } else {
                            initValue("", "new");
                          }
                        }}
                        // disabled={form.getFieldValue().id === "" ? true : false}
                        size="small"
                      >
                        {_t("strNew")}
                      </Button>
                      <div
                        style={{ opacity: 0.8, position: "absolute", top: 24 }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + N
                        </Tag>
                      </div>
                    </div>
                  )}
                  {window.sessionStorage.getItem("entType") !== "0" && (
                    <div>
                      <Button
                        type="text"
                        icon={<PrinterOutlined />}
                        onClick={() =>
                          window.sessionStorage.getItem("entType") === "0"
                            ? onPrintDailyMove()
                            : onPrint(form.getFieldValue("id"))
                        }
                        disabled={printState}
                        size="small"
                      >
                        {_t("strPrint")}
                      </Button>
                      <div
                        style={{ opacity: 0.8, position: "absolute", top: 24 }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + P
                        </Tag>
                      </div>
                    </div>
                  )}

                  {(window.sessionStorage.getItem("entType") === "3" ||
                    window.sessionStorage.getItem("entType") === "4") && (
                    <Upload
                      action={(file) => onImportExcel(file)}
                      beforeUpload={(file) => {
                        handelBeforeUpload(file);
                      }}
                      maxCount={1}
                      showUploadList={false}
                    >
                      <Button
                        icon={<FileExcelOutlined />}
                        size="small"
                        type="text"
                      >
                        {_t("strImportFormExcel")}
                      </Button>
                    </Upload>
                  )}
                  {window.sessionStorage.getItem("entType") !== "0" && (
                    <div>
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => showConfirmDelete()}
                        disabled={
                          form.getFieldValue("id") === "" ? true : false
                        }
                        size="small"
                        danger
                      >
                        {_t("strDelete")}
                      </Button>
                      <div
                        style={{ opacity: 0.8, position: "absolute", top: 24 }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + shift + delete
                        </Tag>
                      </div>
                    </div>
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default EntryForm;
