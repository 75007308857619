import React, { useEffect,useMemo, useState } from "react";
import DataTable, { pageSizeKey } from "../helper/gridDataTable/dataTable";
import { Button, Divider, Space, message, Popconfirm, Tooltip ,Badge} from "antd";
import _t from "../../languages/translate";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import SearchT from "../helper/gridDataTable/components/searchT";
import { useLocation, Link } from "react-router-dom";
import { getPagers, deleteCurrencies } from "../../services/Items/currenciesService";
import { handleEx } from "../helper/handleException";

import { getAuth } from "../../services/Authorization/userRoleService";
import { authKeys } from "../../services/Authorization/authKeys";

const PageCurrencies = () => {
    const location = useLocation();
    const savedPageSize = localStorage.getItem(pageSizeKey);
    const [dataSource, setDataSource] = useState([]);
    const [meta, setMeta] = useState("");

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.branch)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  useMemo(()=>{authorization()},[])
  //////////----|👉 end auth|----//////////
  const [loadData, setLoadData] = useState(false);
  const realodTable =()=>{
    getView()
  }


    const columns = [
        {
            key: "code",
            name: _t("strCode"),
            visible: true,
            width: 300,
        },
        {
            key: "name",
            name: _t("strName"),
            visible: true,
            width: 300,
        },
        {
            name: _t("strStatus"),
            key: "inactive",
            visible: true,
            width: 300,
            formatter: (p) => {
              return p.row.inactive ? (
                <Badge color="red" text={_t("strInActive")} style={{ fontSize: 10 }} />
              ) : (
                <Badge color="green" text={_t("strActive")} style={{ fontSize: 10 }} />
              );
            },
          },

    ]

    useEffect(() => { 
        getView();
    }, [location.search, savedPageSize])

    const getView = async () => {
        setLoadData(true)
        const query = new URLSearchParams(location.search);
        if (savedPageSize && !query.has(pageSizeKey))
            query.set(pageSizeKey, savedPageSize);
        try {
            const { data: responce } = await getPagers(query.toString());
            if (responce.data.length > 0) {
                setDataSource(responce.data);
                setMeta(responce.meta);
            } else {
                setDataSource([]);
                message.info(_t("msgThereIsNoData"), 3);
            }
        } catch (error) {
            handleEx(error);
        }finally{
            setLoadData(false)
        }
    };

    const onDelete = async (id) => {
        try {
            const response = await deleteCurrencies(id);
            getView();
            message.success(response.data.message);
        } catch (ex) {
            message.error(ex.response?.data?.message || _t("msg.not_deleted"));
        }
    };

    const actionButtons = (
        <div className="actionButtons">
            <Space size="small">
            {auth?.perPost && (
                <Button className="addNewButton" type="primary" size="small">
                    <Link to={`/currencies/new`}>{_t("strAddNew")}</Link>
                </Button>
            )}
                <SearchT />
            </Space>
        </div>
    )

    const actionCol = [{
        key: "actions",
        name: _t("strActions"),
        visible: true,
        width: 300,
        formatter: (p) => {
            return (
                <>
                
                    <Tooltip title={auth?.perPut ?_t("strEdit") : _t("strPreview")} color="#6c63ff">
                        <Link style={{ color: "#6c63ff" }} to={`/currencies/${p.row?.id}`}>
                        {auth?.perPut && (
                    <EditOutlined />
                    )}
                    {!auth?.perPut && (
                    <EyeOutlined />
                    )}
                            </Link>
                    </Tooltip>
                
                    {auth?.perDelete && (
                        <>
                    <Divider type="vertical" /><Tooltip title={_t("strDelete")} color="red">
                        <Popconfirm title={_t("strSureToDelete")} onConfirm={() => onDelete(p.row?.id)}>
                            <DeleteOutlined style={{ color: "#EC2867" }} />
                        </Popconfirm>
                    </Tooltip>
                    </>
                    )}
                </>
            )
        },
    }];

    return (
        <>
            <DataTable
                columns={[...columns, ...actionCol]}
                dataSource={dataSource}
                  style={{ height: "calc(100vh - 195px)", width: "100%" }}
                tableName="Scr_Currencies"
                tree={false}
                frezzColExp={false}
                actionButtons={actionButtons}
                meta={meta}
                pageName ={_t("strCurrencies")} 
                groupName={_t("strDefinitions")} 
                type="page"
                handleReload={realodTable}
                loading={loadData}
            />
        </>
    );
};

export default PageCurrencies;
