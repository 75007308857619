import React, { useState } from "react";
import {
  Button,
  Space,
  Divider,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  AppstoreAddOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import _t from "../../../../languages/translate";
import InsertPrintElements from "./popups/InsertPrintElements";
import DataGrid from "react-data-grid";
import { sortObjsArr } from "./popups/helperMethods";

const PrintOptionsSetting = ({ form, dataSource, setDataSource }) => {
  const [showInsertItems, setShowInsertItems] = useState(false);
  const columns = [
    {
      name: "#",
      key: "rowNum",
      minWidth: 30,
      width: 43,
    },
    {
      key: "elementName",
      name: _t("strType"),
      width: 300,
    },
    {
      key: "row",
      name: _t("strRow"),
      width: 100,
    },
    {
      key: "col",
      name: _t("strCol"),
      width: 100,
    },
  ];

  const actionCol = [
    {
      key: "actions",
      name: _t("strActions"),
      visible: true,
      width: 200,
      formatter: (p) => {
        return (
          <>
            <Tooltip title={_t("strEdit")} color="#6c63ff">
              <EditOutlined
                onClick={() => {
                  editRow(p.row);
                }}
                style={{ color: "#2596be" }}
              />
            </Tooltip>
            <Divider type="vertical" />

            <Tooltip title={_t("strDelete")} color="red">
              <Popconfirm
                title={_t("strSureToDelete")}
                onConfirm={() => onDelete(p.row.id)}
              >
                <DeleteOutlined style={{ color: "#EC2867" }} />
              </Popconfirm>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [dataObj, setDataObj] = useState(undefined);

  function pushToArray(arr, obj) {
    const index = arr.findIndex((e) => e.id === obj.id);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
    return arr;
  }

  const handleData = (data) => {
    const list = pushToArray(dataSource, data);
    setShowInsertItems(false);
    setDataSource(sortObjsArr(list));
  };

  const editRow = (data) => {
      const source = JSON.parse(data.source);
      setDataObj({ id: data?.id, source: source });
      setShowInsertItems(true);
  };

  const onDelete = (id) => {
    const data = dataSource.filter((e) => e.id !== id);
    setDataSource(sortObjsArr(data));
  };

  return (
    <div>

      <InsertPrintElements
        mainForm={form}
        dataObj={dataObj}
        showModal={showInsertItems}
        setShowModal={setShowInsertItems}
        onHandleData={handleData}
      />
      <Space split={<Divider type="vertical" />}>
        <Button
          type="text"
          icon={<AppstoreAddOutlined style={{ fontSize: 16 }} />}
          value="insertComponents"
          onClick={() => {
            setDataObj(undefined)
            setShowInsertItems(true);
          }}
        >
          {_t("strInsertElement")}
        </Button>
      </Space>

      <DataGrid
        columns={[...columns, ...actionCol]}
        rows={dataSource.map((e, i) => (e = { ...e, rowNum: i + 1 }))}
        onRowsChange={setDataSource}
        rowHeight={25}
        className="rdg-light"
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        direction={_t("langDiriction")}
        style={{
          marginTop: 10,
          height: `calc(100vh - 350px)`,
          width: "100%",
        }}
      />
    </div>
  );
};

export default PrintOptionsSetting;
