

export function shortCut(e) {
    //-------------------------
    // alt + F = 70
    // alt + P = 80
    // alt + D = 68
    // alt + S = 83
    // alt + R = 82
    // alt + N = 78
    // alt + B = 66
    // alt + enter = 13
    // alt + shift + delete = 46
    // alt + up = 38
    // alt + down = 40
    //-------------------------
    // alt + 1 = 49 
    // alt + 2 = 50
    // alt + 3 = 51
    // alt + 4 = 52
    // alt + 5 = 53
    // alt + 6 = 54
    // alt + 7 = 55
    // alt + 8 = 56
    // alt + 9 = 57
    //-------------------------
    let result = null;
    if (e.altKey && e.keyCode === 70) {
        result = 'search';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 80) {
        result = 'print';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 83) {
        result = 'save';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 68) {
        result = 'distribute';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 82) {
        result = 'refresh';
        e.preventDefault();
    }
    else if (e.altKey && e.shiftKey && e.keyCode === 83)
    {
        result = 'saveAndPrint';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 78) {
        result = 'new';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 66) {
        result = 'stay';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 13) {
        result = 'insert';
        e.preventDefault();
    }
    else if (e.altKey && e.shiftKey && e.keyCode === 46) {
        result = 'delete';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 38) {
        result = 'next';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 40) {
        result = 'prev';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 87) {
        result = 'altW';
        e.preventDefault();
    }  
    else if (e.altKey && e.keyCode === 81) {
        result = 'altQ';
        e.preventDefault();
    }  
    else if (e.altKey && e.keyCode === 49) {
        result = 'block1';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 50) {
        result = 'block2';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 51) {
        result = 'block3';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 52) {
        result = 'block4';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 53) {
        result = 'block5';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 54) {
        result = 'block6';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 55) {
        result = 'block7';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 56) {
        result = 'block8';
        e.preventDefault();
    }
    else if (e.altKey && e.keyCode === 57) {
        result = 'block9';
        e.preventDefault();
    }
    else if (e.altKey) {
        result = 'tag';     
        e.preventDefault();
      }
    return result;
}



