import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Modal,
  DatePicker,
  Input,
  message,
  Tabs,
  Tag,
  Button,
} from "antd";
import _t from "../../../languages/translate";
import RawDetails from "./RawGrid/rawDetails";
import MunfDetails from "./MunfGrid/munfDetails";
import Draggable from "react-draggable";
import { shortCut } from "../../helper/shortCuts";
import InputCurrency from "../../helper/Input/InputCurrency";
import { objCountNum } from "../../helper/countOfZero";
import { emptValue } from "../../helper/helperMethods";
import { _Mul, _RateMul, _SumArr, _Sum } from "../../helper/handleMath";
import {
  calcChangeCountPrivRaw,
  calcChangeCountPrivMunf,
} from "./helper/generalCalc";
// import { calcQtyTotalByCount } from "./RawGrid/handleColumns/calcColumns";
// import { calcQty,calcQtyTotal } from "./MunfGrid/handelColumns";

const MunfInOut = ({
  auth,
  form,
  detail,
  setDetail,
  rowObj,
  objRowNum,
  pattrenSet,
  showModal,
  setShowModal,
  promptForm,
}) => {
  const [loadData, setLoadData] = useState(false);
  const [rawDetail, setRawDetail] = useState([]);
  const [munfDetail, setMunfDetail] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [activeKey, setActiveKey] = useState("0");
  const gridMunfRef = useRef(null);
  const gridRawRef = useRef(null);
  const currRef = useRef(null);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [modalForm] = Form.useForm();
  const { TabPane } = Tabs;
  //  let rawTotal = 0;
  useEffect(() => {
    async function fetchData() {
      setLoadData(true);
      if (
        rowObj?.munfRawMaterials !== null &&
        rowObj?.munfRawMaterials !== undefined
      ) {
        await setRawDetail([
          ...rowObj?.munfRawMaterials.map((e) => {
            return { ...e };
          }),
          { key: rowObj?.munfRawMaterials.length },
        ]);
      } else {
        await setRawDetail([{ key: 0 }]);
      }
      if (
        rowObj?.munfMunfMaterials !== null &&
        rowObj?.munfMunfMaterials !== undefined
      ) {
        await setMunfDetail([
          ...rowObj?.munfMunfMaterials.map((e) => {
            return { ...e };
          }),
          { key: rowObj?.munfMunfMaterials.length },
        ]);
      } else {
        await setMunfDetail([{ key: 0 }]);
      }
      modalForm.setFieldsValue({
        countPriv: rowObj?.count,
        date: form.getFieldValue("date"),
        priceMethodId: rowObj?.priceMethodId,
        currRate: rowObj?.currRate,
      });
    }
    fetchData();
  }, [showModal]);

  const handleChangeCountPrivRaw = async (count, arr) => {
    let result = await calcChangeCountPrivRaw(count, arr);
    await setRawDetail([...result]);
  };

  const handleChangeCountPrivMunf = async (count, arr, rawTotal) => {
    // let _rawTotal = _SumArr(rawDetail, "netTotal");

    let result = await calcChangeCountPrivMunf(count, arr, rawTotal);

    await setMunfDetail([...result]);
  };

  const handleClear = () => {
    setActiveKey("0");
    setLoadData(true);
    setTimeout(() => {
      setLoadData(false);
    }, 50);
  };

  const handelChangeCount = () => {
    let value = modalForm.getFieldValue("countPriv");

    for (let i = 0; i < detail.length; i++) {
      if (i === objRowNum) {
        detail[i].count = value;
      }

      setDetail(detail); // ak~
    }
  };

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };
  const onFinish = () => {
    handelChangeCount();
    let totalDistribution = 0;
    let saveContinue = true;
    const dataOut = detail;
    const rawMaterials = rawDetail;
    const munfMaterials = munfDetail;
    const munfOutput = munfMaterials.filter((obj) =>
      Object.keys(obj).includes("itemId")
    );
    const rawOutput = rawMaterials.filter((obj) =>
      Object.keys(obj).includes("itemId")
    );
    if (munfOutput.length === 0 || rawOutput.length === 0) {
      saveContinue = false;
      message.error(_t("strSaveWithOutAtLeastOne"), 5);
    }
    if (munfMaterials !== null) {
      munfOutput.map((v, i) => {
        if (
          v.distributePerc === undefined ||
          v.distributePerc === 0 ||
          v.distributePerc === null
        ) {
          message.error(
            _t("msgDistributionReqOne") + (i + 1) + _t("msgDistributionReqTwo"),
            5
          );
          saveContinue = false;
        } else {
          totalDistribution = _Sum(totalDistribution,v.distributePerc);
        }
      });
      if (saveContinue === false) {
        return;
      }
      if (totalDistribution !== 100) {
        message.error(  _t("msgDistributionRatio") +
            " " +
            totalDistribution +
            "%" +
            " " +
            _t("msgDistributionRatio2"),
          5
        );
        return;
      }
      dataOut[objRowNum].munfMunfMaterials = munfOutput;
    }
    if (rawMaterials !== null) {
      dataOut[objRowNum].munfRawMaterials = rawOutput;
    }
    setRawDetail(rawOutput);
    setMunfDetail(munfOutput);
    if ((saveContinue = true)) {
      setDetail(dataOut);
      setTimeout(() => {
        setShowModal(false);
        window.sessionStorage.setItem("showModal", false);
      }, 0);
    }
  };

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  let rtime;
  let timeout = false;
  let delta = 600;

  const handleCountPriv = async () => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(await calcCountPriv, delta);
    }
  };

  const calcCountPriv = async () => {
    if (new Date() - rtime < delta) {
      setTimeout(calcCountPriv, delta);
    } else {
      timeout = false;
      let value = emptValue(modalForm.getFieldValue("countPriv"), 1);
      await handleChangeCountPrivRaw(value, rawDetail);
      let _rawTotal = _SumArr(rawDetail, "netTotal");
      await handleChangeCountPrivMunf(value, munfDetail, _rawTotal);
    }
  };

  const [altPress, setAltPress] = useState(false);
  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (JSON.parse(window.sessionStorage.getItem("showModal"))) {
        switch (shortCut(e)) {
          case "save":
            modalForm.submit();
            break;
          case "block1":
            currRef?.current?.focus();
            currRef?.current?.select();
            break;
          case "block2":
            setActiveKey("0");
            setTimeout(function () {
              if (!!gridMunfRef.current)
                gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "block3":
            setActiveKey("1");
            setTimeout(function () {
              if (!!gridRawRef.current)
                gridRawRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
            }, 50);
            break;
          case "tag":
            if (!!gridMunfRef.current)
              gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: null });
            if (!!gridRawRef.current)
              gridRawRef?.current?.selectCell({ idx: 0, rowIdx: null });
            setAltPress(true);
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  return (
    <Modal
      destroyOnClose
      forceRender
      width="100%"
      bodyStyle={{ padding: "0px 10px" }}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {_t("strMunf")}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => {
        window.sessionStorage.setItem("showModal", false);
        setShowModal(false);
      }}
      afterClose={() => handleClear()}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => modalForm.submit()}
          //  disabled={selectedRows === undefined}
        >
          <div>
            {_t("strSave")}
            <div style={{ opacity: 0.8, position: "absolute", top: 24 }}>
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + S
              </Tag>
            </div>
          </div>
        </Button>,
      ]}
    >
      <Form form={modalForm} onFinish={onFinish} forceRender>
        <>
          <div>
            <Form.Item
              name="countPriv"
              label={_t("strCount")}
              style={{ padding: "0px 57px" }}            
            >
              <InputCurrency
                innerRef={currRef}
                precision={objCountNum.value}
                size="small"
                style={{ width: "100%" }}
                onChange={async () => {
                  await handleCountPriv();
                }}
                disabled={!pattrenSet?.modelEnable}
                
                />
            </Form.Item>
            <div
              style={{
                opacity: 0.8,
                zIndex: 99,
                position: "absolute",
                top: 60,
              }}
            >
              <Tag color="#2b2b2b" hidden={!altPress}>
                alt + 1
              </Tag>
            </div>
          </div>

          <Form.Item name="date" hidden>
            <DatePicker showTime={{ format: "HH:mm" }} />
          </Form.Item>
          <Form.Item name="priceMethodId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="currRate" hidden>
            <Input />
          </Form.Item>
          <Tabs
            style={{ marginTop: 0 }}
            tabBarStyle={{ width: 90 }}
            activeKey={activeKey}
            tabPosition={_t("txtDiriction")}
            className="ak-tabs"
            onTabClick={handleChangeTab}
          >
            <TabPane
     
              forceRender
              tab={
                <div>
                  {_t("strTheOutput")}
                  <div
                    style={{
                      opacity: 0.8,
                      zIndex: 99,
                      position: "absolute",
                      top: 24,
                    }}
                  >
                    <Tag color="#2b2b2b" hidden={!altPress}>
                      alt + 2
                    </Tag>
                  </div>
                </div>
              }
              style={{ padding: 10 }}
              key="0"
            >
              <MunfDetails
                auth={auth}
                innerRef={gridMunfRef}
                pattrenSet={pattrenSet}
                form={modalForm}      
                setMunfDetail={setMunfDetail}
                readOnly={pattrenSet?.modelEnable}
                munfDetail={munfDetail}
                setLoadData={setLoadData}
                id={""}
                promptForm={promptForm}
                noStyle={true}
                showTotalQty={true}
              />
            </TabPane>
            <TabPane
      
              forceRender
              tab={
                <div>
                  {_t("strTheInput")}
                  <div
                    style={{
                      opacity: 0.8,
                      zIndex: 99,
                      position: "absolute",
                      top: 24,
                    }}
                  >
                    <Tag color="#2b2b2b" hidden={!altPress}>
                      alt + 3
                    </Tag>
                  </div>
                </div>
              }
              style={{ padding: 10 }}
              key="1"
            >
              <RawDetails
                auth={auth}
                innerRef={gridRawRef}
                pattrenSet={pattrenSet}
                form={modalForm}
                callType="inOut"
                setRawDetail={setRawDetail}
                readOnly={pattrenSet?.modelEnable}
                rawDetail={rawDetail}
                setMunfDetail={setMunfDetail}
                munfDetail={munfDetail}
                activeKey={activeKey}
                showModal={showModal}
                setLoadData={setLoadData}
                id={""}
                promptForm={promptForm}
                noStyle={true}
                showTotalQty={true}
              />
            </TabPane>
          </Tabs>
        </>
      </Form>
    </Modal>
  );
};

export default MunfInOut;
