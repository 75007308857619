
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/MunfPattSett";

export function getByMunfPatt(query) {
  return http.post(`${apiEndpoint}/GetByMunfPatt?munfPatt=${query}`);
}

export function getMunfPattSettById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function initiale(id) {
  return http.post(`${apiEndpoint}/Initiale?munfPatt=${id}`);
}

export function editMunfPattSett(obj) {
  
  if (obj.id !== "new" && obj.id !== undefined ) {
    return http.put(apiEndpoint, obj);
  } else {
   return http.post(apiEndpoint, obj);
 }
}

export function deleteMunfPattSett(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}





