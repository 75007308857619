export function checkEntType(typeId) {
  const obj = {
    showCode: true,
    showNameAr: true,
    showNameEn: true,
    showEntTypeId: true,
    showBranchId: true,
    showAccVisible: true,
    showAccEnable: true,
    showAccDefId: true,
    showEntCostCenterVisible: true,
    showEntCostCenterEnable: true,
    showEntCostCenterReq: true,
    showEntPattSettCostCenter_Maps: true,
    showDateEnable: true,
    showCurrVisible: true,
    showCurrEnable: true,
    showCurrChType: true,
    showCurrApplyType: true,
    showCurrDefId: true,
    showDocReq: true,
    showDocVisible: true,
    showShowPrevBalance: true,
    showShowDayBalance: true,
    showDetailsColSett: true,
    showCodeStart: true,
    showCodeEnable: true,
    showGenShortEnt: true,
    showJournalId: true,
    showPost: true,
    showPostVisible: true,
    showPostEnable: true,
    showRelatedPattSettId: true,
  };
  switch (typeId) {
    case "1addd599-60e1-48bf-9d91-390d6b262662": // الحركة اليومية
      obj.showDocReq = false;
      obj.showDocVisible = false;
      obj.showCodeStart = false;
      obj.showCodeEnable = false;
      obj.showPostEnable = false;
      break;
    case "9dfc78cd-3569-4433-a9a6-7c0e6aa8b6e7": // المدفوعات
      obj.showShowPrevBalance = false;
      obj.showRelatedPattSettId = false;
      break;
    case "336894bf-06df-4d08-b710-51e2f46b1401": // المقبوضات
      obj.showShowPrevBalance = false;
      obj.showRelatedPattSettId = false;
      break;
    case "9b6267dd-651f-4fd6-94c3-de6f63bd4ef5": // سند قيد
      obj.showAccVisible = false;
      obj.showAccEnable = false;
      obj.showAccDefId = false;
      obj.showShowPrevBalance = false;
      obj.showRelatedPattSettId = false;
      obj.showEntCostCenterVisible = false;
      obj.showEntCostCenterEnable = false;
      obj.showEntCostCenterReq = false;
      obj.showEntPattSettCostCenter_Maps = false;
      break;
    case "273eb372-062e-4ebc-9579-262af345512a": // قيد افتتاحي
      obj.showAccVisible = false;
      obj.showAccEnable = false;
      obj.showAccDefId = false;
      obj.showShowPrevBalance = false;
      obj.showPostEnable = false;
      obj.showRelatedPattSettId = false;
      obj.showEntCostCenterVisible = false;
      obj.showEntCostCenterEnable = false;
      obj.showEntCostCenterReq = false;
      obj.showEntPattSettCostCenter_Maps = false;
      break;
    default:
      break;
  }

  return obj;
}
