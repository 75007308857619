import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  TreeSelect,
  Row,
  Col,
  Menu,
  Tabs,
  Dropdown,
  Typography,
  Spin,
  Card,
  Tooltip,
  Tag,
} from "antd";
import {
  getItemById,
  editItem,
  getActiveParents,
  getInitiale,
} from "../../../services/Items/itemService";
import { getTreeSearch } from "../../../services/itemFinalAccountService";
import { GetActiveChildren } from "../../../services/branchService";
import {
  ExclamationCircleOutlined,
  RetweetOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getActiveUnit } from "../../../services/Items/itemUnitService";
import { useApi } from "../../../services/useApi";
import DropDownList from "../../helper/dataGrid/source/DropDownList";
import CurrEditor from "../../helper/dataGrid/source/CurrEditor";
import { TextEditor, SelectCellFormatter } from "react-data-grid";
import EditableGrid from "../../helper/dataGrid/DataGrid";
import { shortCut } from "../../helper/shortCuts";
import { getNumFormat, objCountNum } from "../../helper/countOfZero";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ItemsForm = ({ onNewStore }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const IX_Name2 = useRef(null);
  const gridUnitRef = useRef(null);
  const gridPriceRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [mainState, setMainState] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [priceListCol, setPriceListCol] = useState([]);
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { confirm } = Modal;
  //******************************//
  const [parentChange, setParentChange] = useState(false);
  const [storeParentOpt, setStoreParentOpt] = useState([]);
  const [branchChildsOpt, setBranchChildsOpt] = useState([]);
  const [priceDetails, setPriceDetails] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [stateParintId, setStateParintId] = useState("");
  const [stateStoreIds, setStateStoreIds] = useState([]);
  const [dataUnits, setDataUnits] = useState([]);
  const [isGoBack, setIsGoBack] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { TextArea } = Input;
  const { Text } = Typography;
  const [{ data }] = useApi(getActiveUnit(), [null || null]);

  useEffect(() => {
    setDataUnits(data);
  }, [data]);

  const typeOpt = [
    { id: 1, name: _t("strBigger") },
    { id: 2, name: _t("strSmaller") },
  ];

  const getUnitsOptions = (rowKey) => {
    const filteredUnits = dataSource?.filter((row) => row?.key !== rowKey);
    const selectedUnits = filteredUnits.map((row) => row?.unitId);
    return dataUnits?.filter((unit) => !selectedUnits?.includes(unit?.id));
  };

  const handleDelete = (key, type) => {
    if (type !== 0) {
      const newUnit = dataSource.filter((e) => e.key !== key);
      const newUnitsPrice = priceDetails.filter((e) => e.key !== key);
      let newNum = 0;
      for (let i = 0; i < newUnit.length; i++) {
        newUnit[i].key = newNum;
        newNum++;
      }
      newNum = 0;
      for (let i = 0; i < newUnitsPrice.length; i++) {
        newUnitsPrice[i].key = newNum;
        newNum++;
      }
      setDataSource([...newUnit]);
      setPriceDetails([...newUnitsPrice]);
    } else message.warning(_t("msgCannotDeleteDeffVal"));
  };

  const deleteColumn = [
    {
      key: "delete",
      name: _t("strDelete"),
      align: "center",
      dataIndex: "delete",
      resizable: false,
      width: "5%",
      formatter(props) {
        return (
          <DeleteTwoTone
            twoToneColor="#eb2f96"
            style={{ fontSize: "16px" }}
            onClick={() => conDelete(props.row.key, props.row.type)}
          />
        );
      },
    },
  ];

  const conDelete = (key, type) => {
    confirm({
      title: _t("strConfirmDeleteAsk"),
      icon: <ExclamationCircleOutlined />,
      content: _t("strConfirmDeleteMessage"),
      okText: _t("strYes"),
      okType: "danger",
      cancelText: _t("strNo"),
      direction: _t("langDiriction"),
      onOk() {
        handleDelete(key, type);
        // const delValue = dataSource.filter((item) => item.key !== key);
        // sortDataKey(delValue, key);
      },
      onCancel() {},
    });
  };

  const unitPriceCol = [
    {
      key: "key",
      name: "#",
      width: "5%",
      resizable: false,
      formatter(props) {
        const key = props?.row?.key + 1;
        return key;
      },
    },
    {
      key: "unitId",
      name: _t("strUnits"),
      width: "20%",
      formatter(props) {
        const id = props?.row?.unitId;
        return id ? dataUnits.find((c) => c?.id === id)?.name : null;
      },
    },
  ];

  const columns = [
    {
      key: "key",
      name: "#",
      width: "5%",
      resizable: false,
      formatter(props) {
        const key = props?.row?.key + 1;
        return key;
      },
    },
    {
      key: "unitId",
      name: _t("strUnits"),
      width: "20%",
      editor: (p) => (
        <DropDownList
          rowKey={p?.row?.key}
          value={p.row?.unitId}
          onFilter={getUnitsOptions}
          onChange={(e) => {
            p?.onRowChange({ ...p?.row, unitId: e }, true);
            addEmptyRow(dataSource, e, p?.row?.key);
            handleSaveUnitPrice(dataSource, e, p?.row?.key);
          }}
          options={dataUnits}
        />
      ),
      formatter: (p) =>
        dataUnits.find((c) => c?.id === p?.row?.unitId)?.name || null,
    },
    {
      key: "type",
      name: _t("strType"),
      width: "15%",
      editor: (p) => (
        <DropDownList
          value={p.row?.type}
          onChange={(e) => p?.onRowChange({ ...p?.row, type: e }, true)}
          options={typeOpt}
        />
      ),
      editable: (row) => row.key !== 0,
      formatter: (p) =>
        typeOpt.find((c) => c?.id === p.row.type)?.name || (
          <Text disabled>{_t("strMainUnit")}</Text>
        ),
    },

    {
      key: "value",
      name: _t("strValue"),
      width: "15%",
      editor: (p) => {
        return (
          <CurrEditor
            row={p.row}
            column={p.column}
            onRowChange={p.onRowChange}
            onBlur={(e) => {
              p?.onRowChange(
                {
                  ...p?.row,
                  [p.column.key]: e.target.value > 0 ? e.target.value : 1,
                },
                true
              );
            }}
            onClose={p.onClose}
          />
        );
      },
      formatter: (p) => {
        return (
          <Tooltip title={parseFloat(p?.row?.value)}>
            {getNumFormat(parseFloat(p?.row?.value), 1)}
          </Tooltip>
        );
      },
      editable: (row) => row.key !== 0,
    },

    {
      key: "isStock",
      name: _t("strStocked"),
      width: "5%",
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.isStock}
            onChange={() => {
              let check = handleCheckCount(row);
              onRowChange({
                ...row,
                isStock: check,
              });
            }}
            isCellSelected={isCellSelected}
            disabled={
              handleCheckStock() === true &&
              (row.isStock === false || row.isStock === undefined)
                ? true
                : false
            }
          />
        );
      },
    },

    {
      key: "isDef",
      name: _t("strDefault"),
      width: "5%",
      formatter({ row, onRowChange, isCellSelected }) {
        return (
          <SelectCellFormatter
            value={row.isDef}
            onChange={() => {
              let check = row?.unitId !== undefined ? true : false;
              onRowChange({ ...row, isDef: check }); //isDef: row.isDef });
              if (check) handleCheckDef(row);
            }}
            isCellSelected={isCellSelected}
          />
        );
      },
    },

    {
      key: "note",
      name: _t("strNote"),
      width: "30%",
      editor: TextEditor,
    },
  ];

  const handleCheckCount = (row) => {
    let num = 0;
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].isStock === true) {
        num++;
      }
    }

    return row?.unitId !== undefined ? (num > 1 ? !row.isStock : true) : false;
  };

  const handleCheckStock = () => {
    let num = 0;
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].isStock === true) {
        num++;
      }
    }
    if (num > 2) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckDef = (row) => {
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].key === row.key) {
        dataSource[i].isDef = true;
      } else {
        dataSource[i].isDef = false;
      }
    }
  };

  const promptForm = () => {
    setProChange(true);
  };

  const deleteEmptyRow = (arr) => {
    return arr.filter((obj) => Object.keys(obj).includes("unitId"));
  };

  const addEmptyRow = (arr, id, rowKey) => {
    const rep = arr.map((e) =>
      e.key === rowKey ? { ...e, unitId: id } : { ...e }
    );
    const tepArr = deleteEmptyRow(rep);
    let newNum =
      tepArr.reduce(
        (acc, shot) => (acc = acc > shot.key ? acc : shot.key),
        -1
      ) + 1;
    const output = [...tepArr, { key: newNum, type: 1, value: 1 }];
    setDataSource(output);
  };

  const handleSaveUnitPrice = (list, id, rowKey) => {
    const rep = list.map((e) =>
      e.key === rowKey ? { ...e, unitId: id } : { ...e }
    );
    const arr = deleteEmptyRow(rep);

    rowKey === undefined ? (rowKey = 0) : (rowKey = rowKey);
    if (priceDetails[rowKey] !== undefined) {
      priceDetails[rowKey].unitId = arr[rowKey].unitId;
    } else {
      priceDetails.push({ key: rowKey, unitId: arr[rowKey].unitId });
    }
    setPriceDetails([...priceDetails]);
  };

  const initValue = async (Id) => {
    try {
      const { data: newData } = await getInitiale(Id);
      if (id !== "new" && id !== "add") {
        form.setFieldsValue({
          code: newData.data.code !== null ? newData.data.code : "",
        });
      } else {
        form.setFieldsValue({
          code: newData.data.code !== null ? newData.data.code : "",
          parentId: newData.data.parentId !== null ? newData.data.parentId : "",
          //  isMain: newData.data.isMain,
          type: newData.data.type,

          itemFinalAccId:
            newData.data.itemFinalAccId !==
            "00000000-0000-0000-0000-000000000000"
              ? newData.data.itemFinalAccId
              : undefined,

          inactive: newData.data.inactive,
        });
        addEmptyRow(newData.data.units);
      }
      extraCol(newData.data.priceColumns);
      setPriceDetails(newData.data.priceDetails);
      handleActiveParents(
        "",
        newData.data.parentId !== null ? newData.data.parentId : ""
      );
      IX_Name.current.focus();
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  const getById = async () => {
    try {
      const { data } = await getItemById(id);
      if (data.data.parentId === null) {
        data.data.parentId = "";
      }
      setStateParintId(data.data.parentId);
      setStateStoreIds(data.data.branchIds);
      form.setFieldsValue({
        code: data.data.code !== null ? data.data.code : "",
        parentId: data.data.parentId !== null ? data.data.parentId : "",
        isMain: data.data.isMain,
        name: data.data.name,
        name2: data.data.name2,
        itemFinalAccId: data.data.itemFinalAccId,
        // type: data.data.type,
        note: data.data.note,
        inactive: data.data.inactive,
      });

      extraCol(data.data.priceColumns);
      setPriceDetails(data.data.priceDetails);
      handleActiveBranches(
        "",
        data.data.branchIds !== null ? data.data.branchIds : ""
      );
      handleActiveParents(
        "",
        data.data.parentId !== null ? data.data.parentId : ""
      );
      IX_Name.current.focus();
      addEmptyRow(data.data.units);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  const extraCol = (arr) => {
    const list = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = {};

      obj["key"] = arr[i].key;
      obj["name"] = arr[i].name;
      obj["width"] = 200;
      obj["editor"] = (p) => (
        <CurrEditor
          row={p.row}
          column={p.column}
          onRowChange={p.onRowChange}
          onBlur={(e) => {
            p?.onRowChange(
              {
                ...p?.row,
                [p.column.key]: e.target.value,
              },
              true
            );
          }}
          onClose={p.onClose}
        />
      );

      obj["formatter"] = (p) => {
        if (p?.column?.key !== undefined) {
          var value = p?.row[p.column.key];
        }
        return value !== undefined ? (
          <Tooltip title={parseFloat(value)}>
            {getNumFormat(parseFloat(value), objCountNum?.value)}
          </Tooltip>
        ) : null;
      };
      list.push(obj);
    }
    setPriceListCol(list);
  };

  const [itemFinalTree, setItemFinalTree] = useState([]);

  const handleGetTreeSearch = async (_Search) => {
    try {
      const { data: responce } = await getTreeSearch(_Search);
      setItemFinalTree(responce.data);
    } catch (error) {
      handleEx(error);
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      handleGetTreeSearch("");
      if (id === "new" || typeof onNewStore === "function") {
        return await initValue("");
      } else if (id === "add") {
        const parentId = query.get("ParentId");

        // const parentId = history.location.state?.parentId;

        if (!parentId) return;
        setIsGoParentId(parentId);
        return await initValue(parentId);
      } else {
        setParentChange(true);
        return await getById(id);
      }
    }
    fetchData();
  }, []);

  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        direction: _t("langDiriction"),
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          initValue(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      initValue(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      if (history.action === "POP") {
        history.push({ pathname: "/items" });
      } else {
        history.goBack();
      }
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/items/new" });
      }
      form.resetFields();
      initValue(isGoParentId);
      handleActiveParents("", "");
      IX_Name.current.focus();
    }
  };

  const handleActiveBranches = async (_Search, _Active) => {
    try {
      let obj = { search: _Search, addIds: _Active };
      const { data: branchChildData } = await GetActiveChildren(obj);
      setBranchChildsOpt(branchChildData.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  // search client & supp by type num // AK~
  const handleActiveParents = async (_Search, _Active) => {
    try {
      const { data: responce } = await getActiveParents(_Search, _Active);
      setStoreParentOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    } finally {
      setLoadData(false);
    }
  };

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };
  const [activeKey, setActiveKey] = useState("0");
  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "block2":
              setActiveKey("0");
              setTimeout(function () {
                if (!!gridUnitRef.current)
                  gridUnitRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              }, 50);
              break;
            case "block3":
              setActiveKey("1");
              setTimeout(function () {
                if (!!gridPriceRef.current)
                  gridPriceRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              }, 50);
              break;
            case "tag":
              if (!!gridUnitRef.current)
                gridUnitRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridPriceRef.current)
                gridPriceRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    setLoadData(true);
    setProChange(false);
    values.id = id;
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    if (dataSource !== null) {
      let output = dataSource.filter((obj) =>
        Object.keys(obj).includes("unitId")
      );
      values.units = output;
    }
    values.priceDetails = priceDetails;
    values.type = 0;
    try {
      const data = await editItem(values);
      setLoadData(false);
      message.success(data.data.message, 3);
      goBack();
      form.setFieldsValue({ isMain: mainState });
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name, IX_Name2 });
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const storeParentOptList =
    storeParentOpt.length > 0 &&
    storeParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );

  const isMainChange = (e) => {
    setProChange(true);
  };

  return (
    <div key="mainDiv">
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "150px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{
              isMain: mainState,
            }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={24}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strDefinitions")}</Text>
                      <Text style={{ fontWeight: 700 }}>{_t("strItems")}</Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          className="inpCode"
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>

            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={_t("strItemClassification")}
                        name="isMain"
                        hidden={typeof onNewStore === "function"}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                          onChange={(e) => {
                           
                            setMainState(e.target.value);
                            isMainChange();
                          }}
                        >
                          <Radio value={false} autoFocus>
                            {_t("strSub")}
                          </Radio>
                          <Radio value={true}>{_t("strMain")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="parentId"
                        label={_t("strMainItem")}
                        rules={[{ required: false }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) => {
                            handleActiveParents(value, stateParintId);
                          }}
                          optionFilterProp="children"
                          notFoundContent={
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                          }
                          onChange={onChangeParent}
                          placeholder={_t("strNotSelected")}
                          size="small"
                        >
                          {storeParentOptList}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name"
                        label={
                          <div>
                            {_t("strName")}
                            <div
                              style={{
                                opacity: 0.8,
                                position: "absolute",
                                top: 4,
                              }}
                            >
                              <Tag color="#2b2b2b" hidden={!altPress}>
                                alt + 1
                              </Tag>
                            </div>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="name2"
                        label={_t("strName2")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          placeholder={_t("strName2")}
                          maxLength={200}
                          autoComplete="off"
                          onChange={promptForm}
                          ref={IX_Name2}
                          size="small"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        name="itemFinalAccId"
                        rules={[{ required: true }]}
                        label={_t("strCategory")}
                      >
                        <TreeSelect
                          style={{ width: "100%" }}
                          showSearch
                          allowClear
                          onSearch={(value) => {
                            handleGetTreeSearch(value);
                          }}
                          filterTreeNode={(search, item) => {
                            return (
                              item.title
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) >= 0
                            );
                          }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          treeData={itemFinalTree}
                          placeholder={_t("strCategory")}
                          size="small"
                          treeDefaultExpandAll
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item label={_t("strUnits")}>
                        <Card
                          bodyStyle={{ padding: 0 }}
                          style={{ marginBottom: 10 }}
                        >
                          <Tabs
                            style={{
                              marginTop: -5,
                              marginRight: 5,
                              marginLeft: 5,
                            }}
                            activeKey={activeKey}
                            onTabClick={handleChangeTab}
                          >
                            <TabPane
                              tab={
                                <div>
                                  {_t("strUnitList")}
                                  <div
                                    style={{
                                      opacity: 0.8,
                                      position: "absolute",
                                      top: 24,
                                    }}
                                  >
                                    <Tag color="#2b2b2b" hidden={!altPress}>
                                      alt + 2
                                    </Tag>
                                  </div>
                                </div>
                              }
                              key="0"
                            >
                              <EditableGrid
                                innerRef={gridUnitRef}
                                style={{
                                  height: `calc(100vh - 400px)`,
                                  width: "100%",
                                }}
                                columns={[...columns, ...deleteColumn]}
                                dataSource={dataSource}
                                setDataSource={setDataSource}
                              />
                            </TabPane>
                            <TabPane
                              tab={
                                <div>
                                  {_t("strPriceList2")}
                                  <div
                                    style={{
                                      opacity: 0.8,
                                      position: "absolute",
                                      top: 24,
                                    }}
                                  >
                                    <Tag color="#2b2b2b" hidden={!altPress}>
                                      alt + 3
                                    </Tag>
                                  </div>
                                </div>
                              }
                              key="1"
                            >
                              <EditableGrid
                                innerRef={gridPriceRef}
                                style={{
                                  height: `calc(100vh - 400px)`,
                                  width: "100%",
                                }}
                                columns={[...unitPriceCol, ...priceListCol]}
                                dataSource={priceDetails}
                                setDataSource={setPriceDetails}
                              />
                            </TabPane>
                          </Tabs>
                        </Card>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="large">
                  <div>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      size="small"
                      disabled={loading}
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => {
                      history.action === "PUSH"
                        ? history.goBack()
                        : history.push("/items");
                    }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};

export default ItemsForm;
