
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/MunfModel";

export function getPagers(query) {
 
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getSearchPopup(search) {
  return http.post(
    `${apiEndpoint}/GetSearchPopup/?q=${search}`
  );
}

export function getManufModelById(Id) {
  
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editManufModels(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function setDefaultItem(Id) {
  return http.post(
    `${apiEndpoint}/SetDefaultItem?MunfModelMunfMaterialId=${Id}`
  );
}

export function deleteManufModels(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getManufModels() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllManufacturingModels() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewManufModelsCode() {
  return http.post(`${apiEndpoint}/GetNewCode/`);
}

export function getPagerManufModels(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function searchManufModels(obj) {
  return http.post(`${apiEndpoint}/GetPagerSearch/`, obj);
}

export function getActiveTreeManufModels() {
  return http.post(`${apiEndpoint}/GetActiveTree`);
}
