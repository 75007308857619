import React, { useState, useEffect } from "react";
import { Tabs, Spin } from "antd";
import _t from "../../../../languages/translate";
import PrintOptionsSetting from "./PrintOptionsSetting";

const PrintPattSettings = ({
  form,
  dataObj,
  selectedTypeId,
  selectedBranchId,
  printHeadData,
  setPrintHeadData,
  pageHeaderData,
  setPageHeaderData,
  contentData,
  setContentData,
  pageFooterData,
  setPageFooterData,
  printFooterData,
  setPrintFooterData,
}) => {
  const [loadData, setLoadData] = useState(false);
  // main table-----------

  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setLoadData(true);

    if (dataObj !== undefined) {
      fillData(dataObj);
    }
    setLoadData(false);
  }, [dataObj]);

  const fillData = (data) => {
    // form.setFieldsValue({
    //detailsColSett: data?.data?.detailsColSett,
    // });
  };

  const onTabsChange = (key) => {
    setActiveKey(key);
  };

  // useEffect(() => {
  //   let result =
  //     printHeadData.length > 0 ||
  //     pageHeaderData.length > 0 ||
  //     contentData.length > 0 ||
  //     pageFooterData.length > 0 ||
  //     printFooterData.length > 0
  //       ? true
  //       : false;

  
  // }, [
  //   printHeadData,
  //   pageHeaderData,
  //   contentData,
  //   pageFooterData,
  //   printFooterData,
  // ]);

  return (
    <>
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <div name="cardsId" className="frContent">
          <Tabs
            type="card"
            onChange={onTabsChange}
            activeKey={activeKey}
            items={[
              {
                key: "1",
                label: _t("strPrinthead"),
                children: (
                  <PrintOptionsSetting
                    form={form}
                    type="Printhead"
                    dataSource={printHeadData}
                    setDataSource={setPrintHeadData}
                  />
                ),

                forceRender: true,
              },
              {
                key: "2",
                label: _t("strPageHeader"),
                children: (
                  <PrintOptionsSetting
                    form={form}
                    type="PageHeader"
                    dataSource={pageHeaderData}
                    setDataSource={setPageHeaderData}
                  />
                ),
                forceRender: true,
              },
              {
                key: "3",
                label: _t("strContent"),
                children: (
                  <PrintOptionsSetting
                    form={form}
                    type="Content"
                    dataSource={contentData}
                    setDataSource={setContentData}
                  />
                ),
                forceRender: true,
              },
              {
                key: "4",
                label: _t("strPageFooter"),
                children: (
                  <PrintOptionsSetting
                    form={form}
                    type="PageFooter"
                    dataSource={pageFooterData}
                    setDataSource={setPageFooterData}
                  />
                ),
                forceRender: true,
              },
              {
                key: "5",
                label: _t("strPrintFooter"),
                children: (
                  <PrintOptionsSetting
                    form={form}
                    type="PrintFooter"
                    dataSource={printFooterData}
                    setDataSource={setPrintFooterData}
                  />
                ),
                forceRender: true,
              },
            ]}
          />
        </div>
      </Spin>
    </>
  );
};

export default PrintPattSettings;
