import React from 'react';
import logo from "../../images/svg/Logo/LandingLogo.svg";
//import { Watermark } from 'antd';
const HomePage = () => (
  <>
  <img
  style={{ width: 300, margin: "10px 18px" ,opacity:"0.7"}} 
  className='center'
  alt="Yottabase"
  src={logo}
/>

<strong 
className='center'
style={{ width: 300, margin: "105px 18px" ,opacity:"0.7"}} 
>{process.env.REACT_APP_VIRSION}</strong>
  </>
);
export default HomePage;