import React from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { PostChangePassword } from "../../../services/Authorization/ChangePasswordService";

const ChangePasswordForm = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const { data } = await PostChangePassword(values);
      message.success(data.message, 3);
      form.resetFields();
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  return (
    <div className="bg">
      <Form
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={12}>
          <Col xs={24} md={24}>
            <Form.Item
              name="userName"
              label={_t("strUserName")}
              rules={[
                {
                  required: true,
                  message: `${_t("strIsRequired")}`,
                },
                { min: 1, message: `${_t("strFrom3-200")}` },
                { max: 200, message: `${_t("strFrom3-200")}` },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                maxLength={200}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              prefix={<LockOutlined className="site-form-item-icon" />}
              label={_t("strCurrentPassword")}
              name="currentPassword"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 100,
                  message: _t("msgInputYourPass"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              name="newPassword"
              label={_t("strNewPassword")}
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 100,
                  message: _t("msgInputYourPass"),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              name="confirmNewPassword"
              label={_t("strConfirmPassword")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 100,
                  message: _t("msgInputYourConPass"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(_t("msgPassNotMatch"));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="buttonForms">
              {_t("strSubmit")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
