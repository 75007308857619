// import { getDetailsByUnits } from "../../../../services/invoiceService";
import { getPriceByUnit } from "../../../../services/Items/itemService";
import { handleEx } from "../../../helper/handleException";
import { emptValue } from "../../../helper/helperMethods";
import { _SumArr } from "../../../helper/handleMath";
import {
  calcChangeCountPrivRaw,
  calcChangePricePrivRaw,
  calcChangeCountPrivMunf,
} from "../helper/generalCalc";

// تسعير الجدول
// export const handelClxPriceMethod = async (
//   arr,
//   date,
//   priceMethodId,
//   currRate
// ) => {
//   let tempArr = arr.map((e) => {
//     return { ...e };
//   });
//   const mainArray = distributeArray(tempArr, priceMethodId);
//   const merge = mergedArray(mainArray, "munfRawMaterials");
//   const list = groupedArray(...merge);
//   const priceByUnit = await handleGetPriceByUnit(list, date, priceMethodId);
//   return await calcMunfComplex(mainArray, priceByUnit);
// };

// Complex تسعير جدول الـ
export const handelClxPriceMethod = async (
  arr,
  date,
  priceMethodId,
  currRate,
  type = "manual"
) => {
  let tempArr = arr.map((e) => {
    return { ...e };
  });
  const mainArray = distributeArray(tempArr, priceMethodId);
  const merge = mergedArrayComplex(
    mainArray,
    "munfRawMaterials",
    currRate,
    type
  );
  const list = removeDuplicates(merge);
  const priceByUnit = await handleGetPriceByUnit(list, date, priceMethodId);
  
  return await calcMunfComplex(mainArray, priceByUnit);
};

//#region Calc

// حساب جدول المركب
const calcMunfComplex = async (mainArray, priceByUnit) => {
  for (let i = 0; i < mainArray?.length; i++) {
    if (mainArray[i]?.munfRawMaterials === undefined) continue;

    mainArray[i].munfRawMaterials = await calcRawArrays(
      mainArray[i]?.munfRawMaterials,
      priceByUnit,
      emptValue(mainArray[i]?.count, 1));

    if (mainArray[i]?.munfMunfMaterials === undefined) continue;

    mainArray[i].munfMunfMaterials = await calcMunfArrays(
      mainArray[i].munfMunfMaterials,
      mainArray[i]?.munfRawMaterials,
      emptValue(mainArray[i]?.count, 1));
      
  }
  return mainArray;
};

// حساب جدول الأولية
const calcRawArrays = async (rawArray, priceByUnit, count) => {
  for (let i = 0; i < rawArray?.length; i++) {
    if (rawArray[i].itemUnit_MapId === undefined) continue;
    
    rawArray[i].price = await getPriceById(
      rawArray[i]?.itemUnit_MapId,
      rawArray[i]?.currRate,
      priceByUnit
    );
  }
  return await calcChangePricePrivRaw(count, rawArray);
  // return await calcChangeCountPrivRaw(count, rawArray);
};

// حساب جدول الجاهزة
const calcMunfArrays = async (munfArray, rawArray, count) => {
  let rawTotal = _SumArr(rawArray, "netTotal");
  return await calcChangeCountPrivMunf(count, munfArray, rawTotal);
};

//#endregion

//#region Fun

// جلب الاسعار من السيرفر
const handleGetPriceByUnit = async (list, date, priceMethodId) => {
  try {
    const { data: newData } = await getPriceByUnit(list, date, priceMethodId);
    return newData?.data
  } catch (error) {
    handleEx(error);
  }
};

// توزيع طريقة التسعير على جدول المركب
const distributeArray = (arr, priceMethodId) => {
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i]?.munfModelId === undefined) continue;
    arr[i].priceMethodId = priceMethodId;
  }
  return arr;
};

// // دمج جداول الاولية من كل اسطر جول المركب
// const mergedArray = (arrays, propName) => {
//   const groups = new Map();
//   arrays.forEach((array) => {
//     const key = array.prop;
//     groups.set(key, groups.get(key) || []);
//     groups.get(key).push(array[propName]);
//   });
//   return Array.from(groups.values()).map((items) => {
//     return items.flat();
//   });
// };

// // ItemUnit_MapId حذف التكرارات و الابقاء فقط على الـ
// const groupedArray = (array) => {
//   let unitArr = [];
//   for (let i = 0; i < array?.length; i++) {
//     if (array[i]?.itemId === undefined) continue;
//     unitArr.push(array[i].itemUnit_MapId);
//   }
//   let filteredArr = [...new Set(unitArr)];
//   return filteredArr;
// };

// تحديد السعر حسب نوع الوحدة بالسطر
const getPriceById = (id, value, arrUnitIds) => {
  for (let i = 0; i < arrUnitIds?.length; i++) {
    if ((arrUnitIds[i]?.itemUnit_MapId + arrUnitIds[i]?.currRate) !== (id + value)) continue;
    return arrUnitIds[i].price;
  }
};

//#endregion

//#region Complex Fun

const removeDuplicates = (array) => {
  const seen = new Set();
  const newArr = [];
  for (const obj of array) {
    const key = obj["itemUnit_MapId"] + obj["currRate"];
    if (!seen.has(key)) {
      newArr.push(obj);
      seen.add(key);
    }
  }
  return newArr;
};

const loopingSetCurr = (array, propName, currencyRate, type) => {
  if (type === "complex") {
    for (let i = 0; i < array?.length; i++) {
      for (let c = 0; c < array[i][propName]?.length; c++) {
        array[i][propName][c].currRate = array[i].currRate;
      }
    }
  } else {
    for (let i = 0; i < array?.length; i++) {
      for (let c = 0; c < array[i][propName]?.length; c++) {
        array[i][propName][c].currRate = currencyRate;
      }
    }
  }
  return array;
};

// دمج جداول الاولية من كل اسطر جول المركب مع اضافة التعادل على كل سطر
const mergedArrayComplex = (array, propName, currencyRate, type) => {
  let currUnitArr = loopingSetCurr(array, propName, currencyRate, type);
  const groups = new Map();
  currUnitArr.forEach((array) => {
    const key = array.prop;
    groups.set(key, groups.get(key) || []);
    groups.get(key).push(array[propName]);
  });
  const finalArr = Array.from(groups.values()).map((items) => {
    return items.flat();
  });
  let unitArr = [];
  for (let i = 0; i < finalArr[0]?.length; i++) {
    if (finalArr[0][i]?.itemId === undefined) continue;
    unitArr.push({
      itemUnit_MapId: finalArr[0][i].itemUnit_MapId,
      currRate: finalArr[0][i].currRate,
    });
  }
  let filteredArr = [...new Set(unitArr)];
  return filteredArr;
};

//#endregion
