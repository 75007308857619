import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
  Typography,
  Tag,
  message,
  Tooltip,
  Popconfirm,
  Divider,
} from "antd";
import {
  getNewCode,
  initiale,
  editPattEnt,
  getEntPattById,
} from "../../../services/EntPatService";
import {
  getByEntPatt,
  deleteEntPattSett,
} from "../../../services/EntPatSettService";
import { getTypeDropDown } from "../../../services/printPatService";

import SelectSingle from "../../helper/selectSingle";

import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { shortCut } from "../../helper/shortCuts";

import PrintPattSettings from "./helperPatt/PrintPattSettings";

const PrintPattForm = () => {
  const IX_Code = useRef(null);
  const IX_NameAr = useRef(null);
  const IX_NameEn = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [selectedPattren, setSelectedPattren] = useState(undefined);
  const [dataObj, setDataObj] = useState(undefined);
  const [selectedTypeId, setSelectedTypeId] = useState(undefined);

  const [branchDataSource, setBranchDataSource] = useState([]);
  const [optPrintTypes, setOptPrintTypes] = useState([]);

  const [printHeadData, setPrintHeadData] = useState([]);
  const [pageHeaderData, setPageHeaderData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [pageFooterData, setPageFooterData] = useState([]);
  const [printFooterData, setPrintFooterData] = useState([]);

  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const [isGoBack, setIsGoBack] = useState(true);
  const { Text } = Typography;

  //////////----|👉 start auth|----//////////
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.finalAcc)[0];
      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////
  const codeGenerator = async () => {
    setLoadData(true);
    const { data: newData } = await getNewCode();
    form.setFieldsValue({ code: newData.data });
    IX_NameAr.current.focus();
    setLoadData(false);
  };

  const handleGetBranchesCol = async () => {
    try {
      const { data: data } = await getByEntPatt(id);
      setBranchDataSource(data.data);
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    handlePrintType();
    async function fetchData() {
      if (id === "new") {
        return await codeGenerator();
      } else {
        try {
          const { data } = await getEntPattById(id);
          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            nameAr: data.data.nameAr,
            nameEn: data.data.nameEn,
            entTypeId: data.data.entTypeId,
          });
          setDataObj(data);
          setSelectedTypeId(data.data.entTypeId);
          setSelectedPattren(data.data.entTypeId);
          IX_NameAr.current.focus();
        } catch (error) {
          handleEx(error, { IX_Code, IX_NameAr, IX_NameEn });
        } finally {
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = (objId) => {
    if (objId !== true) {
      history.replace({ ...history.location, pathname: `/entPatt/${objId}` });
      IX_NameAr.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    values.printHeadData = printHeadData;
    values.pageHeaderData = pageHeaderData;
    values.contentData = contentData;
    values.pageFooterData = pageFooterData;
    values.printFooterData = printFooterData;



    // setLoadData(true);
    // setProChange(false);
    // values.id = id;
    // sessionStorage.removeItem("popup_entTableSett");
    // sessionStorage.removeItem("popup_addTableSett");
    // sessionStorage.removeItem("popup_discTableSett");
    // // values.detailsColSett= values.detailsColSett.filter(e=>e.visible === true);

    // try {
    //   const data = await editPattEnt(values);
    //   message.success(data.data.message, 3);
    //   goBack(data?.data?.data);
    // } catch (error) {
    //   setLoadData(false);
    //   handleEx(error, { IX_Code, IX_NameAr, IX_NameEn });
    // } finally {
    //   setLoadData(false);
    // }
  
  };

  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showModal = JSON.parse(window.sessionStorage.getItem("showModal"));
      if (showModal === false || showModal === null) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_NameAr?.current?.focus();
              break;
            case "tag":
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };



  const finishLink = () => {
    handleGetBranchesCol();

  };
  const deleteBranch = async (objId) => {
    await deleteEntPattSett(objId);
    await handleGetBranchesCol();
  };

  const formStyle = {
    afterTextAr: "first text arabic",
    afterTextEn: "first text english",
    beforeTextAr: "last text arabic",
    beforeTextEn: "last text english",

    drawingBorder: true,
    borderColor: "#cc0000",
    borderT: 1,
    borderR: 2,
    borderB: 1,
    borderL: 1,
    borderType: "mm",
    borderStyle: "double",

    row: 1,
    col: 3,

    width: 40,
    hight: 3,
    widthHightType: "%",

    marginT: 5,
    marginR: 5,
    marginB: 5,
    marginL: 5,
    marginType: "mm",

    paddingT: 5,
    paddingR: 5,
    paddingB: 5,
    paddingL: 5,
    paddingType: "mm",

    fontColor: "#0e00d1",
    fontSize: 14,
    fontWeight: 900,
    italic: true,
    textWarper: true,

    dateTimeFormatting: "DD/MM/YYYY HH:mm:ss",

    valueFormat: 0,
    countOfZeros: 12,
    thousandsComma: true,
  };

  const tdStyle = `width:${formStyle.width};hight:${formStyle.hight};padding:${
    formStyle.paddingT +
    formStyle.paddingR +
    formStyle.paddingB +
    formStyle.paddingL
  };margin:${
    formStyle.marginT +
    formStyle.marginR +
    formStyle.marginB +
    formStyle.marginL
  };text-align:${formStyle.textAlign};border-color:${
    formStyle.borderColor
  };border-width:${
    formStyle.borderT +
    formStyle.borderR +
    formStyle.borderB +
    formStyle.borderL
  };border-style:${formStyle.borderStyle};font-size:${
    formStyle.fontSize
  };font-weight:${formStyle.fontWeight};font-style:${
    formStyle.fontStyle
  };color:${formStyle.color}`;

  const tempObj = {
    id: "GUID",
    code: 1001,
    nameAr: "اسم القالب",
    nameEn: "Temp Name",
    type: 0,
    printHead: [
      {
        id: "GUID",
        type: "addressType",
        row: 1,
        col: 2,
        dateTimeFormatting: "yyyy/mm/dd hh:mm:ss",
        valueFormatting: 0,
        countOfZero: 12,
        useComma: true,
        afterTextAr: "نص قبل",
        beforeTextAr: "نص بعد",
        afterTextEn: "After Text",
        beforeTextEn: "Before Text",
        tdStyle: tdStyle,
        formStyle: JSON.stringify(formStyle),
        collSett: [],
        tHeaderStyle: JSON.stringify(""),
        tBodyStyle: JSON.stringify(""),
      },
    ],
    pageHead: [
      {
        id: "GUID",
        type: "addressType",
        row: 1,
        col: 2,
        dateTimeFormatting: "yyyy/mm/dd hh:mm:ss",
        valueFormatting: 0,
        countOfZero: 12,
        useComma: true,
        afterTextAr: "نص قبل",
        beforeTextAr: "نص بعد",
        afterTextEn: "After Text",
        beforeTextEn: "Before Text",
        tdStyle: tdStyle,
        formStyle: JSON.stringify(formStyle),

        collSett: [],
        tHeaderStyle: JSON.stringify(""),
        tBodyStyle: JSON.stringify(""),
      },
    ],
    content: [
      {
        id: "GUID",
        type: "addressType",
        row: 1,
        col: 2,
        dateTimeFormatting: "yyyy/mm/dd hh:mm:ss",
        valueFormatting: 0,
        countOfZero: 12,
        useComma: true,
        afterTextAr: "نص قبل",
        beforeTextAr: "نص بعد",
        afterTextEn: "After Text",
        beforeTextEn: "Before Text",
        tdStyle: tdStyle,
        formStyle: JSON.stringify(formStyle),

        collSett: [],
        tHeaderStyle: JSON.stringify(""),
        tBodyStyle: JSON.stringify(""),
      },
    ],
    pageFooter: [
      {
        id: "GUID",
        type: "addressType",
        row: 1,
        col: 2,
        dateTimeFormatting: "yyyy/mm/dd hh:mm:ss",
        valueFormatting: 0,
        countOfZero: 12,
        useComma: true,
        afterTextAr: "نص قبل",
        beforeTextAr: "نص بعد",
        afterTextEn: "After Text",
        beforeTextEn: "Before Text",
        tdStyle: tdStyle,
        formStyle: JSON.stringify(formStyle),

        collSett: [],
        tHeaderStyle: JSON.stringify(""),
        tBodyStyle: JSON.stringify(""),
      },
    ],
    printFooter: [
      {
        id: "GUID",
        type: "addressType",
        row: 1,
        col: 2,
        dateTimeFormatting: "yyyy/mm/dd hh:mm:ss",
        valueFormatting: 0,
        countOfZero: 12,
        useComma: true,
        afterTextAr: "نص قبل",
        beforeTextAr: "نص بعد",
        afterTextEn: "After Text",
        beforeTextEn: "Before Text",
        tdStyle: tdStyle,
        formStyle: JSON.stringify(formStyle),

        collSett: [],
        tHeaderStyle: JSON.stringify(""),
        tBodyStyle: JSON.stringify(""),
      },
    ],
  };

  const handlePrintType = async () => {
    try {
      const { data: data } = await getTypeDropDown();
      setOptPrintTypes(data?.data);
    } catch (error) {
      handleEx(error);
    }
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="itemUnitform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{
            flex: "150px",
          }}
          wrapperCol={{
            span: 50,
          }}
        >
          <div name="code" className="frHeader">
            <Row>
              <Col xs={24} md={24}>
                <Space split="#">
                  <Space split="\">
                    <Text>{_t("strSettings")}</Text>
                    <Text style={{ fontWeight: 700 }}>
                      {_t("strDrawingPrint")}
                    </Text>
                  </Space>
                  <div>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        className="inpCode"
                        size="small"
                        placeholder={_t("strCode")}
                        maxLength={200}
                        autoComplete="off"
                        onChange={promptForm}
                        ref={IX_Code}
                        bordered={false}
                      />
                    </Form.Item>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + F
                      </Tag>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </div>
          <Row gutter={6}>     
            <Col xs={24} md={id !== "new" ? 18 : 24}>
              <div name="field" className="frContent">
                <Row gutter={12}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="nameAr"
                      label={
                        <div>
                          {_t("strNameAr")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 4,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 1
                            </Tag>
                          </div>
                        </div>
                      }
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        placeholder={_t("strNameAr")}
                        maxLength={200}
                        autoComplete="off"
                        size="small"
                        onChange={promptForm}
                        ref={IX_NameAr}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="nameEn"
                      label={_t("strNameEn")}
                      rules={[
                        { required: true, message: `${_t("strIsRequired")}` },
                        { min: 1, message: `${_t("strFrom3-200")}` },
                        { max: 200, message: `${_t("strFrom3-200")}` },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input
                        placeholder={_t("strNameEn")}
                        maxLength={200}
                        autoComplete="off"
                        size="small"
                        onChange={promptForm}
                        ref={IX_NameEn}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="printTypeId"
                      label={_t("strType")}
                      labelCol={{ flex: "85px" }}
                    >
                      <SelectSingle
                        allowClear
                        opt={optPrintTypes}
                        onChange={(e) => {
                          setSelectedPattren(e);

                          //  handelChangeType(e);
                        }}
                        placeholder={_t("strType")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {selectedPattren !== undefined && (
                <PrintPattSettings
                  form={form}
                  dataObj={dataObj}
                  selectedTypeId={selectedTypeId}       
                  printHeadData={printHeadData}
                  setPrintHeadData={setPrintHeadData}
                  pageHeaderData={pageHeaderData}
                  setPageHeaderData={setPageHeaderData}
                  contentData={contentData}
                  setContentData={setContentData}
                  pageFooterData={pageFooterData}
                  setPageFooterData={setPageFooterData}
                  printFooterData={printFooterData}
                  setPrintFooterData={setPrintFooterData}
                  // selectedPattren={selectedPattren}
                  // setSelectedPattren={setSelectedPattren}
                />
              )}
            </Col>
          </Row>
          <div
            className="frFooter"
            style={id !== "new" ? { marginTop: 3 } : {}}
          >
            <Form.Item>
              <Space size="large">
                {!auth?.perPut && (
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      htmlType="submit"
                      disabled={loading}
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                )}
                <Button
                  hidden={typeof onNewStore === "function"}
                  onClick={() => {
                    history.action === "PUSH"
                      ? history.goBack()
                      : history.push("/entPatt");
                  }}
                  size="small"
                >
                  {_t("strBack")}
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

export default PrintPattForm;
