import React, { memo } from "react";
import { Select } from "antd";

const SelectCurr = ({
  value,
  allowClear,
  onFilter,
  rowKey,
  onBlur,
  onSearch,
  options,
  ...rest
}) => {

  const { Option } = Select;
  const filteredOptions =
    typeof onFilter === "function" ? onFilter(rowKey) : options;

  return (
    <Select
      autoFocus 
      size="small"
      showSearch
      optionFilterProp='children'
      bordered={false}
      value={value}
      {...rest}
    >
      {filteredOptions?.map((op) => (
        <Option key={op?.currId} value={op?.currId} >
          {op?.currName}
        </Option>
      ))}
    </Select>
  );
};

export default memo(SelectCurr);
