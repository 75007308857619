import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
  Divider,
  Collapse,
  Card,
  Checkbox,
  InputNumber,
  Input,
  Space,
} from "antd";
import _t from "../../../../../languages/translate";
import SelectSingle from "../../../../helper/selectSingle";
import { getTableColumns } from "../../../../../services/printPatService";
import { handleEx } from "../../../../helper/handleException";
import InputSpacing from "../Inputs/InputSpacing";
import InputWidth from "../Inputs/InputWidth";
import InputPosition from "../Inputs/InputPosition";
import Draggable from "react-draggable";
import TableStyleSett from "../tableStyleSett";
import { convObjToStyle } from "./convObjToSty";
import { convertFromSevePT } from "../../../../helper/gridSettTable/components/helperMethods";

const InsertPrintElements = ({
  mainForm,
  tableStyle = false,
  popName = `${_t("strDrawingPrint")} / ${_t("strInsertElement")}`,
  dataObj,
  showModal,
  setShowModal,
  onHandleData,
}) => {
  const [loadData, setLoadData] = useState(false);
  const [elementTypeSelected, setElementTypeSelected] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [showColSett, setShowColSett] = useState(false);
  const [uniqueId, setUniqueId] = useState(new Date());
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const alignOpt = [
    { id: "start", name: _t("strStartOfLine") },
    { id: "center", name: _t("strMidLine") },
    { id: "end", name: _t("strEndOfLine") },
  ];
  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  useEffect(() => {
    
    if (dataObj?.source !== undefined) {
      if (typeof dataObj?.source === "string") {
        dataObj.source = JSON.parse(dataObj.source);
      }

      form.setFieldsValue({
        afterTextAr: dataObj.source.afterTextAr,
        afterTextEn: dataObj.source.afterTextEn,
        beforeTextAr: dataObj.source.beforeTextAr,
        beforeTextEn: dataObj.source.beforeTextEn,
        borderB: dataObj.source.borderB,
        borderColor: dataObj.source.borderColor,
        borderL: dataObj.source.borderL,
        borderR: dataObj.source.borderR,
        borderStyle: dataObj.source.borderStyle,
        borderT: dataObj.source.borderT,
        borderType: dataObj.source.borderType,
        col: dataObj.source.col,
        countOfZeros: dataObj.source.countOfZeros,
        dateTimeFormatting: dataObj.source.dateTimeFormatting,
        drawingBorder: dataObj.source.drawingBorder,
        fontColor: dataObj.source.fontColor,
        fontSize: dataObj.source.fontSize,
        fontWeight: dataObj.source.fontWeight,
        textAlign: dataObj.source.textAlign,
        hight: dataObj.source.hight,
        italic: dataObj.source.italic,
        elementId: dataObj.source.elementId,
        elementType: dataObj.source.elementType,
        elementName: dataObj.source.elementName,
        marginB: dataObj.source.marginB,
        marginL: dataObj.source.marginL,
        marginR: dataObj.source.marginR,
        marginT: dataObj.source.marginT,
        marginType: dataObj.source.marginType,
        paddingB: dataObj.source.paddingB,
        paddingL: dataObj.source.paddingL,
        paddingR: dataObj.source.paddingR,
        paddingT: dataObj.source.paddingT,
        paddingType: dataObj.source.paddingType,
        row: dataObj.source.row,
        textWarper: dataObj.source.textWarper,
        thousandsComma: dataObj.source.thousandsComma,
        valueFormat: dataObj.source.valueFormat,
        width: dataObj.source.width,
        widthHightType: dataObj.source.widthHightType,
        unPrintEmptyElements: dataObj.source.unPrintEmptyElements,
        colSett: dataObj.source.colSett,
        tHeaderStyle: dataObj.source.tHeaderStyle,
        tBodyStyle: dataObj.source.tBodyStyle,
      });
    }
    setDrawBorderValue(!dataObj?.source?.drawingBorder);
    setElementTypeSelected(dataObj?.source?.elementType);
  }, [dataObj, showModal]);

  const onFinish = (values) => {
    const data = convObjToStyle({ id: dataObj?.id, values: values });
    onHandleData(data);
    handleClear();
  };

  const handleClear = () => {
    setLoadData(true);
    setElementTypeSelected("");
    form.resetFields();
    setUniqueId(new Date());
    setShowModal(false);
    setTimeout(() => {
      setLoadData(false);
    }, 100);
  };

  const handelShowColTableSett = () => {
    const tempObj = convertFromSevePT(form.getFieldValue("colSett"));
    setDataSource(tempObj?.dataSource);
    setColumns(tempObj?.columns);

    setShowColSett(true);
  };

  const handelTableSett = (data) => {};

  const [drawBorderValue, setDrawBorderValue] = useState(true);
  const onCheckDrawBorder = (val) => {
    setDrawBorderValue(!val.target.checked);
    form.setFieldsValue({
      borderColor: "#292929",
      borderStyle: undefined,
      borderType: "mm",
      borderT: undefined,
      borderB: undefined,
      borderR: undefined,
      borderL: undefined,
    });
  };

  const handleElementTypeChange = async (e) => {
    form.setFieldsValue({ elementType: e?.type, elementName: e?.name });
    setElementTypeSelected(e?.type);
 if(e?.fieldId !==undefined){
   try {
     const { data: data } = await getTableColumns(
       mainForm.getFieldValue("printTypeId"),
       e?.fieldId
     );

     form.setFieldsValue({ colSett: data?.data });

   } catch (error) {
     handleEx(error);
   }
 }
  };

  const onHandelTableSett = (e, type) => {
    if (type === "tableSett") {
      form.setFieldsValue({ colSett: e });
    } else if (type === "header") {
      form.setFieldsValue({ tHeaderStyle: e });
    } else if (type === "body") {
      form.setFieldsValue({ tBodyStyle: e });
    }
  };

  return (
    <Modal
      centered
      width={"800px"}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {popName}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      afterClose={() => handleClear()}
      footer={false}
    >
      <div key={uniqueId}>
        <Form
          form={form}
          labelCol={{
            flex: "110px",
          }}
          wrapperCol={{
            span: 50,
          }}
          onFinish={onFinish}
          initialValues={{
            paddingType: "mm",
            marginType: "mm",
            widthHightType: "cm",
            borderType: "mm",
            borderColor: "#292929",
            textAlign: "start",
            fontSize: 12,
            fontWeight: "400",
            fontColor: "#292929",
            dateTimeFormatting: "DD/MM/YYYY HH:mm:ss",
          }}
        >
          <Form.Item name="elementName" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="elementType" hidden>
            <Input />
          </Form.Item>
          <Row gutter={6}>
            {!tableStyle && (
              <Col span={24}>
                <Card
                  style={{ margin: 5, width: "99%" }}
                  bodyStyle={{ padding: 5 }}
                >
                  <Form.Item
                    label={_t("strElementType")}
                    name="elementId"
                    labelCol={{ flex: "110px" }}
                    rules={[{ required: true }]}
                  >
                    <SelectSingle
                      style={{ width: "97%" }}
                      opt={[
                        {
                          name: "عناصر",
                          options: [
                            {
                              name: "محتوى نص",
                              id: "0",
                              type: "text",
                              fieldId: 0,
                              filter1: "محتوى نص",
                            },
                            {
                              name: "تاريخ",
                              id: "1",
                              type: "date",
                              fieldId: 0,
                              filter1: "تاريخ",
                            },
                            {
                              name: "صورة",
                              id: "2",
                              type: "image",
                              fieldId: 0,
                              filter1: "صورة",
                            },
                            {
                              name: "قيمة",
                              id: "3",
                              type: "value",
                              fieldId: 0,
                              filter1: "قيمة",
                            },
                          ],
                        },
                        {
                          name: "جداول القيد",
                          options: [
                            {
                              name: "الجدول الرئيسي",
                              id: "4",
                              type: "table",
                              fieldId: 0,
                              filter1: "الجدول الرئيسي",
                            },
                          ],
                        },
                        {
                          name: "جداول الفاتورة",
                          options: [
                            {
                              name: "الجدول الرئيسي",
                              id: "5",
                              type: "table",
                              fieldId: 1,
                              filter1: "الجدول الرئيسي",
                            },
                            {
                              name: "جدول الخصم",
                              id: "6",
                              type: "table",
                              fieldId: 2,
                              filter1: "جدول الخصم",
                            },
                            {
                              name: "جدول الاضافة",
                              id: "7",
                              type: "table",
                              fieldId: 3,
                              filter1: "جدول الاضافة",
                            },
                          ],
                        },
                      ]}
                      onChange={(_, e) => {
                        handleElementTypeChange(e);
                      }}
                    />
                  </Form.Item>
                </Card>
              </Col>
            )}
            <Divider style={{ margin: 10 }} />
            <Card
              style={{ margin: 5, width: "100%" }}
              bodyStyle={{ padding: 5, maxHeight: "60vh", overflow: "auto" }}
              type="inner"
            >
              <Collapse
                defaultActiveKey={["1", "2", "3"]}
                style={{ width: "100%" }}
              >
                {!tableStyle && (
                  <Panel header={_t("strContentProperties")} key="1">
                    {elementTypeSelected === "table" && (
                      <>
                        <Form.Item name="colSett" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="tHeaderStyle" hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name="tBodyStyle" hidden>
                          <Input />
                        </Form.Item>

                        <TableStyleSett
                          form={form}
                          columns={columns}
                          setColumns={setColumns}
                          dataSource={dataSource}
                          showColSett={showColSett}
                          setShowColSett={setShowColSett}
                          setDataSource={setDataSource}
                          openTableModal={handelShowColTableSett}
                          onHandelTableSett={onHandelTableSett}
                        />
                      </>
                    )}

                    {elementTypeSelected !== "table" && (
                      <Card style={{ margin: 5 }} type="inner">
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={_t("strTextBeforeAr")}
                              name="afterTextAr"
                              labelCol={{
                                flex: "140px",
                              }}
                            >
                              <Input
                                size="small"
                                style={{ width: "100%" }}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={_t("strTextAfterAr")}
                              name="beforeTextAr"
                              labelCol={{
                                flex: "140px",
                              }}
                            >
                              <Input
                                size="small"
                                style={{ width: "100%" }}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={_t("strTextBeforeEn")}
                              name="afterTextEn"
                              labelCol={{
                                flex: "140px",
                              }}
                            >
                              <Input
                                size="small"
                                style={{ width: "100%" }}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={_t("strTextAfterEn")}
                              name="beforeTextEn"
                              labelCol={{
                                flex: "140px",
                              }}
                            >
                              <Input
                                size="small"
                                style={{ width: "100%" }}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    )}
                    {elementTypeSelected === "date" && (
                      <Card style={{ margin: 5 }} type="inner">
                        <Form.Item
                          label={_t("strDateFormat")}
                          name="dateTimeFormatting"
                          tooltip={_t("strDiscDateFormat")}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Card>
                    )}
                    {elementTypeSelected === "value" && (
                      <Card style={{ margin: 5 }} type="inner">
                        <Form.Item
                          label={_t("strFormattingValues")}
                          name="valueFormat"
                          tooltip="لايتوفر شرح"
                        >
                          <SelectSingle
                            opt={[
                              { id: 0, name: "0.10" },
                              { id: 1, name: "0.1" },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          label={_t("strNumbersAfterComma")}
                          name="countOfZeros"
                          labelCol={{
                            flex: "155px",
                          }}
                        >
                          <InputNumber
                            min={0}
                            max={15}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={_t("strUsetHousandsComma")}
                          name="thousandsComma"
                          valuePropName="checked"
                          tooltip="9,999,999.00"
                          labelCol={{
                            flex: "160px",
                          }}
                        >
                          <Checkbox />
                        </Form.Item>
                      </Card>
                    )}

                    <Card style={{ margin: 5 }} type="inner">
                      <Form.Item
                        label={_t("strDontPrintEmptyElements")}
                        name="unPrintEmptyElements"
                        valuePropName="checked"
                        labelCol={{
                          flex: "160px",
                        }}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Card>
                  </Panel>
                )}
                <Panel header={_t("strDrawingContent")} key="2">
                  {!tableStyle && (
                    <Col span={24}>
                      <Form.Item label={_t("strLocation")}>
                        <InputPosition rowInputName="row" colInputName="col" />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item name="textAlign" label={_t("strAlignment")}>
                      <SelectSingle opt={alignOpt} />
                    </Form.Item>
                  </Col>
                  {!tableStyle && (
                    <Col span={24}>
                      <Form.Item label={_t("strTheArea")}>
                        <InputWidth
                          inpType="space"
                          typeName="widthHightType"
                          widthInputName="width"
                          hightInputName="hight"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item label={_t("strPadding")}>
                      <InputSpacing
                        inpType="space"
                        typeName="paddingType"
                        topInputName="paddingT"
                        bottomInputName="paddingB"
                        rightInputName="paddingR"
                        leftInputName="paddingL"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={_t("strMargins")}>
                      <InputSpacing
                        inpType="space"
                        typeName="marginType"
                        topInputName="marginT"
                        bottomInputName="marginB"
                        rightInputName="marginR"
                        leftInputName="marginL"
                      />
                    </Form.Item>
                  </Col>
                </Panel>

                <Panel header={_t("strContentFormat")} key="3">
                  <Card style={{ margin: 5 }} type="inner">
                    <Form.Item label={_t("strFontColor")} name="fontColor">
                      <input type="color" />
                    </Form.Item>
                    <Form.Item label={_t("strFontSize")} name="fontSize">
                      <InputNumber
                        min={1}
                        max={9999}
                        size="small"
                        style={{ width: "100%" }}
                        addonBefore="px"
                      />
                    </Form.Item>
                    <Form.Item label={_t("strFontThickness")} name="fontWeight">
                      <SelectSingle
                        opt={
                          [
                          {
                            id: "100",
                            name: (
                              <>
                                <label>{_t("strThin")}</label>
                                <small>{_t("str100")}</small>
                              </>
                            ),
                          },
                          {
                            id: "200",
                            name: (
                              <>
                                <label>{_t("strExtraLight")}</label>
                                <small>{_t("str200")}</small>
                              </>
                            ),
                          },
                          {
                            id: "300",
                            name: (
                              <>
                                <label>{_t("strLight")}</label>
                                <small>{_t("str300")}</small>
                              </>
                            ),
                          },
                          {
                            id: "400",
                            name: (
                              <>
                                <label>{_t("strNormal")}</label>
                                <small>{_t("str400")}</small>
                              </>
                            ),
                          },
                          {
                            id: "500",
                            name: (
                              <>
                                <label>{_t("strMedium")}</label>
                                <small>{_t("str500")}</small>
                              </>
                            ),
                          },
                          {
                            id: "600",
                            name: (
                              <>
                                <label>{_t("strSemiBold")}</label>
                                <small>{_t("str600")}</small>
                              </>
                            ),
                          },
                          {
                            id: "700",
                            name: (
                              <>
                                <label>{_t("strBold")}</label>
                                <small>{_t("str700")}</small>
                              </>
                            ),
                          },
                          {
                            id: "800",
                            name: (
                              <>
                                <label>{_t("strExtraBold")}</label>
                                <small>{_t("str800")}</small>
                              </>
                            ),
                          },
                          {
                            id: "900",
                            name: (
                              <>
                                <label>{_t("strHeavy")}</label>
                                <small>{_t("str900")}</small>
                              </>
                            ),
                          },
                        ]
                      }
                      />
                    </Form.Item>
                    <Space size="large">
                      <Form.Item
                        label={_t("strFontItalic")}
                        name="italic"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>

                      <Form.Item
                        label={_t("strTextWrap")}
                        name="textWarper"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Space>
                  </Card>

                  <Card
                    style={{ margin: 5 }}
                    type="inner"
                    title={
                      <Form.Item
                        label={_t("strBoredrEnable")}
                        name="drawingBorder"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={onCheckDrawBorder} />
                      </Form.Item>
                    }
                  >
                    <Form.Item label={_t("strBoredrColor")} name="borderColor">
                      <input type="color" disabled={drawBorderValue} />
                    </Form.Item>
                    <Form.Item label={_t("strBoredrType")} name="borderStyle">
                      <SelectSingle
                        opt={[
                          { id: "solid", name: _t("strLine") },
                          { id: "double", name: _t("strDoubleLine") },
                          { id: "dashed", name: _t("strDashedLine") },
                          { id: "dotted", name: _t("strDottedLine") }
                        ]}
                        disabled={drawBorderValue}
                      />
                    </Form.Item>
                    <Col span={24}>
                      <Form.Item label={_t("strBoredrSize")}>
                        <InputSpacing
                          inpType="border"
                          typeName="borderType"
                          topInputName="borderT"
                          bottomInputName="borderB"
                          rightInputName="borderR"
                          leftInputName="borderL"
                          disabled={drawBorderValue}
                        />
                      </Form.Item>
                    </Col>
                  </Card>
                </Panel>
              </Collapse>
            </Card>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Form.Item>
            <Button
              type="primary"
              style={{ margin: "0 10px" }}
              htmlType="submit"
            >
              {_t("strSave")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default InsertPrintElements;
