
import http from "./http";

const apiEndpoint = process.env.REACT_APP_BASE_URL +  "/api/ItemFinalAcc";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getItemFinalAccountById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editItemFinalAccount(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteItemFinalAccount(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}


export function getInitiale(Id) {
  return http.post(`${apiEndpoint}/Initiale/?parentId=${Id}`);
}

export function getItemFinalAccount() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}


export function GetActiveChildren(obj) {
  return http.post(`${apiEndpoint}/GetActiveChildren`,obj);
}

export function getParents(search, exId) {
  return http.post(
    `${apiEndpoint}/GetParent/?search=${search}&exId=${exId}`
  );
}

export function getItemFinalAccountSearch(search) {
  return http.post(`${apiEndpoint}/GetSearch/?search=${search}`);
}


export function getActiveParentsURL(search, addId) {
   const params = new URLSearchParams()
  search && params.append("search", search);
  //addId && params.append("addId", addId); 
 
  return `${apiEndpoint}/GetActiveChildrenTest/?${params.toString()}`;
}

export function getTreeSearch(search) {
  return http.post(`${apiEndpoint}/GetTree/?search=${search}`);
}



export function getAllFinalAccount() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewFinalAccountCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerItemFinalAccount(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}



export function deleteImage(Id, fileName) {
  
  return http.post(`${apiEndpoint}/DeleteImage?Id=${Id}&fileName=${fileName}`);
}

