
export const objCountNum = {
  qty: 3,
  value: 2,
  currency: 3,
  rate: 5,
  equivlent: 5,
  perc: 2,
  num: 0,
};

export function getNumFormat(value, type) {
  if (type === 0) {
    return formatedNumber(value, true, objCountNum.qty);
  }
  if (type === 1) {
    return formatedNumber(value, true, objCountNum.value);
  }
  if (type === 2) {
    return formatedNumber(value, true, objCountNum.currency);
  }
  if (type === 3) {
    return formatedNumber(value, true, objCountNum.rate);
  }
  if (type === 4) {
    return formatedNumber(value, true, objCountNum.equivlent);
  }
  if (type === 5) {
    return formatedNumber(value, true, objCountNum.perc);
  }
  if (type === 6) {
    return formatedNumber(value, true, objCountNum.num);
  }
}

const formatedNumber = (tmpVal, withComma = true, precision) => {
  let val = parseFloat(tmpVal);
  if (val === NaN || val === 0) return null;
  // if (val === undefined||(typeof val !== 'string' && typeof val !== 'number'))return null;

  val = val?.toFixed(precision);

  let results = `${val}`.replace(/[^\d.-]/g, "").split(".");
  if (!results[0]) {
    return null;
  }
  if (withComma) {
    results[0] = results[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (results[0] && typeof precision === "number" && precision > 0) {
    results[1] =
      results[1] && results[1].lenght === precision
        ? results[1]
        : fillNumber(results[1], precision);
    results = results.splice(0, 2);

    return results.join(".");
  }
  return results[0];
};

function fillNumber(value, decimals) {
  if (!decimals || decimals === 0) {
    return "";
  }
  const values = (value || "").split("");
  let results = [];
  for (let i = 0; i < decimals; i++) {
    results[i] = values[i] || "0";
  }
  return results.join("");
}
