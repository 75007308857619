import { calcQtyTotalByCount } from "../RawGrid/handleColumns/calcColumns";
import { calcQtyTotal, calcTotal } from "../MunfGrid/handelColumns";
import { handlePriceFn } from "../RawGrid/handleColumns/handleColumns";
import { _SumArr } from "../../../helper/handleMath";

export const calcChangeCountPrivRaw = async (count, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.itemId !== undefined) {

      // arr[i] = await handlePriceFn(arr[i]);
     arr[i] = await calcQtyTotalByCount(arr[i], count);


    }
  }
  return [...arr];
  // await setRawDetail([...arr]);
};

export const calcChangePricePrivRaw = async (count, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.itemId !== undefined) {

      arr[i] = await handlePriceFn(arr[i]);
    //  arr[i] = await calcQtyTotalByCount(arr[i], count);


    }
  }
  return [...arr];
  // await setRawDetail([...arr]);
};


export const calcChangeCountPrivMunf = async (count, arr,rawTotal) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.itemId !== undefined) {
        
      arr[i] =  calcTotal(arr[i], rawTotal);
      
      arr[i] =  calcQtyTotal(arr[i], count);
      

    }
  }
  return [...arr];
  // await setMunfDetail([...arr]);
};

export const calcRowAndMunf = async (detailObj, count) => {
    
  detailObj.munfRawMaterials = await calcChangeCountPrivRaw(
    count,
    detailObj?.munfRawMaterials
  );
  
let rawTotal=_SumArr(detailObj.munfRawMaterials,"netTotal")

// let aba=detailObj.munfRawMaterials.Sum(x=>x.finalTotal)
  detailObj.munfMunfMaterials = await calcChangeCountPrivMunf(
    count,
    detailObj?.munfMunfMaterials,
    rawTotal
  );
  
  return detailObj;
};
