import { useEffect, useState } from "react";
import { handleEx } from "../../helper/handleException";
import { GetActiveChildren } from "../../../services/AccountService";
import SelectSingle from "../../helper/selectSingle";
import { emptArr, emptString } from "../../helper/helperMethods";

const SelectAccount = ({
  innerRef,
  zIndex,
  value,
  onChange,
  placeholder = "",
  addId = "",
  clientId = "",
  handelOptChange,
  disabled = false,
  handleChange,
}) => {
  const [list, setList] = useState([]);

  let obj = {
    search: "",
    addIds: emptString(addId, null) === null ? "" : [addId],
    clientId: clientId || "",
  };

  const handleSearch = async (e) => {
    
    try {
      obj.search = e;
      const { data: responce } = await GetActiveChildren(obj);
      setList(responce?.data !== undefined ? responce?.data : []);
      if (typeof handelOptChange === "function") {
        handelOptChange(responce?.data);
      }
      if (typeof handleChange === "function") {
        if (responce?.data.length === 1) {
          handleChange(responce?.data[0].id);
        }
      }
    } catch (error) {
      handleEx(error);
    }
  };

  useEffect(() => {
    handleSearch("");
  }, [clientId, addId]);

  return (
    <SelectSingle
      innerRef={innerRef}
      tabIndex={zIndex}
      value={value}
      onChange={(_, reqOptAccCenter) => {
        onChange(_, reqOptAccCenter);
      }}
      placeholder={placeholder}
      disabled={disabled}
      allowClear={true}
      fieldNames={{
        label: "name",
        value: "id",
      }}
      opt={list}
    />
  );
};

export default SelectAccount;
