import React, { useEffect, useState } from "react";
import _t from "../../../languages/translate";
import { getNumFormat } from "../../helper/countOfZero";
import {
  Form,
  // Select,
  // Empty,
  Typography,
  DatePicker,
  Table,
  message,
  Input,
} from "antd";
import InputCurrency from "../../helper/Input/InputCurrency";
import { objCountNum } from "../../helper/countOfZero";
import { emptValue } from "../../helper/helperMethods";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import SelectAccount from "../../helper/inputSelect/selectAccount";
// import SelectDisItemAcc from "./invFunctions/selectHelper/selectDisItemAcc";
// import SelectAddItemAcc from "./invFunctions/selectHelper/selectAddItemAcc";
// import SelectPayValAcc from "./invFunctions/selectHelper/selectPayValAcc";
import SelectSingle from "../../helper/selectSingle";
import DiscFields from "./invFunctions/Discount/DiscFields";
import AddsFields from "./invFunctions/Additional/AddsFields";
import { calcValueReq } from "../../helper/Modal/costCenterModal/costCenterHelper";
import { _RateMul, _Sum, _Sub } from "../../helper/handleMath";
import CostCenterInput from "../../helper/Input/costCenterInput";
const InvGeneralTotal = ({
  refObj,
  pattrenSet,
  form,
  // handlePayMethodSearch,
  payMethodOpt,
  payMethListOptions,
  promptForm,
  mainDataSource,
  payAccReq,
  setPayAccReq,
  setDiscsDataSource,
  discsDataSource,
  disableDiscsPart,
  disableAddPart,
  setStatDiscFields,
  setAddsDataSource,
  addsDataSource,
  setStatAddFields,
  refInvPayMethod,
  refInvPayMethodAcc,
  refDiscInvAcc,
  refAddInvAcc,
  refDiscItemAcc,
  refAddItemAcc,
  refDueDateVisible,
  refPayValueVisible,
  setActiveKey,
  auth,
  setItemDiscOptReqCost,
  itemDiscOptReqCost,
  setItemDiscCostMessage,
  itemDiscCostMessage,
  setItemAddOptReqCost,
  itemAddOptReqCost,
  setItemAddCostMessage,
  itemAddCostMessage,
  setPayOptReqCost,
  payOptReqCost,
  setPayCostMessage,
  payCostMessage,
  setInvDiscsOptReqCost,
  invDiscsOptReqCost,
  setInvAddsOptReqCost,
  invAddsOptReqCost,
  inLoadData,
  setInLoadData,
  payMethType,
   setPayMethType
}) => {
  const { Text } = Typography;
  const [dataList, setDataList] = useState([]);
  const [itemtemDiscAccReq, setItemDiscAccReq] = useState(false);
  const [itemtemAddAccReq, setItemAddAccReq] = useState(false);

  const columns = [
    {
      title: _t("strUnit"),
      dataIndex: "name",
    },
    {
      title: _t("strQuantity"),
      dataIndex: "qty",
      render: (text, record) => getNumFormat(record.qty, 0),
    },
  ];

  

  const handlePayMeth = () => {
    let payMethId = form.getFieldValue("payMethodId");
    const type = payMethodOpt.filter((e) => e.id === payMethId)[0]?.type;
    setPayMethType(type);
    calcPayVal(type);
  };

  const obj = {
    payValue: 0,
  };

  useEffect(() => {
    // handlePayMeth();
    if (emptValue(form.getFieldValue("payValue"), 0) !== 0 && (pattrenSet?.invType !==7 && pattrenSet?.invType !==0) ) {
      setPayAccReq(true);
    } else {
      setPayAccReq(false);
    }
  }, [obj]);

  const calcPayVal = (type) => {

    if (type === 0) {
      obj.payValue = emptValue(form.getFieldValue("netTotal"),0);
      form.setFieldsValue({
        payValue: emptValue(form.getFieldValue("netTotal"),0),
      });
    }
    calcPayTotal();
  };

  const calcItemsTotalList = () => {
    var result = [];

    mainDataSource.reduce(function (res, value) {
      if (value?.itemUnit_MapId !== undefined) {
        let unitName = value?.units.filter(
          (x) => x?.id === value?.itemUnit_MapId
        )[0]?.name;

        if (!res[unitName]) {
          res[unitName] = { name: unitName, qty: 0 };
          result.push(res[unitName]);
        }
        res[unitName].qty = _Sum(res[unitName].qty, value?.qty);
      }
      return res;
    }, {});
    setDataList(result);
  };

  useEffect(() => {
    if (inLoadData === false) {
      calcNetTotal();
      // calcItemsTotalList();
    }
    calcItemsTotalList();
    
    setInLoadData(false);
  }, [mainDataSource, discsDataSource, addsDataSource]);

  const handlePayValue = (e) => {
    if (e > 0) {
      setPayAccReq(true);
    } else {
      setPayAccReq(false);
    }
    calcPayTotal();
  };

  const calcNetTotal = () => {
    const {
      itemSubTotal: sub,
      invDiscTotal: disc,
      invAddTotal: add,
    } = form.getFieldsValue(true);

    form.setFieldsValue({
      netTotal: emptValue(_Sum(_Sub(sub, disc), add), null),
    });
    calcPayVal(payMethType);
  };

  const calcPayTotal = () => {
    form.setFieldsValue({
      payTotal: emptValue(
        _Sum(
          form.getFieldValue("calcPayTotal"),
          form.getFieldValue("payValue")
        ),
        null
      ),
    });
    calcRestTotal();
  };

  const calcRestTotal = () => {
    let n1 = form.getFieldValue("netTotal");
    let n2 = form.getFieldValue("payTotal");

    let test = emptValue(_Sub(n1, n2), null);

    form.setFieldsValue({
      restTotal: test,
    });
  };

  return (
    <div key="div-1" className="scroll">
      <table
        style={{
          textAlign: "start",
          flexDirection: "row-reverse" /*,direction:"ltr"*/,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "end",
                flexDirection: "row-reverse",
                width: "300px",
                maxWidth: "300px",
                verticalAlign: "top",
              }}
            >
              {pattrenSet?.totalQtyTableVisible && (
                <Table
                  columns={columns}
                  dataSource={dataList}
                  scroll={{ y: 200 }}
                  size="small"
                  pagination={false}
                  bordered
                />
              )}
              {pattrenSet?.totalQtyVisible && (
                <table
                  style={{ direction: _t("toolDiriction"), width: "70px" }}
                >
                  <tbody>
                    <tr>
                      <>
                        <td>
                          <Form.Item name="unitTotal" noStyle>
                            {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                            <InputCurrency
                              precision={objCountNum.qty}
                              size="small"
                              style={{
                                width: 160,
                                textAlign: "right",
                                marginBottom: 3,
                              }}
                              disabled
                            />
                          </Form.Item>
                        </td>
                        <td
                          style={{
                            minWidth: 130,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <Text>{_t("strTotalQtyUnits")}</Text>
                        </td>
                      </>
                    </tr>
                  </tbody>
                </table>
              )}
            </td>
            <td
              style={{ maxWidth: "550px", width: "550px", minWidth: "0px" }}
            ></td>
            <td>
              <table style={{ direction: _t("toolDiriction"), width: "20px" }}>
                {/*Total*/}
                <tbody>
                  <tr
                    hidden={
                      !pattrenSet?.itemTotalVisible || !auth?.perShowPrices
                    }
                  >
                    <td style={{ minWidth: 130 }}>
                      <Form.Item name="itemTotal" noStyle>
                        {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                        <InputCurrency
                          onChange={promptForm}
                          precision={objCountNum.currency}
                          size="small"
                          style={{
                            width: 160,
                            textAlign: "right",
                            marginBottom: 3,
                          }}
                          disabled
                        />
                      </Form.Item>
                    </td>
                    <td
                      style={{
                        minWidth: 130,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <Text strong>{_t("strTotal")}</Text>
                    </td>
                  </tr>
                  {/*Item Disc Total*/}
                  <tr>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.itemDiscTotalVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="itemDiscTotal"
                          noStyle
                          shouldUpdate={(prevValues, curValues) => {
                            setItemDiscAccReq(
                              curValues.itemDiscTotal > 0 ? true : false
                            );
                          }}
                        >
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            onChange={promptForm}
                            precision={objCountNum.value}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.itemDiscTotalVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strItemDiscTotal")}</label>
                      </td>
                    </>

                    <>
                      <td
                        hidden={
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="itemDiscAccId"
                          className="gen-input"
                          rules={[
                            {
                              required:
                                itemtemDiscAccReq &&
                                pattrenSet?.genEntFromTotal,
                              message: "",
                              validator: async (_, value) => {
                                if (
                                  itemtemDiscAccReq &&
                                  pattrenSet?.genEntFromTotal &&
                                  (value === undefined || value === "")
                                ) {
                                  setActiveKey("1");
                                  message.error(
                                    `(${_t("strItemDiscTotalAcc")}) ${_t(
                                      "strIsRequired"
                                    )}`
                                  );
                                  throw new Error("message");
                                }
                              },
                            },
                          ]}
                        >
                          <SelectAccount
                            addId={form.getFieldValue("itemDiscAccId")}
                            innerRef={refObj?.refItemDiscAcc}
                            zIndex={117}                        
                            onChange={(_, relatedObj) => {
                              promptForm();
                              setItemDiscOptReqCost(
                                relatedObj?.costCenterReqOpt
                              );
                            }}
                            placeholder={_t("strAccount")}
                            style={{
                              minWidth: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled={!pattrenSet?.discItemAccEnable}
                            handleChange={() => {}}
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 75,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strAccount")}
                          <label
                            style={{
                              color: "red",
                              visibility:
                                itemtemDiscAccReq > 0 &&
                                pattrenSet?.genEntFromTotal
                                  ? ""
                                  : "collapse",
                            }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>

                    <>
                      <td
                        hidden={
                          !pattrenSet?.discItemAccCostCenterVisible ||
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, curr) =>
                            prev.itemDiscCostCenter_Maps !==
                            curr.itemDiscCostCenter_Maps
                          }
                        >
                          {({ getFieldValue }) => {
                            setItemDiscCostMessage(
                              calcValueReq(
                                getFieldValue("itemDiscTotal"),
                                getFieldValue("itemDiscCostCenter_Maps"),
                                itemDiscOptReqCost
                              )
                            );
                            return (
                              <Form.Item name="itemDiscCostCenter_Maps" hidden>
                                <Input />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                        <Form.Item
                          name="itemDiscCostCenter"
                          noStyle
                          rules={[
                            {
                              required:
                                pattrenSet?.discItemAccCostCenterReq === true
                                  ? pattrenSet?.discItemAccCostCenterReq
                                  : itemDiscCostMessage !== undefined
                                  ? true
                                  : false,
                              validator: async (_, value) => {
                                if (
                                  pattrenSet?.discItemAccCostCenterReq === true
                                    ? pattrenSet?.discItemAccCostCenterReq &&
                                      (value === undefined || value === "")
                                    : itemDiscCostMessage !== undefined &&
                                      (value === undefined || value === "")
                                    ? true
                                    : false &&
                                      (value === undefined || value === "")
                                ) {
                                  // setActiveKey("1");
                                  message.error(
                                    `(${_t("strCostCenterItemDisc")}) ${_t(
                                      "strIsRequired"
                                    )}`
                                  );
                                  throw new Error("message");
                                }
                              },
                            },
                          ]}
                        >
                          <CostCenterInput
                            form={form}
                            innerRef={refObj?.refItemDiscCostCent}
                            zIndex={118}
                            fieldName="itemDiscCostCenter"
                            arrName="itemDiscCostCenter_Maps"
                            accountValidationOpt={itemDiscOptReqCost}
                            amount={form.getFieldValue("itemDiscTotal")}
                            type="input"
                            reqMessage={itemDiscCostMessage}
                            disabled={!pattrenSet?.discItemAccCostCenterEnable}
                            onHandelData={(e) => {
                              form.setFieldsValue({ itemDiscCostCenter: e });
                            }}
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.discItemAccCostCenterVisible ||
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strCostCenterItemDisc")}
                          <label
                            style={{
                              color: "red",
                              visibility:
                                pattrenSet?.discItemAccCostCenterReq === true
                                  ? ""
                                  : itemDiscCostMessage !== undefined
                                  ? ""
                                  : "collapse",
                            }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>
                  </tr>
                  {/*Item Add Total*/}
                  <tr>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.itemAddTotalVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="itemAddTotal"
                          shouldUpdate={(prevValues, curValues) => {
                            setItemAddAccReq(
                              curValues.itemAddTotal > 0 ? true : false
                            );
                          }}
                          noStyle
                        >
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            onChange={promptForm}
                            precision={objCountNum.value}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.itemAddTotalVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strItemAddTotal")} </label>
                      </td>
                    </>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.addItemAccVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="itemAddAccId"
                          className="gen-input"
                          rules={[
                            {
                              required:
                                itemtemAddAccReq && pattrenSet?.genEntFromTotal,
                              message: "",
                              validator: async (_, value) => {
                                if (
                                  itemtemAddAccReq &&
                                  pattrenSet?.genEntFromTotal &&
                                  (value === undefined || value === "")
                                ) {
                                  setActiveKey("1");
                                  message.error(
                                    `(${_t("strItemAddTotalAcc")}) ${_t(
                                      "strIsRequired"
                                    )}`
                                  );
                                  throw new Error("message");
                                }
                              },
                            },
                          ]}
                        >
                          <SelectAccount
                            addId={form.getFieldValue("itemAddAccId")}
                            innerRef={refObj?.refItemAddAcc}
                            zIndex={115}
                            onChange={(_, relatedObj) => {
                              promptForm();
                              setItemAddOptReqCost(
                                relatedObj?.costCenterReqOpt
                              );
                            }}
                            placeholder={_t("strAccount")}
                            style={{
                              minWidth: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled={!pattrenSet?.addItemAccEnable}
                            handleChange={() => {}}
                          />                       
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.addItemAccVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 75,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strAccount")}
                          <label
                            style={{
                              color: "red",
                              visibility:
                                itemtemAddAccReq > 0 &&
                                pattrenSet?.genEntFromTotal
                                  ? ""
                                  : "collapse",
                            }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>

                    <>
                      <td
                        hidden={
                          !pattrenSet?.addItemAccCostCenterVisible ||
                          !pattrenSet?.addItemAccVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, curr) =>
                            prev.itemAddCostCenter_Maps !==
                            curr.itemAddCostCenter_Maps
                          }
                        >
                          {({ getFieldValue }) => {
                            setItemAddCostMessage(
                              calcValueReq(
                                getFieldValue("itemAddTotal"),
                                getFieldValue("itemAddCostCenter_Maps"),
                                itemAddOptReqCost
                              )
                            );
                            return (
                              <Form.Item name="itemAddCostCenter_Maps" hidden>
                                <Input />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>

                        <Form.Item
                          name="itemAddCostCenter"
                          noStyle
                          rules={[
                            {
                              required:
                                pattrenSet?.addItemAccCostCenterReq === true
                                  ? pattrenSet?.addItemAccCostCenterReq
                                  : itemAddCostMessage !== undefined
                                  ? true
                                  : false,
                              validator: async (_, value) => {
                                if (
                                  pattrenSet?.addItemAccCostCenterReq === true
                                    ? pattrenSet?.addItemAccCostCenterReq &&
                                      (value === undefined || value === "")
                                    : itemAddCostMessage !== undefined &&
                                      (value === undefined || value === "")
                                    ? true
                                    : false &&
                                      (value === undefined || value === "")
                                ) {
                                  // setActiveKey("1");
                                  message.error(
                                    `(${_t("strCostCenterItemAdd")}) ${_t(
                                      "strIsRequired"
                                    )}`
                                  );
                                  throw new Error("message");
                                }
                              },
                            },
                          ]}
                        >
                          <CostCenterInput
                            form={form}
                            innerRef={refObj?.refItemAddCostCent}
                            zIndex={116}
                            fieldName="itemAddCostCenter"
                            arrName="itemAddCostCenter_Maps"
                            accountValidationOpt={itemAddOptReqCost}
                            amount={form.getFieldValue("itemAddTotal")}
                            type="input"
                            reqMessage={itemAddCostMessage}
                            disabled={!pattrenSet?.addItemAccCostCenterEnable}
                            onHandelData={(e) => {
                              form.setFieldsValue({ itemAddCostCenter: e });
                            }}
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.addItemAccCostCenterVisible ||
                          !pattrenSet?.addItemAccVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strCostCenterItemAdd")}
                          <label
                            style={{
                              color: "red",
                              visibility:
                                pattrenSet?.addItemAccCostCenterReq === true
                                  ? ""
                                  : itemAddCostMessage !== undefined
                                  ? ""
                                  : "collapse",
                            }}
                            // style={{
                            //   color: "red",
                            //   visibility:
                            //     itemtemAddAccReq > 0 &&
                            //     pattrenSet?.genEntFromTotal
                            //       ? ""
                            //       : "collapse",
                            // }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>
                  </tr>
                  {/*Sub Total*/}
                  <tr>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.itemSubTotalVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item name="itemSubTotal" noStyle>
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            onChange={promptForm}
                            precision={objCountNum.currency}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.itemSubTotalVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <Text strong>{_t("strSubTotal")} </Text>
                      </td>
                    </>
                  </tr>
                  {/*Invoice Disc Total*/}

                  <DiscFields
                    auth={auth}
                    refObj={refObj}
                    innerRefSelect={refDiscInvAcc}
                    form={form}
                    discsDataSource={discsDataSource}
                    setDiscsDataSource={setDiscsDataSource}
                    setInvDiscsOptReqCost={setInvDiscsOptReqCost}
                    invDiscsOptReqCost={invDiscsOptReqCost}
                    promptForm={promptForm}
                    pattrenSet={pattrenSet}
                    setStatDiscFields={setStatDiscFields}
                    disableDiscsPart={disableDiscsPart}
                    setActiveKey={setActiveKey}
                  />

                  {/*Invoice Add Total*/}

                  <AddsFields
                    auth={auth}
                    refObj={refObj}
                    innerRefSelect={refAddInvAcc}
                    form={form}
                    addsDataSource={addsDataSource}
                    setAddsDataSource={setAddsDataSource}
                    setInvAddsOptReqCost={setInvAddsOptReqCost}
                    invAddsOptReqCost={invAddsOptReqCost}
                    promptForm={promptForm}
                    pattrenSet={pattrenSet}
                    setStatAddFields={setStatAddFields}
                    disableAddPart={disableAddPart}
                    setActiveKey={setActiveKey}
                  />

                  {/*Net Total*/}
                  <tr>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.netTotalVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item name="netTotal" noStyle>
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            precision={objCountNum.currency}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                              color:
                                emptValue(form.getFieldValue("netTotal")) < 0
                                  ? "red"
                                  : "gray",
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.netTotalVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <Text strong>{_t("strNet")} </Text>
                      </td>
                    </>

                    <>
                      <td
                        hidden={
                          !pattrenSet?.dueDateVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="dueDate"
                          className="gen-input"
                          rules={[
                            { required: pattrenSet?.dueDateReq, message: "" },
                          ]}
                        >
                          <DatePicker
                            ref={refDueDateVisible}
                            allowClear={false}
                            showTime={{ format: "HH:mm" }}
                            format={dateFormatList}
                            className="dateTimeStyle"
                            size="small"
                            onChange={promptForm}
                            disabled={!pattrenSet?.dueDateEnable}
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.dueDateVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strDueDate")}
                          <label
                            style={{
                              color: "red",
                              visibility: pattrenSet?.dueDateReq
                                ? ""
                                : "collapse",
                            }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>
                  </tr>
                  {/*Pay Value*/}
                  <tr>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.payValueVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, curr) =>
                            prev.payMethodId !== curr.payMethodId
                          }
                        >
                          {({ getFieldValue }) => {
                            const payMethId = getFieldValue("payMethodId");
                            const type = payMethodOpt.filter((e) => e.id === payMethId)[0]?.type;
                            window.sessionStorage.setItem("payValueEnable",type === 0 ? true : false);
                            return (
                              <Form.Item name="payValue" noStyle>
                                <InputCurrency
                                  innerRef={refObj?.refPayValue}
                                  zIndex={101}
                                  precision={objCountNum.currency}
                                  size="small"
                                  style={{
                                    width: 160,
                                    textAlign: "right",
                                    marginBottom: 3,
                                  }}
                                  onChange={handlePayValue}
                                  disabled={type === 0 ? true : false}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.payValueVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strPayValue")} </label>
                      </td>
                    </>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.cashAccVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="payAccId"
                          className="gen-input"
                          //  rules={[{ required: payAccReq, message: "" }]}
                          rules={[
                            {
                              required: payAccReq,
                              message: "",
                              validator: async (_, value) => {
                                if (payAccReq) {
                                  if (value === undefined || value === "") {
                                    setActiveKey("1");
                                    message.error( `(${ _t("strAccount") + " " + _t("strPayValue") }) ${_t("strIsRequired")}`
                                    );
                                    throw new Error("message");
                                  }
                                }
                              },
                            },
                          ]}
                        >
                          <SelectAccount
                            addId={form.getFieldValue("payAccId")}
                            innerRef={refObj?.refPayAcc}
                            zIndex={102}
                            onChange={(_, relatedObj) => {
                              promptForm();
                              setPayOptReqCost(relatedObj?.costCenterReqOpt);
                            }}
                            placeholder={_t("strAccount")}
                            style={{
                              minWidth: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled={!pattrenSet?.cashAccEnable}
                            handleChange={() => {}}
                          />                   
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.cashAccVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 75,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strAccount")}
                          <label
                            style={{
                              color: "red",
                              visibility: payAccReq ? "" : "collapse",
                            }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>

                    <>
                      <td
                        hidden={
                          !pattrenSet?.cashAccCostCenterVisible ||
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, curr) =>
                            prev.cashAccCostCenter_Maps !==
                            curr.cashAccCostCenter_Maps
                          }
                        >
                          {({ getFieldValue }) => {
                            
                            let _payValue = getFieldValue("payValue");

                            let _cashAccCostCenter_Maps = getFieldValue(
                              "cashAccCostCenter_Maps"
                            );
                            
                            let x = calcValueReq(
                              _payValue,
                              _cashAccCostCenter_Maps,
                              payOptReqCost
                            );
                            

                            setPayCostMessage(x);
                            return (
                              <Form.Item name="cashAccCostCenter_Maps" hidden>
                                <Input />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, curr) =>
                            prev.payValue !== curr.payValue
                          }
                        >
                          {({ getFieldValue }) => {
                            return (
                              <Form.Item
                                name="cashAccCostCenter"
                                noStyle
                                rules={[
                                  {
                                    required:
                                      pattrenSet?.cashAccCostCenterReq === true
                                        ? pattrenSet?.cashAccCostCenterReq
                                        : payCostMessage !== undefined
                                        ? true
                                        : false,
                                    validator: async (_, value) => {
                                      if (
                                        pattrenSet?.cashAccCostCenterReq ===
                                        true
                                          ? pattrenSet?.cashAccCostCenterReq &&
                                            (value === undefined ||
                                              value === "")
                                          : payCostMessage !== undefined &&
                                            (value === undefined ||
                                              value === "")
                                          ? true
                                          : false &&
                                            (value === undefined ||
                                              value === "")
                                      ) {
                                        // setActiveKey("1");
                                        message.error(
                                          `(${_t("strCostCenterPayAcc")}) ${_t(
                                            "strIsRequired"
                                          )}`
                                        );
                                        throw new Error("message");
                                      }
                                    },
                                  },
                                ]}
                              >
                                <CostCenterInput
                                zIndex={103}
                                innerRef={refObj?.refPayCostCent}
                                  style={{
                                    width: 160,
                                    textAlign: "right",
                                    marginBottom: 3,
                                  }}
                                  type="input"
                                  form={form}
                                  fieldName="cashAccCostCenter"
                                  arrName="cashAccCostCenter_Maps"
                                  accountValidationOpt={payOptReqCost}
                                  amount={getFieldValue("payValue")}
                                  reqMessage={payCostMessage}
                                  disabled={
                                    !pattrenSet?.cashAccCostCenterEnable
                                  }
                                  onHandelData={(e) => {
                                    form.setFieldsValue({
                                      cashAccCostCenter: e,
                                    });
                                  }}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.cashAccCostCenterVisible ||
                          !pattrenSet?.discItemAccVisible ||
                          !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strCostCenterPayAcc")}
                          <label
                            style={{
                              color: "red",
                              visibility:
                                pattrenSet?.cashAccCostCenterReq === true
                                  ? ""
                                  : payCostMessage !== undefined
                                  ? ""
                                  : "collapse",
                            }}
                            // style={{
                            //   color: "red",
                            //   visibility:
                            //     itemtemDiscAccReq > 0 &&
                            //     pattrenSet?.genEntFromTotal
                            //       ? ""
                            //       : "collapse",
                            // }}
                          >
                            *
                          </label>
                        </label>
                      </td>
                    </>
                    <>
                      <td
                        hidden={
                          !pattrenSet?.payMethodVisible || !auth?.perShowPrices
                        }
                      >
                        <Form.Item
                          name="payMethodId"
                          className="gen-input"
                          rules={[
                            {
                              required: pattrenSet?.payMethodReq,
                              message: "",
                              validator: async (_, value) => {
                                if (pattrenSet?.payMethodReq) {
                                  if (value === undefined || value === "") {
                                    setActiveKey("1");
                                    message.error(
                                      `(${_t("strPayMethods")}) ${_t(
                                        "strIsRequired"
                                      )}`
                                    );
                                    throw new Error("message");
                                  }
                                }
                              },
                            },
                          ]}
                        >
                          <SelectSingle
                            innerRef={refObj?.refPayWay}
                            zIndex={104}                   
                            onChange={() => {
                              promptForm();
                              handlePayMeth();
                            }}
                            placeholder={_t("strPayMethods")}
                            disabled={!pattrenSet?.payMethodEnable}
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            allowClear={true}
                            fieldNames={{
                              label: "name",
                              value: "id",
                            }}
                            opt={payMethListOptions}
                          />
                        </Form.Item>
                      </td>
                      <td
                        hidden={
                          !pattrenSet?.payMethodVisible || !auth?.perShowPrices
                        }
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>
                          {_t("strPayMethods")}
                          {pattrenSet?.payMethodReq && (
                            <label
                              style={{
                                color: "red",
                                visibility: true ? "" : "collapse",
                              }}
                            >
                              *
                            </label>
                          )}
                        </label>
                      </td>
                    </>
                  </tr>
                  {/*Calc Pay Total*/}
                  <tr
                    hidden={
                      !pattrenSet?.calcPayTotalVisible || !auth?.perShowPrices
                    }
                  >
                    <>
                      <td>
                        <Form.Item name="calcPayTotal" noStyle>
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            precision={objCountNum.currency}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strCalcPayTotal")} </label>
                      </td>
                    </>
                  </tr>
                  {/*Pay Total*/}
                  <tr
                    hidden={
                      !pattrenSet?.payTotalVisible || !auth?.perShowPrices
                    }
                  >
                    <>
                      <td>
                        <Form.Item name="payTotal" noStyle>
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            precision={objCountNum.currency}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strPayTotal")} </label>
                      </td>
                    </>
                  </tr>
                  {/*Rest Total*/}
                  <tr
                    hidden={
                      !pattrenSet?.restTotalVisible || !auth?.perShowPrices
                    }
                  >
                    <>
                      <td>
                        <Form.Item name="restTotal" noStyle>
                          {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                          <InputCurrency
                            precision={objCountNum.currency}
                            size="small"
                            style={{
                              width: 160,
                              textAlign: "right",
                              marginBottom: 3,
                              color:
                                emptValue(form.getFieldValue("restTotal")) < 0
                                  ? "red"
                                  : "gray",
                            }}
                            disabled
                          />
                        </Form.Item>
                      </td>
                      <td
                        style={{
                          minWidth: 130,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <label>{_t("strRest")} </label>
                      </td>
                    </>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvGeneralTotal;
