import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  message,
  Empty,
  Space,
  Row,
  Col,
  Typography,
  Spin,
  Dropdown,
  DatePicker,
  Tabs,
  Modal,
  Switch,
  Menu,
  Collapse,
  InputNumber,
  Tag,
} from "antd";
import {
  editManufModels,
  getNewManufModelsCode,
  deleteManufModels,
  getManufModelById,
} from "../../../services/munfModelService";
import {
  ExclamationCircleOutlined,
  DeleteTwoTone,
  InfoCircleOutlined,
  ExclamationCircleFilled,
  SaveOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import _t from "../../../languages/translate";
import { handleEx } from "../../helper/handleException";
import { getCurrActiveSearch } from "../../../services/Items/currenciesService";
import { GetActiveStoreChildren } from "../../../services/storeService";
import { getActivePriceMethodSearch } from "../../../services/Items/pricingMethodsService";
import {
  getRate,
  changeRate,
} from "../../../services/Items/currenciesRateService";
import MunfDetails from "./MunfGrid/munfDetails";
import RawDetails from "./RawGrid/rawDetails";
import moment from "moment";
import { getAuth } from "../../../services/Authorization/userRoleService";
import { authKeys } from "../../../services/Authorization/authKeys";
import { shortCut } from "../../helper/shortCuts";
import InputCurrency from "../../helper/Input/InputCurrency";
import { objCountNum } from "../../helper/countOfZero";
import { dateFormatList } from "../../helper/Input/dateFormatList";
import { emptString, emptDate } from "../../helper/helperMethods";
import { handelClxPriceMethod } from "./helper/reCalcPrice";
import { _Sum } from "../../helper/handleMath";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const MunfModelForm = () => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const IX_Doc = useRef(null);
  const IX_Currency = useRef(null);

  const gridMunfRef = useRef(null);
  const gridRawRef = useRef(null);

  const [form] = Form.useForm();
  let { id } = useParams();
  let query = useQuery();
  //******************************//
  const [loadData, setLoadData] = useState(true);
  const [currencyOpt, setCurrencyOpt] = useState([]);
  const [priceMethodOpt, setPriceMethodOpt] = useState([]);
  const [storeOpt, setStoreOpt] = useState([]);
  const [rawDetail, setRawDetail] = useState([]);
  const [munfDetail, setMunfDetail] = useState([]);
  const [pattrenSet, setPattrenSet] = useState(null);
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyCurrId, setCompanyCurrId] = useState(
    form.getFieldValue().currId === pattrenSet?.companyCurrId
  ); //بعد استلام العملة الافتراضية  pattrenSet حذف كل ال
  const [initVal, setInitVal] = useState(null);
  //******************************//
  const [proChange, setProChange] = useState(false);
  const { confirm } = Modal;
  const { TextArea } = Input;
  let history = useHistory();
  const { Panel } = Collapse;
  const { Search } = Input;
  const { Text } = Typography;
  const { TabPane } = Tabs;

  //////////----|👉 start auth|----//////////
  // perDelete
  // perExcel
  // perPdf
  // perPost
  // perPrint
  // perPut
  // perView
  //-----------------------------------------
  const [auth, setAuth] = useState({});
  const authorization = async () => {
    const obj = JSON.parse(localStorage.getItem("auth"));
    if (obj !== null) {
      const responce = obj.filter((e) => e?.roleKey === authKeys.munfModels)[0];

      setAuth(responce);
    } else {
      try {
        const data = await getAuth();
        localStorage.setItem("auth", JSON.stringify(data.data.data));
        authorization();
      } catch (ex) {
        return null;
      }
    }
  };
  //////////----|👉 end auth|----//////////

  const promptForm = () => {
    setProChange(true);
  };

  // const addEmptyRowMunf = () => {
  //   const { munfRawMaterials: dataOut } = form.getFieldsValue(true);
  //   let newNum = dataOut.reduce((acc, shot) => acc = acc > shot.key ? acc : shot.key, -1) + 1;

  //   let output = [...dataOut, { key: newNum }];
  //   form.setFieldsValue({ munfRawMaterials: output });
  //   setMunfDetail(output);
  // };

  // const addEmptyRowRaw = () => {
  //   const { munfMunfMaterials: dataIn } = form.getFieldsValue(true);

  //   let newNum = dataIn.reduce((acc, shot) => acc = acc > shot.key ? acc : shot.key, -1) + 1;
  //   let output = [...dataIn, { key: newNum }];
  //   form.setFieldsValue({ munfMunfMaterials: output });
  //   setRawDetail(output);
  // };

  // useEffect(() => {
  //   addEmptyRowMunf();
  //   addEmptyRowRaw();
  // }, [])

  const initValue = async () => {
    try {
      if (id !== "new") {
        const { data: data } = await getManufModelById(id);

        fillData(data);
      } else {
        form.resetFields();
        codeGenerator();
        handleStoreSearch("", "");
        handlePriceMethodSearch("");
        handleSearchCurrency("");
        setMunfDetail([{ key: 0 }]);
        setRawDetail([{ key: 0 }]);
      }
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };
  const codeGenerator = async () => {
    try {
      const { data: newData } = await getNewManufModelsCode();

      form.setFieldsValue({ code: newData.data });
      setLoadData(false);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    }
  };

  function showConfirmDelete() {
    confirm({
      title: _t("strSureToDelete"),
      icon: <DeleteTwoTone twoToneColor="#eb2f96" />,
      direction: _t("langDiriction"),
      onOk() {
        handleDeleteForm();
      },
      onCancel() {
        return;
      },
    });
  }

  const handleDeleteForm = async () => {
    try {
      const { data: newData } = await deleteManufModels(
        form.getFieldValue().id
      );
      initValue();
      message.success(newData.message);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    authorization();
    const recourdId = query.get("invId");
    async function fetchData() {
      initValue(id, recourdId ?? "");
    }
    fetchData();
  }, [id]);

  const changeCurr = async (currId, value) => {
    let obj = {
      currId: currId,
      date: form.getFieldValue().date.toISOString().slice(0, 16) + "Z",
      value: value,
    };
    const { data: result } = await changeRate(obj);
    message.success(result.message, 3);
  };

  const fillData = (newData) => {
    form.setFieldsValue({
      id:
        newData.data.id !== null &&
        newData.data.id !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.id
          : "",
      name: newData.data.name !== null ? newData.data.name : undefined,
      doc: newData.data.doc !== null ? newData.data.doc : undefined,
      code: newData.data.code !== null ? newData.data.code : undefined,
      date: newData.data.date !== null ? moment(newData.data.date) : undefined,
      fromStoreId:
        newData.data.fromStoreId !== null &&
        newData.data.fromStoreId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.fromStoreId
          : undefined,
      toStoreId:
        newData.data.toStoreId !== null &&
        newData.data.toStoreId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.toStoreId
          : undefined,
      currId:
        newData.data.currId !== null &&
        newData.data.currId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.currId
          : undefined,
      currRate:
        newData.data.currRate !== null ? newData.data.currRate : undefined,
      priceMethodId:
        newData.data.priceMethodId !== null &&
        newData.data.priceMethodId !== "00000000-0000-0000-0000-000000000000"
          ? newData.data.priceMethodId
          : undefined,
      note: newData.data.note !== null ? newData.data.note : undefined,
      inactive: newData.data.inactive,
      munfRawMaterials: newData.data.munfRawMaterials, // سيتم ارسال المدخلات على انها مخرجات
      munfMunfMaterials: newData.data.munfMunfMaterials, // سيتم ارسال المخرجات على انها مدخلات
    });

    setMunfDetail([
      ...newData.data.munfMunfMaterials,
      { key: newData.data.munfMunfMaterials.length },
    ]);
    setRawDetail([
      ...newData.data.munfRawMaterials,
      { key: newData.data.munfRawMaterials.length },
    ]);
    setPattrenSet(null);
    //end
    setProChange(false);
    setInitVal(newData.data);
    handleStoreSearch(
      "",
      newData.data?.storeId !== null ? newData.data?.storeId : ""
    );
    handlePriceMethodSearch("");
    handleSearchCurrency("");
  };

  const handleStoreSearch = async (_Search, _Active) => {
    try {
      let obj = {
        search: _Search,
        addIds:
          _Active === null ||
          _Active === undefined ||
          _Active === "" ||
          _Active === "00000000-0000-0000-0000-000000000000"
            ? []
            : [_Active],
      };
      const { data: responce } = await GetActiveStoreChildren(obj);
      setStoreOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handlePriceMethodSearch = async (_Search) => {
    try {
      const { data: responce } = await getActivePriceMethodSearch(_Search);
      setPriceMethodOpt(responce.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleSearchCurrency = async (value) => {
    try {
      const { data: result } = await getCurrActiveSearch(value);
      setCurrencyOpt(result.data);
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      setLoadData(false);
    }
  };

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };
  const [activeKey, setActiveKey] = useState("0");
  const [altPress, setAltPress] = useState(false);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      const confState = JSON.parse(window.sessionStorage.getItem("confState"));
      let showMunfModal = JSON.parse(
        window.sessionStorage.getItem("showMunfModal")
      );
      let showRawModal = JSON.parse(
        window.sessionStorage.getItem("showRawModal")
      );
      if (
        (showMunfModal === false || showMunfModal === null) &&
        (showRawModal === false || showRawModal === null)
      ) {
        if (!confState) {
          switch (shortCut(e)) {
            case "save":
              form.submit();
              break;
            case "search":
              IX_Code?.current?.focus();
              IX_Code?.current?.select();
              break;
            case "block1":
              IX_Name?.current?.focus();
              break;
            case "block2":
              setActiveKey("0");
              setTimeout(function () {
                if (!!gridMunfRef.current)
                  gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              }, 50);
              break;
            case "block3":
              setActiveKey("1");
              setTimeout(function () {
                if (!!gridRawRef.current)
                  gridRawRef?.current?.selectCell({ idx: 0, rowIdx: -1 });
              }, 50);
              break;
            case "tag":
              IX_Name?.current?.focus();
              IX_Name?.current?.blur();
              if (!!gridMunfRef.current)
                gridMunfRef?.current?.selectCell({ idx: 0, rowIdx: null });
              if (!!gridRawRef.current)
                gridRawRef?.current?.selectCell({ idx: 0, rowIdx: null });
              setAltPress(true);
              break;
            default:
              break;
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keydown", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      if (e.keyCode === 18) {
        setAltPress(false);
      }
    };
    window.addEventListener("keyup", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("keyup", handleKeydownOnEdit);
    };
  }, []);

  useEffect(() => {
    const handleKeydownOnEdit = (e) => {
      setAltPress(false);
    };
    window.addEventListener("blur", handleKeydownOnEdit);
    return () => {
      window.removeEventListener("blur", handleKeydownOnEdit);
    };
  }, []);

  const onFinish = async (values) => {
    //const values = form.getFieldsValue(true);
    setLoadData(true);
    setProChange(false);
    values.id = id;
    const oldDate = values.date;

    if (munfDetail !== null) {
      let totalDistribution = 0;
      let saveContinue = true;
      let output = munfDetail.filter((obj) =>
        Object.keys(obj).includes("itemId")
      );
      output.map((v, i) => {
        if (v.distributePerc === undefined ||  v.distributePerc === 0 ||  v.distributePerc === null) {
          setLoadData(false);
          message.error( _t("msgDistributionReqOne") + (i + 1) + _t("msgDistributionReqTwo"), 5);
          saveContinue = false;
        } else {
          totalDistribution =  _Sum(totalDistribution,v.distributePerc);
        }
      });
      if (saveContinue === false) {
        return;
      }
      if (totalDistribution !== 100) {
        setLoadData(false);
        message.error( _t("msgDistributionRatio") + " " + totalDistribution +  "%" + " " + _t("msgDistributionRatio2"), 5);
        return;
      }
      values.munfMunfMaterials = output;
    }
    if (rawDetail !== null) {
      let output = rawDetail.filter((obj) =>
        Object.keys(obj).includes("itemId")
      );
      values.munfRawMaterials = output;
    }
    if (values.date !== "") {
      values.date = values.date?.toISOString().slice(0, 16) + "Z";
    }
    try {
      const responce = await editManufModels(values);
      setLoadData(false);
      message.success(responce.data.message, 3);
      goBack();
    } catch (error) {
      setLoadData(false);
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    } finally {
      values.date = oldDate;
    }
  };

  const handleChangeCurr = async (value, row) => {
    try {
      const data = await getRate(
        value,
        form.getFieldValue().date.toISOString().slice(0, 16) + "Z"
      );
      form.setFieldsValue({ currRate: data.data.data });
    } catch (error) {
      handleEx(error, { IX_Code, IX_Name, IX_Doc, IX_Currency });
    }
    setCompanyCurrId(form.getFieldValue().currId === pattrenSet?.companyCurrId);
  };

  const currencyOptList =
    currencyOpt.length > 0 &&
    currencyOpt.map((item) => (
      <Select.Option key={item.currId} value={item.currId}>
        {item.currName}
      </Select.Option>
    ));

  const priceMethodOptList =
    priceMethodOpt.length > 0 &&
    priceMethodOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const storeOptList =
    storeOpt.length > 0 &&
    storeOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const showConfirmNew = (currId, value) => {
    confirm({
      title: _t("msgAskSureToChange"),
      icon: <ExclamationCircleOutlined />,
      content: _t("msSureToChange"),
      direction: _t("langDiriction"),
      onOk() {
        changeCurr(currId, value);
      },
      onCancel() {
        return;
      },
    });
  };

  const handleChangeRate = (currId, rate) => {
    if (pattrenSet?.currChType === 0) {
      if (currId !== undefined) {
        changeCurr(currId, rate);
      }
    } else if (pattrenSet?.currChType === 2) {
      if (currId !== undefined) {
        showConfirmNew(currId, rate);
      }
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({ ...history.location, pathname: "/munfModel/new" });
      }
      initValue();
      IX_Name.current.focus();
    }
  };
  return (
    <div key="mainDiv">
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="branchform"
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{
              flex: "100px",
            }}
            wrapperCol={{
              span: 23,
            }}
            initialValues={{
              munfMunfMaterials: munfDetail,
              munfRawMaterials: rawDetail,
              date: moment(),
              currId: localStorage.getItem("currId"),
              currRate: 1,
            }}
          >
            <div name="code" className="frHeader">
              <Row>
                <Col xs={24} md={12}>
                  <Space split="#">
                    <Space split="\">
                      <Text>{_t("strMunf")}</Text>
                      <Text style={{ fontWeight: 700 }}>
                        {_t("strModalMunf")}
                      </Text>
                    </Space>
                    <div>
                      <Form.Item
                        name="code"
                        rules={[
                          { required: true, message: `${_t("strIsRequired")}` },
                          { min: 1, message: `${_t("strFrom3-200")}` },
                          { max: 200, message: `${_t("strFrom3-200")}` },
                        ]}
                      >
                        <Input
                          size="small"
                          placeholder={_t("strCode")}
                          maxLength={200}
                          autoComplete="off"
                          ref={IX_Code}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          opacity: 0.8,
                          zIndex: 99,
                          position: "absolute",
                          top: 24,
                        }}
                      >
                        <Tag color="#2b2b2b" hidden={!altPress}>
                          alt + F
                        </Tag>
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
            <Row gutter={3}>
              <Col flex="1 1 400px">
                <div name="field" className="frContent">
                  <Row gutter={12}>
                    <Form.Item name="id" noStyle hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item name="rawTotal" noStyle hidden>
                      <InputNumber />
                    </Form.Item>

                    <Form.Item name="rawItemTotal" noStyle hidden>
                      <InputNumber />
                    </Form.Item>

                    <Form.Item name="rawPrice" noStyle hidden>
                      <InputNumber />
                    </Form.Item>

                    {!pattrenSet?.clientVisible && (
                      <Col xs={24} md={8}>
                        <div>
                          <Form.Item
                            name="name"
                            label={_t("strName")}
                            rules={[
                              {
                                required: true,
                                message: `${_t("strIsRequired")}`,
                              },
                              { min: 1, message: `${_t("strFrom3-200")}` },
                              { max: 200, message: `${_t("strFrom3-200")}` },
                            ]}
                          >
                            <Input
                              placeholder={_t("strName")}
                              maxLength={200}
                              autoComplete="off"
                              onChange={promptForm}
                              ref={IX_Name}
                              size="small"
                            />
                          </Form.Item>
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 1
                            </Tag>
                          </div>
                        </div>
                      </Col>
                    )}
                    {!pattrenSet?.docVisible && (
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="doc"
                          label={_t("strDocument")}
                          rules={[
                            {
                              required: pattrenSet?.docReq,
                              message: `${_t("strIsRequired")}`,
                            },
                            { min: 1, message: `${_t("strFrom3-200")}` },
                            { max: 200, message: `${_t("strFrom3-200")}` },
                          ]}
                        >
                          <Input
                            placeholder={_t("strDocument")}
                            maxLength={200}
                            autoComplete="off"
                            onChange={promptForm}
                            ref={IX_Doc}
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} md={8}>
                      <Form.Item
                        name="date"
                        label={_t("strDate")}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          allowClear={false}
                          showTime={{ format: "HH:mm" }}
                          format={dateFormatList}
                          className="dateTimeStyle"
                          size="small"
                          onChange={promptForm}
                          disabled={pattrenSet?.dateEnable}
                        />
                      </Form.Item>
                    </Col>

                    {!pattrenSet?.storeVisible && (
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="fromStoreId"
                          label={_t("strFromStore")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) => {
                              handleStoreSearch(value, initVal?.fromStoreId);
                            }}
                            optionFilterProp="children"
                            notFoundContent={
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              ></Empty>
                            }
                            placeholder={_t("strFromStore")}
                            size="small"
                            onChange={promptForm}
                            disabled={pattrenSet?.storeEnable}
                          >
                            {storeOptList}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {!pattrenSet?.toStoreVisible && (
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="toStoreId"
                          label={_t("strToStore")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) => {
                              handleStoreSearch(value, initVal?.toStoreId);
                            }}
                            optionFilterProp="children"
                            notFoundContent={
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              ></Empty>
                            }
                            placeholder={_t("strToStore")}
                            size="small"
                            onChange={promptForm}
                            disabled={pattrenSet?.toStoreEnable}
                          >
                            {storeOptList}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {!pattrenSet?.currVisible && (
                      <Col xs={24} md={8}>
                        <Form.Item label={_t("strCurrency")}>
                          <Input.Group compact>
                            <Form.Item
                              name="currId"
                              noStyle
                              rules={[{ required: true }]}
                            >
                              <Select
                                ref={IX_Currency}
                                style={{ width: "70%" }}
                                showSearch
                                filterOption={false}
                                onSearch={(value) => {
                                  handleSearchCurrency(value);
                                }}
                                onChange={(e) => {
                                  handleChangeCurr(e, "");
                                  promptForm();
                                }}
                                optionFilterProp="children"
                                notFoundContent={
                                  <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  ></Empty>
                                }
                                placeholder={_t("strNotSelected")}
                                size="small"
                                disabled={pattrenSet?.currEnable}
                              >
                                {currencyOptList}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="currRate"
                              noStyle
                              rules={[{ required: true }]}
                            >
                              {/* count of zero by types name >> ( qty: 3, value: 2, currency: 5, rate: 5, equivlent:5, perc:2 )~*/}
                              <InputCurrency
                                precision={objCountNum.rate}
                                style={{ width: "30%" }}
                                size="small"
                                onPressEnter={() =>
                                  handleChangeRate(
                                    form.getFieldValue("currId"),
                                    form.getFieldValue("currRate")
                                  )
                                }
                                placeholder={_t("strRate")}
                                onChange={promptForm}
                                suffix={
                                  <Tooltip title={_t("msgRateCurrValue")}>
                                    <InfoCircleOutlined
                                      style={{ color: "rgba(0,0,0,.45)" }}
                                    />
                                  </Tooltip>
                                }
                                disabled={
                                  pattrenSet?.currEnable ||
                                  companyCurrId ||
                                  pattrenSet?.currChType === 3
                                }
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    )}
                    {!pattrenSet?.priceMethodVisible && (
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="priceMethodId"
                          label={_t("strPriceMethod")}
                          rules={[{ required: true }]}
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) => {
                              handlePriceMethodSearch(value);
                            }}
                            optionFilterProp="children"
                            notFoundContent={
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              ></Empty>
                            }
                            placeholder={_t("strPriceMethod")}
                            size="small"
                            onChange={(e) => {
                              promptForm();
                              if (
                                e !== undefined &&
                                emptString(rawDetail[0].itemId, false)
                              ) {
                                confirm({
                                  title: _t(
                                    "msgDoYouWantRepriceAllInvoiceItems"
                                  ),
                                  icon: <ExclamationCircleFilled />,
                                  direction: _t("langDiriction"),
                                  onOk: async () => {
                                    setLoadData(true);
                                    let res = await handelClxPriceMethod(
                                      [
                                        {
                                          munfRawMaterials: rawDetail,
                                          munfMunfMaterials: munfDetail,
                                        },
                                      ],                                    
                                        form.getFieldValue("date")?.toISOString()?.slice(0, 16) + "Z",
                                      e,
                                      form.getFieldValue().currRate ===
                                        undefined
                                        ? ""
                                        : form.getFieldValue().currRate
                                    );                          
                                    setRawDetail(
                                      res.length > 0
                                        ? res[0].munfRawMaterials
                                        : rawDetail
                                    );
                                    setMunfDetail(
                                      res.length > 0
                                        ? res[0].munfMunfMaterials
                                        : munfDetail
                                    );
                                    setLoadData(false);
                                  },
                                  onCancel() {},
                                });
                              }
                            }}
                          >
                            {priceMethodOptList}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="inactive"
                        valuePropName="checked"
                        label={_t("strInActive")}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                      <Form.Item
                        name="note"
                        label={_t("strNote")}
                        rules={[
                          {
                            required: false,
                            message: `${_t("strIsRequired")}`,
                          },
                          { min: 1, message: `${_t("strFrom1-1000")}` },
                          { max: 1000, message: `${_t("strFrom1-1000")}` },
                        ]}
                      >
                        <TextArea
                          placeholder={_t("strNote")}
                          maxLength={1000}
                          autoComplete="off"
                          onChange={promptForm}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="frContent">
                  <Tabs
                    activeKey={activeKey}
                    onTabClick={handleChangeTab}
                    style={{ marginTop: 0 }}
                  >
                    <TabPane
                      forceRender
                      tab={
                        <div>
                          {_t("strTheOutput")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 2
                            </Tag>
                          </div>
                        </div>
                      }
                      key="0"
                    >
                      <MunfDetails
                        innerRef={gridMunfRef}
                        pattrenSet={pattrenSet}
                        form={form}
                        setMunfDetail={setMunfDetail}
                        munfDetail={munfDetail}
                        setLoadData={setLoadData}
                        id={id}
                        promptForm={promptForm}
                        noStyle={true}
                      />
                    </TabPane>

                    <TabPane
                      forceRender
                      tab={
                        <div>
                          {_t("strTheInput")}
                          <div
                            style={{
                              opacity: 0.8,
                              zIndex: 99,
                              position: "absolute",
                              top: 24,
                            }}
                          >
                            <Tag color="#2b2b2b" hidden={!altPress}>
                              alt + 3
                            </Tag>
                          </div>
                        </div>
                      }
                      key="1"
                    >
                      <RawDetails
                        innerRef={gridRawRef}
                        pattrenSet={pattrenSet}
                        form={form}
                        activeKey={activeKey}
                        setRawDetail={setRawDetail}
                        rawDetail={rawDetail}
                        setMunfDetail={setMunfDetail}
                        munfDetail={munfDetail}
                        setLoadData={setLoadData}
                        id={id}
                        promptForm={promptForm}
                        noStyle={true}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>
            <div className="frFooter">
              <Form.Item>
                <Space size="middle">
                  <div>
                    <Button
                      type="link"
                      icon={<SaveOutlined />}
                      onClick={() => form.submit()}
                      size="small"
                    >
                      {_t("strSave")}
                    </Button>
                    <div
                      style={{
                        opacity: 0.8,
                        zIndex: 99,
                        position: "absolute",
                        top: 24,
                      }}
                    >
                      <Tag color="#2b2b2b" hidden={!altPress}>
                        alt + S
                      </Tag>
                    </div>
                  </div>
                  {/* <Button
                      type="text"
                      icon={<PrinterOutlined />}
                      onClick={() => alert("Print inactive.")}
                      // disabled={id === "new" ? true : false}
                      size="small"
                    >
                      {_t("strPrint")}
                    </Button> */}
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => {
                      history.action === "PUSH"
                        ? history.goBack()
                        : history.push("/munfModel");
                    }}
                    size="small"
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </React.Fragment>
    </div>
  );
};
export default MunfModelForm;
