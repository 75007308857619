import React, { useState, useEffect } from "react";
import { Button, Card, Space, Divider } from "antd";
import {
  InsertRowRightOutlined,
  InsertRowAboveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import InsertPrintElements from "./popups/InsertPrintElements";
import ColTableSett from "./popups/colTableSett";
import _t from "../../../../languages/translate";

const TableStyleSett = ({
  form,
  columns,
  setColumns,
  dataSource,
  showColSett,
  setShowColSett,
  openTableModal,
  setDataSource,
  onHandelTableSett,
}) => {
  const [showInsertHeader, setShowInsertHeader] = useState(false);
  const [showInsertBody, setShowInsertBody] = useState(false);


  return (
    <>
      <InsertPrintElements
        tableStyle={true}
        popName={_t("strTableHeader")}
        dataObj={form.getFieldValue("tHeaderStyle")}
        showModal={showInsertHeader}
        setShowModal={setShowInsertHeader}
        onHandleData={(e) => {
          setShowInsertHeader(false);
          onHandelTableSett(e, "header");
        }}
      />
      <InsertPrintElements
        tableStyle={true}
        popName={_t("strTableContent")}
        dataObj={form.getFieldValue("tBodyStyle")}
        showModal={showInsertBody}
        setShowModal={setShowInsertBody}
        onHandleData={(e) => {
          setShowInsertBody(false);
          onHandelTableSett(e, "body");
        }}
      />
      <ColTableSett
        showModal={showColSett}
        setShowModal={setShowColSett}
        columns={columns}
        setColumns={setColumns}
        dataSource={dataSource}
        setDataSource={setDataSource}
        tableName="popup_colTableSett"
        onHandleData={(ts) => {
          onHandelTableSett(ts, "tableSett");
        }}
        // onHandleData={(e) => {
        //     handleTableData(e);
        //   // form.setFieldsValue({ addsColSett: e });
        // }}
      />

      <Card style={{ margin: 5 }} type="inner">
        <Space size="small">
          <Button
            type="text"
            icon={
              <SettingOutlined style={{ fontSize: 16, color: "#666666" }} />
            }
            onClick={openTableModal}
          >
            {_t("strColSett")}
          </Button>
          <Divider type="vertical" />
          <Button
            type="text"
            icon={
              <InsertRowAboveOutlined
                style={{ fontSize: 16, color: "#666666" }}
              />
            }
            onClick={() => {
              setShowInsertHeader(true);
            }}
          >
            {_t("strTableHeader")}
          </Button>
          <Divider type="vertical" />
          <Button
            type="text"
            icon={
              <InsertRowRightOutlined
                style={{ fontSize: 16, color: "#666666" }}
              />
            }
            onClick={() => {
              setShowInsertBody(true);
            }}
          >
            {_t("strTableContent")}
          </Button>
        </Space>
      </Card>
    </>
  );
};

export default TableStyleSett;
